import { Component, Input } from '@angular/core';
import { Navigate } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';
import { RouteInfo } from 'src/app/navigation/route-info.interface';

@Component({
  selector: 'sf-route-cards',
  templateUrl: './route-cards.component.html',
  styleUrls: ['./route-cards.component.scss']
})
export class RouteCardsComponent {

  @Input()routeInfoList: RouteInfo[];
  @Input() fitCenter = false;

  constructor(private store: Store){

  }

  onRedirect(agentRouteInfo: RouteInfo) {
    if (agentRouteInfo.disabled) return;
    this.store.dispatch(new Navigate([agentRouteInfo.route]))
  }

}
