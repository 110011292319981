import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardTemplateComponent } from './templates/card-template/card-template.component';
import { SearchBarComponent } from './components/search-bar/search-bar.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MapModule } from './elements/map/map.module';
import { SnowflakeMapsComponent } from './components/snowflake-maps/snowflake-maps.component';
import { RainFormsModule } from './elements/rain-forms/rain-forms.module';
import { CustomSpinnerComponent } from './components/custom-spinner/custom-spinner.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatChipsModule } from '@angular/material/chips';
import { MatSelectModule } from '@angular/material/select';
import { SidebarTemplateComponent } from './templates/sidebar-template/sidebar-template.component';
import { SnowflakeIconComponent } from './components/snowflake-icon/snowflake-icon.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { BackBtnComponent } from './components/back-btn/back-btn.component';
import { WidgetTemplateComponent } from './templates/widget-template/widget-template.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { pipes } from './pipes';
import { ModalTemplateComponent } from './templates/modal-template/modal-template.component';
import { FileUploaderModule } from './elements/file-uploader/file-uploader.module';
import { BlockLoaderComponent } from './elements/block-loader/block-loader.component';
import { ButtonComponent } from './components/button/button.component';
import { SFDynamicFormsModule } from './elements/dynamic-forms/dynamic-forms.module';
import { GroupsFilterComponent } from './elements/groups-filter/groups-filter.component';
import { MatTreeModule } from '@angular/material/tree';
import { PopupModalComponent } from './components/popup-modal/popup-modal.component';
import { ProgressLoaderComponent } from './components/progress-loader/progress-loader.component';
import { GeocoderComponent } from './components/geocoder/geocoder.component';
import { RainCardComponent } from './components/rain-card/rain-card.component';
import { ClickOutsideModule } from 'ng-click-outside';
import { ColorBoxComponent } from './components/color-box/color-box.component';
import { LabelledInfoComponent } from './components/labelled-info-components/labelled-info/labelled-info.component';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BlankCardComponent } from './components/blank-card/blank-card.component';
import { MiniMenuButtonComponent } from './components/mini-menu/mini-menu-button/mini-menu-button.component';
import { MiniMenuComponent } from './components/mini-menu/mini-menu.component';
import { RainButtonComponent } from './components/rain-button/rain-button.component';
import { HeaderBoxComponent } from './components/header-box/header-box.component';
import { InteractionButtonsComponent } from '../interactions/interaction-buttons/interaction-buttons.component';
import { IconButtonComponent } from './components/icon-button/icon-button.component';
import { ModalFormContainerComponent } from './components/popup-modal/modal-form-container/modal-form-container.component';
import { AsyncLabelledInfoComponent } from './components/labelled-info-components/async-labelled-info/async-labelled-info.component';
import { LabelledColorBoxComponent } from './components/labelled-info-components/labelled-color-box/labelled-color-box.component';
import { AsyncLabelledColorBoxComponent } from './components/labelled-info-components/async-labelled-color-box/async-labelled-color-box.component';
import { DateTimeSelectOptionsComponent } from './components/date-time-select-options/date-time-select-options.component';
import { CustomProgressSpinnerComponent } from './components/custom-progress-spinner/custom-progress-spinner.component';
import { DefaultPageTemplateComponent } from './templates/default-page-template/default-page-template.component';
import { RouteCardsComponent } from './components/route-cards/route-cards.component';
import { MarginBoxComponent } from './components/margin-box/margin-box.component';
import { LOADER_ELEMENTS } from './elements/loaders';
import { FileUploaderComponent } from './components/file-uploader/file-uploader.component';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { ChatHeaderComponent } from './components/chat-header/chat-header.component';
import { ConnectedCustomerInfoComponent } from './components/connected-customer-info/connected-customer-info.component';
import { OnlyIconButtonComponent } from './components/only-icon-button/only-icon-button.component';
import { SHARED_COMPONENTS } from './components';
import { DetailedSummaryComponent } from './components/detailed-summary/detailed-summary.component';
import { DetailedCardComponent } from './components/detailed-card/detailed-card.component';
import { LinkButtonComponent } from './components/link-button/link-button.component';
import { RainEditButtonComponent } from './components/rain-edit-button/rain-edit-button.component';
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { TextGroupComponent } from './components/text-group/text-group.component';
import { FormRowComponent } from './components/popup-modal/form-row/form-row.component';
import { FormErrorComponent } from './components/popup-modal/form-error/form-error.component';
import { InfoIconComponent } from './components/info-icon/info-icon.component';
import { SquareButtonComponent } from './components/square-button/square-button.component';
import { RefreshSmartsubDataButtonComponent } from '../toolbar/refresh-smartsub-data-button/refresh-smartsub-data-button.component';
import { KeywordSearchComponent } from './elements/keyword-search/keyword-search.component';
import { NavItemTextComponent } from './components/nav-item-text/nav-item-text.component';
import { SquareIconButtonComponent } from './components/square-icon-button/square-icon-button.component';
import { SmallTextSpinnerModule } from './components/small-text-spinner/small-text-spinner.module';
import { RollupWrapperComponent } from './components/rollup-wrapper/rollup-wrapper.component';
import { AgentComponentModule } from './components/agent/agent-component.module';
import { TicketTrackingModule } from './components/ticket-tracking/ticket-tracking.module';
import { ADMIN_PIPES } from '../features/pages/admin/assets/pipes';
import { TestAccountCopyComponent } from './components/test-account-copy/test-account-copy.component';
import { SearchableGroupsFilterComponent } from './elements/groups-filter/searchable-groups-filter/searchable-groups-filter.component';

const declarations = [
    CardTemplateComponent,
    SearchBarComponent,
    SnowflakeMapsComponent,
    CustomSpinnerComponent,
    SidebarTemplateComponent,
    SnowflakeIconComponent,
    BackBtnComponent,
    ButtonComponent,
    WidgetTemplateComponent,
    BlockLoaderComponent,
    ModalTemplateComponent,
    PopupModalComponent,
    ProgressLoaderComponent,
    GeocoderComponent,
    RainCardComponent,
    ColorBoxComponent,
    LabelledInfoComponent,
    MiniMenuComponent,
    MiniMenuButtonComponent,
    BlankCardComponent,
    RainButtonComponent,
    HeaderBoxComponent,
    InteractionButtonsComponent,
    IconButtonComponent,
    ModalFormContainerComponent,
    AsyncLabelledInfoComponent,
    LabelledColorBoxComponent,
    AsyncLabelledColorBoxComponent,
    DateTimeSelectOptionsComponent,
    CustomProgressSpinnerComponent,
    DefaultPageTemplateComponent,
    RouteCardsComponent,
    MarginBoxComponent,
    FileUploaderComponent,
    ChatHeaderComponent,
    ConnectedCustomerInfoComponent,
    OnlyIconButtonComponent,
    DetailedSummaryComponent,
    LinkButtonComponent,
    DetailedCardComponent,
    RainEditButtonComponent,
    TextGroupComponent,
    FormRowComponent,
    FormErrorComponent,
    InfoIconComponent,
    SquareButtonComponent,
    RefreshSmartsubDataButtonComponent,
    KeywordSearchComponent,
    SquareIconButtonComponent,
    RollupWrapperComponent,
    TestAccountCopyComponent,
    ...LOADER_ELEMENTS,
    ...SHARED_COMPONENTS,
    ...ADMIN_PIPES
]

@NgModule({
    declarations: [
        ...declarations,
        ...pipes,
        ModalTemplateComponent,
        BlockLoaderComponent,
        GroupsFilterComponent,
        NavItemTextComponent,
        SearchableGroupsFilterComponent,
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        ClickOutsideModule,
        MatIconModule,
        MatFormFieldModule,
        MatInputModule,
        MatChipsModule,
        MatSelectModule,
        MapModule,
        RainFormsModule,
        MatProgressSpinnerModule,
        MatSidenavModule,
        FontAwesomeModule,
        FileUploaderModule,
        SFDynamicFormsModule,
        MatTreeModule,
        MatMenuModule,
        MatTooltipModule,
        NgxDropzoneModule,
        GooglePlaceModule,
        SmallTextSpinnerModule,
        AgentComponentModule,
        TicketTrackingModule
    ],
    exports: [
        ...declarations,
        ...pipes,
        MapModule,
        RainFormsModule,
        MatIconModule,
        MatFormFieldModule,
        MatInputModule,
        MatChipsModule,
        MatSelectModule,
        MatSidenavModule,
        ModalTemplateComponent,
        FileUploaderModule,
        BlockLoaderComponent,
        SFDynamicFormsModule,
        ClickOutsideModule,
        GroupsFilterComponent,
        MatMenuModule,
        MatTooltipModule,
        FontAwesomeModule,
        GooglePlaceModule,
        MatTreeModule,
        SmallTextSpinnerModule,
        AgentComponentModule,
        TicketTrackingModule,
        SearchableGroupsFilterComponent,

    ]
})
export class SharedModule { }
