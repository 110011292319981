import { SFValidators } from "src/app/shared/functions/sf-validators";
import { Dictionary } from "src/app/shared/interfaces/dictionary.interface";


export const patchList = <T extends Dictionary>(originalList: T[], newList: T[], key: keyof T) => {
    const listMap: { [key in keyof T]?: T } = {};

    const mapList = (item: T) => {
        const identifier = item?.[key];
        if (SFValidators.isDefined(identifier)) {
            listMap[identifier] = item;
        }
    }

    originalList.forEach(mapList);
    newList.forEach(mapList);

    return Object.values(listMap);
}
