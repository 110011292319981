
import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { CallbackService } from '../../../callback/services/callback.service';
import { AgentViewCallbackActions } from '../actions/agent-view-callback-actions';
import { CallbackStatus } from '../../../callback/store/types/callback-status.type';
import { AddSuccessResponseNotification } from 'src/app/core/store/actions/notifications.actions';
import { Utils } from 'src/app/Utils';
import { CallbackExtras } from '../../../callback/store/types/callback-response.interface';
import { SFResponseType } from '../types/respond-to-ticket-payload.interface';
import { CoreState } from 'src/app/core/store/state/core.state';
import { CustomerCallbackActions } from '../../../callback/store/actions/customer-callback-actions';


interface AgentViewCallbackResponseStateModel {
    loading: boolean;
}
@State<AgentViewCallbackResponseStateModel>({
    name: 'sf_AgentViewCallbackResponse_state',
    defaults: {
        loading: false
    }
})
@Injectable()
export class AgentViewCallbackResponseState {

    @Selector()
    static isLoading(state: AgentViewCallbackResponseStateModel) { return state.loading }

    constructor(private callbackService: CallbackService,
        private store: Store) {
    }

    @Action(AgentViewCallbackActions.AcceptCallback)
    AcceptCallback(ctx: StateContext<AgentViewCallbackResponseStateModel>, action: AgentViewCallbackActions.AcceptCallback) {
        const { callback } = action;
        const response: SFResponseType = "accept";
        const extras = this.getAgentResponseExtras(<CallbackExtras>callback?.extras, response);

        return ctx.dispatch(new AgentViewCallbackActions.RespondToCallback(callback?.id, response, extras));
    }

    @Action(AgentViewCallbackActions.RejectCallback)
    RejectCallback(ctx: StateContext<AgentViewCallbackResponseStateModel>, action: AgentViewCallbackActions.RejectCallback) {
        const { callback } = action;
        const response: SFResponseType = "reject";
        const extras = this.getAgentResponseExtras(<CallbackExtras>callback?.extras, response);

        return ctx.dispatch(new AgentViewCallbackActions.RespondToCallback(callback?.id, response, extras));
    }

    private getAgentResponseExtras(currentExtras: CallbackExtras, response: SFResponseType) {
        const agentEmail = this.store.selectSnapshot(CoreState.getAgentEmail);

        return {
            agentResponses: [
                ...currentExtras?.agentResponses ?? [],
                {
                    response,
                    email: agentEmail,
                    inserted_at: Math.round(Date.now() / 1000)
                }
            ]
        }
    }


    @Action(AgentViewCallbackActions.RespondToCallback)
    RespondToCallback(ctx: StateContext<AgentViewCallbackResponseStateModel>, action: AgentViewCallbackActions.RespondToCallback) {
        const { callbackId, type, extras } = action;
        const updatedStatus: CallbackStatus = type === "accept" ? "ACKNOWLEDGED" : "INITIATED";

        ctx.patchState({ loading: true });

        return this.callbackService.updateStatus(callbackId, { status: updatedStatus, extras })
            .pipe(tap({
                next: res => ctx.dispatch(new AgentViewCallbackActions.RespondToCallbackSuccess(type, res?.result)),
                error: (e: unknown) => ctx.dispatch(new AgentViewCallbackActions.RespondToCallbackFail(type, e))
            }));
    }


    @Action(AgentViewCallbackActions.RespondToCallbackSuccess)
    RespondToCallbackSuccess(ctx: StateContext<AgentViewCallbackResponseStateModel>, action: AgentViewCallbackActions.RespondToCallbackSuccess) {
        ctx.patchState({ loading: false });

        const { type, payload } = action;

        if (type === "accept") {
            return ctx.dispatch([
                new CustomerCallbackActions.SetCallbackData(payload),
                new AddSuccessResponseNotification({
                    success: true,
                    message: "Successfully accepted callback ticket."
                })
            ]);
        }
    }


    @Action(AgentViewCallbackActions.RespondToCallbackFail)
    RespondToCallbackFail(ctx: StateContext<AgentViewCallbackResponseStateModel>, action: AgentViewCallbackActions.RespondToCallbackFail) {
        ctx.patchState({ loading: false });

        const { type, error } = action;
        const errorMessage = Utils.Helpers.findError(error, "");

        if (type === "accept") {
            return ctx.dispatch(new AddSuccessResponseNotification({
                success: false,
                message: `Failed to accept callback ticket. ${errorMessage}`
            }));
        }

    }

}   