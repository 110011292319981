import { Utils } from "src/app/Utils";


export class Add {
    static readonly type = Utils.Helpers.Type("[WhatsappNotification] Add");
    constructor(public id: string) { }
}

export class Remove {
    static readonly type = Utils.Helpers.Type("[WhatsappNotification] Remove");
    constructor(public id: string) { }
}

export class Clear {
    static readonly type = Utils.Helpers.Type("[WhatsappNotification] Clear");
}