import { Utils } from "src/app/Utils";
import { Callback, CallbackExtras } from "../../types/callback-response.interface";
import { UpdateCallbackStatusPayload } from "../../types/callback-status.type";


export class UpdateExtras {
    static readonly type = Utils.Helpers.Type("[Update Callback] Update extras");
    constructor(public callbackId: string, public payload: CallbackExtras) { }
}

export class UpdateStatusAndExtras {
    static readonly type = Utils.Helpers.Type("[Update Callback] Update status and extras");
    constructor(public callbackId: string, public payload: UpdateCallbackStatusPayload) { }
}

export class UpdateSuccess {
    static readonly type = Utils.Helpers.Type("[Update Callback] Update success");
    constructor(public payload: Callback) { }
}

export class UpdateFail {
    static readonly type = Utils.Helpers.Type("[Update Callback] Update fail");
    constructor(public error: unknown) { }
}