import { Pipe, PipeTransform } from '@angular/core';
import { DataHandler } from '../../data-handler/data-handler';

const DEFAULT_COLOR_INDICATORS = {
  dark_green: ["excellent", "great"],
  green: ["good", "normal", "high", "yes"],
  red: ["bad", "very low", "very poor", "very bad", "no", "critically low", "critical"],
  orange: ["fair to poor", "average", "poor", "low"],
};

const REVERSE_INDICATORS = {
  dark_green: ["very low"],
  green: ["low", "normal", "no"],
  red: ["very high", "critically high", "yes"],
  orange: ["high"],
};

@Pipe({ name: 'wellnessColorIndicator' })
export class WellnessColorIndicatorPipe implements PipeTransform {

  transform(value: string, reverseIndicator: "default" | "reverse" = 'default'): string {
    if (!value || value === "no data") {
      return;
    }

    const colorIndicators = reverseIndicator === "reverse" ? REVERSE_INDICATORS : DEFAULT_COLOR_INDICATORS;
    return getColorCode(value, colorIndicators);
  }

}


const DEFAULT_SIMPLE_COLOR_INDICATORS = {
  green: ["excellent", "great", "good", "normal", "high", "yes"],
  red: ["bad", "very low", "very poor", "very bad", "no", "critically low", "critical"],
  orange: ["fair to poor", "average", "poor", "low"],
};

const REVERSE_SIMPLE_INDICATORS = {
  dark_green: ["very low"],
  green: ["very low", "low", "normal", "no"],
  red: ["very high", "critically high", "yes"],
  orange: ["high"],
};


@Pipe({ name: 'simpleWellnessColorIndicator' })
export class SimpleWellnessColorIndicatorPipe implements PipeTransform {

  transform(value: string, reverseIndicator: "default" | "reverse" = 'default'): string {
    if (!value || value === "no data") {
      return;
    }

    const colorIndicators = reverseIndicator === "reverse" ? REVERSE_SIMPLE_INDICATORS : DEFAULT_SIMPLE_COLOR_INDICATORS;
    return getColorCode(value, colorIndicators);
  }

}

const getColorCode = (strValue: string, colorIndicators: { [color: string]: string[] }) => {
  for (const color in colorIndicators) {
    const indicatorWords: string[] = colorIndicators[color];

    for (const word in indicatorWords) {
      if (strValue?.toString()?.toLowerCase()?.includes(indicatorWords[word])) {
        const colorCode = DataHandler.getColorCode(color);
        return colorCode
      }
    }
  }
}
