import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { DEFAULT_HTTP_OPTIONS } from 'src/app/constants';
import { MigrationPayload } from '../components/level-up/store/types/migrate-level.interfaces';
import { GeneratedOrderResult } from '../../store/interfaces/generate-order.interface';
import { CheckMigrationResponse } from '../../store/types/check-migration-response.interface';

@Injectable({
    providedIn: 'root'
})
export class MigrationService {

    constructor(private http: HttpClient) { }

    migrateProduct(payload: MigrationPayload, serviceId: string) {
        const url = `${environment.main_proxy}/new-service-proxy/service/${serviceId}/_migrate`;
        return this.http.patch<GeneratedOrderResult>(url, payload, DEFAULT_HTTP_OPTIONS);
    }

    singleLevelMigration(payload: MigrationPayload, serviceId: string) {
        const url = `${environment.main_proxy}/new-service-proxy/service/${serviceId}/_level-migration-mobile-vas`;
        return this.http.post<GeneratedOrderResult>(url, payload, DEFAULT_HTTP_OPTIONS);
    }

    checkMigration(serviceId: string) {
        const url = `${environment.main_proxy}/new-service-proxy/service/${serviceId}/_check-migration`;
        return this.http.get<CheckMigrationResponse>(url);
    }
}
