import { Injectable } from "@angular/core";
import { Store } from "@ngxs/store";
import { SnowflakeSelectOption } from "src/app/shared/elements/rain-forms/elements/snowflake-select/interfaces/rain-select-option.interface";
import { NewTicketOptions } from "./assets/new-ticket-options.model";
import { AllOrderSelectors } from "src/app/customer-info-summary-page/store/selectors/all-order.selectors";
import { FilteredServiceSelectors } from "src/app/customer-info-summary-page/store/selectors/filtered-service.selectors";


@Injectable({
    providedIn: 'root'
})
export class NewTicketOptionsService {


    constructor(private store: Store) {
    }

    newTicketOptionMap = {
        billing: new NewTicketOptions('billing support'),
        delivery: new NewTicketOptions('delivery support'),
        rica: new NewTicketOptions('rica support'),
        sales: new NewTicketOptions('sales support', 'order number', this.getCorrectOrders.bind(this)),
        network: new NewTicketOptions('network support', 'MSISDN with network issues', this.getCurrentSimOptions.bind(this)),
        "SIM swap": new NewTicketOptions('sim support', 'SIM to swap', this.getCurrentSimOptions.bind(this)),
        "cancel SIM": new NewTicketOptions('cancellation support', 'SIM to cancel', this.getCurrentSimOptions.bind(this)),
        optimize: new NewTicketOptions('optimization support', 'MSISDN to optimize', this.getCurrentSimOptions.bind(this)),
        onboarding: new NewTicketOptions("onboarding support"),
        "wa-onboarding": new NewTicketOptions("wa-onboarding support"),
        orders: new NewTicketOptions('order support', 'order number', this.getCorrectOrders.bind(this)),
        payment: new NewTicketOptions("payment support"),
        general: new NewTicketOptions('general support'),
        MNP: new NewTicketOptions('mnp escalations'),
        nvidia: new NewTicketOptions('nvidia support'),
        "retail customer engagement": new NewTicketOptions('retail customer engagement support'),
        "cpe collections": new NewTicketOptions('cpe collections support')
    }

    private getCorrectOrders() {
        const correctOrders = this.store.selectSnapshot(AllOrderSelectors.getFilteredOrders);
        return correctOrders.map(order => {
            return { label: order?.order_number, value: `ORDER #${order?.order_number}` };
        });
    }

    private getCurrentSimOptions(): SnowflakeSelectOption[] {
        const services = this.store.selectSnapshot(FilteredServiceSelectors.getAgeFilteredServices);
        return services.map(service => {
            return {
                label: `${service?.name} - ${service?.service_reference}`,
                value: NewTicketOptions.getMsisdnPostfix(service?.service_reference)
            }
        });
    }

    getOptions(ticketName: string): NewTicketOptions {
        return this.newTicketOptionMap[ticketName];
    }

    getTitles() {
        return Object.values(this.newTicketOptionMap)
            .map(item => item.title);
    }


    getNewTicketNames() {
        return Object.keys(this.newTicketOptionMap);
    }
}
