import { ShortTicket } from "src/app/shared/customer-ticket/interfaces/customer-ticket.interface";

const SPECIFIC_NAMES = ["WebCallMe", "SIM swap", "cancel SIM"] as const;

export function getTicketMainSubject(shortTicket: ShortTicket) {
  if (!shortTicket?.name) return null;

  const subject = shortTicket.name;



  for (const name of SPECIFIC_NAMES) {
    if (subject.includes(name)) {
      return name;
    }
  }

  if(subject.startsWith("rain ") && subject.includes("support")){
    const parts = subject.split(" ");
    const i = parts.findIndex(item => item === "support");
    const topic = parts[i - 1];
    return topic;
  }

  if (subject.includes("-")) {
    const parts = subject.split("-");
    const mainPart = parts[1]?.split(" ")[0];
    return mainPart?.toLowerCase();
  }

  else {
    const parts = subject.split(" ");
    const mainPart = parts[0] + " " + parts[1];
    return mainPart;
  }


}
