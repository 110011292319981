import { Component, Input } from '@angular/core';

@Component({
  selector: 'sf-form-row',
  templateUrl: './form-row.component.html',
  styleUrls: ['./form-row.component.scss']
})
export class FormRowComponent {

  @Input() hasError = false;
  @Input() label = "";
  @Input() showMultipleErrors = false;

}
