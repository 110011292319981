import { Component, OnDestroy, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { ToggleModalByName } from 'src/app/core/store/actions/modal.actions';
import { AgentCallbackSelector } from './view-model/agent-callback.selector';
import { Observable } from 'rxjs';
import { UpcomingCallbackViewModel } from './view-model/upcoming-callback-view-model.interface';
import { Callback } from '../../store/types/callback-response.interface';
import { Navigate } from '@ngxs/router-plugin';
import { CUSTOMER_INFO_SUMMARY_ROUTE } from 'src/app/constants';
import { AgentCallbackActions } from '../../store/actions/agent-callback-actions';
import { SFQueryParams } from 'src/app/shared/interfaces/sf-query-params.interface';
import { AddSuccessResponseNotification } from 'src/app/core/store/actions/notifications.actions';
import { ModalWindowService } from 'src/app/shared/modal-window/modal-window.service';
import { FetchDifferentCustomerDetails } from 'src/app/customer-info-summary-page/store/actions/customer.actions';
import { NavigationExtras, Router } from '@angular/router';

@Component({
    selector: 'sf-upcoming-callback-modal',
    templateUrl: './upcoming-callback-modal.component.html',
    styleUrls: ['./upcoming-callback-modal.component.scss']
})
export class UpcomingCallbackModalComponent implements OnInit, OnDestroy {

    @Select(AgentCallbackSelector.getViewModel) viewModel$: Observable<UpcomingCallbackViewModel>;

    constructor(private store: Store,
        private router: Router,
        private modal: ModalWindowService) { }


    ngOnInit(): void {
        this.store.dispatch(new AgentCallbackActions.FetchInCtx());
    }

    async gotoCustomerPage(selectedCallback: Callback) {
        const { status, id: callbackId, recipient_ref: customerEmail, external_ref: ticketHexId } = selectedCallback ?? {};

        if (status === "PENDING") {
            const confirmation = this.showIsPendingMessage();
            if (!confirmation) {
                return;
            }
        }

        if (!customerEmail) {
            this.store.dispatch(new AddSuccessResponseNotification({
                success: false,
                message: "Failed to navigate to the customer page. Email field is missing."
            }))
            return;
        }

        this.navigateToCustomerPage({ email: customerEmail, callbackId, ticketHexId });

        this.onClose();
    }

    private navigateToCustomerPage({ email, callbackId, ticketHexId }: { email: string, callbackId: string, ticketHexId: string }) {
        const currentUrl = this.router.url;
        const isCustomerInfoPage = currentUrl.includes(CUSTOMER_INFO_SUMMARY_ROUTE);

        const queryParams: SFQueryParams = { callbackId, ticketHexId };
        const navigationExtras: NavigationExtras = { queryParamsHandling: 'merge' };

        if (isCustomerInfoPage) {
            this.store.dispatch(new FetchDifferentCustomerDetails(email, queryParams, navigationExtras));
        }
        else {
            this.store.dispatch(new Navigate([CUSTOMER_INFO_SUMMARY_ROUTE, email], queryParams, navigationExtras));
        }
    }

    private async showIsPendingMessage() {
        const message = "Please note that this callback is pending the response from an agent. Make sure that a different agent has not already contacted the customer. Are you sure you want to continue?";
        return this.modal.showConfirmation(message);
    }

    loadMore() {
        this.store.dispatch(new AgentCallbackActions.FetchNextPage());
    }

    onClose() {
        this.store.dispatch(new ToggleModalByName("upcoming_callback_modal", false));
    }

    ngOnDestroy(): void {
        this.onClose();
    }

}
