import { Selector, createSelector } from "@ngxs/store";
import { SimProductSelector } from "src/app/customer-data-components/sim-details/store/selectors/sim-product.selectors";
import { Customer, CustomerService, ServiceAndOrder } from "src/app/shared/interfaces/smartsub-data.interface";
import { CustomerInfoState } from "../state/customer-info.state";
import { PaymentType } from "src/app/core/store/interfaces/rain-product.interface";
import { RainPaymentType } from "src/app/core/store/interfaces/product-catalog.interfaces";
import { CUSTOMER_SERVICE_STATUS, CustomerServiceStatus } from "src/app/shared/constants/service-status.constant";
import { CustomerOrder } from "src/app/customer-data-components/customer-order/customer-order-card/assets/customer-order.interface";
import { Dictionary } from "src/app/shared/interfaces/dictionary.interface";
import { Utils } from "src/app/Utils";
import { SFValidators } from "src/app/shared/functions/sf-validators";
import { AccountState } from "src/app/shared/interfaces/account-status.interface";
import { ExtendedServiceSelectors } from "./extended-service.selector";
import { AllOrderSelectors } from "./all-order.selectors";
import { LegacySelectors } from "./legacy.selectors";
import { RainoneSelectors } from "./rainone.selectors";



const DEFAULT_PAYMENT_TYPE: RainPaymentType = "upfront";

export class CustomerInfoSelector {

    /**
    * Checks if a customer is upfront or postpaid. Favours upfront.
    */
    @Selector([ExtendedServiceSelectors.getServices, SimProductSelector.getCombinedProducts])
    static getPaymentType(services: CustomerService[], products: { id: string, paymentType: PaymentType }[]): RainPaymentType {

        if (!services?.length) {
            return DEFAULT_PAYMENT_TYPE;
        }

        for (const service of services ?? []) {
            const product = products?.find(p => p?.id === service?.product_id);

            const activeServiceStates: CustomerServiceStatus[] = [
                CUSTOMER_SERVICE_STATUS["Active"],
                CUSTOMER_SERVICE_STATUS["Service Suspended"],
            ];

            const hasActivePostpaidService = product?.paymentType === "postpaid" && activeServiceStates.includes(service?.status);
            if (hasActivePostpaidService) {
                return "postpaid";
            }
        }

        return DEFAULT_PAYMENT_TYPE;
    }

    @Selector([CustomerInfoSelector.getPaymentType])
    static isUpfront(paymentType: RainPaymentType) {
        return paymentType === "upfront";
    }

    @Selector([ExtendedServiceSelectors.getServices, AllOrderSelectors.getOrders])
    static getServicesAndOrdersMappedByID(services: CustomerService[], orders: CustomerOrder[]): Dictionary<ServiceAndOrder> {
        if (!services?.length) {
            return null;
        }

        const ordersMap = Utils.Mappers.toHashMapV2(orders, "id");

        return services.reduce((serviceAndOrderDict, service) => {
            const { id, order_details } = service ?? {};
            const order = ordersMap?.[order_details?.order_id];

            serviceAndOrderDict[id] = {
                service,
                order
            }

            return serviceAndOrderDict;
        }, <Dictionary<ServiceAndOrder>>{});

    }

    @Selector([CustomerInfoState.getCustomer])
    static hasBusinessDetails(customer: Customer) { return !!customer?.company_name }

    @Selector([CustomerInfoState.getCustomer])
    static getBillCycleSpecDetail(customer: Customer) { return customer?.bill_cycle_spec_detail; }

    @Selector([CustomerInfoState.getCustomer])
    static hasBillingCycleSpec(customer: Customer): boolean {
        return SFValidators.isDefined(customer?.bill_cycle_spec_detail?.bill_cycle_spec);
    }

    @Selector([CustomerInfoState.getAccountState, ExtendedServiceSelectors.getServices])
    static getDerivedAccountState(accountState: AccountState, services: CustomerService[]) {
        const hasSuspendedService = services?.some(service => service?.status === CUSTOMER_SERVICE_STATUS['Service Suspended']);
        return hasSuspendedService ? "SUSPENDED" : accountState;
    }

    static hasOneOfTheseAccountStates(accountStates: AccountState[]) {
        return createSelector(
            [CustomerInfoSelector.getDerivedAccountState],
            (derivedState) => accountStates.includes(derivedState)
        )
    }

    @Selector([
      RainoneSelectors.has5gRainoneOrderOrService,
      RainoneSelectors.has5gRainone101OrderOrService,
      LegacySelectors.has5gLegacyService
    ])
    static has5gService(
      has5gRainoneOrderOrService: boolean,
      has5gRainone101OrderOrService: boolean,
      has5gLegacyService: boolean
      ) {
      return has5gLegacyService || has5gRainone101OrderOrService || has5gRainoneOrderOrService
    }
}
