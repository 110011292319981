import { Utils } from "src/app/Utils";
import { SfWarningType } from "../types/sf-warning.type";

export class AddSFWarning {
  static readonly type = Utils.Helpers.Type('[Warnings: Add] Add warning');
  constructor(public warningType: SfWarningType) { }
}

export class RemoveSFWarning {
  static readonly type = Utils.Helpers.Type('[Warnings: Remove] Remove warning');
  constructor(public warningType: SfWarningType) { }
}
