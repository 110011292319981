import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PostTicketModalOptions } from 'src/app/shared/services/post-ticket/post-ticket-modal-options.model';
import { NvidiaModalOptions } from './assets/nvidia-modal-options';
import { Store } from '@ngxs/store';
import { PostTicketService } from 'src/app/shared/services/post-ticket/post-ticket.service';
import { FormHelper } from 'src/app/Utils/helpers';
import { ToggleModalByName } from 'src/app/core/store/actions/modal.actions';
import { InteractionTicketsState } from '../../store/state/interaction-tickets.state';
import { ChatJsonNote } from '../components/chat-json-note/assets/chat-json-note.interface';
import { CustomerInfoState } from 'src/app/customer-info-summary-page/store/state/customer-info.state';
import { GooglePlacesAddress, GooglePlacesResponseData } from 'src/app/shared/elements/rain-forms/elements/snowflake-address-search/assets/google-places-address.interface';
import { CustomerAddress } from 'src/app/shared/interfaces/smartsub-data.interface';

@Component({
  selector: 'sf-nvidia-post-close-modal',
  templateUrl: './nvidia-post-close-modal.component.html',
  styleUrls: ['./nvidia-post-close-modal.component.scss']
})
export class NvidiaPostCloseModalComponent extends FormHelper implements OnInit {

  form: FormGroup;
  modalOptions: PostTicketModalOptions;
  files: File[]= [];
  inputAddress: GooglePlacesAddress;
  address: CustomerAddress;
  hasScreenshots=false;

  readonly queryOptions= NvidiaModalOptions.QUERY_OPTIONS;
  readonly ticketOutcomeOptions= NvidiaModalOptions.TICKET_OUTCOME_OPTIONS;
  readonly platformOptions=NvidiaModalOptions.PLATFORM_OPTIONS;
  readonly queryOutcomeOptions=NvidiaModalOptions.QUERY_OUTCOME_OPTIONS;
  readonly homeNetworkTypeOptions=NvidiaModalOptions.HOME_NETWORK_TYPE_OPTIONS;
  readonly connectionTechnologyOptions = NvidiaModalOptions.CONNECTION_TECHNOLOGY_OPTIONS;
  readonly gameExperienceOptions = NvidiaModalOptions.RATE_GAME_EXPERIENCE;

  constructor(private formBuilder: FormBuilder, private store: Store, private postTicketService: PostTicketService) {
    super();
}

  ngOnInit(): void {
    this.createForm();
    this.InitForm(this.form);
    this.modalOptions = new PostTicketModalOptions();
  }


  createForm() {
    this.form = this.formBuilder.group({
        date_time_played:this.formBuilder.control('', Validators.compose([Validators.required])),
        game_played: this.formBuilder.control('', Validators.compose([Validators.required])),
        game_played_address: this.formBuilder.control('', Validators.compose([Validators.required])),
        query: this.formBuilder.control('', Validators.compose([Validators.required])),
        screenshotsCheckbox:this.formBuilder.control(false),
        attachmentsUploader: this.formBuilder.control(null),
        ISP: this.formBuilder.control('', Validators.compose([Validators.required])),
        home_network_type:this.formBuilder.control('', Validators.compose([Validators.required])),
        connection_technology:this.formBuilder.control('', Validators.compose([Validators.required])),
        ticket_outcome: this.formBuilder.control('', Validators.compose([Validators.required])),
        platform: this.formBuilder.control('', Validators.compose([Validators.required])),
        query_outcome: this.formBuilder.control('', Validators.compose([Validators.required])),
        game_experience: this.formBuilder.control('', Validators.compose([Validators.required])),
        comments: this.formBuilder.control('')
    })
}

onOptionSelect(formField: string, value: string) {
  this.getControl(formField).patchValue(value);
}


private getFormattedData() {
  const {  date_time_played, game_played, game_played_address, query, ISP, home_network_type,connection_technology,ticket_outcome, platform, query_outcome ,game_experience, comments } = this.form?.getRawValue() ?? {};
  const usersEmail = this.store.selectSnapshot(
      CustomerInfoState.getCustomerEmail
  );

  return {
      'customer email': usersEmail,
      "date/ time played": date_time_played,
      "game played": game_played,
      "game played address":game_played_address ,
      "query": [query],
      "Screenshot of the in-game stats":this.files,
      "ISP": ISP,
      "connection_technology": connection_technology,
      "ticket outcome":ticket_outcome,
      "platform":platform,
      "query outcome":query_outcome,
      "home network type":home_network_type,
      "game experience":game_experience,
      "comments": comments
  }
}

attachFileData(files: File[]) {
  this.files = [...this.files, ...files];
}

onAddressSelected(data: GooglePlacesResponseData) {
  this.inputAddress = data?.address;
}

setAddress(address: CustomerAddress) {
  this.address = address;
}

toggleScreenShots(){
  this.hasScreenshots = !this.hasScreenshots
  this.getControl('screenshotsCheckbox').setValue(this.hasScreenshots)  
}

onSend() {
  const data = this.getFormattedData();
  const payload: ChatJsonNote = {
      message: "nvidia post close form",
      detail: data,
      type: 'nvidia_form'
  }
  const hexId = this.store.selectSnapshot(InteractionTicketsState.getSelectedHexId);
  this.postTicketService.sendPostTicketPayload(
      hexId, payload, this.modalOptions, this.close.bind(this)
  );
}

  close() {
    this.store.dispatch(new ToggleModalByName('nvidia_post_close_modal', false));
}


  ngOnDestroy(): void {
    this.close();
}

}
