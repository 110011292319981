import { Component, EventEmitter, Input, Output, ChangeDetectionStrategy, ViewChild } from '@angular/core';
import { SnowflakeSelectOption } from '../rain-forms/elements/snowflake-select/interfaces/rain-select-option.interface';
import { KeywordSearchEvent } from './assets/keyword-search-event.interface';
import { SearchBarComponent } from '../../components/search-bar/search-bar.component';

@Component({
  selector: 'sf-keyword-search',
  templateUrl: './keyword-search.component.html',
  styleUrls: ['./keyword-search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class KeywordSearchComponent {

  @ViewChild(SearchBarComponent) searchBarComponent: SearchBarComponent;

  @Output() search = new EventEmitter<KeywordSearchEvent>();

  private _searchOptions: SnowflakeSelectOption[] = [];
  @Input() set searchOptions(options: SnowflakeSelectOption[]) {
    if (!options?.length) {
      return;
    }

    this._searchOptions = options;
    this.selectedOption = options.find(o => o?.selected);
  }
  get searchOptions() {
    return this._searchOptions;
  }

  @Input() delay = 500;
  @Input() emitOnSetOption = true;

  private _previousOption: SnowflakeSelectOption = null;
  selectedOption: SnowflakeSelectOption;

  private _searchTerm = "";

  onSetOption(option: SnowflakeSelectOption) {
    this._previousOption = this.selectedOption;
    this.selectedOption = option;

    if (this.emitOnSetOption) {
      this.onSearch(this._searchTerm);
    }
  }

  onSearch(searchTerm: string) {
    this._searchTerm = searchTerm?.trim();
    this.search.next({
      key: this.selectedOption.value,
      previousKey: this._previousOption?.value,
      searchTerm: this._searchTerm
    });
  }

  setOptionAndSearchTerm<T extends SnowflakeSelectOption = SnowflakeSelectOption>(searchTerm: string, option: T) {
    this._searchTerm = searchTerm;
    this.searchBarComponent.setSearchText(searchTerm);
    this.onSetOption(option);
  }

}
