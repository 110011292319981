import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RespondToTicketPayload } from '../store/types/respond-to-ticket-payload.interface';
import { environment } from 'src/environments/environment';
import { DEFAULT_HTTP_OPTIONS } from 'src/app/constants';

@Injectable({
    providedIn: 'root'
})
export class TicketResponseService {

    constructor(private http: HttpClient) { }

    respondToTicket(payload: RespondToTicketPayload) {
        const { type, agentTeam, agentEmail, hexId } = payload;

        const url = `${environment.snowflake_api_url}/ticket/${type}/${agentTeam}/${agentEmail}/${hexId}`;

        return this.http.post(url, null, DEFAULT_HTTP_OPTIONS);
    }

}