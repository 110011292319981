import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MapSearchComponent } from './map-search.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

@NgModule({
    declarations: [MapSearchComponent],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        GooglePlaceModule
    ],
    exports: [
        MapSearchComponent
    ],
    providers: [],
})
export class MapSearchModule { }
