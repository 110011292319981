import { Selector, createSelector } from "@ngxs/store";
import { Customer } from "src/app/shared/interfaces/smartsub-data.interface";
import { CustomerInfoStateModel } from "../interfaces/customer-info-state.model";
import { CustomerInfoState } from "../state/customer-info.state";
import { CustomerOrder } from "src/app/customer-data-components/customer-order/customer-order-card/assets/customer-order.interface";
import { DataHandler } from "src/app/shared/data-handler/data-handler";


export class AllOrderSelectors {

    @Selector([CustomerInfoState])
    static getMaxOrderDays(state: CustomerInfoStateModel) { return state.max_order_days; }

    @Selector([CustomerInfoState.getCustomer])
    static getOrders(customer: Customer): CustomerOrder[] { return customer?.orders ?? []; }

    static getOrderByOrderId(orderId: string) {

        return createSelector(
            [AllOrderSelectors.getOrders],
            (orders: CustomerOrder[]): CustomerOrder | null => {
                if (!orderId || !orders?.length) {
                    return null;
                }

                return orders.find(order => order?.id === orderId);
            }
        );
    }

    @Selector([
        AllOrderSelectors.getMaxOrderDays,
        AllOrderSelectors.getOrders
    ])
    static getFilteredOrders(maxOrderDays: number, orders: CustomerOrder[]) {
        if (!orders?.length) {
            return [];
        }

        return orders.filter(order => {
            if (!order?.order_date) {
                return false;
            }
            const daysOld = DataHandler.getDiffInTime("now", order.order_date, "days");
            return daysOld <= maxOrderDays;
        });
    }
}