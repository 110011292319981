<div [ngClass]="{ active: (active$ | async) }" class="wrapper pointer"
  [class]="(isOpenTicketForGroup$ | async) ? 'has-open-ticket': ''">
  <div class="content" *ngIf="(shortTicket$ | async) as shortTicket">
    <div>
      <p class="status">
        status: <span>{{ getTicketStatus(shortTicket?.state_id) }}</span>
      </p>
      <h3 class="text text-darkest-grey header">{{ shortTicket?.name }}</h3>
      <p class="font-normal text-dark-grey content-text">
        external id:
        <span class="text text-blue">
          {{ (shortTicket?.external_id) }}
        </span>
      </p>

      <p class="font-normal content-text text-dark-grey">
        created on:
        <span class="text text-blue">
          {{shortTicket?.inserted_at | date: "d MMM, y, h:mm a"}}
        </span>
      </p>

      <p class="font-normal content-text text-dark-grey">
        updated on:
        <span class="text text-blue">
          {{shortTicket?.updated_at | date: "d MMM, y, h:mm a"}}
        </span>
      </p>

    </div>
  </div>
</div>
