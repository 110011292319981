import { Selector } from "@ngxs/store";
import { AgentNotificationState } from "../store/state/agent-notifications.state";
import { AgentNotification } from "../store/types/agent-notification.type";
import { ModalState } from "src/app/core/store/state/modal.state";

export interface AgentNotificationsViewModel {
    filteredNotifications: AgentNotification[];
    nonViewedCount: number;
    open: boolean;
    isCallbackModalOpen: boolean;
}


export class AgentNotificationsSelector {

    @Selector([
        AgentNotificationState.getFilteredNotifications,
        AgentNotificationState.nonViewedCount,
        AgentNotificationState.isOpen,
        ModalState.isModalOpen("upcoming_callback_modal")
    ])
    static getViewModel(
        filteredNotifications: AgentNotification[],
        nonViewedCount: number,
        open: boolean,
        isCallbackModalOpen: boolean
    ): AgentNotificationsViewModel {

        return {
            filteredNotifications,
            nonViewedCount,
            open,
            isCallbackModalOpen
        }

    }
}
