import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MsalBroadcastService, MsalGuardConfiguration, MsalService, MSAL_GUARD_CONFIG, } from '@azure/msal-angular';
import { AccountInfo, AuthenticationResult, EventMessage, EventType, InteractionStatus, } from '@azure/msal-browser';
import { Subject, Subscription } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { AuthService } from './auth/auth.service';
import { APP_NAME } from './constants';
import { NavigationEnd, Router } from '@angular/router';
import { RouterEventControllerService } from './shared/services/router-event-controller/router-event-controller.service';
import { RainAgent } from './shared/components/agent/rain-agent.service';
import { Store } from '@ngxs/store';
import { navActions } from './core/store/actions/nav-actions';
import { SetInitialAzureAuthResult } from './core/store/actions/azure-auth.actions';
import { ProductActions } from './core/store/actions/product-actions';
import { FetchAllGroups } from './core/store/actions/groups.actions';
import { ResetTimer, StartTimer } from './main-modals-container/store/actions/time-out.actions';
import { UserActionsService } from './shared/services/user-actions.service';
import { ProductCatalogActions } from './core/store/actions/product-catalog-actions';
import { FirebaseFeatureFlagsActions } from './core/store/actions/firebase-feature-flags-actions';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
    title = APP_NAME;

    isIframe = false;
    loginDisplay = false;
    private readonly _destroying$ = new Subject<void>();
    private routerEventSub: Subscription;

    azureDataIsSet = false;

    constructor(
        @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
        private msalAuthService: MsalService,
        private msalBroadcastService: MsalBroadcastService,
        private myAuthService: AuthService,
        private routerEventService: RouterEventControllerService,
        private router: Router,
        private rainAgent: RainAgent,
        private store: Store,
        private userActionsService: UserActionsService,
    ) { }

    ngOnInit(): void {
        this.store.dispatch([
            new ProductActions.GetAll(),
            new ProductCatalogActions.Fetch(),
            new FetchAllGroups(),
            new FirebaseFeatureFlagsActions.FetchIfNotLoadingOrLoaded(),
        ]);


        this.isIframe = window !== window.parent && !window.opener;

        this.msalBroadcastService.inProgress$
            .pipe(
                filter(
                    (status: InteractionStatus) =>
                        status === InteractionStatus.None,
                ),
                takeUntil(this._destroying$),
            )
            .subscribe(() => {
                this.setLoginDisplay();
            });

        this.msalBroadcastService.msalSubject$
            .pipe(
                filter(
                    (msg: EventMessage) =>
                        msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS,
                ),
                takeUntil(this._destroying$),
            )
            .subscribe((result: EventMessage) => {
                const payload = result?.payload as AuthenticationResult;
                if (this.azureDataIsSet || !payload) {
                    return;
                }

                this.myAuthService.setPayloaddetails(payload);
                this.initAgentDetails(payload?.account);
                this.store.dispatch(new SetInitialAzureAuthResult(payload));
                this.azureDataIsSet = true;
            });

        this.initializeRoutingEventListener();

        this.initOnUserInteraction();
        this.store.dispatch(new StartTimer());
    }

    private initOnUserInteraction() {
        this.userActionsService.getMainActions()
            .pipe(
                takeUntil(this._destroying$),
            )
            .subscribe({
                next: () => this.store.dispatch(new ResetTimer())
            });
    }

    initializeRoutingEventListener() {
        this.routerEventSub = this.router.events
            .pipe(
                takeUntil(this._destroying$),
            )
            .subscribe((result) => {
                if (!(result instanceof NavigationEnd)) return;
                this.routerEventService.handleRouteChange(this.router, result.url);
                this.store.dispatch(new navActions.UpdateURL(result.url));
            });
    }

    setLoginDisplay() {
        this.loginDisplay =
            this.msalAuthService.instance.getAllAccounts().length > 0;
    }

    private initAgentDetails(accountInfo: AccountInfo) {
        const { name, username, idTokenClaims } = accountInfo ?? {};
        this.rainAgent.setAgentDetails(
            name,
            username,
            idTokenClaims?.['roles'] ?? [],
        );
    }
    ngOnDestroy(): void {
        this._destroying$.next(null);
        this._destroying$.complete();
        if (this.routerEventSub) this.routerEventSub.unsubscribe();
    }
}
