import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { AddSuccessResponseNotification } from 'src/app/core/store/actions/notifications.actions';
import { Utils } from 'src/app/Utils';
import { AddCallbackActions } from '../actions/add-callback-actions';
import { CallbackService } from '../../services/callback.service';
import { AddKnownCustomerEvent } from 'src/app/shared/customer-ticket/store/actions/ticket-event.actions';
import { SA_LOCALE, SCHEDULED_CALLBACK } from 'src/app/constants';
import { formatDate } from "@angular/common";
import { ShowContinueButton } from 'src/app/interactions/store/actions/continue-flow.actions';
import { CoreState } from 'src/app/core/store/state/core.state';


interface AddCallbackStateModel {
    loading: boolean;
}
@State<AddCallbackStateModel>({
    name: 'sf_AddCallback_state',
    defaults: {
        loading: false
    }
})
@Injectable()
export class AddCallbackState {

    @Selector()
    static isLoading(state: AddCallbackStateModel) { return state.loading }

    constructor(private callbackService: CallbackService,
        private store: Store) {
    }

    @Action(AddCallbackActions.AddCallbackInCtx)
    AddCallbackInCtx(ctx: StateContext<AddCallbackStateModel>, action: AddCallbackActions.AddCallbackInCtx) {
        const { dateTime, hexId, customerEmail, extras } = action?.payload;
        const agentEmail = this.store.selectSnapshot(CoreState.getAgentEmail);
        const group = this.store.selectSnapshot(CoreState.getTeamRole);

        return ctx.dispatch(new AddCallbackActions.AddCallback({
            scheduled_date_time: dateTime,
            external_ref: hexId,
            user_ref: agentEmail,
            recipient_ref: customerEmail,
            group,
            extras
        }));
    }

    @Action(AddCallbackActions.AddCallback)
    AddCallback(ctx: StateContext<AddCallbackStateModel>, action: AddCallbackActions.AddCallback) {
        const { payload } = action;

        ctx.patchState({ loading: true });

        return this.callbackService.addCallback(payload)
            .pipe(tap({
                next: res => ctx.dispatch(new AddCallbackActions.AddCallbackSuccess(res?.result)),
                error: (e: unknown) => ctx.dispatch(new AddCallbackActions.AddCallbackFail(e))
            }));
    }


    @Action(AddCallbackActions.AddCallbackSuccess)
    AddCallbackSuccess(ctx: StateContext<AddCallbackStateModel>, action: AddCallbackActions.AddCallbackSuccess) {
        ctx.patchState({ loading: false });

        const { scheduled_date_time } = action?.payload;
        const time = formatDate(scheduled_date_time, "YYYY-MM-dd H:mm", SA_LOCALE);

        return ctx.dispatch([
            new AddKnownCustomerEvent({
                eventComment: `${SCHEDULED_CALLBACK} for ${time}`,
                reload: false
            }),
            new AddSuccessResponseNotification({
                success: true,
                message: `Successfully added callback for ${time}`
            }),
            new ShowContinueButton(true)
        ]);
    }


    @Action(AddCallbackActions.AddCallbackFail)
    AddCallbackFail(ctx: StateContext<AddCallbackStateModel>, action: AddCallbackActions.AddCallbackFail) {
        ctx.patchState({ loading: false });

        const error = Utils.Helpers.findError(action.error, '');
        return ctx.dispatch(new AddSuccessResponseNotification({
            success: false,
            message: `Failed to schedule callback. ${error}`
        }));

    }

}