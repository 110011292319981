<ng-container *ngIf="fullModal; else basic">
  <app-blank-card>

    <mat-icon class="default-cancel-icon" (click)="onClose()">close</mat-icon>

    <h3 class="header text text-blue">{{header}}</h3>

    <div class="dropzone-wrapper">
      <ngx-dropzone [multiple]="canBeMultiple" [accept]='acceptedFormats' class="dropzone" (change)="onSelect($event)">

        <ngx-dropzone-label *ngIf="!files?.length" class="center-container font-normal text-grey">
          <ng-content></ng-content>
        </ngx-dropzone-label>

        <ngx-dropzone-preview *ngFor="let f of files" [removable]="true" (removed)="onRemove(f)">
          <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
        </ngx-dropzone-preview>
      </ngx-dropzone>
    </div>

    <div class="bottom-button flex-content">
      <button class="rain-button default-button-size" [disabled]="files.length < 1"
        (click)="addAttachment()">attach</button>
    </div>

  </app-blank-card>
</ng-container>

<ng-template #basic>
  <div class="dropzone-wrapper">
    <ngx-dropzone [multiple]="canBeMultiple" [accept]='acceptedFormats' class="dropzone" (change)="onSelect($event)">

      <ngx-dropzone-label *ngIf="!files?.length" class="center-container font-normal text-grey">
        <ng-content></ng-content>
      </ngx-dropzone-label>

      <ngx-dropzone-preview *ngFor="let f of files" [removable]="true" (removed)="onRemove(f)">
        <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
      </ngx-dropzone-preview>
    </ngx-dropzone>
  </div>
</ng-template>
