import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-chat-header',
  templateUrl: './chat-header.component.html',
  styleUrls: ['./chat-header.component.scss']
})
export class ChatHeaderComponent implements OnInit {

  @Input() header: string;
  @Input() height = 3;

  headerHeight: string;

  ngOnInit(): void {
    this.headerHeight = `${this.height}rem`;
  }

}
