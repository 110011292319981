import { Component } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { SipPhoneService } from 'src/app/sip-phone/services/sip-phone.service';
import { SipPhoneState } from 'src/app/sip-phone/store/state/sip.state';

@Component({
  selector: 'sf-agent-voice-registered-icon',
  templateUrl: './agent-voice-registered-icon.component.html',
  styleUrls: ['./agent-voice-registered-icon.component.scss']
})
export class AgentVoiceRegisteredIconComponent {

  @Select(SipPhoneState.isUserAgentProcessing) isUserAgentProcessing$: Observable<boolean>;

  constructor(public sipPhone: SipPhoneService) { }


}
