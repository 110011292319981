import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { Subscription } from 'rxjs';
import { ModalOptions } from './assets/modal-options.interface';
import { ModalWindowService } from './modal-window.service';

@Component({
  selector: 'app-modal-window',
  templateUrl: './modal-window.component.html',
  styleUrls: ['./modal-window.component.scss']
})
export class ModalWindowComponent implements OnInit, OnDestroy {
  showModal = false;
  modalOptions: ModalOptions;
  showLoadingSpinner = false;

  private _spinnerSub: Subscription;
  private _modalWindowSub: Subscription;

  constructor(private modalWindowService: ModalWindowService,
    private store: Store) { }

  ngOnInit(): void {
    this._spinnerSub = this.modalWindowService.spinnerSub.subscribe(
      active => this.showLoadingSpinner = active
    );

    this._modalWindowSub = this.modalWindowService.modalWindowSub.subscribe(
      (options: ModalOptions) => {
        this.modalOptions = options;
        this.showModal = true;
      }
    )
  }

  onCancel() {
    if (this.modalOptions?.messageType === 'confirm') this.onResponse(false);
    else this.closeModal();
  }

  onResponse(response: boolean) {
    this.modalOptions.resolver(response);
    this.closeModal();
  }

  closeModal() {
    this.showModal = false;
  }

  onAction() {
    this.store.dispatch(this.modalOptions?.actions ?? []);
    this.closeModal();
  }

  ngOnDestroy(): void {
    if (this._modalWindowSub) this._modalWindowSub.unsubscribe();
    if (this._spinnerSub) this._spinnerSub.unsubscribe();
  }

}
