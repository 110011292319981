import { Utils } from 'src/app/Utils';

export class RefreshMaps {
  static readonly type = Utils.Helpers.Type('[Events: Maps] Refresh maps');
  constructor(public where?: string) { }
}

export class ContinueToAgentView {
  static readonly type = Utils.Helpers.Type('[Events: Continue] Continue to agent view');
}
