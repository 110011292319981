

export class SuccessDataResponse<T = string>{
  success: boolean;
  data: T;

  constructor(success: boolean, data: T = null) {
    this.success = success;
    this.data = data;
  }
}
