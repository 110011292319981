
export function getDataLoadingDefaultValues<T, E = string>(data: T = null) {
    return {
        loading: false,
        loaded: false,
        error: null,
        data,
    } as DataLoading<T, E>
}

export function getDataLoadingAndSilentlyLoadingValues<T, E = string>(data: T | null = null) {
    return {
        loading: false,
        silentlyLoading: false,
        loaded: false,
        error: null,
        data,
    } as DataLoadingAndSilentLoading<T, E>
}

export interface DataLoading<T = any, E = string> {
    loading: boolean;
    loaded: boolean;
    error: E;
    data: T;
}

export interface DataLoadingAndSilentLoading<T = unknown, E = string> extends DataLoading<T, E> {
    silentlyLoading: boolean;
}