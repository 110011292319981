import { Utils } from "src/app/Utils";


export class SetSelectedOrderId {
    static readonly type = Utils.Helpers.Type("[Customer Order] Set selected order id");
    constructor(public orderId: string) { }
}

export class OrderPageDestroyed {
    static readonly type = Utils.Helpers.Type("[Customer Order] Order Page Destroyed");
}
