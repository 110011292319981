import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaintenanceOverlayComponent } from './maintenance-overlay.component';


@NgModule({
    declarations: [
        MaintenanceOverlayComponent
    ],
    imports: [
        CommonModule
    ],
    exports: [
        MaintenanceOverlayComponent
    ]
})
export class MaintenanceOverlayModule { }
