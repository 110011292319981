import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AgentPhoneAvailableModalComponent } from './agent-phone-available-modal/agent-phone-available-modal.component';
import { AgentStatusModalComponent } from './agent-status-modal/agent-status-modal.component';
import { MatMenuModule } from '@angular/material/menu';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgxsModule } from '@ngxs/store';
import { AgentTicketToggleComponent } from './agent-ticket-toggle/agent-ticket-toggle.component';
import { AgentViewTicketResponseState } from './store/state/agent-view-ticket-response.state';
import { AgentViewTicketState } from './store/state/agent-view-ticket.state';
import { ToggleButtonModule } from '../../elements/rain-forms/elements/toggle-button/toggle-button.module';


const declarations = [
    AgentPhoneAvailableModalComponent,
    AgentStatusModalComponent,
    AgentTicketToggleComponent
]

@NgModule({
    declarations: [...declarations],
    imports: [
        CommonModule,
        ToggleButtonModule,
        NgxsModule.forFeature([
            AgentViewTicketState,
            AgentViewTicketResponseState,
        ]),
        MatMenuModule,
        MatSlideToggleModule,
        MatFormFieldModule,
        MatInputModule,
    ],
    exports: [...declarations]
})
export class AgentComponentModule { }
