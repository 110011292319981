import { DeviceType } from "src/app/sip-phone/store/types/device.type";


export const DEFAULT_DEVICE_TYPE: DeviceType = "sip_device" as const;
export const ACCEPTABLE_DEVICE_TYPES: DeviceType[] = ["sip_device", "softphone"];
export const MAX_EXTENSION_RANGE = 9999;
export const MIN_EXTENSION_RANGE = 1000;


export const DEFAULT_CALL_RESTRICTIONS = {
  caribbean: {
    action: "inherit"
  },
  did_us: {
    action: "inherit"
  },
  emergency: {
    action: "inherit"
  },
  international: {
    action: "inherit"
  },
  toll_us: {
    action: "inherit"
  },
  tollfree_us: {
    action: "inherit"
  },
  unknown: {
    action: "inherit"
  }
}

export const DEFAULT_MEDIA_SETTINGS = {
  encryption: {
    enforce_security: false,
    methods: []
  },
  audio: {
    codecs: [
      "PCMU",
      "PCMA"
    ]
  },
  video: {
    codecs: []
  }
}
