import { CustomerTicket } from "src/app/shared/customer-ticket/interfaces/customer-ticket.interface";
import { Utils } from "src/app/Utils";


export class FetchTicket {
    static readonly type = Utils.Helpers.Type('[Interactions: Note] Fetch ticket with notes');
    constructor(public hexId: string) { }
}

export class FetchTicketSuccess {
    static readonly type = Utils.Helpers.Type('[Interactions: Note] Fetch ticket with notes success');
    constructor(public payload: { data: CustomerTicket }) { }
}

export class FetchTicketFail {
    static readonly type = Utils.Helpers.Type('[Interactions: Note] Fetch ticket with notes fail');
    constructor(public error?: any) { }
}

export class SetTicket {
    static readonly type = Utils.Helpers.Type('[Interactions: Note] Set fresh ticket');
    constructor(public payload: CustomerTicket) { }
}

export class Clear {
    static readonly type = Utils.Helpers.Type('[Interactions: Note] Clear current note');
}

