import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { DataHandler } from 'src/app/shared/data-handler/data-handler';
import { Utils } from 'src/app/Utils';
import { SuccessLoaderStatus } from '../success-loader/success-loader-status.type';
import { AsyncSuccessLoaderConfig } from './async-success-loader-config.interface';

@Component({
  selector: 'sf-async-success-loader',
  templateUrl: './async-success-loader.component.html',
  styleUrls: ['./async-success-loader.component.scss']
})
export class AsyncSuccessLoaderComponent implements OnInit {

  @Input() size = "3.5rem";
  @Input() step: number | string = 1;
  @Input() config: AsyncSuccessLoaderConfig;

  canRetry = false;
  public status$: Observable<SuccessLoaderStatus> = null;

  constructor(private store: Store) { }

  ngOnInit(): void {
    if (!this.config) {
      throw new Error("No config was provided for AsyncSuccessLoaderComponent");
    }

    this.canRetry = DataHandler.isDefined(this.config.retryEvent);
    this.status$ = Utils.Helpers.determineSuccessLoaderStatus(this.config);
  }

  onRetry() {
    if (this.canRetry) {
      this.store.dispatch(this.config.retryEvent);
    }
  }



}
