import { Feature, FeatureCollection, Geometry, Point } from "geojson";
import { Units as TurfUnits, distance as TurfDistance } from "@turf/turf";
import { Dictionary } from "../../interfaces/dictionary.interface";
import { IAddress } from "../../elements/map/models/addressDetail.model";
import { CustomerAddress } from "../../interfaces/smartsub-data.interface";



export function getLngLatArray(coordinatesArray: number[][], reverseCoords = false) {
  return coordinatesArray.map(coords => {
    if (reverseCoords) {
      return new google.maps.LatLng(coords[1], coords[0]);
    }
    else {
      return new google.maps.LatLng(coords[0], coords[1]);
    }
  });
}

export function getDegreesBetweenTwoPoints(lngLatArray: google.maps.LatLng[]) {
  return google.maps.geometry.spherical.computeHeading(lngLatArray[0], lngLatArray[1]);
}

export function getPulastingDotSizes(map: google.maps.Map, maxSize: number) {
  const topRight = map.getBounds().getNorthEast();
  const bottomLeft = map.getBounds().getSouthWest();

  const distance = google.maps.geometry.spherical.computeDistanceBetween(topRight, bottomLeft);
  const ratio = distance / 1750;

  const newMaxSize = ratio * maxSize;
  const minSize = ratio * maxSize * 0.35;
  const incrementValue = newMaxSize * 0.15;

  return [newMaxSize, minSize, incrementValue];
}

export function addOffsetToLineCoordinates(lngLatArray: google.maps.LatLng[], offSetDegrees: number) {
  const spherical = google.maps.geometry.spherical;
  const [lngLat1, lngLat2] = lngLatArray;
  const currentDegrees = getDegreesBetweenTwoPoints(lngLatArray);
  const distance = spherical.computeDistanceBetween(lngLat1, lngLat2);
  const offsetValue = (offSetDegrees / (distance / 1000));
  const newLngLat = spherical.computeOffset(lngLat1, distance, currentDegrees + offsetValue);
  return [lngLat1, newLngLat];
}

export const filterFeaturesByProperty = <T extends Record<string, any>, P extends keyof T, V extends T[P]>
  (features: Array<Feature<Geometry, T>>, property: P, value: V) =>
  features?.filter(f => f?.properties?.[property] === value) ?? [];


/**
 *
 * @param units The unit type for the return value, default is set to meters.
 * @returns The distance in specified units
 */
export const calculateDistance = (coordinatesArray: Array<number[]>, units: TurfUnits = "meters") => {
  return Math.floor(
    TurfDistance(coordinatesArray[0], coordinatesArray[1], { units: units })
  );
}

export const calculateDistanceInMetersGM = (from: google.maps.LatLng, to: google.maps.LatLng) => {
  return google.maps.geometry.spherical.computeDistanceBetween(from, to);
}

export const updateDataLayerFeatures = <T extends Dictionary>(layer: google.maps.Data, features: Array<Feature<Point, T>>) =>
  updateDataLayerFeatureCollection(layer, { type: "FeatureCollection", features });

export const removeFeaturesFromLayer = (layer: google.maps.Data) => {
  layer.forEach(feature => layer.remove(feature));
  return layer;
}

export const updateDataLayerFeatureCollection = <FC extends FeatureCollection>(layer: google.maps.Data, featureCollection: FC) =>
  removeFeaturesFromLayer(layer)
    .addGeoJson(featureCollection);


export const mapGoogleAddressToCustomerAddress = (address: IAddress): CustomerAddress => {
  if (!address) {
    return null;
  }
  const { floorLevel, unitNumber, buildingName, streetNumber, streetName, suburb, province, postalCode, city, gps_coordinates } = address;

  return {
    floor_level: floorLevel,
    unit_number: unitNumber,
    building_name: buildingName,
    street_number: streetNumber,
    street_name: streetName,
    suburb,
    city,
    province,
    postal_code: postalCode,
    gps_coordinates
  }
}


