import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { DataHandler } from '../../data-handler/data-handler';
import { SuccessResponse } from '../../success-response-handler/success-response.model';

@Component({
  selector: 'sf-popup-modal',
  templateUrl: './popup-modal.component.html',
  styleUrls: ['./popup-modal.component.scss']
})
export class PopupModalComponent {

  @Output() close = new EventEmitter();

  @Input() sending = false;
  @Input() isOpen: boolean;
  @Input() successResponse: SuccessResponse;
  @Input() spinnerMessage = "sending..";
  @Input() limitHeight = false;
  @Input() overlayScroll = false;
  @Input() autoClose = true;
  @Input() hasCloseButton = true;
  @Input() zIndex: number;
  @Input() maxWidth: string;
  @Input() minWidth: string;
  @Input() width: string;

  @HostListener('click', ['$event'])
  private clickOutside(e: any) {
    const target: HTMLElement = e.target;

    if (target.classList.contains('is-open') && this.autoClose) {
      this.onClose();
    }
  }

  onClose() {
    this.close.next();
  }

  closeResponse() {
    this.successResponse = null;
  }

  getColorCode(success: boolean) {
    const colorChoice = success ? "green" : "red";
    return DataHandler.getColorCode(colorChoice);
  }

}
