import { Component } from '@angular/core';

@Component({
  selector: 'sf-modal-form-container',
  templateUrl: './modal-form-container.component.html',
  styleUrls: ['./modal-form-container.component.scss']
})
export class ModalFormContainerComponent {

}
