import { ConfigItem } from "src/app/customer-data-components/customer-order/customer-order-card/assets/customer-order.interface";
import { tryFn } from "./try-fn.functional";

export function getParentServiceIdFromOrderLine(configs: ConfigItem[]) {

  if (!configs?.length) {
    return
  }

  const { value } = configs?.find(config => config?.name === 'comments') ?? {}
  const { data, error } = tryFn(value, JSON.parse)

  if (error) {
    return null
  }

  return data?.parent_service_id ?? null
}

