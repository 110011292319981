import moment from "moment";
import { BillCyclePeriod } from "src/app/shared/interfaces/smartsub-data.interface";


export function getStartDateFromBillCycleDay(day: number) {
    const { date, month, year } = getDayMonthAndYear(moment());

    //Previous month
    if (day > date) {
        const previousYear = month === 0;
        return moment({
            date: day,
            month: previousYear ? 11 : month - 1,
            year: previousYear ? year - 1 : year
        })
    }

    //Same month as current month
    return moment({ day });
}

export function getBillCycleDates(cyclePeriod: BillCyclePeriod) {
    const { start, end } = cyclePeriod;
    const startDate = getStartDateFromBillCycleDay(start);
    const { month, year } = getDayMonthAndYear(startDate);

    //End of same month
    if (end > start) {
        return {
            start: startDate,
            end: moment({ date: end, month, year })
        }
    }

    const nextYear = month === 11;

    //Next month
    return {
        start: startDate,
        end: moment({
            date: end,
            month: nextYear ? 1 : month + 1,
            year: nextYear ? year + 1 : year
        })
    }
}

function getDayMonthAndYear(moment: moment.Moment) {
    return {
        date: moment.date(),
        month: moment.month(),
        year: moment.year()
    }
}