import { Component, OnDestroy, OnInit } from '@angular/core';
import { Actions, ofActionSuccessful, Store } from '@ngxs/store';
import { FormHelper } from 'src/app/Utils/helpers';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { getTicketMainSubject } from 'src/app/interactions/chat-interaction/default-post-ticket-modal/assets/ticket-subjects.function';
import { CustomerInfoSummaryService } from 'src/app/customer-info-summary-page/store/services/customer-info-summary.service';
import { PostTicketModalOptions } from 'src/app/shared/services/post-ticket/post-ticket-modal-options.model';
import { PostTicketService } from 'src/app/shared/services/post-ticket/post-ticket.service';
import { ToggleModalByName } from 'src/app/core/store/actions/modal.actions';
import { InteractionTicketsState } from '../../store/state/interaction-tickets.state';
import { NWDAFConnectedSiteState } from 'src/app/customer-data-components/sim-details/store/state/nwdaf-connected-site.state';
import { filter, takeUntil } from 'rxjs/operators';
import { NWDAFConnectedSiteActions } from 'src/app/customer-data-components/sim-details/store/actions/nwdaf-connected-site-actions';
import * as PostCloseOptions from './assets/cec-team-a-f-post-ticket-options.constant';
import { ChatJsonNote } from '../components/chat-json-note/assets/chat-json-note.interface';
import { SnowflakeSelectOption } from 'src/app/shared/elements/rain-forms/elements/snowflake-select/interfaces/rain-select-option.interface';
import { Dictionary } from 'src/app/shared/interfaces/dictionary.interface';
import { SimSelectors } from 'src/app/customer-data-components/sim-details/store/selectors/sim.selectors';


const FORM_KEYS = ["network", "system_issue", "delivery", "rain_one_query", "account_arrears", "billing_disputes"];

type ConnectedSiteData =
    {
        live_connection?: boolean,
        enodeb_id?: number | string,
        cell_id?: number
    } |
    {
        error: string
    }

@Component({
    selector: 'sf-cec-team-a-f-post-close-modal',
    templateUrl: './cec-team-a-f-post-close-modal.component.html',
    styleUrls: ['./cec-team-a-f-post-close-modal.component.scss']
})
export class CecTeamAFPostCloseModalComponent extends FormHelper implements OnInit, OnDestroy {

    readonly networkSelectOptions: SnowflakeSelectOption[] = PostCloseOptions.NETWORK_DEFAULT_POST_TICKET_OPTIONS;
    readonly systemIssueSelectOptions: SnowflakeSelectOption[] = PostCloseOptions.SYSTEM_DEFAULT_POST_TICKET_OPTIONS;
    readonly deliverySelectOptions: SnowflakeSelectOption[] = PostCloseOptions.DELIVERY_DEFAULT_POST_TICKET_OPTIONS;
    readonly rainOneQuerySelectOptions: SnowflakeSelectOption[] = PostCloseOptions.RAINONE_DEFAULT_POST_TICKET_OPTIONS;
    readonly billingDisputesOptions: SnowflakeSelectOption[] = PostCloseOptions.BILLING_DISPUTE_POST_TICKET_OPTIONS;
    readonly accountInArrearsOptions: SnowflakeSelectOption[] = PostCloseOptions.BILLING_ACCOUNT_ARREARS_POST_TICKET_OPTIONS;

    otherOptionsSelected: Dictionary<boolean> = {
        msisdns: false,
    };
    msisdnOptions: SnowflakeSelectOption<{msisdn: string, id: string} | string>[];
    isAnyOptionSelected = false;

    selectedMSISDNs: {msisdn: string, id: string}[] = [];
    classificationMessage: string;
    form: FormGroup;
    connectedSiteDataMap: ConnectedSiteData = {};
    modalOptions: PostTicketModalOptions;

    constructor(private fb: FormBuilder,
        private store: Store,
        private customerInfoSummaryService: CustomerInfoSummaryService,
        private postTicketService: PostTicketService,
        private actions$: Actions) {
        super();
    }

    ngOnInit(): void {
        this.modalOptions = new PostTicketModalOptions();
        this.setValues();

        const multiSelectOptions = FORM_KEYS.reduce((obj, key) => {
            obj[key] = this.fb.control([]);
            return obj;
        }, {});

        this.form = this.fb.group({
            correct_classification: this.fb.control(false),
            msisdns: this.fb.control(''),
            device_issue: this.fb.control(false),
            change_update_customer_details: this.fb.control(false),


            ...multiSelectOptions
        });
        this.InitForm(this.form);

        this.store.select(NWDAFConnectedSiteState.loadingIMSIs)
            .pipe(takeUntil(this.ngDestroy$))
            .subscribe({
                next: loadingImsis => {
                    const loadingCurrentSelected = this.getCurrentIMSIList().some(imsi => loadingImsis?.includes(imsi));

                    if (loadingCurrentSelected) {
                        this.modalOptions.spinnerMessage = "working..";
                        this.modalOptions.sending = true;
                    }
                    else {
                        this.modalOptions.spinnerMessage = "sending..";
                        this.modalOptions.sending = false;
                    }
                }
            });

        this.actions$
            .pipe(
                ofActionSuccessful(NWDAFConnectedSiteActions.FetchByIMSISuccess),
                filter((res: NWDAFConnectedSiteActions.FetchByIMSISuccess) => this.getCurrentIMSIList()?.includes(res?.imsi)),
                takeUntil(this.ngDestroy$),
            )
            .subscribe({
                next: (res: NWDAFConnectedSiteActions.FetchByIMSISuccess) => {
                    const { imsi } = res ?? {};
                    const id = this.getServiceIdforIMSI(imsi);
                    this.setConnectedSiteDataFromStore(id, imsi);
                }
            });

        this.form.get('device_issue').valueChanges
            .pipe(
                takeUntil(this.ngDestroy$)
            )
            .subscribe({
                next: () => {
                    this.isAnyOptionSelected = this.checkIfAnOptionWasSelected()
                }
            });

        this.form.get('change_update_customer_details').valueChanges
            .pipe(
                takeUntil(this.ngDestroy$)
            )
            .subscribe({
                next: () => {
                    this.isAnyOptionSelected = this.checkIfAnOptionWasSelected()
                }
            });

    }

    setValues() {
        const selectedTicket = this.store.selectSnapshot(InteractionTicketsState.getSelectedShortTicket);
        this.classificationMessage = getTicketMainSubject(selectedTicket);
        const simOptions = this.customerInfoSummaryService.getCurrentSimSFOptions();
        this.msisdnOptions = simOptions.length ? simOptions : [{ label: 'No active sims on this account', value: 'No active sims on this account' }];
    }

    onSelectOptions(key: string, selectedOptions: string[]) {
        this.form.get(key).patchValue(selectedOptions);

        if (key === "network") {
            this.otherOptionsSelected['msisdns'] = this.form.get('network').value.length > 0;
        }

        this.isAnyOptionSelected = this.checkIfAnOptionWasSelected();
    }


    onSelectMSISDN(selectedMSISDNs: {msisdn: string, id: string}[]) {
        this.selectedMSISDNs = selectedMSISDNs;

        this.selectedMSISDNs.forEach(option => this.addConnectedSiteData(option?.msisdn, option?.id));
    }

    addConnectedSiteData(msisdn: string, id: string) {
        if (this.connectedSiteDataMap?.[id]) {
            return;
        }

        const imsi = this.getIMSIforServiceId(id);
        if (!imsi) {
            this.connectedSiteDataMap[id] = { error: "No IMSI found for MSISDN" };
            return;
        }

        const hasStoreData = this.setConnectedSiteDataFromStore(id, imsi);
        if (!hasStoreData) {
            this.store.dispatch(new NWDAFConnectedSiteActions.FetchByIMSIIfNotPreset(imsi));
        }
    }

    private setConnectedSiteDataFromStore(id: string, imsi: string): boolean {
        const savedConnectionData = this.store.selectSnapshot(NWDAFConnectedSiteState.getConnectionData(imsi));
        if (!savedConnectionData) {
            return false;
        }

        const { liveConnection, enodeBId, cellId } = savedConnectionData ?? {};
        this.connectedSiteDataMap[id] = {
            live_connection: liveConnection,
            enodeb_id: enodeBId,
            cell_id: cellId
        };
        return true;
    }

    onSend() {
        const data = this.getFormattedData();
        const payload: ChatJsonNote = {
            message: "CEC support post close form",
            detail: data,
            type: "cec_team_a_to_f_support_form"
        }
        const hexId = this.store.selectSnapshot(InteractionTicketsState.getCurrentHexId);
        this.postTicketService.sendPostTicketPayload(
            hexId, payload, this.modalOptions, this.close.bind(this)
        );
    }

    private getFormattedData() {
        const { network, account_arrears, billing_disputes, device_issue, delivery,
            rain_one_query, system_issue, change_update_customer_details, correct_classification } = this.form?.getRawValue() ?? {};

        return {
            "network": [...network],
            "connected site data": this.getConnectedData(),
            "billing - billing dispute": [...billing_disputes],
            "billing - account in arrears": [...account_arrears],
            "delivery": [...delivery],
            "rain one query": [...rain_one_query],
            "system issue": [...system_issue],
            "change/update customer details": change_update_customer_details,
            "device issue": device_issue,
            "classification": {
                "subject": this.classificationMessage,
                "is-correct": correct_classification
            }
        }
    }

    private addRemoveValidators(controlName: string, required: boolean) {
        const control = this.getControl(controlName);
        if (required) {
            control?.addValidators(Validators.required);
            control?.updateValueAndValidity();
        }
        else {
            control?.removeValidators(Validators.required);
            control?.patchValue(null);
        }
    }

    getConnectedData() {
        return this.selectedMSISDNs.map(option => ({ msisdn: option?.msisdn, ...this.connectedSiteDataMap?.[option?.id] }));
    }

    checkIfAnOptionWasSelected() {
        for (const key of FORM_KEYS) {
            const hasOption = (this.getControl(key)?.value as string[])?.length > 0;
            const checkBoxTicked = (this.getControl('device_issue').value || this.getControl('change_update_customer_details').value)

            if (hasOption || checkBoxTicked) {
                return true;
            }
        }
        return false;
    }

    private getIMSIforServiceId(id: string) {
        return this.store.selectSnapshot(SimSelectors.getIMSIforServiceID(id));
    }

    private getCurrentIMSIList() {
        return this.selectedMSISDNs
            .map(option => this.getIMSIforServiceId(option?.id))
            .filter(imsi => Boolean(imsi));
    }

    private getServiceIdforIMSI(imsi: string) {
        return this.store.selectSnapshot(SimSelectors.getServiceIdforIMSI(imsi));
    }

    close() {
        this.store.dispatch(new ToggleModalByName("cec_team_a_to_f_post_close_modal", false));
    }

    ngOnDestroy(): void {
        this.close();
        this.ngDestroy$.next(null);
        this.ngDestroy$.complete();
    }
}
