import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { directives } from './directives';
import { services } from './services';
import { NgxsModule } from '@ngxs/store';
import { NotificationsState } from './store/state/notifications.state';
import { NotifiactionsModule as NotificationsModule } from './notifiactions/notifiactions.module';
import { HttpClientModule } from '@angular/common/http';
import { ApolloModule, APOLLO_OPTIONS } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { InMemoryCache } from '@apollo/client/core';
import { environment } from 'src/environments/environment';
import { BillingState } from './store/state/billing.state';
import { NavigationState } from './store/state/navigations.state';
import { AzureAuthState } from './store/state/azure-auth.state';
import { PersistentStorageState } from './store/state/persistent-storage/persistent-storage.state';
import { ModalState } from './store/state/modal.state';
import { CustomerInfoState } from '../customer-info-summary-page/store/state/customer-info.state';
import { ProductsState } from './store/state/product-state/products.state';
import { TicketEventModule } from '../shared/customer-ticket/ticket-event.module';
import { IndexedDBState } from '../shared/services/indexed-db/store/state/indexed-db.state';
import { VoiceLoggingState } from './store/state/voice-logging.state';
import { CustomerServiceState } from '../customer-info-summary-page/store/state/customer-service.state';
import { ProductCatalogState } from './store/state/product-state/product-catalog.state';
import { NavigationActionHandlerState } from './store/state/navigation-action-handler.state';
import { SHARED_SALES_STATES } from '../sales/store/state/shared-sales-states';
import { CoreState } from './store/state/core.state';
import { GeforceEligibilityState } from '../customer-data-components/nvidia-geforce-now/store/state/geforce-eligibility.state';
import { FirebaseFeatureFlagsState } from './store/state/firebase-feature-flags.state';
import { CORE_AGENT_STATES } from '../shared/components/agent/store/state';
import { NewServiceProxyState } from '../customer-info-summary-page/store/state/new-proxy-service.state';


@NgModule({
    declarations: [
        ...directives,
    ],
    imports: [
        CommonModule,
        NotificationsModule,
        TicketEventModule,
        NgxsModule.forFeature([
            CoreState,
            NotificationsState,
            BillingState,
            NavigationState,
            AzureAuthState,
            PersistentStorageState,
            CustomerInfoState,
            CustomerServiceState,
            NewServiceProxyState,
            GeforceEligibilityState,
            ModalState,
            ProductsState,
            ProductCatalogState,
            IndexedDBState,
            VoiceLoggingState,
            NavigationActionHandlerState,
            FirebaseFeatureFlagsState,
            ...SHARED_SALES_STATES,
            ...CORE_AGENT_STATES

        ]),
        ApolloModule,
        HttpClientModule
    ],
    providers: [
        {
            provide: APOLLO_OPTIONS,
            useFactory: (httpLink: HttpLink) => {
                return {
                    cache: new InMemoryCache(),
                    link: httpLink.create({
                        uri: `${environment.snowflake_api_url}/graphql`,
                    }),
                };
            },
            deps: [HttpLink],
        },
        ...services
    ],
    exports: [
        directives,
        NotificationsModule,
    ]
})
export class CoreModule { }
