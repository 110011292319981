import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';


@Component({
  selector: 'app-detailed-summary',
  templateUrl: './detailed-summary.component.html',
  styleUrls: ['./detailed-summary.component.scss']
})
export class DetailedSummaryComponent {

  @Output() continuePressed = new EventEmitter();
  @Output() backPressed = new EventEmitter();

  @Input() isBackButtonShown = true;
  @Input() defaultBack = true;
  
  @Input() hasContinueButton = false;
  @Input() scrollable = false;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) { }

  onBack() {
    if (this.defaultBack) {
      this.router.navigate(['.'], { relativeTo: this.activatedRoute.parent });
    }
    else {
      this.backPressed.next();
    }

  }

  onContinue() {

    this.continuePressed.next();
  }

}
