
import { SnowflakeSelectOption } from "src/app/shared/elements/rain-forms/elements/snowflake-select/interfaces/rain-select-option.interface";
import { createSFselectOptions } from "src/app/shared/functions/create-sf-select-options.function";


export const SALES_CHANNEL_OPTIONS:SnowflakeSelectOption[] = createSFselectOptions([
    "Inbound call",
    "Ticket"
])

export const HEAR_ABOUT_OPTIONS: SnowflakeSelectOption[] = createSFselectOptions(
  [
      "Word of Mouth",
      "Ads on other websites",
      "TV",
      "YouTube",
      "Google Search",
      "Radio",
      "Bus advertisements",
      "Billboards/Wall murals",
      "Unsure",
      "Existing customer",
      "Not applicable"
  ]
);

export const STATUS_OPTIONS: SnowflakeSelectOption[] = [
    {
        label: 'successful',
        value: 'successful'
    },
    {
        label: 'unsuccessful',
        value: 'unsuccessful'
    },
];

export const HOME_REASON_OPTIONS: SnowflakeSelectOption[] = [
  {
      label: 'No contact made',
      value: 'No contact made'
  },
  {
      label: 'Looking for legacy product',
      value: 'Looking for legacy product'
  },
  {
      label: 'No funds',
      value: 'No funds'
  },
  {
      label: 'No coverage',
      value: 'No coverage'
  },
  {
      label: 'Product information only',
      value: 'Product information only',
  },
  {
      label: 'Billing Query',
      value: 'Billing Query'
  },
  {
    label: 'Network Query',
    value: 'Network Query'
  },
  {
      label: 'Logistics/Delivery query',
      value: 'Logistics/Delivery query',
  },
  {
      label: 'Voice query',
      value: 'Voice query',
  },
  {
      label: 'Queued for delivery',
      value: 'Queued for delivery',
  },
  {
    label: 'Delivered already',
    value: 'Delivered already',
},
{
  label: 'Duplicate ticket',
  value: 'Duplicate ticket',
},
{
  label: 'Too expensive',
  value: 'Too expensive',
},
{
  label: 'Cancellation',
  value: 'Cancellation',
},
{
  label: 'No RICA documents',
  value: 'No RICA documents',
},
{
  label: 'Online banking error',
  value: 'Online banking error',
},
];

export const MOBILE_REASON_OPTIONS: SnowflakeSelectOption[] = [
  {
    label: 'Callback - contacted',
    value: 'Callback - contacted'
  },
  {
    label: 'Callback - no contact',
    value: 'Callback - no contact'
  },
  {
    label: 'Wants unlimited data plan',
    value: 'Wants unlimited data plan'
  },
  {
    label: 'In contract with another provider',
    value: 'In contract with another provider'
  },
  {
    label: 'Insufficient funds/Affordability',
    value: 'Insufficient funds/Affordability'
  },
  {
    label: 'Sold rain home',
    value: 'Sold rain home'
  },
  {
    label: 'Sold rain work',
    value: 'Sold rain work'
  },
  {
    label: 'Looking for a device with the sim',
    value: 'Looking for a device with the sim'
  },
  {
    label: 'Incorrect product selection',
    value: 'Incorrect product selection'
  },
  {
    label: 'Escalated to support',
    value: 'Escalated to support'
  },
  {
    label: 'No RICA documents',
    value: 'No RICA documents'
  }
]

export const WORK_REASON_OPTIONS: SnowflakeSelectOption[] = [
  {
    label: 'Wants higher speeds',
    value: 'Wants higher speeds'
  },
  {
    label: 'In a contract with another provider',
    value: 'In a contract with another provider'
  },
  {
    label: 'Incorrect product tile request',
    value: 'Incorrect product tile request'
  },
  {
    label: 'Insufficient funds',
    value: 'Insufficient funds'
  },
  {
    label: 'Sold rain home',
    value: 'Sold rain home'
  },
  {
    label: 'Sold rain mobile',
    value: 'Sold rain mobile'
  },
  {
    label: 'Requires VOIP system access',
    value: 'Requires VOIP system access'
  },
  {
    label: 'Not in coverage',
    value: 'Not in coverage'
  },
  {
    label: 'Product information only',
    value: 'Product information only'
  },
  {
    label: 'Escalated to support',
    value: 'Escalated to support'
  },
  {
    label: 'No RICA documents',
    value: 'No RICA documents'
  },
];
