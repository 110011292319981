<div class="center-content">

  <div>
    <h3 class="text text-blue" [style.color]="isPrimaryCustomer ? ('green' | rainColorCode): ''">
      primary customer {{isPrimaryCustomer ? " (current)": ""}}
    </h3>

    <ul class="default-unordered-list">
      <li>
        <span>first name:</span>
        <span>{{voucherData?.primaryUserFirstName}}</span>
      </li>
      <li>
        <span>last name:</span>
        <span>{{voucherData?.primaryUserLastName}}</span>
      </li>
      <li>
        <span>email:</span>
        <span>{{voucherData?.primaryUserEmailAddress}}</span>
      </li>
      <li>
        <span>phone:</span>
        <span>{{voucherData?.primaryUserCellNumber}}</span>
      </li>
      <li>
        <sf-rain-button (click)="onViewFullProfile(voucherData?.primaryUserEmailAddress)"
          [disabled]="!voucherData?.primaryUserEmailAddress || mainCustomerEmail === voucherData?.primaryUserEmailAddress">
          view full profile
        </sf-rain-button>
      </li>
    </ul>
  </div>

  <div>
    <h3 class="text text-blue" [style.color]="!isPrimaryCustomer ? ('green' | rainColorCode): ''">
      secondary customer {{!isPrimaryCustomer ? " (current)":
      (voucherData?.primaryUserEmailAddress === voucherData?.emailAddress ? "(same)": "")}}
    </h3>

    <ul class="default-unordered-list">
      <li>
        <span>first name:</span>
        <span>{{voucherData?.firstName}}</span>
      </li>
      <li>
        <span>last name:</span>
        <span>{{voucherData?.lastName}}</span>
      </li>
      <li>
        <span>email:</span>
        <span>{{voucherData?.emailAddress}}</span>
      </li>
      <li>
        <span>phone:</span>
        <span>{{voucherData?.cellNumber}}</span>
      </li>
      <li>
        <sf-rain-button (click)="onViewFullProfile(voucherData?.emailAddress)"
          [disabled]="!voucherData?.emailAddress || (mainCustomerEmail === voucherData?.emailAddress)">
          view full profile
        </sf-rain-button>
      </li>
    </ul>
  </div>

</div>
