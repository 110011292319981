import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { navActions } from '../actions/nav-actions';
import { produce } from 'immer';
import { QueueAction } from '../actions/nav-actions/nav.actions';
import { FilterFunctions } from 'src/app/shared/functions/filter';


interface NavigationActionHandlerStateModel {
    queueActions: QueueAction<unknown>[];
}
@State<NavigationActionHandlerStateModel>({
    name: 'sf_navigation_action_handler_state',
    defaults: {
        queueActions: []
    }
})
@Injectable()
export class NavigationActionHandlerState {


    @Action(navActions.AddQueueAction)
    QueueAction(ctx: StateContext<NavigationActionHandlerStateModel>, action: navActions.AddQueueAction<unknown>) {
        const { payload } = action;

        ctx.setState(produce(draft => { draft.queueActions.push(payload) }));
    }


    @Action(navActions.UpdateURL)
    DispatchQueuedActions(ctx: StateContext<NavigationActionHandlerStateModel>, action: navActions.UpdateURL) {
        const { queueActions } = ctx.getState();
        const currentRoute = action.payload;

        const [queuedActionsToDispatch, remainingQueuedActions] = FilterFunctions.partition(
            queueActions,
            (item) => currentRoute.includes(item.routerSegment)
        );

        ctx.patchState({
            queueActions: remainingQueuedActions
        });

        return ctx.dispatch(queuedActionsToDispatch.map(item => item.action));
    }


}   