import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { FormHelper } from '../../helpers/form.helper';
import { validationMessages } from '../../pipes';
import { PopupError } from 'src/app/core/handlers/popup-error';


//TODO: split out file input part

@Component({
    selector: 'sf-form-input',
    templateUrl: './rain-input.component.html',
    styleUrls: ['./rain-input.component.scss']
})
export class RainInputComponent extends FormHelper implements AfterViewInit {

    @ViewChild("mainInput") inputElRef: ElementRef<HTMLInputElement>;

    @Output() filesPicked = new EventEmitter<FileList | null>();

    @Input() control: FormControl;
    @Input() messages = validationMessages;
    @Input() public maxlength = '255';
    @Input() public label: string;
    @Input() public type = 'text';
    @Input() public placeholder = 'type here...';
    @Input() public autocomplete = '';
    @Input() public display: 'column' | 'row' = 'column';
    @Input() public markValid = false;
    @Input() public focusInput = false;
    @Input() accept?: string;

    ngAfterViewInit() {
        if (this.focusInput) {
            return this.focusInputElement();
        }
        if (this.control) {
            this.InitFormControl(this.control);
        }
    }

    onChange(event: Event) {
        if (this.type === "file") {
            this.onFilesPicked(event);
        }
    }

    onFilesPicked(event: Event) {
        const { target } = event ?? {};

        if (!(target instanceof HTMLInputElement)) {
            throw new PopupError("Something went wrong when selecting a file. Please try again.");
        }

        const { files } = target ?? {};

        if (!files?.length) {
            this.filesPicked.next(null);
        }
        else {
            this.filesPicked.next(files);
        }
    }

    public onBoundaryClick() {
        return this.focusInputElement();
    }

    private focusInputElement() {
        const inputEl: HTMLInputElement = this.inputElRef.nativeElement;
        if (inputEl !== undefined) inputEl.focus();
    }

    public formatLabel(l: string) {
        return l.replace(' ', '_');
    }
}
