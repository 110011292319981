import { Utils } from "src/app/Utils";
import { AgentActionLoggingRequest } from "../../types/agent-action-logging-request.interface";

export class LogAction {
    static readonly type = Utils.Helpers.Type("[Agent Action Logging] Log agent action");
    constructor(public payload: AgentActionLoggingRequest) { }
}

export class LogActionSuccess {
    static readonly type = Utils.Helpers.Type("[Agent Action Logging] Log agent action success");
}

export class LogActionFail {
    static readonly type = Utils.Helpers.Type("[Agent Action Logging] Log agent action fail");
}