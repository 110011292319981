import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxsModule } from '@ngxs/store';
import { CustomerCallbackState } from './store/state/customer-callback.state';
import { AddCallbackState } from './store/state/add-callback.state';
import { UpdateCallbackState } from './store/state/update-callback-extra.state';
import { AgentCallbackState } from './store/state/agent-callback.state';

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        NgxsModule.forFeature([
            CustomerCallbackState,
            AddCallbackState,
            UpdateCallbackState,
            AgentCallbackState
        ])
    ],
})
export class CallbackModule { }