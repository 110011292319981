import { Selector } from "@ngxs/store";
import { CustomerOrderFunctions } from "src/app/shared/functions/customer-order-functions";
import { CustomerOrder } from "src/app/customer-data-components/customer-order/customer-order-card/assets/customer-order.interface";
import { CustomerOrderState } from "../state/customer-order.state";
import { OptionalSelectorOptions, createOptionalSelector } from "src/app/shared/functions/create-optional-selector.function";
import { AllOrderSelectors } from "src/app/customer-info-summary-page/store/selectors/all-order.selectors";


export class CustomerOrderSelectors {

    static getOrderById(orderId: string, options?: OptionalSelectorOptions) {

        return createOptionalSelector(
            [AllOrderSelectors.getOrders],
            (orders: CustomerOrder[]) => orders?.find(order => order?.id === orderId),
            options
        );
    }

    static getOrderByNumber(orderNumber: number, options?: OptionalSelectorOptions) {

        return createOptionalSelector(
            [AllOrderSelectors.getOrders],
            (orders: CustomerOrder[]) => orders?.find(order => order?.order_number === orderNumber),
            options
        );
    }

    static getOrderTotal(orderNumber: number, options?: OptionalSelectorOptions) {

        return createOptionalSelector(
            [CustomerOrderSelectors.getOrderByNumber(orderNumber)],
            (order: CustomerOrder) => CustomerOrderFunctions.calculateOrderTotal(order?.order_lines ?? []),
            options
        );
    }

    @Selector([CustomerOrderState.getSelectedOrderId, AllOrderSelectors.getOrders])
    static getSelectedOrder(orderId: string, orders: CustomerOrder[]): CustomerOrder {

        return CustomerOrderSelectors.getOrderById(orderId, { rawFunction: true })(orders);
    }

    @Selector([CustomerOrderSelectors.getSelectedOrder])
    static isCollection(order: CustomerOrder) {
        return order?.delivery?.type === "collection";
    }

    @Selector([AllOrderSelectors.getOrders])
    static getAmountOfAllOrders(orders: CustomerOrder[]): number {
        return orders?.length
    }

}


