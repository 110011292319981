import { Component, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { StatusTooltip } from 'src/app/shared/models/status-tooltip.model';
import { AGENT_NO_QUEUE_MESSAGE } from 'src/app/sip-phone/services/sip-platform/assets/voice-messages.constants';
import { AgentVoiceQueueActions } from 'src/app/sip-phone/store/actions/agent-voice-queue-actions';
import { VoiceAuthActions } from 'src/app/sip-phone/store/actions/voice-auth-actions';
import { AgentVoiceQueueState } from 'src/app/sip-phone/store/state/agent-voice-queue-state';
import { VoiceAuthState } from 'src/app/sip-phone/store/state/voice-auth.state';

interface StatusObject {
  hasError: boolean;
  tooltip?: StatusTooltip;
  retryMessage?: string;
  action?: VoiceAuthActions.Retry | AgentVoiceQueueActions.RetryLogin;
}

@Component({
  selector: 'sf-voice-queue-error',
  templateUrl: './voice-queue-error.component.html',
  styleUrls: ['./voice-queue-error.component.scss']
})
export class VoiceQueueErrorComponent implements OnInit {
  @Select(VoiceAuthState.getError) voiceAuthError$: Observable<string>;
  @Select(AgentVoiceQueueState.getError) voiceQueueError$: Observable<string>;

  statusObject$: Observable<StatusObject>;

  constructor(private store: Store) { }

  ngOnInit(): void {

    this.statusObject$ = combineLatest([
      this.voiceAuthError$,
      this.voiceQueueError$
    ])
      .pipe(
        map(([voiceAuthError, voiceQueueError]) => {

          if (!voiceAuthError && !voiceQueueError) {
            return { hasError: false };
          }

          //Voice auth error takes priority
          if (voiceAuthError) {
            return this.getVoiceAuthErrorOptions(voiceAuthError);
          }

          return this.getVoiceQueueError(voiceQueueError);
        })
      );
  }


  private getVoiceAuthErrorOptions(error: string): StatusObject {
    return {
      hasError: true,
      tooltip: new StatusTooltip("red", error),
      retryMessage: "Retry voice authentication.",
      action: new VoiceAuthActions.Retry()
    }
  }

  private getVoiceQueueError(error: string): StatusObject {
    const color = error?.includes(AGENT_NO_QUEUE_MESSAGE) ? "orange" : "red";

    return {
      hasError: true,
      tooltip: new StatusTooltip(color, error),
      retryMessage: "Retry voice queue login.",
      action: new AgentVoiceQueueActions.RetryLogin()
    }
  }

  dispatchAction(action: VoiceAuthActions.Retry | AgentVoiceQueueActions.RetryLogin) {
    this.store.dispatch(action);
  }

}
