import { Component, Input } from '@angular/core';
import { Ticks } from './ticks.type';

@Component({
  selector: 'sf-chat-ticks',
  templateUrl: './chat-ticks.component.html',
  styleUrls: ['./chat-ticks.component.scss']
})
export class ChatTicksComponent {

  hasTwo = false;
  isBlue = false;

  private _ticks: Ticks;
  @Input() set ticks(ticks: Ticks) {
    this._ticks = ticks;
    this.hasTwo = ticks === "two" || ticks === "two-blue";
    this.isBlue = ticks === "two-blue";
  }

  get ticks(): Ticks {
    return this._ticks;
  }

}
