import { Injectable } from '@angular/core';
import { Action, createSelector, Selector, State, StateContext, Store } from '@ngxs/store';
import { ClearPersistentStorageItem, SetPersistentStorageItem } from 'src/app/core/store/actions/persistent-storage.actions';
import { PersistentStorageState } from 'src/app/core/store/state/persistent-storage/persistent-storage.state';
import { Utils } from 'src/app/Utils';
import { environment, FEATURE_FLAGS } from 'src/environments/environment';
import { FeatureFlagsActive } from 'src/environments/feature-flags';
import { FeatureFlagActions } from '../actions/feature-flag-actions';


type FeatureFlagStateModel = FeatureFlagsActive;

@State<FeatureFlagStateModel>({
    name: 'sf_feature_flag_state',
    defaults: FEATURE_FLAGS
})
@Injectable()
export class FeatureFlagState {

    @Selector()
    static getFlags(state: FeatureFlagStateModel) {
        return state;
    }

    static isFeatureActive(key: keyof FeatureFlagsActive) {
        return createSelector(
            [FeatureFlagState],
            (state: FeatureFlagStateModel) => state[key]);
    }

    private _initialized = false;

    constructor(private store: Store) { }


    @Action(FeatureFlagActions.Init)
    Init(ctx: StateContext<FeatureFlagStateModel>) {
        if (this._initialized) {
            return;
        }
        this._initialized = true;

        //Clear saved flags with new version
        const savedVersion = this.store.selectSnapshot(PersistentStorageState.get("app-version"));
        if (environment.version !== savedVersion) {
            this.store.dispatch(new ClearPersistentStorageItem("feature-flags"));
        }

        //Only use saved flags for non prod environment
        const savedFlags = this.store.selectSnapshot(PersistentStorageState.get("feature-flags"));
        if (!environment.production && savedFlags) {
            ctx.setState(Utils.Mappers.patch(FEATURE_FLAGS, savedFlags));
        }
        else {
            ctx.setState(FEATURE_FLAGS);
        }
    }

    @Action(FeatureFlagActions.SetFeatureFlag)
    SetFeatureFlag(ctx: StateContext<FeatureFlagStateModel>, action: FeatureFlagActions.SetFeatureFlag) {
        const { flag, active } = action;

        ctx.patchState({
            [flag]: active
        });
        this.persistState(ctx.getState());
    }

    @Action(FeatureFlagActions.SetFeatureFlags)
    SetFeatureFlags(ctx: StateContext<FeatureFlagStateModel>, action: FeatureFlagActions.SetFeatureFlags) {
        const { options } = action;

        ctx.setState(options);
        this.persistState(options);
    }

    @Action(FeatureFlagActions.ResetToDefault)
    ResetToDefault(ctx: StateContext<FeatureFlagStateModel>) {
        ctx.setState(FEATURE_FLAGS);
        this.persistState(FEATURE_FLAGS);
    }

    private persistState(options: FeatureFlagsActive) {
        //Only save feature flags for non prod environments
        if (environment.production) {
            return;
        }

        this.store.dispatch(new SetPersistentStorageItem("feature-flags", options));
    }
}   