import { Utils } from "src/app/Utils";
import { DigitalIdentityCustomer } from "../../../types/digital-identity-by-customer-id-response.interface";

export class Fetch {
  static readonly type = Utils.Helpers.Type("[Digital Identity By User Id] Fetch");
  constructor(public payload: string) { }
}

export class FetchSuccess {
  static readonly type = Utils.Helpers.Type("[Digital Identity By User Id] Fetch success");
  constructor(public payload: DigitalIdentityCustomer) { }
}

export class FetchFail {
  static readonly type = Utils.Helpers.Type("[Digital Identity By User Id] Fetch fail");
  constructor(public error: unknown) { }
}

export class Clear {
  static readonly type = Utils.Helpers.Type("[Digital Identity By User Id] Clear");
}
