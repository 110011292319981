import { Utils } from "src/app/Utils";
import { VoiceTicketSubject } from "../../constants/call-ticket-and-event-names.constants";
import { CallTicketEventName } from "../../interfaces/call-ticket-event-names.interface";




export class SetCallTicketEventName {
    static readonly type = Utils.Helpers.Type('[VoiceTicket: Set] Set call ticket event name');
    constructor(public name: CallTicketEventName) { }
}

export class ResetCallTicketEventName {
    static readonly type = Utils.Helpers.Type('[VoiceTicket: Reset] Reset call ticket event name to the default');
}

export class SetCallTicketSubject {
    static readonly type = Utils.Helpers.Type('[VoiceTicket: Set] Set call ticket subjectF');
    constructor(public subject: VoiceTicketSubject) { }
}

export class ResetCallTicketSubject {
    static readonly type = Utils.Helpers.Type('[VoiceTicket: Reset] Reset call ticket subjectF');
}
