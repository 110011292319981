import { Utils } from "src/app/Utils";
import { Callback, CallbackExtras } from "src/app/shared/components/callback/store/types/callback-response.interface";
import { SFResponseType } from "../../types/respond-to-ticket-payload.interface";



export class AcceptCallback {
    static readonly type = Utils.Helpers.Type("[Respond to callback] Accept");
    constructor(public callback: Callback) { }
}

export class RejectCallback {
    static readonly type = Utils.Helpers.Type("[Respond to callback] Reject");
    constructor(public callback: Callback) { }
}

export class RespondToCallback {
    static readonly type = Utils.Helpers.Type("[Respond to callback] Respond");
    constructor(public callbackId: string, public type: SFResponseType, public extras: CallbackExtras) { }
}

export class RespondToCallbackSuccess {
    static readonly type = Utils.Helpers.Type("[Respond to callback] Respond success");
    constructor(public type: SFResponseType, public payload: Callback) { }
}

export class RespondToCallbackFail {
    static readonly type = Utils.Helpers.Type("[Respond to callback] Respond fail");
    constructor(public type: SFResponseType, public error: unknown) { }
}