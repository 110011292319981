import { Injectable } from '@angular/core';
import { Navigate } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';
import { navActions } from 'src/app/core/store/actions/nav-actions';
import { AllTicketsService } from 'src/app/features/pages/ticket/services/all-tickets.service';
import { InteractionTicketActions } from 'src/app/interactions/store/actions/interaction-ticket-actions';
import { SearchService } from 'src/app/search-page/search.service';
import { CustomerTicketHelper } from 'src/app/shared/customer-ticket/customer-ticket-helper';
import { CustomerTicket } from 'src/app/shared/customer-ticket/interfaces/customer-ticket.interface';
import { SipPhoneActions } from '../store/actions/sip-phone-actions';
import { CoreState } from 'src/app/core/store/state/core.state';
import { SALES_PORTAL_ROUTE } from 'src/app/constants';
import { CustomerIdentifier } from 'src/app/shared/components/ticket-tracking/store/types/ticket-tracking-data.interface';
import { SFValidators } from 'src/app/shared/functions/sf-validators';

@Injectable({
  providedIn: 'root',
})
export class IncomingCallService {
  constructor(
    private allTicketsService: AllTicketsService,
    private searchService: SearchService,
    private store: Store,
  ) { }

  /**
   * Will try to get the customer email from the ticket. But if no email could be retrieved,
   * then the number will be used as a fall back search param.
   * If not on smartsub the agent will be redirected to the search page.
   */
  async setupTicketInfo(callerNumber: string, customerId:string) {
    const identificationResult = await this.checkIVRIdentification(callerNumber);
    const { email } = identificationResult ?? {};

    const identifier: CustomerIdentifier = SFValidators.isDefined(email)
      ? { type: "email", value: email }
      : { type: "phone", value: callerNumber }

    const foundOnSmartsub = await this.searchService.checkIfOnSmartsub(identifier.value);
    foundOnSmartsub ? this.navigateBasedOnIdentification(foundOnSmartsub, identifier.value) : await this.navigateBasedOnCustomerId(customerId);
  }

  async navigateBasedOnCustomerId(customerId: string|null|undefined){
    customerId && await this.searchService.checkIfOnSmartsub(customerId)
    ? this.store.dispatch([
        new navActions.SetIsFromAgentView(true),
        new Navigate([`customer-info-summary/${customerId}`])
      ])
    : this.navigateBasedOnIdentification(false)
  }

  //TODO: check if this actually works
  private async checkIVRIdentification(callerNumber: string): Promise<{ email: string }> {

    const response = await this.allTicketsService.getPhoneCallTicket(callerNumber);
    const ticket: CustomerTicket = response?.data?.data?.[0];

    if (!ticket) {
      return { email: null };
    }

    const email = new CustomerTicketHelper(ticket)?.customerEmail;
    if (!email) {
      return { email: null };
    }

    this.store.dispatch(new InteractionTicketActions.SetAssignedHexId(ticket?.hex_id));
    return { email };
  }

  navigateBasedOnIdentification(identified: boolean, searchParam?: string) {
    const inSales = this.store.selectSnapshot(CoreState.isInSales);

    if (identified) {
      this.store.dispatch([
        new navActions.SetIsFromAgentView(true),
        new Navigate([`customer-info-summary/${searchParam}`])
      ]);
    }
    else if (inSales) {
      this.store.dispatch(new Navigate([SALES_PORTAL_ROUTE]));
    }
    else {
      this.store.dispatch([
        new SipPhoneActions.SetUnknownIncomingCall(true),
        new Navigate(['search'])
      ]);
    }
  }

}
