import { Component, Input } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { ModalState } from 'src/app/core/store/state/modal.state';

@Component({
    selector: 'sf-interactions-modal-container',
    templateUrl: './interactions-modal-container.component.html',
    styleUrls: ['./interactions-modal-container.component.scss'],
})
export class InteractionsModalContainerComponent {
    @Select(ModalState.isModalOpen('default_post_ticket_modal')) isPostTicketOpen$: Observable<boolean>;
    @Select(ModalState.isModalOpen('retentions_close_modal')) isRetentionsModalOpen$: Observable<boolean>;
    @Select(ModalState.isModalOpen('retentions_update_modal')) isRetentionsUpdateModalOpen$: Observable<boolean>;
    @Select(ModalState.isModalOpen('retentions_close_4G_modal')) isRetentions4GClosedModalOpen$: Observable<boolean>;
    @Select(ModalState.isModalOpen('retentions_update_4G_modal')) isRetentions4GUpdateModalOpen$: Observable<boolean>;
    @Select(ModalState.isModalOpen('post_sales_modal')) isSalesTicketModalOpen$: Observable<boolean>;
    @Select(ModalState.isModalOpen('post_collections_modal')) isPostCollectionsModalOpen$: Observable<boolean>;
    @Select(ModalState.isModalOpen('escalation_modal')) isEscalationModalOpen$: Observable<boolean>;
    @Select(ModalState.isModalOpen('update_ticket_warning_modal')) isUpdateTicketWarningModalOpen$: Observable<boolean>;
    @Select(ModalState.isModalOpen('new_customer_ticket_modal')) isNewTicketModalOpen$: Observable<boolean>;
    @Select(ModalState.isModalOpen('post_activations_modal')) isPostActivationsModalOpen$: Observable<boolean>;
    @Select(ModalState.isModalOpen('rica_post_close_modal')) isRicaModalOpen$: Observable<boolean>;
    @Select(ModalState.isModalOpen('csat_close_modal')) isCSATmodalOpen$: Observable<boolean>;
    @Select(ModalState.isModalOpen('early_optimizations_close_modal')) isEarlyOptimizationsModalOpen$: Observable<boolean>;
    @Select(ModalState.isModalOpen('post_campaign_call_modal')) isPostCampaignCallModalOpen$: Observable<boolean>;
    @Select(ModalState.isModalOpen('rain_one_retentions_post_close_modal')) isRainOneRetentionsPostCloseModalOpen$: Observable<boolean>;
    @Select(ModalState.isModalOpen('post_upgrade_modal')) isUpgradePostTicketModalOpen$: Observable<boolean>;
    @Select(ModalState.isModalOpen('cec_team_a_to_f_post_close_modal')) isCECTeamAFPostTicketModalOpen$: Observable<boolean>;
    @Select(ModalState.isModalOpen('nvidia_post_close_modal')) isInvidiaPostTicketModalOpen$: Observable<boolean>;
    @Select(ModalState.isModalOpen('post_collections_modal_b')) isPostCollectionsModalBopen$: Observable<boolean>;
    @Select(ModalState.isModalOpen('fulfillment_deliveries_post_close_modal')) isFulfilmentDeliveriesModalOpen$: Observable<boolean>;
    @Select(ModalState.isModalOpen('fulfillment_cpe_collections_post_close_modal')) isFulfilmentCpeCollectionsModalOpen$: Observable<boolean>;
    @Select(ModalState.isModalOpen('fulfillment_rica_post_close_modal')) isFulfilmentRicaModalOpen$: Observable<boolean>;
    @Select(ModalState.isModalOpen('retail_customer_engagement_post_close_modal')) isRetailCustomerEngagementPostCloseModalOpen$: Observable<boolean>;
    @Select(ModalState.isModalOpen('retentions_main_post_close_modal')) isRetentionMainPostCloseModalOpen$: Observable<boolean>;
    @Select(ModalState.isModalOpen('rain_101_post_close_modal')) isRain101PostCloseModalOpen$: Observable<boolean>;


    @Input() numOpenTickets: number;
    @Input() customerEmail: string;
}
