import { PathKeys } from "src/app/shared/types/path-keys.type";

//TODO: Write this function better
export function getNestedValue<T extends Record<string, unknown>>(obj: T, keyString: PathKeys<T>) {
  if (!obj) {
    return;
  }

  const keys = (<string>keyString).split(".");
  //Create deep copy
  let currentValue = JSON.parse(JSON.stringify(obj));

  for (const key of keys) {
    if (!(key in currentValue)) {
      return;
    }
    currentValue = currentValue[key];
  }
  return currentValue;
}
