<app-rain-card [showDogEar]="showDogEar" [dogEarMessage]="dogEarMessage">
  <ng-container front>

    <div class="container">

      <div class="top">
        <div>
          <h2 class="text text-blue">{{header}}</h2>
          <ng-content select="[headerContent]"></ng-content>
        </div>

        <div *ngIf="rightHeader">
          <h2 class="text text-blue">{{rightHeader}}</h2>
        </div>
      </div>


      <div class="bottom">
        <div class="col content-left">
          <ng-content select="[contentLeft]"></ng-content>
        </div>

        <div class="col content-right">
          <ng-content select="[contentRight]"></ng-content>
        </div>
      </div>

    </div>
  </ng-container>
</app-rain-card>