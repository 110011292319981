import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store, } from '@ngxs/store';
import { switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { AddSuccessResponseNotification } from 'src/app/core/store/actions/notifications.actions';
import { ClearPersistentStorageItem, SetPersistentStorageItem } from 'src/app/core/store/actions/persistent-storage.actions';
import { PersistentStorageState } from 'src/app/core/store/state/persistent-storage/persistent-storage.state';
import { Utils } from 'src/app/Utils';
import { AGENT_NO_QUEUE_MESSAGE } from '../../services/sip-platform/assets/voice-messages.constants';
import { QueueData } from '../../services/sip-platform/assets/voice-queue-info.interface';
import { LoginAction } from '../../services/sip-platform/login-action.type';
import { SipPlatformLoginService } from '../../services/sip-platform/sip-platform-login.service';
import { AgentVoiceQueueActions } from '../actions/agent-voice-queue-actions';
import { VoiceAuthActions } from '../actions/voice-auth-actions';
import { VoiceAuthState } from './voice-auth.state';
import { Subject } from 'rxjs';


type LoginStatus = "un-initialized" | "logged in" | "logged out";


function getDefaults(): AgentVoiceQueueStateModel {
    return {
        loading: false,
        loaded: false,
        error: null,
        currentQueue: null,
        allQueues: [],
        loginStatus: "un-initialized",
        lastLoginAction: null
    }
}

interface AgentVoiceQueueStateModel {
    loading: boolean;
    loaded: boolean;
    error: string;
    currentQueue: QueueData;
    allQueues: QueueData[];
    loginStatus: LoginStatus;
    lastLoginAction: LoginAction;
}

@State<AgentVoiceQueueStateModel>({
    name: 'sf_agent_voice_queue_state',
    defaults: getDefaults()
})
@Injectable()
export class AgentVoiceQueueState {

    @Selector()
    static isLoading(state: AgentVoiceQueueStateModel) { return state.loading }

    @Selector()
    static isLoaded(state: AgentVoiceQueueStateModel) { return state.loaded }

    @Selector()
    static getLoginStatus(state: AgentVoiceQueueStateModel) { return state.loginStatus }

    @Selector()
    static getQueue(state: AgentVoiceQueueStateModel) { return state.currentQueue }

    @Selector()
    static getAllQueues(state: AgentVoiceQueueStateModel) { return state.allQueues }

    @Selector()
    static getError(state: AgentVoiceQueueStateModel) { return state.error }

    private readonly _cancelLoginToggleRequest$ = new Subject();

    constructor(private loginService: SipPlatformLoginService,
        private store: Store) {
    }

    @Action(AgentVoiceQueueActions.FetchQueues)
    FetchQueues(ctx: StateContext<AgentVoiceQueueStateModel>, action: AgentVoiceQueueActions.FetchQueues) {
        const { voiceToken } = action;
        if (!voiceToken) {
            return ctx.dispatch(new AgentVoiceQueueActions.FetchQueuesFail(
                "Failed to fetch access token.",
                { dontNotify: true }
            ));
        }

        const storedQueues = this.store.selectSnapshot(PersistentStorageState.get("agent-voice-queue-data"));
        if (storedQueues?.length) {
            return this.checkQueues(ctx, storedQueues);
        }

        ctx.patchState({ loading: true });

        return this.loginService.fetchQueues(voiceToken)
            .pipe(tap({
                next: res => this.checkQueues(ctx, res?.queues),
                error: (e: unknown) => ctx.dispatch(new AgentVoiceQueueActions.FetchQueuesFail(e))
            }));
    }

    private checkQueues(ctx: StateContext<AgentVoiceQueueStateModel>, queues: QueueData[]) {
        /* FAIL CONDITIONS */
        if (!queues?.length) {
            return ctx.dispatch(new AgentVoiceQueueActions.FetchQueuesFail(AGENT_NO_QUEUE_MESSAGE, { isWarning: true }));
        }
        //Agent should not be in more than one queue at a time
        if (queues?.length > 1) {
            const message = "Agent is assigned to more than one queue.";

            //Show what queues agent is currently logged into
            ctx.patchState({
                allQueues: queues
            })

            return ctx.dispatch([
                new AgentVoiceQueueActions.FetchQueuesFail(message),
                ...queues.map(q => new AgentVoiceQueueActions.ToggleLogin({
                    loginAction: "logout",
                    queueId: q.id
                }))
            ]);
        }
        /* SUCCESS */
        return ctx.dispatch(new AgentVoiceQueueActions.FetchQueuesSuccess(queues));
    }


    @Action(AgentVoiceQueueActions.FetchQueuesSuccess)
    FetchQueuesSuccess(ctx: StateContext<AgentVoiceQueueStateModel>, action: AgentVoiceQueueActions.FetchQueuesSuccess) {
        const allQueues = action.payload;

        ctx.patchState({
            loading: false,
            loaded: true,
            allQueues,
            currentQueue: allQueues[0],
            error: null,
        });

        //Logout by default
        return ctx.dispatch([
            new SetPersistentStorageItem("agent-voice-queue-data", allQueues),
            new AgentVoiceQueueActions.ToggleLogin({
                loginAction: "logout",
                showMessage: true
            })
        ]);
    }


    @Action(AgentVoiceQueueActions.FetchQueuesFail)
    FetchQueuesFail(ctx: StateContext<AgentVoiceQueueStateModel>, action: AgentVoiceQueueActions.FetchQueuesFail) {
        const { error, options } = action;
        const { isWarning, dontNotify } = options;
        const errorMessage = Utils.Helpers.findError(error, '');
        const message = `Voice Queue Error. ${errorMessage}`;

        ctx.patchState({
            loading: false,
            loaded: true,
            currentQueue: null,
            error: message,
        });

        if (dontNotify) {
            return;
        }

        return ctx.dispatch(new AddSuccessResponseNotification(
            {
                success: false,
                message: message
            },
            isWarning)
        );
    }


    @Action(AgentVoiceQueueActions.ToggleLogin)
    ToggleLogin(ctx: StateContext<AgentVoiceQueueStateModel>, action: AgentVoiceQueueActions.ToggleLogin) {
        const { currentQueue, lastLoginAction } = ctx.getState();
        const { loginAction, showMessage, queueId } = action.payload;
        const selectedQueueId = queueId ?? currentQueue?.id;

        if (loginAction === lastLoginAction || !selectedQueueId) {
            return;
        }

        ctx.patchState({
            loading: true,
            lastLoginAction: loginAction
        });

        this._cancelLoginToggleRequest$.next(null);

        const accessToken = this.store.selectSnapshot(VoiceAuthState.getAccessToken);
        return this.loginService.loginOrOut(loginAction, selectedQueueId, accessToken)
            .pipe(
                tap({
                    next: () => ctx.dispatch(new AgentVoiceQueueActions.ToggleLoginSuccess(loginAction, showMessage)),
                    error: (e: unknown) => ctx.dispatch(new AgentVoiceQueueActions.ToggleLoginFail(loginAction, e, showMessage))
                }),
                takeUntil(this._cancelLoginToggleRequest$.pipe(take(1))),
            );
    }

    @Action(AgentVoiceQueueActions.ToggleLoginSuccess)
    ToggleLoginSuccess(ctx: StateContext<AgentVoiceQueueStateModel>, action: AgentVoiceQueueActions.ToggleLoginSuccess) {
        const { showMessage, loginAction } = action;
        const loginStatus = (loginAction === "login") ? "logged in" : "logged out";

        ctx.patchState({
            loginStatus,
            loading: false
        });

        if (showMessage) {
            return ctx.dispatch(new AddSuccessResponseNotification({
                success: true,
                message: "iMobility authentication was successful."
            }));
        }
    }

    @Action(AgentVoiceQueueActions.ToggleLoginFail)
    ToggleLoginFail(ctx: StateContext<AgentVoiceQueueStateModel>, action: AgentVoiceQueueActions.ToggleLoginFail) {
        const { error, showError, loginAction } = action;
        const errorMessage = Utils.Helpers.findError(error, '');

        const prefix = loginAction === "login" ? "Failed to login to voice queue." : "Failed to logout of voice queue.";
        const message = `${prefix} ${errorMessage}`;

        ctx.patchState({
            error: message,
            loading: false
        });

        if (showError) {
            return ctx.dispatch(new AddSuccessResponseNotification({
                success: false,
                message
            }));
        }
    }

    @Action(AgentVoiceQueueActions.RetryLogin)
    retrySipLogin(ctx: StateContext<AgentVoiceQueueStateModel>, action: AgentVoiceQueueActions.RetryLogin) {
        const { hardRefresh } = action;

        if (hardRefresh) {
            return ctx.dispatch(new ClearPersistentStorageItem("agent-voice-queue-data"))
                .pipe(
                    switchMap(() => ctx.dispatch(new VoiceAuthActions.Retry())),
                    tap(() => this._retry(ctx))
                );
        }

        return this._retry(ctx);
    }

    private _retry(ctx: StateContext<AgentVoiceQueueStateModel>) {
        const voiceToken = this.store.selectSnapshot(VoiceAuthState.getAccessToken);
        return ctx.dispatch([
            new AgentVoiceQueueActions.Clear(),
            new AgentVoiceQueueActions.FetchQueues(voiceToken)
        ]);
    }

    @Action(AgentVoiceQueueActions.Clear)
    Clear(ctx: StateContext<AgentVoiceQueueStateModel>) {
        ctx.setState(getDefaults());
    }

}
