import { HttpErrorResponse } from "@angular/common/http";
import { FindErrorOptions, findError } from "./find-error.helper";

interface ErrorAndStatusCode {
    error: string;
    statusCode: number | null;
}

export const findErrorAndStatusCode = <E = unknown>(errorObj: E, defaultError = '', options?: FindErrorOptions): ErrorAndStatusCode => {

    const error = findError(errorObj, defaultError, options);

    const statusCode = errorObj instanceof HttpErrorResponse
        ? errorObj?.status
        : null;

    return {
        error,
        statusCode
    }
}