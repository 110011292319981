export const NETWORK_QUERY_OPTIONS = [
  "No Connection",
  "Intermittent connection",
  "Slow speeds",
  "WIFI"
];


export const BILLING_QUERY_OPTIONS = [
  "Payment date change",
  "Pay later options",
  "How do I pay",
  "What my balance",
  "Overcharged"
];

export const MOBILE_QUERY_OPTIONS = [
  "VoLTE setup",
  "rain talk setup",
  "APN",
  "Can't make calls",
  "Buy more",
  "Sim swap"
];

export const QUERY_OUTCOME_OPTIONS = [
  "Resolved",
  "Escalated",
  "Pending customer feedback"
];


export const CONNECTION_TYPE_OPTIONS = [
  "4G",
  "5G"
];



export const ESCALATION_OPTIONS = [
  "RAN",
  "Transmission",
  "Core",
  "ISOC",
  "Logistic (Swap out)",
  "Billing",
  "Collections",
  "Retentions",
  "Dashers"
];


