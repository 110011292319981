
type ObjType = "string" | "number" | "bigint" | "boolean" | "symbol" | "undefined" | "object" | "function";

function sameType(obj1: any, obj2: any) {
    return typeof obj1 === typeof obj2;
}

function sameLength(obj1: any[] | Record<string, unknown>, obj2: any[] | Record<string, unknown>) {
    if (Array.isArray(obj1) && Array.isArray(obj2)) {
        return obj1.length === obj2.length;
    }
    return Object.keys(obj1).length === Object.keys(obj2).length;
}

/**
 * Check if two objects are the same. Not dealing with "function" or "symbol" for now.
 */
export function areObjectsEqual(obj1: any, obj2: any) {
    if (!sameType(obj1, obj2)) {
        return false;
    }
    //They are the same type at this point
    const objType = typeof obj1;
    const simpleTypes: ObjType[] = ["string", "number", "bigint", "boolean", "undefined"];

    //compare simple types
    if (simpleTypes.includes(objType)) {
        return obj1 === obj2;
    }

    //not dealing with "function" or "symbol" for now
    if (objType !== "object" || !sameLength(obj1, obj2)) {
        return false;
    }

    //objs are the same length at this point
    if (Array.isArray(obj1) && !isArrayTheSame(obj1, obj2)) {
        return false;
    }

    //objType is "object" at this point
    return isArrayTheSame(Object.values(obj1), Object.values(obj2));
}

function isArrayTheSame(arr1: any[], arr2: any[]) {
    for (let i = 0; i < arr1.length; i++) {
        const element1 = arr1?.[i];
        const element2 = arr2?.[i];
        if (!areObjectsEqual(element1, element2)) {
            return false;
        }
    }
    return true;
}



