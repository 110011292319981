<audio id="incoming" src="../../assets/ring.mp3" loop [volume]="phoneVolumeSettings.ring"></audio>
<audio id="outgoing" src="../../assets/ringback.mp3" loop [volume]="phoneVolumeSettings.ring"></audio>
<audio id="voiceElement" [volume]="phoneVolumeSettings.voice"></audio>


<div #mainModal [class.hidden]="!modalOpen" class="phone-modal-wrapper" [class.static-position]="isPositionStatic"
  cdkDrag [cdkDragDisabled]="isPositionStatic">
  <div class="phone-modal-container">

    <mat-icon [matMenuTriggerFor]="menu" class="settings-button text-light-grey pointer">settings</mat-icon>

    <mat-menu #menu="matMenu">

      <div *ngFor="let setting of phoneVolumeSettings | keyvalue: originalOrder  trackBy:trackByFn" class="settings-div"
        (click)="$event.stopPropagation()">
        <p class="font-normal">{{setting.key + " volume"}}</p>
        <span class="slider-item">
          <mat-icon class="text-dark-grey volume-icon">
            {{setting.value > 0? "volume_up": "volume_off"}}
          </mat-icon>
          <mat-slider [(ngModel)]="phoneVolumeSettings[setting.key]" min="0" max="1" step="0.05"
            (mouseup)="saveVolumeChange()"></mat-slider>
        </span>
      </div>
    </mat-menu>
    
    <app-incoming-call-modal [callQueues]="callQueues" [canMinimize]="!isPositionStatic"></app-incoming-call-modal>
    <app-outgoing-call-modal [callQueues]="callQueues" [class.hidden]="callType==='incoming'"></app-outgoing-call-modal>
  </div>
</div>
