import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Store } from '@ngxs/store';
import {  Subject } from 'rxjs';
import { ToggleModalByName } from 'src/app/core/store/actions/modal.actions';
import { CoreState } from 'src/app/core/store/state/core.state';
import { SnowflakeSelectOption } from 'src/app/shared/elements/rain-forms/elements/snowflake-select/interfaces/rain-select-option.interface';
import { createSFselectOptions } from 'src/app/shared/functions/create-sf-select-options.function';
import { PostTicketModalOptions } from 'src/app/shared/services/post-ticket/post-ticket-modal-options.model';
import { PostTicketService } from 'src/app/shared/services/post-ticket/post-ticket.service';
import { InteractionTicketsState } from '../../store/state/interaction-tickets.state';
import { ChatJsonNote } from '../components/chat-json-note/assets/chat-json-note.interface';
import { RETENTION_OUTCOME_OPTIONS, RETENTION_SOLUTIONS_PROVIDED, RETENTIONS_CALL_TYPES, OUTGOING_CALL_OPTIONS, INCOMING_CALL_OPTIONS, IMMEDIATE_CANCELLATION_OPTIONS, SCHEDULED_CANCELLATION_OPTIONS } from './assets/rain-main-ticket-options.constant';
import { FormHelper } from 'src/app/Utils/helpers';
import { CustomerInfoState } from 'src/app/customer-info-summary-page/store/state/customer-info.state';
import { FilteredServiceSelectors } from 'src/app/customer-info-summary-page/store/selectors/filtered-service.selectors';
import { getCorrectProductName } from 'src/app/customer-data-components/sim-details/assets/product-lookup-table';

@Component({
  selector: 'sf-retentions-main-post-close-modal',
  templateUrl: './retentions-main-post-close-modal.component.html',
  styleUrls: ['./retentions-main-post-close-modal.component.scss']
})
export class RetentionsMainPostCloseModalComponent extends FormHelper implements OnInit {
  modalOptions: PostTicketModalOptions;
  outcomeOptions: SnowflakeSelectOption[] = createSFselectOptions(RETENTION_OUTCOME_OPTIONS);
  outgoingCallOptions: SnowflakeSelectOption[] = createSFselectOptions(OUTGOING_CALL_OPTIONS);
  incomingCallOptions: SnowflakeSelectOption[] = createSFselectOptions(INCOMING_CALL_OPTIONS)
  productOptions: SnowflakeSelectOption[];
  solutionProvidedToRetain: SnowflakeSelectOption[] = createSFselectOptions(RETENTION_SOLUTIONS_PROVIDED);
  immediateCancellationReasons: SnowflakeSelectOption[] = createSFselectOptions(IMMEDIATE_CANCELLATION_OPTIONS);
  scheduledCancellationReasons: SnowflakeSelectOption[] = createSFselectOptions(SCHEDULED_CANCELLATION_OPTIONS);
  callTypes: SnowflakeSelectOption[] = createSFselectOptions(RETENTIONS_CALL_TYPES);
  contactMade: string;
  callType: string;
  altSolutionProvided = false;
  selectedTypeOfCall: string = null;
  selectedOutcomeOption: string = null;
  ableToContinueTheForm = false;

  form: FormGroup;
  labelStyle = "thin-large-font";
  ngDestroy$: Subject<void> = new Subject();

  constructor(private fb: FormBuilder,
    private store: Store,
    private postTicketService: PostTicketService) {
    super();
  }


  ngOnInit(): void {
    this.modalOptions = new PostTicketModalOptions();

    this.form = this.fb.group({
      call_type: this.fb.control(null, Validators.compose([Validators.required])),
      outgoing_call_status: this.fb.control(null),
      incoming_call_status: this.fb.control(null),
      product_type: this.fb.control(null),
      ticket_outcome: this.fb.control(null),
      cancellation_immediate_reason: this.fb.control(null),
      cancellation_scheduled_reason: this.fb.control(null),
      solutions_provided_to_retain: this.fb.control(null),
      other_solution_provided_to_retain: this.fb.control(null),
      comments: this.fb.control('')
    });

    const services = this.store.selectSnapshot(FilteredServiceSelectors.getFilteredAndSortedNormalServices);

    const mappedServices = services.map(service => `${getCorrectProductName(service?.current_speed)} - ${service.service_reference}`);

    this.productOptions = createSFselectOptions(mappedServices);

    this.InitForm(this.form);
  }
  onSelect(controlName: string, value: string) {
    this.getControl(controlName)?.patchValue(value);
    if (controlName === 'solutions_provided_to_retain' && value === 'other') {
      this.altSolutionProvided = true;
      this.addRemoveValidators('solutions_text', true);
    } else if (controlName === 'solutions_provided_to_retain' && value != 'other') {
      this.altSolutionProvided = false
      this.addRemoveValidators('solutions_text', false);
    }

    if (controlName === 'call_type') {
      this.selectedTypeOfCall = value;
      if (value === 'Incoming') {
        this.addRemoveValidators('incoming_call_status', true);
        this.addRemoveValidators('outgoing_call_status', false);

      }
      else if (value === 'Outgoing (ticket)') {
        this.addRemoveValidators('outgoing_call_status', true);
        this.addRemoveValidators('incoming_call_status', false);

      }
    }



    if (controlName === 'outgoing_call_status' || controlName === 'incoming_call_status') {

      if (value === 'Answered' || value === 'Cancellation') {
        this.ableToContinueTheForm = true;
        this.addRemoveValidators('product_type', true);
        this.addRemoveValidators('ticket_outcome', true);


      } else {
        this.ableToContinueTheForm = false;
        this.addRemoveValidators('product_type', false);
        this.addRemoveValidators('ticket_outcome', false);
      }
    }

    if (controlName === 'ticket_outcome') {
      this.selectedOutcomeOption = value;
      if (value === 'Cancellation immediate') {
        this.addRemoveValidators('cancellation_immediate_reason', true);
        this.addRemoveValidators('cancellation_scheduled_reason', false);
        this.addRemoveValidators('solutions_provided_to_retain', false);
      }
      else if (value === 'Cancellation scheduled') {
        this.addRemoveValidators('cancellation_scheduled_reason', true);
        this.addRemoveValidators('cancellation_immediate_reason', false);
        this.addRemoveValidators('solutions_provided_to_retain', false);
      }
      else if (value === 'Retained: cancellation revoked by agent' || value === 'Retained: no cancellation scheduled') {
        this.addRemoveValidators('solutions_provided_to_retain', true);
        this.addRemoveValidators('cancellation_immediate_reason', false);
        this.addRemoveValidators('cancellation_scheduled_reason', false);
      }
    }
  }

  onSend() {
    const data = this.getPayload();
    const payload: ChatJsonNote = {
      message: "Retentions post close form",
      detail: data,
      type: "retentions_main_post_ticket_form"
    }

    const hexId = this.store.selectSnapshot(InteractionTicketsState.getSelectedHexId);
    this.postTicketService.sendPostTicketPayload(
      hexId, payload, this.modalOptions, this.close.bind(this)
    );
  }

  getPayload() {
    const formValues = this.form.getRawValue();
    if (formValues?.was_help_declined === null) {
      formValues.was_help_declined = false;
    }
    const agentEmail = this.store.selectSnapshot(CoreState.getAgent)?.name;
    const customer = this.store.selectSnapshot(CustomerInfoState.getSmartsubData)?.user;

    const payload = {};
    const shortTicket = this.store.selectSnapshot(InteractionTicketsState.getSelectedShortTicket);
    payload["ticket_hex_id"] = shortTicket.hex_id;
    payload["external_ticket_id"] = shortTicket.external_id;
    payload["start_time"] = shortTicket.inserted_at;
    payload["completion_time"] = shortTicket.updated_at;
    payload["customer_info"] = { "customer_email": customer?.email, "customer_id": customer?.id };
    payload["agent_email"] = { "agent_email": agentEmail }
    payload["feedback"] = { ...formValues };
    return payload;
  }

  private addRemoveValidators(controlName: string, required: boolean) {

    const control = this?.getControl(controlName);
    if (required) {
      control?.addValidators(Validators.required);
      control?.updateValueAndValidity();
    }
    else {
      control?.removeValidators(Validators.required);
      control?.patchValue(null);
    }
  }

  close() {
    this.store.dispatch(new ToggleModalByName("retentions_main_post_close_modal", false));
  }

  ngOnDestroy(): void {
    this.ngDestroy$.next(null);
    this.ngDestroy$.complete();
    this.close();
  }
}
