import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { ToggleModalByName } from 'src/app/core/store/actions/modal.actions';
import { CoreState } from 'src/app/core/store/state/core.state';
import { FeatureFlagsActive } from 'src/environments/feature-flags';
import { FeatureFlagActions } from '../store/actions/feature-flag-actions';
import { FeatureFlagState } from '../store/state/feature-flag.state';

type EntryOption = [string, boolean];

@Component({
  selector: 'sf-feature-flag-selector-modal',
  templateUrl: './feature-flag-selector-modal.component.html',
  styleUrls: ['./feature-flag-selector-modal.component.scss']
})
export class FeatureFlagSelectorModalComponent implements OnInit, OnDestroy {

  @Select(CoreState.hasOneOfTheseRoles(["AUTH.USER.MANAGEMENT", "AUTH.USER.ADMIN", "CEC.WIPROGEN.A"])) hasAccess$: Observable<boolean>;

  form: FormGroup;
  featureOptions = this.store.selectSnapshot(FeatureFlagState.getFlags);
  toggleOptions = Object.keys(this.featureOptions);

  constructor(private store: Store,
    private fb: FormBuilder) { }

  ngOnInit(): void {
    const formControls = Object
      .entries(this.featureOptions)
      .reduce((previous, [name, value]: EntryOption) => {
        previous[name] = this.fb.control(value, [Validators.required])
        return previous;
      },
        {});

    this.form = this.fb.group({
      ...formControls
    });
  }

  apply() {
    const options = <FeatureFlagsActive>this.form.getRawValue();
    this.store.dispatch(new FeatureFlagActions.SetFeatureFlags(options));
    this.onClose();
  }

  onClose() {
    this.store.dispatch(new ToggleModalByName("feature_flag_modal", false));
  }

  ngOnDestroy(): void {
    this.onClose();
  }


}
