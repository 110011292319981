import { SFValidators } from "src/app/shared/functions/sf-validators";



type KeyOfObject<T> = keyof T;

/**
 * Check if an object has all the specified properties/keys
 */
export function hasAllProperties<T extends Record<string | number, any>>(obj: T, properties: KeyOfObject<T>[]) {

  for (const property of properties) {
    if (!SFValidators.hasProperty(obj, <string>property)) {
      return false;
    }
  }

  return true;
}
