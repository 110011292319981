import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Store } from '@ngxs/store';
import { takeUntil } from 'rxjs/operators';
import { FormHelper } from 'src/app/Utils/helpers';
import { ToggleModalByName } from 'src/app/core/store/actions/modal.actions';
import { SnowflakeSelectOption } from 'src/app/shared/elements/rain-forms/elements/snowflake-select/interfaces/rain-select-option.interface';
import { PostTicketModalOptions } from 'src/app/shared/services/post-ticket/post-ticket-modal-options.model';
import { PostTicketService } from 'src/app/shared/services/post-ticket/post-ticket.service';
import { InteractionTicketsState } from '../../store/state/interaction-tickets.state';
import { ChatJsonNote } from '../components/chat-json-note/assets/chat-json-note.interface';

@Component({
  selector: 'sf-post-collections-modal-b',
  templateUrl: './post-collections-modal-b.component.html',
  styleUrls: ['./post-collections-modal-b.component.scss']
})
export class PostCollectionsModalBComponent extends FormHelper implements OnInit {
  form: FormGroup;
    modalOptions: PostTicketModalOptions;
    isCustomerContacted = false;
    isOneFieldSelected = false;

    customerContactedOptions: SnowflakeSelectOption[] = [
        { label: 'yes', value: 'yes' },
        { label: 'no', value: 'no', selected: true }
    ]

    constructor(private fb: FormBuilder,
        private store: Store,
        private postTicketService: PostTicketService,
    ) {
        super();
    }


    ngOnInit(): void {
        this.modalOptions = new PostTicketModalOptions();
        this.createPostCollectionsForm();
        this.InitForm(this.form);
        this.litenToFormChanges();
    }

    createPostCollectionsForm() {
        this.form = this.fb.group({
            customer_contacted: this.fb.control('no'),

            //CUSTOMER CONTACTED OPTIONS
            take_my_money_now: this.fb.control(false),
            pay_now: this.fb.control(false),
            logged_PTP: this.fb.control(false),
            promise_to_callback: this.fb.control(false),
            partial_payment: this.fb.control(false),
            payment_arrangement: this.fb.control(false),
            card_details_updated: this.fb.control(false),
            refuse_to_pay: this.fb.control(false),
            device_collected_received: this.fb.control(false),
            cancelled_or_wants_to_cancel:this.fb.control(false),
            customer_retained:this.fb.control(false),


            //CUSTOMER NOT CONTACTED OPTIONS
            payment_already_received: this.fb.control(false),
            voicemail: this.fb.control(false),
            third_party_reached: this.fb.control(false),
            customer_not_reachable: this.fb.control(false),
        });
    }

    private litenToFormChanges() {
        this.form.valueChanges
            .pipe(takeUntil(this.ngDestroy$))
            .subscribe({
                next: () => this.updateValidation()
            })
    }

    onOptionSelect(value: "yes" | "no") {
        const isContacted = value === "yes";
        this.isCustomerContacted = isContacted;
        this.form?.get("customer_contacted")?.patchValue(value);
    }

    private updateValidation() {
        this.isOneFieldSelected = this.checkIfOneValueSelected();
    }

    private checkIfOneValueSelected() {
        const formValues = this.form.getRawValue();
        const keysToCheck = Object.keys(formValues).filter(key => key !== "customer_contacted");

        for (const key of keysToCheck) {
            const control = this.getControl(key);
            if (control?.value) {
                return true;
            }
        }
        return false;
    }

    onSend() {
        const hexId = this.store.selectSnapshot(InteractionTicketsState.getSelectedHexId);
        const formData = this.form.getRawValue();

        const payload: ChatJsonNote = {
            message: "Collections B post close form",
            detail: formData,
            type: "collections_form"
        }

        this.postTicketService.sendPostTicketPayload(
            hexId,
            payload,
            this.modalOptions,
            this.close.bind(this),
        );
    }

    close() {
        this.store.dispatch(new ToggleModalByName('post_collections_modal_b', false));
    }

    ngOnDestroy(): void {
        this.ngDestroy$.next();
        this.ngDestroy$.complete();
        this._form.reset;
        this.close();
    }
}
