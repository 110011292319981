import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AgentActionLoggingRequest } from '../store/types/agent-action-logging-request.interface';
import { environment } from 'src/environments/environment';
import { DEFAULT_HTTP_OPTIONS } from 'src/app/constants';

@Injectable({
    providedIn: 'root'
})
export class AgentActionLoggingService {

    constructor(private http: HttpClient) { }

    logAction(payload: AgentActionLoggingRequest) {
        const url = `${environment.main_proxy}/agent-action-service/agent-action`;

        return this.http.post<void>(url, payload, DEFAULT_HTTP_OPTIONS);
    }
}