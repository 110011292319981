import { StatusGroup } from "src/app/shared/data-handler/datafield-options.interface";
import { TextAndBackgroundColor } from "./text-and-background-color.interface";
import { DataHandler } from "src/app/shared/data-handler/data-handler";
import { AgentStatus } from "./agent-status.type";

export const AGENT_STATUS_COLOR_MAP: StatusGroup = {
  green: ["available"],
  orange: ["in call", "in ticket", "busy", "post-ticket", "break", "tea break 1", "tea break 2", "coaching", "meeting"],
  red: [],
  defaultColor: "grey"
  //"logged on" will be grey
}

export const TEXT_AND_BACKGROUND_COLOR_MAP: { [key: string]: TextAndBackgroundColor } = {
  green: {
    textColor: "#009A52",
    backgroundColor: "#D9FFEA"
  },
  orange: {
    textColor: "#F7B200",
    backgroundColor: "rgb(255 238 196 / 54%)"
  },
  red: {
    textColor: "#E02020",
    backgroundColor: "#dd7d7d;"
  },
  grey: {
    textColor: "#424242",
    backgroundColor: "#c4c4c4"
  }
};

export const getAgentStatusColorChoice = (status: AgentStatus) => {
  const colorChoice = DataHandler.getStatusColorChoice(status, AGENT_STATUS_COLOR_MAP);
  return TEXT_AND_BACKGROUND_COLOR_MAP[colorChoice];
}
