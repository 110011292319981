

export class PopupError extends Error {

    constructor(public message: string, public log = true) {
        super(message);
        if (log) {
            console.error(this);
        }
    }

}