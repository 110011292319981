import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EyeballCounterComponent } from './eyeball-counter.component';
import { MatBadgeModule } from '@angular/material/badge';
import { SharedModule } from '../../shared.module';


@NgModule({
    declarations: [EyeballCounterComponent],
    imports: [
        CommonModule,
        MatBadgeModule,
        SharedModule,
    ],
    exports: [EyeballCounterComponent]
})
export class EyeballCounterModule { }