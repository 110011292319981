import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { BehaviorSubject, Subject } from 'rxjs';
import { Invitation, Inviter, InviterInviteOptions, InviterOptions, Registerer, RegistererRegisterOptions, RegistererState, Session, SessionState, URI, UserAgent } from 'sip.js';
import { holdModifier } from 'sip.js/lib/platform/web';
import { AuthService } from 'src/app/auth/auth.service';
import { AddNotification } from 'src/app/core/store/actions/notifications.actions';
import { DataHandler } from 'src/app/shared/data-handler/data-handler';
import { ModalWindowService } from 'src/app/shared/modal-window/modal-window.service';
import { Utils } from 'src/app/Utils';
import { environment } from 'src/environments/environment';
import { ICallQueues } from '../assets/call-queue.interface';
import { SipSessionHandler } from '../assets/handlers/sip-session-handler.model';
import { SoundHandler } from '../assets/handlers/sound-handler.model';
import { SipCallType } from '../assets/sip-call.type';
import { SipConfig, REALM, getCallTransferQueue } from '../assets/sip-config';
import { SipMessageSubject } from '../assets/sip-message-subject.type';
import { SipPhoneActions } from '../store/actions/sip-phone-actions';
import { AgentVoiceQueueState } from '../store/state/agent-voice-queue-state';
import { QueueData } from './sip-platform/assets/voice-queue-info.interface';
import { AgentState } from 'src/app/shared/components/agent/store/state/agent.state';


const MAX_ATTEMPTS = 5;
const DELAY = 1000;
const FAILED_REGISTRATION_MESSAGE = "Failed to register voice connection.";
const FAILED_USER_AGENT_MESSAGE = "Failed to start voice connection.";

@Injectable({
  providedIn: 'root'
})
export class SipPhoneService {

  readonly userAgentConnectedSubject = new Subject<boolean>();
  readonly callSubject = new Subject<Map<SipMessageSubject, unknown>>();

  readonly hasActiveSession$ = new BehaviorSubject(false);

  private _hasAudioDevice = false;
  private _activeSession: Session;
  private _userAgent: UserAgent;
  private _callType: SipCallType;
  private _registerer: Registerer;
  inviterOptions: InviterInviteOptions = {}


  constructor(private authService: AuthService,
    private modal: ModalWindowService,
    private store: Store) {
  }

  get isUserAgentConnected(): boolean {
    if (!DataHandler.isDefined(this.userAgent)) {
      return false;
    }
    return this.userAgent?.isConnected();
  }

  get inCall() {
    return this._activeSession?.state === SessionState.Established;
  }

  get activeSession() {
    return this._activeSession;
  }

  get callType() {
    return this._callType;
  }

  private set userAgent(userAgent: UserAgent) {
    this._userAgent = userAgent;
  }

  private get userAgent() {
    return this._userAgent;
  }

  private broadcastUserAgentConnectionState(state: boolean) {
    this.userAgentConnectedSubject.next(state);
  }

  private setActiveSession(session: Session, callType: SipCallType) {
    this._activeSession = session;
    this.setCallType(callType);
    this.hasActiveSession$.next(Boolean(session));
  }

  private setCallType(callType: SipCallType) {
    this._callType = callType;
    this.store.dispatch(new SipPhoneActions.SetCurrentCallType(callType));
    this.sendCallSubjectMessage("call_type_changed", this._callType);
  }

  sendCallSubjectMessage(subjectName: SipMessageSubject, data?: unknown) {
    this.callSubject.next(new Map().set(subjectName, data));
  }

  async retryUserAgentConnection() {
    this._hasAudioDevice = false;
    await this.stopUserAgentConnection();
    this.initializeUserAgent();
  }

  async stopUserAgentConnection() {
    this.store.dispatch(new SipPhoneActions.SetUserAgentProcessing(true));

    if (this.userAgent && this.userAgent.isConnected()) {
      await this.userAgent.stop();
    }
    this.userAgent = null;

    this.store.dispatch(new SipPhoneActions.SetUserAgentProcessing(false));
  }

  //TODO: Write this shit code better, use Observables instead of promises
  async initializeUserAgent(): Promise<void> {
    this.store.dispatch(new SipPhoneActions.SetUserAgentProcessing(true));

    await this.promtForAllowAudio();
    if (!this.userAgent && this._hasAudioDevice) {

      const result = await this.tryToStartUserAgent();
      if (result?.hasData()) {
        await this.tryToRegisterUserAgent();
      }
    }

    this.store.dispatch(new SipPhoneActions.SetUserAgentProcessing(false));
  }

  private tryToStartUserAgent() {
    const fn = async () => {
      await this.createUserAgent();
      await this.userAgent?.start();
      return this.userAgent?.isConnected();
    }

    const resetFn = () => {
      if (this.userAgent?.isConnected()) {
        return this.userAgent?.stop();
      }
    }

    return Utils.Helpers.autoRetryPromise(
      fn,
      {
        maxAttempts: MAX_ATTEMPTS,
        delayAmount: DELAY,
        cleanupFn: err => {
          this.stopUserAgentConnection();
          this.showActionErrorPopup(FAILED_USER_AGENT_MESSAGE, err);
        },
        resetFn
      }
    );
  }

  private async createUserAgent() {
    const agentName = await this.authService.getUserName();
    const username = agentName.replace("@", ".").substring(0, 32);
    const password = environment.sipPassword;
    const onInvite = (invitation: Invitation) => this.onInvite(invitation);
    const userAgentOptions = SipConfig.getSipConfig(username, password, onInvite);
    this.userAgent = new UserAgent(userAgentOptions);
    return this.userAgent;
  }

  //this is outgoing because you will be sending an invite to the spesified number
  async getInviter(phoneNumber: string): Promise<Inviter> {
    const options: InviterOptions = {
      sessionDescriptionHandlerOptions: {
        constraints: {
          audio: true,
          video: false
        }
      }
    }
    const target = new URI("sip", phoneNumber, REALM);
    await this.initializeUserAgent();
    if (!this._hasAudioDevice) return null;
    const inviter = new Inviter(this.userAgent, target, options);
    this.setActiveSession(inviter, "outgoing");
    return inviter;
  }

  private async promtForAllowAudio() {
    if (this._hasAudioDevice) return;
    try {
      await navigator.mediaDevices.getUserMedia({ audio: true });
      this._hasAudioDevice = true;
    }
    catch (error) {
      this._hasAudioDevice = false;
      this.showActionErrorPopup("No microphone detected.", error);
    }
  }

  private tryToRegisterUserAgent() {
    const fn = () => {
      this._registerer = new Registerer(this.userAgent);
      const options = this.getRegistererOptions();
      return this._registerer.register(options);
    }

    const resetFn = async () => {
      if (this._registerer?.state === RegistererState.Registered) {
        await this._registerer?.unregister();
      }
      return this._registerer?.dispose();
    }

    return Utils.Helpers.autoRetryPromise(
      fn,
      {
        maxAttempts: MAX_ATTEMPTS,
        delayAmount: DELAY,
        cleanupFn: error => this.onRegistrationFail(error),
        resetFn
      }
    );
  }

  private getRegistererOptions(): RegistererRegisterOptions {
    return {
      requestDelegate: {
        onReject: res => {
          const error = res?.message?.reasonPhrase ?? "SIP registration was rejected.";
          this.onRegistrationFail(error);
        }
      }
    }
  }

  private async onRegistrationFail(error: unknown) {
    await this._registerer?.dispose();
    this.stopUserAgentConnection();

    this.showActionErrorPopup(FAILED_REGISTRATION_MESSAGE, error);
  }

  private onInvite(invitation: Invitation) {
    //if already in a call reject any other invites
    const status = this.store.selectSnapshot(AgentState.getStatus);
    const acceptingCalls = this.store.selectSnapshot(AgentState.isAcceptingCalls);

    if (this._activeSession || status !== "available" || !acceptingCalls) {
      invitation.reject();
      return;
    }
    this.setActiveSession(invitation, "incoming");
    this.sendCallSubjectMessage("invitation", invitation);
  }

  handleSession(session: Invitation | Inviter) {
    const voiceElement = document.getElementById('voiceElement') as HTMLAudioElement;
    const ringElement = document.getElementById(this._callType) as HTMLAudioElement;

    const sipSessionHandler = new SipSessionHandler(this.store, voiceElement, ringElement, this._callType);
    sipSessionHandler.handleSession(session,
      {
        onChangeFunction: (state: SessionState) => this.sendCallSubjectMessage.bind(this)("state_changed", state),
        onCleanupFunction: this.cleanupSession.bind(this)
      });
  }

  private showActionErrorPopup(message: string, error: unknown) {
    this.modal.showActionModal(
      message,
      [new SipPhoneActions.RetryUserAgentRegistration()],
      {
        header: "failure",
        error: Utils.Helpers.findError(error, "Unknown Error"),
        confirmMessage: "retry",
        declineMessage: "close"
      }
    );

    this.broadcastUserAgentConnectionState(false);
    this.cleanupSession();
    this.sendCallSubjectMessage("error", error);
  }

  private async cleanupSession() {
    if (this._activeSession) {
      await this._activeSession?.dispose();
      this._activeSession = null;
    }

    this.store.dispatch(new SipPhoneActions.CallSessionEnded(this._callType));
    this.setCallType(null);
    this.hasActiveSession$.next(false);
  }

  async endCall() {
    if (!this._activeSession) return;
    switch (this._activeSession.state) {
      case SessionState.Initial:
      case SessionState.Establishing:
        if (this._activeSession instanceof Inviter) {
          // An unestablished outgoing session
          const inviter: Inviter = this._activeSession;
          await inviter.cancel();
        }
        else {
          // An unestablished incoming session
          await (this._activeSession as Invitation)?.reject();
        }
        break;
      case SessionState.Established:
        // An established session
        await this._activeSession.bye();
        break;
      case SessionState.Terminating:
      case SessionState.Terminated:
        // Cannot terminate a session that is already terminated
        break;
    }
  }

  toggleSounds(play: boolean) {
    SoundHandler.toggleSound(document.getElementById(this._callType) as HTMLAudioElement, play);
  }

  async onHold() {
    this.inviterOptions['sessionDescriptionHandlerModifiers'] = [holdModifier]
    this._activeSession.invite(this.inviterOptions)
  }

  async resumeCall() {
    this.inviterOptions['sessionDescriptionHandlerModifiers'] = []
    this._activeSession.invite(this.inviterOptions)
  }


  transferCall(queue: ICallQueues) {
    const currentQueue: QueueData = this.store.selectSnapshot(AgentVoiceQueueState.getQueue);
    if (queue.id === currentQueue.id) {
      this.store.dispatch(new AddNotification({ type: 2, title: 'ERROR', message: 'Not allowed to transfer to the same voice queue' }))
      return;
    }

    const target = getCallTransferQueue(queue.ext);
    this._activeSession.refer(target)
      .then(() => {
        this.store.dispatch(new AddNotification({ type: 0, title: 'SUCCESS', message: 'Call transferred successfully' }))
      })
      .catch(() => {
        this.store.dispatch(new AddNotification({ type: 2, title: 'ERROR', message: 'Call not transferred!' }))
      })
  }

  getCurrentCallStateDetails(): { inCall: boolean, isInboundCall: boolean, registered: boolean } {
    const inCall = this.inCall;
    const isInboundCall = inCall ? this.callType === "incoming" : null;
    const registered = this.isUserAgentConnected;

    return {
      inCall,
      isInboundCall,
      registered
    }
  }

}

