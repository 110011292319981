<!-- *ngIf="hasAccess$ | async" -->
<sf-popup-modal [isOpen]="true" [hasCloseButton]="true" (close)="onClose()" [autoClose]="true">

    <ng-container header>select features to enable</ng-container>

    <ng-container mainContent>

        <div *ngIf="form" class="checkboxes">
            <sf-form-checkbox *ngFor="let option of toggleOptions" class="checkbox" [control]="form.get(option)"
                [label]="option | prettifyKey">
            </sf-form-checkbox>
        </div>

    </ng-container>

    <ng-container buttons>
        <sf-rain-button [isSolid]="true" (click)="apply()">apply</sf-rain-button>
    </ng-container>
</sf-popup-modal>