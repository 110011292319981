<sf-popup-modal *ngIf="isOpen" [isOpen]="isOpen" [hasCloseButton]="true" [autoClose]="false" [limitHeight]="true"
  (close)="closeModal()">

  <ng-container header><span class="red">WARNING!</span></ng-container>
  <ng-container mainContent>
    <div class="content">
      <h2 class="red">You are currently using a testing environment!</h2>

      <h3>Please follow this link to the correct Snowflake page:</h3>
      <h3><a href="https://snowflake.devops.rain.co.za/home">https://snowflake.devops.rain.co.za/home</a></h3>
    </div>
  </ng-container>

</sf-popup-modal>
