import { Category, ProductType } from "src/app/core/store/interfaces/rain-product.interface";
import { MigrationExtras } from "src/app/sales/sales-portal/components/level-up/store/types/migrate-level.interfaces";
import { TechType } from "src/app/shared/services/google-maps/assets/techtype.type";

export interface OrderDelivery {
    channel_type?: string;
    instructions?: string;
    rica_type?: string;
    sales_channel?: string;
    sim_type?: string;
    time?: string;
    type?: "collection" | "scheduled";
}

export interface ConfigItem {
    name?: string;
    value?: string | number;
}


export interface ProductConfig {
    configs?: ConfigItem | ConfigItem[];
    iccid?: string;
    msisdn?: string;
    value_adds?: {
        id: string;
    }[];
    [x: string]: unknown;
}

export interface OrderLine {
    amount?: number;
    category?: ProductType | Category;
    currency?: string;
    description?: string;
    id?: string;
    number?: string;
    order_id?: string;
    product_configs?: ProductConfig;
    product_id?: string;
    quantity?: number;
    status?: string;
    unit_price?: number;
    name?: string;
}

export interface CustomerOrder {
    complete?: boolean;
    delivery?: OrderDelivery;
    id?: string;
    order_date?: string;
    order_lines?: OrderLine[];
    order_number?: number;
    status?: CustomerOrderStatus;
    comments: string;
}

export interface CustomerOrderCommentsJson extends MigrationExtras {
    voucher?: string;
    isIn5G?: boolean;
}

export interface ESimCommentsJson {
    esim_allocation: { [key in TechType]: number };
}

export type CustomerOrderStatus = | 'Created'
    | "Queued for Delivery"
    | "Queued for Collection"
    | "Delivered"
    | "Collected"
    | 'Partially delivered'
    | 'Partially collected'
    | 'Rica Complete'
    | 'Queued for RICA'
    | 'Delivery Failed'
    | 'RICA Processing'
    | "Order Cancelled"
    | 'Payment Failed'
    | 'Redelivered'
    | 'Payment not Completed'
//And more


export const UNSUCCESSFUL_ORDER_STATES: CustomerOrderStatus[] = [
    'Delivery Failed', "Order Cancelled", 'Payment Failed'
]



export const DISABLE_ORDER_BUTTONS_STATES: CustomerOrderStatus[] = [
  'Payment Failed', 'Payment not Completed', 'Collected', 'Delivered', 'Order Cancelled', 'Redelivered', 'Created', 'Rica Complete'
]

export enum OrderStatuses {
    None = 0,
    PaymentFailedDelivery,
    OrderProcessedDelivery,
    InTransit,
    DeliveryFailed,
    Delivered,
    DeliveryProcessed,
    PartiallyDelivered,
    ActivatedDelivered,
    CancelledDelivery,

    PaymentFailedCollection,
    OrderProcessedCollection,
    CollectionProcessed,
    PartiallyCollected,
    ActivatedCollected,
    CancelledCollection,

    PaymentFailedRICA,
    OrderProcessedRICA,
    RICAProcessed,
    PartialRICA,
    ActivatedRICA,
    CancelledRICA
}
