import { Utils } from "src/app/Utils";
import { NWDAFConnectedSite } from "../../interfaces/nwdaf-connected-site.interface";



export class FetchByIMSI {
    static readonly type = Utils.Helpers.Type("[NWDAF Connected Site] Fetch by imsi");
    constructor(public imsi: string) { }
}

export class FetchByIMSIIfNotPreset {
    static readonly type = Utils.Helpers.Type("[NWDAF Connected Site] Fetch by imsi if not present");
    constructor(public imsi: string) { }
}

export class FetchByIMSISuccess {
    static readonly type = Utils.Helpers.Type("[NWDAF Connected Site] Fetch by imsi success");
    constructor(public imsi: string, public payload: NWDAFConnectedSite) { }
}

export class FetchByIMSIFail {
    static readonly type = Utils.Helpers.Type("[NWDAF Connected Site] Fetch by imsi fail");
    constructor(public imsi: string, public error: any) { }
}

export class Clear {
    static readonly type = Utils.Helpers.Type("[NWDAF Connected Site] Clear all");
}