


export const SF_MODAL_DEFAULTS = {
    credit_modal: false,
    refund_modal: false,
    addon_removed_modal: false,
    sales_skin_select_modal: false,
    sales_speedup_modal: false,
    wallet_topup_modal: false,
    new_customer_ticket_modal: false,
    two_for_one_voucher_modal: false,
    edit_two_for_one_voucher_modal: false,
    cancellation_modal: false,
    immediate_cancellation_modal: false,
    cancel_revert_modal: false,
    promise_to_pay_modal: false,
    tmmn_modal: false,
    cancel_promise_to_pay_modal: false,
    erica_redelivery_modal: false,
    post_sales_modal: false,
    post_upgrade_modal: false,
    add_voice_user_manual_modal: false,
    add_voice_user_file_modal: false,
    edit_voice_user_queues_modal: false,
    post_collections_modal: false,
    escalation_modal: false,
    post_activations_modal: false,
    update_ticket_warning_modal: false,
    rica_post_close_modal: false,
    default_post_ticket_modal: false,
    retentions_update_modal: false,
    retentions_update_4G_modal: false,
    retentions_close_4G_modal: false,
    retentions_close_modal: false,
    csat_close_modal: false,
    faq_scenarios_modal: false,
    post_campaign_call_modal: false,
    early_optimizations_close_modal: false,
    play_call_recording_modal: false,
    feature_flag_modal: false,
    timeout_modal: false,
    react_to_ticket_modal: false,
    delatch_customer_connection_modal: false,
    wallet_credit_modal: false,
    rain_one_level_up_modal: false,
    sales_level_up_modal: false,
    rain_one_add_phone_lines_modal: false,
    rain_one_add_wifi_speed_modal: false,
    upgrade_to_rainone: false,
    cancel_rain_one_modal: false,
    cancel_rain_one_revert_modal: false,
    usage_history_modal: false,
    confirm_buy_more_modal: false,
    confirm_wifi_speed_up_modal: false,
    rain_one_retentions_post_close_modal: false,
    out_of_coverage_modal: false,
    check_existing_client_modal: false,
    upcoming_callback_modal: false,
    confirm_international_calling_top_up_modal: false,
    sim_swop_modal: false,
    device_swop_modal: false,
    msisdn_swap_modal: false,
    order_lines_serial_number_modal: false,
    cec_team_a_to_f_post_close_modal: false,
    is_esim_ready_modal: false,
    update_phone_number_modal: false,
    update_policy_modal: false,
    update_details_modal: false,
    scheduled_action_modal: false,
    update_nvidia_tier_modal: false,
    nvidia_post_close_modal: false,
    token_expired_modal: false,
    post_collections_modal_b: false,
    fulfillment_deliveries_post_close_modal: false,
    fulfillment_cpe_collections_post_close_modal: false,
    fulfillment_rica_post_close_modal: false,
    black_friday_coupon_modal: false,
    retail_customer_engagement_post_close_modal: false,
    nvidia_sales_modal: false,
    return_cpe_modal: false,
    view_terms_modal: false,
    select_product_modal: false,
    rica_auth_confirmation: false,
    create_work_account_alert_modal: false,
    retentions_main_post_close_modal: false,
    top_up_modal: false,
    rain101_debug_modal: false,
    rain_101_post_close_modal: false,
    change_individual_level_modal: false,
    number_porting_modal: false,
    wallet_adjustment_modal: false,
    buy_more_modal: false,
    force_activate_modal: false,
    switch_to_prepaid_modal: false,
    add_prepaid_balance_modal: false,
} as const;

export type ModalOptions = { [key in SfModalName]: boolean };

export type SfModalName = keyof typeof SF_MODAL_DEFAULTS;


export function getModalStartingData(): { [key in SfModalName]?: unknown } {
    const data = {};
    Object.keys(SF_MODAL_DEFAULTS).forEach(key => data[key] = null);
    return data;
}
