import tinycolor from "tinycolor2";
import { SetIntervalType } from "src/app/shared/types/set-interval.type";

export interface CustomCircleOptions {
  maxSize: number;
  minSize: number;
  color: string;
}

interface PulsatingDotAnimationOptions extends CustomCircleOptions {
  minOpacity: number;
}

export class PulsatingDot {

  private _circles: google.maps.Marker[] = [];
  private _intervalRef: SetIntervalType;

  constructor(private _map: google.maps.Map, private _latLng: google.maps.LatLng, private readonly _options: CustomCircleOptions) {
  }

  /**
   * Adds the pulsating dot to the map.
   * @param delay The delay in miliseconds before the circle is updated again. Default is 45.
   */
  add(delay = 45): void {

    const firstAnimationOptions: PulsatingDotAnimationOptions = {
      ...this._options,
      minOpacity: 0.10,
    }
    const firstCircle = this.createCircle(this._latLng, this._options);
    const animateCircle1 = this.getAnimateCircleFunction(firstCircle, firstAnimationOptions);

    const secondCircleOptions = Object.assign({}, this._options);
    secondCircleOptions.maxSize = secondCircleOptions.minSize * 1.20;
    secondCircleOptions.color = tinycolor(secondCircleOptions.color).darken(30).toString();

    const secondAnimationOptions: PulsatingDotAnimationOptions = {
      ...secondCircleOptions,
      minOpacity: 0.40,
    }
    const secondCircle = this.createCircle(this._latLng, secondAnimationOptions);
    const animateCircle2 = this.getAnimateCircleFunction(secondCircle, secondAnimationOptions);

    this._circles = [firstCircle, secondCircle];
    this.show();

    this._intervalRef = setInterval(() => {
      animateCircle1();
      animateCircle2();
    }, delay);

  }


  private getAnimateCircleFunction(circle: google.maps.Marker, options: PulsatingDotAnimationOptions): CallableFunction {
    const maxSize = options.maxSize;
    const minSize = options.minSize;

    const startingIcon = circle?.getIcon() as google.maps.Symbol;
    const startingFillOpacity = startingIcon.fillOpacity;
    const startingStrokeOpacity = startingIcon.strokeOpacity;

    const incrementAmount = (maxSize - minSize) * 0.05;
    const opacityReductionRatio = 0.95;

    return () => {
      const currIcon = circle?.getIcon() as google.maps.Symbol;
      const currScale = currIcon?.scale;

      if (currScale >= maxSize) {
        //Reset values
        currIcon.scale = minSize;
        currIcon.fillOpacity = startingFillOpacity;
        currIcon.strokeOpacity = startingStrokeOpacity;
      }
      else {
        //increase size
        currIcon.scale = currScale + incrementAmount;

        if (currIcon.fillOpacity > options.minOpacity) {
          //Reduce opacity
          currIcon.fillOpacity = currIcon.fillOpacity * opacityReductionRatio;
          currIcon.strokeOpacity = currIcon.fillOpacity * opacityReductionRatio;
        }
      }
      circle.setIcon(currIcon);
    };
  }


  private createCircle(latLng: google.maps.LatLng, options: CustomCircleOptions) {
    const color = options.color;

    const circleIcon: google.maps.ReadonlySymbol = {
      path: google.maps.SymbolPath.CIRCLE,
      fillOpacity: 0.9,
      fillColor: color,
      strokeOpacity: 0.9,
      strokeColor: color,
      strokeWeight: 1.0,
      scale: options.minSize,
    };

    return new google.maps.Marker({
      icon: circleIcon,
      position: latLng,
      zIndex: 0,
      clickable: false
    });
  }

  hide() {
    this._circles.forEach(circle => circle?.setMap(null));
  }

  show() {
    this._circles.forEach(circle => circle?.setMap(this._map));
  }

  remove() {
    this._circles.forEach(circle => this.removeCircle(circle));
    this.removeInterval();
  }

  private removeInterval() {
    if (this._intervalRef) {
      clearInterval(this._intervalRef);
    }
  }

  private removeCircle(circle: google.maps.Marker) {
    if (circle) {
      circle?.setMap(null);
    }
    circle = null;
  }


}
