import { Injectable } from '@angular/core';
import { Action, createSelector, State, StateContext, } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { AddInfoNotification, AddSuccessResponseNotification } from 'src/app/core/store/actions/notifications.actions';
import { SFValidators } from 'src/app/shared/functions/sf-validators';
import { DataLoading } from 'src/app/shared/interfaces/data-loading.interface';
import { SuccessDataResponse } from 'src/app/shared/success-response-handler/success-data-response.model';
import { SuccessResponse } from 'src/app/shared/success-response-handler/success-response.model';
import { Utils } from 'src/app/Utils';
import { DataLoadingHelper } from 'src/app/Utils/helpers';
import { SiteDataService } from '../../services/gemini.service';
import { FetchAzimuthData, FetchAzimuthDataFail, FetchAzimuthDataSuccess } from '../actions/azimuth.actions';
import { GeminiFeature, GeminiFeatureCollection } from '../interfaces/gemini-response.interface';



interface AzimuthStateModel {
  [siteID: string]: DataLoading<GeminiFeature>;
}
@State<AzimuthStateModel>({
  name: 'sf_azimuth_state',
  defaults: {}
})
@Injectable()
export class AzimuthState {


  static isLoading(siteID: string | number) {
    return createSelector([AzimuthState], (state: AzimuthStateModel) => {
      return state?.[siteID]?.loading;
    });
  }

  static isLoaded(siteID: string | number) {
    return createSelector([AzimuthState], (state: AzimuthStateModel) => {
      return state?.[siteID]?.loaded;
    });
  }

  static getError(siteID: string | number) {
    return createSelector([AzimuthState], (state: AzimuthStateModel) => {
      return state?.[siteID]?.error;
    });
  }

  static getData(siteID: string | number) {
    return createSelector([AzimuthState], (state: AzimuthStateModel) => {
      return state?.[siteID]?.data;
    });
  }


  constructor(private siteDataService: SiteDataService) {
  }


  @Action(FetchAzimuthData)
  fetchAzimuthData(ctx: StateContext<AzimuthStateModel>, action: FetchAzimuthData) {
    const { siteID } = action;

    DataLoadingHelper.initNestedState(ctx, siteID);
    DataLoadingHelper.setNestedDataLoading(ctx, siteID);

    ctx.dispatch(new AddInfoNotification(`Fetching azimuth data for site ID: ${siteID}.`));

    return this.siteDataService.search(siteID)
      .pipe(tap({
        next: res => {
          const foundFeatureResponse = findCorrectFeature(siteID, res);
          if (foundFeatureResponse.success) {
            ctx.dispatch(new FetchAzimuthDataSuccess(siteID, foundFeatureResponse.data as GeminiFeature));
          }
          else {
            const error = foundFeatureResponse.data as string;
            ctx.dispatch(new FetchAzimuthDataFail(siteID, error));
          }
        },
        error: e => ctx.dispatch(new FetchAzimuthDataFail(siteID, e))
      }))


  }

  @Action(FetchAzimuthDataSuccess)
  fetchAzimuthDataSuccess(ctx: StateContext<AzimuthStateModel>, action: FetchAzimuthDataSuccess) {
    const { siteID, payload } = action;
    DataLoadingHelper.setNestedData(ctx, siteID, payload);
  }

  @Action(FetchAzimuthDataFail)
  fetchAzimuthDataFail(ctx: StateContext<AzimuthStateModel>, action: FetchAzimuthDataFail) {
    const { siteID, error } = action;
    const formattedError = Utils.Helpers.findError(error, "");
    const errorMessage = `Azimuth error for site ID: ${siteID}. Error: ${formattedError}`;
    DataLoadingHelper.setNestedError(ctx, siteID, errorMessage);

    const successResponse = new SuccessResponse(false, errorMessage);
    ctx.dispatch(new AddSuccessResponseNotification(successResponse));
  }

}


function findCorrectFeature(siteID: number, featureCollection: GeminiFeatureCollection): SuccessDataResponse<GeminiFeature | string> {
  const feature = featureCollection?.features?.find(f => f?.id === siteID);
  const antennas = feature?.properties?.antenna;

  if (!antennas?.length) {
    return new SuccessDataResponse(false, "Antenna list is empty.");
  }

  for (const antenna of antennas) {
    if (SFValidators.isNotDefined(antenna?.azi)) {
      return new SuccessDataResponse(false, "Azimuth field is missing or null.");
    }

    if (SFValidators.isNotDefined(antenna?.rad)) {
      return new SuccessDataResponse(false, "Radius field is missing or null.");
    }
  }

  return new SuccessDataResponse(true, feature);
}
