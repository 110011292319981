import { Injectable } from '@angular/core';
import { ApiControllerService } from 'src/app/shared/services/api-service/api-controller.service';
import { environment } from 'src/environments/environment';
import { ApiResponse } from 'src/app/shared/services/api-service/api-response.interface';
import { SmartsubAlarm } from './alarm-count.interface';
import { AlarmSiteFeatureCollection } from 'src/app/shared/services/google-maps/assets/map-site.interfaces';
import { HttpClient } from '@angular/common/http';
import { SiteAlarmDetails } from 'src/app/shared/elements/map/store/interfaces/site-alarm-details.interface';


@Injectable({
  providedIn: 'root'
})
export class AlarmService {

  constructor(private apiService: ApiControllerService,
    private http: HttpClient) { }


  fetchAlarms() {
    const url = `${environment.smartSubApi}/data/alarm_features`;
    return this.http.get<{ data: AlarmSiteFeatureCollection }>(url);
  }

  async getAlarmsInAreaAroundSite(coordinates: number[], tech: string): Promise<number> {
    const requestPath = `${environment.smartSubApi}/data/alarms_in_radius?lon=${coordinates[0]}&lat=${coordinates[1]}`;
    const response: ApiResponse<{ data: SmartsubAlarm[] }> = await this.apiService.get(requestPath, {});

    if (response?.status === 204) return 0;
    else if (response?.status !== 200) return null;

    const acceptedTypes = ["critical", "major", "minor"];
    const filteredAlarms: SmartsubAlarm[] = response?.data?.data?.filter(
      alarm => acceptedTypes.includes(alarm?.alarmtype) && alarm?.tech === tech
    );
    let totalAlarms = 0;
    filteredAlarms?.forEach(alarm => totalAlarms += alarm?.alarmcount);

    return totalAlarms;
  }

  getAlarmDetailsBySiteId(siteId: number) {
    const url = `${environment.smartSubApi}/data/alarm_details/${siteId}`;

    const headers = {
      "Content-Type": "application/json",
    }

    return this.http.get<{ data: SiteAlarmDetails[] }>(url, { headers });
  }

}
