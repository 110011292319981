<div
    class="maintenance"
    *ngIf="(isInMaintenanceMode$ | async)"
>

    <div class="offline">
        <div class="offline__container">
            <div class="content-block inner">
                <svg
                    viewBox="0 0 393.8 183"
                    class="main-rain-logo"
                >
                    <g fill="#BBBCBC">
                        <path
                            d="M278,178.8h37.4v-68c0-7.7,0-26.1,21.4-26.1c19.6,0,19.6,17.1,19.6,25.9v68.3h37.4v-78.2 c0-24.6-7.7-34.5-14.4-40.3c-6.8-5.9-20.1-10.8-32-10.8c-22.3,0-30.2,11.5-34,17.6h-0.5V53.7H278V178.8z M262.3,178.8h-37.4v-125 h37.4V178.8z M107.7,116.6c0-14.2,9.5-32.2,30.9-32.2c21.2,0,31.5,16.7,31.5,31.8c0,12.2-7.9,32-31.3,32 C116.3,148.1,107.7,128.3,107.7,116.6 M207.5,53.7h-37.4V67h-0.5c-4.5-7.7-16-17.6-36.5-17.6c-33.3,0-62.9,25.7-62.9,66.7 c0,35.8,23.2,66.9,62.9,66.9c14.6,0,30.2-5.6,36.5-17.8h0.5v13.5h37.4V53.7z M0,53.7h35.1V67h0.5c3.2-6.1,9.7-17.6,31.3-17.6v37.6 c-17.3,0.4-29.5,4.1-29.5,23v68.7H0V53.7z"
                        >
                        </path>
                    </g>
                    <g fill="#0283cf">
                        <path
                            d="M265.8,22.3c0-12.3-10-22.3-22.3-22.3c-12.3,0-22.3,10-22.3,22.3c0,12.3,10,22.3,22.3,22.3 C255.9,44.6,265.8,34.6,265.8,22.3"
                        >
                        </path>
                    </g>
                </svg>

                <h1 class="">website update in progress</h1>
                <div class="">
                    <p class="">
                        Please bear with us while we make a few updates.

                    </p>

                </div>
            </div>
        </div>

        <div class="logo-container">
            <div class="footer-logo">
                <svg viewBox="0 0 393.8 183">
                    <g fill="#BBBCBC">
                        <path
                            d="M278,178.8h37.4v-68c0-7.7,0-26.1,21.4-26.1c19.6,0,19.6,17.1,19.6,25.9v68.3h37.4v-78.2 c0-24.6-7.7-34.5-14.4-40.3c-6.8-5.9-20.1-10.8-32-10.8c-22.3,0-30.2,11.5-34,17.6h-0.5V53.7H278V178.8z M262.3,178.8h-37.4v-125 h37.4V178.8z M107.7,116.6c0-14.2,9.5-32.2,30.9-32.2c21.2,0,31.5,16.7,31.5,31.8c0,12.2-7.9,32-31.3,32 C116.3,148.1,107.7,128.3,107.7,116.6 M207.5,53.7h-37.4V67h-0.5c-4.5-7.7-16-17.6-36.5-17.6c-33.3,0-62.9,25.7-62.9,66.7 c0,35.8,23.2,66.9,62.9,66.9c14.6,0,30.2-5.6,36.5-17.8h0.5v13.5h37.4V53.7z M0,53.7h35.1V67h0.5c3.2-6.1,9.7-17.6,31.3-17.6v37.6 c-17.3,0.4-29.5,4.1-29.5,23v68.7H0V53.7z"
                        >
                        </path>
                    </g>
                    <g fill="#0283cf">
                        <path
                            d="M265.8,22.3c0-12.3-10-22.3-22.3-22.3c-12.3,0-22.3,10-22.3,22.3c0,12.3,10,22.3,22.3,22.3 C255.9,44.6,265.8,34.6,265.8,22.3"
                        >
                        </path>
                    </g>
                </svg>
            </div>
        </div>
    </div>
</div>