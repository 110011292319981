import { DataHandler } from "src/app/shared/data-handler/data-handler";
import { SnowflakeSelectOption } from "src/app/shared/elements/rain-forms/elements/snowflake-select/interfaces/rain-select-option.interface";
import { MultiSelectInfo } from "./multi-select-info.type";

export class MultiSelectOption implements SnowflakeSelectOption {
  label: string;
  value: string | number;
  selected: boolean;
  showSuccessToggle: boolean;
  successful = false;
  isHoveringOverSuccessToggle = false;

  private _valueType: "string" | "number" | "MultiSelectInfo";

  /**
   * Retruns what the type of the value is
   */
  public get valueType(): "string" | "number" | "MultiSelectInfo" {
    return this._valueType;
  }

  constructor(snowflakeSelectOption: SnowflakeSelectOption, showSuccessToggle = false) {
    this.value = snowflakeSelectOption.value;
    this.label = snowflakeSelectOption.label;
    this.showSuccessToggle = showSuccessToggle;
    if (DataHandler.isDefined(snowflakeSelectOption?.selected)) {
      this.selected = snowflakeSelectOption.selected;
    }
    else {
      this.selected = false;
    }
    this.setValueType();
  }

  private setValueType() {
    if (this.showSuccessToggle) {
      this._valueType = "MultiSelectInfo";
    }
    else if (isNaN(this.value as number)) {
      this._valueType = "number";
    }
    else {
      this._valueType = "string";
    }
  }

  toggleIsSelected() {
    this.selected = !this.selected;

    //If selected set successful to true else false,
    //this is used to set the toggle option to true when selected
    this.successful = this.selected;
    if(!this.selected){
      this.isHoveringOverSuccessToggle = false;
    }
  }

  toggleIsSuccessful() {
    this.successful = !this.successful;
  }

  getInfo(): MultiSelectInfo {
    if (this.showSuccessToggle) {
      return { value: this.value, successful: this.successful }
    }
    else {
      return this.value;
    }
  }
}
