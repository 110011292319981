import { Utils } from "src/app/Utils";
import { FeatureFlagsActive } from "src/environments/feature-flags";


export class Init {
    static readonly type = Utils.Helpers.Type("[FeatureFlag] Init default features");
}

export class SetFeatureFlag {
    static readonly type = Utils.Helpers.Type("[FeatureFlag] Set specific flag");
    constructor(public flag: keyof FeatureFlagsActive, public active: boolean) { }
}
export class SetFeatureFlags {
    static readonly type = Utils.Helpers.Type("[FeatureFlag] Set all feature flags");
    constructor(public options: FeatureFlagsActive) { }
}

export class ResetToDefault {
    static readonly type = Utils.Helpers.Type("[FeatureFlag] Reset defaults");
}