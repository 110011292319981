<ng-container *ngIf="(statusObject$ | async) as statusObject">

    <ng-container *ngIf="statusObject.hasError">
        <sf-info-icon [statusTooltip]="statusObject.tooltip" [matMenuTriggerFor]="menu">
        </sf-info-icon>

        <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="dispatchAction(statusObject.action)">
                <mat-icon>refresh</mat-icon>
                <span>{{statusObject.retryMessage}}</span>
            </button>
        </mat-menu>
    </ng-container>

</ng-container>