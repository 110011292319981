<new-ticket-modal *ngIf="isNewTicketModalOpen$ | async" [customerEmail]="customerEmail"></new-ticket-modal>
<sf-more-open-tickets-modal [numOpenTickets]="numOpenTickets"></sf-more-open-tickets-modal>

<!-- =================================  MODALS  ================================== -->

<escalation-modal *ngIf="isEscalationModalOpen$ | async"></escalation-modal>

<sf-post-sales-modal *ngIf="isSalesTicketModalOpen$ | async"></sf-post-sales-modal>

<sf-post-upgrade-modal *ngIf="isUpgradePostTicketModalOpen$ | async"></sf-post-upgrade-modal>

<sf-retentions-modal *ngIf="isRetentionsModalOpen$ | async"></sf-retentions-modal>

<sf-default-post-ticket-modal *ngIf="isPostTicketOpen$ | async"></sf-default-post-ticket-modal>

<sf-cec-team-a-f-post-close-modal *ngIf="isCECTeamAFPostTicketModalOpen$ | async"></sf-cec-team-a-f-post-close-modal>

<sf-retenstions-update-modal *ngIf="isRetentionsUpdateModalOpen$ | async">
</sf-retenstions-update-modal>

<sf-retentions-update-modal-4g *ngIf="isRetentions4GUpdateModalOpen$ | async">
</sf-retentions-update-modal-4g>

<sf-post-collections-modal *ngIf="isPostCollectionsModalOpen$ | async"></sf-post-collections-modal>

<sf-activations-post-close-modal *ngIf="isPostActivationsModalOpen$ | async"></sf-activations-post-close-modal>

<sf-rica-post-close-modal *ngIf="isRicaModalOpen$ | async"></sf-rica-post-close-modal>

<sf-csat-post-close-modal *ngIf="isCSATmodalOpen$ | async"></sf-csat-post-close-modal>

<sf-early-optimizations-close-modal *ngIf="isEarlyOptimizationsModalOpen$ | async"></sf-early-optimizations-close-modal>

<sf-post-campaign-call-modal *ngIf="isPostCampaignCallModalOpen$ | async"></sf-post-campaign-call-modal>

<sf-rain-one-retentions-post-close-modal
  *ngIf="isRainOneRetentionsPostCloseModalOpen$ | async"></sf-rain-one-retentions-post-close-modal>

<sf-nvidia-post-close-modal *ngIf="isInvidiaPostTicketModalOpen$ | async"></sf-nvidia-post-close-modal>

<sf-post-collections-modal-b *ngIf="isPostCollectionsModalBopen$ | async"></sf-post-collections-modal-b>


<sf-fullfillment-cpe-collections-post-close-modal
  *ngIf="isFulfilmentCpeCollectionsModalOpen$ | async"></sf-fullfillment-cpe-collections-post-close-modal>

<sf-fulfillment-rica-post-close-modal *ngIf="isFulfilmentRicaModalOpen$ | async"></sf-fulfillment-rica-post-close-modal>


<sf-fulfillment-deliveries-post-close-modal
  *ngIf="isFulfilmentDeliveriesModalOpen$ | async"></sf-fulfillment-deliveries-post-close-modal>

<sf-retail-customer-engagement-post-close-modal
  *ngIf="isRetailCustomerEngagementPostCloseModalOpen$ | async"></sf-retail-customer-engagement-post-close-modal>

  <sf-retentions-main-post-close-modal
*ngIf="isRetentionMainPostCloseModalOpen$ | async"></sf-retentions-main-post-close-modal>

<sf-rain101-post-close-modal *ngIf="isRain101PostCloseModalOpen$ | async"></sf-rain101-post-close-modal>
