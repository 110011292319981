import { Utils } from "src/app/Utils";
import { CheckMigrationResponse } from "../../types/check-migration-response.interface";

export class Fetch {
  static readonly type = Utils.Helpers.Type("[Check Migration] Fetch");
  constructor(public payload: string) { }
}

export class FetchSuccess {
  static readonly type = Utils.Helpers.Type("[Check Migration] Fetch success");
  constructor(public payload: CheckMigrationResponse) { }
}

export class FetchFail {
  static readonly type = Utils.Helpers.Type("[Check Migration] Fetch fail");
  constructor(public error: unknown) { }
}

export class Clear {
  static readonly type = Utils.Helpers.Type("[Check Migration] Clear");
}
