<div
  class="box-div"
  [style.border]="border"
  [style.backgroundColor]="backgroundColor"
  [class.can-hover]="canHover"
>

  <mat-icon
    *ngIf="hasCloseButton"
    class="default-cancel-icon"
    (click)="onClose()"
  >cancel</mat-icon>

  <div class="content">

    <p
      class="text header"
      [style.color]="textColor"
    >
      <ng-content select="[header]"></ng-content>
    </p>

    <small-text-spinner *ngIf="loading; else defaultContent">loading..</small-text-spinner>

    <ng-template #defaultContent>
      <p
        class="text sub-header"
        [style.color]="textColor"
      >
        <ng-content select="[subHeader]"></ng-content>
      </p>
      <p class="text sub-header red">
        <ng-content select="[error]"></ng-content>
      </p>
      <p class="time text-dark-grey">{{time | date: 'd MMM, y, h:mm a'}}</p>
    </ng-template>


    <p
      *ngIf="hasShowMoreButton"
      class="show-more-button"
      (click)="onShowMore()"
    >{{showMoreText}}</p>
  </div>

</div>