<div class="container">

  <div class="header-container">
    <div>
      <h1 class="header text text-white">
        <ng-content select="[header]"></ng-content>
      </h1>

      <h5 class="sub-header font-normal text-white">
        <ng-content select="[subHeader]"></ng-content>
      </h5>
    </div>
  </div>

  <span class="default-spacer">
    <sf-test-account-copy></sf-test-account-copy>
  </span>

  <ng-content select="[contentRight]"></ng-content>

  <ng-container *ngIf="isInteractionsShown">
    <div class="interaction-buttons-wrapper">
      <div class="interaction-buttons">
        <app-interaction-buttons [showCart]="showCart"></app-interaction-buttons>
      </div>
    </div>
  </ng-container>

</div>
