
import { Injectable } from '@angular/core';
import { ApiResponse } from 'src/app/shared/services/api-service/api-response.interface';
import { ApiControllerService } from 'src/app/shared/services/api-service/api-controller.service';
import { environment } from 'src/environments/environment';
import { DataHandler } from 'src/app/shared/data-handler/data-handler';
import { StatusGroup } from 'src/app/shared/data-handler/datafield-options.interface';
import { Store } from '@ngxs/store';
import { CustomerInfoState } from 'src/app/customer-info-summary-page/store/state/customer-info.state';
import { Observable } from 'rxjs';
import { AccountActivityItem } from './assets/account-activity.interface';



@Injectable({
  providedIn: 'root'
})
export class PaymentHistoryService {


  constructor(
    private apiService: ApiControllerService,
    private store: Store) {
  }

  async getAccountActivity(): Promise<ApiResponse<any>> {
    const customer = this.store.selectSnapshot(CustomerInfoState.getSmartsubData)?.user;
    if (!customer?.id) return this.apiService.getNoDataResponse("no user id, could not get account activity.");
    const path = `${environment.smartSubApi}/data/help/new_transactions/${customer?.id}`;
    return this.apiService.get(`${path}`, {});
  }

  getAccountActivityV2(id: string): Observable<ApiResponse<AccountActivityItem[]>> {
    const path = `${environment.smartSubApi}/data/help/new_transactions/${id}`;
    return this.apiService.getV2(`${path}`, {});
  }

  getAccountActivityStatusColor(doc_status: string) {
    if (!doc_status) return "orange";
    const statusGroup: StatusGroup = {
      green: ["processed", "allocated", "paid", "finalised", "validated", "received"],
      orange: [],
      red: ["suppressed"],
      defaultColor: "red"
    };
    return DataHandler.getStatusColorChoice(doc_status, statusGroup);
  }






}
