import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { FetchCustomerDetails } from "src/app/customer-info-summary-page/store/actions/customer.actions";
import { DataLoading, getDataLoadingDefaultValues } from "src/app/shared/interfaces/data-loading.interface";
import { BasicCustomer, Customer } from "src/app/shared/interfaces/smartsub-data.interface";
import { ApiResponse } from "src/app/shared/services/api-service/api-response.interface";
import { SearchService } from "../search.service";
import { FetchCustomerDetailsByICCID, FetchCustomerDetailsByIMSI, FetchCustomerDetailsByName, FetchCustomerDetailsByNameSuccess, FetchCustomerDetailsByOrderNumber, FetchCustomerDetailsByParamFail, FetchCustomerDetailsByPassport, FetchCustomerDetailsByPassportFail, FetchCustomerDetailsByUUID, FetchFullProfileByParam, ResetCustomerSearch } from "./search.actions";
import { DataLoadingHelper } from "src/app/Utils/helpers";
import { Utils } from "src/app/Utils";
import { tap } from "rxjs/operators";
import { NO_DATA_MESSAGE } from "src/app/constants";
import { getFullNameFromSearchString } from "./functions/format_to_name.function";


export type CustomerSearchStateModel = DataLoading<BasicCustomer[]>;
@State<CustomerSearchStateModel>({
  name: 'customer_search',
  defaults: getDataLoadingDefaultValues()
})
@Injectable()
export class CustomerSearchState {

  @Selector()
  static isLoading(state: CustomerSearchStateModel) { return state.loading }

  @Selector()
  static isLoaded(state: CustomerSearchStateModel) { return state.loaded }

  @Selector()
  static getErrorMessage(state: CustomerSearchStateModel) { return state.error }

  @Selector()
  static getCustomerList(state: CustomerSearchStateModel) { return state.data }


  constructor(private searchService: SearchService) { }


  @Action(FetchCustomerDetailsByUUID)
  fetchCustomerDetailsByOrderId(ctx: StateContext<CustomerSearchStateModel>, action: FetchCustomerDetailsByUUID) {
    const { uuid } = action;

    ctx.patchState({
      loading: true
    });

    //Firstly check order_id and if that fails, check user_id
    return this.searchService.findCustomerByOrderId(uuid)
      .pipe(
        tap({
          next: res => {
            const { email } = res ?? {};
            const param = email ? email : uuid;
            return ctx.dispatch(new FetchFullProfileByParam(param));
          },
          error: () => ctx.dispatch(new FetchFullProfileByParam(uuid))
        })
      )
  }


  @Action(FetchCustomerDetailsByOrderNumber)
  fetchCustomerDetailsByOrderOrIdNumber(ctx: StateContext<CustomerSearchStateModel>, action: FetchCustomerDetailsByOrderNumber) {
    const { orderNumber } = action;

    ctx.patchState({
      loading: true
    });

    return this.searchService.findCustomerByOrderNumber(orderNumber)
      .pipe(
        tap({
          next: res => {
            const { email } = res ?? {};
            if (email) {
              return ctx.dispatch(new FetchFullProfileByParam(email));
            }
            return ctx.dispatch(new FetchCustomerDetailsByParamFail(NO_DATA_MESSAGE));
          },
          error: (e: unknown) => ctx.dispatch(new FetchCustomerDetailsByParamFail(e))
        })
      )
  }


  @Action(FetchCustomerDetailsByName)
  async fetchCustomerDetailsByName(ctx: StateContext<CustomerSearchStateModel>, action: FetchCustomerDetailsByName) {

    ctx.patchState({
      loading: true
    });

    const response: ApiResponse<BasicCustomer[] | { user: Customer }> = await this.searchService.searchCustomerDetailsByName(action.payload);

    if (response.status !== 200 || !response.data) {
      ctx.dispatch(new FetchCustomerDetailsByParamFail(response.errorMessage?.error));
      return;
    }

    const responseData = response.data;
    if (Array.isArray(responseData)) {
      ctx.dispatch(new FetchCustomerDetailsByNameSuccess(responseData));
    }
    else if ((responseData?.user)) {
      ctx.dispatch(new FetchFullProfileByParam(responseData.user.email));
    }
    else {
      ctx.dispatch(new FetchCustomerDetailsByParamFail("Response data received in unexpected format."));
    }
  }



  @Action(FetchCustomerDetailsByPassport)
  fetchCustomerDetailsByPassport(ctx: StateContext<CustomerSearchStateModel>, action: FetchCustomerDetailsByPassport) {
    const { searchString } = action;

    ctx.patchState({
      loading: true
    });
    //Firstly check order_id and if that fails, check user_id
    return this.searchService.findCustomerDetailsByPassport(searchString)
      .pipe(
        tap({
          next: res => {
            const  {email} = res?.user ?? {};
            return ctx.dispatch(new FetchFullProfileByParam(email));
          },
          error: err=>{
            if(err.status===404){
              const namePayload = getFullNameFromSearchString(searchString);
              return ctx.dispatch(new FetchCustomerDetailsByName(namePayload));
            }
            return ctx.dispatch(new FetchCustomerDetailsByPassportFail(err))
          }
        })
      )
  }

  @Action(FetchCustomerDetailsByICCID)
  fetchCustomerDetailsByICCID(ctx: StateContext<CustomerSearchStateModel>, action: FetchCustomerDetailsByICCID) {
    const { searchString } = action;

    ctx.patchState({
      loading: true
    });
    return this.searchService.findCustomerDetailsByICCID(searchString)
      .pipe(
        tap({
          next: res => {
            const  {email} = res?.user ?? {};
            return ctx.dispatch(new FetchFullProfileByParam(email));
          },
        error: err=>{
          if(err.status===404){
            const namePayload = getFullNameFromSearchString(searchString);
            return ctx.dispatch(new FetchCustomerDetailsByName(namePayload));
          }
         return ctx.dispatch(new FetchCustomerDetailsByPassportFail(err))
        }
        })
      )
  }

  @Action(FetchCustomerDetailsByPassportFail)
  fetchCustomerDetailsByPassportFail(ctx: StateContext<CustomerSearchStateModel>, action: FetchCustomerDetailsByPassportFail) {
   const error = Utils.Helpers.findError(action.error, "");
   DataLoadingHelper.setError(ctx, error);
 }

  @Action(FetchCustomerDetailsByIMSI)
  fetchCustomerDetailsByIMSI(ctx: StateContext<CustomerSearchStateModel>, action: FetchCustomerDetailsByIMSI) {
    const { imsi } = action;

    ctx.patchState({
      loading: true
    });

    return this.searchService.findCustomerByIMSI(imsi)
      .pipe(
        tap({
          next: res => {
            const { email } = res?.result?.customer ?? {};
            if (email) {
              return ctx.dispatch(new FetchFullProfileByParam(email));
            }
            return ctx.dispatch(new FetchCustomerDetailsByParamFail(NO_DATA_MESSAGE));
          },
          error: (e: unknown) => ctx.dispatch(new FetchCustomerDetailsByParamFail(e))
        })
      )
  }

  @Action(FetchCustomerDetailsByNameSuccess)
  fetchCustomerDetailsByNameSuccess(ctx: StateContext<CustomerSearchStateModel>, action: FetchCustomerDetailsByNameSuccess) {
    DataLoadingHelper.setData(ctx, action.payload);
  }

  @Action(FetchCustomerDetailsByParamFail)
  fetchCustomerDetailsByNameFail(ctx: StateContext<CustomerSearchStateModel>, action: FetchCustomerDetailsByParamFail) {
    const error = Utils.Helpers.findError(action.error, "");
    DataLoadingHelper.setError(ctx, error);
  }

  @Action(FetchFullProfileByParam)
  foundSingleCustomerByName(ctx: StateContext<CustomerSearchStateModel>, action: FetchFullProfileByParam) {
    const { param, autoNavigate } = action;

    return ctx.dispatch([
      new FetchCustomerDetails({
        value: param,
        config: {
          loader: true,
          autoNavigate
        }
      }),
      new ResetCustomerSearch()
    ]);
  }

  @Action(ResetCustomerSearch)
  resetSearch(ctx: StateContext<CustomerSearchStateModel>) {
    ctx.setState(getDataLoadingDefaultValues());
  }


}
