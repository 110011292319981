import { AzureProfile } from "src/app/profile/assets/azure-profile.interface";
import { Utils } from "src/app/Utils";
import { BaseAgent } from "../../../../../core/interfaces/agent.interface";
import { AzureResponse } from "../../../../../core/interfaces/azure-response.interface";

export class AssignAgent {
    static readonly type = Utils.Helpers.Type('[Core: Agent] Assign agent');
    constructor(public payload: BaseAgent) { }
}

export class FetchAllAgents {
    static readonly type = Utils.Helpers.Type('[Core: Agents] Fetch all agents');
}
export class FetchAllAgentsSuccess {
    static readonly type = Utils.Helpers.Type('[Core: Agents] Fetch all agents success');
    constructor(public payload: AzureResponse<AzureProfile>) { }
}
export class FetchAllAgentsFail {
    static readonly type = Utils.Helpers.Type('[Core: Agents] Fetch all agents fail');
    constructor(public payload: unknown) { }
}

export class AddAgentBrowserActionTracker {
    static readonly type = Utils.Helpers.Type('[Core: Agents] add the BrowserActionTracker');
}

export class InitializeAgentDetails {
    static readonly type = Utils.Helpers.Type('[Core: Agents] Init agent details');
    constructor(public name: string, public email: string, public roles: string[] = []) { }
}

export class UpdateAgentRoles {
    static readonly type = Utils.Helpers.Type('[Core: Agents] Set agent roles');
    constructor(public roles: string[]) { }
}

export class StoreAgentRoles {
    static readonly type = Utils.Helpers.Type('[Core: Agents] Store agent roles in localStorage');
    constructor(public roles: string[]) { }
}

export class ToggleAcceptingCalls {
    static readonly type = Utils.Helpers.Type('[Core: Agents] Toggle accepting calls');
}

export class SetAcceptingCalls {
    static readonly type = Utils.Helpers.Type('[Core: Agents] Set accepting calls');
    constructor(public acceptingCalls: boolean) { }
}

export class ToggleAcceptingTickets {
    static readonly type = Utils.Helpers.Type('[Core: Agents] Toggle accepting tickets');
}

export class SetAcceptingTickets {
    static readonly type = Utils.Helpers.Type('[Core: Agents] Set accepting tickets');
    constructor(public acceptingTickets: boolean) { }
}

