<ng-container *ngIf="level <= maxLevel">

  <div
    *ngIf="(data | typeOfData) as type"
    class="container"
    [class]="type"
    [class.nested-object]="level > 2"
  >

    <ng-container *ngIf="type === 'object'">

      <ng-container *ngFor="let item of data | keyvalue : originalOrder trackBy:trackByFn">

        <h1
          class="heading"
          [class.sub-heading]="level > 0"
        >
          {{ item?.key }}:
        </h1>

        <ng-container *ngIf="(item?.value | typeOfData) as nestedType">

          <ul *ngIf="nestedType === 'object'; else notNestedObj">
            <li
              *ngFor="let nestedObject of item?.value | keyvalue"
            >
              <span>
                {{nestedObject?.key }}:
                <sf-pretty-json-note
                  [level]="level + 1"
                  [data]="nestedObject.value"
                ></sf-pretty-json-note>
              </span>
            </li>
          </ul>

        </ng-container>

        <ng-template #notNestedObj>

          <sf-pretty-json-note
            [level]="level + 1"
            [data]="item?.value"
          ></sf-pretty-json-note>
        </ng-template>

        <span class="line-break"></span>

      </ng-container>

    </ng-container>

    <ng-container *ngIf="type === 'array'">
      <ul *ngFor="let arrItem of data">

        <li>
          <sf-pretty-json-note
            [level]="level + 1"
            [data]="arrItem"
          ></sf-pretty-json-note>
        </li>

      </ul>
    </ng-container>

    <ng-container>
      {{ data | formatType : type }}
    </ng-container>

  </div>

</ng-container>
