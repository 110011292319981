import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { SFNotification } from 'src/app/core/notifiactions/interfaces/notifiactions.interface';
import { RemoveNotification } from 'src/app/core/store/actions/notifications.actions';

@Component({
  selector: 'sf-notifcation',
  templateUrl: './notifcation.component.html',
  styleUrls: ['./notifcation.component.scss']
})
export class NotifcationComponent implements OnInit {
  @Input() notification: SFNotification;
  public isActive = true;
  
  constructor(
    private store: Store
  ) { }

  ngOnInit(): void {
    if(this.notification && this.notification.type !== 2) {
      setTimeout(() => {
        this.closeNotification();
      }, 7000);
    }
  }

  public closeNotification() {
    this.isActive = false;
    
    setTimeout(() => {
      return this.store.dispatch(new RemoveNotification(this.notification));
    }, 500);
  }
}
