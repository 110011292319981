<ng-container *ngIf="viewModel$ | async as viewModel">

    <mat-icon
        (click)="toggleOpen()"
        class="notification-panel pointer"
        [matBadge]="viewModel.nonViewedCount"
        [matBadgeHidden]="!viewModel.nonViewedCount"
        matBadgePosition="above after"
        matBadgeColor="warn"
        matBadgeSize="medium"
        matBadgeOverlap="true"
    >notifications
    </mat-icon>

    <ng-container *ngIf="viewModel.open">
        <div class="hidden-overlay">
        </div>

        <div class="notification-panel notification-box shadow">
            <div class="container">
                <div class="arrow"></div>

                <div class="top-panel">
                    <h3>notifications</h3>
                </div>

                <div class="notifications-section scrollable">
                    <ng-container *ngIf="viewModel.filteredNotifications?.length > 0; else noNotifications">

                        <div class="close-all ">
                            <div
                                class="pointer"
                                (click)="closeAllNotifications()"
                            >close all</div>
                        </div>

                        <sf-agent-notification
                            *ngFor="let notification of viewModel.filteredNotifications"
                            [notification]="notification"
                        ></sf-agent-notification>
                    </ng-container>

                    <ng-template #noNotifications>
                        <div class="no-notifications">no new notifications</div>
                    </ng-template>
                </div>

            </div>
        </div>

    </ng-container>


    <sf-upcoming-callback-modal *ngIf="viewModel.isCallbackModalOpen"></sf-upcoming-callback-modal>

</ng-container>