import { Utils } from "src/app/Utils";
import { WebSocketStatus } from "../../types/agent-websocket-status.type";


export class Connect {
    static readonly type = Utils.Helpers.Type("[Agent Notification WS] Connect");
    constructor(public email: string) { }
}

export class ConnectInCtx {
    static readonly type = Utils.Helpers.Type("[Agent Notification WS] Connect in current ctx");
}

export class UpdateStatus {
    static readonly type = Utils.Helpers.Type("[Agent Notification WS] Update Status");
    constructor(public status: WebSocketStatus) { }
}

export class SetErrorMessage {
    static readonly type = Utils.Helpers.Type("[Agent Notification WS] Set Error Message");
    constructor(public error: unknown) { }
}

