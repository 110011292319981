<sf-popup-modal
  [isOpen]="true"
  [hasCloseButton]="false"
  [autoClose]="false"
  [successResponse]="modalOptions.successResponse"
  [sending]="modalOptions.sending"
  [spinnerMessage]="modalOptions.spinnerMessage"
>

  <ng-container header>post collections modal</ng-container>

  <ng-container mainContent>
    <sf-modal-form-container [formGroup]="form">

      <sf-select
        class="customer-contacted-select"
        [options]="customerContactedOptions"
        (onOptionSelect)="onOptionSelect($event)"
        marginBottom="1rem"
        label="Customer Contacted"
        [placeholder]="'Customer Contacted'"
        [hasError]="form.get('customer_contacted').errors?.notSelected"
      >
      </sf-select>

      <ng-container *ngIf="isCustomerContacted; else customerNotContacted">
        <sf-form-checkbox
          class="checkbox"
          [control]="form.get('take_my_money_now')"
          label="take my money now"
        >
        </sf-form-checkbox>

        <sf-form-checkbox
          class="checkbox"
          [control]="form.get('pay_now')"
          label="pay now link sent"
        >
        </sf-form-checkbox>

        <sf-form-checkbox
          class="checkbox"
          [control]="form.get('logged_PTP')"
          label="logged promise to pay"
        >
        </sf-form-checkbox>

        <sf-form-checkbox
          class="checkbox"
          [control]="form.get('promise_to_callback')"
          label="call back customer"
        >
        </sf-form-checkbox>


        <sf-form-checkbox
          class="checkbox"
          [control]="form.get('partial_payment')"
          label="partial payment"
        >
        </sf-form-checkbox>

        <sf-form-checkbox
          class="checkbox"
          [control]="form.get('payment_arrangement')"
          label="payment arrangement"
        >
        </sf-form-checkbox>

        <sf-form-checkbox
          class="checkbox"
          [control]="form.get('card_details_updated')"
          label="card details updated"
        >
        </sf-form-checkbox>


        <sf-form-checkbox
          class="checkbox"
          [control]="form.get('refuse_to_pay')"
          label="refuse to pay (handover)"
        >
        </sf-form-checkbox>

        <sf-form-checkbox
          class="checkbox"
          [control]="form.get('device_collected_received')"
          label="device collected or received"
        >
        </sf-form-checkbox>

        <sf-form-checkbox
        class="checkbox"
        [control]="form.get('cancelled_or_wants_to_cancel')"
        label="cancelled/ wants to cancel"
      >
      </sf-form-checkbox>

      <sf-form-checkbox
        class="checkbox"
        [control]="form.get('customer_retained')"
        label="customer retained"
      >
      </sf-form-checkbox>

      </ng-container>

      <ng-template #customerNotContacted>

        <sf-form-checkbox
          class="checkbox"
          [control]="form.get('payment_already_received')"
          label="payment already received"
        >
        </sf-form-checkbox>

        <sf-form-checkbox
          class="checkbox"
          [control]="form.get('voicemail')"
          label="voicemail"
        >
        </sf-form-checkbox>


        <sf-form-checkbox
          class="checkbox"
          [control]="form.get('third_party_reached')"
          label="3rd party reached"
        >
        </sf-form-checkbox>

        <sf-form-checkbox
          class="checkbox"
          [control]="form.get('customer_not_reachable')"
          label="customer not reachable"
        >
        </sf-form-checkbox>

      </ng-template>

      <ng-container buttons>
        <div class="send-button-wrapper">
          <sf-rain-button
            [disabled]="!form || !isOneFieldSelected"
            (click)="onSend()"
          >send</sf-rain-button>
        </div>

      </ng-container>

    </sf-modal-form-container>
  </ng-container>

</sf-popup-modal>
