import { WhatsappSearchPayload } from "../types/whatsapp-search-payload.interface";


export const WHATSAPP_SEARCH_PAYLOAD_DEFAULTS: Partial<WhatsappSearchPayload> = {
    messageTypes: ["TEXT", "IMAGE", "VIDEO", "AUDIO"],
    channelType: "WHATSAPP",
    sortByFieldName: "insertedAt",
    sortOrder: "DESC",
}

export const WHATSAPP_INVOCATION_SOURCE = "SNOWFLAKE";

export const WHATSAPP_STORAGE_BASE_URL = "https://rain-sys-prod-devops-notifications.s3.af-south-1.amazonaws.com";