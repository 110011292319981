import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormHelper } from 'src/app/Utils/helpers/form.helper';
import { CustomerAddress } from '../../../../interfaces/smartsub-data.interface';
import { GooglePlacesAddress } from '../snowflake-address-search/assets/google-places-address.interface';

@Component({
  selector: 'sf-snowflake-address-inputs',
  templateUrl: './snowflake-address-inputs.component.html',
  styleUrls: ['./snowflake-address-inputs.component.scss']
})
export class SnowflakeAddressInputsComponent extends FormHelper implements OnInit {

  @Input() header = "address details";
  @Input() maxHeight = "340px";

  @Input() set shouldValidate(value: boolean) {
    this.assignUnAssignValidators(value);
  }
  @Input() set inputAddress(address: GooglePlacesAddress) {
    if (address) {
      this.assignAddressDetails(address);
    }
  }
  @Output() customerAddressOut = new EventEmitter<CustomerAddress>();
  @Output() formInitialized = new EventEmitter<FormGroup>();

  form: FormGroup;
  autoFilled = false;

  constructor(private fb: FormBuilder,
    private cd: ChangeDetectorRef) {
    super();
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      alt_address: this.fb.group({
        floor_level: this.fb.control(''),
        unit_number: this.fb.control(''),
        building_name: this.fb.control(''),
        street_number: this.fb.control(''),
        street_name: this.fb.control(''),
        suburb: this.fb.control(''),
        city: this.fb.control(''),
        province: this.fb.control(''),
        postal_code: this.fb.control(''),
      })
    });

    this.InitForm(this.form);
    this.formInitialized.next(this.form);
    this.assignUnAssignValidators(true); //validation is on by default;
  }

  submitAddress() {
    if (this.form.invalid) return;

    const address = this.getControl('alt_address').value;
    this.customerAddressOut.next(address);
  }

  private assignAddressDetails(address: GooglePlacesAddress) {
    this.getControl('alt_address').patchValue({
      floor_level: '',
      unit_number: '',
      street_number: address.streetNumber,
      street_name: address.streetName,
      suburb: address.suburb,
      city: address.city,
      province: address.province,
      postal_code: address.postalCode
    });
    this.autoFilled = true;
  }

  private assignUnAssignValidators(shouldValidate: boolean) {

    const street_number_validators = [Validators.required, this.AlphaNumericValidator(), Validators.minLength(1), Validators.maxLength(6), this.ValidateDoesNotStartWithZero()];
    const postal_code_validators = [Validators.required, Validators.minLength(4), Validators.maxLength(4), this.ValidateZipCode()];

    if (shouldValidate) {
      this.getControl('street_number', 'alt_address').setValidators(Validators.compose(street_number_validators));
      this.getControl('street_name', 'alt_address').setValidators(Validators.required);
      this.getControl('suburb', 'alt_address').setValidators(Validators.required);
      this.getControl('city', 'alt_address').setValidators(Validators.required);
      this.getControl('province', 'alt_address').setValidators(Validators.required);
      this.getControl('postal_code', 'alt_address').setValidators(Validators.compose(postal_code_validators));
    } else {
      this.getControl('street_number', 'alt_address').removeValidators(Validators.compose(street_number_validators));
      this.getControl('street_name', 'alt_address').removeValidators(Validators.required);
      this.getControl('suburb', 'alt_address').removeValidators(Validators.required);
      this.getControl('city', 'alt_address').removeValidators(Validators.required);
      this.getControl('province', 'alt_address').removeValidators(Validators.required);
      this.getControl('postal_code', 'alt_address').removeValidators(Validators.compose(postal_code_validators));
    }

    this.cd.detectChanges();
    return this.form.updateValueAndValidity();
  }

}
