import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { SanitizedChatNote } from 'src/app/shared/customer-ticket/interfaces/sanitized-chat-note.interface';
import { ChatJsonNote } from './assets/chat-json-note.interface';
import { ChatNoteState } from 'src/app/interactions/store/state/note.state';
import { TicketFunctions } from 'src/app/interactions/store/assets/ticket-functions';
import { ChatNoteActions } from 'src/app/interactions/store/actions/chat-note-actions';

@Component({
  selector: 'sf-chat-json-note',
  templateUrl: './chat-json-note.component.html',
  styleUrls: ['./chat-json-note.component.scss']
})
export class ChatJsonNoteComponent implements OnInit {
  @Input() note: SanitizedChatNote;

  isExpanded$: Observable<boolean>;

  jsonNote: ChatJsonNote = null;
  detail = null;

  constructor(private store: Store) { }

  ngOnInit(): void {
    if (!this.note?.hex_id) {
      return;
    }

    this.isExpanded$ = this.store.select(ChatNoteState.isJsonNoteOpen(this.note.hex_id));
    this.setJson();
  }

  private setJson() {
    const json = TicketFunctions.getChatJsonNote(this.note?.comment);
    if (!json) {
      return;
    }
    this.jsonNote = json;
    this.detail = json?.detail;

  }


  onToggleExpanded() {
    this.store.dispatch(new ChatNoteActions.ToggleJsonNoteExpanded(this.note.hex_id));
  }

}
