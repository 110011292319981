import { SnowflakeSelectOption } from "src/app/shared/elements/rain-forms/elements/snowflake-select/interfaces/rain-select-option.interface";

export const RETAINED_YES_OPTIONS :SnowflakeSelectOption[]=[
  {label:'Remote optimisation',value:'Remote optimisation' },
  {label:'Dasher Request',value:'Dasher Request' },
  {label:'Sim Swap',value:'Sim Swap' },
  {label:'Device Swap Out',value:'Device Swap Out' },
  {label:'Billing correction/Credit',value:'Billing correction/Credit' },
  {label:'Trouble shooting-Voice Assistance',value:'Trouble shooting-Voice Assistance' },
  {label:'Incorrect cancellation requested.',value:'Incorrect cancellation requested.' },
  {label:'Cancellation already revoked by customer',value:'Cancellation already revoked by customer' }
];

export const RETAINED_NO_OPTIONS:SnowflakeSelectOption[]=[
  {label:'No contact',value:'No contact' },
  {label:'Network Issues',value:'Network Issues' },
  {label:'Moved to New ISP/Fibre',value:'Moved to New ISP/Fibre' },
  {label:'Voice Issues',value:'Voice Issues' },
  {label:'Billing',value:'Billing' },
  {label:'Affordability',value:'Affordability' },
  {label:'Out of 4G and 5G coverage',value:'Out of 4G and 5G coverage' },
  {label:'Delivery Delays',value:'Delivery Delays' },
];

export const RETAINED_RAIN_ONE_NO_OPTIONS:SnowflakeSelectOption[]=[
  {label:'No contact',value:'No contact' },
  {label:'Network Issues',value:'Network Issues' },
  {label:'Moved to New ISP/Fibre',value:'Moved to New ISP/Fibre' },
  {label:'Voice Issues',value:'Voice Issues' },
  {label:'Billing',value:'Billing' },
  {label:'Affordability',value:'Affordability' },
  {label:'Out of 4G and 5G coverage',value:'Out of 4G and 5G coverage' },
  {label:'Delivery Delays',value:'Delivery Delays' },
  {label:'New Purchase- rainOne 101',value:'New Purchase- rainOne 101' },

];

export const VOICE_OPTIONS:SnowflakeSelectOption[]=[
  {label:'Calls dropping', value:'Calls dropping'},
  {label:'Rain talk issues', value:'Rain talk issues'},
  {label:'Porting', value:'Porting'},
  {label:'SMS', value:'SMS'},
  {label:'Bundle allocation', value:'Bundle allocation'},
  {label:'Device compatibility', value:'Device compatibility'},
]

export const NETWORK_OPTIONS:SnowflakeSelectOption[]=[
  {label:'Intermittent connection', value:'Intermittent connection'},
  {label:'Slow speeds',value:'Slow speeds'},
  {label:'No connection', value:'No connection'}
];
