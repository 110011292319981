import { Injectable } from '@angular/core';
import { Action, createSelector, Selector, State, StateContext, } from '@ngxs/store';
import { DataHandler } from 'src/app/shared/data-handler/data-handler';
import { ChatNoteActions } from '../actions/chat-note-actions';
import { CoreState } from 'src/app/core/store/state/core.state';


const getDefaults = (): ChatNoteStateModel => {
    return {
        jsonNotesExpanded: {},
        viewerEmails: []
    }
}

interface ChatNoteStateModel {
    jsonNotesExpanded: { [hex_id: string]: boolean };
    viewerEmails: string[];
}
@State<ChatNoteStateModel>({
    name: 'sf_chat_note_state',
    defaults: getDefaults()
})
@Injectable()
export class ChatNoteState {


    static isJsonNoteOpen(hex_id: string) {
        return createSelector([ChatNoteState], (state: ChatNoteStateModel) => {
            const { jsonNotesExpanded } = state;

            if (!(hex_id in jsonNotesExpanded)) return false;
            return jsonNotesExpanded[hex_id];
        });
    }

    @Selector()
    static getViewerEmails(state: ChatNoteStateModel) { return state.viewerEmails; }

    @Selector([
        ChatNoteState.getViewerEmails,
        CoreState.getAgentEmail
    ])
    static getNonSelfViewers(emails: string[], agentEmail: string) {
        return emails.filter(email => email !== agentEmail);
    }


    @Action(ChatNoteActions.ToggleJsonNoteExpanded)
    toggleJsonNoteExpanded(ctx: StateContext<ChatNoteStateModel>, action: ChatNoteActions.ToggleJsonNoteExpanded) {
        const { hex_id } = action;
        const { jsonNotesExpanded } = DataHandler.createDeepCopy(ctx.getState());

        if (!(hex_id in jsonNotesExpanded)) {
            jsonNotesExpanded[hex_id] = true;
        }
        else {
            jsonNotesExpanded[hex_id] = !jsonNotesExpanded[hex_id];
        }

        ctx.patchState({ jsonNotesExpanded });
    }


    @Action(ChatNoteActions.UpdateViewers)
    UpdateViewers(ctx: StateContext<ChatNoteStateModel>, action: ChatNoteActions.UpdateViewers) {
        ctx.patchState({ viewerEmails: action.emails });
    }

    @Action(ChatNoteActions.ChatNoteInteractionsDestroyed)
    Clear(ctx: StateContext<ChatNoteStateModel>) {
        ctx.setState(getDefaults());
    }


}
