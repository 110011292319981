export const RETENTION_CONTACT_TYPE = [
  "Already assisted",
  "Already cancelled",
  "Answered - Assisted",
  "Answered - Callback",
  "Incoming Call",
  "No contact made after multiple attempts"
];

export const RETENTIONS_CALL_TYPES = [
  "Outgoing (ticket)",
  "Incoming"
];

export const OUTGOING_CALL_OPTIONS = [
  "Answered",
  "No Answer",
  "Callback",
  "Already Cancelled",
  "Already assisted"
]

export const INCOMING_CALL_OPTIONS = [
  "Cancellation",
  "Transferred to sales",
  "Transferred to support",
  "Already assisted"
]


export const RETENTION_OUTCOME_OPTIONS = [
  "Cancellation immediate",
  "Cancellation scheduled",
  "Escalation: Dasher request",
  "Escalation: Network Advanced ",
  "Escalation: Logistics",
  "Escalation: Billing: payment arrangement",
  "Escalation: Billing: lost, stolen, or damaged devices",
  "Upgraded by agent: to rainOne",
  "Upgraded by agent: to rainOne 101",
  "Pending customer feedback",
  "Cancellation revoked by customer",
  "Retained: cancellation revoked by agent",
  "Retained: no cancellation scheduled"
];

export const RETENTION_PRODUCT_TYPE = [
  "4G UOP",
  "4G for phones",
  "4G unlimited",
  "5G Basic",
  "5G Standard",
  "5G Premium",
  "5G rainOne",
  "5G rainOne 101",
  "One for 4G UOP",
  "One for 4G ULTD",
  "NVIDIA"
];

export const RETENTION_SOLUTIONS_PROVIDED = [
  "Credit",
  "Network assistance",
  "Billing correction",
  "Device swopped",
  "Sim swap",
  "Assisted with 101 order",
  "None",
  "Other"
];

export const SCHEDULED_CANCELLATION_OPTIONS = [
  "Affordability",
  "Network issues",
  "Billing issues ",
  "Moved to new ISP",
  "Coverage issues",
  "Bad customer service",
  "Issues relating to 4G mobile sims",
  "Purchased 101",
  "Lost/stolen/damaged device",
  "Declined further assistance",
  "Damaged/lost sim",
  "Service no longer required"
];

export const IMMEDIATE_CANCELLATION_OPTIONS = [
  "Client has previously requested cancellation",
  "Purchased 101",
  "Not within coverage",
  "Bad or no connection"
];

export const RETENTION_REASON_FOR_CANCELLATION_OPTIONS = [
  "Affordability",
  "Network issues",
  "Billing issues ",
  "Moved to new ISP",
  "Coverage issues",
  "Bad customer service",
  "Issues relating to 4G mobile sims",
  "Purchased 101",
  "Lost/stolen/damaged device",
  "Declined further assistance",
  "Damaged/lost sim",
];
