

export const RATING_ERROR = "1 or 2 rating error";
export const NO_ANSWER_EMAIL_SENT = "no answer - email sent";
export const PENDING_CUSTOMER_FEEDBACK = "pending customer feedback";

export const BAD_RATING_OPTIONS = {
  "reason for bad rating": [
    "delivery",
    "activation",
    "customer service",
    "billing experience",
    "billed for NO usage period (double billed or more)",
    "network experience",
    "load shedding",
    RATING_ERROR,
    NO_ANSWER_EMAIL_SENT
  ]
};

export const SOLUTIONS_OFFERED = {
  "solutions offered": [
    "optimisation",
    "optimisation with discount",
    "chip-in",
    "dasher",
    "device swap out",
    "service Migration",
    "downgrade (4G)",
    "sim swap"
  ]
};

export const OUTCOME_OPTIONS = [
  "resolved",
  "resolved but customer cancelling",
  "dasher Requested",
  "escalated to advance team",
  "escalated to Retentions",
  "no coverage",
  "failed to resolve",
  PENDING_CUSTOMER_FEEDBACK,
  "customer education"
] as const;

export type OutcomeOptions = typeof OUTCOME_OPTIONS[number];


export const CHANGE_OPTIONS = [
  "yes",
  "no"
];

export const NEW_RATING = [
  "Very satisfied",
  "Satisfied",
  "Neutral",
  "Unsatisfied",
  "Very unsatisfied"
];

