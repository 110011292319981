import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxsModule } from '@ngxs/store';
import { SharedModule } from '../../shared.module';
import { LoadsheddingBannerComponent } from './loadshedding-banner/loadshedding-banner.component';
import { CurrentLoadsheddingReportState } from './store/state/current-loadshedding-report.state';
import { LoadsheddingSchedulePerSimCardComponent } from './loadshedding-schedule-per-sim-card/loadshedding-schedule-per-sim-card.component';
import { ReactiveFormsModule } from '@angular/forms';
import { NewLoadsheddingPerSimState } from './store/state/new-loadshedding-per-sim.state';
import { NewLoadsheddingIconComponent } from './new-loadshedding-icon/new-loadshedding-icon.component';
import { LoadsheddingForSiteIDstate } from './store/state/loadshedding-schedule-for-site.state';


const declarations = [
  LoadsheddingBannerComponent,
  LoadsheddingSchedulePerSimCardComponent,
  NewLoadsheddingIconComponent
];

@NgModule({
  declarations: [...declarations],
  imports: [
    CommonModule,
    SharedModule,
    ReactiveFormsModule,
    NgxsModule.forFeature([
      CurrentLoadsheddingReportState,
      NewLoadsheddingPerSimState,
      LoadsheddingForSiteIDstate
    ]),
  ],
  exports: [...declarations]
})
export class LoadsheddingModule { }
