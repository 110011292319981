import { Feature, Geometry } from "geojson";
import { NormalSiteProperties, NormalSiteStatus } from "../map-site.interfaces";
import { TechType } from "../techtype.type";

export const IMAGE_BASE_PATH = 'assets/map-icons/';

type StyleOptions = google.maps.Data.StyleOptions & google.maps.Data.DataOptions;


export const buildStyle = (map: google.maps.Map, imageName: string, zIndex: number): StyleOptions => {
    return {
        map,
        zIndex,
        icon: {
            url: `${IMAGE_BASE_PATH}/${imageName}`,
            scaledSize: new google.maps.Size(25, 25),
            anchor: new google.maps.Point(12.5, 12.5),
        },
    }
}

export const getNormalSiteStyle = (map: google.maps.Map, f: google.maps.Data.Feature, zIndex: number) => {
    const imageName = getImageNameFromStatus(f);
    return buildStyle(map, imageName, zIndex);
}

export const getImageNameFromStatus = (f: google.maps.Data.Feature) =>
    `${f.getProperty('tech')?.toLowerCase()}_${f.getProperty('status').toLowerCase().replace(" ", "_")}.png`;



export const normalFeatureFilter = (techType: TechType) => (f: Feature<Geometry, NormalSiteProperties>) =>
    f?.properties?.tech === techType && f?.properties?.status === "OSS HO";

export const statusFeatureFilter = (status: NormalSiteStatus) => (f: Feature<Geometry, NormalSiteProperties>) =>
    f?.properties?.status === status;
