import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

export class BaseGraphQLService {

    protected _endpoint = '';
    protected _domain: string = environment.snowflake_api_url

    constructor(public _http: HttpClient) {}

    public query<T>(options: {
        query: string;
        variables?: { [key: string]: any };
    }): Observable<T> {
        return this._http
            .post<{ data: T, errors?: any }>(`${this._domain}/graphql`, {
                query: options.query,
                variables: options.variables,
            })
            .pipe(map((d) => {
                if(d.data) return d.data;

                else {return d.errors}
            }));
    }

    public mutate<T>(options: {
        mutation: string;
        variables?: { [key: string]: any };
    }): Observable<any> {
        return this._http
            .post<{ data: T }>(`${this._domain}/${this._endpoint}`, {
                query: options.mutation,
                variables: options.variables,
            })
            .pipe(map((d) => d.data));
    }
}
