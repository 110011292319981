<div
  class="escalations-modal-overlay"
  [class.is-open]="true"
>
  <div class="modal-lockup">
    <header>
      <div class="top-bar">
        <mat-icon class="default-cancel-icon" (click)="onClose()"
          >cancel</mat-icon
        >
      </div>
      <h1 class="default-card-header">escalate ticket</h1>
    </header>

    <div
      *ngIf="escalationForm; else isError"
      [formGroup]="escalationForm"
      class="form-container"
    >
      <div formGroupName="mainEscalationForm">
        <div
          class="form-row"
          [class.has-error]="checkForErrors('mainEscalationForm.reason')"
        >
          <label>reason:</label>
          <div class="input-container">
            <input
              formControlName="reason"
              placeholder="reason for escalation"
            />
          </div>
          <p>
            <span
              *ngIf="
                checkForErrors('mainEscalationForm.reason') &&
                escalationForm.get('mainEscalationForm.reason').errors.required
              "
            >
              This field is required.
            </span>
          </p>
        </div>
        <div
          *ngIf="teamOptions?.length > 0; else noOptions"
          class="form-row"
          [class.has-error]="checkForErrors('mainEscalationForm.team')"
        >
          <label>team:</label>
          <sf-select
            [options]="teamOptions"
            [reset]="!(isEscalationModalOpen$ | async)"
            (onOptionSelect)="onOptionSelect('mainEscalationForm.team', $event)"
            [placeholder]="'select a team to escalate to:'"
            [hasError]="
              escalationForm.get('mainEscalationForm.team').errors?.notSelected
            "
          ></sf-select>
          <p>
            <span
              *ngIf="
                checkForErrors('mainEscalationForm.team') &&
                escalationForm.get('mainEscalationForm.team').errors
                  ?.notSelected
              "
            >
              This field is required.
            </span>
          </p>
        </div>
      </div>

      <!--to the dasher escalation form -->
      <div *ngIf="checkIfDasher()" formGroupName="dasherEscalationForm">
        <div class="form-row form-group">
          <label>description of the issue:</label>
          <div class="input-container">
            <input
              formControlName="descriptionOfTheIssueTextbox"
              placeholder="description of the Issue"
            />
          </div>

          <p>
            <span
              *ngIf="
                checkForErrors(
                  'dasherEscalationForm.descriptionOfTheIssueTextbox'
                ) &&
                escalationForm.get(
                  'dasherEscalationForm.descriptionOfTheIssueTextbox'
                ).errors?.required
              "
            >
              This field is required.
            </span>
          </p>
        </div>

        <div
          class="form-row"
          [class.has-error]="checkForErrors('dasherEscalationForm.simDetails')"
        >
          <ng-container *ngIf="!smartsubDataLoaded$ | async; else dataLoaded">
            <small-text-spinner>loading..</small-text-spinner>
          </ng-container>

          <ng-template #dataLoaded>
            <label>sim details:</label>
            <sf-select
              *ngIf="simOptions?.length > 0; else noSimcards"
              [reset]="!(isEscalationModalOpen$ | async)"
              [options]="simOptions"
              (onOptionSelect)="
                onOptionSelect('dasherEscalationForm.simDetails', $event)
              "
              [placeholder]="'sim card details:'"
              [hasError]="
                escalationForm.get('dasherEscalationForm.simDetails').errors
                  ?.notSelected
              "
            ></sf-select>

            <p>
              <span
                *ngIf="
                  checkForErrors('dasherEscalationForm.simDetails') &&
                  escalationForm.get('dasherEscalationForm.simDetails').errors
                    ?.notSelected
                "
              >
                This field is required.
              </span>
            </p>
          </ng-template>
        </div>

        <div class="form-row">
          <div class="checkbox">
            <sf-form-checkbox
              [control]="
                this.escalationForm.get(
                  'dasherEscalationForm.use_alternate_address'
                )
              "
              label="use alternative address"
            >
            </sf-form-checkbox>
          </div>

          <ng-container class="address" *ngIf="useAltAddress">
            <sf-address-search
              [control]="
                this.escalationForm.get(
                  'dasherEscalationForm.confirmation_address'
                )
              "
              (onAddressSelect)="onAddressSelected($event)"
              label="Collection address"
            >
            </sf-address-search>

            <sf-snowflake-address-inputs
              (customerAddressOut)="setAddress($event)"
              [inputAddress]="inputAddress"
            >
            </sf-snowflake-address-inputs>
          </ng-container>
        </div>

        <div
          class="form-row"
          [class.has-error]="
            checkForErrors('dasherEscalationForm.deviceMakeandModel')
          "
        >
          <label>device make and model:</label>
          <div class="input-container">
            <input
              formControlName="deviceMakeandModel"
              placeholder="device make and model"
            />
          </div>

          <p>
            <span
              *ngIf="
                checkForErrors('dasherEscalationForm.deviceMakeandModel') &&
                escalationForm.get('dasherEscalationForm.deviceMakeandModel')
                  .errors?.required
              "
            >
              This field is required.
            </span>
          </p>
        </div>

        <div class="form-row">
          <div class="checkbox">
            <sf-form-checkbox
              [control]="
                this.escalationForm.get('dasherEscalationForm.feedbackCheckbox')
              "
              label="add additional feedback:"
            >
            </sf-form-checkbox>
          </div>

          <div
            *ngIf="addAdditionalFeedbackRequired"
            [class.has-error]="
              checkForErrors('dasherEscalationForm.feedbackTextbox')
            "
          >
            <div class="input-container">
              <input
                formControlName="feedbackTextbox"
                placeholder="add additional feedback"
              />
            </div>

            <p>
              <span
                *ngIf="
                  checkForErrors('dasherEscalationForm.feedbackTextbox') &&
                  escalationForm.get('dasherEscalationForm.feedbackTextbox')
                    .errors.required
                "
              >
                This field is required.
              </span>
            </p>
          </div>
        </div>

        <div class="form-row required-dasher">
          <label for="">what is required of the Dasher:</label>
          <div class="checkbox required-dasher">
            <sf-form-checkbox
              [control]="
                this.escalationForm.get(
                  'dasherEscalationForm.deviceSwapOutCheckbox'
                )
              "
              label="device swap out"
            >
            </sf-form-checkbox>
          </div>

          <div class="checkbox required-dasher">
            <sf-form-checkbox
              [control]="
                this.escalationForm.get('dasherEscalationForm.simSwapCheckbox')
              "
              label="sim Swap"
            >
            </sf-form-checkbox>
          </div>
          <div class="checkbox required-dasher">
            <sf-form-checkbox
              [control]="
                this.escalationForm.get(
                  'dasherEscalationForm.testSimAndDeviceCheckbox'
                )
              "
              label="test sim and device"
            >
            </sf-form-checkbox>
          </div>
          <div class="checkbox required-dasher">
            <sf-form-checkbox
              [control]="
                this.escalationForm.get(
                  'dasherEscalationForm.reoptimizeConnectionCheckbox'
                )
              "
              label="reoptimize the connection"
            >
            </sf-form-checkbox>
          </div>
          <p class="trouble-shooting-label required-dasher">
            all trouble shooting done:
          </p>
          <div class="text-area">
            <input
              formControlName="allTroubleShootingDoneTextbox"
              placeholder="all troubleshooting done"
            />
          </div>

          <p>
            <span
              *ngIf="
                checkForErrors(
                  'dasherEscalationForm.allTroubleShootingDoneTextbox'
                ) &&
                escalationForm.get(
                  'dasherEscalationForm.allTroubleShootingDoneTextbox'
                ).errors.required
              "
            >
              This field is required.
            </span>
          </p>
        </div>

        <div class="form-row">
          <div class="checkbox">
            <sf-form-checkbox
              [control]="
                this.escalationForm.get(
                  'dasherEscalationForm.equipmentNeededCheckbox'
                )
              "
              label="equipment needed from warehouse"
            >
            </sf-form-checkbox>
          </div>

          <div
            *ngIf="equipmentRequired"
            [class.has-error]="
              checkForErrors('dasherEscalationForm.equipmentNeededTextbox')
            "
          >
            <div class="input-container">
              <input
                formControlName="equipmentNeededTextbox"
                placeholder="Equipment Needed"
              />
            </div>

            <p>
              <span
                *ngIf="
                  checkForErrors(
                    'dasherEscalationForm.equipmentNeededTextbox'
                  ) &&
                  escalationForm.get(
                    'dasherEscalationForm.equipmentNeededTextbox'
                  ).errors.required
                "
              >
                This field is required.
              </span>
            </p>
          </div>
        </div>

        <div class="form-row">
          <div class="checkbox">
            <sf-form-checkbox
              [control]="
                this.escalationForm.get(
                  'dasherEscalationForm.attachmentsCheckbox'
                )
              "
              label="Add attachments"
            >
            </sf-form-checkbox>
          </div>

          <div
            class="file-upload-wrapper"
            *ngIf="HasAttachments && attachmentModalOpen"
          >
            <app-file-uploader
              [control]="
                this.escalationForm.get(
                  'dasherEscalationForm.attachmentsUploader'
                )
              "
              *ngIf="escalationForm"
              (newFiles)="attachFileData($event)"
              (close)="closeAttachmentUploader()"
            ></app-file-uploader>
          </div>
        </div>
      </div>

      <!--from  agent-->

      <div
        *ngIf="checkIfAgentThatCanEscalateToDasher() && checkIfDasher()"
        formGroupName="fromAgentEscalationForm"
      >
        <div class="form-row">
          <div class="checkbox">
            <sf-form-checkbox
              [control]="
                this.escalationForm.get(
                  'fromAgentEscalationForm.contactDetailsCheckbox'
                )
              "
              label="Contact details different to profile:"
            >
            </sf-form-checkbox>
          </div>

          <div
            *ngIf="hasOtherContactDetails"
            [class.has-error]="
              checkForErrors('fromAgentEscalationForm.contactDetailsTextbox')
            "
          >
            <div class="input-container">
              <input
                formControlName="contactDetailsTextbox"
                placeholder="contact details"
              />
            </div>

            <p>
              <span
                *ngIf="
                  checkForErrors(
                    'fromAgentEscalationForm.contactDetailsTextbox'
                  ) &&
                  escalationForm.get(
                    'fromAgentEscalationForm.contactDetailsTextbox'
                  ).errors.required
                "
              >
                This field is required.
              </span>
            </p>
          </div>
        </div>

        <div class="form-row form-group">
          <label>reason for dasher request:</label>
          <div class="input-container">
            <input
              formControlName="reasonForDasherRequestTextbox"
              placeholder="reason for dasher request:"
            />
          </div>

          <p>
            <span
              *ngIf="
                checkForErrors(
                  'fromAgentEscalationForm.reasonForDasherRequestTextbox'
                ) &&
                escalationForm.get(
                  'fromAgentEscalationForm.reasonForDasherRequestTextbox'
                ).errors?.required
              "
            >
              This field is required.
            </span>
          </p>
        </div>
        <div class="form-row">
          <div class="checkbox">
            <sf-form-checkbox
              [control]="
                this.escalationForm.get(
                  'fromAgentEscalationForm.alarmsOnSiteCheckbox'
                )
              "
              label="Has Alarms:"
            >
            </sf-form-checkbox>
          </div>

          <div
            *ngIf="hasAlarmsOnSite"
            [class.has-error]="
              checkForErrors('fromAgentEscalationForm.alarmsOnSiteTextbox')
            "
          >
            <div class="input-container">
              <input
                formControlName="alarmsOnSiteTextbox"
                placeholder="Alarms on Site"
              />
            </div>

            <p>
              <span
                *ngIf="
                  checkForErrors(
                    'fromAgentEscalationForm.alarmsOnSiteTextbox'
                  ) &&
                  escalationForm.get(
                    'fromAgentEscalationForm.alarmsOnSiteTextbox'
                  ).errors.required
                "
              >
                This field is required.
              </span>
            </p>
          </div>
        </div>

        <label>location type:</label>
        <sf-select
          *ngIf="locationTypes?.length > 0"
          [reset]="!(isEscalationModalOpen$ | async)"
          [options]="locationTypes"
          (onOptionSelect)="
            onOptionSelect('fromAgentEscalationForm.locationType', $event)
          "
          [placeholder]="'Location type:'"
          [hasError]="
            escalationForm.get('fromAgentEscalationForm.locationType').errors
              ?.notSelected
          "
        ></sf-select>
        <div class="checkbox required-dasher">
          <sf-form-checkbox
            [control]="
              this.escalationForm.get(
                'fromAgentEscalationForm.PDPResetCheckbox'
              )
            "
            label="PDP Reset"
          >
          </sf-form-checkbox>
        </div>

        <div class="checkbox required-dasher">
          <sf-form-checkbox
            [control]="
              this.escalationForm.get(
                'fromAgentEscalationForm.policyStatusConfirmedCheckbox'
              )
            "
            label="policy status confirmed"
          >
          </sf-form-checkbox>
        </div>
        <div class="checkbox required-dasher">
          <sf-form-checkbox
            [control]="
              this.escalationForm.get(
                'fromAgentEscalationForm.coverageConfirmedCheckbox'
              )
            "
            label="coverage confirmed"
          >
          </sf-form-checkbox>
        </div>
        <div class="checkbox required-dasher">
          <sf-form-checkbox
            [control]="
              this.escalationForm.get(
                'fromAgentEscalationForm.noNetworkSubscriberStateDataCheckbox'
              )
            "
            label="no network subscriber state data"
          >
          </sf-form-checkbox>
        </div>
        <div class="checkbox required-dasher">
          <sf-form-checkbox
            [control]="
              this.escalationForm.get(
                'fromAgentEscalationForm.provisioningConfirmedCheckbox'
              )
            "
            label="provisioning confirmed"
          >
          </sf-form-checkbox>
        </div>
        <div class="checkbox required-dasher">
          <sf-form-checkbox
            [control]="
              this.escalationForm.get(
                'fromAgentEscalationForm.simMismatchCheckbox'
              )
            "
            label="sim mismatch"
          >
          </sf-form-checkbox>
        </div>
        <div class="checkbox required-dasher">
          <sf-form-checkbox
            [control]="
              this.escalationForm.get(
                'fromAgentEscalationForm.IMSImismatchCheckbox'
              )
            "
            label="IMSI mismatch"
          >
          </sf-form-checkbox>
        </div>
        <div class="checkbox required-dasher">
          <sf-form-checkbox
            [control]="
              this.escalationForm.get(
                'fromAgentEscalationForm.billingUpToDateConfirmed'
              )
            "
            label="billing up to date confirmed"
          >
          </sf-form-checkbox>
        </div>
      </div>

      <!-- From a dasher Dasher -->
      <div
        *ngIf="checkIfTeamRoleDasher()"
        formGroupName="fromdasherEscalationForm"
      >
        <div class="checkbox required-dasher">
          <sf-form-checkbox
            [control]="
              this.escalationForm.get(
                'fromdasherEscalationForm.onSiteOptimizationCheckbox'
              )
            "
            label="on-site optimization checkbox"
          >
          </sf-form-checkbox>
        </div>

        <div class="checkbox required-dasher">
          <sf-form-checkbox
            [control]="
              this.escalationForm.get(
                'fromdasherEscalationForm.deviceTestingCheckbox'
              )
            "
            label="device testing"
          >
          </sf-form-checkbox>
        </div>

        <div class="checkbox required-dasher">
          <sf-form-checkbox
            [control]="
              this.escalationForm.get(
                'fromdasherEscalationForm.deviceSwapOutCheckbox'
              )
            "
            label="device swap-out"
          >
          </sf-form-checkbox>
        </div>

        <div class="checkbox required-dasher">
          <sf-form-checkbox
            [control]="
              this.escalationForm.get(
                'fromdasherEscalationForm.escalatedToNCCCheckbox'
              )
            "
            label="escalated to NCCC"
          >
          </sf-form-checkbox>
        </div>

        <div class="checkbox required-dasher">
          <sf-form-checkbox
            [control]="
              this.escalationForm.get(
                'fromdasherEscalationForm.clientEducationCheckbox'
              )
            "
            label="client education"
          >
          </sf-form-checkbox>
        </div>

        <div class="checkbox required-dasher">
          <sf-form-checkbox
            [control]="
              this.escalationForm.get(
                'fromdasherEscalationForm.mountedDeviceCheckbox'
              )
            "
            label="mounted device "
          >
          </sf-form-checkbox>
        </div>

        <div class="checkbox required-dasher">
          <sf-form-checkbox
            [control]="
              this.escalationForm.get(
                'fromdasherEscalationForm.POEFaultyCheckbox'
              )
            "
            label="POE faulty"
          >
          </sf-form-checkbox>
        </div>

        <div class="checkbox required-dasher">
          <sf-form-checkbox
            [control]="
              this.escalationForm.get(
                'fromdasherEscalationForm.WifiFaultyCheckbox'
              )
            "
            label="WIFI faulty"
          >
          </sf-form-checkbox>
        </div>

        <div class="checkbox required-dasher">
          <sf-form-checkbox
            [control]="
              this.escalationForm.get(
                'fromdasherEscalationForm.ClientDeviceIncompatibilityCheckbox'
              )
            "
            label="client device incompatibility"
          >
          </sf-form-checkbox>
        </div>

        <div class="form-row form-group">
          <label>fault finding :</label>
          <div class="input-container">
            <input
              formControlName="faultFindingTextbox"
              placeholder="fault finding:"
            />
          </div>

          <p>
            <span
              *ngIf="
                checkForErrors(
                  'fromdasherEscalationForm.faultFindingTextbox'
                ) &&
                escalationForm.get(
                  'fromdasherEscalationForm.faultFindingTextbox'
                ).errors?.required
              "
            >
              This field is required.
            </span>
          </p>
        </div>

        <div class="form-row form-group">
          <label>RSRP before :</label>
          <div class="input-container">
            <input
              formControlName="RSRPBeforeTextbox"
              placeholder="RSRP before:"
            />
          </div>

          <p>
            <span
              *ngIf="
                checkForErrors('fromdasherEscalationForm.RSRPBeforeTextbox') &&
                escalationForm.get('fromdasherEscalationForm.RSRPBeforeTextbox')
                  .errors?.required
              "
            >
              This field is required.
            </span>
          </p>
        </div>

        <div class="form-row form-group">
          <label>RSRP after :</label>
          <div class="input-container">
            <input
              formControlName="RSRPAfterTextbox"
              placeholder="RSRP after:"
            />
          </div>

          <p>
            <span
              *ngIf="
                checkForErrors('fromdasherEscalationForm.RSRPAfterTextbox') &&
                escalationForm.get('fromdasherEscalationForm.RSRPAfterTextbox')
                  .errors?.required
              "
            >
              This field is required.
            </span>
          </p>
        </div>

        <div class="form-row form-group">
          <label>RSRQ before :</label>
          <div class="input-container">
            <input
              formControlName="RSRQBeforeTextbox"
              placeholder="RSRQ before:"
            />
          </div>

          <p>
            <span
              *ngIf="
                checkForErrors('fromdasherEscalationForm.RSRQBeforeTextbox') &&
                escalationForm.get('fromdasherEscalationForm.RSRQBeforeTextbox')
                  .errors?.required
              "
            >
              This field is required.
            </span>
          </p>
        </div>

        <div class="form-row form-group">
          <label>RSRQ after :</label>
          <div class="input-container">
            <input
              formControlName="RSRQAfterTextbox"
              placeholder="RSRQ after:"
            />
          </div>

          <p>
            <span
              *ngIf="
                checkForErrors('fromdasherEscalationForm.RSRQAfterTextbox') &&
                escalationForm.get('fromdasherEscalationForm.RSRQAfterTextbox')
                  .errors?.required
              "
            >
              This field is required.
            </span>
          </p>
        </div>

        <div class="form-row form-group">
          <label>SINR before :</label>
          <div class="input-container">
            <input
              formControlName="SINRBeforeTextbox"
              placeholder="SINR before:"
            />
          </div>

          <p>
            <span
              *ngIf="
                checkForErrors('fromdasherEscalationForm.SINRBeforeTextbox') &&
                escalationForm.get('fromdasherEscalationForm.SINRBeforeTextbox')
                  .errors?.required
              "
            >
              This field is required.
            </span>
          </p>
        </div>

        <div class="form-row form-group">
          <label>SINR after :</label>
          <div class="input-container">
            <input
              formControlName="SINRAfterTextbox"
              placeholder="SINR after:"
            />
          </div>

          <p>
            <span
              *ngIf="
                checkForErrors('fromdasherEscalationForm.SINRAfterTextbox') &&
                escalationForm.get('fromdasherEscalationForm.SINRAfterTextbox')
                  .errors?.required
              "
            >
              This field is required.
            </span>
          </p>
        </div>

        <div class="form-row form-group">
          <label>speed test before :</label>
          <div class="input-container">
            <input
              formControlName="speedTestBeforeTextbox"
              placeholder="speed test before:"
            />
          </div>

          <p>
            <span
              *ngIf="
                checkForErrors(
                  'fromdasherEscalationForm.speedTestBeforeTextbox'
                ) &&
                escalationForm.get(
                  'fromdasherEscalationForm.speedTestBeforeTextbox'
                ).errors?.required
              "
            >
              This field is required.
            </span>
          </p>
        </div>

        <div class="form-row form-group">
          <label>speed test after :</label>
          <div class="input-container">
            <input
              formControlName="speedTestAfterTextbox"
              placeholder="speed test before:"
            />
          </div>

          <p>
            <span
              *ngIf="
                checkForErrors(
                  'fromdasherEscalationForm.speedTestAfterTextbox'
                ) &&
                escalationForm.get(
                  'fromdasherEscalationForm.speedTestAfterTextbox'
                ).errors?.required
              "
            >
              This field is required.
            </span>
          </p>
        </div>

        <div class="form-row form-group">
          <label>solution or feedback :</label>
          <div class="input-container">
            <input
              formControlName="solutionOrFeedbackTextbox"
              placeholder="solution or feedback:"
            />
          </div>

          <p>
            <span
              *ngIf="
                checkForErrors(
                  'fromdasherEscalationForm.solutionOrFeedbackTextbox'
                ) &&
                escalationForm.get(
                  'fromdasherEscalationForm.solutionOrFeedbackTextbox'
                ).errors?.required
              "
            >
              This field is required.
            </span>
          </p>
        </div>
      </div>
      <!--End of from a dasher -->

      <!--To the collections escalation form-->
      <!--form commented out as the team is both collections ( LOGISTICS.COLLECTIONS_IMMEDIATE.A and LOGISTICS.COLLECTIONS_SCHEDULED.A) teams are removed-->
      <!-- <div *ngIf="checkIfCollection()" formGroupName="collectionEscalationForm">
        <div class="form-row">
          <div class="checkbox">
            <sf-form-checkbox
              [control]="
                this.escalationForm.get(
                  'collectionEscalationForm.contactDetailsCheckbox'
                )
              "
              label="Contact details different to profile:"
            >
            </sf-form-checkbox>
          </div>

          <div
            *ngIf="hasOtherContactDetails"
            [class.has-error]="
              checkForErrors('collectionEscalationForm.customerDetails')
            "
          >
            <div class="input-container">
              <input
                formControlName="customerDetails"
                placeholder="name,mobile,or alternative number:"
              />
            </div>

            <p>
              <span
                *ngIf="
                  checkForErrors('collectionEscalationForm.customerDetails') &&
                  escalationForm.get('collectionEscalationForm.customerDetails')
                    .errors.required
                "
              >
                This field is required.
              </span>
            </p>
          </div>
        </div>
        <div
          class="form-row"
          [class.has-error]="
            checkForErrors('collectionEscalationForm.simDetails')
          "
        >
          <ng-container *ngIf="!smartsubDataLoaded$ | async; else dataLoaded">
            <small-text-spinner>loading..</small-text-spinner>
          </ng-container>

          <ng-template #dataLoaded>
            <label>sim details:</label>
            <sf-select
              *ngIf="simOptions?.length > 0; else noSimcards"
              [reset]="!(isEscalationModalOpen$ | async)"
              [options]="simOptions"
              (onOptionSelect)="
                onOptionSelect('collectionEscalationForm.simDetails', $event)
              "
              [placeholder]="'sim card details:'"
              [hasError]="
                escalationForm.get('collectionEscalationForm.simDetails').errors
                  ?.notSelected
              "
            ></sf-select>

            <p>
              <span
                *ngIf="
                  checkForErrors('collectionEscalationForm.simDetails') &&
                  escalationForm.get('collectionEscalationForm.simDetails')
                    .errors?.notSelected
                "
              >
                This field is required.
              </span>
            </p>
          </ng-template>
        </div>

        <div
          class="form-row"
          [class.has-error]="
            checkForErrors('collectionEscalationForm.deviceMakeandModel')
          "
        >
          <label>device make and model:</label>
          <div class="input-container">
            <input
              formControlName="deviceMakeandModel"
              placeholder="device make and model"
            />
          </div>

          <p>
            <span
              *ngIf="
                checkForErrors('collectionEscalationForm.deviceMakeandModel') &&
                escalationForm.get(
                  'collectionEscalationForm.deviceMakeandModel'
                ).errors?.required
              "
            >
              This field is required.
            </span>
          </p>
        </div>

        <div class="form-row">
          <div class="checkbox">
            <sf-form-checkbox
              [control]="
                this.escalationForm.get(
                  'collectionEscalationForm.use_alternate_address'
                )
              "
              label="use alternative address"
            >
            </sf-form-checkbox>
          </div>

          <ng-container class="address" *ngIf="useAltAddress">
            <sf-address-search
              [control]="
                this.escalationForm.get(
                  'collectionEscalationForm.collection_address'
                )
              "
              (onAddressSelect)="onAddressSelected($event)"
              label="Collection address"
            >
            </sf-address-search>

            <sf-snowflake-address-inputs
              (customerAddressOut)="setAddress($event)"
              [inputAddress]="inputAddress"
            >
            </sf-snowflake-address-inputs>
          </ng-container>
        </div>

        <div
          *ngIf="this.collectionTypes?.length > 0"
          class="form-row"
          [class.has-error]="
            checkForErrors('collectionEscalationForm.collectionType')
          "
        >
          <label for="">select the type of collection:</label>
          <sf-select
            [options]="this.collectionTypes"
            [reset]="!(isEscalationModalOpen$ | async)"
            (onOptionSelect)="
              onOptionSelect('collectionEscalationForm.collectionType', $event)
            "
            [placeholder]="'select the type of escalation:'"
            [hasError]="
              this.escalationForm.get('collectionEscalationForm.collectionType')
                .errors?.notSelected
            "
          ></sf-select>
          <p>
            <span
              *ngIf="
                checkForErrors('collectionEscalationForm.collectionType') &&
                this.escalationForm.get(
                  'collectionEscalationForm.collectionType'
                ).errors.notSelected
              "
            >
              This field is required.
            </span>
          </p>
        </div>

        <div class="form-row">
          <div class="checkbox">
            <sf-form-checkbox
              [control]="
                this.escalationForm.get(
                  'collectionEscalationForm.creditCheckbox'
                )
              "
              label="user has credit:"
            >
            </sf-form-checkbox>
          </div>

          <div
            *ngIf="userHasCredit"
            [class.has-error]="
              checkForErrors('collectionEscalationForm.creditAmount')
            "
          >
            <div class="input-container">
              <input formControlName="creditAmount" placeholder="R0" />
            </div>

            <p>
              <span
                *ngIf="
                  checkForErrors('collectionEscalationForm.creditAmount') &&
                  escalationForm.get('collectionEscalationForm.creditAmount')
                    .errors.required
                "
              >
                This field is required.
              </span>
            </p>
          </div>
        </div>
      </div> -->
      <ng-template #noOptions>
        <div class="no-team-options-error">
          <p>No escalation options for your group</p>
        </div>
      </ng-template>
      <ng-template #noSimcards>
        <div class="no-team-options-error">
          <p>No sim card options for this user</p>
        </div>
      </ng-template>
      <ng-template #noAddressChosen>
        <div class="no-address-chosen-error">
          <p>No address chosen for this user yet</p>
        </div>
      </ng-template>
    </div>
    <ng-template #isError>
      <div class="no-data-error">
        <div>
          <p *ngFor="let msg of errorLines" class="text red">{{ msg }}</p>
        </div>
      </div>
    </ng-template>
    <div class="send-button-wrapper">
      <button
        class="rain-button default-button-size send-button"
        (click)="onClose()"
      >
        cancel
      </button>
      <button
        class="rain-button default-button-size send-button solid"
        [disabled]="isValid() || (this.useAltAddress && this.addressInput?.form?.invalid) || updatingTeam"
        (click)="onEscalate()">
        escalate
      </button>
    </div>
    <div *ngIf="updatingTeam" class="small-spinner-wrapper">
      <small-text-spinner>sending..</small-text-spinner>
    </div>
  </div>
</div>
