import { Utils } from "src/app/Utils";
import { AddCallbackPayload, AddCallbackRequestPayload } from "../../types/add-callback-payload.interface";
import { Callback } from "../../types/callback-response.interface";

export class AddCallbackInCtx {
    static readonly type = Utils.Helpers.Type("[Add callback] Add callback in ctx");
    constructor(public payload: AddCallbackPayload) { }
}

export class AddCallback {
    static readonly type = Utils.Helpers.Type("[Add callback] Add callback");
    constructor(public payload: AddCallbackRequestPayload) { }
}

export class AddCallbackSuccess {
    static readonly type = Utils.Helpers.Type("[Add callback] Add callback success");
    constructor(public payload: Callback) { }
}

export class AddCallbackFail {
    static readonly type = Utils.Helpers.Type("[Add callback] Add callback fail");
    constructor(public error: unknown) { }
}


