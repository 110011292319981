import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { SalesSettingActions } from '../../action/sales-setting-actions';
import { SalesPortalDestroyed } from '../../action/sales-portal.actions';
import { SalesCartState } from '../sales-cart.state';

const getDefaults = (): SalesSettingsStateModel => {
    return {
        isKnownCustomer: false,
        isMigration: false
    }
}

interface SalesSettingsStateModel {
    isKnownCustomer: boolean;
    isMigration: boolean;
}
@State<SalesSettingsStateModel>({
    name: 'sf_sales_settings_state',
    defaults: getDefaults()
})
@Injectable()
export class SalesSettingsState {

    @Selector([SalesSettingsState])
    static isKnownCustomer(state: SalesSettingsStateModel) { return state.isKnownCustomer }

    @Selector()
    static isMigration(state: SalesSettingsStateModel) { return state.isMigration }

    @Selector([
        SalesSettingsState.isKnownCustomer,
        SalesCartState.mobileSelected
    ])
    static shouldDoCoverageCheck(
      isKnownCustomer: boolean,
      mobileSelected: boolean
    ) {
      return isKnownCustomer && !mobileSelected // removing && !state.isMigration
    }

    @Action(SalesSettingActions.SetIsKnownCustomer)
    actionNameFail(ctx: StateContext<SalesSettingsStateModel>, action: SalesSettingActions.SetIsKnownCustomer) {
        ctx.patchState({
            isKnownCustomer: action.isKnown
        });
    }

    @Action(SalesSettingActions.SetIsMigration)
    setIsMigration(ctx: StateContext<SalesSettingsStateModel>, action: SalesSettingActions.SetIsMigration) {
        const { isMigration } = action
        ctx.patchState({
            isMigration
        });
    }

    @Action([SalesPortalDestroyed, SalesSettingActions.Clear])
    Clear(ctx: StateContext<SalesSettingsStateModel>) {
        ctx.patchState(getDefaults());
    }

}
