import { Component, Input, OnInit } from '@angular/core';
import { AsyncSuccessLoaderConfig } from '../async-success-loader/async-success-loader-config.interface';

@Component({
  selector: 'sf-success-progress-stepper',
  templateUrl: './success-progress-stepper.component.html',
  styleUrls: ['./success-progress-stepper.component.scss']
})
export class SuccessProgressStepperComponent implements OnInit {

  @Input() configs: AsyncSuccessLoaderConfig[];

  ngOnInit(): void {
    if (!this.configs) {
      throw new Error("No configs were provided for the SuccessProgressStepperComponent");
    }

  }

}
