import { Observable, throwError, timer } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

export interface RetryStrategyOptions {
    maxRetryAttempts?: number,
    delay?: number;
    excludedStatusCodes?: number[]
}

export const genericRetryStrategy = <T extends { status: number }>({
    maxRetryAttempts = 3,
    delay = 1000,
    excludedStatusCodes = []
}: RetryStrategyOptions = {}) => (errors$: Observable<T>) => {

    return errors$
        .pipe(
            mergeMap((error, i) => {
                const retryAttempt = i + 1;

                const maxAttemptsReached = retryAttempt > maxRetryAttempts;
                const includesExcludibleCode = excludedStatusCodes.some(code => code === error?.status);

                if (maxAttemptsReached || includesExcludibleCode) {
                    return throwError(error);
                }

                return timer(delay);
            })
        );
};