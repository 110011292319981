<div class="main-div">

  <div class="header-section">
    <ng-content select="[headerSection]"></ng-content>
  </div>

  <div class="top-spacer">
    <span *ngIf="isBackButtonShown" class="button-detailed-summary back-button text text-blue pointer" (click)="onBack()">
      <mat-icon>keyboard_arrow_left</mat-icon>
      <h2>back</h2>
    </span>

    <span class="default-spacer"></span>

    <span *ngIf="hasContinueButton" class="button-detailed-summary continue-button text text-blue pointer" (click)="onContinue()">
      <h2>continue</h2>
      <mat-icon>keyboard_arrow_right</mat-icon>
    </span>
  </div>

  <div class="main-content" [class.scrollable]="scrollable">
    <ng-content select="[mainContent]"></ng-content>
  </div>

</div>
