import { Utils } from "src/app/Utils";
import { VoicePlatformData } from "../../../services/sip-platform/assets/voice-platform-data.interface";



export class FetchLoginDetails {
    static readonly type = Utils.Helpers.Type('[Voice: Auth] Fetch login details');
    constructor(public email: string) { }
}

export class FetchLoginDetailsSuccess {
    static readonly type = Utils.Helpers.Type('[Voice: Auth] Fetch login details success');
    constructor(public payload: VoicePlatformData) { }
}

export class FetchLoginDetailsFail {
    static readonly type = Utils.Helpers.Type('[Voice: Auth] Fetch login details fail');
    constructor(public error: any) { }
}

export class Retry {
    static readonly type = Utils.Helpers.Type('[Voice: Auth] Retry');
}

export class Clear {
    static readonly type = Utils.Helpers.Type('[Voice: Auth] clear');
}

