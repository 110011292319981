/* eslint-disable @typescript-eslint/no-explicit-any */
import { Subject, timer, merge } from "rxjs";
import { filter, take, takeUntil, tap } from "rxjs/operators";

interface ActionSchedulerOptions {
    baseFilter?: () => boolean;
}

export interface SchedulerAction {
    timeOut: number;
    action: () => any;
}

export class ActionScheduler {

    private readonly _clearTimer$ = new Subject<void>();
    private readonly _schedulerActions: SchedulerAction[] = [];

    private _paused = false;

    constructor(private _options: ActionSchedulerOptions = {}) { }

    private composeStream() {
        const { baseFilter } = this._options;
        const filterFn = baseFilter
            ? () => baseFilter() && !this._paused
            : () => !this._paused;

        return merge(...this.createTimers(filterFn));
    }

    private createTimers(filterFn: () => boolean) {
        let currentTimeOut = 0;
        return this._schedulerActions
            .map(({ timeOut, action }) => {
                currentTimeOut += timeOut;
                return timer(currentTimeOut)
                    .pipe(
                        filter(filterFn),
                        tap(() => action())
                    )
            });
    }

    addAction(action: SchedulerAction) {
        this._schedulerActions.push(action);
        return this;
    }

    pause() {
        this._paused = true;
        return this;
    }

    play() {
        this._paused = false;
        return this;
    }

    startTimer() {
        if (!this._schedulerActions.length) {
            throw Error("No actions where added.");
        }

        this.composeStream()
            .pipe(
                take(this._schedulerActions.length),
                takeUntil(this._clearTimer$)
            )
            .subscribe();
        return this;
    }

    clearTimer() {
        this._clearTimer$.next(null);
        return this;
    }

    resetTimer() {
        this.clearTimer()
            .startTimer();
        return this;
    }

    destroy() {
        this._clearTimer$.next();
        this._clearTimer$.complete();
    }


}