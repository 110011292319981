import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { SiteIncidentLog } from '../../sim-card-page/sub-components/coverage/optimize/store/types/incident-log.interface';
import { NWDAFConnectedSite } from '../interfaces/nwdaf-connected-site.interface';

@Injectable({
    providedIn: 'root'
})
export class NWDAFService {

    constructor(private http: HttpClient) { }

    fetchConnectedSiteByIMSI(imsi: string) {
        const url = `${environment.smartSubApi}/data/nwdaf_connected_site/${imsi}`;

        const headers = {
            "Content-Type": "application/json"
        }

        return this.http.get<{ data: [NWDAFConnectedSite] }>(url, { headers });
    }

    fetchSiteIncidentLog(siteName: string) {
        const url = `${environment.smartSubApi}/data/site_incident_log/${siteName}`;

        const headers = {
            "Content-Type": "application/json"
        }

        return this.http.get<{ data: [SiteIncidentLog] }>(url, { headers });
    }
}