import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngxs/store';
import { ToggleModalByName } from 'src/app/core/store/actions/modal.actions';
import { SnowflakeSelectOption } from 'src/app/shared/elements/rain-forms/elements/snowflake-select/interfaces/rain-select-option.interface';
import { PostTicketModalOptions } from 'src/app/shared/services/post-ticket/post-ticket-modal-options.model';
import { FormHelper } from 'src/app/Utils/helpers';
import { changeBooleanToStatus } from '../assets/convert-boolean.function';
import { PostTicketService } from 'src/app/shared/services/post-ticket/post-ticket.service';
import { InteractionTicketsState } from '../../store/state/interaction-tickets.state';
import { ChatJsonNote } from '../components/chat-json-note/assets/chat-json-note.interface';

@Component({
    selector: 'sf-activations-post-close-modal',
    templateUrl: './activations-post-close-modal.component.html',
    styleUrls: ['./activations-post-close-modal.component.scss'],
})
export class ActivationsPostCloseModalComponent extends FormHelper implements OnInit {
    modalOptions: PostTicketModalOptions;

    postActivationsForm: FormGroup;
    contactOptions: SnowflakeSelectOption[] = [
        { label: 'Yes', value: 'yes' },
        { label: 'No', value: 'no' },
    ];
    outcomeTypes: SnowflakeSelectOption[] = [
        { label: 'Retained', value: 'retained' },
        { label: 'Cancelled', value: 'cancelled' },
        { label: 'Migrated', value: 'migrated' },
        { label: 'Downgraded', value: 'downgraded' },
        { label: 'No Answer - email sent', value: 'no answer - email' },
        { label: 'No Contact', value: 'no contact' }
    ];
    customerExperienceOptions: SnowflakeSelectOption[] = [
        {
            label: 'Activated and Connected with no issues',
            value: 'Activated and Connected with no issues',
        },
        {
            label: 'Activated with slow/interrupted connection',
            value: 'Activated with slow/interrupted connection',
        },
        { label: 'Activated with no usage', value: 'Activated with no usage' },
        { label: 'Activated with usage', value: 'Activated with usage' },
    ];

    constructor(
        private fb: FormBuilder,
        private store: Store,
        private postTicketService: PostTicketService,
    ) { super(); }

    ngOnInit(): void {
        this.modalOptions = new PostTicketModalOptions({
            acceptableStatuses: [200, 201, 204],
        });
        this.createForm();
        this.InitForm(this.postActivationsForm);
    }

    onOptionSelect(formField: string, value: string) {
        this.postActivationsForm?.get(formField)?.patchValue(value);
    }

    createForm() {
        this.postActivationsForm = this.fb.group({
            contacted: this.fb.control(
                '',
                Validators.compose([Validators.required]),
            ),
            outcome: this.fb.control(
                '',
                Validators.compose([Validators.required]),
            ),
            customer_current_experience: this.fb.control(
                '',
                Validators.compose([Validators.required]),
            ),
            optimisation: this.fb.control(''),
            dasher_request: this.fb.control(''),
            device_setup: this.fb.control(''),
            device_swap_out: this.fb.control(''),
            sim_card_swap_out: this.fb.control(''),
            wifi_setup: this.fb.control(''),
            no_additional_help: this.fb.control(''),
            no_contact_email_sent: this.fb.control('')
        });
    }

    onSend() {
        const hexId = this.store.selectSnapshot(InteractionTicketsState.getSelectedHexId);
        const formData = this.postActivationsForm.getRawValue();
        const data = {
            ticket_id: BigInt(hexId).toString(),
            post_activations_modal: {
                'contacted':
                    formData.contacted,
                'outcome': formData.outcome,
                'customer current experience': formData.customer_current_experience,
                'solutions provided':
                {
                    '1. optimisation': changeBooleanToStatus(formData.optimisation),
                    '2. dasher request': changeBooleanToStatus(formData.dasher_request),
                    '3. device setup': changeBooleanToStatus(formData.device_setup),
                    '4. device swap out': changeBooleanToStatus(formData.device_swap_out),
                    '5. sim card swap out': changeBooleanToStatus(formData.sim_card_swap_out),
                    '6. WIFI setup': changeBooleanToStatus(formData.wifi_setup),
                    '7. did not need additional Help': changeBooleanToStatus(formData.no_additional_help),
                    '8. no contact email sent': changeBooleanToStatus(formData.no_contact_email_sent),
                }
            },
        };


        const payload: ChatJsonNote = {
            message: "Activations post close form",
            detail: data,
            type: "activations_form"
        }

        this.postTicketService.sendPostTicketPayload(
            hexId,
            payload,
            this.modalOptions,
            this.close.bind(this),
        );

    }

    close() {
        this.store.dispatch(
            new ToggleModalByName('post_activations_modal', false),
        );
    }

    ngOnDestroy(): void {
        this.ngDestroy$.next();
        this.ngDestroy$.complete();
        this._form.reset;
        this.close();
    }
}
