
import { Utils } from "src/app/Utils";
import { LoadsheddingSchedulePerSite } from "../interfaces/loadshedding-schedule-per-site";


export class FetchLoadsheddingScheduleForSiteID {
  static readonly type = Utils.Helpers.Type('[Loadshedding: Fetch] Fetch loadshedding schedule for siteID');
  constructor(public siteID: number) { }
}

export class FetchLoadsheddingScheduleForSiteIDSuccess {
  static readonly type = Utils.Helpers.Type('[Loadshedding: Fetch] Fetch loadshedding schedule for siteID success');
  constructor(public payload: LoadsheddingSchedulePerSite) { }
}

export class FetchLoadsheddingScheduleForSiteIDFail {
  static readonly type = Utils.Helpers.Type('[Loadshedding: Fetch] Fetch loadshedding schedule for siteID fail');
  constructor(public error: any) { }
}

export class SetLoadsheddingScheduleForSiteIDError {
  static readonly type = Utils.Helpers.Type('[Loadshedding: Set] Set loadshedding schedule for siteID error');
  constructor(public errorMessage: string) { }
}

export class RefreshLoadsheddingForSiteID {
  static readonly type = Utils.Helpers.Type('[Loadshedding: Refresh] Refresh loadshedding schedule for siteID');
}

export class ClearLoadsheddingScheduleForSiteID {
  static readonly type = Utils.Helpers.Type('[Loadshedding: Clear] Clear loadshedding schedule for siteID');
}
