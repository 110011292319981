import { Utils } from "src/app/Utils";
import { WhatsappMessage } from "../../types/whatsapp-message.interface";
import { WhatsappSendMessageRequiredPayload } from "../../types/whatsapp-send-message-payload.interface";



export class Init {
    static readonly type = Utils.Helpers.Type("[Whatsapp: Fetch] Init Fetch messages");
}

export class Fetch {
    static readonly type = Utils.Helpers.Type("[Whatsapp: Fetch] Fetch messages");
}

export class FetchSuccess {
    static readonly type = Utils.Helpers.Type("[Whatsapp: Fetch] Fetch messages success");
    constructor(public payload: WhatsappMessage[]) { }
}

export class FetchFail {
    static readonly type = Utils.Helpers.Type("[Whatsapp: Fetch] Fetch messages fail");
    constructor(public error: any) { }
}

export class FetchNextPage {
    static readonly type = Utils.Helpers.Type("[Whatsapp: Fetch] Fetch next page");

}

export class FetchNextPageSuccess {
    static readonly type = Utils.Helpers.Type("[Whatsapp: Fetch] Fetch next page success");
    constructor(public payload: WhatsappMessage[]) { }
}

export class FetchNextPageFail {
    static readonly type = Utils.Helpers.Type("[Whatsapp: Fetch] Fetch next page fail");
    constructor(public error: any) { }
}

export class Clear {
    static readonly type = Utils.Helpers.Type("[Whatsapp: Fetch] Clear");
}

export class Send {
    static readonly type = Utils.Helpers.Type("[Whatsapp: Send] Send message");
    constructor(public payload: WhatsappSendMessageRequiredPayload) { }
}

export class SendSuccess {
    static readonly type = Utils.Helpers.Type("[Whatsapp: Send] Send message success");
    constructor(public payload: any) { }
}

export class SendFail {
    static readonly type = Utils.Helpers.Type("[Whatsapp: Send] Send message fail");
    constructor(public error: any) { }
}


export class ReceivedNewMessage {
    static readonly type = Utils.Helpers.Type("[Whatsapp: Fetch] Received new message");
    constructor(public message: WhatsappMessage) { }
}

export class ReceivedUpdatedMessage {
    static readonly type = Utils.Helpers.Type("[Whatsapp: Fetch] Received updated message");
    constructor(public message: WhatsappMessage) { }
}

export class PatchData {
    static readonly type = Utils.Helpers.Type("[Whatsapp: Fetch] Patch current data with freshly loaded data");
    constructor(public options: { page: number, pageSize: number } = { page: 0, pageSize: 20 }) { }
}

export class PatchDataSuccess {
    static readonly type = Utils.Helpers.Type("[Whatsapp: Fetch] Patch data success");
    constructor(public payload: WhatsappMessage[]) { }
}

export class PatchDataFail {
    static readonly type = Utils.Helpers.Type("[Whatsapp: Fetch] Patch data fail");
    constructor(public error: any) { }
}