import { Pipe, PipeTransform } from '@angular/core';
import { DataHandler } from '../../data-handler/data-handler';

@Pipe({ name: 'numberPlacementSuffix' })
export class NumberPlacementPipe implements PipeTransform {

  transform(value: number | string) {
    return DataHandler.getNumberPlacementSuffix(value);
  }

}
