import { Component } from '@angular/core';

@Component({
  selector: 'sf-warning-modals',
  templateUrl: './warning-modals.component.html',
  styleUrls: ['./warning-modals.component.scss']
})
export class WarningModalsComponent {


}
