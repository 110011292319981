import { AnyFunc } from "./assets/any-func.type";
import { LastFnReturnType, PipeArgs } from "./assets/pipe.types";



/**
 * 
 * @param arg The first value is the argument to be operated on.
 * @param firstFn The first function.
 * @param fns The rest of the functions.
 * @returns The result of calling all the function in order on the first argument.
 */
export const pipe = <FirstFn extends AnyFunc, F extends AnyFunc[]>(
    arg: Parameters<FirstFn>[0],
    firstFn: FirstFn,
    ...fns: PipeArgs<F> extends F ? F : PipeArgs<F>
): LastFnReturnType<F, ReturnType<FirstFn>> => {
    return (fns as AnyFunc[]).reduce((acc, fn) => fn(acc), firstFn(arg));
}
