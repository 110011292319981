import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MultiSelectOption } from '../assets/multi-select-option.model';

@Component({
  selector: 'sf-select-span',
  templateUrl: './select-span.component.html',
  styleUrls: ['./select-span.component.scss', "../assets/multi-select.scss"]
})
export class SelectSpanComponent {
  @Output() successToggled = new EventEmitter<boolean>();

  @Input() multiSelectOption: MultiSelectOption;


  onToggleSuccess() {
    this.multiSelectOption.toggleIsSuccessful();
    this.successToggled.next(this.multiSelectOption.successful);
  }

}
