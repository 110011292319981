import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';
import { DeviceInfoResponse } from 'src/app/customer-data-components/sim-details/store/interfaces/device-info.interface';


@Injectable({
  providedIn: 'root'
})
export class DeviceService {

  constructor(private http: HttpClient,
    private authService: AuthService) { }

  fetchDeviceInformation(msisdn: string) {
    const url = `https://prod-bss-cf.rain.co.za/v1/tps/devices/phone/${msisdn}`;

    const httpOptions = {
      headers: this.authService.setRainAuthHeaders()
    };
    return this.http.get<DeviceInfoResponse>(url, httpOptions);
  }


}
