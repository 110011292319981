import { Pipe, PipeTransform } from "@angular/core";
import { ValidationErrors } from "@angular/forms";

export const validationMessages: ValidationErrors = {
  required: 'This field is required',
  min: 'The amount entered is less than the required amount.',
  max: 'The amount entered is more than the required amount.',
  minlength: 'Too Short',
  maxlength: 'Too Long',
  pattern: 'Forbidden Entry',
  email: 'Invalid Entry',
  date: 'Invalid Date',
  notSelected: 'Atleast one option needs to be selected',
  inValid: "You've entered an invalid entry",
  notAlphaNumeric: 'There needs to be atleast 1 number',
  tooYoung: "Too young",
  idDateValidation: 'ID number date is invalid.',
  idValidation: 'ID number is invalid.',
  specialChars: 'Special characters not allowed',
  phoneNumberValidation: 'Phone number is invalid'
};

@Pipe({
  name: 'errorKeys'
})
export class ErrorKeysPipe implements PipeTransform {
  transform(errors: ValidationErrors): string[] {
    if (!errors) {
      return;
    }
    return Object.keys(errors);
  }
}
