import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CustomRouterEventHandler } from './custom-router-event-handler.interface';

@Injectable({
  providedIn: 'root'
})
export class RouterEventControllerService {

  private _routerEventHandlers: CustomRouterEventHandler[] = [];
  private _currentUrl: string;
  private _previousUrl: string;

  constructor(private router: Router) {
    this._currentUrl = this.router.url;
  }

  addCustomEventHandler(customRouterEventHandler: CustomRouterEventHandler) {
    this._routerEventHandlers.push(customRouterEventHandler);
  }


  handleRouteChange(router: Router, nextUrl: string) {
    this._previousUrl = this._currentUrl;
    this._currentUrl = nextUrl;
    this._routerEventHandlers.forEach(handler => {
      handler.handleRouteChange(router,
        {
          previousUrl: this._previousUrl,
          currentUrl: this._currentUrl,
        });
    })
  }

  reloadPage() {
    const currentUrl = this.router.url;
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate([currentUrl]);
  }


}
