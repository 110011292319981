<div class="notification" *ngIf="notification" [ngClass]="{
    'is-active': isActive,
    'is-success': notification.type === 0,
    'is-warning': notification.type === 1,
    'is-persistant-warning': notification.type === 2
  }">

    <div class="column-left">
        <div [ngSwitch]="notification.type">
            <sf-icon *ngSwitchCase="0" name="checkmark1.svg"></sf-icon>
            <sf-icon *ngSwitchCase="1" name="warning1.svg"></sf-icon>
            <sf-icon *ngSwitchCase="2" name="error.svg"></sf-icon>
            <sf-icon *ngSwitchCase="3" name="info.svg"></sf-icon>
        </div>
    </div>
    <div class="body">
        <h5>{{notification.title}}</h5>
        <p>{{notification.message}}</p>
    </div>
    <div class="column-right">
        <div class="icon-lockup">
            <sf-icon name="clear" (click)="closeNotification()"></sf-icon>
        </div>
    </div>
</div>
