import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Actions, Store, ofActionCompleted, ofActionSuccessful } from '@ngxs/store';
import { BehaviorSubject, Subject, combineLatest } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap, takeUntil } from 'rxjs/operators';
import { GPSCoordinates } from 'src/app/customer-data-components/sim-details/sim-card-page/sub-components/coverage/store/interfaces/gps-coordinates.interface';
import { CurrentDeviceState } from 'src/app/customer-data-components/sim-details/sim-card-page/sub-components/coverage/store/state/current-device.state';
import { RefreshMaps } from 'src/app/customer-info-summary-page/store/actions/events.actions';
import { NewMapService } from '../../services/google-maps/new-map.service';
import { SFValidators } from '../../functions/sf-validators';
import { CoverageDataState } from 'src/app/customer-data-components/sim-details/sim-card-page/sub-components/coverage/store/state/coverage-data.state';
import { MapActions } from '../../elements/map/store/actions/map-actions';

//TODO: fix this component and the address search in general
@Component({
    selector: 'sf-snowflake-maps',
    templateUrl: './snowflake-maps.component.html',
    styleUrls: ['./snowflake-maps.component.scss']
})
export class SnowflakeMapsComponent implements OnInit, OnDestroy {

    @Input() set addressID(value: string) {
        this._currentAddressId$.next(value);

        if (value && !this._initialAddressId$.getValue()) {
            this._initialAddressId$.next(value);
        }
    }

    @Input() canSkipAddressCheck = false;
    @Input() topCenterFullscreenControlId = '';

    private _addressMarker: google.maps.Marker | null = null;

    private readonly _initialAddressId$ = new BehaviorSubject<string | null>(null);

    private readonly _currentAddressId$ = new BehaviorSubject<string | null>(null);

    private readonly _addressCoordinates$ = new BehaviorSubject<GPSCoordinates | null>(null);

    private readonly _addressLoaded$ = new BehaviorSubject<boolean>(false);

    readonly loaded$ = combineLatest([
        this._addressLoaded$,
        this.store.select(CoverageDataState.isDeviceInitialized)
    ])
        .pipe(
            map(items => items.every(Boolean))
        );

    readonly coordinates$ = new BehaviorSubject<GPSCoordinates | null>(null)

    private readonly destroy$ = new Subject();

    constructor(
        private store: Store,
        private actions$: Actions,
        private mapService: NewMapService
    ) { }

    ngOnInit(): void {
        this.setupAddressMarkerListener();

        this._currentAddressId$
            .pipe(
                filter(SFValidators.isDefined),
                distinctUntilChanged(),
                takeUntil(this.destroy$)
            )
            .subscribe({
                next: placeId => this.getAddress(placeId)
            });

        this.actions$
            .pipe(
                ofActionSuccessful(RefreshMaps),
                takeUntil(this.destroy$)
            )
            .subscribe({
                next: () => {
                    this._currentAddressId$.next(this._initialAddressId$.getValue());
                }
            });
    }

    private setupAddressMarkerListener() {

        this.store.select(CoverageDataState.isDeviceInitialized)
            .pipe(
                filter(Boolean),
                switchMap(() => combineLatest([
                    this._addressCoordinates$,
                    this.store.select(CurrentDeviceState.getDeviceCoordinates),
                    this.actions$.pipe(
                        ofActionCompleted(MapActions.MapLoaded)
                    )
                ])),
                map(([
                    addressCoordinates,
                    deviceCoordinates
                ]) => {
                    return {
                        addressCoordinates,
                        deviceCoordinates
                    }
                }),
                takeUntil(this.destroy$)
            )
            .subscribe({
                next: data => {
                    const { addressCoordinates, deviceCoordinates } = data ?? {};

                    if (deviceCoordinates) {
                        this.updateMarker("DEVICE", deviceCoordinates);
                        this.coordinates$.next(deviceCoordinates);
                    }
                    else {
                        this.updateMarker("ADDRESS", addressCoordinates);
                        this.coordinates$.next(addressCoordinates);
                    }

                }
            })
    }

    private async updateMarker(type: "ADDRESS" | "DEVICE", coordinates: GPSCoordinates) {

        const gMap = await this.mapService.getMap();

        if (this._addressMarker) {
            this._addressMarker?.setMap(null);
            this._addressMarker = null;
        }

        this._addressMarker = new google.maps.Marker({
            icon: type === "DEVICE"
                ? {
                    url: "assets/icons/device-map-icon.svg",
                    anchor: new google.maps.Point(18, 72)
                }
                : null,
            map: gMap,
            position: coordinates
        });
    }

    getAddress(placeId: string) {
        const divEl = document.getElementById('dummy') as HTMLDivElement;
        const placesService = new google.maps.places.PlacesService(divEl);

        placesService.getDetails({ placeId }, (result) => {

            this._addressCoordinates$.next({
                lat: result?.geometry?.location?.lat(),
                lng: result?.geometry?.location?.lng()
            })

            this._addressLoaded$.next(true);
        });

    }

    ngOnDestroy(): void {
        this.destroy$.next(null);
        this.destroy$.complete();
    }


}
