import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { ApmService } from "@elastic/apm-rum-angular";
import { ACCOUNT_DETAILS_ROUTE, ADMIN_ROUTE, AGENT_VIEW_ROUTE, CUSTOMER_INFO_SUMMARY_ROUTE, HOME_ROUTE, SEARCH_ROUTE, SIM_DETAILS_ROUTE, TICKETS_ROUTE } from "src/app/constants";
import { stringIncludesArrayItem } from "src/app/shared/functions/includes-array-item/string-includes-array-item.function";
import { environment } from "src/environments/environment";


const FACE_VALUE_ROUTES = [
    HOME_ROUTE,
    AGENT_VIEW_ROUTE,
    SEARCH_ROUTE,
    ADMIN_ROUTE
];

const NON_WILDCARD_SEGMENTS = [
    CUSTOMER_INFO_SUMMARY_ROUTE,
    TICKETS_ROUTE,
    SIM_DETAILS_ROUTE,
    ACCOUNT_DETAILS_ROUTE
];


@Injectable({
    providedIn: 'root'
})
export class APMWrapperService {

    private _initialized = false;

    constructor(private apmService: ApmService,
        private router: Router) {
    }

    initializeAPMLogger(email: string) {
        if (this._initialized) {
            return;
        }
        this._initialized = true;

        const apm = this.apmService
            .init({
                serviceName: 'Snowflake-FE',
                serverUrl: environment.apm_endpoint,
                environment: environment.production ? "PROD" : "SIT",
                pageLoadTransactionName: this.getFormattedRoute()
            })

        apm.setUserContext({
            id: `${email}_${Date.now()}`,
            email
        });
    }

    private getFormattedRoute(): string {
        const url = this.router.url;
        if (stringIncludesArrayItem(url, FACE_VALUE_ROUTES)) {
            return url;
        }

        return this.mapWildCardUrl(url, NON_WILDCARD_SEGMENTS);
    }

    private mapWildCardUrl(url: string, nonWildcardParts: string[]) {
        const routes = [...nonWildcardParts, ""];
        return url
            .split("/")
            .map(segment => routes.includes(segment) ? segment : ":id")
            .join("/");
    }

}