import { Selector } from "@ngxs/store";
import { SimTechServiceSelectors } from "src/app/customer-data-components/sim-details/store/selectors/sim-techtype-product.selector";
import { CustomerInfoState } from "src/app/customer-info-summary-page/store/state/customer-info.state";
import { CustomerService } from "src/app/shared/interfaces/smartsub-data.interface";

export interface ViewModelRetention4G {
    servicesSfOptions:CustomerService[];
    customerEmail:string;
}

export class GetRetention4GSelectors {

    @Selector([
        SimTechServiceSelectors.sfOptionsFromServicesByTechType("4G"),
        CustomerInfoState.getCustomerEmail
    ])
    static getViewModel(
        servicesSfOptions:CustomerService[],
        customerEmail:string
    ): ViewModelRetention4G {
        return {
            servicesSfOptions,
            customerEmail
        }
    }
}