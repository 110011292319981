
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'toJson' })
export class ToJsonPipe implements PipeTransform {

  transform(value: string) {

    try {
      return JSON.parse(value);
    } catch (e) {
      return value;
    }

  }

}
