import { NgModule } from "@angular/core";
import { AgentControlComponent } from './agent-control.component';


@NgModule({
    declarations: [
        AgentControlComponent
    ],
    exports: [
        AgentControlComponent
    ],
})
export class AgentControlModule {

}
