import { PaymentLinkState } from "./payment-link.state";
import { SalesBillingCycleState } from "./sales-billing-cycle.state";
import { CreateCustomerState } from "./create-customer.state";
import { SalesSettingsState } from "./sales-settings.state";
import { SalesOrdersState } from "./orders.state";


export const SHARED_SALES_STATES = [
    CreateCustomerState,
    SalesSettingsState,
    PaymentLinkState,
    SalesBillingCycleState,
    SalesOrdersState,
];