import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

/**
 * CONFIG_URL @param
 */
const CONFIG_URL = './config.json';
/**
 *
 *
 * @export
 * @class ConfigService
 */
@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  CLIPIN_URL: string;
  CHIPIN_API_KEY: string;
  ICCID_URL: string;
  ICCID_KEY: string;
  TOGGLE_URL: string;
  TOGGLE_API_KEY: string;
  API_URL: string;
  API_KEY: string;
  RAIN_MAKER_API_URL: string;
  CHECK_ORDER_ADDRESS_URL: string;
  RAIN_MAKER_API_KEY: string;
  RAIN_MAKER_RICA_URL: string;
  RAIN_POS_API_URL: string;
  NOTIFY_URL: string;
  NOTIFY_COVERAGE_CHECK: string;
  CUSTOMER_CONSENT: string;
  RAIN_INBOX: string;
  TPS_URL: string;
  KEY: string;
  MAP_API_KEY: string;
  PRODUCT_API_URL: string;
  DEVOPS_URL: string;
  PEACH_URL: string;
  CACHE_EXPIRY: number;
  IDLE_CACHE_EXPIRY: number;
  API_TIMEOUT: number;
  UOP_ADDON_ID: string;
  SEVEN_DAY_ADDON_ID: string;
  CART_EXPIRY: number;
  GOT_SIM_ID: string;
  GOT_SIM_PHONE_ONLY_ID: string;
  GOT_SIM_UOP_ID: string;
  NEW_GOT_SIM_ID: string;
  ESIM_ID: string;
  DELIVERY_COST: number;
  OPTIMISE_URL: string;
  BETA_API_URL: string;
  ESIM_API_URL: string;
  E_RICA_URL: string;
  PAYMENT_GATEWAY_URL: string;
  FIVEG_PREMIUM_ID: string;
  FIVEG_STANDARD_ID: string;
  MESSAGE_API_URL: string;
  SUPPORT_QUESTION_API_URL: string;
  TOP_10_SUPPORT_QUESTION_API_URL: string;
  SUPPORT_TICKET_API_URL: string;
  PLACES_API_KEY: string;
  SUPPORT_GROUP_ITEM_API_URL: string;
  NETWORK_SUPPORT_API_URL: string;
  RAIN_NETWORK_CHECK_API: string;
  IDM_URL: string;
  SITE_KEY: string;
  CPT_SWITCH: boolean;
  PAY_LATER_FROM: number;
  PAY_LATER_TO: number;
  WEATHER_API_KEY: string;
  WEATHER_URL: string;
  RAINMAKER_STARTER_ID: string;
  SURVEY_URL: string;
  REDIRECT_KEY: string;
  REDIRECT_INBOX_URL: string;
  REDIRECT_EMAIL_URL: string;
  REDIRECT_SMS_URL: string;
  REDIRECT_PUSH_URL: string;
  REDIRECT_WHATSAPP_URL: string;


  public onChange: Subject<void> = new Subject<void>();

  constructor(private http: HttpClient) { }

  public load() {
    const httpOptions = {
      params: {}
    };

    return this.http
      .get(CONFIG_URL, httpOptions)
      .toPromise()
      .then((x: any) => {
        this.populateConfig(x);

        this.onChange.next();
      })
      .catch((err: any) => { console.error(err) });
  }

  private populateConfig(x: any) {
    this.CLIPIN_URL = x.CLIPIN_URL;
    this.CHIPIN_API_KEY = x.CHIPIN_API_KEY;
    this.ICCID_URL = x.ICCID_URL;
    this.ICCID_KEY = x.ICCID_KEY;
    this.TOGGLE_URL = x.TOGGLE_URL;
    this.TOGGLE_API_KEY = x.TOGGLE_API_KEY;
    this.API_KEY = x.API_KEY;
    this.API_TIMEOUT = x.API_TIMEOUT;
    this.API_URL = x.API_URL;
    this.RAIN_MAKER_API_URL = x.RAIN_MAKER_API_URL;
    this.CHECK_ORDER_ADDRESS_URL = x.CHECK_ORDER_ADDRESS_URL;
    this.RAIN_MAKER_API_KEY = x.RAIN_MAKER_API_KEY;
    this.RAIN_MAKER_RICA_URL = x.RAIN_MAKER_RICA_URL;
    this.RAIN_POS_API_URL = x.RAIN_POS_API_URL;
    this.NOTIFY_URL = x.NOTIFY_URL;
    this.NOTIFY_COVERAGE_CHECK = x.NOTIFY_COVERAGE_CHECK;
    this.TPS_URL = x.TPS_URL;
    this.CACHE_EXPIRY = x.CACHE_EXPIRY;
    this.DEVOPS_URL = x.DEVOPS_URL;
    this.KEY = x.KEY;
    this.CUSTOMER_CONSENT = x.CUSTOMER_CONSENT;
    this.RAIN_INBOX = x.RAIN_INBOX;
    this.MAP_API_KEY = x.MAP_API_KEY;
    this.PEACH_URL = x.PEACH_URL;
    this.PRODUCT_API_URL = x.PRODUCT_API_URL;
    this.IDLE_CACHE_EXPIRY = x.IDLE_CACHE_EXPIRY;
    this.UOP_ADDON_ID = x.UOP_ADDON_ID;
    this.CART_EXPIRY = x.CART_EXPIRY;
    this.GOT_SIM_ID = x.GOT_SIM_ID;
    this.GOT_SIM_PHONE_ONLY_ID = x.GOT_SIM_PHONE_ONLY_ID;
    this.GOT_SIM_UOP_ID = x.GOT_SIM_UOP_ID;
    this.DELIVERY_COST = x.DELIVERY_COST;
    this.OPTIMISE_URL = x.OPTIMISE_URL;
    this.BETA_API_URL = x.BETA_API_URL;
    this.ESIM_API_URL = x.ESIM_API_URL;
    this.E_RICA_URL = x.E_RICA_URL;
    this.PAYMENT_GATEWAY_URL = x.PAYMENT_GATEWAY_URL;
    this.FIVEG_PREMIUM_ID = x.FIVEG_PREMIUM_ID;
    this.FIVEG_STANDARD_ID = x.FIVEG_STANDARD_ID;
    this.SEVEN_DAY_ADDON_ID = x.SEVEN_DAY_ADDON_ID;
    this.NEW_GOT_SIM_ID = x.NEW_GOT_SIM_ID;
    this.MESSAGE_API_URL = x.MESSAGE_API_URL;
    this.SUPPORT_QUESTION_API_URL = x.SUPPORT_QUESTION_API_URL;
    this.TOP_10_SUPPORT_QUESTION_API_URL = x.TOP_10_SUPPORT_QUESTION_API_URL;
    this.NETWORK_SUPPORT_API_URL = x.NETWORK_SUPPORT_API_URL;
    this.SUPPORT_GROUP_ITEM_API_URL = x.SUPPORT_GROUP_ITEM_API_URL;
    this.SUPPORT_TICKET_API_URL = x.SUPPORT_TICKET_API_URL;
    this.PLACES_API_KEY = x.PLACES_API_KEY;
    this.IDM_URL = x.IDM_URL;
    this.RAIN_NETWORK_CHECK_API = x.RAIN_NETWORK_CHECK_API;
    this.SITE_KEY = x.SITE_KEY;
    this.CPT_SWITCH = x.CPT_SWITCH || false;
    this.PAY_LATER_FROM = x.PAY_LATER_FROM;
    this.PAY_LATER_TO = x.PAY_LATER_TO;
    this.WEATHER_API_KEY = x.WEATHER_API_KEY;
    this.WEATHER_URL = x.WEATHER_URL;
    this.RAINMAKER_STARTER_ID = x.RAINMAKER_STARTER_ID;
    this.SURVEY_URL = x.SURVEY_URL;
    this.REDIRECT_KEY = x.REDIRECT_KEY;
    this.REDIRECT_INBOX_URL = x.REDIRECT_INBOX_URL;
    this.REDIRECT_EMAIL_URL = x.REDIRECT_EMAIL_URL;
    this.REDIRECT_SMS_URL = x.REDIRECT_SMS_URL;
    this.REDIRECT_PUSH_URL = x.REDIRECT_PUSH_URL;
    this.REDIRECT_WHATSAPP_URL = x.REDIRECT_WHATSAPP_URL;
  }
}

/**
 *
 * @param initConfig
 */
export function initConfig(configService: ConfigService) {
  return () => configService.load();
}
