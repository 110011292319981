import { CustomerTicket } from "src/app/shared/customer-ticket/interfaces/customer-ticket.interface";
import { Utils } from "src/app/Utils";
import { VoiceCampaignInfo } from "../../../assets/campaign-info.interface";


export class SetupTicketFlow {
    static readonly type = Utils.Helpers.Type('[VoiceCampaign: Setup] setup campaign ticket flow');
    constructor(public payload: VoiceCampaignInfo) { }
}

export class TicketIdMatches {
    static readonly type = Utils.Helpers.Type('[VoiceCampaign: TicketIdMatch] ticket ID matches');
    constructor(public ticket: CustomerTicket, public customerNumber: string) { }
}

export class NoMatchingTicketId {
    static readonly type = Utils.Helpers.Type('[VoiceCampaign: NoID] no matching ticket ID');
    constructor(public payload: VoiceCampaignInfo) { }
}

export class CampaignCallEnded {
    static readonly type = Utils.Helpers.Type('[VoiceCampaign: CallEnded] Campaign call ended');
}

