import { AlarmSiteFeatureCollection } from "src/app/shared/services/google-maps/assets/map-site.interfaces";
import { Utils } from "src/app/Utils";


export class Initialize {
    static readonly type = Utils.Helpers.Type("[AlarmSites: Initialize]");
}

export class Fetch {
    static readonly type = Utils.Helpers.Type("[AlarmSites: Fetch]");
}

export class FetchSuccess {
    static readonly type = Utils.Helpers.Type("[AlarmSites: Fetch] Success");
    constructor(public payload: AlarmSiteFeatureCollection) { }
}

export class FetchFail {
    static readonly type = Utils.Helpers.Type("[AlarmSites: Fetch] Fail");
    constructor(public error: any) { }
}

export class Refresh {
    static readonly type = Utils.Helpers.Type("[AlarmSites: Refresh]");
}

export class Clear {
    static readonly type = Utils.Helpers.Type("[AlarmSites: Clear]");
}
