import { Utils } from "src/app/Utils";


export class ToggleJsonNoteExpanded {
    static readonly type = Utils.Helpers.Type('[Interactions: Note] Toggle if the json note is expanded');
    constructor(public hex_id: string) { }
}


export class ChatNoteInteractionsDestroyed {
    static readonly type = Utils.Helpers.Type('[Interactions: Note] ChatInteractionsDestroyed');
}

export class UpdateViewers {
    static readonly type = Utils.Helpers.Type('[Interactions: Note] Update viewers');
    constructor(public emails: string[]) { }
}







