import { createSFselectOptions } from "src/app/shared/functions/create-sf-select-options.function";
import { MultiSelectConfig } from "./multi-select-config.interface";
import { MultiSelect } from "./multi-select.model";


export class MultiSelectMap {
  [x: string]: MultiSelect;

  constructor(optionMap: { [x: string]: string[] }, config?: MultiSelectConfig) {
    for (const key in optionMap) {
      this[key] = new MultiSelect(createSFselectOptions(optionMap[key]), config);
    }
  }

  static isAnyOptionSelected(multiMap: MultiSelectMap) {
    const values = Object.values(multiMap);
    for (const value of values) {
      if (value.isValid) {
        return true;
      }
    }

    return false;
  }
}
