import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { CallTimerActions } from '../actions/call-timer-actions';
import { Subject, timer } from 'rxjs';
import { ONE_SECOND_IN_MILI } from 'src/app/constants';
import { take, takeUntil } from 'rxjs/operators';

function formatNumber(input: number | string) {
    const stringInput = input.toString();
    return (stringInput.length === 1) ? "0" + input : input;
}


interface CallTimerStateModel {
    durationInSeconds: number;
    lastCallDuration: number;
    lastCallEndTime: number;
}
@State<CallTimerStateModel>({
    name: 'sf_CallTimer_state',
    defaults: {
        durationInSeconds: 0,
        lastCallDuration: 0,
        lastCallEndTime: null
    }
})
@Injectable()
export class CallTimerState {

    @Selector()
    static getCurrentCallDurationInSeconds(state: CallTimerStateModel) { return state.durationInSeconds }

    @Selector()
    static getLastCallDurationInSeconds(state: CallTimerStateModel) { return state.lastCallDuration }

    @Selector()
    static getFormattedTime(state: CallTimerStateModel) {
        const { durationInSeconds: timeInSeconds } = state ?? {};
        return formatNumber(Math.floor(timeInSeconds / 60)) + ":" + formatNumber(timeInSeconds % 60 ? timeInSeconds % 60 : '00');
    }

    private readonly _stop$ = new Subject<void>();


    @Action(CallTimerActions.StartTimer)
    StartTimer(ctx: StateContext<CallTimerStateModel>) {

        //Don't let NGXS handle this sub
        timer(ONE_SECOND_IN_MILI, ONE_SECOND_IN_MILI)
            .pipe(
                takeUntil(this._stop$.pipe(take(1)))
            )
            .subscribe({
                next: () => {
                    ctx.patchState({
                        durationInSeconds: ctx.getState().durationInSeconds + 1
                    })
                }
            });
    }

    @Action(CallTimerActions.StopTimer)
    StopTimer(ctx: StateContext<CallTimerStateModel>) {
        const currentDuration = ctx.getState().durationInSeconds;

        this._stop$.next(null);

        ctx.patchState({
            durationInSeconds: 0,
            lastCallDuration: currentDuration,
            lastCallEndTime: Date.now()
        });
    }

}