import { createSFselectOptions } from "src/app/shared/functions/create-sf-select-options.function";


export const CANCELLED_IMMEDIATELY = "Cancelled Immediately";
export const ANSWERED = "answered";

export const EARLY_OPT_SELECT_OPTIONS = {
    callOutcome: createSFselectOptions([
        "answered",
        "no answer",
        "call back"
    ]),
    knownCustomerIssue: createSFselectOptions([
        "yes",
        "no"
    ]),
    hadTicketOpen: createSFselectOptions([
        "yes",
        "no"
    ]),
    causeOfLowSignal: createSFselectOptions([
        "did not optimise",
        "line of sight obstruction",
        "extreme elevation",
        "poor coverage"
    ]),
    solutionsProvided: createSFselectOptions([
        "Optimisation with current device",
        "dasher with ODU setup",
        "ODU delivery - outside dasher coverage"
    ]),
    outcome: createSFselectOptions([
        "resolved",
        "Pending customer feedback",
        "Follow up call to be done after ODU is setup",
        CANCELLED_IMMEDIATELY
    ]),

    additionalAssistanceOffered: createSFselectOptions([
        "service migration",
        "downgrade to 4G ",
        "credit",
        "refund",
        "sim swap",
        "none"
    ])
} as const;


