import { Dictionary } from "src/app/shared/interfaces/dictionary.interface";
import { StringKey } from "src/app/shared/types/string-of.type";
import { findError } from "../helpers";
import { SFValidators } from "src/app/shared/functions/sf-validators";

/**
 *
 * @param objects
 * @param key
 */
export function ToHashMap<T, KeyType extends string | number = string>(obj: T[], state: any, key: keyof T): { [key in KeyType]: T } {
  return obj.reduce(
    (entities: { [id: string]: any }, item: any) => {
      let param = item[key];
      if (Array.isArray(item[key])) {
        param = item[key][0];
      }
      return {
        ...entities,
        [param]: item,
      };
    },
    { ...state.entities }
  );

}

export const toHashMapV2 = <T, TKey extends string | number = string, TState extends { [key in TKey]: T } = { [key in TKey]: T }>
  (list: T[], key: keyof T, initialState: TState = <TState>{}): TState =>
  (list ?? []).reduce(
    (state: TState, listItem: T) => {

      const id = listItem?.[key];
      if (SFValidators.isDefined(id)) {
        (<any>state)[id] = listItem;
      }

      return state;
    },
    { ...initialState }
  );

/**
 *
 *
 * @export
 * @param {any[]} obj
 * @param {*} state
 * @param {string} key
 * @returns
 */
export function ToSortedHashMap<T>(obj: any[], state: any, key: string): { [key: string]: T } {
  return obj.reduce((r: any, a: any) => {
    if (a !== null) {
      r[a[key]] = r[a[key]] || [];
      r[a[key]] = a;
    }
    return r;
  }, Object.create(null));
}


/**
 *
 *
 * @export
 * @param {*} entities
 * @returns {any[]}
 */
export function FromHashMap<T>(entities): T[] {

  if (entities) {
    return Object.keys(entities).map(key => {
      if (key) return entities[key];
    });
  }
}

/**
 *
 * @export
 * @template T
 * @param {{[lang: string]: T[]}} dataSet
 * @returns {T[]}
 */
export function FlattenHashMapArrays<T>(dataSet: { [lang: string]: T[] }): T[] {
  const entities = FromHashMap<T[]>(dataSet);
  const flatArr = [];

  // joining arrays into 1
  entities.forEach(a => flatArr.push(...a));
  return flatArr;
}


/**
 *
 * @param list The list to convert
 * @param startingMap The object to add the values to. Defaults to empty {}
 */
export function ToHashMapOfArrays<T>(list: T[], key: StringKey<T>, startingMap: { [key: string]: T[] } = {}
): { [key: string]: T[] } {

  list.forEach(item => {
    const id = item[key] as unknown as string;
    if (!id) {
      //Skip if id is null
      return;
    }

    if (!(id in startingMap)) {
      startingMap[id] = [item];
    }
    else {
      startingMap[id].push(item);
    }
  });

  return startingMap;
}

export const patch = <T>(initialValue: T, newValue: Partial<T>) => {
  const newState = {
    ...initialValue
  };
  for (const key in newValue) {
    newState[key] = (newValue as T)[key];
  }
  return newState;
}


export const mapOptions = <T extends Dictionary>(defaultOptions: T, inputOptions?: Partial<T>): T =>
  inputOptions ? patch(defaultOptions, inputOptions) : defaultOptions;


export const arrayBufferToBase64 = (buffer: ArrayBuffer) => {
  try {
    const binary = new Uint8Array(buffer)
      .reduce(
        (binaryStr, byte) => binaryStr + String.fromCharCode(byte),
        ""
      );

    return btoa(binary);
  }
  catch (e) {
    throw new Error(
      `Failed to convert arraybuffer to base64 string. ${findError(e, "")}`
    );
  }
}


//TODO: make this strongly typed
export const remapKeys = <T extends Record<string, any>>
  (originalObj: T, mappedKeys: { [key in T[string]]: string }) => {

  const newObj: { [key: string]: any } = {};
  Object.entries(mappedKeys)
    .forEach(([oldKey, newKey]) => newObj[newKey as string] = originalObj?.[oldKey]);

  return newObj;

}