import { webSocket, WebSocketSubject } from 'rxjs/webSocket';
import { Observable } from 'rxjs';

export class CustomWebSocket {
  connection: WebSocketSubject<any>;

  connect(url: string): Observable<any> {
    this.connection = webSocket({
      url: url
    });
    return this.connection;
  }

  send(data: any): void {
    if (this.connection) {
      this.connection.next(data);
    } else {
      console.error("Failed to send websocket message. No active connection.");
    }
  }

  closeConnection(): void {
    if (this.connection) {
      this.connection.complete();
      this.connection = null;
    }
  }

}
