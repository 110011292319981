import { Utils } from "src/app/Utils";


export class SetSelectedID {
  static readonly type = Utils.Helpers.Type("[SimActions] Set selected id");
  constructor(public id: string) { }
}

export class ClearSelectedID {
  static readonly type = Utils.Helpers.Type("[SimActions] Clear selected id");
}
