import { Component, OnDestroy, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SF_AUTH_ROLES } from 'src/app/auth/assets/auth-role.config';
import { BaseAgent } from 'src/app/core/interfaces/agent.interface';
import { CoreState } from 'src/app/core/store/state/core.state';
import { UpdateAgentRoles } from 'src/app/shared/components/agent/store/actions/agent.actions';


const ALLOWED_ROLE_CHANGERS = [
  "stefan.jaarsveld@rain.co.za",
  "quinton@rain.co.za",
  "abdul-malik.mohamed@rain.co.za",
  "seshni.thathiah@rain.co.za",
  "kerry.chapman@rain.co.za",
  "muhammad.makgotlho@rain.co.za",
  "lehlohonolo.dikweni@rain.co.za",
  "george.thulo@rain.co.za",
  "noluthando.biyase@rain.co.za",
  "luyanda.hlatshwayo@rain.co.za",
  "tebogo.mabuku@rain.co.za",
  "nadeem.johnson@rain.co.za"
];

interface RoleOption {
  role: string;
  hasRole: boolean;
}
@Component({
  selector: 'sf-auth-role-setter',
  templateUrl: './auth-role-setter.component.html',
  styleUrls: ['./auth-role-setter.component.scss']
})
export class AuthRoleSetterComponent implements OnInit, OnDestroy {
  @Select(CoreState.getAgent) agent$: Observable<BaseAgent>;

  //NOTE: This class is purely for making RBAC testing easier, this will only be available in SIT and also only to specific emails

  canChange = false;
  options: RoleOption[] = [];

  private readonly ngDestroy$ = new Subject<void>();

  constructor(private store: Store) { }

  ngOnInit(): void {
    this.agent$
      .pipe(
        takeUntil(this.ngDestroy$)
      )
      .subscribe(agent => this.initOptions(agent));
  }

  private async initOptions(baseAgent: BaseAgent) {
    this.setCanChange(baseAgent.email);
    this.setRoleOptions(baseAgent.roles);
  }

  private async setRoleOptions(agentRoles: string[]) {
    if (!agentRoles) {
      return;
    }

    this.options = Object.values(SF_AUTH_ROLES).map(role => {
      if (agentRoles.includes(role)) {
        return { role, hasRole: true }
      }
      else {
        return { role, hasRole: false }
      }
    })
  }

  private setCanChange(email: string) {
    this.canChange = ALLOWED_ROLE_CHANGERS.includes(email?.toLowerCase());
  }

  toggleRoleActive(index: number) {
    const roleOption = this.options[index];
    const hasRole = roleOption.hasRole;
    roleOption.hasRole = !hasRole;

    let roles = Object.assign([], this.store.selectSnapshot(CoreState.getAgentRoles));

    //Remove role if hasRole or else add it
    if (hasRole) {
      roles = roles?.filter(r => r !== roleOption.role);
    }
    else {
      roles.push(roleOption.role);
    }

    this.store.dispatch(new UpdateAgentRoles(roles));
  }

  ngOnDestroy(): void {
    this.ngDestroy$.next(null);
    this.ngDestroy$.complete();
  }


}
