import { Component } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { ToggleModalByName } from 'src/app/core/store/actions/modal.actions';
import { ModalState } from 'src/app/core/store/state/modal.state';

@Component({
  selector: 'sf-timeout-modal',
  templateUrl: './timeout-modal.component.html',
  styleUrls: ['./timeout-modal.component.scss']
})
export class TimeoutModalComponent {

  @Select(ModalState.getModalData<string>("timeout_modal")) agentTimeOutMessage$: Observable<string>;

  constructor(private store: Store) { }

  closeModal() {
    this.store.dispatch(new ToggleModalByName('timeout_modal', false));
  }
}
