<div *ngIf="showModal" class="overlay">
  <div class="center-modal-wrapper shadow">

    <mat-icon class="default-cancel-icon" (click)="onCancel()">cancel</mat-icon>

    <h3 class="header text text-blue">{{modalOptions?.header}}</h3>

    <p class="content text text-dark-grey">{{modalOptions?.content}}</p>

    <p *ngIf="modalOptions?.error" class="error text red">{{"error: " + modalOptions?.error}}</p>


    <div class="button-div-wrapper">

      <div [ngSwitch]="modalOptions?.messageType">

        <div *ngSwitchCase="'confirm'" class="button-div">
          <button class="rain-button default-button-size" (click)="onResponse(false)">
            {{modalOptions?.declineMessage ?? "cancel"}}
          </button>

          <button class="rain-button default-button-size" (click)="onResponse(true)">
            {{modalOptions?.confirmMessage ?? "ok"}}
          </button>
        </div>

        <div *ngSwitchCase="'alert'" class="button-div">
          <button class="rain-button default-button-size" (click)="closeModal()">ok</button>
        </div>

        <div *ngSwitchCase="'action'" class="button-div">
          <sf-rain-button (click)="closeModal()">
            {{modalOptions?.declineMessage ?? "cancel"}}
          </sf-rain-button>

          <sf-rain-button (click)="onAction()">
            {{modalOptions?.confirmMessage ?? "ok"}}
          </sf-rain-button>
        </div>
      </div>

    </div>


  </div>
</div>

<div *ngIf="showLoadingSpinner" class="overlay">
  <app-custom-spinner></app-custom-spinner>
</div>