import { SnowflakeSelectOption } from "src/app/shared/elements/rain-forms/elements/snowflake-select/interfaces/rain-select-option.interface";


export const NETWORK_DEFAULT_POST_TICKET_OPTIONS: SnowflakeSelectOption[] = [
    { label: 'No Connection', value: 'No Connection' },
    { label: 'Intermittent Connectivity', value: 'Intermittent Connectivity' },
    { label: 'Slow Speed', value: 'Slow Speed' },
    { label: 'Voice Query', value: 'Voice Query' },
    { label: 'Other', value: 'Other' }

]

export const BILLING_DEFAULT_POST_TICKET_OPTIONS: SnowflakeSelectOption[] = [
  { label: 'Customer Education', value:'Customer Education'},
  { label: 'Processed Payment', value: 'Processed Payment' },
  { label: 'Account in Arrears', value: 'Account in Arrears' },

    { label: 'Master Policy Update', value:'Master Policy Update'},
    { label: 'Invalid escalation', value:'Invalid Escalation'},
    { label: 'Force activation', value:'Force activation'},
    { label:'RainOne level/speed up', value:'RainOne level/speed up'},
    { label:'Refund', value:'Refund'},
    { label: 'Credit/Refund', value: 'Credit/Refund' },
    { label:'Allocation', value:'Allocation'},
    { label:'Bill disputes', value:'Bill disputes'},
    { label:'Profile management', value:'Profile management'},
    { label:'Wallet Adjustment', value:'Wallet Adjustment'},
    { label: 'Other', value: 'Other' },

]
export const UNSUCCESSFUL_SALE_DEFAULT_POST_TICKET_OPTIONS: SnowflakeSelectOption[] = [
  { label: 'Looking for legacy product', value:'Looking for legacy product'},
  { label: 'No funds', value: 'No funds' },
  { label: 'Account in Arrears', value: 'Account in Arrears' },
  { label: 'No coverage', value:'No coverage'},
  { label: 'Product information only', value:'Product information only'},
  { label: 'Billing Query', value:'Billing Query'},
  { label:'Network Query', value:'Network Query'},
  { label:'Logistics/Delivery query', value:'Logistics/Delivery query'},
  { label: 'Voice query', value: 'Voice query' },
  { label:'Queued for delivery.', value:'Queued for delivery.'},
  { label:'Delivered already.', value:'Delivered already.'},
  { label:'Duplicate ticket', value:'Duplicate ticket'},
  { label:'Too expensive', value:'Too expensive'},
  { label: 'Cancellation', value: 'Cancellation' },
  { label:'No RICA document.', value:'No RICA document.'},
  { label:'Online banking error', value:'Online banking error'},
  { label:'Awaiting payment', value:'Awaiting payment'},
  { label:'Failed activation ', value:'Failed activation '},
  { label: 'Other', value: 'Other' },

]

export const REFUND_DEFAULT_POST_TICKET_OPTIONS: SnowflakeSelectOption[] = [
  { label: 'Order cancellation', value: 'Order cancellation' },
  { label: 'TMMO error', value: 'TMMO error' },
  { label: 'Incorrect bill', value: 'Incorrect bill' },
  { label: 'Delayed cancellation', value: 'Delayed cancellation' },
  { label: 'Early cancellation', value: 'Early cancellation' },
  { label: 'Other', value: 'Other' }
]

export const ALLOCATION_DEFAULT_POST_TICKET_OPTIONS: SnowflakeSelectOption[] = [
  { label: 'Payment allocation', value: 'Payment allocation' },
  { label: '4G Mobile gig allocation', value: '4G Mobile gig allocation' },
]

export const DEVICE_DEFAULT_POST_TICKET_OPTIONS: SnowflakeSelectOption[] = [
    { label: 'Faulty Device', value: 'Faulty Device' },
    { label: 'Poor quality device', value: 'Poor quality device' },
    { label: 'Distance from router', value: 'Distance from router' },
    { label: 'Too many connected users', value: 'Too many connected users' },
    { label: 'Other', value: 'Other' }
]

export const ONBOARDING_DEFAULT_POST_TICKET_OPTIONS: SnowflakeSelectOption[] = [
    { label: 'Customer Education', value: 'Customer Education' },
    { label: 'Optimisation', value: 'Optimisation' },
    { label: 'e-RICA', value: 'e-RICA' },
    { label: 'Delivery', value: 'Delivery' },
    { label: 'APN Settings', value: 'APN Settings' },
    { label: 'Activation', value: 'Activation' },
    { label: 'Successful sale', value: 'Successful sale' },
    { label: 'Collection', value: 'Collection' },
    { label: 'Delivery to collection', value: 'Delivery to collection' },
    { label: 'Other', value: 'Other' }
]

export const BUSINESS_OPERATIONS_DEFAULT_POST_TICKET_OPTIONS: SnowflakeSelectOption[] = [
    { label: 'Migration', value: 'Migration' },
    { label: 'Lost SIM', value: 'Lost SIM' },
    { label: 'SIM Swop', value: 'SIM Swop' },
    { label: 'Password Reset', value: 'Password Reset' },
    { label: 'Spend Limit', value: 'Spend Limit' },
    { label: 'Other', value: 'Other' }
]

export const BILLING_DISPUTE_POST_TICKET_OPTIONS: SnowflakeSelectOption[] = [
    { label: 'Multiple Invoices', value: 'Multiple Invoices' },
    { label: 'Zero usage', value: 'Zero usage' },
    { label: 'Invoice request', value: 'Invoice request' },
    { label: 'rainone missed payment invoice', value: 'rainone missed payment invoice' },
    { label: 'post paid last charge cancellation', value: 'post paid last charge cancellation' },
]

export const PROFILE_MANAGEMENT_POST_TICKET_OPTIONS: SnowflakeSelectOption[] = [
  { label: 'Payment date change', value: 'Payment date change' },
  { label: 'Customer detail change', value: 'Customer detail change' },
]

export const BILLING_ACCOUNT_ARREARS_POST_TICKET_OPTIONS: SnowflakeSelectOption[] = [
    { label: 'Expired Card', value: 'Expired Card' },
    { label: 'Late Cancellation', value: 'Late Cancellation' },
    { label: 'Late SIM Swap', value: 'Late SIM Swap' },
    { label: 'Zero usage', value: 'Zero usage' }
]
