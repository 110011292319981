import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { FormHelper } from 'src/app/Utils/helpers';
import { PostTicketModalOptions } from 'src/app/shared/services/post-ticket/post-ticket-modal-options.model';
import { ChatJsonNote } from '../components/chat-json-note/assets/chat-json-note.interface';
import { Store } from '@ngxs/store';
import { PostTicketService } from 'src/app/shared/services/post-ticket/post-ticket.service';
import { InteractionTicketsState } from '../../store/state/interaction-tickets.state';
import { ToggleModalByName } from 'src/app/core/store/actions/modal.actions';

@Component({
  selector: 'sf-fullfillment-cpe-collections-post-close-modal',
  templateUrl: './fullfillment-cpe-collections-post-close-modal.component.html',
  styleUrls: ['./fullfillment-cpe-collections-post-close-modal.component.scss']
})
export class FullfillmentCpeCollectionsPostCloseModalComponent extends FormHelper implements OnInit {
  readonly FULFILLMENT_CPE_COLLECTIONS_OPTIONS: string[]= [
    'Immediate Collection',
    'Scheduled Collection',
    'CPE Return',
    'Swap Out'
  ]
  selectedCpeCollectionOption:string;
  form: FormGroup;
  modalOptions: PostTicketModalOptions;
  readonly ngDestroy$ = new Subject<void>();

  constructor(private formBuilder:FormBuilder, private store: Store, private postTicketService: PostTicketService) {
    super();
  }

  createForm()
  {
    this.form = this.formBuilder.group({
      fulfillment_collections_option: this.formBuilder.control([])

    })
  }
  ngOnInit(): void {
    this.createForm();
    this.InitForm(this.form);
    this.modalOptions = new PostTicketModalOptions();

  }


  updateSelectedOption(option: string) {
    this.selectedCpeCollectionOption = option;
}

onSubmit(){
  const data = {
    "cpe collection reason": this.selectedCpeCollectionOption,
  }
  const payload: ChatJsonNote = {
      message: "fulfillment cpe collection post close form",
      detail: data,
      type: 'fulfillment_cpe_collections_form'
  }
  const hexId = this.store.selectSnapshot(InteractionTicketsState.getSelectedHexId);
  this.postTicketService.sendPostTicketPayload(
    hexId, payload, this.modalOptions, this.close.bind(this)
);
}

close(){
  this.store.dispatch(new ToggleModalByName('fulfillment_rica_post_close_modal'));
}



 ngOnDestroy() {
  this.ngDestroy$.next(null);
  this.ngDestroy$.complete();
}

resertFormControl(controlName:string){
  this.form.get(controlName).reset()
}

}
