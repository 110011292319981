<div class="form-row" [class.has-error]="hasError">
  <label>{{label}}</label>
  <div class="input-container">
    <ng-content select="[inputContent]"></ng-content>
  </div>

  <sf-form-error *ngIf="!showMultipleErrors">
    <ng-content select="[error]">
    </ng-content>
  </sf-form-error>


  <ng-content *ngIf="showMultipleErrors" select="[errors]"></ng-content>
</div>
