
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { TicketGQLResponse } from 'src/app/features/pages/ticket/interfaces/ticket-response.interface';
import { FetchTicketById, PaginateTicketFilterSchema, TicketSchema } from '../schemas/ticket.schema';
import { CoreState } from '../store/state/core.state';
import { BaseGraphQLService } from './base-graphql-api.service';

@Injectable({
    providedIn: 'root'
})
export class TicketFilterService extends BaseGraphQLService {

    constructor(public _http: HttpClient, private store: Store) {
        super(_http);
    }

    getAllTicketByFilters(filters: any, pagination: string) {        
        return this.query({
            query: PaginateTicketFilterSchema(filters, pagination),
        })
    }

    paginateTickets(filters: any, pagination: string) {
        return this.query({
            query: PaginateTicketFilterSchema(filters, pagination),
        })
    }

    getAllTickets(): Observable<TicketGQLResponse>  {
        return this.query({
            query: TicketSchema
        })
    }

    fetchTicket(options: any): Observable<TicketGQLResponse>  {
        return this.query({
            query: FetchTicketById(options)
        })
    }

    AddTicketFollower(data: {ticket_id: string, followers: Array<string>}) {
        const url = `${this._domain}/ticket/follow`;

        return this._http.post(url, data);
    }

    RemoveTicketFollower(data: {ticket_id: string, followers: Array<string>}) {
        const url = `${this._domain}/ticket/unfollow`;

        return this._http.post(url, data);
    }

    AddTicketAssignee(data: {ticket_id: string, assignee: string}) {
        const url = `${this._domain}/ticket/assign`;

        return this._http.post(url, data)
    }

    RemoveTicketAssignee(data: {ticket_id: string, assignee: string}) {
        const url = `${this._domain}/ticket/unassign`;

        return this._http.post(url, data);
    }

    UpdateTicket(ticket, id) {
        const url = `${this._domain}/ticket/${id}`;

        return this._http.put(url, ticket);
    }

    UploadFile(data: FormData, hexId: string) {
        const agent = this.store.selectSnapshot(CoreState.getAgent);
        const url = `${this._domain}/ticket/attachment/${hexId}`;

        data.append("hex_id", hexId);
        data.append("private", `${true}`);
        data.append("email", agent.email);

        return this._http.post(url, data);
    }

}
