import { SF_TICKET_PREFIX } from "src/app/constants";


//Event Names
export const DEFAULT_CALL_EVENT_NAME = "created new incoming call ticket" as const;

//Ticket Subjects
export const INCOMING_CALL_TITLE = `${SF_TICKET_PREFIX} support incoming call` as const;
export const OUTGOING_CALL_TITLE = `${SF_TICKET_PREFIX} support outgoing call` as const;

export type VoiceTicketSubject = typeof INCOMING_CALL_TITLE | typeof OUTGOING_CALL_TITLE;