import { MobileProductVasIdResponse } from "src/app/customer-info-summary-page/store/interfaces/mobile-product-vas-id-response.interface";
import { Utils } from "src/app/Utils";

export class Fetch {
  static readonly type = Utils.Helpers.Type("[Mobile Product VAS Id] Fetch");
  constructor(public payload: string) { }
}

export class FetchSuccess {
  static readonly type = Utils.Helpers.Type("[Mobile Product VAS Id] Fetch success");
  constructor(public payload: MobileProductVasIdResponse) { }
}

export class FetchFail {
  static readonly type = Utils.Helpers.Type("[Mobile Product VAS Id] Fetch fail");
  constructor(public error: unknown) { }
}

export class Clear {
  static readonly type = Utils.Helpers.Type("[Mobile Product VAS Id] Clear");
}
