<div
  class="toggle-container"
  [class.header-toggle]="headerToggle"
>
  <p>
    {{label}}
  </p>

  <div>
    <label [class.disabled]="disabled">
      <input
        [formControl]="toggleControl"
        type="checkbox"
        (change)="onToggle()"
        [checked]="toggled"
      >
      <span></span>
    </label>
  </div>

</div>