import { Injectable } from '@angular/core';
import { Action, createSelector, Selector, State, StateContext, } from '@ngxs/store';
import { tap, takeUntil, take } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { DataLoading } from 'src/app/shared/interfaces/data-loading.interface';
import { Utils } from 'src/app/Utils';
import { DataLoadingHelper } from 'src/app/Utils/helpers';
import { NWDAFConnectedSiteActions } from '../actions/nwdaf-connected-site-actions';
import { NWDAFService } from '../services/nwdaf.service';
import { NWDAFConnectedSite } from '../interfaces/nwdaf-connected-site.interface';
import { SFValidators } from 'src/app/shared/functions/sf-validators';
import { ConnectedSiteStatus } from '../interfaces/connected-site-status.type';
import { produce } from 'immer';
import { RainAppResponse } from '../../assets/rain-app-response.interface';
import { SiteConnectionData } from '../interfaces/site-connection-data.interface';
import { aggregateCurrentlyConnectedData } from '../functions/aggregate-connection-data';
import { CustomerInfoSummaryDestroyed } from 'src/app/customer-info-summary-page/store/actions/customer.actions';
import { NO_DATA_MESSAGE } from 'src/app/constants';
import { CustomerResourceSelectors } from 'src/app/customer-info-summary-page/store/selectors/customer-resource.selectors';


const getDefaults = (): NWDAFConnectedSiteStateModel => {
    return {
        imsis: {}
    }
}

export interface NWDAFConnectedSiteStateModel {
    imsis: {
        [imsis: string]: DataLoading<NWDAFConnectedSite>;
    }
}


@State<NWDAFConnectedSiteStateModel>({
    name: 'sf_NWDAF_connected_site_state',
    defaults: getDefaults()
})
@Injectable()
export class NWDAFConnectedSiteState {

    @Selector([NWDAFConnectedSiteState])
    static loadingIMSIs(state: NWDAFConnectedSiteStateModel) {
        return (Object.entries(state?.imsis) ?? [])
            .filter(([_imsi, dataLoading]) => dataLoading?.loading)
            .map(([imsi]) => imsi);
    }

    @Selector([NWDAFConnectedSiteState.loadingIMSIs])
    static anyLoading(loadingImsis: string[]) { return loadingImsis?.length > 0; }

    static anyLoadingByIMSIs(inputImsis: string[]) {
        return createSelector(
            [NWDAFConnectedSiteState],
            (state: NWDAFConnectedSiteStateModel) => {
                const { imsis } = state;
                return (Object.entries(imsis) ?? [])
                    .filter(([imsi]) => inputImsis?.includes(imsi))
                    .some(([_imsi, dataLoading]) => dataLoading?.loading)
            });
    }


    static isLoading(imsi: string) {
        return createSelector([NWDAFConnectedSiteState], (state: NWDAFConnectedSiteStateModel) => {
            return state.imsis[imsi]?.loading;
        });
    }

    static isLoaded(imsi: string) {
        return createSelector([NWDAFConnectedSiteState], (state: NWDAFConnectedSiteStateModel) => {
            return state.imsis[imsi]?.loaded;
        });
    }

    static getData(imsi: string) {
        return createSelector([NWDAFConnectedSiteState], (state: NWDAFConnectedSiteStateModel) => {
            return state.imsis[imsi]?.data;
        });
    }

    static getError(imsi: string) {
        return createSelector([NWDAFConnectedSiteState], (state: NWDAFConnectedSiteStateModel) => {
            return state.imsis[imsi]?.error;
        });
    }

    static loadedSuccessfully(imsi: string) {
        return createSelector([NWDAFConnectedSiteState], (state: NWDAFConnectedSiteStateModel) => {
            const { loaded, data } = state.imsis[imsi] ?? {};
            return loaded && SFValidators.isDefined(data);
        });
    }

    static getStateByIMSI(imsi: string) {
        return createSelector(
            [NWDAFConnectedSiteState],
            (state: NWDAFConnectedSiteStateModel) => state.imsis[imsi]
        );
    }

    static getStatus(imsi: string): (state: NWDAFConnectedSiteStateModel) => ConnectedSiteStatus {
        return createSelector([NWDAFConnectedSiteState], (state: NWDAFConnectedSiteStateModel) => {
            const { error, data, loading } = state.imsis[imsi] ?? {};
            const { online } = data ?? {};
            if (loading) {
                return "loading";
            }

            if (error === NO_DATA_MESSAGE) {
                return "not-connected";
            }

            if (error) {
                return "error";
            }

            return online ? "connected" : "not-connected";
        });
    }

    static getConnectionData(imsi: string) {
        return createSelector(
            [
                NWDAFConnectedSiteState.isLoaded(imsi),
                NWDAFConnectedSiteState.getData(imsi),
                CustomerResourceSelectors.getRainAppResponseByIMSI(imsi)
            ],
            (loaded: boolean, connectedSiteData: NWDAFConnectedSite, rainAppResponse: RainAppResponse): SiteConnectionData => {
                return aggregateCurrentlyConnectedData(loaded, connectedSiteData, rainAppResponse);
            });
    }

    private readonly _cancelRequest$ = new Subject<null>();

    constructor(private nwdafService: NWDAFService) {
    }


    @Action(NWDAFConnectedSiteActions.FetchByIMSIIfNotPreset)
    FetchByIMSIIfNotPresent(ctx: StateContext<NWDAFConnectedSiteStateModel>, action: NWDAFConnectedSiteActions.FetchByIMSIIfNotPreset) {
        const { imsi } = action;
        const { loading, loaded } = ctx.getState().imsis?.[imsi] ?? {};
        if (!loading && !loaded) {
            return ctx.dispatch(new NWDAFConnectedSiteActions.FetchByIMSI(imsi));
        }
    }

    @Action(NWDAFConnectedSiteActions.FetchByIMSI)
    FetchByIMSI(ctx: StateContext<NWDAFConnectedSiteStateModel>, action: NWDAFConnectedSiteActions.FetchByIMSI) {
        const { imsi } = action;

        if (!imsi) {
            return;
        }

        ctx.setState(produce(draft => {
            draft.imsis[imsi] = DataLoadingHelper.isLoading<NWDAFConnectedSite, string>();
        }));

        return this.nwdafService.fetchConnectedSiteByIMSI(imsi)
            .pipe(
                tap({
                    next: res => {
                        const data = res?.data?.[0];
                        if (!data) {
                            return ctx.dispatch(new NWDAFConnectedSiteActions.FetchByIMSIFail(imsi, NO_DATA_MESSAGE));
                        }
                        return ctx.dispatch(new NWDAFConnectedSiteActions.FetchByIMSISuccess(imsi, data));
                    },
                    error: (e: unknown) => ctx.dispatch(new NWDAFConnectedSiteActions.FetchByIMSIFail(imsi, e))
                }),
                takeUntil(this._cancelRequest$.pipe(take(1)))
            );
    }


    @Action(NWDAFConnectedSiteActions.FetchByIMSISuccess)
    FetchByIMSISuccess(ctx: StateContext<NWDAFConnectedSiteStateModel>, action: NWDAFConnectedSiteActions.FetchByIMSISuccess) {
        const { imsi, payload } = action;
        ctx.setState(produce(draft => {
            draft.imsis[imsi] = DataLoadingHelper.dataLoaded(payload);
        }));
    }


    @Action(NWDAFConnectedSiteActions.FetchByIMSIFail)
    FetchByIMSIFail(ctx: StateContext<NWDAFConnectedSiteStateModel>, action: NWDAFConnectedSiteActions.FetchByIMSIFail) {
        const { imsi, error } = action;
        const errorMessage = Utils.Helpers.findError(error, '');

        ctx.setState(produce(draft => {
            draft.imsis[imsi] = DataLoadingHelper.errorLoaded(errorMessage);
        }));
    }

    @Action([NWDAFConnectedSiteActions.Clear, CustomerInfoSummaryDestroyed])
    Clear(ctx: StateContext<NWDAFConnectedSiteStateModel>) {
        this._cancelRequest$.next(null);
        ctx.setState(getDefaults());
    }

}   