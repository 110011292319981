import { Component, Input } from '@angular/core';

@Component({
  selector: 'sf-rollup-wrapper',
  templateUrl: './rollup-wrapper.component.html',
  styleUrls: ['./rollup-wrapper.component.scss']
})
export class RollupWrapperComponent {

  @Input() rolledUp = false;
  @Input() header: string;

  toggleRolledUp() {
    this.rolledUp = !this.rolledUp;
  }

}
