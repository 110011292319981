import { Dictionary } from "src/app/shared/interfaces/dictionary.interface";
import { PathKeys } from "src/app/shared/types/path-keys.type";
import { SortOrder } from "src/app/shared/types/sort-order.type";
import { getNestedValue } from "./get-nested-key.helper";

export function SortBy<T extends Dictionary>(array: T[], key: keyof T, order: SortOrder = "asc") {
    const nArry = [...array];
    if  (order === 'asc') {
        nArry.sort((a,  b) => {
            const date1 = new Date(a[key]).getTime();
            const date2 = new Date(b[key]).getTime();
            return date2 < date1 ? 1 : -1;
        });
    } else {
        nArry.sort((a,  b) => {
            const date1 = new Date(a[key]).getTime();
            const date2 = new Date(b[key]).getTime();
            return date2 > date1 ? 1 : -1;
        });
    }

    return nArry;
}


export function nestedSortBy<T>(array: T[], keyString: PathKeys<T>, order: SortOrder = "asc") {
    const nArry = [...array];
    if  (order === 'asc') {
        nArry.sort((a,  b) => {
            const date1 = getTime(a, keyString);
            const date2 = getTime(b, keyString);
            return date2 < date1 ? 1 : -1;
        });
    }
    else {
        nArry.sort((a, b) => {
            const date1 = getTime(a, keyString);
            const date2 = getTime(b, keyString);
            return date2 > date1 ? 1 : -1;
        });
    }

    return nArry;
}

function getTime<T extends Dictionary>(obj: T, keyString: string) {
    const dateString = getNestedValue(obj, <any>keyString);
    if (!dateString) {
        return;
    }
    return new Date(dateString).getTime();
}
