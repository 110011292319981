import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { ResetTimer } from "src/app/main-modals-container/store/actions/time-out.actions";
import { DataHandler } from "src/app/shared/data-handler/data-handler";
import { CallLogType, SipCallType } from "../../assets/sip-call.type";
import { CallLoggingService } from "../../services/call-loggin.service";
import { SipPhoneService } from "../../services/sip-phone.service";
import { SipPhoneActions } from "../actions/sip-phone-actions";
import { CallDetails } from "../interfaces/call-details.interface";

const RECORDING_ID_TYPES: CallLogType[] = [
    "incomingRecordingId",
    "outgoingRecordingId"
]

interface SipPhoneStateModel {
    isUserAgentProcessing: boolean;
    incomingCallDetails: CallDetails;
    outgoingCallDetails: CallDetails;
    lastLoggedCallIds: { [key in CallLogType]: string };
    callTicketHexId: string;
    unknownIncomingCall: boolean;
    currentCallType: SipCallType;
    lastPhoneNumber: string;
}

@State<SipPhoneStateModel>({
    name: 'sip_state',
    defaults: {
        isUserAgentProcessing: false,
        incomingCallDetails: null,
        outgoingCallDetails: null,
        lastLoggedCallIds: {
            attempt: null,
            incoming: null,
            outgoing: null,
            incomingRecordingId: null,
            outgoingRecordingId: null
        },
        callTicketHexId: null,
        unknownIncomingCall: false,
        currentCallType: null,
        lastPhoneNumber: null
    }
})


@Injectable()
export class SipPhoneState {

    @Selector()
    static isUserAgentProcessing(state: SipPhoneStateModel) { return state.isUserAgentProcessing; }

    @Selector()
    static getIncomingCallDetails(state: SipPhoneStateModel) { return state.incomingCallDetails; }

    @Selector()
    static getIncomingOrOutgoingCallDetails(state: SipPhoneStateModel) {
        return state.incomingCallDetails ?? state.outgoingCallDetails;
    }

    @Selector()
    static getCallTicketHexId(state: SipPhoneStateModel) { return state.callTicketHexId; }

    @Selector()
    static isUnknownIncomingCall(state: SipPhoneStateModel) { return state.unknownIncomingCall; }

    @Selector()
    static getLastPhoneNumber(state: SipPhoneStateModel) { return state.lastPhoneNumber; }

    constructor(private callLogService: CallLoggingService,
        private sipPhoneService: SipPhoneService) {
    }

    @Action(SipPhoneActions.SetUserAgentProcessing)
    setUserAgentProcessing(ctx: StateContext<SipPhoneStateModel>, action: SipPhoneActions.SetUserAgentProcessing) {
        ctx.patchState({
            isUserAgentProcessing: action.processing
        });
    }

    @Action(SipPhoneActions.SetIncomingCallDetails)
    setIncomingCallDetails(ctx: StateContext<SipPhoneStateModel>, action: SipPhoneActions.SetIncomingCallDetails) {
        ctx.patchState({
            incomingCallDetails: action.payload,
            lastPhoneNumber: action.payload?.phoneNumber
        });
    }

    @Action(SipPhoneActions.ClearIncomingCallDetails)
    clearIncomingCallDetails(ctx: StateContext<SipPhoneStateModel>) {
        ctx.patchState({
            incomingCallDetails: null
        });
    }

    @Action(SipPhoneActions.SetOutgoingCallDetails)
    SetOutgoingCallDetails(ctx: StateContext<SipPhoneStateModel>, action: SipPhoneActions.SetOutgoingCallDetails) {
        ctx.patchState({
            outgoingCallDetails: action.payload,
            lastPhoneNumber: action.payload?.phoneNumber
        });
    }

    @Action(SipPhoneActions.CallSessionEnded)
    ClearOutgoingCallDetails(ctx: StateContext<SipPhoneStateModel>) {
        ctx.patchState({
            outgoingCallDetails: null
        });
    }

    //TODO: refactor this trash
    @Action(SipPhoneActions.LogCall)
    logCall(ctx: StateContext<SipPhoneStateModel>, action: SipPhoneActions.LogCall) {
        const { callType, payload } = action;
        const { callId, recordingId, recordingFileName } = payload?.callDetails ?? {};

        const callLoggingId = RECORDING_ID_TYPES.includes(callType) ? recordingId : callId;

        const { lastLoggedCallIds } = ctx.getState();
        const lastLoggedCallIdCopy = DataHandler.createDeepCopy(lastLoggedCallIds);

        if (!callLoggingId || lastLoggedCallIdCopy?.[callType] === callLoggingId) {
            //Don't log the same call ID again
            return;
        }

        lastLoggedCallIdCopy[callType] = callLoggingId;

        ctx.patchState({
            lastLoggedCallIds: lastLoggedCallIdCopy,
        });

        this.callLogService.logCall(
            callType,
            {
                hexId: payload.hexId,
                callDetails: {
                    callId: callLoggingId,
                    phoneNumber: payload?.callDetails?.phoneNumber,
                    recordingFileName
                }
            }
        );
    }

    @Action(SipPhoneActions.SetCallTicketHexId)
    setCallTicketHexId(ctx: StateContext<SipPhoneStateModel>, action: SipPhoneActions.SetCallTicketHexId) {
        const { hexId } = action;

        ctx.patchState({
            callTicketHexId: hexId
        });
    }

    //Clear call ticket hex id when the call session ends
    @Action(SipPhoneActions.CallSessionEnded)
    clearCallTicketHexId(ctx: StateContext<SipPhoneStateModel>) {
        ctx.patchState({
            callTicketHexId: null
        });

        //Reset the main timeout timer
        ctx.dispatch(new ResetTimer());
    }


    @Action(SipPhoneActions.SetUnknownIncomingCall)
    setUnknownIncomingCall(ctx: StateContext<SipPhoneStateModel>, action: SipPhoneActions.SetUnknownIncomingCall) {
        ctx.patchState({
            unknownIncomingCall: action.isUnknown
        });
    }

    @Action(SipPhoneActions.RetryUserAgentRegistration)
    RetryUserAgentRegistration() {
        return this.sipPhoneService.retryUserAgentConnection();
    }

    @Action(SipPhoneActions.SetCurrentCallType)
    SetCurrentCallType(ctx: StateContext<SipPhoneStateModel>, action: SipPhoneActions.SetCurrentCallType) {
        ctx.patchState({
            currentCallType: action.callType
        })
    }



}
