
export function stripHTMLTag(htmlString: string, tagLetter: string) {
    if (!htmlString) {
        return htmlString;
    }

    const firstExp = new RegExp(`<${tagLetter}\\b[^>]*>`, "ig");
    const secondExp = new RegExp(`<\\/${tagLetter}>`, "ig");

    return htmlString
        .replace(firstExp, "")
        .replace(secondExp, "");
}