import { RouterState } from '@ngxs/router-plugin';
import { Selector } from "@ngxs/store";
import { DigitalIdentityByUserIdSelectors, DigitalIdentityByUserIdViewModel } from 'src/app/customer-data-components/axiom/digital-identity/store/selectors/digital-identity-by-user-id.selectors';
import { CantPurchaseReason, PurchaseValidationSelectors } from "src/app/customer-info-summary-page/store/selectors/purchase-validation.selectors";
import { SFValidators } from 'src/app/shared/functions/sf-validators';

export interface InteractionButtonsViewModel {
  disabled: boolean;
  reason: string;
}

export class InteractionButtonsSelectors {

  @Selector([
    PurchaseValidationSelectors.conditionsForDisable(['hasMax5gRainone101HomeOrWorkOrderOrServiceForSales', 'prohibitedAccountState']),
    PurchaseValidationSelectors?.conditionsForDisable(["has5gRainone101OrderOrServiceForSales"]),
    PurchaseValidationSelectors?.conditionsForDisable(["hasMax5gRainone101WorkOrderOrServiceForSales"]),
    PurchaseValidationSelectors?.conditionsForDisable(["hasMax4gMobileOrdersOrServicesForSales"]),
    RouterState,
    DigitalIdentityByUserIdSelectors.getViewModel
  ])
  static getViewModel(
    mainCantPurchase: CantPurchaseReason,
    homeCantPurchase: CantPurchaseReason,
    workCantPurchase: CantPurchaseReason,
    mobileCantPurchase: CantPurchaseReason,
    routerState: any, //TODO
    digitalIdentityByUserIdViewModel: DigitalIdentityByUserIdViewModel
  ): InteractionButtonsViewModel {

    const { product } = routerState?.state?.queryParams ?? {}
    const isWorkSelected = product === 'work'
    const isHomeSelected = product === 'home'
    const isMobileSelected = product === 'mobile'
    const productSelected = SFValidators.isDefined(product)

    const { hasSMERole } = digitalIdentityByUserIdViewModel ?? {}

    const disableHomePurchase = (homeCantPurchase?.isTrue && productSelected && !isWorkSelected && !isMobileSelected || (homeCantPurchase.isTrue && hasSMERole)) && isHomeSelected
    const disableHomePurchaseReason = disableHomePurchase && isHomeSelected ? homeCantPurchase?.reason : ''

    const disableWorkPurchase = (workCantPurchase?.isTrue && productSelected  && !isHomeSelected && !isMobileSelected || (workCantPurchase.isTrue && hasSMERole)) && isWorkSelected
    const disableWorkPurchaseReason = disableWorkPurchase && isWorkSelected ? workCantPurchase?.reason : ''

    const disableMobilePurchase = (mobileCantPurchase?.isTrue && productSelected && !isWorkSelected && !isHomeSelected || (mobileCantPurchase.isTrue && hasSMERole)) && isMobileSelected
    const disableMobilePurchaseReason = mobileCantPurchase && isMobileSelected ? mobileCantPurchase?.reason : ''

    return {
      disabled: mainCantPurchase?.isTrue || disableHomePurchase || disableWorkPurchase || disableMobilePurchase,
      reason: mainCantPurchase?.reason || disableHomePurchaseReason || disableWorkPurchaseReason || disableMobilePurchaseReason
    }

  }

}
