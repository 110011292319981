import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Actions, Select, Store, ofActionDispatched } from '@ngxs/store';
import { Observable, Subject, timer } from 'rxjs';
import { filter, take, takeUntil } from 'rxjs/operators';
import { AssignAgent } from 'src/app/shared/components/agent/store/actions/agent.actions';
import { AgentCallbackActions } from 'src/app/shared/components/callback/store/actions/agent-callback-actions';
import { MILISECONDS_IN_MINUTE } from 'src/app/constants';
import { AgentCallbackState } from 'src/app/shared/components/callback/store/state/agent-callback.state';
import { AgentNotificationsSelector, AgentNotificationsViewModel } from './view-model/agent-notifications.selector';
import { AgentNotificationActions } from './store/actions/agent-notification-actions';
import { AgentNotificationState } from './store/state/agent-notifications.state';
import { AgentNotificationWSActions } from './store/actions/agent-notification-ws-actions';
import { AgentNotificationWebSocketService } from './store/services/agent-notification-websocket.service';


const ONE_MINUTE = MILISECONDS_IN_MINUTE;
const TEN_SECONDS = 10_000;
const FIVE_SECONDS = 5_000;


@Component({
    selector: 'sf-agent-notifications',
    templateUrl: './agent-notifications.component.html',
    styleUrls: ['./agent-notifications.component.scss']
})
export class AgentNotificationsComponent implements OnInit, OnDestroy {

    @Select(AgentNotificationsSelector.getViewModel) viewModel$: Observable<AgentNotificationsViewModel>;

    private readonly ngDestroy$ = new Subject<void>();

    constructor(private store: Store,
        private actions$: Actions,
        private agentNotificationWSService: AgentNotificationWebSocketService) { }

    ngOnInit(): void {
        this.actions$
            .pipe(
                ofActionDispatched(AssignAgent),
                filter((action: AssignAgent) => Boolean(action?.payload?.email)),
                take(1),
                takeUntil(this.ngDestroy$)
            )
            .subscribe({
                next: () => {
                    this.initializeCallbackListeners();
                    this.store.dispatch(new AgentNotificationWSActions.ConnectInCtx());
                }
            });

        this.agentNotificationWSService
            .getNotificationsForTopic("alert")
            .pipe(
                takeUntil(this.ngDestroy$)
            )
            .subscribe({
                next: notification => {
                    const { message, alert_type } = notification ?? {};

                    this.store.dispatch(new AgentNotificationActions.AddNotification({
                        alertType: alert_type,
                        message
                    }))
                }
            });


    }

    @HostListener('click', ['$event'])
    private clickOutside(e: { target: HTMLElement }) {
        const target = e?.target;

        const isOutside = target?.classList?.contains('hidden-overlay');
        if (isOutside) {
            this.setOpen(false);
        }
    }

    private initializeCallbackListeners() {

        timer(TEN_SECONDS, FIVE_SECONDS)
            .pipe(
                takeUntil(this.ngDestroy$)
            )
            .subscribe({
                next: () => {

                    const hasCheckableCallbacks = this.store.selectSnapshot(AgentCallbackState.hasCheckableCallbacks);
                    if (hasCheckableCallbacks) {
                        this.store.dispatch(new AgentCallbackActions.CheckNotificationTime());
                    }

                }
            });

        timer(TEN_SECONDS, ONE_MINUTE)
            .pipe(
                takeUntil(this.ngDestroy$)
            )
            .subscribe({
                next: () => {

                    const isLoaded = this.store.selectSnapshot(AgentCallbackState.isLoaded);
                    if (isLoaded) {
                        this.store.dispatch(new AgentCallbackActions.Refresh());
                    }
                    else {
                        this.store.dispatch(new AgentCallbackActions.FetchInCtx());
                    }

                }
            });

    }

    toggleOpen() {
        const isOpen = this.store.selectSnapshot(AgentNotificationState.isOpen);
        this.setOpen(!isOpen);
    }

    setOpen(open: boolean) {
        this.store.dispatch(new AgentNotificationActions.SetOpen(open));
    }

    closeAllNotifications() {
        this.store.dispatch(new AgentNotificationActions.CloseAll());
    }


    ngOnDestroy(): void {
        this.ngDestroy$.next(null);
        this.ngDestroy$.complete();
    }

}
