import { AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { DataHandler } from '../../data-handler/data-handler';
import { CircleOptions, CountdownCircleInput, getDefaultCountDownCircleValues } from './interfaces/circle-options.interface';


@Component({
  selector: 'sf-countdown-circle',
  templateUrl: './countdown-circle.component.html',
  styleUrls: ['./countdown-circle.component.scss']
})
export class CountdownCircleComponent implements AfterViewInit {
  @ViewChild("cv") canvasEl: ElementRef<HTMLCanvasElement>;

  private _circleInput: CountdownCircleInput = this.getDefaultValues();
  @Input() set circleInput(value: CountdownCircleInput) {
    if (!value) {
      this._circleInput = this.getDefaultValues();
      this.draw();
      return;
    }

    if (!value.options) {
      value.options = getDefaultCountDownCircleValues()
    }
    this._circleInput = value;
    this.draw();
  }

  get circleInput() {
    return this._circleInput;
  }

  private getDefaultValues() {
    return {
      options: getDefaultCountDownCircleValues(),
      values: {
        percentageLeft: 0
      }
    }
  }

  ngAfterViewInit(): void {
    this.draw();
  }

  private calcDegrees(percentageLeft: number) {
    if (percentageLeft > 0) {
      return 360 * (percentageLeft < 1 ? 1 - percentageLeft : 1);
    }
    return 0;
  }

  private draw() {
    const canvas = this.canvasEl?.nativeElement;
    if (!canvas) {
      return;
    }

    const { values, options } = this._circleInput;
    const { text, percentageLeft } = values;
    const degrees = this.calcDegrees(percentageLeft);

    this.drawCircleOnCanvas(canvas, {
      ...options,
      text,
      degrees
    });
  }

  private drawCircleOnCanvas(canvas: HTMLCanvasElement, values: CircleOptions) {
    const { backgroundColor, fillColor, degrees, text, textSize, lineWidth } = values;
    const ctx = canvas.getContext("2d");
    const { width, height } = canvas;
    ctx.clearRect(0, 0, width, height);

    //Draw background circle
    ctx.beginPath();
    ctx.strokeStyle = backgroundColor;
    ctx.lineWidth = lineWidth;
    ctx.arc(width / 2, width / 2, 100, 0, Math.PI * 2, false);
    ctx.stroke();
    const radians = degrees * Math.PI / 180;

    //Draw overlay circle
    if (degrees > 0) {
      ctx.beginPath();
      ctx.strokeStyle = fillColor;
      ctx.lineWidth = lineWidth;
      ctx.lineCap = "round";
      ctx.arc(width / 2, height / 2, 100, 0 - 90 * Math.PI / 180, radians - 90 * Math.PI / 180, true);
      ctx.stroke();
      ctx.fillStyle = fillColor;
    }
    else {
      ctx.fillStyle = backgroundColor;
    }

    if (!DataHandler.isDefined(text)) {
      return;
    }

    //Add text in the center
    const txtSize = textSize ?? 135;
    ctx.font = `normal 700 ${txtSize}px Poppins`;
    const textRatioAdjust = (txtSize + 5) * 0.35;
    const textWidth = ctx.measureText(text?.toString()).width;
    ctx.fillText(text?.toString(), width / 2 - textWidth / 2, height / 2 + textRatioAdjust);
  }



}
