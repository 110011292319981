import { Injectable, OnDestroy } from '@angular/core';
import { Store } from '@ngxs/store';
import { Feature, Geometry } from 'geojson';
import { Subject } from 'rxjs';
import { DataHandler } from '../../data-handler/data-handler';
import { AzimuthLayer } from '../../elements/map/assets/azimuth-layer';
import { FetchAzimuthData } from '../../elements/map/store/actions/azimuth.actions';
import { GeminiFeature } from '../../elements/map/store/interfaces/gemini-response.interface';
import { AzimuthState } from '../../elements/map/store/state/azimuth.state';
import { GeoFunctions } from '../../functions/geo-functions';
import { AddLayerOptions } from './assets/add-layer.options';
import { GoogleMapsEventInfo } from './assets/google-maps-event-info.interface';
import { MapLayerId } from './assets/map-layer-ids.interface';
import { MapLayerFunctions } from './assets/map-layer-functions';
import { AlarmSiteFeatureCollection, NormalSiteFeatureCollection, NormalSiteStatus } from './assets/map-site.interfaces';
import { PulsatingDot, CustomCircleOptions } from './assets/pulsating-dot.model';
import { TechType } from './assets/techtype.type';
import { NewMapService } from './new-map.service';
import { SiteAlarmDetailActions } from '../../elements/map/store/actions/site-alarm-detail-actions';
import { SiteAlarmDetailsState } from '../../elements/map/store/state/site-alarm-details.state';
import { Severity } from './assets/severity.type';
import { FilterFunctions } from '../../functions/filter';
import moment from 'moment';
import { SFValidators } from '../../functions/sf-validators';
import { TECH_TYPES } from 'src/app/constants';
import { DecimalPipe } from '@angular/common';
import { Postfix } from '../../pipes/general-pipes/postfix-pipe';
import { CustomerGeoSelectors } from 'src/app/customer-data-components/sim-details/sim-card-page/sub-components/coverage/store/selectors/customer-geo.selectors';
import { calculateDistance } from '../../functions/geo-functions/geo-functions';


const PROLONGED_ALARM_HOURS = 24;

@Injectable({
    providedIn: 'root',
})
export class MapLayerService implements OnDestroy {
    private readonly ngDestroy: Subject<void> = new Subject();

    private dottedLineSymbol = {
        path: "M 0,-1 0,1",
        strokeOpacity: 1,
        scale: 4,
    }

    connectedLine: google.maps.Polyline;
    adHocLine: google.maps.Polyline;

    distanceMarkersForConnectedLine: google.maps.Marker[] = [];
    adHockDistanceMarkers: google.maps.Marker[] = [];

    layers: { [layer in MapLayerId]?: google.maps.Data } = {};
    coverageLayers: { [x: string]: google.maps.ImageMapType };
    azimuthLayers: { [siteID: string]: AzimuthLayer } = {};

    listeners: google.maps.MapsEventListener[] = [];

    pulsatingDot: PulsatingDot;
    infoWindow = new google.maps.InfoWindow({
        maxWidth: 350,
        pixelOffset: new google.maps.Size(0, 0)
    });

    icon_map: google.maps.Marker[] = [];

    alarmInfoWindow = new google.maps.InfoWindow();

    private pinLatLng: google.maps.LatLngLiteral;

    constructor(private store: Store,
        private newMapService: NewMapService,
        private decimalPipe: DecimalPipe,
        private postFixPipe: Postfix,
    ) {
    }

    setPinLatLng(location: google.maps.LatLngLiteral) {
        this.pinLatLng = location

    }

    highlightConnectedSite(coordinates: number[]): void {
        const lngLat = new google.maps.LatLng(coordinates[1], coordinates[0]);
        this.addAnimatedCircle(lngLat);
    }

    private addLayer<P>(map: google.maps.Map, features: Array<Feature<Geometry, P>>, options: AddLayerOptions<P>) {
        const { filterFn, styleFn, name } = options;

        const layer = new google.maps.Data({ map });
        this.layers[name] = layer;
        layer.addGeoJson({
            type: 'FeatureCollection',
            features: filterFn ? features?.filter(filterFn) : features,
        });

        layer.setStyle(styleFn);
        this.addListeners(layer, map);
        return layer;
    }

    addSites(map: google.maps.Map, features: NormalSiteFeatureCollection, techTypes: TechType[]) {
        if (!features?.length) {
            //Should show error
            return;
        }

        techTypes.forEach(type => this.addNormalSiteByTechType(map, features, type));

        //TODO: add back when required -> "OSS VC"
        this.addStatusSites(map, features, ["Roaming"]);
    }

    private addStatusSites(map: google.maps.Map, features: NormalSiteFeatureCollection, statuses: NormalSiteStatus[]) {
        statuses
            .forEach(status => this.addLayer(map, features, {
                name: <MapLayerId>status,
                filterFn: MapLayerFunctions.statusFeatureFilter(status),
                styleFn: f => MapLayerFunctions.getNormalSiteStyle(map, f, 35)
            }));
    }

    private addNormalSiteByTechType(map: google.maps.Map, features: NormalSiteFeatureCollection, techType: TechType) {
        this.addLayer(map, features, {
            name: techType,
            filterFn: MapLayerFunctions.normalFeatureFilter(techType),
            styleFn: f => MapLayerFunctions.getNormalSiteStyle(map, f, 25)
        })
    }

    updateNormalSites(map: google.maps.Map, features: NormalSiteFeatureCollection, techTypes: TechType[]) {

        TECH_TYPES.forEach(tech => {
            const layer = this.getLayerById(tech);
            const hasLayer = SFValidators.isDefined(layer);
            const shouldShowLayer = techTypes.includes(tech);

            if (shouldShowLayer) {
                hasLayer
                    ? GeoFunctions.updateDataLayerFeatures(layer, features.filter(MapLayerFunctions.normalFeatureFilter(tech)))
                    : this.addNormalSiteByTechType(map, features, tech);
            }
            else if (hasLayer) {
                GeoFunctions.removeFeaturesFromLayer(layer);
            }
        });


        //TODO: update "Roaming" and "OSS VC" sites when they need to be added
    }

    addAlarmSites(map: google.maps.Map, features: AlarmSiteFeatureCollection, techTypes: TechType[] = TECH_TYPES) {
        if (!features) {
            //Should show error
            return;
        }

        const correctFeatures = features.filter(f =>
            f?.properties?.status === 'OSS HO'
            && techTypes.includes(f?.properties?.tech)
            && (f?.properties?.severity === "CRITICAL"
                || f?.properties?.loadshedding)
        );

        const [prolongedAlarmFeatures, normalAlarmFeatures] = FilterFunctions.partition(
            correctFeatures,
            f => {
                const { severity, alarmdate } = f?.properties;
                if (alarmdate && severity === "CRITICAL") {
                    return moment().diff(moment(alarmdate), "hours") >= PROLONGED_ALARM_HOURS;
                }
                return false;
            }
        );

        this.addNormalAlarms(map, normalAlarmFeatures);
        this.addProlongedAlarmSites(map, prolongedAlarmFeatures);
    }

    addNormalAlarms(map: google.maps.Map, features: AlarmSiteFeatureCollection) {
        const alarmLayer = this.addLayer(map, features, {
            name: "Normal Alarms",
            styleFn: f => {
                const severity = f?.getProperty("severity") as Severity;
                const tech: '4g' | '5g' | unknown = f?.getProperty("tech")?.toLowerCase();
                const imgName = severity === 'CRITICAL' ? `${tech}_critical.png` : `${tech}_loadshed.png`;
                return MapLayerFunctions.buildStyle(map, imgName, 200);
            }
        });

        this.addAlarmInfoListener(alarmLayer, map);
    }


    addProlongedAlarmSites(map: google.maps.Map, features: AlarmSiteFeatureCollection) {
        const alarmLayer = this.addLayer(map, features, {
            name: "Prolonged Alarms",
            styleFn: f => {
                const tech: '4g' | '5g' | unknown = f?.getProperty("tech")?.toLowerCase();
                const imgName = `${tech}_critical_prolonged.png`;
                return MapLayerFunctions.buildStyle(map, imgName, 250);
            }
        });

        this.addAlarmInfoListener(alarmLayer, map);
    }

    async addConnectedLine(lngLatArray: google.maps.LatLngLiteral[], color: string) {
        this.removeConnectedLine();
        this.clearAllDistanceMarkersForConnectedLine();
        const connectedLineDistance = this.store.selectSnapshot(CustomerGeoSelectors.getConnectedLineDistanceInKm)

        const m = await this.newMapService.getMap();
        this.connectedLine = new google.maps.Polyline({
            path: lngLatArray,
            geodesic: false,
            strokeColor: color,
            zIndex: 20
        });
        this.connectedLine.setMap(m);
        const bounds = new google.maps.LatLngBounds();
        for (let i = 0; i < lngLatArray.length; i++) {
            bounds.extend(lngLatArray[i]);
        }

        const distanceMarker = new google.maps.Marker({
            position: bounds.getCenter(),
            draggable: false,
            label: { text: this.postFixPipe.transform(this.decimalPipe.transform(connectedLineDistance, '1.2-2'), ' km' ?? '--'), color: "white", fontSize: '16px', className: 'google-maps-label' },
            icon: ' '
        });

        this.distanceMarkersForConnectedLine.push(distanceMarker);
        this.setMapOnAllDistanceMarkersForConnectedLine(m)

    }

    async addAdHocLine(lngLatArray: google.maps.LatLngLiteral[], color: string) {
        this.removeAdHocLine();
        this.clearAllAdHockDistanceMarkers();
        const coordinatesList = lngLatArray
            .map(item => {
                const { lat, lng } = item ?? {};
                return [lat, lng];
            });

        const totalDistanceInMeter = calculateDistance(coordinatesList)
        const m = await this.newMapService.getMap();
        this.adHocLine = new google.maps.Polyline({
            path: lngLatArray,
            strokeOpacity: 0,
            icons: [{
                icon: this.dottedLineSymbol,
                offset: "0",
                repeat: "20px"
            }],
            geodesic: false,
            strokeColor: color,
            zIndex: 20
        });
        this.adHocLine.setMap(m);
        const bounds = new google.maps.LatLngBounds();
        for (let i = 0; i < lngLatArray.length; i++) {
            bounds.extend(lngLatArray[i]);
        }

        const totalDistanceInKm = (totalDistanceInMeter / 1000).toFixed(2)

        const distanceMarker = new google.maps.Marker({
            position: bounds.getCenter(),
            draggable: false,
            label: { text: this.postFixPipe.transform(this.decimalPipe.transform(totalDistanceInKm, '1.2-2'), ' km' ?? '--'), color: "white", fontSize: '16px', className: 'google-maps-label' },
            icon: ' '
        });

        this.adHockDistanceMarkers.push(distanceMarker)
        this.setMapOnAllAdHockDistanceMarkers(m)
    }


    clearAllDistanceMarkersForConnectedLine() {
        this.setMapOnAllDistanceMarkersForConnectedLine(null);
        this.distanceMarkersForConnectedLine = [];
    }

    setMapOnAllDistanceMarkersForConnectedLine(map: google.maps.Map | null) {
        for (let i = 0; i < this.distanceMarkersForConnectedLine.length; i++) {
            this.distanceMarkersForConnectedLine[i].setMap(map);
        }
    }

    clearAllAdHockDistanceMarkers() {
        this.setMapOnAllAdHockDistanceMarkers(null);
        this.adHockDistanceMarkers = [];
    }

    setMapOnAllAdHockDistanceMarkers(map: google.maps.Map | null) {
        for (let i = 0; i < this.adHockDistanceMarkers.length; i++) {
            this.adHockDistanceMarkers[i].setMap(map);
        }
    }

    private async addAnimatedCircle(latLng: google.maps.LatLng) {
        this.clearCircle();
        const map = await this.newMapService.getMap();

        const options: CustomCircleOptions = {
            color: "#0044C8",
            maxSize: 25,
            minSize: 11
        }

        this.pulsatingDot = new PulsatingDot(map, latLng, options);
        this.pulsatingDot.add();
    }

    clearCircle() {
        this.pulsatingDot?.remove();
        this.pulsatingDot = null;
    }


    private addListeners(layer: google.maps.Data, map: google.maps.Map) {
        this.addPopupListeners(layer, map);
        this.addAzimuthListeners(layer);
        this.addAdhocLineListener(layer);
    }

    private addPopupListeners(layer: google.maps.Data, map: google.maps.Map) {

        const mouseOverListener = layer.addListener("mouseover", (event: GoogleMapsEventInfo<MouseEvent>) => {
            const { feature, latLng } = event ?? {};
            const infoWindowSiteId = this.store.selectSnapshot(SiteAlarmDetailsState.getCurrentSiteId);
            const sameAsCurrentSiteId = feature.getProperty("siteID") === infoWindowSiteId;
            if (sameAsCurrentSiteId) {
                return;
            }

            this.infoWindow.setPosition(latLng);
            this.infoWindow.setContent(feature.getProperty("name"));
            this.infoWindow.open(map);
        });

        const mouseOutListener = layer.addListener("mouseout", () => {
            this.infoWindow.close();
        });

        this.listeners.push(mouseOverListener, mouseOutListener);
    }

    private addAlarmInfoListener(layer: google.maps.Data, map: google.maps.Map) {
        const rightClickListener = layer.addListener("click", (event: GoogleMapsEventInfo<MouseEvent>) => {
            const { feature, latLng } = event ?? {};
            const siteId = feature?.getProperty("siteID");
            if (!siteId) {
                this.store.dispatch(new SiteAlarmDetailActions.FetchFail(`Failed to fetch alarm details. No "siteID" field found in feature.`));
            }
            else {
                this.store.dispatch(new SiteAlarmDetailActions.Fetch(feature?.getProperty("siteID")));
            }

            this.alarmInfoWindow.setPosition(latLng);
            this.alarmInfoWindow.open(map);
            this.infoWindow.close();
        });

        const closeWindowListener = map.addListener("click", () => {
            this.store.dispatch(new SiteAlarmDetailActions.Clear());
            this.alarmInfoWindow.close();
        })

        this.listeners.push(rightClickListener, closeWindowListener);
    }


    setAlarmInfoWindowContent(content: string) {
        this.alarmInfoWindow.setContent(content);
    }

    private addAzimuthListeners(layer: google.maps.Data) {
        const mouseClickListener = layer.addListener("rightclick", (event: GoogleMapsEventInfo<MouseEvent>) => {
            const { feature } = event;
            const siteID: number = feature.getProperty("siteID");
            this.toggleAzimuth(siteID);
        });

        this.listeners.push(mouseClickListener);
    }

    private addAdhocLineListener(layer: google.maps.Data) {
        const mouseClickListener = layer.addListener("click", (event: GoogleMapsEventInfo<MouseEvent>) => {
            const { latLng } = event;
            this.addAdHocLine([{
                lat: latLng.lat(),
                lng: latLng.lng(),
            }, this.pinLatLng], 'gray')
        });

        this.listeners.push(mouseClickListener);
    }

    private toggleAzimuth(siteID: number) {
        const azimuthLayer = this.azimuthLayers?.[siteID];
        if (azimuthLayer) {
            azimuthLayer.layer?.toggleVisibility();
        }
        else {
            this.addMissingAzimuth(siteID);
        }
    }

    private addMissingAzimuth(siteID: number) {
        const feature = this.store.selectSnapshot(AzimuthState.getData(siteID));
        if (!feature) {
            this.store.dispatch(new FetchAzimuthData(siteID));
            return;
        }

        this.addAzimuthLayer(siteID, feature);
    }

    async addAzimuthLayer(siteID: number, feature: GeminiFeature) {
        const map = await this.newMapService.getMap();
        if (!map) {
            return;
        }

        this.azimuthLayers[siteID] = new AzimuthLayer(map, feature);
    }

    hideAllAzimuths() {
        Object.values(this.azimuthLayers)
            .forEach(aziLayer => aziLayer?.layer?.setVisibility(false));
    }

    cleanUpAzimuthLayers() {
        Object.values(this.azimuthLayers)
            .forEach(aziLayer => {
                aziLayer?.layer?.setVisibility(false);
                aziLayer?.cleanUp()
            });
        this.azimuthLayers = {};
    }


    isLayerVisible(layerId: MapLayerId) {
        const layer = this.layers[layerId];
        return DataHandler.isDefined(layer?.getMap());
    }

    toggleSites(layerId: MapLayerId) {
        const layer = this.getLayerById(layerId);
        if (layer) {
            return this.setLayerVisibility(layer, !layer.getMap());
        }
    }

    setVisibility(layerId: MapLayerId, visible: boolean) {
        const layer = this.getLayerById(layerId);
        if (layer) {
            return this.setLayerVisibility(layer, visible);
        }
    }

    getLayerById(layerId: MapLayerId) {
        return this.layers?.[layerId];
    }

    private async setLayerVisibility(layer: google.maps.Data, visible: boolean) {
        if (visible) {
            const m = await this.newMapService.getMap();
            layer.setMap(m);
        }
        else {
            layer.setMap(null);
        }
    }

    setCoverageLayer(coverageLayers: { [x: string]: google.maps.ImageMapType }) {
        this.coverageLayers = coverageLayers;
    }

    removeConnectedLine() {
        this.connectedLine?.setMap(null);
        this.connectedLine = null;
    }

    removeAdHocLine() {
        this.adHocLine?.setMap(null);
        this.adHocLine = null;
    }

    async resetSites() {
        if (!this.layers) {
            return;
        }

        const m = await this.newMapService.getMap();
        Object.keys(this.layers)?.forEach((key) => {
            this.layers?.[key]?.setMap(m);
        });
    }

    resetCoverage() {
        if (!this.coverageLayers) {
            return;
        }

        Object.keys(this.coverageLayers)?.forEach((key) => {
            this.coverageLayers?.[key]?.setOpacity(0);
        });
    }

    clear(hardRefresh = false) {
        this.removeConnectedLine();
        this.clearCircle();
        this.cleanUpAzimuthLayers();
        this.infoWindow.close();

        if (hardRefresh) {
            this.listeners.forEach(listener => listener?.remove());
            this.listeners = [];
        }
    }

    async refreshMap() {
        this.clear();
        this.resetCoverage();
        await this.resetSites();
    }

    ngOnDestroy(): void {
        this.ngDestroy.next();
        this.ngDestroy.complete();
        this.clear(true);
    }
}
