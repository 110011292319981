

/**
 *
 * @param input the input string to compare against.
 * @param arr the array of string values.
 * @returns true if at least one item in the array is included in the input string.
 */
export function stringIncludesArrayItem(input: string, arr: string[]): boolean {
  for (let i = 0; i < arr.length; i++) {
    if (input.includes(arr[i])) return true;
  }
  return false;
}
