import { Component, Input } from '@angular/core';

@Component({
    selector: 'sf-eyeball-counter',
    templateUrl: './eyeball-counter.component.html',
    styleUrls: ['./eyeball-counter.component.scss']
})
export class EyeballCounterComponent {

    private _emails: string[] = [];

    description: string;

    @Input() subject: string;

    @Input() set emails(emails: string[]) {
        if (emails) {
            this._emails = emails;
            this.description = this.createDescription(this.subject, emails);
        }
    }

    get emails() {
        return this._emails;
    }

    private createDescription(subject: string, emails: string[]) {

        let description = `Other people currently viewing this ${subject}:\n`;
        const maxViewers = 5;

        for (const [index, email] of Object.entries(emails)) {
            if (+index > maxViewers - 1) {
                description += `and ${emails.length - maxViewers} more.`;
                return description;
            }

            description += `•${email}\n`;
        }

        return description;
    }

}
