import { Utils } from "src/app/Utils";
import { AssignableTicket } from "../../types/assignable-hex-id.interface";


export class FetchTicketToAssign {
    static readonly type = Utils.Helpers.Type("[Agent Assigned Ticket] Fetch ticket to assign");
}

export class FetchSuccess {
    static readonly type = Utils.Helpers.Type("[Agent Assigned Ticket] Fetch success");
    constructor(public payload: AssignableTicket) { }
}

export class FetchFail {
    static readonly type = Utils.Helpers.Type("[Agent Assigned Ticket] Fetch fail");
    constructor(public error: unknown) { }
}

export class SetLoading {
    static readonly type = Utils.Helpers.Type("[Agent Assigned Ticket] Set Loading");
    constructor(public loading: boolean) { }
}

export class UpdateTicketRequestTime {
    static readonly type = Utils.Helpers.Type("[Agent Assigned Ticket] Updated requested time");
}

export class Clear {
    static readonly type = Utils.Helpers.Type("[Agent Assigned Ticket] Clear");
}