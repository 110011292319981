import { Utils } from "src/app/Utils";
import { GeforceEligibility, GeforceEligibilityRequestPayload } from "../../types/geforce-eligibility.types";


export class FetchEligibility {
    static readonly type = Utils.Helpers.Type("[Geforce Eligibility] Fetch");
    constructor(public payload: GeforceEligibilityRequestPayload) { }
}

export class FetchEligibilitySuccess {
    static readonly type = Utils.Helpers.Type("[Geforce Eligibility] Fetch Success");
    constructor(public payload: GeforceEligibility) { }
}

export class FetchEligibilityFail {
    static readonly type = Utils.Helpers.Type("[Geforce Eligibility] Fetch Fail");
    constructor(public error: unknown) { }
}


export class Clear {
    static readonly type = Utils.Helpers.Type("[Geforce Eligibility] Clear");
}
