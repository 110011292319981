import { Utils } from "src/app/Utils";

export class ToggleSFmapExpanded {
  static readonly type = Utils.Helpers.Type('[SFMap: Toggle] Toggle if the map is expanded.');
}

export class SetSFMapExpanded {
  static readonly type = Utils.Helpers.Type('[SFMap: Set] Set if the map is expanded.');
  constructor(public expanded: boolean) { }
}

export class SetSFMapStreetView {
  static readonly type = Utils.Helpers.Type('[SFMap: Set] Set if the map is in street view.');
  constructor(public bool: boolean) { }
}

export class ResetSFCoverageMapDefaults {
  static readonly type = Utils.Helpers.Type('[SFMap: Reset] Reset to map defaults.');
}

