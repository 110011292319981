import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GeforceEligibilityRequestPayload, GeforceEligibilityResponsePayload } from '../types/geforce-eligibility.types';
import { environment } from 'src/environments/environment';
import { DEFAULT_HTTP_OPTIONS } from 'src/app/constants';


@Injectable({
    providedIn: 'root'
})
export class GeforceWhitelistService {

    constructor(private http: HttpClient) { }

    getGeforceEligibility(payload: GeforceEligibilityRequestPayload) {

        const url = `${environment.main_proxy}/whitelist-service/whitelist/check/nvidia/${payload?.email}`;

        // return of<GeforceEligibilityResponsePayload>({result: {is_whitelisted: true}}) //TODO: remove after testing

        return this.http.get<GeforceEligibilityResponsePayload>(url, DEFAULT_HTTP_OPTIONS);
    }
}
