import { AfterViewInit, Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { FormHelper } from '../../helpers/form.helper';
import { validationMessages } from '../../pipes';
import { FormDateFilter } from '../../../dynamic-forms/partials/form-datepicker/form-date-filter.interface';

@Component({
  selector: 'sf-date-picker',
  templateUrl: './snowflake-date-picker.component.html',
  styleUrls: ['./snowflake-date-picker.component.scss']
})
export class SnowflakeDatePickerComponent extends FormHelper implements AfterViewInit {
  @Input() public control: FormControl;
  @Input() public messages = validationMessages;
  @Input() public label: string;
  @Input() public showInput = true;
  @Input() public disabled = false;

  @Input() dateFilter: FormDateFilter = () => true;

  constructor() {
    super();
  }

  ngAfterViewInit() {
    if (this.control) {
      this.InitFormControl(this.control);
    }
  }

  public formatLabel(l: string) {
    return l.replace(' ', '_');
  }
}
