import { Injectable } from '@angular/core';
import { Navigate } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';
import { AGENT_VIEW_ROUTE, MILISECONDS_IN_MINUTE } from 'src/app/constants';
import { ToggleModalByName } from 'src/app/core/store/actions/modal.actions';
import { SipPhoneService } from 'src/app/sip-phone/services/sip-phone.service';
import { ActionScheduler } from '../models/action-scheduler';
import { SetLoggedOff } from '../components/agent/store/actions/agent-status-actions/agent-status.actions';
import { AgentState } from '../components/agent/store/state/agent.state';

const NINE_MINUTES = MILISECONDS_IN_MINUTE * 9;
const ONE_MINUTE = MILISECONDS_IN_MINUTE;
const FIRST_WARNING = "You are about to be logged off due to inactivity!";
const SECOND_WARNING = "You are now logged off due to inactivity!";

@Injectable({
  providedIn: 'root'
})
export class MainTimeoutService {

  private _actionScheduler: ActionScheduler;

  constructor(private store: Store,
    private sipService: SipPhoneService
  ) {
    this.createScheduler();
  }

  private createScheduler() {

    this._actionScheduler = new ActionScheduler(
      { baseFilter: () => this.store.selectSnapshot(AgentState.getStatus) === 'available' && !this.sipService.activeSession }
    )
      .addAction({
        timeOut: NINE_MINUTES,
        action: () =>
          this.store.dispatch(new ToggleModalByName<string>('timeout_modal', true, FIRST_WARNING))
      })

      .addAction({
        timeOut: ONE_MINUTE,
        action: () => {
          this.onSetLoggedOff();
          this.store.dispatch([
            new ToggleModalByName<string>('timeout_modal', true, SECOND_WARNING),
            new Navigate([AGENT_VIEW_ROUTE])
          ]);
        }
      });

  }

  startTimer() {
    this._actionScheduler.startTimer();
  }

  resetTimer() {
    this.store.dispatch(new ToggleModalByName('timeout_modal', false));
    this._actionScheduler.resetTimer();
  }

  onSetLoggedOff() {
    this.store.dispatch(new SetLoggedOff());
  }
}
