import { Utils } from "src/app/Utils";
import { SFValidators } from "../functions/sf-validators";

export interface ISuccessResult<T, E = any> {
    data: T;
    error: E;
}

export class SuccessResult<T> {

    private _error: string = null;
    private _data: T = null;

    constructor(data: T = null) {
        this._data = data;
    }

    setData(data: T) {
        this._data = data;
        return this;
    }

    setError(error: unknown) {
        this._error = Utils.Helpers.findError(error, "Unknown Error");
        return this;
    }

    hasData() {
        return SFValidators.isDefined(this._data);
    }

    hasError() {
        return SFValidators.isDefined(this._error);
    }

    get error(): string | null {
        return this._error;
    }

    get data(): T | null {
        return this._data;
    }


}