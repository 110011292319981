import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, ofActionSuccessful } from '@ngxs/store';
import { Subject } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { NavigationService } from '../navigation/navigation.service';
import { RouteInfo } from '../navigation/route-info.interface';
import { UpdateAgentRoles } from '../shared/components/agent/store/actions/agent.actions';
import { takeUntil } from 'rxjs/operators';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {

  agentCardSections: RouteInfo[] = [];
  loginStatus = false;
  headerMessage: string;

  private readonly ngDestroy$ = new Subject<void>();

  constructor(
    private authService: AuthService,
    private router: Router,
    private navigationService: NavigationService,
    private actions$: Actions
  ) { }


  ngOnInit(): void {
    this.loginStatus = this.authService.setLoginStatus();
    if (!this.loginStatus) {
      this.router.navigate(['/authentication']);
    }

    this.setAgentCardSections();

    this.actions$
      .pipe(
        ofActionSuccessful(UpdateAgentRoles),
        takeUntil(this.ngDestroy$)
      )
      .subscribe({
        next: () => this.setAgentCardSections()
      });
  }

  private setAgentCardSections() {
    this.agentCardSections = this.navigationService.getRouterCards("home");
  }

  ngOnDestroy(): void {
    this.ngDestroy$.next(null);
    this.ngDestroy$.complete();
  }

}
