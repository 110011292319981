<div class="wrapper">

    <custom-icon-button class="expand-button" size="2rem" (click)="toggleRolledUp()">
        {{ rolledUp ? "keyboard_arrow_down": "keyboard_arrow_up" }}
    </custom-icon-button>

    <div class="rolled-up-wrapper" [class.hidden]="!rolledUp">
        <app-blank-card>
            <div class="rollup-header">
                <div>
                    {{ header }}
                </div>
            </div>
        </app-blank-card>
    </div>

    <div [class.hidden]="rolledUp">
        <ng-content select="[content]"></ng-content>
    </div>
</div>