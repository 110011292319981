import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CurrentlyConnectSiteResponse } from 'src/app/customer-info-summary-page/store/interfaces/conected-site-response.interface';
import { CustomerAddress } from 'src/app/shared/interfaces/smartsub-data.interface';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CoverageService {

  constructor(private http: HttpClient) { }

  getformattedAddress(cAddress: CustomerAddress) {
    if (!cAddress) {
      return;
    }
    return `${cAddress?.street_number} ${cAddress?.street_name} ${cAddress?.city} ${cAddress?.province} ${cAddress?.postal_code}`;
  }

  getCurrentConnectedSite(msisdn: string) {
    const url = `${environment.smartSubApi}/data/currently_connected_site/${msisdn}`;
    return this.http.get<CurrentlyConnectSiteResponse>(url);
  }

}
