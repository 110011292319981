import { Injectable } from '@angular/core';
import { State, Action, StateContext, Selector } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { DataLoading, getDataLoadingDefaultValues } from 'src/app/shared/interfaces/data-loading.interface';
import { SetIntervalType } from 'src/app/shared/types/set-interval.type';
import { DataLoadingHelper } from 'src/app/Utils/helpers';
import { LoadsheddingService } from '../../loadshedding.service';
import { CloseCurrentLoadsheddingReport, FetchCurrentLoadsheddingReport, FetchCurrentLoadsheddingReportFail, FetchCurrentLoadsheddingReportSuccess, SetCurrentLoadsheddingReportError, StartLoadsheddingReportRefetchLoop } from '../actions/current-loadshedding-report.actions';
import { LoadsheddingReport } from '../interfaces/loadshedding-report.interface';

interface CurrentLoadsheddingReportStateModel extends DataLoading<LoadsheddingReport> {
  is_open: boolean;
  refetch_started: boolean;
  current_interval: SetIntervalType
}
@State<CurrentLoadsheddingReportStateModel>({
  name: 'current_loadshedding_report',
  defaults: {
    ...getDataLoadingDefaultValues(),
    is_open: false,
    refetch_started: false,
    current_interval: null
  }


})
@Injectable()
export class CurrentLoadsheddingReportState {

  @Selector()
  static isLoading(state: CurrentLoadsheddingReportStateModel) { return state.loading }

  @Selector()
  static isLoaded(state: CurrentLoadsheddingReportStateModel) { return state.loaded }

  @Selector()
  static getData(state: CurrentLoadsheddingReportStateModel) { return state.data }

  @Selector()
  static getError(state: CurrentLoadsheddingReportStateModel) { return state.error }

  @Selector()
  static isOpen(state: CurrentLoadsheddingReportStateModel) { return state.is_open }


  constructor(private _loadsheddingService: LoadsheddingService) {
  }


  @Action(FetchCurrentLoadsheddingReport)
  FetchCurrentLoadsheddingReport(ctx: StateContext<CurrentLoadsheddingReportStateModel>) {

    return this._loadsheddingService.getLoadsheddingReport()
      .pipe(tap({
        next: (resp) => {
          if (resp.status === 200) {
            ctx.dispatch(new FetchCurrentLoadsheddingReportSuccess(resp.body));
            ctx.dispatch(new StartLoadsheddingReportRefetchLoop());
          }
          else {
            ctx.dispatch(new SetCurrentLoadsheddingReportError(`Failed to fetch loadshedding report`));
          }
        },
        error: (e) => {
          ctx.dispatch(new FetchCurrentLoadsheddingReportFail(e));
        }
      }));

  }

  @Action(StartLoadsheddingReportRefetchLoop)
  startLoadsheddingReportRefetchLoop(ctx: StateContext<CurrentLoadsheddingReportStateModel>) {
    const state = ctx.getState();
    if (state.refetch_started) {
      return;
    }
    ctx.patchState({ refetch_started: true });

    const current_interval = setInterval(() => {
      if (!state.is_open && current_interval) {
        clearInterval(current_interval);
        return;
      }
      ctx.dispatch(new FetchCurrentLoadsheddingReport());
    }, 300_000);
    ctx.patchState({ current_interval });
  }

  @Action(FetchCurrentLoadsheddingReportSuccess)
  fetchCurrentLoadsheddingReportSuccess(ctx: StateContext<CurrentLoadsheddingReportStateModel>, action: FetchCurrentLoadsheddingReportSuccess) {
    DataLoadingHelper.setData(ctx, action.payload);
    ctx.patchState({
      is_open: true
    })
  }


  @Action(FetchCurrentLoadsheddingReportFail)
  fetchCurrentLoadsheddingReportFail(ctx: StateContext<CurrentLoadsheddingReportStateModel>, action: FetchCurrentLoadsheddingReportFail) {
    const errorMessage = action.error?.error;
    ctx.dispatch(new SetCurrentLoadsheddingReportError(errorMessage));
  }

  @Action(SetCurrentLoadsheddingReportError)
  setCurrentLoadsheddingReportError(ctx: StateContext<CurrentLoadsheddingReportStateModel>, action: SetCurrentLoadsheddingReportError) {
    DataLoadingHelper.setError(ctx, action.errorMessage);
  }


  @Action(CloseCurrentLoadsheddingReport)
  clearCurrentLoadsheddingReport(ctx: StateContext<CurrentLoadsheddingReportStateModel>) {
    const state = ctx.getState();
    if (state.current_interval) {
      clearInterval(state.current_interval);
    }
    ctx.patchState({
      is_open: false,
      current_interval: null
    })
  }

}
