

export function getCorrectProductName(inputName: string) {
  const productMap = {
    "4G Unlimited Low": "4G 24/7 unlimited",
    "4G Unlimited Phone": "unlimited 4G for phones",
    "PAfterYU_UOP": "19 hours unlimited off-peak",
    "5G Home X": "unlimited home 5G premium",
    "5G Home Pro": "unlimited home 5G premium",
    "5G Home Medium": "unlimited home 5G standard",
    "5G Home Basic": "unlimited home 5G basic",
    "5G Standard - Chip in": "unlimited home 5G standard chip in",
    "PrePaid 4G": "4G prepaid gigs",
    "4G Staff": "unlimited 4G staff",
    "5G Home Staff": "5G home staff",
    "4G_SA_GENCON_AU": "4G stand alone rainMobile",
    "standalone 4G unlMIN 2GB 100SMS subscription AU": "rain mobile 60MIN 2GB 100SMS subscription AU",
    "T20 Voucher Discount": "Voucher Discount",
    "standalone 4G generic consumer SIM AU" : "rain mobile sim"
  }

  const correctName = productMap?.[inputName];
  return correctName ? correctName : inputName;
}
