import { AgentGroupType, AGENT_GROUP_TYPES } from 'src/app/shared/interfaces/agent-group-type.type';

export function getAgentGroupType(agentTeam: string): AgentGroupType {
  for (const gType of AGENT_GROUP_TYPES) {
    if (agentTeam.includes(gType)) {
      return gType;
    }
  }
  return 'NORMAL';
}
