import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { Navigate } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';
import { Utils } from 'src/app/Utils';
import { SFNotification } from '../notifiactions/interfaces/notifiactions.interface';
import { AddNotification } from '../store/actions/notifications.actions';
import { CoreState } from '../store/state/core.state';
import { PersistentStorageState } from '../store/state/persistent-storage/persistent-storage.state';


@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  constructor(private store: Store) { }

  canActivate(route: ActivatedRouteSnapshot) {
    const agentRoles = this.store.selectSnapshot(CoreState.getAgentRoles);


    if (agentRoles?.length > 0) {
      return this.checkRoles(agentRoles, route?.data?.roles, route?.data?.unallowed_roles);
    }

    const roles = this.store.selectSnapshot(PersistentStorageState.get("agent-roles")) ?? [];
    return this.checkRoles(roles, route?.data?.roles, route?.data.unallowed_roles);
  }

  private goToAuthPage() {
    this.store.dispatch(new Navigate(["/authentication"]));
    return false;
  }

  private checkRoles(agentRoles: string[],allowedRoles:string[], unallowedRoles:string[]=[]) {
    if (!agentRoles?.length) {
      this.goToAuthPage();
    }


    if (!allowedRoles) {
      //Return true if the route is not restricted by any role
      return true;
    }

    const hasAccess = Utils.SfHelpers.hasAccess(agentRoles, allowedRoles, unallowedRoles);
    if (hasAccess) {
      return true;
    }
    else {
      this.store.dispatch(new Navigate(["/home"]));
      this.dispatchWarning();
      return false;
    }
  }

  private dispatchWarning() {
    const notify: SFNotification = {
      title: 'WARNING!',
      message: "You do not have access to this page.",
      type: 2,
    };
    this.store.dispatch(new AddNotification(notify));
  }

}
