import { Injectable } from '@angular/core';
import localforage from 'localforage';
import { from, Observable } from 'rxjs';
import { IndexedDBItems, IndexedDBKey } from './indexed-db-key.type';

@Injectable({
  providedIn: 'root'
})
export class IndexedDBService {

  constructor() {
    localforage.config({
      name: 'snowball'
    });
  }

  get<Key extends keyof IndexedDBItems>(key: Key) {
    return from(localforage.getItem<IndexedDBItems[Key]>(key));
  }

  set<Key extends keyof IndexedDBItems>(key: Key, value: IndexedDBItems[Key], observe = false):
    typeof observe extends true ? void : Observable<IndexedDBItems[Key]> {
    const setItem = () => localforage.setItem(key, value);
    if (observe) {
      return from(setItem())
    }
    setItem();
  }

  remove(key: IndexedDBKey) {
    return from(localforage.removeItem(key));
  }

  clear() {
    return localforage.clear();
  }

  listKeys() {
    return localforage.keys();
  }

}
