import { Injectable } from '@angular/core';
import { from, Subject } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { Utils } from 'src/app/Utils';
import { CustomResolveHandler } from '../models/custom-resolver/custom-resolve-handler.model';
import { ModalOptions } from './assets/modal-options.interface';
import { Dictionary } from '../interfaces/dictionary.interface';

@Injectable({
    providedIn: 'root',
})
export class ModalWindowService {
    modalWindowSub = new Subject<ModalOptions>();
    spinnerSub = new Subject<boolean>();

    private sendModalOptions(options: ModalOptions) {
        this.modalWindowSub.next(options);
    }

    toggleLoadingSpinner(active: boolean) {
        this.spinnerSub.next(active);
    }

    showInfo(content: string) {
        this.sendModalOptions({
            header: 'attention',
            content: content,
            messageType: 'alert',
        });
    }

    showError(content: string, error?: string) {
        this.sendModalOptions({
            header: 'failure',
            content: content,
            messageType: 'alert',
            error: error ? Utils.Helpers.findError(error, '') : null,
        });
    }

    showSuccess(content: string) {
        this.sendModalOptions({
            header: 'success',
            content: content,
            messageType: 'alert',
        });
    }

    async showConfirmation(content: string, options?: Pick<ModalOptions, "confirmMessage" | "declineMessage">,): Promise<boolean> {
        const resolveHandler = new CustomResolveHandler();

        let modalOptions: ModalOptions = {
            header: 'confirm',
            content: content,
            messageType: 'confirm',
            resolver: resolveHandler.resolve,
        };

        if (options) {
            modalOptions = { ...modalOptions, ...options };
        }

        this.sendModalOptions(modalOptions);
        return resolveHandler.confirmationResult();
    }

    showConfirmation$(content: string, options?: Pick<ModalOptions, "confirmMessage" | "declineMessage">) {
        return from(this.showConfirmation(content, options))
            .pipe(
                take(1),
                filter(confirm => confirm)
            );
    }

    showActionModal(
        content: string,
        actions: Dictionary[],
        options?: Pick<ModalOptions, "confirmMessage" | "declineMessage" | "error" | "header">,
    ) {

        const defaultOptions: ModalOptions = {
            content,
            header: 'attention',
            messageType: "action",
            actions
        };

        const finalOptions = Utils.Mappers.mapOptions(defaultOptions, options);
        this.sendModalOptions(finalOptions);
    }


}
