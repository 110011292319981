import { LatLngLiteral } from "@google/maps";
import { createSelector, Selector } from "@ngxs/store";
import { CurrentlyConnectedSiteSelectors } from "src/app/customer-data-components/sim-details/store/selectors/currently-connected-site.selectors";
import { GeoFunctions } from "src/app/shared/functions/geo-functions";
import { SFValidators } from "src/app/shared/functions/sf-validators";
import { CoverageDataState } from "../state/coverage-data.state";
import { Units as TurfUnit, } from "@turf/turf";
import { getDistanceColor } from "src/app/shared/components/geocoder/assets/distance-color-map";
import { CurrentDeviceState } from "../state/current-device.state";
import { PossibleGPSCoordinates } from "../interfaces/gps-coordinates.interface";


interface AddressToSiteLatLng {
    customerLatLng: LatLngLiteral;
    siteLatLng: LatLngLiteral;
}

export interface ConnectedLatLngAndDistance {
    latLngArray: LatLngLiteral[];
    distance: number;
}

export class CustomerGeoSelectors {

    @Selector([
        CoverageDataState.customerAddressLatLng,
        CurrentlyConnectedSiteSelectors.currentFeatureLatLng
    ])
    static getAddressToSiteLatLng(customerLatLng: LatLngLiteral, siteLatLng: LatLngLiteral): AddressToSiteLatLng {
        return {
            customerLatLng,
            siteLatLng
        }
    }

    @Selector([
        CustomerGeoSelectors.getAddressToSiteLatLng,
        CoverageDataState.isDeviceInitialized,
    ])
    static hasConnectedLatLng(addressToSiteLatLng: AddressToSiteLatLng, isDeviceInitialized: boolean) {
        const { customerLatLng, siteLatLng } = addressToSiteLatLng ?? {};

        return isDeviceInitialized && SFValidators.isDefined(customerLatLng) && SFValidators.isDefined(siteLatLng);
    }

    @Selector([
        CustomerGeoSelectors.hasConnectedLatLng,
        CustomerGeoSelectors.getAddressToSiteLatLng,
        CurrentDeviceState.getDeviceCoordinates
    ])
    static getConnectedLatLngArray(hasConnectedLatLng: boolean, addressToSiteLatLng: AddressToSiteLatLng, deviceCoordinates: PossibleGPSCoordinates): LatLngLiteral[] | null {
        if (!hasConnectedLatLng) {
            return null;
        }

        const { lat: deviceLat, lng: deviceLng } = deviceCoordinates ?? {};

        if (!deviceLat || !deviceLng) {
            return Object.values(addressToSiteLatLng);
        }

        return [deviceCoordinates, addressToSiteLatLng.siteLatLng];
    }

    static getConnectedLineDistance(unit: TurfUnit) {
        return createSelector(
            [CustomerGeoSelectors.getConnectedLatLngArray],
            (latLngArray: LatLngLiteral[]) => {
                if (!latLngArray?.length) {
                    return null;
                }

                const coordinatesList = latLngArray
                    .map(item => {
                        const { lat, lng } = item ?? {};
                        return [lat, lng];
                    });

                return GeoFunctions.calculateDistance(coordinatesList, unit);
            }
        );
    }

    @Selector([CustomerGeoSelectors.getConnectedLineDistance("meters")])
    static getConnectedLineDistanceInKm(distanceInMeters: number): number {
        if (SFValidators.isNotDefined(distanceInMeters)) {
            return null;
        }

        return distanceInMeters / 1000;
    }

    @Selector([CustomerGeoSelectors.getConnectedLineDistance("meters")])
    static getConnectedLineColor(distanceInMeters: number): string {
        return getDistanceColor(distanceInMeters, false);
    }

    @Selector([
        CustomerGeoSelectors.hasConnectedLatLng,
        CustomerGeoSelectors.getConnectedLatLngArray,
        CustomerGeoSelectors.getConnectedLineDistance("meters")
    ])
    static getConnectedLatLngArrayAndDistance(
        hasConnectedLatLng: boolean,
        latLngArray: LatLngLiteral[],
        distance: number,
    ): ConnectedLatLngAndDistance | null {

        if (!hasConnectedLatLng) {
            return null;
        }

        return {
            latLngArray,
            distance
        }
    }


}

