import { Subject } from 'rxjs';
import * as XLSX from 'xlsx'
import { trimObjKeys } from './trim-object-keys.helper';

export function convertCSVtoJson<T = any>(file: File): Subject<T[]> {

  const resultSubject = new Subject<T[]>();
  const reader = new FileReader();

  reader.onload = (event) => {
    const result = event.target.result;
    const workbook = XLSX.read(result, { type: 'binary' });
    const sheets = workbook.Sheets;
    const jsonData = Object.keys(sheets)
      .map(key => XLSX.utils.sheet_to_json<T>(sheets[key]));

    if (!jsonData?.length) {
      resultSubject.next([]);
    }
    else {
      resultSubject.next(trimObjKeys(jsonData[0]))
    }
  }

  setTimeout(() => {
    reader.readAsBinaryString(file);
  }, 50);

  return resultSubject;
}
