import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngxs/store';
import { ToggleModalByName } from 'src/app/core/store/actions/modal.actions';
import { MultiSelectMap } from 'src/app/shared/elements/rain-forms/multi-level-select/assets/multi-select-map.class';
import { createSFselectOptions } from 'src/app/shared/functions/create-sf-select-options.function';
import { PostTicketModalOptions } from 'src/app/shared/services/post-ticket/post-ticket-modal-options.model';
import { PostTicketService } from 'src/app/shared/services/post-ticket/post-ticket.service';
import { InteractionTicketsState } from '../../store/state/interaction-tickets.state';
import { CSATFormOptions } from './assets/csat-from-options';
import { ChatJsonNote } from '../components/chat-json-note/assets/chat-json-note.interface';

type CSATSelectKey = "outcome" | "change_rating" | "new_rating";


@Component({
    selector: 'sf-csat-post-close-modal',
    templateUrl: './csat-post-close-modal.component.html',
    styleUrls: ['./csat-post-close-modal.component.scss']
})
export class CsatPostCloseModalComponent implements OnInit, OnDestroy {

    modalOptions: PostTicketModalOptions;

    multiOptions = {
        bad_rating: new MultiSelectMap(CSATFormOptions.BAD_RATING_OPTIONS, { isOpen: true }),
        solutions: new MultiSelectMap(CSATFormOptions.SOLUTIONS_OFFERED, { isOpen: true }),
    }

    singleOptions = {
        outcome: createSFselectOptions(CSATFormOptions.OUTCOME_OPTIONS as unknown as string[]),
        change_rating: createSFselectOptions(CSATFormOptions.CHANGE_OPTIONS),
        new_rating: createSFselectOptions(CSATFormOptions.NEW_RATING)
    }

    showNewRatingOptions = false;
    isMultiselectValid = false;

    noAnswerOptionSelected = false;
    pendingOptionSelected = false;

    form: FormGroup;

    constructor(private fb: FormBuilder,
        private store: Store,
        private postTicketService: PostTicketService) {
    }


    ngOnInit(): void {
        this.modalOptions = new PostTicketModalOptions();

        this.form = this.fb.group({
            outcome: this.fb.control(null, [Validators.required]),
            change_rating: this.fb.control(null, [Validators.required]),
            new_rating: this.fb.control(null),
        });

    }

    onMultiOptionSelected() {
        this.noAnswerOptionSelected = this.noAnswerMultiSelectSelected();
        if (this.selectedRatingError()) {
            this.isMultiselectValid = true;
        }
        else {
            this.isMultiselectValid = this.checkIfMultiOptionSelected();
        }
    }

    private selectedRatingError() {
        const multiSelect = this.multiOptions.bad_rating["reason for bad rating"];
        const option = multiSelect.selectOptions.find(o => o?.label === CSATFormOptions.RATING_ERROR);
        return option?.selected;
    }

    private noAnswerMultiSelectSelected() {
        const multiSelect = this.multiOptions.bad_rating["reason for bad rating"];
        const option = multiSelect.selectOptions.find(o => o?.label === CSATFormOptions.NO_ANSWER_EMAIL_SENT);
        return option?.selected;
    }

    private removeValidators(keys: CSATSelectKey[]) {
        keys.forEach(key => {
            const control = this.form.get(key);
            control.removeValidators([Validators.required]);
            control.patchValue(null);
        })
    }

    private checkIfMultiOptionSelected() {
        for (const value of Object.values(this.multiOptions)) {
            const isSelected = MultiSelectMap.isAnyOptionSelected(value);
            if (!isSelected) {
                return false;
            }
        }
        return true;
    }

    onSelect(value: string, controlName: CSATSelectKey) {
        this.form.get(controlName).patchValue(value);

        if (controlName === "outcome") {
            this.updateOutcomeValidator(value as CSATFormOptions.OutcomeOptions);
        }
        else if (controlName === "change_rating") {
            this.updateRatingValidator(value);
        }
    }

    private updateOutcomeValidator(value: CSATFormOptions.OutcomeOptions) {
        this.pendingOptionSelected = value === CSATFormOptions.PENDING_CUSTOMER_FEEDBACK;
    }

    private updateRatingValidator(value: string) {
        const newRatingControl = this.form.get("new_rating");

        if (value === "yes") {
            newRatingControl.addValidators([Validators.required]);
            this.showNewRatingOptions = true;
        }
        else {
            newRatingControl.removeValidators([Validators.required]);
            this.showNewRatingOptions = false;
        }
        newRatingControl.patchValue(null);
    }

    onSend() {
        const payload: ChatJsonNote = {
            message: "CSAT post close modal form",
            detail: this.getData(),
            type: "csat_form"
        }

        const hexId = this.store.selectSnapshot(InteractionTicketsState.getSelectedHexId);

        this.postTicketService.sendPostTicketPayload(
            hexId, payload, this.modalOptions, this.onClose.bind(this)
        );
    }

    private getData() {
        const payload = {};
        payload["bad_rating_reasons"] = this.multiOptions.bad_rating["reason for bad rating"].selectedValues;
        payload["solutions_offered"] = this.multiOptions.solutions["solutions offered"].selectedValues;

        return { ...payload, ...this.form.getRawValue() };
    }

    onClose() {
        this.store.dispatch(new ToggleModalByName("csat_close_modal", false));
    }

    ngOnDestroy(): void {
        this.onClose();
    }

}

