import { NumberFunctions } from "src/app/shared/functions/number-functions";
import { RainAppResponse } from "../../assets/rain-app-response.interface";
import { NWDAFConnectedSite } from "../interfaces/nwdaf-connected-site.interface";
import { SiteConnectionData } from "../interfaces/site-connection-data.interface";


export const aggregateCurrentlyConnectedData =
    (loaded: boolean, connectedSiteData: NWDAFConnectedSite, rainAppResponse: RainAppResponse): SiteConnectionData => {
        if (!loaded) {
            return null;
        }

        const { cgi_nb_id, cgi_cell_id, online, site_id } = connectedSiteData ?? {};
        if (cgi_nb_id) {
            return {
                cellId: cgi_cell_id,
                enodeBId: cgi_nb_id,
                liveConnection: online,
                siteId: site_id
            }
        }

        const { cell, enodebid, siteid } = rainAppResponse?.initialsite ?? {};
        if (enodebid) {
            return {
                cellId: NumberFunctions.convertToNumElseNull(cell),
                enodeBId: NumberFunctions.convertToNumElseNull(enodebid),
                liveConnection: false,
                siteId: NumberFunctions.convertToNumElseNull(siteid)
            }
        }

        return null;
    }