import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { AuthRoleSetterComponent } from './auth-role-setter/auth-role-setter.component';
import { FeatureFlagSelectorModalComponent } from './feature-flag/feature-flag-selector-modal/feature-flag-selector-modal.component';
import { NgxsModule } from '@ngxs/store';
import { FeatureFlagState } from './feature-flag/store/state/feature-flag.state';
import { FeatureOptionsState } from './feature-flag/store/state/feature-options.state';


const declarations = [
  AuthRoleSetterComponent,
  FeatureFlagSelectorModalComponent,
];

@NgModule({
  declarations: [
    ...declarations
  ],
  imports: [
    CommonModule,
    SharedModule,
    NgxsModule.forFeature([
      FeatureFlagState,
      FeatureOptionsState
    ]),
  ],
  exports: [...declarations]
})
export class ProfileModule { }
