<div
    class="map-wrapper"
    *ngIf="{ 
        coordinates: coordinates$  | async,
        loaded: loaded$ | async
    } as viewModel"
>
    <div
        class="map-lockup"
        *ngIf="viewModel.loaded || canSkipAddressCheck"
    >
        <sf-map
            [longitude]="viewModel?.coordinates?.lng"
            [latitude]="viewModel?.coordinates?.lat"
            [topCenterFullscreenControlId]="topCenterFullscreenControlId"
            [showMarker]="false"
        ></sf-map>
    </div>
    <div id="dummy"></div>
</div>