import { Component } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { ToggleModalByName } from 'src/app/core/store/actions/modal.actions';
import { FAQ_ScenariosState } from '../store/state/faq-scenarios.state';

@Component({
  selector: 'sf-faqs-modal',
  templateUrl: './faqs-modal.component.html',
  styleUrls: ['./faqs-modal.component.scss']
})
export class FAQsModalComponent {
  @Select(FAQ_ScenariosState.getAnswer) selectedAnswer$: Observable<string>;
  @Select(FAQ_ScenariosState.getQuestion)
  selectedQuestion$: Observable<string>;

  constructor(private store: Store) { }

  onClose() {
    this.store.dispatch(
      new ToggleModalByName('faq_scenarios_modal', false),
    );
  }

  ngOnDestroy(): void {
    this.store.dispatch(
      new ToggleModalByName('faq_scenarios_modal', false),
    );
  }
}
