import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, } from '@ngxs/store';
import { Subject } from 'rxjs';
import { take, takeUntil, tap } from 'rxjs/operators';
import { AlarmService } from 'src/app/map/services/alarm-service/alarm-service.service';
import { FilterFunctions } from 'src/app/shared/functions/filter';
import { DataLoading, getDataLoadingDefaultValues } from 'src/app/shared/interfaces/data-loading.interface';
import { Utils } from 'src/app/Utils';
import { DataLoadingHelper } from 'src/app/Utils/helpers';
import { Fetch, FetchSuccess, FetchFail, Clear } from "../actions/site-alarm-detail-actions";
import { SiteAlarmDetails } from '../interfaces/site-alarm-details.interface';


const getDefaults = (): SiteAlarmDetailsStateModel => {
    return {
        currentSiteId: null,
        ...getDataLoadingDefaultValues()
    }
}

interface SiteAlarmDetailsStateModel extends DataLoading<SiteAlarmDetails[]> {
    currentSiteId: number;
}
@State<SiteAlarmDetailsStateModel>({
    name: 'sf_site_alarm_details_state',
    defaults: getDefaults()
})
@Injectable()
export class SiteAlarmDetailsState {

    @Selector()
    static getState(state: SiteAlarmDetailsStateModel) { return state }

    @Selector()
    static isLoading(state: SiteAlarmDetailsStateModel) { return state.loading }

    @Selector()
    static getCurrentSiteId(state: SiteAlarmDetailsStateModel) { return state.currentSiteId }

    @Selector()
    static isLoaded(state: SiteAlarmDetailsStateModel) { return state.loaded }

    @Selector()
    static getData(state: SiteAlarmDetailsStateModel) { return state.data }

    @Selector()
    static getError(state: SiteAlarmDetailsStateModel) { return state.error }

    @Selector()
    static getHighestPrioAlarm(state: SiteAlarmDetailsStateModel) {
        return Utils.Functional.pipe(
            state.data,
            (alarms: SiteAlarmDetails[]) => FilterFunctions.findOnlyHighestPrio(
                alarms ?? [], alarmFeature => alarmFeature?.severity, ["CRITICAL", "MAJOR", "MINOR", "WARNING", "INFORMATION"]
            ),
            (alarms: SiteAlarmDetails[]) => Utils.Helpers.SortBy(alarms, "alarm_raised_time", "desc")[0]
        );
    }

    private readonly _cancelRequest$ = new Subject<null>();

    constructor(private alarmService: AlarmService) {
    }

    @Action(Fetch)
    Fetch(ctx: StateContext<SiteAlarmDetailsStateModel>, action: Fetch) {
        const { siteId } = action;

        ctx.patchState({
            currentSiteId: siteId,
            loading: true
        });

        return this.alarmService.getAlarmDetailsBySiteId(siteId)
            .pipe(

                tap({
                    next: res => {
                        if (!res?.data?.length) {
                            return ctx.dispatch(new FetchFail("Load shedding"));
                        }
                        return ctx.dispatch(new FetchSuccess(res.data));
                    },
                    error: (e: unknown) => ctx.dispatch(new FetchFail(e))
                }),
                takeUntil(this._cancelRequest$.pipe(take(1)))
            );
    }


    @Action(FetchSuccess)
    FetchSuccess(ctx: StateContext<SiteAlarmDetailsStateModel>, action: FetchSuccess) {
        const { payload } = action;
        DataLoadingHelper.setData(ctx, payload);
    }


    @Action(FetchFail)
    FetchFail(ctx: StateContext<SiteAlarmDetailsStateModel>, action: FetchFail) {
        const error = Utils.Helpers.findError(action.error, '');
        DataLoadingHelper.setError(ctx, error);
    }

    @Action(Clear)
    Clear(ctx: StateContext<SiteAlarmDetailsStateModel>) {
        this._cancelRequest$.next(null);
        ctx.setState(getDefaults());
    }

}   