import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { RefreshTicketActions } from 'src/app/interactions/store/actions/refresh-ticket-actions';
import { environment } from 'src/environments/environment';
import { ApiControllerService } from '../../services/api-service/api-controller.service';
import { ApiOptions } from '../../services/api-service/api-response.interface';
import { CamundaAction } from './assets/camunda-action.type';


@Injectable({
  providedIn: 'root'
})
export class CamundaService {

  constructor(
    private apiService: ApiControllerService,
    private _http: HttpClient,
    private store: Store
  ) { }

  async sendPayload(payload: any, action: CamundaAction, hexId: string) {
    const endpoint = `${environment.snowflake_api_url}/ticket/camunda/${action}`;

    const httpOptions: ApiOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    const response = await this.apiService.post(endpoint, httpOptions, payload);
    if ([200, 201, 204].includes(response?.status)) {
      this.store.dispatch(new RefreshTicketActions.RefreshTicket(hexId));
    }
    return response;
  }

  sendPayloadV2<T = any, R = any>(payload: T, action: CamundaAction): Observable<R> {
    const endpoint = `${environment.snowflake_api_url}/ticket/camunda/${action}`;

    const httpOptions: ApiOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this._http.post<R>(endpoint, payload, { ...httpOptions });
  }
}

