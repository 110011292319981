<sf-popup-modal
  [isOpen]="true"
  [hasCloseButton]="true"
  [autoClose]="false"
  [limitHeight]="true"
  (close)="onClose()"
  [zIndex]="1000"
>

  <ng-container header><span class="red">WARNING!</span></ng-container>
  <ng-container mainContent>
    <div class="content">
      <h2 class="red">Failed to refresh Snowflake access token. Please sign out and back in.</h2>
    </div>
  </ng-container>

</sf-popup-modal>