import { SubOptionsFunction } from "./suboption-function.interface";


export class NewTicketOptions {

  constructor(public title: string, public subOptionLabel: string = null, private _subOptions: string[] | SubOptionsFunction = null) {
  }

  get subOptions(): string[] {
    if (!this._subOptions) return;
    if (Array.isArray(this._subOptions)) return this._subOptions;
    this._subOptions = this._subOptions();
    return this._subOptions;
  }

  static getMsisdnPostfix(msisdn: string) {
    return `MSISDN #${msisdn}`;
  }
}
