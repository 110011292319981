import { Component } from '@angular/core';

@Component({
  selector: 'app-custom-spinner',
  template: `<mat-spinner class="spinner"></mat-spinner>`,
  styles: [`
  .spinner {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }`]
})
export class CustomSpinnerComponent {

}
