import { Utils } from "src/app/Utils";
import { EscalationActionPayload } from "../../interfaces/escalation-payload.interface";


export class Escalate {
    static readonly type = Utils.Helpers.Type("[Escalation] escalate");
    constructor(public payload: EscalationActionPayload) { }
}

export class EscalateSuccess {
    static readonly type = Utils.Helpers.Type("[Escalation] escalate success");
    constructor(public payload: any) { }
}

export class EscalateFail {
    static readonly type = Utils.Helpers.Type("[Escalation] escalate fail");
    constructor(public error: any) { }
}