import { Utils } from "src/app/Utils";
import { Dictionary } from "../../interfaces/dictionary.interface";
import { UpdateFilterOptions } from "../../interfaces/update-filter-options.interface";
import { SFValidators } from "../sf-validators";


export const updateFilters = <T extends Dictionary, Key extends keyof T>
    (filters: T, options: UpdateFilterOptions<Key, T[Key]>, replaceEmptyString = false) => {

    if (replaceEmptyString) {
        return Utils.Functional.pipe(
            replace(filters, "" as T[keyof T], null),
            (filters) => _updateFilters(filters, options)
        )
    }
    return _updateFilters(filters, options);
}


const _updateFilters = <T extends Dictionary, Key extends keyof T>
    (oldFilter: T, { add, remove }: UpdateFilterOptions<Key, T[Key]>) => {

    const { key, value } = add;
    const oldKeys = removeKey(Object.keys(oldFilter), remove);

    return uniqueKeys([...oldKeys, key])
        .reduce((newFilter, currentKey) => {

            if (key === currentKey) {
                if (SFValidators.isDefined(value)) {
                    newFilter[key] = value;
                }
                return newFilter;
            }
            newFilter[currentKey as keyof T] = oldFilter[currentKey];
            return newFilter;
        },
            {} as T);
}

const removeKey = <Key>(keys: string[], keyToRemove: Key) => {
    if (keyToRemove) {
        return keys.filter(key => key !== keyToRemove?.toString());
    }
    return keys;
}


const uniqueKeys = <T>(keys: T[]) => [...new Set(keys).values()];

export const replace = <T extends Dictionary, R>
    (filter: T, matchingValue: T[keyof T], replacer: R) => {

    return Object.keys(filter)
        .reduce((newFilter, currentKey) => {
            if (matchingValue === replacer) {
                return newFilter;
            }
            newFilter[currentKey as keyof T] = filter[currentKey];
            return newFilter;
        },
            {} as T);
}