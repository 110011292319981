<sf-popup-modal [isOpen]="true" [hasCloseButton]="true" (close)="onClose()" [autoClose]="true">
  <ng-container mainContent>
  <h2>{{selectedQuestion$ | async}}</h2>
  <div *ngIf="(selectedAnswer$ | async) as selectedAnswer">
    <p [innerHTML]="selectedAnswer">
    </p>
  </div>
  </ng-container>
  <ng-container buttons>
    <button class="rain-button default-button-size send-button solid" (click)="onClose()"
    >
      Ok
    </button>
  </ng-container>
</sf-popup-modal>
