import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MultiSelectOption } from './assets/multi-select-option.model';
import { MultiSelect } from './assets/multi-select.model';

@Component({
  selector: 'sf-multi-level-select',
  templateUrl: './multi-level-select.component.html',
  styleUrls: ['./assets/multi-select.scss', 'multi-level-select.component.scss']
})
export class MultiLevelSelectComponent {

  @Output() optionSelected = new EventEmitter<{ key: string, option: MultiSelectOption }>();
  @Output() otherSelected = new EventEmitter<string | number>();

  @Input() multiSelectMap: { [x: string]: MultiSelect }

  onOptionSelect(key: string, index: number, multiSelect: MultiSelect, selectedOption: MultiSelectOption) {
    if (selectedOption.isHoveringOverSuccessToggle) {
      return;
    }
    multiSelect.toggleSelectOption(index);
    this.onSelectEvent(key, selectedOption);
  }

  onSuccessToggled(key: string) {
    this.multiSelectMap[key].updateSelectedOptions();
  }

  onSelectEvent(key: string, multiSelectOption: MultiSelectOption) {
    this.optionSelected.next({ key, option: multiSelectOption });
  }

  onOtherSelected(event: InputEvent) {
    this.otherSelected.next(event.target?.["value"]);
  }

  originalOrder = (): number => {
    return 0;
  }
}
