import { Pipe, PipeTransform } from '@angular/core';
import { ProductNames, PRODUCT_ID_NAME_MAP } from '../../constants/product-id-to-name-mapping.constant';



@Pipe({ name: 'isProduct' })
export class IsProductPipe implements PipeTransform {

  /**
   * A readable way to check product_ids in the template
   *
   * @returns If the service's product_id matches
   */
  transform(product_id: string, productName: ProductNames): boolean {
    if (!Object.keys(PRODUCT_ID_NAME_MAP).includes(productName)) {
      throw Error(`${productName} does not match any of the rain product names!`);
    }

    return product_id === PRODUCT_ID_NAME_MAP[productName];
  }

}
