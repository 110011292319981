import { TicketFilterFunctions } from "src/app/features/pages/ticket/pages/all-tickets-page/assets/ticket-filter-functions";


function toInt(arr: any[] | any) {
    if (Array.isArray(arr)) {

        const nums = arr.map((i) => BigInt(i));
        return new BigInt64Array(nums);
    } else {
        return BigInt(arr);
    }
}

export function parseFilters(filters: any): string {
    let filterString = '';

    for (let index = 0; index < Object.keys(filters).length; index++) {
        const key = Object.keys(filters)[index];
        if (index === 0) {
            if (Array.isArray(filters[key])) {
                let stringArr = "[";
                if (key === 'stateId') {
                    filterString = filterString + `stateId: [${toInt(filters[key])}]`;
                }
                else {
                    for (let index = 0; index < filters[key].length; index++) {
                        const element = filters[key][index];
                        if (index === filters[key].length - 1) {
                            stringArr = stringArr + "'" + element + "']"
                        } else {
                            stringArr = stringArr + "'" + element + "',"
                        }
                    }
                    filterString = filterString + `${key}: "${(stringArr)}"`
                }

            }
            else {
                if (key === 'date_range') {
                    filterString = filterString + `updatedAt: ${TicketFilterFunctions.formatUpdatedAtString(filters[key])}`;
                }
                else {
                    filterString = filterString + `${key}: "${filters[key]}"`;
                }
            }
        }
        else {
            if (key === 'date_range') {
                filterString = filterString + `, updatedAt: ${TicketFilterFunctions.formatUpdatedAtString(filters[key])}`;
            }
            else {
                if (Array.isArray(filters[key])) {
                    if (key === 'stateId') {
                        filterString = filterString + `,stateId:[${toInt(filters[key])}]`;
                    }
                    else {
                        filterString = filterString + `,${key}: "${filters[key]}"`;
                    }
                }
                else {
                    filterString = filterString + `, ${key}: "${filters[key]}"`;
                }
            }
        }
    }

    return filterString;
}


export const AttachmentSchema =
    `
        entries {
            id
            ticketId
            channelId
            isPrivate
            attachmentType
            name
            mimeType
            url
            byteSize
            description
            insertedAt
        }
    `


export const RelatedEntitySchema =
    `
        entries {
            id
            email
            idmId
            azureId
            insertedAt
        }

    `

export const EntitySchema =
    `
        entries {
            id
            entityTypeId
            insertedAt
            relatedEntities {
                ${RelatedEntitySchema}
            }
        }
    `

export const NoteSchema =
    `
        entries {
            id
            channelId
            comment
            ticketId
            privateNote
            insertedAt
            relatedEntity {
                id
                email
                idmId
                azureId
                insertedAt
            }
        }
    `

export const PaginationSchema =
    `
        pagination {
            pageNo
            pageSize
            totalPages
            totalCount
        }
    `

export function ISODateString(d) {
    function pad(n) { return n < 10 ? '0' + n : n }
    return d.getUTCFullYear() + '-'
        + pad(d.getUTCMonth() + 1) + '-'
        + pad(d.getUTCDate()) + 'T'
        + pad(d.getUTCHours()) + ':'
        + pad(d.getUTCMinutes()) + ':'
        + pad(d.getUTCSeconds()) + 'Z'
}

export function TicketFilterSchema(filters: any, pagination: string) {
    const filterString = parseFilters(filters);

    return `
    {
        tickets(
            pagination: ${pagination},
            filter: {${filterString}},
            sorting: {by: {field: "insertedAt", dir: DESC}}
        ) {
            entries {
                id
                hexId
                externalId
                description
                name
                ticketTypeId
                stateId
                priority
                severity
                notes {
                    ${NoteSchema}
                }
                attachments {
                    ${AttachmentSchema}
                }
                entities {
                    ${EntitySchema}
                }
                requestedResolutionDate
                expectedResolutionDate
                resolutionDate
                insertedAt
                updatedAt
            }
            ${PaginationSchema}
        }
    }
`
}

export function PaginateTicketFilterSchema(filters: any, pagination: string) {
    const filterString = parseFilters(filters);

    return `
    {
        tickets(
            pagination: ${pagination},
            filter: {${filterString}},
            sorting: {by: {field: "insertedAt", dir: DESC}}
        ) {
            entries {
                id
                hexId
                externalId
                description
                name
                ticketTypeId
                stateId
                priority
                severity
                requestedResolutionDate
                expectedResolutionDate
                resolutionDate
                insertedAt
                updatedAt
            }
            ${PaginationSchema}
        }
    }
`
}

export function FetchTicketById(filters: any) {
    const filterString = parseFilters(filters);

    return `
    {
        tickets(
            filter: {${filterString}},
            sorting: {by: {field: "insertedAt", dir: DESC}}
        ) {
            entries {
                id
                hexId
                externalId
                description
                name
                ticketTypeId
                stateId
                priority
                severity
                notes {
                    ${NoteSchema}
                }
                attachments {
                    ${AttachmentSchema}
                }
                entities {
                    ${EntitySchema}
                }
                requestedResolutionDate
                expectedResolutionDate
                resolutionDate
                insertedAt
                updatedAt
            }
            ${PaginationSchema}
        }
    }
`
}

export const TicketSchema =
    `
        {
            tickets(
                pagination: {pageNo: 0, pageSize: 25},
                sorting: {by: {field: "insertedAt", dir: DESC}}
            ) {
                entries {
                    id
                    hexId
                    externalId
                    description
                    name
                    ticketTypeId
                    stateId
                    priority
                    severity
                    requestedResolutionDate
                    expectedResolutionDate
                    resolutionDate
                    insertedAt
                    updatedAt
                }
                ${PaginationSchema}
            }
        }
    `