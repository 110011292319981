import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { RetentionsModalComponent } from './chat-interaction/retentions-modal/retentions-modal.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InteractionsState } from './store/state/interactions.state';
import { NgxsModule } from '@ngxs/store';
import { InteractionsModalState } from './store/state/interaction-modal-state';
import { NewTicketModalComponent } from './new-ticket-modal/new-ticket-modal.component';
import { PostSalesModalComponent } from './chat-interaction/post-sales-modal/post-sales-modal.component';
import { DefaultPostTicketModalComponent } from './chat-interaction/default-post-ticket-modal/default-post-ticket-modal.component';
import { MoreOpenTicketsComponent } from './components/more-open-tickets/more-open-tickets.component';
import { PostCollectionsModalComponent } from './chat-interaction/post-collections-modal/post-collections-modal.component';
import { InteractionsComponent } from './interactions.component';
import { EscalationModalComponent } from './chat-interaction/escalation-modal/escalation-modal.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { ChatInteractionComponent } from './chat-interaction/chat-interaction.component';
import { CustomerTicketInteractionComponent } from './customer-ticket-interaction/customer-ticket-interaction.component';

import { MatTabsModule } from '@angular/material/tabs';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { ChatAttachmentComponent } from './chat-interaction/components/chat-attachment/chat-attachment.component';
import { ChatBoxElementComponent } from './chat-interaction/components/chat-box-element/chat-box-element.component';
import { ChatBubbleComponent } from './chat-interaction/components/chat-bubble/chat-bubble.component';
import { ChatEventComponent } from './chat-interaction/components/chat-event/chat-event.component';
import { ChatUpdatedMessageComponent } from './chat-interaction/components/chat-updated-message/chat-updated-message.component';
import { AttachmentLoadingComponent } from './chat-interaction/components/attachment-loading/attachment-loading.component';
import { UpdateTicketWarningModalComponent } from './chat-interaction/update-ticket-warning-modal/update-ticket-warning-modal.component';
import { ActivationsPostCloseModalComponent } from './chat-interaction/activations-post-close-modal/activations-post-close-modal.component';
import { ChatJsonNoteComponent } from './chat-interaction/components/chat-json-note/chat-json-note.component';
import { ChatNoteState } from './store/state/note.state';
import { RicaPostCloseModalComponent } from './chat-interaction/rica-post-close-modal/rica-post-close-modal.component';
import { InteractionsModalContainerComponent } from './components/interactions-modal-container/interactions-modal-container.component';
import { CsatPostCloseModalComponent } from './chat-interaction/csat-post-close-modal/csat-post-close-modal.component';
import { ContinueFromTicketState } from './store/state/continue-from-ticket.state';
import { InteractionTicketsState } from './store/state/interaction-tickets.state';
import { ChatTicketState } from './store/state/chat-ticket.state';
import { RefreshTicketState } from './store/state/refresh-ticket.state';
import { EarlyOptimizationsCloseModalComponent } from './chat-interaction/early-optimizations-close-modal/early-optimizations-close-modal.component';
import { PostCampaignCallModalComponent } from './chat-interaction/post-campaign-call-modal/post-campaign-call-modal.component';
import { WhatsappInteractionsModule } from './whatsapp-interactions/whatsapp-interactions.module';
import { EscalationState } from './store/state/escalation.state';
import { RainMultiSelectModule } from '../shared/elements/rain-forms/elements/rain-multi-select/rain-multi-select.module';
import { RainOneRetentionsPostCloseModalComponent } from './chat-interaction/rain-one-retentions-post-close-modal/rain-one-retentions-post-close-modal.component';
import { CallbackModule } from '../shared/components/callback/callback.module';
import { PostUpgradeModalComponent } from './chat-interaction/post-upgrade-modal/post-upgrade-modal.component';
import { INTERACTION_PIPES } from './pipes';
import { CallbackSchedulerComponent } from './chat-interaction/components/callback-scheduler/callback-scheduler.component';
import { CecTeamAFPostCloseModalComponent } from './chat-interaction/cec-team-a-f-post-close-modal/cec-team-a-f-post-close-modal.component';
import { TicketEyeballComponent } from './chat-interaction/components/ticket-eyeball/ticket-eyeball.component';
import { EyeballCounterModule } from '../shared/components/eyeball-counter/eyeball-counter.module';
import { RetenstionsUpdateModalComponent } from './chat-interaction/retenstions-update-modal/retenstions-update-modal.component';
import { RetentionsUpdateModal4GComponent } from './chat-interaction/retentions-update-modal-4g/retentions-update-modal-4g.component';
import { NvidiaPostCloseModalComponent } from './chat-interaction/nvidia-post-close-modal/nvidia-post-close-modal.component';
import { PostCollectionsModalBComponent } from './chat-interaction/post-collections-modal-b/post-collections-modal-b.component';
import { FulfillmentDeliveriesPostCloseModalComponent } from './chat-interaction/fulfillment-deliveries-post-close-modal/fulfillment-deliveries-post-close-modal.component';
import { FullfillmentCpeCollectionsPostCloseModalComponent } from './chat-interaction/fullfillment-cpe-collections-post-close-modal/fullfillment-cpe-collections-post-close-modal.component';
import { FulfillmentRicaPostCloseModalComponent } from './chat-interaction/fulfillment-rica-post-close-modal/fulfillment-rica-post-close-modal.component';
import { RetailCustomerEngagementPostCloseModalComponent } from './chat-interaction/retail-customer-engagement-post-close-modal/retail-customer-engagement-post-close-modal.component';
import { PrettyJsonNoteModule } from '../shared/components/pretty-json-note/pretty-json-note.module';
import { RetentionsMainPostCloseModalComponent } from './chat-interaction/retentions-main-post-close-modal/retentions-main-post-close-modal.component';
import { Rain101PostCloseModalComponent } from './chat-interaction/rain101-post-close-modal/rain101-post-close-modal.component';


const declarations = [
    InteractionsComponent,
    EscalationModalComponent,
    RetentionsModalComponent,
    NewTicketModalComponent,
    PostSalesModalComponent,
    DefaultPostTicketModalComponent,
    NewTicketModalComponent,
    MoreOpenTicketsComponent,
    RetenstionsUpdateModalComponent,
    RetentionsUpdateModal4GComponent,
    CustomerTicketInteractionComponent,
    ChatInteractionComponent,
    ChatBoxElementComponent,
    ChatBubbleComponent,
    ChatAttachmentComponent,
    ChatEventComponent,
    ChatUpdatedMessageComponent,
    AttachmentLoadingComponent,
    ChatJsonNoteComponent,
    RicaPostCloseModalComponent,
    CsatPostCloseModalComponent,
    InteractionsModalContainerComponent,
    PostCollectionsModalComponent,
    UpdateTicketWarningModalComponent,
    ActivationsPostCloseModalComponent,
    EarlyOptimizationsCloseModalComponent,
    PostCampaignCallModalComponent,
    RainOneRetentionsPostCloseModalComponent,
    PostUpgradeModalComponent,
    CallbackSchedulerComponent,
    CecTeamAFPostCloseModalComponent,
    TicketEyeballComponent,
    NvidiaPostCloseModalComponent,
    PostCollectionsModalBComponent,
    FulfillmentDeliveriesPostCloseModalComponent,
    FullfillmentCpeCollectionsPostCloseModalComponent,
    FulfillmentRicaPostCloseModalComponent,
    RetailCustomerEngagementPostCloseModalComponent,
    RetentionsMainPostCloseModalComponent,
    Rain101PostCloseModalComponent
];

@NgModule({
    declarations: [
        ...declarations,
    ...INTERACTION_PIPES],
    imports: [
        CommonModule,
        SharedModule,
        FormsModule,
        ReactiveFormsModule,
        MatToolbarModule,
        MatTabsModule,
        MatBadgeModule,
        MatButtonModule,
        WhatsappInteractionsModule,
        RainMultiSelectModule,
        CallbackModule,
        EyeballCounterModule,
        PrettyJsonNoteModule,
        NgxsModule.forFeature([
            InteractionsModalState,
            InteractionsState,
            InteractionTicketsState,
            RefreshTicketState,
            ChatTicketState,
            ChatNoteState,
            ContinueFromTicketState,
            EscalationState
        ])
    ],
    exports: [
        ...declarations
    ]
})
export class InteractionsModule { }
