import { Utils } from "src/app/Utils";
import { SiteAlarmDetails } from "../../interfaces/site-alarm-details.interface";


export class Fetch {
    static readonly type = Utils.Helpers.Type("[SiteAlarmDetails] Fetch");
    constructor(public siteId: number) { }
}

export class FetchSuccess {
    static readonly type = Utils.Helpers.Type("[SiteAlarmDetails] Fetch success");
    constructor(public payload: SiteAlarmDetails[]) { }
}

export class FetchFail {
    static readonly type = Utils.Helpers.Type("[SiteAlarmDetails] Fetch fail");
    constructor(public error: any) { }
}

export class Clear {
    static readonly type = Utils.Helpers.Type("[SiteAlarmDetails] Clear");
}