
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'prettifyKey' })
export class PrettifyKeyPipe implements PipeTransform {

  transform(value: string): string {
    if (!value || typeof value !== "string") {
      return value;
    }

    return value.replace(/_/g, " ").trim();
  }

}
