

export const SF_AUTH_ROLES = {
  AGENT: "AUTH.USER.AGENT",
  GENERAL: "AUTH.USER.GENERAL",
  MANAGEMENT: "AUTH.USER.MANAGEMENT",
  WIPROGEN: "CEC.WIPROGEN.A",
  ADMIN: "AUTH.USER.ADMIN"
} as const;

export const ALL= "all";

export type SFAuthRoleType = keyof typeof SF_AUTH_ROLES;
export type AuthRoleValues = typeof SF_AUTH_ROLES[SFAuthRoleType];
