import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { FormHelper } from 'src/app/Utils/helpers';
import { PostTicketModalOptions } from 'src/app/shared/services/post-ticket/post-ticket-modal-options.model';
import { ChatJsonNote } from '../components/chat-json-note/assets/chat-json-note.interface';
import { Store } from '@ngxs/store';
import { InteractionTicketsState } from '../../store/state/interaction-tickets.state';
import { PostTicketService } from 'src/app/shared/services/post-ticket/post-ticket.service';
import { ToggleModalByName } from 'src/app/core/store/actions/modal.actions';

@Component({
  selector: 'sf-fulfillment-deliveries-post-close-modal',
  templateUrl: './fulfillment-deliveries-post-close-modal.component.html',
  styleUrls: ['./fulfillment-deliveries-post-close-modal.component.scss']
})
export class FulfillmentDeliveriesPostCloseModalComponent extends FormHelper implements OnInit {
  selectedDeliveryOption:string= null;
  form: FormGroup;
  modalOptions: PostTicketModalOptions;
  readonly ngDestroy$ = new Subject<void>();
  readonly FULFILLMENT_DELIVERIES_OPTIONS: string[]= [
    'Sim Swap',
    'Swap Out',
    'Rain One',
    'Migration'
  ];

  constructor(private formBuilder:FormBuilder, private store:Store, private postTicketService:PostTicketService) {
    super();
  }

  createForm()
  {
    this.form = this.formBuilder.group({
      fulfillment_deliveries_option: this.formBuilder.control(''),
    })
  }
  ngOnInit(): void {
    this.createForm();
    this.InitForm(this.form);
    this.modalOptions = new PostTicketModalOptions();

  }

  updateSelectedOption(option: string) {
    this.selectedDeliveryOption = option;
}

  onSubmit(){
    const data = {
      "delivery reason": this.selectedDeliveryOption,
    }
    const payload: ChatJsonNote = {
        message: "fulfillment deliveries post close form",
        detail: data,
        type: 'fulfillment_deliveries_form'
    }
    const hexId = this.store.selectSnapshot(InteractionTicketsState.getSelectedHexId);
    this.postTicketService.sendPostTicketPayload(
      hexId, payload, this.modalOptions, this.close.bind(this)
  );
  }


 ngOnDestroy() {
  this.close();
  this.ngDestroy$.next(null);
  this.ngDestroy$.complete();
}

  close(){
    this.store.dispatch(new ToggleModalByName('fulfillment_deliveries_post_close_modal'));
  }

}

