import { Injectable } from '@angular/core';
import { State, Action, StateContext, Selector } from '@ngxs/store';
import { MapActions } from 'src/app/shared/elements/map/store/actions/map-actions';
import { ResetSFCoverageMapDefaults, SetSFMapExpanded, SetSFMapStreetView, ToggleSFmapExpanded } from '../actions/sf-map.actions';
import { CenterOnOption } from '../interfaces/center-on-option.interface';


function getDefaults(): SFCoverageMapStateModel {
  return {
    is_expanded: true,
    is_street_view: false,
    mapLoaded: false,
    showPinDrop: true,
    centerOnOption: "connectedSite"
  }
}

interface SFCoverageMapStateModel {
  is_expanded: boolean;
  is_street_view: boolean;
  mapLoaded: boolean;
  showPinDrop: boolean;
  centerOnOption: CenterOnOption;
}
@State<SFCoverageMapStateModel>({
  name: 'sf_coverage_map_state',
  defaults: getDefaults()
})
@Injectable()
export class SFCoverageMapState {

  @Selector()
  static isExpanded(state: SFCoverageMapStateModel) { return state.is_expanded; }

  @Selector()
  static isStreetView(state: SFCoverageMapStateModel) { return state.is_street_view; }

  @Selector()
  static isMapLoaded(state: SFCoverageMapStateModel) { return state.mapLoaded; }

  @Selector()
  static showPinDrop(state: SFCoverageMapStateModel) { return state.showPinDrop; }

  @Selector()
  static getCenteredOn(state: SFCoverageMapStateModel) { return state.centerOnOption; }


  @Action(ToggleSFmapExpanded)
  toggleSFmapExpanded(ctx: StateContext<SFCoverageMapStateModel>) {
    ctx.patchState({
      is_expanded: !ctx.getState().is_expanded
    })
  }

  @Action(SetSFMapExpanded)
  setSFMapExpanded(ctx: StateContext<SFCoverageMapStateModel>, action: SetSFMapExpanded) {
    ctx.patchState({
      is_expanded: action.expanded
    })
  }

  @Action(SetSFMapStreetView)
  setSFMapSatView(ctx: StateContext<SFCoverageMapStateModel>, action: SetSFMapStreetView) {
    ctx.patchState({
      is_street_view: action.bool
    })
  }

  @Action(MapActions.MapLoaded)
  MapLoaded(ctx: StateContext<SFCoverageMapStateModel>) {
    ctx.patchState({
      mapLoaded: true
    })
  }

  @Action(MapActions.MapDestroyed)
  MapDestroyed(ctx: StateContext<SFCoverageMapStateModel>) {
    ctx.patchState({
      mapLoaded: false
    })
  }

  @Action(MapActions.ShowPinDrop)
  ShowPinDrop(ctx: StateContext<SFCoverageMapStateModel>, action: MapActions.ShowPinDrop) {
    ctx.patchState({
      showPinDrop: action.show
    })
  }

  @Action(MapActions.ToggleCenteredOnOption)
  ToggleCenteredOnOption(ctx: StateContext<SFCoverageMapStateModel>) {
    const { centerOnOption } = ctx.getState();

    ctx.patchState({
      centerOnOption: centerOnOption === "connectedSite" ? "userAddress" : "connectedSite"
    })
  }

  @Action(ResetSFCoverageMapDefaults)
  resetSFMapDefaults(ctx: StateContext<SFCoverageMapStateModel>) {
    ctx.setState(getDefaults());
  }

}
