import { Component, ElementRef, Inject, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import MarkerClustered from '@google/markerclustererplus';
import { isNil } from 'lodash';
import { combineLatest, Observable, Subject } from 'rxjs';
import { MapLayerService } from 'src/app/shared/services/google-maps/map-layer.service';
import { NewMapService } from 'src/app/shared/services/google-maps/new-map.service';
import { MapLayerId } from '../../services/google-maps/assets/map-layer-ids.interface';
import { DEFAULT_LATITUDE, DEFAULT_LONGITUDE, MAX_ZOOM_LEVEL_OVERLAY_SUPPORT } from './models/constants';
import { GeoService, CoverageMapModes } from './services/geo.service';
import { MapStylesService } from '../../services/google-maps/map-styles.service';
import { Actions, ofActionSuccessful, Select, Store } from '@ngxs/store';
import { SetSFMapStreetView } from 'src/app/customer-data-components/sim-details/sim-card-page/sub-components/coverage/store/actions/sf-map.actions';
import { TechType } from '../../services/google-maps/assets/techtype.type';
import { MapActions } from './store/actions/map-actions';
import { filter, takeUntil } from 'rxjs/operators';
import { RefreshMaps } from 'src/app/customer-info-summary-page/store/actions/events.actions';
import { FetchAzimuthDataSuccess } from './store/actions/azimuth.actions';
import { NormalSitesState } from './store/state/normal-map-sites.state';
import { AlarmSiteState } from './store/state/alarm-sites.state';
import { SFMapFunctions } from '../../services/google-maps/assets/sf-map-functions';
import { SiteAlarmDetailsState } from './store/state/site-alarm-details.state';
import { MapLayerFunctions } from '../../services/google-maps/assets/map-layer-functions';
import { SiteAlarmDetails } from './store/interfaces/site-alarm-details.interface';
import { formatSiteAlarmDetails } from './store/functions/format-site-alarm-details';
import { SFValidators } from '../../functions/sf-validators';
import { MAP_GEO_ZOOM_LEVEL, TECH_TYPES } from 'src/app/constants';
import { AlarmSiteActions } from './store/actions/alarm-site-actions';
import { GeoFunctions } from '../../functions/geo-functions';
import { CurrentlyConnectedSiteSelectors } from 'src/app/customer-data-components/sim-details/store/selectors/currently-connected-site.selectors';
import { DOCUMENT } from '@angular/common';
import { CustomerGeoSelectors } from 'src/app/customer-data-components/sim-details/sim-card-page/sub-components/coverage/store/selectors/customer-geo.selectors';
import { SFCoverageMapState } from 'src/app/customer-data-components/sim-details/sim-card-page/sub-components/coverage/store/state/sf-coverage-map.state';
import { CenterOnOption } from 'src/app/customer-data-components/sim-details/sim-card-page/sub-components/coverage/store/interfaces/center-on-option.interface';
import { PickupPointsState } from 'src/app/sales/store/state/pickup-points.state';
import { FormattedPickupPoint } from 'src/app/sales/store/interfaces/pickup-point.interface';
import { PickupPointActions } from 'src/app/sales/store/action/pickup-point-actions';


const DEFAULT_ZOOM_LEVEL = MAP_GEO_ZOOM_LEVEL;

const LAYER_IDS: MapLayerId[] = ["4G", "5G", "Normal Alarms", "Prolonged Alarms", "Roaming"]; // "OSS VC"
type CoverageLayerId = TechType | "fourg_roaming_shp" | "fourg_105" | "home_5G"

@Component({
    selector: 'sf-map',
    templateUrl: './map.component.html',
    styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit, OnDestroy {
    @ViewChild('floatingPanel', { static: false }) floatingPanel: ElementRef;
    @ViewChild('fullscreenSearch', { static: false }) fullscreenSearch: ElementRef;

    @Select(CurrentlyConnectedSiteSelectors.currentFeatureTech) currentFeatureTech$: Observable<TechType>;
    @Select(SFCoverageMapState.getCenteredOn) mapCenteredOn$: Observable<CenterOnOption>;
    @Select(PickupPointsState.formattedPickupPoints) formattedPickupPoints$: Observable<FormattedPickupPoint[]>;

    @Input() public mode: CoverageMapModes = 0;
    @Input() address: string;
    @Input() showCoverageAndSites = true;
    @Input() applyDarkmode = true;
    @Input() showMapControls = true;
    @Input() showFullscreenControls = true;

    readonly markerIcon = { url: 'assets/images/map/rain-map-marker.png', scaledSize: { width: 50, height: 70 } }
    readonly selectedMarkerIcon = { url: 'assets/images/map/rain-map-marker-selected.png', scaledSize: { width: 50, height: 70 } }

    MAX_ZOOM = MAX_ZOOM_LEVEL_OVERLAY_SUPPORT;

    markerCluster: MarkerClustered;

    layers: { [layer in CoverageLayerId]?: google.maps.ImageMapType } = {};

    private _listeners: google.maps.MapsEventListener[] = [];
    layerIds: MapLayerId[] = LAYER_IDS;

    @Input() topCenterFullscreenControlId = '';

    @Input() showMarker = true;

    currentZoomLevel: number;
    insertedElementParentNode;
    siteCoordinates = [];

    @Input()
    set onlyLte(onlyLte: boolean) {
        this._onlyLte = onlyLte;
        this.update();
    }

    @Input()
    set latitude(latitude: number) {
        if (latitude) {
            this._latitude = latitude;
        }
    }

    @Input()
    set longitude(longitude: number) {
        if (longitude) {
            this._longitude = longitude;
        }
    }

    _onlyLte: boolean;
    _latitude: number;
    _longitude: number;

    readonly defaultLat = DEFAULT_LATITUDE;
    readonly defaultLong = DEFAULT_LONGITUDE;

    @Input()
    zoom: number = DEFAULT_ZOOM_LEVEL;

    public coverageMapModes = CoverageMapModes;

    map: google.maps.Map;
    showFourG = true;
    showFiveG = true;

    hideFullScreenTopCenterControl = true

    private readonly ngDestroy$ = new Subject<void>();

    constructor(private geoService: GeoService,
        private newMapService: NewMapService,
        public mapLayerService: MapLayerService,
        private mapStylesService: MapStylesService,
        private store: Store,
        private actions$: Actions,
        @Inject(DOCUMENT) private document: Document
    ) { }


    ngOnInit() {
        if (this.address) {
            this.getGeoLocation(this.address)
                .pipe(takeUntil(this.ngDestroy$))
                .subscribe()
        }

        if (this.showCoverageAndSites) {
            this.applySitesAndCoverage();
        }

    }

    private applySitesAndCoverage() {
        this.mode =
            isNil(this.mode) || Object.values(CoverageMapModes).includes(this.mode) == false
                ? CoverageMapModes.Both
                : this.mode;

        this.listenToActions();
        this.addAlarmDetailSubs();
    }

    private listenToActions() {
        const mapLoaded$ = this.actions$
            .pipe<MapActions.MapLoaded>(ofActionSuccessful(MapActions.MapLoaded));
        this.listenToSitesLoaded(mapLoaded$);
        this.listenToAlarmsLoaded(mapLoaded$);

        this.actions$
            .pipe(
                ofActionSuccessful(RefreshMaps),
                takeUntil(this.ngDestroy$)
            )
            .subscribe({
                next: () => this.refreshMap()
            });

        this.actions$
            .pipe(
                ofActionSuccessful(FetchAzimuthDataSuccess),
                takeUntil(this.ngDestroy$)
            )
            .subscribe({
                next: ({ siteID, payload }) => this.mapLayerService.addAzimuthLayer(siteID, payload)
            });

        combineLatest([
            mapLoaded$,
            this.store.select(CurrentlyConnectedSiteSelectors.currentFeature),
            this.mapCenteredOn$,
            this.store.select(CustomerGeoSelectors.getConnectedLatLngArrayAndDistance),
        ])
            .pipe(
                filter(([mapLoaded, feature]) => SFValidators.allTrue([Boolean(mapLoaded.map), Boolean(feature?.geometry?.coordinates)])),
                takeUntil(this.ngDestroy$)
            )
            .subscribe({
                next: ([mapLoaded, currentFeature, mapCenteredOn]) => {
                    const { map } = mapLoaded;
                    const coordinates = currentFeature.geometry.coordinates;
                    this.siteCoordinates = coordinates
                    const [lng, lat] = coordinates;

                    if (mapCenteredOn === 'connectedSite') {
                        map.setCenter(new google.maps.LatLng(lat, lng));
                        map.setZoom(DEFAULT_ZOOM_LEVEL);
                        map.setMapTypeId(google.maps.MapTypeId.ROADMAP)
                    }
                    else {
                        map.setCenter(new google.maps.LatLng({ lat: this._latitude, lng: this._longitude }));
                        map.setMapTypeId(google.maps.MapTypeId.SATELLITE);
                        map.setZoom(30)
                    }
                    this.mapLayerService.highlightConnectedSite(coordinates)
                }
            })
    }

    private async refreshMap() {
        await this.mapLayerService.refreshMap();
        this.setDefaultSiteVisibility();
    }

    private listenToSitesLoaded(mapLoaded$: Observable<MapActions.MapLoaded>) {
        const features$ = this.store.select(NormalSitesState.getData);
        const featuresLoaded$ = this.store.select(NormalSitesState.isLoaded);

        let initialized = false;

        SFMapFunctions.createMapAndFeatureLoadedObs({ mapLoaded$, featuresLoaded$, features$ })
            .pipe(
                takeUntil(this.ngDestroy$)
            )
            .subscribe({
                next: ({ map, features }) => {
                    if (!features?.length) {
                        return;
                    }

                    if (!initialized) {
                        this.mapLayerService.addSites(map, features, TECH_TYPES);
                        initialized = true;
                    }
                    else {
                        this.mapLayerService.updateNormalSites(map, features, TECH_TYPES);
                    }

                    this.setDefaultSiteVisibility();
                }
            });
    }

    private listenToAlarmsLoaded(mapLoaded$: Observable<MapActions.MapLoaded>) {
        const features$ = this.store.select(AlarmSiteState.getData);
        const featuresLoaded$ = this.store.select(AlarmSiteState.isLoaded);

        let initialized = false;

        SFMapFunctions.createMapAndFeatureLoadedObs({ mapLoaded$, featuresLoaded$, features$ })
            .pipe(
                takeUntil(this.ngDestroy$)
            )
            .subscribe({
                next: ({ map }) => {
                    const { prolongedAlarmFeatures, normalAlarmFeatures } = this.store.selectSnapshot(AlarmSiteState.getNormalAndProlongedAlarmsByTech(TECH_TYPES));

                    if (!initialized) {
                        //Only initialize sites once
                        this.mapLayerService.addNormalAlarms(map, normalAlarmFeatures);
                        this.mapLayerService.addProlongedAlarmSites(map, prolongedAlarmFeatures);
                        initialized = true;
                    }
                    else {
                        GeoFunctions.updateDataLayerFeatures(this.mapLayerService.getLayerById("Prolonged Alarms"), prolongedAlarmFeatures);
                        GeoFunctions.updateDataLayerFeatures(this.mapLayerService.getLayerById("Normal Alarms"), normalAlarmFeatures);
                    }

                }
            });
    }

    mapReady(map: google.maps.Map) {
        this.map = map;
        this.newMapService.setMap(map);
        this.store.dispatch(new MapActions.MapLoaded(map));

        this.applyDarkmode ? this.mapStylesService.darkMode(map) : this.mapStylesService.normalMode(map);

        if (this.showCoverageAndSites) {
            this.applyCoverageTile();
            this.addListeners(map);
            this.map.controls[google.maps.ControlPosition.LEFT_TOP].push(this.floatingPanel.nativeElement);
            this.map.controls[google.maps.ControlPosition.TOP_CENTER].push(this.fullscreenSearch.nativeElement);
        }
    }

    fullscreenchange() {
        if (!this.topCenterFullscreenControlId) {
            return;
        }
        if (this.document.fullscreenElement) {
            this.hideFullScreenTopCenterControl = false;
            const topCenterFullscreenControl = this.document.getElementById(this.topCenterFullscreenControlId);
            this.insertedElementParentNode = topCenterFullscreenControl.parentNode;
            this.document.getElementById("fullScreenTopCenterControl").appendChild(topCenterFullscreenControl);
        }
        else {
            this.hideFullScreenTopCenterControl = true;
            const topCenterFullscreenControl = this.document.getElementById(this.topCenterFullscreenControlId);
            this.insertedElementParentNode.appendChild(topCenterFullscreenControl);
        }

    }

    private setDefaultSiteVisibility() {
        const siteLayers: MapLayerId[] = ["Roaming", "OSS VC"];
        siteLayers.forEach(layer => this.mapLayerService.setVisibility(layer, false));
    }

    toggleCoverage(techType: "4G" | "5G") {
        const layer = this.layers[techType];
        const layers = [layer];
        if (techType === "5G") {
            layers.push(this.layers["5G-cpt"]);
        }

        layers.forEach(l => {
            const opacity = l.getOpacity();

            if (opacity > 0) {
                l.setOpacity(0)
            }
            else {
                l.setOpacity(1);
            }
        })

    }

    isCoverageVisible(techType: "4G" | "5G") {
        const layer = this.layers[techType];
        return layer?.getOpacity() > 0;
    }

    toggleSites(layerId: MapLayerId) {
        this.mapLayerService.toggleSites(layerId);
    }

    applyCoverageTile() {
        for (const layer of this.geoService.getCoverageLayers(this.mode)) {
            this.map.overlayMapTypes.push(layer);
            layer.setOpacity(0);
            this.layers[layer.get("tech")] = layer;
        }

        this.mapLayerService.setCoverageLayer(this.layers)
    }

    updateSelection() {
        // IF SELECTION CLEARS ALL MAP OPTIONS
        if (!this.showFiveG && !this.showFourG) {
            this.map.overlayMapTypes.clear();

            return;
        }
        this.mode = CoverageMapModes.Both;
        // SET MODE FOR COVERAGE LAYERS
        if (this.showFourG && this.showFiveG) {
            this.mode = CoverageMapModes.Both;
        } else if (this.showFourG) {
            this.mode = CoverageMapModes.FourG;
        } else {
            this.mode = CoverageMapModes.FiveG;
        }
        this.update();
    }

    update() {
        this.map.overlayMapTypes.clear();
        this.applyCoverageTile();
    }


    getGeoLocation(address: string): Observable<any> {
        const geocoder = new google.maps.Geocoder();
        return new Observable(observer => {
            geocoder.geocode({
                'address': address
            }, (results, status) => {
                if (status == google.maps.GeocoderStatus.OK) {
                    observer.next(results[0].geometry.location);
                    observer.complete();
                } else {
                    observer.error();
                }
            });
        });
    }

    private addListeners(map: google.maps.Map) {
        const panorama = map.getStreetView();

        const listener = google.maps.event.addListener(panorama, "visible_changed", () => {
            this.store.dispatch(new SetSFMapStreetView(panorama.getVisible()))
        })

        this._listeners.push(listener);
    }

    private addAlarmDetailSubs() {
        this.store.select(SiteAlarmDetailsState.getState)
            .pipe(takeUntil(this.ngDestroy$))
            .subscribe({
                next: state => {
                    const { currentSiteId, loading, data, error } = state ?? {};
                    if (loading) {
                        this.mapLayerService.setAlarmInfoWindowContent("loading..");
                    }
                    else if (data?.length) {
                        const relevantAlarmDetails = this.store.selectSnapshot(SiteAlarmDetailsState.getHighestPrioAlarm);
                        this.setAlarmInfoWindowContent(currentSiteId, relevantAlarmDetails);
                    }
                    else if (error) {
                        this.mapLayerService.setAlarmInfoWindowContent(
                            MapLayerFunctions.createErrorLayout(error))
                    }
                }
            });
    }

    private setAlarmInfoWindowContent(siteId: number, data: SiteAlarmDetails) {
        const siteName = this.store.selectSnapshot(AlarmSiteState.getAlarmsByProperty("siteID", siteId))?.[0]?.properties?.name;
        const formattedData = formatSiteAlarmDetails(siteName, data);

        this.mapLayerService.setAlarmInfoWindowContent(
            MapLayerFunctions.createInfoWindowLayout("site alarm details", formattedData)
        );
    }

    setSelectedPickupPoint(pickupPoint: FormattedPickupPoint) {
        this.store.dispatch(new PickupPointActions.SetSelectedLocationId(pickupPoint?.locationId));
    }

    zoomChange(zoomLevel: number) {
        this.currentZoomLevel = zoomLevel;
    }

    ngOnDestroy(): void {
        this.ngDestroy$.next(null);
        this.ngDestroy$.complete();
        this.mapLayerService.clear(true);
        this._listeners?.forEach(l => l?.remove());

        this.store.dispatch([
            new MapActions.MapDestroyed(),
            new AlarmSiteActions.Clear()
        ]);
    }
}
