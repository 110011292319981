import { DataHandler } from "src/app/shared/data-handler/data-handler";
import { MultiSelectMap } from "./multi-select-map.class";
import { NestedMultiSelectConfig } from "./nested-multi-select-config.interface";



export class NestedMultiSelect {

  nestedOptions: { [x: string]: { multiSelectMap: MultiSelectMap, isOpen: boolean } } = {};

  constructor(nestedMultiSelectOptions: { [x: string]: { [x: string]: string[] } },
    config: NestedMultiSelectConfig = { isTopLevelOpen: true }
  ) {

    for (const key in nestedMultiSelectOptions) {
      this.nestedOptions[key] = {
        multiSelectMap: new MultiSelectMap(nestedMultiSelectOptions[key], config),
        isOpen: config.isTopLevelOpen
      }
    }
  }

  toggleOpen(key: string) {
    this.nestedOptions[key].isOpen = !this.nestedOptions[key].isOpen;
  }

  /**
   * Returns the formatted data
   */
  getInfo() {
    const infoObj = {};
    for (const key in this.nestedOptions) {
      const nestedObj = {};
      const multiSelectMap = this.nestedOptions[key].multiSelectMap;

      for (const k in multiSelectMap) {
        nestedObj[k] = multiSelectMap[k].selectedValues;
      }
      infoObj[key] = nestedObj;
    }
    return infoObj;
  }

  hasOneOrMoreFieldsSelected(options: { excludeValues?: Array<string | number> } = {}): boolean {
    const { excludeValues } = options;

    for (const key in this.nestedOptions) {
      const multiSelectMap = this.nestedOptions[key].multiSelectMap;

      for (const item of Object.values(multiSelectMap)) {
        if (excludeValues?.length) {
          if (item.isValidExcluding(excludeValues)) {
            return true;
          }
        }
        else if (item?.isValid) {
          return true;
        }
      }
    }
    return false;
  }


  isValueSelected(value: string | number, keysToCheck?: { topLevelKeys: string[], nestedKeys: string[] }) {
    if (!DataHandler.isDefined(value)) {
      return;
    }

    for (const key in this.nestedOptions) {
      if (!keysToCheck?.topLevelKeys || keysToCheck?.topLevelKeys?.includes(key)) {
        const isValueSelectedInMap = this.isValueSelectedInMap(value, this.nestedOptions[key].multiSelectMap, keysToCheck?.nestedKeys);
        if (isValueSelectedInMap) {
          return true;
        }
      }
    }
    return false;
  }

  private isValueSelectedInMap(value: string | number, multiSelectMap: MultiSelectMap, nestedKeysToCheck?: string[]) {
    for (const key in multiSelectMap) {
      if (multiSelectMap[key].isValid && (!nestedKeysToCheck || nestedKeysToCheck?.includes(key))) {
        const isSelected = multiSelectMap[key].isValueSelected(value);
        if (isSelected) {
          return true;
        }
      }
    }
    return false;
  }

}
