import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { BasicStateModel } from 'src/app/shared/state-updaters/types/basic-state-updater.types';
import { BasicStateUpdater } from 'src/app/shared/state-updaters/basic.state-updater';
import { Subject } from 'rxjs';
import { CustomerInfoSummaryDestroyed } from 'src/app/customer-info-summary-page/store/actions/customer.actions';
import { CheckMigrationResponse } from '../types/check-migration-response.interface';
import { CheckMigrationActions } from '../action/check-migration';
import { MigrationService } from '../../sales-portal/services/migration.service';

export type CheckMigrationStateModel = BasicStateModel<CheckMigrationResponse>;

@State<CheckMigrationStateModel>({
  name: 'CheckMigrationState',
  defaults: BasicStateUpdater.getDefaultState()
})
@Injectable()
export class CheckMigrationState {

  @Selector()
  static isLoading(state: CheckMigrationStateModel) { return state.loading }

  @Selector()
  static isLoaded(state: CheckMigrationStateModel) { return state.loaded }

  @Selector()
  static getData(state: CheckMigrationStateModel) { return state.data }

  @Selector()
  static getError(state: CheckMigrationStateModel) { return state.error }

  @Selector([CheckMigrationState.getData])
  static canMigrate(data: CheckMigrationResponse) { return data?.result?.canMigrate }

  private readonly _cancelRequest$ = new Subject();

  private readonly _stateUpdater = new BasicStateUpdater<CheckMigrationResponse, string>({
    cancelRequest$: this._cancelRequest$,
    errorMessage: "Failed to check migration.",
    notFoundMessage: "No migration found.",
    baseFetchFn: (serviceId: string) => this.migrationService.checkMigration(serviceId),
    ...CheckMigrationActions,
  });

  constructor(private migrationService: MigrationService) { }

  @Action(CheckMigrationActions.Fetch)
  Fetch(ctx: StateContext<CheckMigrationStateModel>, action: CheckMigrationActions.Fetch) {
    return this._stateUpdater.Fetch(ctx, action);
  }

  @Action(CheckMigrationActions.FetchSuccess)
  FetchSuccess(ctx: StateContext<CheckMigrationStateModel>, action: CheckMigrationActions.FetchSuccess) {
    this._stateUpdater.FetchSuccess(ctx, action);
  }

  @Action(CheckMigrationActions.FetchFail)
  FetchFail(ctx: StateContext<CheckMigrationStateModel>, action: CheckMigrationActions.FetchFail) {
    this._stateUpdater.FetchFail(ctx, action);
  }

  @Action([CustomerInfoSummaryDestroyed, CheckMigrationActions.Clear])
  Clear(ctx: StateContext<CheckMigrationStateModel>) {
    this._stateUpdater.Clear(ctx);
  }

}
