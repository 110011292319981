import { LatLngLiteral } from "@google/maps";
import { Selector, createSelector } from "@ngxs/store";
import { AlarmSiteState } from "src/app/shared/elements/map/store/state/alarm-sites.state";
import { NormalSitesState } from "src/app/shared/elements/map/store/state/normal-map-sites.state";
import { DataLoading } from "src/app/shared/interfaces/data-loading.interface";
import { NormalSiteFeatureCollection } from "src/app/shared/services/google-maps/assets/map-site.interfaces";
import { RainAppResponse } from "../../assets/rain-app-response.interface";
import { aggregateCurrentlyConnectedData } from "../functions/aggregate-connection-data";
import { NWDAFConnectedSite } from "../interfaces/nwdaf-connected-site.interface";
import { SiteConnectionData } from "../interfaces/site-connection-data.interface";
import { NWDAFConnectedSiteState, NWDAFConnectedSiteStateModel } from "../state/nwdaf-connected-site.state";
import { TechType } from "src/app/shared/services/google-maps/assets/techtype.type";
import { SimSelectors } from "./sim.selectors";




export class CurrentlyConnectedSiteSelectors {

    @Selector([SimSelectors.selectedIMSI, NWDAFConnectedSiteState])
    static getCurrentlyConnectedSiteState(imsi: string, state: NWDAFConnectedSiteStateModel) {
        return NWDAFConnectedSiteState.getStateByIMSI(imsi)(state);
    }

    @Selector([SimSelectors.selectedIMSI, NWDAFConnectedSiteState])
    static getConnectionStatus(imsi: string, state: NWDAFConnectedSiteStateModel) {
        return NWDAFConnectedSiteState.getStatus(imsi)(state);
    }


    @Selector([CurrentlyConnectedSiteSelectors.getCurrentlyConnectedSiteState])
    static isLoading(dataLoading: DataLoading<NWDAFConnectedSite, string>) {
        return dataLoading?.loading;
    }

    @Selector([CurrentlyConnectedSiteSelectors.getCurrentlyConnectedSiteState])
    static isLoaded(dataLoading: DataLoading<NWDAFConnectedSite, string>) {
        return dataLoading?.loaded;
    }

    @Selector([CurrentlyConnectedSiteSelectors.getCurrentlyConnectedSiteState])
    static isLoadingOrLoaded(dataLoading: DataLoading<NWDAFConnectedSite, string>) {
        const { loading, loaded } = dataLoading ?? {};
        return loading || loaded;
    }

    @Selector([CurrentlyConnectedSiteSelectors.getCurrentlyConnectedSiteState])
    static getError(dataLoading: DataLoading<NWDAFConnectedSite, string>) {
        return dataLoading?.error;
    }

    @Selector([CurrentlyConnectedSiteSelectors.getCurrentlyConnectedSiteState])
    static getData(dataLoading: DataLoading<NWDAFConnectedSite, string>) {
        return dataLoading?.data;
    }

    @Selector([
        CurrentlyConnectedSiteSelectors.isLoaded,
        CurrentlyConnectedSiteSelectors.getData,
        SimSelectors.getSelectedRainAppResponse
    ])
    static getConnectionData(loaded: boolean, connectedSiteData: NWDAFConnectedSite, rainAppResponse: RainAppResponse): SiteConnectionData {
        return aggregateCurrentlyConnectedData(loaded, connectedSiteData, rainAppResponse);
    }

    @Selector([CurrentlyConnectedSiteSelectors.getData])
    static isOnline(data: NWDAFConnectedSite) {
        return data?.online;
    }

    @Selector([CurrentlyConnectedSiteSelectors.isLoaded, NormalSitesState.isLoaded])
    static sitesAndConnectedSiteLoaded(connectedSitesLoaded: boolean, normalSitesLoaded: boolean) {
        return connectedSitesLoaded && normalSitesLoaded;
    }

    @Selector([CurrentlyConnectedSiteSelectors.isLoaded, AlarmSiteState.isLoaded])
    static alarmSitesAndConnectedSiteLoaded(connectedSitesLoaded: boolean, alarmSitesLoaded: boolean) {
        return connectedSitesLoaded && alarmSitesLoaded;
    }

    @Selector([CurrentlyConnectedSiteSelectors.getConnectionData, NormalSitesState.getData])
    static currentFeature(connectionData: SiteConnectionData, features: NormalSiteFeatureCollection): NormalSiteFeatureCollection[number] | null {
        const { siteId, enodeBId } = connectionData ?? {};
        if (siteId) {
            return NormalSitesState.getFeaturesByProperty("siteID", siteId)(features)?.[0] as NormalSiteFeatureCollection[number];
        }

        if (enodeBId) {
            return NormalSitesState.getFeatureByEnodeBId(enodeBId)(features);
        }

        return null;
    }

    @Selector([CurrentlyConnectedSiteSelectors.currentFeature])
    static currentFeatureTech(feature: NormalSiteFeatureCollection[number]) { return feature?.properties?.tech }

    static isCurrentFeatureThisTech(tech: TechType) {
        return createSelector(
            [CurrentlyConnectedSiteSelectors.currentFeatureTech],
            (featureTech: TechType) => tech === featureTech
        );
    }

    @Selector([CurrentlyConnectedSiteSelectors.currentFeature])
    static currentFeatureSiteName(feature: NormalSiteFeatureCollection[number]) { return feature?.properties?.name }

    @Selector([CurrentlyConnectedSiteSelectors.currentFeature])
    static currentFeatureCoordinates(feature: NormalSiteFeatureCollection[number]) { return feature?.geometry?.coordinates }

    @Selector([CurrentlyConnectedSiteSelectors.currentFeatureCoordinates])
    static currentFeatureLatLng(coordinates: [number, number]): LatLngLiteral {
        if (!coordinates?.length) {
            return null;
        }
        const [lng, lat] = coordinates;
        return { lat, lng };
    }

}
