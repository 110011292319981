<sf-popup-modal [isOpen]="true" [hasCloseButton]="false" [autoClose]="false"
  [successResponse]="modalOptions.successResponse" [sending]="modalOptions.sending"
  [spinnerMessage]="modalOptions.spinnerMessage">

  <ng-container header>post collections modal</ng-container>

  <ng-container mainContent>
    <sf-modal-form-container [formGroup]="form">

      <sf-select class="customer-contacted-select" [options]="customerContactedOptions" (onOptionSelect)="onOptionSelect($event)" marginBottom="1rem"
        label="Customer Contacted" [placeholder]="'Customer Contacted'"
        [hasError]="form.get('customer_contacted').errors?.notSelected">
      </sf-select>

      <ng-container *ngIf="isCustomerContacted">
        <sf-form-checkbox class="checkbox" [control]="form.get('take_my_money_now')" label="take my money now">
        </sf-form-checkbox>

        <sf-form-checkbox class="checkbox" [control]="form.get('pay_now')" label="pay now">
        </sf-form-checkbox>

        <sf-form-checkbox class="checkbox" [control]="form.get('logged_PTP')" label="logged PTP">
        </sf-form-checkbox>

        <sf-form-checkbox class="checkbox" [control]="form.get('promise_to_callback')" label="promise to callback">
        </sf-form-checkbox>
      </ng-container>

      <sf-form-checkbox class="checkbox" [control]="form.get('other_paid')" label="other - paid">
      </sf-form-checkbox>

      <sf-form-checkbox class="checkbox" [control]="form.get('other_voicemail')" label="other - voicemail">
      </sf-form-checkbox>

      <sf-form-checkbox class="checkbox" [control]="form.get('other_pay_later')" label="other - pay later">
      </sf-form-checkbox>


      <sf-form-checkbox class="checkbox" [control]="form.get('other_resolved')" label="other - resolved">
      </sf-form-checkbox>

      <sf-form-checkbox class="checkbox" [control]="form.get('device_collected_received')"
        label="device collected or received">
      </sf-form-checkbox>

      <ng-container buttons>
        <div class="send-button-wrapper">
          <sf-rain-button [disabled]="!form || !isOneFieldSelected" (click)="onSend()">send</sf-rain-button>
        </div>

      </ng-container>

    </sf-modal-form-container>
  </ng-container>

</sf-popup-modal>
