import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { WhatsappNotificationActions } from '../../store/actions/whatsapp-notification-actions';
import { convertToFormattedWhatsappMessage } from '../../store/functions/convert-to-formatted-whatsapp-msg';
import { WhatsappMessagesState } from '../../store/state/whatsapp-messages.state';
import { WhatsappNotificationState } from '../../store/state/whatsapp-notification.state';
import { FormattedWhatsappMessage } from '../../store/types/whatsapp-message.interface';

@Component({
  selector: 'sf-whatsapp-message-renderer',
  templateUrl: './whatsapp-message-renderer.component.html',
  styleUrls: ['./whatsapp-message-renderer.component.scss']
})
export class WhatsappMessageRendererComponent implements OnInit {

  @Input() id: string;

  formattedMessage$: Observable<FormattedWhatsappMessage>;

  constructor(private store: Store) { }

  ngOnInit(): void {
    this.formattedMessage$ = this.store.select(WhatsappMessagesState.getMessageByID(this.id))
      .pipe(map(convertToFormattedWhatsappMessage));
  }

  inView() {
    if (this.store.selectSnapshot(WhatsappNotificationState.hasId(this.id))) {

      setTimeout(
        () => this.store.dispatch(new WhatsappNotificationActions.Remove(this.id)),
        300
      )
    }
  }

}
