import { Utils } from "src/app/Utils";
import { AgentStatus, AgentStatusAction, InternalAgentStatus, InternalAgentStatusAction } from "../../../assets/agent-status.type";
import { SipCallType } from "src/app/sip-phone/assets/sip-call.type";


export class SetLoggedOn implements AgentStatusAction {
    readonly status: AgentStatus = "logged on";
    static readonly type = Utils.Helpers.Type("[Agent Status] Logged On");
}

export class SetAvailable implements AgentStatusAction {
    readonly status: AgentStatus = "available";
    static readonly type = Utils.Helpers.Type("[Agent Status] Available");
}


export class SetConnectingCall implements AgentStatusAction {
    readonly status: AgentStatus = "connecting call";
    static readonly type = Utils.Helpers.Type("[Agent Status] Connecting Call");
    constructor(public callType: SipCallType) { }
}

export class SetInCall implements AgentStatusAction {
    readonly status: AgentStatus = "in call";
    static readonly type = Utils.Helpers.Type("[Agent Status] In Call");
    constructor(public callType: SipCallType) { }
}

export class SetConnectedPostCall implements AgentStatusAction {
    readonly status: AgentStatus = "post-call";
    static readonly type = Utils.Helpers.Type("[Agent Status] Post Connected Call");
    constructor(public callType: SipCallType, public durationInSeconds: number, public callId: string) { }
}

export class SetNonConnectedPostCall implements InternalAgentStatusAction {
    readonly status: InternalAgentStatus = "not-connected-post-call";
    static readonly type = Utils.Helpers.Type("[Agent Status] Post Non Connected Call");
    constructor(public callType: SipCallType, public wasEstablishing: boolean) { }
}

export class SetAcceptingTicket implements AgentStatusAction {
    readonly status: AgentStatus = "accepting ticket";
    static readonly type = Utils.Helpers.Type("[Agent Status] Accepting Ticket");
}

export class SetInTicket implements AgentStatusAction {
    readonly status: AgentStatus = "in ticket";
    static readonly type = Utils.Helpers.Type("[Agent Status] In Ticket");
}

export class SetPostTicket implements AgentStatusAction {
    readonly status: AgentStatus = "post-ticket";
    static readonly type = Utils.Helpers.Type("[Agent Status] Post Ticket");
}

export class SetBusy implements AgentStatusAction {
    readonly status: AgentStatus = "busy";
    static readonly type = Utils.Helpers.Type("[Agent Status] Busy");
}

export class SetTeaBreak1 implements AgentStatusAction {
  readonly status: AgentStatus = "tea break 1";
  static readonly type = Utils.Helpers.Type("[Agent Status] Tea Break 1");
}

export class SetBreak implements AgentStatusAction {
    readonly status: AgentStatus = "break";
    static readonly type = Utils.Helpers.Type("[Agent Status] Break");
}

export class SetTeaBreak2 implements AgentStatusAction {
  readonly status: AgentStatus = "tea break 2";
  static readonly type = Utils.Helpers.Type("[Agent Status] Tea Break 2");
}

export class SetCoaching implements AgentStatusAction {
  readonly status: AgentStatus = "coaching";
  static readonly type = Utils.Helpers.Type("[Agent Status] Coaching");
}

export class SetMeeting implements AgentStatusAction {
  readonly status: AgentStatus = "meeting";
  static readonly type = Utils.Helpers.Type("[Agent Status] Meeting");
}

export class SetLoggedOff implements AgentStatusAction {
    readonly status: AgentStatus = "logged off";
    static readonly type = Utils.Helpers.Type("[Agent Status] Logged Off");
}
