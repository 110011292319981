import { Component, Input } from '@angular/core';
import { ButtonHelper } from 'src/app/Utils/helpers/button.helper';


@Component({
  selector: 'sf-only-icon-button',
  templateUrl: './only-icon-button.component.html',
  styleUrls: ['./only-icon-button.component.scss']
})
export class OnlyIconButtonComponent extends ButtonHelper {

  @Input() active = false;
  @Input() color: string;
  @Input() size = "1.2rem";
  @Input() disabled = false;

}
