import { Utils } from "src/app/Utils";
import { AgentActionAction, ExtraLoggingActionAction } from "../../../assets/agent-action.type"
import { RespondToTicketMetaData } from "../../types/respond-to-ticket-payload.interface";


export class TicketRejected implements AgentActionAction {
    readonly actionType = "ticket_rejected";
    static readonly type = Utils.Helpers.Type('[Agent Actions] Ticket rejected');
    constructor(public metaData: RespondToTicketMetaData) { }
}

export class TicketAccepted implements AgentActionAction {
    readonly actionType = "ticket_accepted";
    static readonly type = Utils.Helpers.Type('[Agent Actions] Ticket accepted');
    constructor(public metaData: RespondToTicketMetaData) { }
}

export class CallRejected implements AgentActionAction {
    readonly actionType = "call_rejected";
    static readonly type = Utils.Helpers.Type('[Agent Actions] Call rejected');
}

export class CallAccepted implements AgentActionAction {
    readonly actionType = "call_accepted";
    static readonly type = Utils.Helpers.Type('[Agent Actions] Call accepted');
}

export class CallEnded implements AgentActionAction {
    readonly actionType = "call_ended";
    static readonly type = Utils.Helpers.Type('[Agent Actions] Call ended');
}

export class BrowserBackButtonClicked implements AgentActionAction {
    readonly actionType = "browser_back_button_clicked";
    static readonly type = Utils.Helpers.Type('[Agent Actions] Browser back button clicked');
}

export class HomeButtonClicked implements AgentActionAction {
    readonly actionType = "home_button_clicked";
    static readonly type = Utils.Helpers.Type('[Agent Actions] Home button clicked');
}

export class ToggledCallsOn implements AgentActionAction {
    readonly actionType = "toggled_calls_on";
    static readonly type = Utils.Helpers.Type('[Agent Actions] Toggled calls on');
}

export class ToggledCallsOff implements AgentActionAction {
    readonly actionType = "toggled_calls_off";
    static readonly type = Utils.Helpers.Type('[Agent Actions] Toggled calls off');
}

export class LoggedOff implements AgentActionAction {
    readonly actionType = "logged_off";
    static readonly type = Utils.Helpers.Type('[Agent Actions] Logged off');
}


/* ============================== EXTRA LOGGING ACTIONS ============================== */

export class SentPaymentLink implements ExtraLoggingActionAction {
    readonly actionType = "sent_payment_link";
    static readonly type = Utils.Helpers.Type('[Agent Actions] Sent payment link');
    constructor(public orderNumber: number) { }
}

export class SuccessfulSale implements ExtraLoggingActionAction {
    readonly actionType = "sale_successful";
    static readonly type = Utils.Helpers.Type('[Agent Actions] Sale successful');
    constructor(public hexId: string, public orderNumber: number, public amount: number) { }
}

export class UnsuccessfulSale implements ExtraLoggingActionAction {
    readonly actionType = "sale_unsuccessful";
    static readonly type = Utils.Helpers.Type('[Agent Actions] Sale unsuccessful');
    constructor(public hexId: string, public orderNumber: number, public amount: number) { }
}
