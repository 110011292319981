import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { AgentBrowserActionTracker } from '../../services/agent-browser-action-tracker';
import { AddAgentBrowserActionTracker, SetAcceptingCalls, SetAcceptingTickets, ToggleAcceptingCalls, ToggleAcceptingTickets } from '../actions/agent.actions';
import { AgentStatus, AgentStatusAction } from '../../assets/agent-status.type';
import { SetAvailable, SetBreak, SetBusy, SetConnectingCall, SetInCall, SetInTicket, SetLoggedOff, SetLoggedOn, SetConnectedPostCall, SetNonConnectedPostCall, SetPostTicket, SetAcceptingTicket, SetCoaching, SetMeeting, SetTeaBreak1, SetTeaBreak2 } from '../actions/agent-status-actions/agent-status.actions';
import { AGENT_VIEW_ROUTE, CUSTOMER_INFO_SUMMARY_ROUTE } from 'src/app/constants';
import { Router } from '@angular/router';
import { Subject, timer } from 'rxjs';
import { take, takeUntil, tap } from 'rxjs/operators';
import { getAgentStatusColorChoice } from '../../assets/rain-agent-colors.constant';
import { AgentActionAction } from '../../assets/agent-action.type';
import { AgentTrackingService } from '../../services/agent-tracking.service';
import { AgentActions } from '../actions/agent-action-actions';


interface SetStatusOptions {
    logStatus: boolean;
}

const TIMEOUT = 500;

const isSameStatus = (ctx: StateContext<RainAgentStateModel>, action: AgentStatusAction) => ctx.getState().status === action.status;

export interface RainAgentStateModel {
    status: AgentStatus;
    loggedOnTime: string;
    acceptingTickets: boolean;
    acceptingCalls: boolean;
}

@State<RainAgentStateModel>({
    name: 'rain_agent_state',
    defaults: {
        status: null,
        loggedOnTime: null,
        acceptingTickets: false,
        acceptingCalls: false
    }
})
@Injectable()
export class AgentState {

    @Selector([AgentState])
    static getState(state: RainAgentStateModel) { return state; }

    @Selector([AgentState])
    static getStatus(state: RainAgentStateModel) { return state.status; }

    @Selector([AgentState])
    static isAcceptingCalls(state: RainAgentStateModel) { return state.acceptingCalls; }

    @Selector([AgentState])
    static isAcceptingTickets(state: RainAgentStateModel) { return state.acceptingTickets; }

    @Selector([AgentState])
    static getLoggedOnTime(state: RainAgentStateModel) { return state.loggedOnTime; }

    @Selector([AgentState.getStatus])
    static getTextAndBackgroundColor(status: AgentStatus) { return getAgentStatusColorChoice(status); }

    private readonly _cancelStatusUpdate$ = new Subject<void>();

    constructor(private router: Router,
        private agentTrackingService: AgentTrackingService,
        private agentBrowserActionTracker: AgentBrowserActionTracker) { }


    @Action([SetLoggedOn])
    setLoggedOn(ctx: StateContext<RainAgentStateModel>, action: SetLoggedOn) {
        this.setStatus(ctx, action);

        ctx.patchState({
            loggedOnTime: new Date(Date.now()).toString()
        });
    }

    @Action([SetLoggedOff])
    setLoggedOff(ctx: StateContext<RainAgentStateModel>, action: SetLoggedOff) {
        //Do not log "logged off" as a status change on the backend, it is logged as an action

        this.setStatus(ctx, action, { logStatus: false });
        return ctx.dispatch(new AgentActions.LoggedOff());
    }

    @Action([SetInTicket])
    setInTicket(ctx: StateContext<RainAgentStateModel>, action: SetInTicket) {
        const currentStatus = ctx.getState().status;
        if (currentStatus === "in call" || currentStatus === "connecting call") {
            return;
        }

        this.setStatus(ctx, action);
    }

    @Action([SetBusy])
    SetBusy(ctx: StateContext<RainAgentStateModel>, action: SetBusy) {
        const currentStatus = ctx.getState().status;
        if (currentStatus === "in ticket" && this.inCustomerTicket()) {
            return;
        }

        this.setStatus(ctx, action);
    }

    @Action([SetNonConnectedPostCall])
    SetNonConnectedPostCall(ctx: StateContext<RainAgentStateModel>) {
        const currentStatus = ctx.getState().status;
        if (currentStatus === "in ticket" && this.inCustomerTicket()) {
            return;
        }

        return ctx.dispatch(new SetBusy());
    }

    @Action([SetConnectedPostCall])
    setPostCall(ctx: StateContext<RainAgentStateModel>, action: SetConnectedPostCall) {
        this.setStatus(ctx, action);

        return this.getTimer()
            .pipe(
                tap({
                    next: () => {
                        const currentStatus = ctx.getState().status;
                        if (currentStatus === "available") {
                            return;
                        }

                        return this.inCustomerTicket()
                            ? ctx.dispatch(new SetInTicket())
                            : ctx.dispatch(new SetBusy());

                    }
                })
            );
    }

    @Action([SetPostTicket])
    setPostTicket(ctx: StateContext<RainAgentStateModel>, action: SetPostTicket) {
        this.setStatus(ctx, action);

        return this.getTimer()
            .pipe(
                tap({
                    next: () => {
                        const currentStatus = ctx.getState().status;
                        if (currentStatus === "available") {
                            return;
                        }

                        return this.inAgentView()
                            ? ctx.dispatch(new SetAvailable())
                            : ctx.dispatch(new SetBusy());

                    }
                })
            );
    }

    @Action([SetAcceptingTicket])
    setAcceptingTicket(ctx: StateContext<RainAgentStateModel>, action: SetAcceptingTicket) {
        this.setStatus(ctx, action, { logStatus: false });
    }

  @Action([SetAvailable, SetBreak, SetTeaBreak1, SetTeaBreak2, SetCoaching, SetMeeting, SetInCall, SetConnectingCall])
    defaultStatusUpdate(ctx: StateContext<RainAgentStateModel>, action: AgentStatusAction) {
        this.setStatus(ctx, action);
    }

    private setStatus(ctx: StateContext<RainAgentStateModel>, action: AgentStatusAction, options: SetStatusOptions = { logStatus: true }) {
        this._cancelStatusUpdate$.next();

        if (isSameStatus(ctx, action)) {
            return;
        }

        const { status } = action;
        ctx.patchState({
            status
        });

        if (options.logStatus) {
            this.agentTrackingService.sendAgentStatusUpdate(status);
        }
    }

    private getTimer() {
        return timer(TIMEOUT)
            .pipe(
                take(1),
                takeUntil(this._cancelStatusUpdate$.pipe(take(1)))
            );
    }

    private inCustomerTicket() {
        return this.router.url?.includes(CUSTOMER_INFO_SUMMARY_ROUTE);
    }

    private inAgentView() {
        return this.router.url?.includes(AGENT_VIEW_ROUTE);
    }

    @Action(ToggleAcceptingCalls)
    toggleAcceptingCalls(ctx: StateContext<RainAgentStateModel>) {
        const acceptingCalls = !ctx.getState().acceptingCalls;

        ctx.patchState({
            acceptingCalls
        });

        const agentAction: AgentActionAction = acceptingCalls
            ? new AgentActions.ToggledCallsOn()
            : new AgentActions.ToggledCallsOff();

        return ctx.dispatch(agentAction);
    }

    @Action(SetAcceptingCalls)
    SetAcceptingCalls(ctx: StateContext<RainAgentStateModel>, action: SetAcceptingCalls) {

        ctx.patchState({
            acceptingCalls: action.acceptingCalls
        });
    }

    @Action(ToggleAcceptingTickets)
    toggleAcceptingTickets(ctx: StateContext<RainAgentStateModel>) {
        const { acceptingTickets } = ctx.getState();

        ctx.patchState({
            acceptingTickets: !acceptingTickets
        })
    }

    @Action(SetAcceptingTickets)
    SetAcceptingTickets(ctx: StateContext<RainAgentStateModel>, action: SetAcceptingTickets) {

        ctx.patchState({
            acceptingTickets: action.acceptingTickets
        });
    }

    @Action(AddAgentBrowserActionTracker)
    addAgentBrowserActionTracker() {
        this.agentBrowserActionTracker.listenForBackButtonClicks();
    }

}
