import { Component, OnDestroy } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { BehaviorSubject, Subject } from 'rxjs';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { takeUntil } from 'rxjs/operators';

export interface FirestoreCollectionMaintenance {
    isInMaintenance: boolean;
}

@Component({
    selector: 'sf-maintenance-overlay',
    templateUrl: './maintenance-overlay.component.html',
    styleUrls: ['./maintenance-overlay.component.scss']
})


export class MaintenanceOverlayComponent implements OnDestroy {

    public isInMaintenanceMode$ = new BehaviorSubject<boolean>(false);

    private ngDestroy$ = new Subject<void>();

    constructor(private firestore: AngularFirestore,
        private router: Router) {

        this.listenToMaintenanceCollection();
    }

    private listenToMaintenanceCollection(): void {

        this.firestore
            .collection('maintenance')
            .doc<FirestoreCollectionMaintenance>(environment.maintenanceFireStoreCollection)
            .valueChanges()
            .pipe(
                takeUntil(this.ngDestroy$)
            )
            .subscribe(collectionConfig => {
                const { isInMaintenance } = collectionConfig ?? {};

                if (isInMaintenance) {
                    this.router.navigate(["'/'"]);
                }
                this.isInMaintenanceMode$.next(isInMaintenance);
            });

    }

    ngOnDestroy(): void {
        this.ngDestroy$.next(null);
    }

}
