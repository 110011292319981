

import { Injectable } from '@angular/core';
import { Action, State, StateContext, Selector } from '@ngxs/store';
import { SipPhoneActions } from '../actions/sip-phone-actions';
import { VoiceTicketActions } from '../actions/voice-ticket-actions';
import { DEFAULT_CALL_EVENT_NAME, INCOMING_CALL_TITLE, VoiceTicketSubject } from '../constants/call-ticket-and-event-names.constants';
import { CallTicketEventName } from '../interfaces/call-ticket-event-names.interface';


interface VoiceTicketStateModel {
    callTicketEventName: CallTicketEventName;
    callTicketSubject: VoiceTicketSubject;
}
@State<VoiceTicketStateModel>({
    name: 'sf_voice_ticket_state',
    defaults: {
        callTicketEventName: DEFAULT_CALL_EVENT_NAME,
        callTicketSubject: INCOMING_CALL_TITLE
    }
})
@Injectable()
export class VoiceTicketState {

    @Selector()
    static getCallTicketEventName(state: VoiceTicketStateModel) { return state.callTicketEventName }

    @Selector()
    static getCallTicketSubject(state: VoiceTicketStateModel) { return state.callTicketSubject }

    @Action(VoiceTicketActions.SetCallTicketEventName)
    setCallTicketEventName(ctx: StateContext<VoiceTicketStateModel>, action: VoiceTicketActions.SetCallTicketEventName) {
        ctx.patchState({
            callTicketEventName: action.name
        })
    }

    @Action([VoiceTicketActions.ResetCallTicketEventName, SipPhoneActions.CallSessionEnded])
    resetCallTicketEventName(ctx: StateContext<VoiceTicketStateModel>) {
        ctx.patchState({
            callTicketEventName: DEFAULT_CALL_EVENT_NAME
        })
    }

    @Action(VoiceTicketActions.SetCallTicketSubject)
    setCallTicketSubject(ctx: StateContext<VoiceTicketStateModel>, action: VoiceTicketActions.SetCallTicketSubject) {
        ctx.patchState({
            callTicketSubject: action.subject
        })
    }

    @Action([VoiceTicketActions.ResetCallTicketSubject, SipPhoneActions.CallSessionEnded])
    resetCallTicketSubject(ctx: StateContext<VoiceTicketStateModel>) {
        ctx.patchState({
            callTicketSubject: INCOMING_CALL_TITLE
        })
    }



}