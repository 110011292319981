import *  as filterSearchFns from "./filter-search.function";
import * as Filters from "./filter.functions";
import * as updateFilterFns from "./update-filter.function";
import * as partitionFns from "./partition.funtion";


const FilterFunctions = {
    ...Filters,
    ...filterSearchFns,
    ...updateFilterFns,
    ...partitionFns
}

export { FilterFunctions };