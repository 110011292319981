

export function trimObjKeys<T>(obj: T) {
  if (!Array.isArray(obj) && typeof obj !== 'object') {
    return obj;
  }
  return Object.keys(obj)
    .reduce((acc, key) => {
      acc[key.trim()] = typeof obj[key] === 'string' ? obj[key].trim() : trimObjKeys(obj[key]);
      return acc;
    }, Array.isArray(obj) ? [] : {}) as T;
}
