import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { RouterState } from "@ngxs/router-plugin";
import { Selector, createSelector } from "@ngxs/store";
import { AppParamKey, AppQueryParamKey } from "../constants/route.constants";
import { Dictionary } from "../interfaces/dictionary.interface";

interface ParamDict {
  queryParamDict: Dictionary<string>;
  paramDict: Partial<Record<AppParamKey, string>>;
}

const mapParams = (state: ActivatedRouteSnapshot, dict: ParamDict = { queryParamDict: {}, paramDict: {} }): ParamDict => {

  const { queryParamDict, paramDict } = dict;
  const { firstChild, queryParams, params } = state ?? {};

  const newDict: ParamDict = {
    queryParamDict: {
      ...queryParamDict,
      ...queryParams
    },
    paramDict: {
      ...paramDict,
      ...params
    }
  }

  if (!firstChild) {
    return newDict;

  }
  return mapParams(firstChild, newDict);
}


export class RouteParamSelectors {

  @Selector([
    RouterState.state
  ])
  static getMappedParams(state: ActivatedRouteSnapshot | undefined): ParamDict {
    if (!state) {
      return {
        paramDict: {},
        queryParamDict: {}
      }
    }

    return mapParams(state);
  }


  static getParamMapValue(key: AppParamKey) {
    return createSelector(
      [RouteParamSelectors.getMappedParams],
      (dict: ParamDict): string | undefined => dict.paramDict[key]
    )
  }

  static getQueryParamMapValue(key: AppQueryParamKey) {
    return createSelector(
      [RouteParamSelectors.getMappedParams],
      (dict: ParamDict): string | undefined => dict.queryParamDict[key]
    )
  }
}
