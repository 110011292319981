import { Utils } from "src/app/Utils";
import { NonTextCurrentMediaType } from "../../types/whatsapp-message.interface";

export interface MediaFetchPayload {
    id: string;
    type: NonTextCurrentMediaType;
    mimeType: string;
    dataUrl: string;
}

export class FetchOrToggle {
    static readonly type = Utils.Helpers.Type("[WhatsappMediaActions] Init");
    constructor(public payload: MediaFetchPayload) { }
}

export class Fetch {
    static readonly type = Utils.Helpers.Type("[WhatsappMediaActions] Fetch");
    constructor(public payload: MediaFetchPayload) { }
}

export class FetchSuccess {
    static readonly type = Utils.Helpers.Type("[WhatsappMediaActions] Fetch success");
    constructor(public payload: Omit<MediaFetchPayload, "dataUrl" | "mimeType"> & { response: string }) { }
}

export class FetchFail {
    static readonly type = Utils.Helpers.Type("[WhatsappMediaActions] Fetch fail");
    constructor(public id: string, public error: any) { }
}

export class ToggleVisibility {
    static readonly type = Utils.Helpers.Type("[WhatsappMediaActions] Toggle if the image is open");
    constructor(public id: string, public visible?: boolean) { }
}

export class Clear {
    static readonly type = Utils.Helpers.Type("[WhatsappMediaActions] Clear");
}