import { Injectable } from '@angular/core';
import { Action, createSelector, Selector, State, StateContext, } from '@ngxs/store';
import { Subject, timer } from 'rxjs';
import { take, takeUntil, tap } from 'rxjs/operators';
import { MILISECONDS_IN_MINUTE } from 'src/app/constants';
import { AlarmService } from 'src/app/map/services/alarm-service/alarm-service.service';
import { FilterFunctions } from 'src/app/shared/functions/filter';
import { GeoFunctions } from 'src/app/shared/functions/geo-functions';
import { DataLoading, getDataLoadingDefaultValues } from 'src/app/shared/interfaces/data-loading.interface';
import { AlarmSiteFeatureCollection, AlarmSiteProperties } from 'src/app/shared/services/google-maps/assets/map-site.interfaces';
import { Utils } from 'src/app/Utils';
import { DataLoadingHelper } from 'src/app/Utils/helpers';
import { AlarmSiteActions } from '../actions/alarm-site-actions';
import moment from 'moment';
import { TechType } from 'src/app/shared/services/google-maps/assets/techtype.type';


const TWO_MINUTES = 2 * MILISECONDS_IN_MINUTE;
const PROLONGED_ALARM_HOURS = 24;

const getDefaults = (): AlarmSiteStateModel => {
    return {
        ...getDataLoadingDefaultValues([]),
        initialized: false,
        lastUpdatedTime: 0,
    }
}

interface AlarmSiteStateModel extends DataLoading<AlarmSiteFeatureCollection> {
    lastUpdatedTime: number;
    initialized: boolean;
}


@State<AlarmSiteStateModel>({
    name: 'sf_alarm_site_state',
    defaults: getDefaults()
})
@Injectable()
export class AlarmSiteState {

    @Selector()
    static isLoading(state: AlarmSiteStateModel) { return state.loading }

    @Selector()
    static isLoaded(state: AlarmSiteStateModel) { return state.loaded }

    @Selector()
    static getData(state: AlarmSiteStateModel) { return state.data ?? [] }

    @Selector()
    static getError(state: AlarmSiteStateModel) { return state.error }


    static getAlarmsByProperty<P extends keyof AlarmSiteProperties, V extends AlarmSiteProperties[P]>
        (property: P, value: V) {
        return createSelector(
            [AlarmSiteState.getData],
            (alarmFeatures: AlarmSiteFeatureCollection) =>
                GeoFunctions.filterFeaturesByProperty(alarmFeatures ?? [], property, value)
        );
    }

    static getNormalAndProlongedAlarmsByTech(techTypes: TechType[]) {
        return createSelector(
            [AlarmSiteState.getData],
            (features: AlarmSiteFeatureCollection) => {

                const correctFeatures = features.filter(f =>
                    f?.properties?.status === 'OSS HO'
                    && techTypes.includes(f?.properties?.tech)
                    && (f?.properties?.severity === "CRITICAL" || f?.properties?.loadshedding)
                );

                const [prolongedAlarmFeatures, normalAlarmFeatures] = FilterFunctions.partition(
                    correctFeatures,
                    f => {
                        const { severity, alarmdate } = f?.properties;
                        if (alarmdate && severity === "CRITICAL") {
                            return moment().diff(moment(alarmdate), "hours") >= PROLONGED_ALARM_HOURS;
                        }
                        return false;
                    }
                );
                return { prolongedAlarmFeatures, normalAlarmFeatures };
            }

        );
    }

    private readonly _cancel$ = new Subject<void>();

    constructor(private alarmService: AlarmService) {
    }


    @Action(AlarmSiteActions.Initialize)
    Initialize(ctx: StateContext<AlarmSiteStateModel>) {
        const { initialized, loading } = ctx.getState();

        if (initialized) {
            return;
        }

        ctx.patchState({
            initialized: true
        });

        //Don't want NGXS to manage this sub, so not returning the Observable
        timer(0, TWO_MINUTES)
            .pipe(
                takeUntil(this._cancel$.pipe(take(1))),
            )
            .subscribe({
                next: () => {
                    if (!loading) {
                        ctx.dispatch(new AlarmSiteActions.Fetch());
                    }
                }
            });
    }

    @Action(AlarmSiteActions.Fetch)
    Fetch(ctx: StateContext<AlarmSiteStateModel>) {

        ctx.patchState({ loading: true });

        return this.alarmService.fetchAlarms()
            .pipe(
                tap({
                    next: res => {
                        if (!res?.data?.length) {
                            return ctx.dispatch(new AlarmSiteActions.FetchFail("Faild to fetch alarms. Response was empty."));
                        }
                        return ctx.dispatch(new AlarmSiteActions.FetchSuccess(res.data));
                    },
                    error: (e: unknown) => ctx.dispatch(new AlarmSiteActions.FetchFail(e))
                }),
                takeUntil(this._cancel$)
            );
    }


    @Action(AlarmSiteActions.FetchSuccess)
    FetchSuccess(ctx: StateContext<AlarmSiteStateModel>, action: AlarmSiteActions.FetchSuccess) {
        const { payload } = action;
        ctx.patchState({
            ...DataLoadingHelper.dataLoaded(payload),
            lastUpdatedTime: Date.now(),
        });
    }


    @Action(AlarmSiteActions.FetchFail)
    FetchFail(ctx: StateContext<AlarmSiteStateModel>, action: AlarmSiteActions.FetchFail) {
        const error = Utils.Helpers.findError(action.error, '');
        const message = `Failed to fetch alarms. ${error}`;
        DataLoadingHelper.setError(ctx, message);

        ctx.patchState({
            loaded: true,
            loading: false,
            error,
        });
    }

    @Action(AlarmSiteActions.Refresh)
    Refresh(ctx: StateContext<AlarmSiteStateModel>) {
        return ctx.dispatch([
            new AlarmSiteActions.Clear(),
            new AlarmSiteActions.Fetch()
        ]);
    }

    @Action(AlarmSiteActions.Clear)
    Clear(ctx: StateContext<AlarmSiteStateModel>) {
        this._cancel$.next(null);
        ctx.setState(getDefaults());
    }

}
