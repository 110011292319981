import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxsModule } from '@ngxs/store';
import { SharedModule } from '../shared/shared.module';
import { VoicePBXAuthState } from './store/state/voice-pbx-auth.state';
import { SipPhoneState } from './store/state/sip.state';
import { VoiceAuthState } from './store/state/voice-auth.state';
import { AgentVoiceQueueState } from './store/state/agent-voice-queue-state';
import { VoiceCampaignState } from './store/state/voice-campaign.state';
import { VoiceTicketState } from './store/state/voice-ticket-info.state';
import { InteractionsModule } from '../interactions/interactions.module';
import { CallTimerState } from './store/state/call-timer.state';


@NgModule({
  declarations: [
  ],
  imports: [
    CommonModule,
    SharedModule,
    InteractionsModule,
    NgxsModule.forFeature([
      VoiceAuthState,
      AgentVoiceQueueState,
      SipPhoneState,
      VoicePBXAuthState,
      VoiceCampaignState,
      VoiceTicketState,
      CallTimerState
    ])
  ],
})
export class SipPhoneModule { }
