import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChatBubbleTemplateComponent } from './chat-bubble-template/chat-bubble-template.component';
import { SharedModule } from '../../shared.module';
import { ChatLetterBubbleComponent } from './chat-bubble-template/elements/chat-letter-bubble/chat-letter-bubble.component';
import { ChatDateTimeComponent } from './chat-bubble-template/elements/chat-date-time/chat-date-time.component';
import { ChatTicksComponent } from './chat-bubble-template/elements/chat-ticks/chat-ticks.component';
import { PrettyJsonNoteModule } from '../pretty-json-note/pretty-json-note.module';


const declarations = [
    ChatBubbleTemplateComponent,
];

@NgModule({
    declarations: [
        ChatLetterBubbleComponent,
        ChatDateTimeComponent,
        ChatTicksComponent,
        ...declarations
    ],
    imports: [
        CommonModule,
        SharedModule,
        PrettyJsonNoteModule
    ],
    exports: [
        ...declarations
    ]
})
export class ChatElementsModule { }
