import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Store } from '@ngxs/store';
import { SF_TICKET_PREFIX } from 'src/app/constants';
import { ToggleModalByName } from 'src/app/core/store/actions/modal.actions';
import { TicketEventHandlerService } from 'src/app/shared/customer-ticket/ticket-event-handler/ticket-event-handler.service';
import { createSFselectOptions } from 'src/app/shared/functions/create-sf-select-options.function';
import { FormHelper } from 'src/app/Utils/helpers';
import { NewTicketOptions } from './assets/new-ticket-options.model';
import { NewTicketOptionsService } from './new-ticket-options.service';
import { SnowflakeSelectOption } from 'src/app/shared/elements/rain-forms/elements/snowflake-select/interfaces/rain-select-option.interface';

@Component({
    selector: 'new-ticket-modal',
    templateUrl: './new-ticket-modal.component.html',
    styleUrls: ['./new-ticket-modal.component.scss']
})
export class NewTicketModalComponent extends FormHelper implements OnInit, OnDestroy {

    @Input() customerEmail: string;


    ticketNameOptions: SnowflakeSelectOption[];
    marginBottom = "0.75rem";
    selectedNewOptions: NewTicketOptions = null;

    isFormValid = false;

    public form: FormGroup;

    constructor(private fb: FormBuilder,
        private ticketEventHandler: TicketEventHandlerService,
        private store: Store,
        private newTicketOptionsService: NewTicketOptionsService) {
        super();
    }

    ngOnInit(): void {
        this.ticketNameOptions = createSFselectOptions(this.newTicketOptionsService.getNewTicketNames());
        this.buildForm();
        this.InitForm(this.form);
    }

    buildForm() {
        this.form = this.fb.group({
            ticketName: this.fb.control('', Validators.compose([Validators.required])),
            postfix: this.fb.control('')
        });
    }

    onClose() {
        this.store.dispatch(new ToggleModalByName("new_customer_ticket_modal", false));
    }

    onAddNewTicket() {
        const formData = this.form.getRawValue();
        const postfix = formData.postfix;
        let ticketName = `${SF_TICKET_PREFIX} ${this.selectedNewOptions.title}`;

        if (postfix) {
            ticketName += ` ${postfix}`;
        }

        this.ticketEventHandler.createNewTicket(ticketName, ticketName, this.customerEmail, true);
        this.onClose();
    }

    onOptionSelect(option: string, control_name: string) {
        if (control_name === "ticketName") {
            this.selectedNewOptions = null;
            setTimeout(() => this.setSelectedNewOptions(option), 20);
        }

        setTimeout(() => {
            this.updateControl(option, control_name);
            this.form.updateValueAndValidity();
            this.isValidPostfix();
        }, 40);
    }

    private setSelectedNewOptions(option: string) {
        //reset postfix
        this.updateControl('', 'postfix');
        this.selectedNewOptions = this.newTicketOptionsService.getOptions(option);
    }

    isValidPostfix() {
        const postfixValue = this.form.getRawValue().postfix;
        this.isFormValid = !(this.selectedNewOptions?.subOptions?.length > 0 && !postfixValue);
    }


    ngOnDestroy(): void {
        this.onClose();
    }

}
