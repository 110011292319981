

export const TICKET_STATES = {
  INITIATED: 0,
  ACKNOWLEDGED: 1,
  REJECTED: 2,
  PENDING: 3,
  HELD: 4,
  IN_PROGRESS: 5,
  CANCELLED: 6,
  CLOSED: 7,
  RESOLVED: 8
}

export const NOTE_CHANNELS = {
  UNKNOWN: 0,
  EVENTS: 1,
  INBOUND_VOICE: 2,
  EMAIL_META_DATA: 3,
  WORKAROUND_FORMS: 4,
  INBOUND_EMAIL: 5,
  HTML: 6,
}

