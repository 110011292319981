import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { environment } from 'src/environments/environment';
import { FetchCustomerDetailsSuccess, SetCustomerDetailsSearchParam } from '../customer-info-summary-page/store/actions/customer.actions';
import { CustomerInfoState } from '../customer-info-summary-page/store/state/customer-info.state';
import { BasicCustomer, Customer } from '../shared/interfaces/smartsub-data.interface';
import { ApiControllerService } from '../shared/services/api-service/api-controller.service';
import { ApiResponse } from '../shared/services/api-service/api-response.interface';
import { CustomerDataService } from '../shared/services/customer-data.service';
import { SearchByIMSIResponse, SearchByNamePayload, SearchByOrderResponse, CustomerResponse } from './store/search.interfaces';
import { HttpClient } from '@angular/common/http';
import { DEFAULT_HTTP_OPTIONS } from '../constants';
import { BehaviorSubject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class SearchService {

  //TODO: yes I know this is kak, will fix later
  readonly searching$ = new BehaviorSubject(false);

  constructor(private customerDataService: CustomerDataService,
    private apiService: ApiControllerService,
    private http: HttpClient,
    private store: Store) { }


  /**
   * Verify if a customer can be found on smartsub. If found, the data will be stored in the @ngxs/store.
   *
   * @param query the search query, can include the customer's email, rain msisdn, normal msisdn or id-number.
   * @returns true if the customer was found else false.
   */
  async checkIfOnSmartsub(query: string) {
    this.searching$.next(true);
    const response = await this.customerDataService.getWrappedSmartsubDataAsPromise(query);
    this.searching$.next(false);

    if (response?.data) {
      this.store.dispatch([
        new SetCustomerDetailsSearchParam(query),
        new FetchCustomerDetailsSuccess(response.data, false)
      ]);
      return true;
    }
    else {
      return false;
    }
  }


  async searchCustomerDetailsByName(firstAndLastName: SearchByNamePayload): Promise<ApiResponse<BasicCustomer[] | { user: Customer; }>> {
    const httpOptions = {
      headers: {
        'Content-Type': 'application/json',
        apiKey: environment.apigeeApiKey,
      }
    };

    const url = `${environment.customercare}/north/customer/profile/name`;
    return this.apiService.post(url, httpOptions, firstAndLastName);
  }

  findCustomerDetailsByPassport(passportNumber: string) {
    const url = `${environment.main_proxy}/fullcircle/api/v8/partner/detailed_customer_profile/id_number/${passportNumber}`;
    return this.http.get<CustomerResponse>(url, DEFAULT_HTTP_OPTIONS);
  }

  findCustomerDetailsByICCID(iccid: string) {
    const url = `${environment.main_proxy}/fullcircle/api/v8/partner/detailed_customer_profile/iccid/${iccid}`;
    return this.http.get<CustomerResponse>(url, DEFAULT_HTTP_OPTIONS);
  }

  checkForCachedCustomerEmail() {
    const email = this.store.selectSnapshot(CustomerInfoState.getCustomerEmail);
    if (email) {
      return email;
    }

    //Check if recent search param was an email
    const recentSearchParam = this.store.selectSnapshot(CustomerInfoState.getMostRecentSearchParam);
    if (recentSearchParam?.includes("@")) {
      return recentSearchParam;
    }

    return null;
  }


  findCustomerByOrderId(orderId: string) {
    const url = `${environment.smartSubApi}/data/get_email_from_order_id/${orderId}`;
    return this.http.get<SearchByOrderResponse>(url, DEFAULT_HTTP_OPTIONS);
  }

  findCustomerByOrderNumber(orderNumber: number) {
    const url = `${environment.smartSubApi}/data/get_email_from_order_number/${orderNumber}`;
    return this.http.get<SearchByOrderResponse>(url, DEFAULT_HTTP_OPTIONS);
  }

  findCustomerByIMSI(imsi: string) {
    const url = `${environment.main_proxy}/customer-info-service/customer/imsi/${imsi}`;
    return this.http.get<{ result: SearchByIMSIResponse }>(url, DEFAULT_HTTP_OPTIONS)
  }
}
