export function isArray(object: any) {
  return Array.isArray(object);
}

export function isBigInt(x) {
  try {
    return BigInt(x) === x; // dont use == because 7 == 7n but 7 !== 7n
  } catch (error) {
    return false; // conversion to BigInt failed, surely it is not a BigInt
  }
}

export function isHex(id) {
  const re = /^[0-9a-fA-F]+$/;
  if (re.test(id)) {
    return true
  } else {
    return false
  }

  re.lastIndex = 0;
}

export function isHTMLString(str: string): boolean {
  return /<\/?[a-z][\s\S]*>/i.test(str);
}

export function isEmailString(str: string): boolean {
  return str?.includes('From:' || 'Sent:' || 'To:' || 'Cc:' || 'Subject:');
}
