import { Component, Input } from '@angular/core';
import { Store } from '@ngxs/store';
import { AgentNotification } from '../../store/types/agent-notification.type';
import { AgentNotificationActions } from '../../store/actions/agent-notification-actions';

@Component({
  selector: 'sf-agent-notification',
  templateUrl: './agent-notification.component.html',
  styleUrls: ['./agent-notification.component.scss']
})
export class AgentNotificationComponent {

  @Input() notification: AgentNotification;

  hoveringOverCancel = false;

  constructor(private store: Store) { }

  markAsViewed() {
    this.store.dispatch(new AgentNotificationActions.MarkAsViewed(this.notification.id));
  }

  executeAction() {
    if (this.hoveringOverCancel) {
      return;
    }

    const actions = this.notification.clickActions;
    if (actions?.length) {
      this.store.dispatch(actions);
    }

    this.removeNotification();
  }

  removeNotification() {
    this.store.dispatch(new AgentNotificationActions.RemoveNotification(this.notification.id));
  }

  setIsHoveringOverCancel(hovering: boolean) {
    this.hoveringOverCancel = hovering;
  }


}
