import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SNOWFLAKE_FE_PROXY } from 'src/environments/env.constants';
import { Store } from '@ngxs/store';
import { AzureAuthState } from '../store/state/azure-auth.state';
import { filter, switchMap, take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable()
export class MainInterceptor implements HttpInterceptor {

  constructor(private store: Store) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const url = request?.url;

    if (url?.includes(SNOWFLAKE_FE_PROXY)) {
      return this.handleSFproxyRoute(request, next);
    }

    return next.handle(request);
  }

  private handleSFproxyRoute(request: HttpRequest<unknown>, next: HttpHandler) {
    const loaded = this.store.selectSnapshot(AzureAuthState.isLoaded);
    const addTokenFn = () => this.addTokenToRequest(this.getAzureToken(true), request, next);

    if (loaded) {
      return addTokenFn();
    }

    //If not loaded, wait until the token has loaded.
    return this.store.select(AzureAuthState.isLoaded)
      .pipe(
        filter(loaded => loaded),
        take(1),
        switchMap(addTokenFn)
      );
  }

  private getAzureToken(validate = false) {
    const token = this.store.selectSnapshot(AzureAuthState.getIdToken);
    return validate ? this.validateToken(token, "Azure") : token;
  }

  private validateToken(token: string, tokenName?: "Azure" | "IDM") {
    if (!token) {
      throw new Error(`Failed to make request. ${tokenName ?? ""} Authentication token is missing.`);
    }
    return token;
  }

  private addTokenToRequest(token: string, request: HttpRequest<unknown>, next: HttpHandler) {

    const newRequest = request.clone({
      setHeaders: {
        apiKey: environment.main_proxy_api_key,
        Authorization: 'Bearer ' + token,
      }
    });
    return next.handle(newRequest);
  }

}
