import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Select, Store } from '@ngxs/store';
import { Observable, Subject } from 'rxjs';
import { CustomerInfoState } from 'src/app/customer-info-summary-page/store/state/customer-info.state';
import { SnowflakeSelectOption } from 'src/app/shared/elements/rain-forms/elements/snowflake-select/interfaces/rain-select-option.interface';
import { TICKET_STATES } from 'src/app/shared/customer-ticket/ticket-event-handler/assets/ticket.constants';
import { TicketEventHandlerService } from 'src/app/shared/customer-ticket/ticket-event-handler/ticket-event-handler.service';
import { EscalationService } from './escalation.service';
import { RainAgent } from 'src/app/shared/components/agent/rain-agent.service';
import { AgentDetails } from 'src/app/shared/components/agent/assets/agent-details.model';
import { GooglePlacesAddress, GooglePlacesResponseData, } from 'src/app/shared/elements/rain-forms/elements/snowflake-address-search/assets/google-places-address.interface';
import { CustomerAddress, SmartSubData } from 'src/app/shared/interfaces/smartsub-data.interface';
import { SnowflakeAddressInputsComponent } from 'src/app/shared/elements/rain-forms/elements/snowflake-address-inputs/snowflake-address-inputs.component';
import { takeUntil } from 'rxjs/operators';
import { FormHelper } from 'src/app/Utils/helpers';
import { CoverageService } from 'src/app/customer-data-components/sim-details/sim-card-page/sub-components/coverage/services/coverage.service';
import { getCorrectProductName } from 'src/app/customer-data-components/sim-details/assets/product-lookup-table';
import { ToggleModalByName } from 'src/app/core/store/actions/modal.actions';
import { AddSuccessResponseNotification } from 'src/app/core/store/actions/notifications.actions';
import { SuccessResponse } from 'src/app/shared/success-response-handler/success-response.model';
import { CheckContinueAfterEscalationFailed, ShowContinueButton } from 'src/app/interactions/store/actions/continue-flow.actions';
import { InteractionTicketsState } from '../../store/state/interaction-tickets.state';
import { ChatJsonNote } from '../components/chat-json-note/assets/chat-json-note.interface';
import { CustomerResourceSelectors } from 'src/app/customer-info-summary-page/store/selectors/customer-resource.selectors';

@Component({
    selector: 'escalation-modal',
    templateUrl: './escalation-modal.component.html',
    styleUrls: ['./escalation-modal.component.scss'],
})
export class EscalationModalComponent extends FormHelper implements OnInit {

    @Select(CustomerInfoState.isLoaded) smartsubDataLoaded$: Observable<boolean>;
    @ViewChild(SnowflakeAddressInputsComponent) addressInput: SnowflakeAddressInputsComponent;

    attachmentModalOpen = false;
    updatingTeam = false;
    errorLines: string[] = [];
    useAltAddress = false;
    reasonTypes: SnowflakeSelectOption[] = [];
    teamOptions: SnowflakeSelectOption[] = [];
    simOptions: SnowflakeSelectOption<{msisdn: string, id: string}>[] = [];
    address: CustomerAddress;
    inputAddress: GooglePlacesAddress;
    collectionTypes: SnowflakeSelectOption[] = [
        { label: 'Immediate', value: 'immediate' },
        { label: 'Scheduled', value: 'scheduled' },
    ];
    locationTypes: SnowflakeSelectOption[] = [
        { label: 'House', value: 'house' },
        { label: 'Building-ground floor', value: 'Building-ground floor' },
        { label: 'Building-upper floor', value: 'Building-upper floor' },
        { label: 'Apartment-ground floor', value: 'Apartment-ground floor' },
        { label: 'Apartment-upper floor', value: 'Apartment-upper floor' },
        { label: 'Industrial area', value: 'Industrial area' },
        { label: 'School / Mall', value: 'School / Mall' },
        { label: 'Other', value: 'other' },
    ];

    escalationForm: FormGroup;
    dasherEscalationForm: FormGroup;
    collectionEscalationForm: FormGroup;
    fromdasherEscalationForm: FormGroup;
    fromAgentEscalationForm: FormGroup;
    resetSelect = false;
    userHasCredit = false;
    creditAmount: number;
    descriptionOfTheIssue: string;
    customerDetails: string;
    deviceMakeAndModel: string;
    addAdditionalFeedbackRequired = false;
    troubleshootingDoneString: string;
    equipmentRequired = false;
    equipmentRequiredText: string;
    HasAttachments = false;
    collectionsEscalations = false;
    dashersEscalations = false;
    hasOtherContactDetails = false;
    hasAlarmsOnSite = false;
    chosenTeam: string;
    team: string;
    files: File[] = [];
    isOpenFields: { [x: string]: boolean } = {};
    isFormValid = false;
    smartSubData: SmartSubData;

    protected ngDestroy$ = new Subject<void>();
    constructor(
        private fb: FormBuilder,
        private store: Store,
        private escalationService: EscalationService,
        private ticketEventHandler: TicketEventHandlerService,
        private rainAgent: RainAgent,
        private coverageService: CoverageService,
    ) {
        super();
    }
    ngOnInit(): void {
        this.initializeTeamOptions();

        this.smartsubDataLoaded$
            .pipe(takeUntil(this.ngDestroy$))
            .subscribe((loaded) => {
                if (loaded) {
                    this.simOptions = this.escalationService?.getSimDetails();
                }
            });

        this.createForm();
        this.InitForm(this.escalationForm);
        this.listenToFormChanges();
    }
    createForm() {
        this.escalationForm = this.fb.group({
            mainEscalationForm: this.fb.group({
                reason: this.fb.control(
                    '',
                    Validators.compose([Validators.required]),
                ),
                team: this.fb.control(
                    '',
                    Validators.compose([Validators.required]),
                ),
            }),
            collectionEscalationForm: this.fb.group({
                contactDetailsCheckbox: this.fb.control(false),
                customerDetails: this.fb.control(''),
                simDetails: this.fb.control(
                    '',
                    Validators.compose([Validators.required]),
                ),
                deviceMakeandModel: this.fb.control(
                    '',
                    Validators.compose([Validators.required]),
                ),
                collection_address: this.fb.control(''),
                use_alternate_address: this.fb.control(this.useAltAddress),
                collectionType: this.fb.control(
                    '',
                    Validators.compose([Validators.required]),
                ),
                creditCheckbox: this.fb.control(false),
                creditAmount: this.fb.control(''),
            }),

            // to the dasher escalation form group
            dasherEscalationForm: this.fb.group({
                descriptionOfTheIssueTextbox: this.fb.control(
                    '',
                    Validators.compose([Validators.required]),
                ),
                simDetails: this.fb.control(
                    '',
                    Validators.compose([Validators.required]),
                ),
                deviceMakeandModel: this.fb.control(
                    '',
                    Validators.compose([Validators.required]),
                ),
                confirmation_address: this.fb.control(''),
                use_alternate_address: this.fb.control(this.useAltAddress),
                feedbackCheckbox: this.fb.control(false),
                feedbackTextbox: this.fb.control(''),
                deviceSwapOutCheckbox: this.fb.control(false),
                simSwapCheckbox: this.fb.control(false),
                testSimAndDeviceCheckbox: this.fb.control(false),
                reoptimizeConnectionCheckbox: this.fb.control(false),
                allTroubleShootingDoneTextbox: this.fb.control(
                    '',
                    Validators.compose([Validators.required]),
                ),
                equipmentNeededCheckbox: this.fb.control(false),
                equipmentNeededTextbox: this.fb.control(''),
                attachmentsCheckbox: this.fb.control(false),
                attachmentsUploader: this.fb.control(null),
            }),

            // from the agent escalation form group
            fromAgentEscalationForm: this.fb.group({
                collection_address: this.fb.control(''),
                use_alternate_address: this.fb.control(this.useAltAddress),
                contactDetailsCheckbox: this.fb.control(false),
                contactDetailsTextbox: this.fb.control(''),
                reasonForDasherRequestTextbox: this.fb.control(
                    '',
                    Validators.compose([Validators.required]),
                ),
                alarmsOnSiteCheckbox: this.fb.control(false),
                alarmsOnSiteTextbox: this.fb.control(''),
                locationType: this.fb.control(
                    '',
                    Validators.compose([Validators.required]),
                ),
                optimizationCheckbox: this.fb.control(false),
                PDPResetCheckbox: this.fb.control(false),
                policyStatusConfirmedCheckbox: this.fb.control(false),
                coverageConfirmedCheckbox: this.fb.control(false),
                noNetworkSubscriberStateDataCheckbox: this.fb.control(false),
                provisioningConfirmedCheckbox: this.fb.control(false),
                simMismatchCheckbox: this.fb.control(false),
                IMSImismatchCheckbox: this.fb.control(false),
                billingUpToDateConfirmed: this.fb.control(false),
            }),

            //from the dasher form group
            fromdasherEscalationForm: this.fb.group({
                onSiteOptimizationCheckbox: this.fb.control(false),
                deviceTestingCheckbox: this.fb.control(false),
                deviceSwapOutCheckbox: this.fb.control(false),
                escalatedToNCCCheckbox: this.fb.control(false),
                clientEducationCheckbox: this.fb.control(false),
                mountedDeviceCheckbox: this.fb.control(false),
                POEFaultyCheckbox: this.fb.control(false),
                WifiFaultyCheckbox: this.fb.control(false),
                ClientDeviceIncompatibilityCheckbox: this.fb.control(false),
                faultFindingTextbox: this.fb.control(
                    '',
                    Validators.compose([Validators.required]),
                ),
                RSRPBeforeTextbox: this.fb.control(
                    '',
                    Validators.compose([Validators.required]),
                ),
                RSRPAfterTextbox: this.fb.control(
                    '',
                    Validators.compose([Validators.required]),
                ),
                RSRQBeforeTextbox: this.fb.control(
                    '',
                    Validators.compose([Validators.required]),
                ),
                RSRQAfterTextbox: this.fb.control(
                    '',
                    Validators.compose([Validators.required]),
                ),
                SINRBeforeTextbox: this.fb.control(
                    '',
                    Validators.compose([Validators.required]),
                ),
                SINRAfterTextbox: this.fb.control(
                    '',
                    Validators.compose([Validators.required]),
                ),
                speedTestBeforeTextbox: this.fb.control(
                    '',
                    Validators.compose([Validators.required]),
                ),
                speedTestAfterTextbox: this.fb.control(
                    '',
                    Validators.compose([Validators.required]),
                ),
                solutionOrFeedbackTextbox: this.fb.control(
                    '',
                    Validators.compose([Validators.required]),
                ),
            }),
        });
    }

    getSimDetails(id: string) {
        const simDetail = this.store.selectSnapshot(CustomerResourceSelectors.getSimDataById(id));
        return {
            'Sim plan:': getCorrectProductName(simDetail?.service?.current_speed),
            ', MSISDN:': simDetail?.service?.service_reference,
            ', Activation Date:': simDetail?.service?.activation_date,
        };
    }
    async onEscalate() {
        const hexID = this.store.selectSnapshot(InteractionTicketsState.getSelectedHexId);

        this.updatingTeam = true;
        const team = this.getControl('mainEscalationForm.team').value;

        const customer = this.store.selectSnapshot(CustomerInfoState.getSmartsubData,)?.user;
        const address = customer?.addresses[0];
        const contactdetails = {
            full_name: customer?.full_name,
            phone: customer?.phone,
            email: customer?.email
        }

        let formattedAddress = this.coverageService.getformattedAddress(address);

        const escalateObject = {
            message: this.getControl('mainEscalationForm.reason').value,
            more_info: {},
        };
        if (this.checkIfCollection()) {
            if (this.useAltAddress) {
                formattedAddress = this.getControl(
                    'collectionEscalationForm.collection_address',
                ).value;
            }
            escalateObject['escalation_info'] = {
                customer_details: contactdetails,
                'sim details': this.getSimDetails(
                    this.getControl('collectionEscalationForm.simDetails')
                        .value,
                ),
                'device and make model details': this.getControl(
                    'collectionEscalationForm.deviceMakeandModel',
                ).value,
                'collection address details': formattedAddress,
                'collection type details': this.getControl(
                    'collectionEscalationForm.collectionType',
                ).value,
                'customer credit amount': this.getControl(
                    'collectionEscalationForm.customerDetails',
                ).value,
            };
        }
        if (this.checkIfDasher()) {
            if (this.useAltAddress) {
                formattedAddress = this.getControl(
                    'dasherEscalationForm.confirmation_address',
                ).value;
            }
            escalateObject['escalation_info'] = {
                'description of the issue': this.getControl(
                    'dasherEscalationForm.descriptionOfTheIssueTextbox',
                ).value,

                'sim details': this.getSimDetails(
                    this.getControl('dasherEscalationForm.simDetails').value,
                ),
                'collection address': formattedAddress,
                'device make and model': this.getControl(
                    'dasherEscalationForm.deviceMakeandModel',
                ).value,

                feedback: this.getControl(
                    'dasherEscalationForm.feedbackTextbox',
                ).value,
                'device swap out': this.getControl(
                    'dasherEscalationForm.deviceSwapOutCheckbox',
                ).value,
                'sim swap': this.getControl(
                    'dasherEscalationForm.simSwapCheckbox',
                ).value,
                'test sim and device': this.getControl(
                    'dasherEscalationForm.testSimAndDeviceCheckbox',
                ).value,
                'reoptimize connection': this.getControl(
                    'dasherEscalationForm.reoptimizeConnectionCheckbox',
                ).value,
                'all troubleshooting done': this.getControl(
                    'dasherEscalationForm.allTroubleShootingDoneTextbox',
                ).value,
                'equipment needed': this.getControl(
                    'dasherEscalationForm.equipmentNeededTextbox',
                ).value,
                attachments: this.files,
            };
        }

        if (this.checkIfTeamRoleDasher()) {
            escalateObject['escalation_info'] = {
                'on Site Optimization Checkbox': this.getControl(
                    'fromdasherEscalationForm.onSiteOptimizationCheckbox',
                ).value,
                'device Testing': this.getControl(
                    'fromdasherEscalationForm.deviceTestingCheckbox',
                ).value,
                'device Swap Out': this.getControl(
                    'fromdasherEscalationForm.deviceSwapOutCheckbox',
                ).value,
                'escalated To NCCC': this.getControl(
                    'fromdasherEscalationForm.escalatedToNCCCheckbox',
                ).value,
                'client Education': this.getControl(
                    'fromdasherEscalationForm.clientEducationCheckbox',
                ).value,
                'mounted device': this.getControl(
                    'fromdasherEscalationForm.mountedDeviceCheckbox',
                ).value,
                'POE faulty': this.getControl(
                    'fromdasherEscalationForm.POEFaultyCheckbox',
                ).value,
                'WIFI faulty': this.getControl(
                    'fromdasherEscalationForm.WifiFaultyCheckbox',
                ).value,
                'client device incompatibility': this.getControl(
                    'fromdasherEscalationForm.ClientDeviceIncompatibilityCheckbox',
                ).value,
                'fault finding': this.getControl(
                    'fromdasherEscalationForm.faultFindingTextbox',
                ).value,
                'RSRP before': this.getControl(
                    'fromdasherEscalationForm.RSRPBeforeTextbox',
                ).value,
                'RSRP after': this.getControl(
                    'fromdasherEscalationForm.RSRPAfterTextbox',
                ).value,
                'RSRQ before': this.getControl(
                    'fromdasherEscalationForm.RSRQBeforeTextbox',
                ).value,
                'RSRQ after': this.getControl(
                    'fromdasherEscalationForm.RSRQAfterTextbox',
                ).value,
                'SINR before': this.getControl(
                    'fromdasherEscalationForm.SINRBeforeTextbox',
                ).value,
                'SINR after': this.getControl(
                    'fromdasherEscalationForm.SINRAfterTextbox',
                ).value,
                'speed test before': this.getControl(
                    'fromdasherEscalationForm.speedTestBeforeTextbox',
                ).value,
                'speed test after': this.getControl(
                    'fromdasherEscalationForm.speedTestAfterTextbox',
                ).value,
                'Solution or Feedback': this.getControl(
                    'fromdasherEscalationForm.solutionOrFeedbackTextbox',
                ).value,
            };
        }
        if (
            this.checkIfAgentThatCanEscalateToDasher() &&
            this.checkIfDasher()
        ) {
            escalateObject['escalation_info'] = {
                'description of the issue': this.getControl(
                    'dasherEscalationForm.descriptionOfTheIssueTextbox',
                ).value,
                'sim details': this.getSimDetails(
                    this.getControl('dasherEscalationForm.simDetails').value,
                ),
                'device make and model': this.getControl(
                    'dasherEscalationForm.deviceMakeandModel',
                ).value,

                feedback: this.getControl(
                    'dasherEscalationForm.feedbackTextbox',
                ).value,
                'device swap out': this.getControl(
                    'dasherEscalationForm.deviceSwapOutCheckbox',
                ).value,
                'sim swap': this.getControl(
                    'dasherEscalationForm.simSwapCheckbox',
                ).value,
                'test sim and device': this.getControl(
                    'dasherEscalationForm.testSimAndDeviceCheckbox',
                ).value,
                'reoptimize connection': this.getControl(
                    'dasherEscalationForm.reoptimizeConnectionCheckbox',
                ).value,
                'all troubleshooting done': this.getControl(
                    'dasherEscalationForm.allTroubleShootingDoneTextbox',
                ).value,
                'equipment needed': this.getControl(
                    'dasherEscalationForm.equipmentNeededTextbox',
                ).value,
                attachments: this.files,
                'contact Details': contactdetails,
                'reason For Dasher Request': this.getControl(
                    'fromAgentEscalationForm.reasonForDasherRequestTextbox',
                ).value,
                'alarms On Site Textbox': this.getControl(
                    'fromAgentEscalationForm.alarmsOnSiteTextbox',
                ).value,
                'location type': this.getControl(
                    'fromAgentEscalationForm.locationType',
                ).value,
                'PDP Reset': this.getControl(
                    'fromAgentEscalationForm.PDPResetCheckbox',
                ).value,
                'policy status confirmed': this.getControl(
                    'fromAgentEscalationForm.policyStatusConfirmedCheckbox',
                ).value,
                'coverage confirmed': this.getControl(
                    'fromAgentEscalationForm.coverageConfirmedCheckbox',
                ).value,
                'no network subscriber state data': this.getControl(
                    'fromAgentEscalationForm.noNetworkSubscriberStateDataCheckbox',
                ).value,
                'provisioning confirmed': this.getControl(
                    'fromAgentEscalationForm.provisioningConfirmedCheckbox',
                ).value,
                'Sim mismatch': this.getControl(
                    'fromAgentEscalationForm.simMismatchCheckbox',
                ).value,
                'IMSI mismatch': this.getControl(
                    'fromAgentEscalationForm.IMSImismatchCheckbox',
                ).value,
                'Billing up to date': this.getControl(
                    'fromAgentEscalationForm.billingUpToDateConfirmed',
                ).value,
            };
        }
        const groupUpdated = await this.escalateToGroup(
            hexID,
            team,
            escalateObject,
        );
        this.handleEscalateResponse(team, hexID, groupUpdated);
        this.updatingTeam = false;
    }

    private handleEscalateResponse(team: string, hexID: string, successful: boolean) {
        if (successful) {
            this.addEvent(team, hexID);
            const successResponse = new SuccessResponse(true, 'Successfully escalated ticket.');
            this.store.dispatch([
                new AddSuccessResponseNotification(successResponse),
                new ShowContinueButton(true)
            ])
            this.onClose();
        }
        else {
            const successResponse = new SuccessResponse(false, 'Failed to escalate ticket.');
            this.store.dispatch([
                new AddSuccessResponseNotification(successResponse),
                new CheckContinueAfterEscalationFailed()
            ]);
        }
    }

    isValid() {
        if (this.checkIfDasher()) {
            if (this.checkIfAgentThatCanEscalateToDasher()) {
                return (
                    this.escalationForm.get('mainEscalationForm').invalid ||
                    this.escalationForm.get('fromAgentEscalationForm')
                        .invalid ||
                    this.escalationForm.get('dasherEscalationForm').invalid
                );
            }
            return (
                this.escalationForm.get('mainEscalationForm').invalid ||
                this.escalationForm.get('dasherEscalationForm').invalid
            );
        } else if (this.checkIfTeamRoleDasher()) {
            return (
                this.escalationForm.get('mainEscalationForm').invalid ||
                this.escalationForm.get('fromdasherEscalationForm').invalid
            );
        } else if (this.checkIfCollection()) {
            return (
                this.escalationForm.get('mainEscalationForm').invalid ||
                this.escalationForm.get('collectionEscalationForm').invalid
            );
        } else return this.escalationForm.get('mainEscalationForm').invalid;
    }

    private addEvent(team: string, hexID: string) {
        const comment = `escalated ticket to ${team}`;
        this.ticketEventHandler.addEventForKnownCustomer(comment, false, hexID);
    }

    async initializeTeamOptions() {
        const optionsMap = this.escalationService.getEscalationTeamOptions();
        const agentDetails: AgentDetails = await this.rainAgent.getAgentDetails();
        this.team = agentDetails.getTeamRole();
        if (this.team?.includes('GENERAL'))
            this.team = agentDetails.getDefaultTeam();

        const selectedOptions: string[] = optionsMap[this.team];
        selectedOptions?.forEach((option) => {
            this.teamOptions.push({ label: option, value: option });
        });
    }
    showError(error: string) {
        const defaultMessage =
            'No group options data received from snowflake api.';
        this.errorLines.push(defaultMessage);
        if (error) this.errorLines.push('Error: ' + error);
    }
    @HostListener('click', ['$event'])
    private clickOutside(e) {
        const target: HTMLElement = e.target;
        if (target.classList.contains('is-open')) {
            this.onClose();
        }
    }

    onOptionSelect(formField: string, value: {msisdn: string, id: string} | string) {
        const val = (value as {msisdn: string, id: string})?.id ?? value;
        this.escalationForm?.get(formField)?.patchValue(val);
    }
    checkIfCollection() {
        if (
            this.chosenTeam === 'LOGISTICS.COLLECTIONS_IMMEDIATE.A' ||
            this.chosenTeam === 'LOGISTICS.COLLECTIONS_SCHEDULED.A'
        ) {
            return true;
        }
        return false;
    }
    checkIfDasher() {
        return this.chosenTeam === 'CEC.DASHERS.A';
    }
    checkIfTeamRoleDasher() {
        return this.team === 'CEC.DASHERS.A';
    }
    listenToFormChanges() {
        this.getControl('dasherEscalationForm.equipmentNeededCheckbox')
            .valueChanges.pipe(takeUntil(this.ngDestroy$))
            .subscribe((value: boolean) => {
                this.equipmentRequired = value;
                if (value) {
                    this.escalationForm
                        .get('dasherEscalationForm.equipmentNeededTextbox')
                        .addValidators([Validators.required]);
                } else if (!value) {
                    this.getControl(
                        'dasherEscalationForm.equipmentNeededTextbox',
                    ).reset('');
                    this.escalationForm
                        .get('dasherEscalationForm.equipmentNeededTextbox')
                        .removeValidators([Validators.required]);
                    this.escalationForm
                        .get('dasherEscalationForm.equipmentNeededTextbox')
                        .patchValue(null);
                }
            });

        this.getControl('dasherEscalationForm.attachmentsCheckbox')
            .valueChanges.pipe(takeUntil(this.ngDestroy$))
            .subscribe((value: boolean) => {
                this.HasAttachments = value;
                this.attachmentModalOpen = value;
                if (value) {
                    this.escalationForm
                        .get('dasherEscalationForm.attachmentsUploader')
                        .addValidators([Validators.required]);
                } else if (!value) {
                    this.getControl(
                        'dasherEscalationForm.attachmentsUploader',
                    ).reset(null);
                    this.escalationForm
                        .get('dasherEscalationForm.attachmentsUploader')
                        .removeValidators([Validators.required]);
                    this.getControl(
                        'dasherEscalationForm.attachmentsUploader',
                    ).patchValue(null);
                }
            });
        this.getControl('collectionEscalationForm.creditCheckbox')
            .valueChanges.pipe(takeUntil(this.ngDestroy$))
            .subscribe((value: boolean) => {
                this.userHasCredit = value;
                if (value) {
                    this.escalationForm
                        .get('collectionEscalationForm.creditAmount')
                        .addValidators([Validators.required]);
                } else if (!value) {
                    this.getControl(
                        'collectionEscalationForm.creditAmount',
                    ).reset('');
                    this.escalationForm
                        .get('collectionEscalationForm.creditAmount')
                        .removeValidators([Validators.required]);
                    this.getControl(
                        'collectionEscalationForm.creditAmount',
                    ).patchValue(null);
                }
            });
        this.getControl('mainEscalationForm.team')
            .valueChanges.pipe(takeUntil(this.ngDestroy$))
            .subscribe((value: string) => {
                this.chosenTeam = value;
            });
        this.getControl('collectionEscalationForm.use_alternate_address')
            .valueChanges.pipe(takeUntil(this.ngDestroy$))
            .subscribe((value: boolean) => {
                this.useAltAddress = value;
                if (!value) {
                    this.address = null;
                    this.inputAddress = null;
                    this.getControl(
                        'collectionEscalationForm.collection_address',
                    ).reset('');
                    this.getControl(
                        'collectionEscalationForm.collection_address',
                    ).patchValue('');
                }
            });
        this.getControl('dasherEscalationForm.use_alternate_address')
            .valueChanges.pipe(takeUntil(this.ngDestroy$))
            .subscribe((value: boolean) => {
                this.useAltAddress = value;
                if (!value) {
                    this.address = null;
                    this.inputAddress = null;
                    this.getControl(
                        'dasherEscalationForm.confirmation_address',
                    ).reset('');
                    this.getControl(
                        'dasherEscalationForm.confirmation_address',
                    ).patchValue(null);
                }
            });
        this.getControl('fromAgentEscalationForm.use_alternate_address')
            .valueChanges.pipe(takeUntil(this.ngDestroy$))
            .subscribe((value: boolean) => {
                this.useAltAddress = value;
                if (!value) {
                    this.address = null;
                    this.inputAddress = null;
                    this.getControl(
                        'fromAgentEscalationForm.collection_address',
                    ).reset('');
                    this.getControl(
                        'fromAgentEscalationForm.collection_address',
                    ).patchValue(null);
                }
            });

        this.getControl('fromAgentEscalationForm.contactDetailsCheckbox')
            .valueChanges.pipe(takeUntil(this.ngDestroy$))
            .subscribe((value: boolean) => {
                this.hasOtherContactDetails = value;
                if (value) {
                    this.escalationForm
                        .get('fromAgentEscalationForm.contactDetailsTextbox')
                        .addValidators([Validators.required]);
                } else if (!value) {
                    this.getControl(
                        'fromAgentEscalationForm.contactDetailsTextbox',
                    ).reset('');
                    this.escalationForm
                        .get('fromAgentEscalationForm.contactDetailsTextbox')
                        .removeValidators(Validators.required);
                    this.getControl(
                        'fromAgentEscalationForm.contactDetailsTextbox',
                    ).patchValue(null);
                }
            });

        this.getControl('dasherEscalationForm.feedbackCheckbox')
            .valueChanges.pipe(takeUntil(this.ngDestroy$))
            .subscribe((value: boolean) => {
                this.addAdditionalFeedbackRequired = value;
                const textboxControl = this.escalationForm.get(
                    'dasherEscalationForm.feedbackTextbox',
                );

                if (value) {
                    textboxControl.addValidators([Validators.required]);
                } else {
                    textboxControl.reset('');
                    textboxControl.patchValue('');
                    textboxControl.removeValidators([Validators.required]);
                    textboxControl.patchValue(null);
                }
            });
        this.getControl('fromAgentEscalationForm.alarmsOnSiteCheckbox')
            .valueChanges.pipe(takeUntil(this.ngDestroy$))
            .subscribe((value: boolean) => {
                this.hasAlarmsOnSite = value;
                if (value) {
                    this.escalationForm
                        .get('fromAgentEscalationForm.alarmsOnSiteTextbox')
                        .addValidators([Validators.required]);
                } else if (!value) {
                    this.getControl(
                        'fromAgentEscalationForm.alarmsOnSiteTextbox',
                    ).reset('');
                    this.escalationForm
                        .get('fromAgentEscalationForm.alarmsOnSiteTextbox')
                        .removeValidators([Validators.required]);
                    this.getControl(
                        'fromAgentEscalationForm.alarmsOnSiteTextbox',
                    ).patchValue(null);
                }
            });
        this.getControl('collectionEscalationForm.contactDetailsCheckbox')
            .valueChanges.pipe(takeUntil(this.ngDestroy$))
            .subscribe((value: boolean) => {
                this.hasOtherContactDetails = value;
                if (value) {
                    this.escalationForm
                        .get('collectionEscalationForm.customerDetails')
                        .addValidators([Validators.required]);
                } else if (!value) {
                    this.getControl(
                        'collectionEscalationForm.customerDetails',
                    ).reset('');
                    this.escalationForm
                        .get('collectionEscalationForm.customerDetails')
                        .removeValidators([Validators.required]);
                    this.getControl(
                        'collectionEscalationForm.customerDetails',
                    ).patchValue(null);
                }
            });
    }
    setAddress(address: CustomerAddress) {
        this.address = address;
    }
    onAddressSelected(data: GooglePlacesResponseData) {
        this.inputAddress = data?.address;
    }

    closeAttachmentUploader() {
        this.HasAttachments = false;
        this.attachmentModalOpen = false;
    }

    attachFileData(files: File[]) {
        this.files = [...this.files, ...files];
    }

    private async escalateToGroup(hexID: string, team: string, data: Record<string, unknown>,
    ): Promise<boolean> {

        const payload: ChatJsonNote = {
            message: "Escalation form",
            detail: data,
            type: "escalations_form"
        }
        const comment = JSON.stringify(payload);

        const response = await this.ticketEventHandler.escalate(
            hexID,
            team,
            comment,
            TICKET_STATES.PENDING,
        );
        return response?.status === 200 || response?.status === 201;
    }

    checkIfAgentThatCanEscalateToDasher() {
        if (
            this.team ===
            ('CEC.RETENTIONS.A' || 'CEC.ADVANCED.A' || 'CEC.BILLING.A')
        ) {
            return true;
        }
        return false;
    }

    public onClose() {
        this.store.dispatch(new ToggleModalByName('escalation_modal', false));
    }

    ngOnDestroy(): void {
        this.onClose();
        this.ngDestroy$.next(null);
        this.ngDestroy$.complete();
    }
}
