import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { ContinueFromPostTicketModal } from 'src/app/interactions/store/actions/interaction-actions/interactions.actions';
import { InteractionsModalState } from 'src/app/interactions/store/state/interaction-modal-state';
import { PostTicketModalOptions } from './post-ticket-modal-options.model';
import { TicketEventHandlerService } from '../../customer-ticket/ticket-event-handler/ticket-event-handler.service';
import { SuccessResponse } from '../../success-response-handler/success-response.model';
import { CustomerInfoState } from 'src/app/customer-info-summary-page/store/state/customer-info.state';
import { ApiResponse } from '../api-service/api-response.interface';
import { ChatJsonNote } from 'src/app/interactions/chat-interaction/components/chat-json-note/assets/chat-json-note.interface';
import { NOTE_CHANNELS } from '../../customer-ticket/ticket-event-handler/assets/ticket.constants';


@Injectable({
  providedIn: 'root'
})
export class PostTicketService {

  constructor(private ticketEventService: TicketEventHandlerService,
    private store: Store) { }

  async sendPostTicketPayload(hex_id: string, payload: ChatJsonNote, modalOptions: PostTicketModalOptions, cleanupMethod?: CallableFunction) {
    modalOptions.sending = true;

    const response = await this.addFormData(hex_id, payload);
    this.checkPostTicketMoveOnState(response, modalOptions, cleanupMethod);
  }


  async addFormData(hex_id: string, payload: ChatJsonNote) {
    return this.ticketEventService.addNote(
      {
        hex_id,
        comment: JSON.stringify(payload),
        isPrivate: true,
        channel_id: NOTE_CHANNELS.WORKAROUND_FORMS
      }
    );
  }

  checkPostTicketMoveOnState(response: ApiResponse, modalOptions: PostTicketModalOptions, cleanupMethod?: CallableFunction) {
    const status = response.status;
    if (modalOptions.retryAttempt >= modalOptions.maxRetries) {
      modalOptions.successResponse = new SuccessResponse(false,
        `failed to saved questionnaire, attempted ${modalOptions.retryAttempt}. Moving on.`
      );
      setTimeout(() => this.moveOn(modalOptions, cleanupMethod), 50);
    }
    else if (modalOptions.acceptableStatuses.includes(status)) {
      modalOptions.successResponse = new SuccessResponse(true, "successfully saved questionnaire");
      setTimeout(() => this.moveOn(modalOptions, cleanupMethod), 50);
    }
    else {
      modalOptions.successResponse = new SuccessResponse(false,
        `failed to saved questionnaire, attempt ${modalOptions.retryAttempt}, error: ${response.errorMessage?.error}`
      );
      modalOptions.addretryAttempt();
    }
    modalOptions.sending = false;
  }


  moveOn(modalOptions: PostTicketModalOptions, cleanupMethod?: CallableFunction) {
    modalOptions.spinnerMessage = "processing..";
    modalOptions.sending = true;
    setTimeout(() => {
      const isPrivateReply = this.store.selectSnapshot(InteractionsModalState.isPrivateReply);
      this.store.dispatch(new ContinueFromPostTicketModal(isPrivateReply));
      if (cleanupMethod) {
        cleanupMethod();
      }
    }, 1500);
  }

  getRelevantPostTicketCustomerData() {
    const customer = this.store.selectSnapshot(CustomerInfoState.getSmartsubData)?.user;
    return {
      name: customer?.full_name,
      email: customer?.email,
      phone: customer?.phone
    };
  }

  async addFormToCurrentOrAssignedTicket(payload: ChatJsonNote, showError = false) {
    const hexId = await this.ticketEventService.getAssignedOrNewHexId("add form data", showError);
    if (hexId) { return this.addFormData(hexId, payload); }
  }


}
