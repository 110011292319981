import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Ticks } from './elements/chat-ticks/ticks.type';

type Direction = "left" | "right";

@Component({
  selector: 'sf-chat-bubble-template',
  templateUrl: './chat-bubble-template.component.html',
  styleUrls: ['./chat-bubble-template.component.scss']
})
export class ChatBubbleTemplateComponent {

  @Output() contentClicked = new EventEmitter<void>();

  @Input() content: string;
  @Input() dateTime: string | number;
  @Input() letter: string;
  @Input() hoverMessage: string;

  @Input() direction: Direction = "left"
  @Input() isHtml = false;
  @Input() colored = false;

  @Input() ticks?: Ticks;

  @Input() allowContentClick = false;
  @Input() contentClickMessage: string;
  @Input() loading = false;
  @Input() isEmailString = false;

  onContentClicked() {
    if (this.allowContentClick) {
      this.contentClicked.next();
    }
  }
}
