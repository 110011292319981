import { DataHandler } from "src/app/shared/data-handler/data-handler";
import { SuccessResponse } from "src/app/shared/success-response-handler/success-response.model";


export interface PostTicketModalConfig {
  spinnerMessage?: string;
  maxRetries?: number;
  acceptableStatuses?: number[];
}

export class PostTicketModalOptions {
  successResponse: SuccessResponse = null;
  sending = false;
  retryAttempt = 1;
  maxRetries: number;
  spinnerMessage: string;
  acceptableStatuses = [200];

  constructor(config?: PostTicketModalConfig) {
    this.spinnerMessage = config?.spinnerMessage ?? "sending..";
    this.maxRetries = DataHandler.isDefined(config?.maxRetries) ? config?.maxRetries : 3;

    if(config?.acceptableStatuses){
      this.acceptableStatuses = config.acceptableStatuses;
    }

  }

  addretryAttempt() {
    this.retryAttempt += 1;
  }
}
