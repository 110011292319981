import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormHelper } from 'src/app/Utils/helpers';
import { PostTicketModalOptions } from 'src/app/shared/services/post-ticket/post-ticket-modal-options.model';
import { NETWORK_OPTIONS, RETAINED_NO_OPTIONS, RETAINED_RAIN_ONE_NO_OPTIONS, RETAINED_YES_OPTIONS, VOICE_OPTIONS } from './assets/rain-one-retentions-ticket-options.constant';
import { ChatJsonNote } from '../components/chat-json-note/assets/chat-json-note.interface';
import { Store } from '@ngxs/store';
import { InteractionTicketsState } from '../../store/state/interaction-tickets.state';
import { PostTicketService } from 'src/app/shared/services/post-ticket/post-ticket.service';
import { ToggleModalByName } from 'src/app/core/store/actions/modal.actions';
import { CustomerInfoState } from 'src/app/customer-info-summary-page/store/state/customer-info.state';
import { CustomerInfoSummaryService } from 'src/app/customer-info-summary-page/store/services/customer-info-summary.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SnowflakeSelectOption } from 'src/app/shared/elements/rain-forms/elements/snowflake-select/interfaces/rain-select-option.interface';
import { CoreState } from 'src/app/core/store/state/core.state';


const FORM_KEYS = ['retained_yes', 'retained_no'];
@Component({
    selector: 'sf-rain-one-retentions-post-close-modal',
    templateUrl: './rain-one-retentions-post-close-modal.component.html',
    styleUrls: ['./rain-one-retentions-post-close-modal.component.scss']
})

export class RainOneRetentionsPostCloseModalComponent extends FormHelper implements OnInit, OnDestroy {
    isRetained = false;
    isMigratedToRainOne=false;
    msisdnOptions = [];
    form: FormGroup;
    modalOptions: PostTicketModalOptions;
    isAnyOptionSelected = false;
    hasOtherOptions: { [option: string]: boolean } = {
        network_issues: false,
        voice_issues: false,
    };
    retainedYesOptions: SnowflakeSelectOption[] = RETAINED_YES_OPTIONS;
    retainedNoOptions: SnowflakeSelectOption[] = RETAINED_NO_OPTIONS;
    networkOptions: SnowflakeSelectOption[] = NETWORK_OPTIONS;
    voiceOptions: SnowflakeSelectOption[] = VOICE_OPTIONS;
    ngDestroy$ = new Subject<void>();
    isRainOneAgent=false;

    constructor(private formBuilder: FormBuilder, private store: Store, private postTicketService: PostTicketService, private customerInfoSummaryService: CustomerInfoSummaryService) {
        super();
    }

    ngOnInit(): void {
        this.createForm();
        this.InitForm(this.form);
        const agentRoles = this.store.selectSnapshot(CoreState.getAgent)?.roles;
        this.isRainOneAgent = agentRoles.includes('CEC.RETENTIONS_RAINONE.A');
        if(this.isRainOneAgent){
          this.retainedNoOptions = RETAINED_RAIN_ONE_NO_OPTIONS;
        }
        this.modalOptions = new PostTicketModalOptions();
        this.msisdnOptions = this.customerInfoSummaryService.getCurrentSimSFOptions();
        this.events('retained').pipe(takeUntil(this.ngDestroy$)).subscribe({
            next: (res) => {
                if (res) {
                    this.isRetained = true;
                    this.form.get('retained_no').reset();
                    this.form.get('voice_issues').reset();
                    this.form.get('network_issues').reset();
                    this.addRemoveValidators('retained_no', false);
                    this.addRemoveValidators('voice_issues', false);
                    this.addRemoveValidators('network_issues', false);
                    this.addRemoveValidators('retained_yes', true);
                } else if (!res) {
                    this.isRetained = false;
                    this.form.get('retained_yes').reset();
                    this.addRemoveValidators('retained_no', true);
                    this.addRemoveValidators('retained_yes', false);


                }
            },
        });

        this.events('migrated_to_rain_one').pipe(takeUntil(this.ngDestroy$)).subscribe({
          next: (res) => {
              if (res) {
                  this.isMigratedToRainOne = true;
                  this.addRemoveValidators('msisdn_migrated_to_rainone', true);
              } else if (!res) {
                  this.isMigratedToRainOne = false;
                  this.form.get('msisdn_migrated_to_rainone').reset();
                  this.addRemoveValidators('msisdn_migrated_to_rainone', false);
              }
          },
      });
    }

    createForm() {
        const multiSelectOptions = FORM_KEYS.reduce((obj, key) => {
            obj[key] = this.formBuilder.control([]);
            return obj;
        }, {});
        this.form = this.formBuilder.group({
            ...multiSelectOptions,
            select_msisdn: this.formBuilder.control(''),
            migrated_to_rain_one:this.formBuilder.control(false),
            msisdn_migrated_to_rainone: this.formBuilder.control(''),
            retained: this.formBuilder.control(false),
            network_issues: this.formBuilder.control(''),
            voice_issues: this.formBuilder.control(''),
            comments: this.formBuilder.control('', Validators.compose([]))
        })
    }

    onSelectOptions(key: string, selectedOptions: string[]) {
        this.form.get(key).patchValue(selectedOptions);
        if (key === 'retained_no') {
            this.hasOtherOptions['network_issues'] = selectedOptions.includes("Network Issues");
            this.addRemoveValidators('network_issues', this.hasOtherOptions['network_issues']);
            this.hasOtherOptions['voice_issues'] = selectedOptions.includes("Voice Issues");
            this.addRemoveValidators('voice_issues', this.hasOtherOptions['voice_issues']);
        }

        this.isAnyOptionSelected = this.checkIfAnOptionWasSelected();
    }

    private addRemoveValidators(controlName: string, required: boolean) {
        const control = this.getControl(controlName);
        if (required) {
            control.addValidators(Validators.required);
            control.updateValueAndValidity();
        }
        else {
            control.removeValidators(Validators.required);
            control.patchValue(null);
        }
    }

    checkIfAnOptionWasSelected() {
        for (const key of FORM_KEYS) {
            const hasOption = (this.getControl(key)?.value as string[])?.length > 0;
            if (hasOption) {
                return true;
            }
        }
        return false;
    }


    private getFormattedData() {
        const { select_msisdn, msisdn_migrated_to_rainone, retained_yes, retained_no, network_issues, voice_issues, comments } = this.form?.getRawValue() ?? {};
        const usersEmail = this.store.selectSnapshot(
            CustomerInfoState.getCustomerEmail
        );

        return {
            "MSISDN": select_msisdn,
            'customer email': usersEmail,
            "msisdn migrated to rainOne":msisdn_migrated_to_rainone,
            "retained-yes": [retained_yes],
            "retained-no": [retained_no],
            "network issues": network_issues,
            "voice issues": voice_issues,
            "comments": comments
        }
    }

    onSend() {
        const data = this.getFormattedData();
        const payload: ChatJsonNote = {
            message: "Rain one retentions post close form",
            detail: data,
            type: 'retentions_form'
        }
        const hexId = this.store.selectSnapshot(InteractionTicketsState.getSelectedHexId);
        this.postTicketService.sendPostTicketPayload(
            hexId, payload, this.modalOptions, this.close.bind(this)
        );
    }

    close() {
        this.store.dispatch(new ToggleModalByName("rain_one_retentions_post_close_modal", false));
    }

    ngOnDestroy(): void {
        this.ngDestroy$.next(null);
        this.ngDestroy$.complete();
        this.close();
        this.resetForm();
    }
}
