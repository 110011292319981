import { Pipe, PipeTransform } from '@angular/core';
import { SFValidators } from 'src/app/shared/functions/sf-validators';
import { TypeICouldBe } from '../../interfaces/type-I-could-be.type';

@Pipe({ name: 'typeOfData' })
export class TypeOfDataPipe implements PipeTransform {

  transform(data: unknown): TypeICouldBe {

    if (SFValidators.isNotDefined(data)) {
      return "nullLike";
    }

    if (typeof data === "number" || typeof data === "bigint") {
      return "number";
    }

    if (typeof data === "string") {

      if (data === '') {
        return 'emptyString'
      } else if (!isNaN(parseFloat(data))) {
        return 'number';
      }
      else {
        return "string";
      }

    }

    if (typeof data === "boolean") {
      return "boolean";
    }

    if (Array.isArray(data)) {

      if (data.length > 0) {
        return "array";
      } else {
        return 'nullLike';
      }

    }

    if (typeof data === "object") {

      if (!Object.entries(data).length) {
        return 'emptyObject';
      }
      else {
        return 'object';
      }

    }

  }

}
