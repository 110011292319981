<chat-box-element
  *ngIf="!error; else showError"
  backgroundColor="#f2f9ff"
  [hasCloseButton]="true"
  (close)="onClose()"
>
  <ng-container header>{{ "uploading " + file.name + ".." }}</ng-container>
  <ng-container subHeader>{{
    "progress: " + progress + "%"
  }}</ng-container></chat-box-element
>

<ng-template #showError
  ><chat-box-element
    backgroundColor="#f2f9ff"
    [hasCloseButton]="true"
    (close)="onClose()"
  >
    <ng-container header>{{ "upload failed" }}</ng-container>
    <ng-container error>{{ "ERROR: " + error }}</ng-container></chat-box-element
  ></ng-template
>
