import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from './auth.service';
import { MsalBroadcastService } from '@azure/msal-angular';
import { InteractionStatus } from '@azure/msal-browser';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { APP_NAME } from '../constants';


@Component({
    selector: 'app-auth',
    templateUrl: './auth.component.html',
    styleUrls: ['./auth.component.scss']
})

export class AuthenticationComponent implements OnInit, OnDestroy {

    isIframe = false;
    loginStatus = false;
    appName = APP_NAME

    private readonly _destroying$ = new Subject<void>();

    constructor(
        private router: Router,
        private authService: AuthService,
        private msalBroadcastService: MsalBroadcastService
    ) { }


    homeRoute() {
        this.router.navigate(['/home']);
    }

    ngOnInit(): void {

        this.isIframe = window !== window.parent && !window.opener;

        this.msalBroadcastService.inProgress$
            .pipe(
                filter((status: InteractionStatus) => status === InteractionStatus.None),
                takeUntil(this._destroying$)
            )
            .subscribe(() => {
                this.loginStatus = this.authService.setLoginStatus();
                if (this.loginStatus) {
                    this.homeRoute();
                }
            });
    }

    login() {
        this.authService.login();
    }

    logout() {
        this.authService.logout();
    }

    ngOnDestroy(): void {
        this._destroying$.next(null);
        this._destroying$.complete();
    }
}
