<sf-popup-modal
  [isOpen]="true"
  [hasCloseButton]="false"
  [autoClose]="false"
  [successResponse]="modalOptions.successResponse"
  [sending]="modalOptions.sending"
  [spinnerMessage]="modalOptions.spinnerMessage"
  [limitHeight]='true'
>

  <ng-container header>post fulfilment form</ng-container>

  <ng-container mainContent>
    <sf-modal-form-container [formGroup]="form">
      <div
        class="cpe-collections-options-wrapper"
        *ngFor="let option of FULFILLMENT_CPE_COLLECTIONS_OPTIONS"
      >
        <input
          type="radio"
          id="{{ option }}"
          formControlName="fulfillment_collections_option"
          [value]="option"
          (click)="updateSelectedOption(option)"
          [checked]="selectedOption === option"
        />
        <label for="{{ option }}">{{ option }}</label>
      </div>

    </sf-modal-form-container>
  </ng-container>

  <ng-container buttons>
    <sf-rain-button
      [isSolid]="true"
      [disabled]="form?.invalid || modalOptions?.sending || !selectedCpeCollectionOption"
      (click)="onSubmit()"
    >
      send
    </sf-rain-button>
  </ng-container>

</sf-popup-modal>
