import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { Subject } from 'rxjs';
import { CoreState } from '../core/store/state/core.state';
import { PersistentStorageState } from '../core/store/state/persistent-storage/persistent-storage.state';
import { getRouterCardsInfo, RouterCardOption } from './agent-card-sections';
import { RouteInfo } from './route-info.interface';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  sidenavOpenChanged = new Subject<boolean>();
  private sidenavOpen = false;

  constructor(private router: Router,
    private store: Store) { }

  private updateSidenavChange() {
    this.sidenavOpenChanged.next(this.sidenavOpen);
  }

  getSidenavOpen() {
    return this.sidenavOpen;
  }

  toggleSidenav() {
    this.sidenavOpen = !this.sidenavOpen;
    this.updateSidenavChange();
  }

  closeSidenav() {
    this.sidenavOpen = false;
    this.updateSidenavChange();
  }

  redirect(agentRouteInfo: RouteInfo) {
    const routeInfo = [agentRouteInfo.route];
    if (agentRouteInfo?.routeParams) {
      routeInfo.push(agentRouteInfo.routeParams);
    }
    this.router.navigate(routeInfo);
  }

  goHome() {
    this.router.navigate(["/home"]);
  }


  getRouterCards(section: RouterCardOption) {
    let roles = this.store.selectSnapshot(CoreState.getAgentRoles);

    if (!roles?.length) {
      roles = this.store.selectSnapshot(PersistentStorageState.get("agent-roles")) ?? [];
    }

    return getRouterCardsInfo(roles, section);
  }
}
