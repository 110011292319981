import { Component, Input } from '@angular/core';

@Component({
  selector: 'sf-loader-template',
  templateUrl: './loader-template.component.html',
  styleUrls: ['./loader-template.component.scss']
})
export class LoaderTemplateComponent {

  @Input() loading = false;
  @Input() hasData = false;
  @Input() error: string = null;
  @Input() useDefaultError = false;
  @Input() useErrorTemplate = false;
  @Input() noDataMessage = "no data";
  @Input() noDataRed = false;

}
