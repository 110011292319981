import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AddNotification } from 'src/app/core/store/actions/notifications.actions';
import { CustomWebSocket } from '../../models/websocket.model';
import { WebsocketEventName } from './assets/websocket-event-name.type';
import { WebSocketRequestMessage } from './assets/webSocket-request-message.model';
import { WebsocketResponse } from './assets/websocket-response.interface';

const WEBSOCKET_EVENTS: WebsocketEventName[] = ["connected", "error"];
const MAX_AUTO_RETRIES = 10;

@Injectable({
  providedIn: 'root'
})
export class MainWebSocketService {

  private _lastPayload: WebSocketRequestMessage;

  private _wsEventMap = new Map<WebsocketEventName, Subject<WebsocketResponse>>();
  private _currentUrl: string;
  private _currentRetries = 0;

  private _websocketObservable: Observable<any>;
  private _websocket = new CustomWebSocket();
  private _destroyed = new Subject();
  private _keepAliveStarted = false;

  private _reconnectAttempt = false;
  private _reconnecting = false;
  private _isConnected = false;

  get websocketObservable() {
    return this._websocketObservable;
  }

  get isConnected() {
    return this._isConnected;
  }

  get reconnecting() {
    return this._reconnecting;
  }

  get currentRetries() {
    return this._currentRetries;
  }

  constructor(private store: Store) {
    this.createWSEventMap();
  }

  private createWSEventMap() {
    WEBSOCKET_EVENTS.forEach(eventName =>
      this._wsEventMap.set(eventName, new Subject<WebsocketResponse>())
    );
  }

  private sendEventToSubscribers(websocketResponse: WebsocketResponse) {
    const message: WebsocketEventName = websocketResponse?.message
    if (this._wsEventMap.has(message)) {
      this._wsEventMap.get(message).next(websocketResponse);
    }
  }

  getWebsocketEventSubject(name: WebsocketEventName) {
    return this._wsEventMap.get(name);
  }

  private resetRetries() {
    this._currentRetries = 0;
  }

  createWSconnection(url: string): void {
    this._currentUrl = url;
    this._websocketObservable = this._websocket.connect(url)
      .pipe(takeUntil(this._destroyed));

    this._websocketObservable
      .subscribe({
        next: (response: WebsocketResponse) => {
          if (response.message === "connected") {
            this.onHasConnected();
          }
          this.sendEventToSubscribers(response);
        },
        error: (e: unknown) => {
          this.onError(e);
        }
      });
  }

  private onHasConnected() {
    this.keepAlive();
    this.resetRetries();
    this._isConnected = true;
    if (this._reconnectAttempt) this.sendLastPayload();
    this.store.dispatch(new AddNotification({ type: 0, title: 'CONNECTED', message: 'Websocket Connected!' }));
    this._reconnecting = false;
  }

  private onError(error: any) {
    console.error("Error from main websocket", error);
    this.store.dispatch(new AddNotification({ type: 2, title: 'ERROR', message: 'Websocket connection lost!' }));
    this.closeConnection();
    this.autoReconnect();
    this._reconnecting = false;
  }

  private sendLastPayload() {
    if (this._lastPayload) {
      this.sendData(this._lastPayload);
    }
  }

  private keepAlive() {
    if (this._keepAliveStarted) return;

    this._keepAliveStarted = true;

    setInterval(() => {
      if (!this.isConnected) return;
      this._websocket.send("ping");
    }, 20_000);
  }

  private autoReconnect() {
    setTimeout(() => {
      //Retrun if exceeds max retry attempts
      if (this._currentRetries >= MAX_AUTO_RETRIES) return;

      this.reconnect();
    }, 5000);
  }


  reconnect() {
    //Retrun if connection is established, already trying to reconnect or there is no current url set
    if (!this._currentUrl || this._isConnected || this._reconnecting) return;

    this._currentRetries += 1;
    this.createWSconnection(this._currentUrl);
    this._reconnectAttempt = true;
    this._reconnecting = true;
  }


  sendData(requestMessage: WebSocketRequestMessage) {
    this._lastPayload = requestMessage;
    if (this.isConnected) {
      this._websocket.send(requestMessage);
    }
  }

  closeConnection() {
    this._destroyed.next(null);
    this._websocket.closeConnection();
    this._websocketObservable = null;
    this._isConnected = false;
  }

}
