import { Selector } from "@ngxs/store";
import { SimState } from "src/app/customer-data-components/sim-details/store/state/sim.state";
import { ExtendedCustomerService } from "../interfaces/proxy-customer-service.interface";
import { ExtendedServiceSelectors } from "./extended-service.selector";
import { ServiceFamily } from "../interfaces/service-family.interface";
import { SIM_TYPE_CODES } from "../interfaces/sim-type-code.interface";
import { SimMethods } from "src/app/customer-data-components/sim-details/assets/sim-methods";
import { ScheduledJobMessage } from "src/app/shared/interfaces/smartsub-data.interface";
import { CUSTOMER_SERVICE_STATUS } from "src/app/shared/constants/service-status.constant";
import { ProductCatalogState } from "src/app/core/store/state/product-state/product-catalog.state";
import { CatalogSimProduct } from "src/app/core/store/interfaces/product-catalog.interfaces";
import { Dictionary } from "src/app/shared/interfaces/dictionary.interface";
import { HomeOrWork } from "../state/home-or-work-services.state";


export class SelectedServiceSelectors {

    @Selector([SimState.getSelectedID, ExtendedServiceSelectors.getServices])
    static getSelectedService(id: string, services: ExtendedCustomerService[]) {
        return ExtendedServiceSelectors.getServiceById(id)(services);
    }

    @Selector([SimState.getSelectedID, ExtendedServiceSelectors.getServices])
    static getSelectedServiceFamily(id: string, services: ExtendedCustomerService[]) {
        return ExtendedServiceSelectors.getServiceFamilyById(id)(services);
    }

    @Selector([SelectedServiceSelectors.getSelectedServiceFamily])
    static hasScheduledActionsForSelectedServiceFamily(serviceFamily: ServiceFamily) {
        const { parentService, children } = serviceFamily ?? {};
        return [parentService, ...children ?? []].some(service => service?.scheduled_action?.length);
    }

    @Selector([SimState.getSelectedID, ExtendedServiceSelectors.getServices])
    static isCurrentSimESim(id: string, services: ExtendedCustomerService[]) {
        return ExtendedServiceSelectors.getServiceById(id)(services)?.sim_type_code === SIM_TYPE_CODES.eSIM;
    }


    @Selector([SelectedServiceSelectors.getSelectedService])
    static getScheduledActionsForCurrentSim(selectedService: ExtendedCustomerService) {
        return selectedService?.scheduled_action
    }

    @Selector([
      SelectedServiceSelectors.getSelectedService,
      ProductCatalogState.getProductsByTypeMappedById("sim")
    ])
    static getSimDetailsMessage(
      selectedService: ExtendedCustomerService,
      simProductDict: Dictionary<CatalogSimProduct>
    ): ScheduledJobMessage {

        const has4Gto5GMigration = SimMethods.hasScheduled4Gto5GMigration(selectedService);

        const { name, category } = simProductDict?.[selectedService?.product_id] ?? {}

        const is5G = category === '5G'
        const is5GWorkSim = name?.includes('work') && is5G
        const is5G101Sim = name?.includes('101') && is5G

        if (has4Gto5GMigration) {
            return {
                message: `5hrs after receiving the rainone order, the ${name} will be deactivated`,
                hasScheduledMigration: false
            }
        }

        if (SimMethods.hasPendingSimSwop(selectedService?.order_requests)) {


           const message = (is5G101Sim || is5GWorkSim) ? "Pending device swap." : "Pending sim swap."

            return {
                message,
                hasScheduledMigration: false
            }
        }

        if (SimMethods.hasScheduledMigration(selectedService)) {
          const { action_date } = selectedService?.scheduled_action?.find(action => action?.action_type === 'Change') ?? {}

            return {
                message: `A migration is pending scheduled date is ${action_date}`,
                hasScheduledMigration: true

            }
        }
    }

    @Selector([SelectedServiceSelectors.getSelectedService])
    static isRainOne(service: ExtendedCustomerService) {
        //TODO use a better way to check for rainOne
        return service?.current_speed?.toLowerCase()?.includes('rainone');
    }

    @Selector([SelectedServiceSelectors.getSelectedService])
    static isRainOne101(service: ExtendedCustomerService) {
        //TODO use a better way to check for rainOne
        return service?.current_speed?.toLowerCase()?.includes('101');
    }

    @Selector([
      SelectedServiceSelectors.getSelectedService,
      ProductCatalogState.getProductsByTypeMappedById("sim")
    ])
    static isRainOneWork(
      service: ExtendedCustomerService,
      simProductDict: Dictionary<CatalogSimProduct>
    ) {

        const { name } = simProductDict?.[service?.product_id] ?? {}
        const lowerCaseName = name?.toLocaleLowerCase()

        const isSME = lowerCaseName?.includes('sme');
        const isWork = lowerCaseName?.includes('work');

        return isSME || isWork

    }

    @Selector([SelectedServiceSelectors.isRainOneWork])
    static getSelectedServiceProductType(isWorkService: boolean ): HomeOrWork {
      return isWorkService ? 'work' : 'home'
    }

    @Selector([SelectedServiceSelectors.getSelectedService])
    static serviceHasScheduledCancellation(service: ExtendedCustomerService) {
        return SimMethods.isCancellationScheduled(service);
    }

    @Selector([SelectedServiceSelectors.getSelectedService])
    static serviceHasScheduledSpeedDown(service: ExtendedCustomerService) {
        if(SimMethods.isSpeedDownScheduled(service)) {
            const { action_date } = service?.scheduled_action?.find(action => action?.action_type === 'change_speed') ?? {}
            return {
                hasSpeedDown: true,
                message: `A migration is pending for a speed down request. Scheduled date is ${action_date}`,
                hasScheduledAction: true
            }
        }
    }

    @Selector([SelectedServiceSelectors.getSelectedService])
    static serviceHasScheduledLevelDown(service: ExtendedCustomerService) {
        if(SimMethods.isLevelDownScheduled(service)) {
            const { action_date } = service?.scheduled_action?.find(action => action?.action_type === 'product_migration') ?? {}
            return {
                hasLevelDown: true,
                message: `A migration is pending for a level down request. Scheduled date is ${action_date}`,
                hasScheduledAction: true
            }
        }
    }

    @Selector([SelectedServiceSelectors.getSelectedService])
    static serviceHasScheduledPreCancellation(service: ExtendedCustomerService) {
        return SimMethods.isPreCancellationScheduled(service);
    }

    @Selector([SelectedServiceSelectors.getSelectedService])
    static isSelectedServiceCancelled(service: ExtendedCustomerService) {
        return service?.status === CUSTOMER_SERVICE_STATUS['Service Canceled'];
    }

    @Selector([SelectedServiceSelectors.getSelectedService])
    static hasPendingMigration(service: ExtendedCustomerService) {
        return service?.pending_migration;
    }
}
