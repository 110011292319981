import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store, } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { AllTicketsService } from 'src/app/features/pages/ticket/services/all-tickets.service';
import { ChatTicketActions } from '../actions/chat-ticket-actions';
import { InteractionTicketActions } from '../actions/interaction-ticket-actions';
import { RefreshTicketActions } from '../actions/refresh-ticket-actions';
import { InteractionTicketsState } from './interaction-tickets.state';
import { InteractionsState } from './interactions.state';
import { Dictionary } from 'src/app/shared/interfaces/dictionary.interface';


interface RefreshTicketStateModel {
    loading: boolean;
    shouldRepeat: boolean;
}
@State<RefreshTicketStateModel>({
    name: 'sf_refresh_ticket_state',
    defaults: {
        loading: false,
        shouldRepeat: false
    }
})
@Injectable()
export class RefreshTicketState {

    @Selector()
    static isLoading(state: RefreshTicketStateModel) { return state.loading }

    constructor(private allTicketsService: AllTicketsService,
        private store: Store) {
    }

    @Action(RefreshTicketActions.RefreshTicket)
    Refresh(ctx: StateContext<RefreshTicketStateModel>, action: RefreshTicketActions.RefreshTicket) {
        const { hexId } = action;

        if (!this.areInteractionsActive()) {
            return;
        }

        ctx.patchState({ loading: true });

        return this.allTicketsService.getTicketByHexId(hexId)
            .pipe(tap({
                next: res => {
                    if (res?.data) {
                        return ctx.dispatch(new RefreshTicketActions.RefreshTicketSuccess(res));
                    }
                    else {
                        return ctx.dispatch(new RefreshTicketActions.RefreshTicketFail())
                    }
                },
                error: (e: unknown) => ctx.dispatch(new RefreshTicketActions.RefreshTicketFail(e))
            }));
    }

    @Action(RefreshTicketActions.RefreshTicketSuccess)
    RefreshSuccess(ctx: StateContext<RefreshTicketStateModel>, action: RefreshTicketActions.RefreshTicketSuccess) {
        ctx.patchState({ loading: false });

        if (!this.areInteractionsActive()) {
            return;
        }

        const ticket = action.payload.data;
        const actions: Dictionary[] = [
            new InteractionTicketActions.UpdateTicket(ticket)
        ];

        const selectedHexId = this.store.selectSnapshot(InteractionTicketsState.getSelectedHexId);

        if (selectedHexId && selectedHexId === ticket?.hex_id) {
            actions.push(new ChatTicketActions.SetTicket(ticket));
        }

        return ctx.dispatch(actions);
    }


    @Action(RefreshTicketActions.RefreshTicketFail)
    RefreshFail(ctx: StateContext<RefreshTicketStateModel>) {
        ctx.patchState({ loading: false });
    }

    private areInteractionsActive() {
        return this.store.selectSnapshot(InteractionsState.isActive);
    }

}   