import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import tinycolor from "tinycolor2";

@Component({
  selector: 'chat-box-element',
  templateUrl: './chat-box-element.component.html',
  styleUrls: ['./chat-box-element.component.scss']
})
export class ChatBoxElementComponent implements OnInit {

  @Output() close = new EventEmitter();
  @Output() showMore = new EventEmitter();

  @Input() showMoreText = "show more";
  @Input() hasShowMoreButton = false;

  @Input() hasCloseButton = false;
  @Input() time: string;
  @Input() backgroundColor: string;
  @Input() loading = false;

  @Input() canHover = false;

  border: string;
  textColor: string;

  ngOnInit(): void {
    this.border = `1px solid ${tinycolor(this.backgroundColor).darken(20).toString()}`;
    this.textColor = tinycolor(this.backgroundColor).darken(55).toString();
  }

  onShowMore() {
    this.showMore.next();
  }

  onClose() {
    this.close.next();
  }

}
