import { Component } from '@angular/core';
import { Select } from '@ngxs/store';
import { CallTimerState } from '../store/state/call-timer.state';
import { Observable } from 'rxjs';

//TODO: Can probably remove this component
@Component({
  selector: 'app-call-timer',
  templateUrl: './call-timer.component.html',
  styleUrls: ['./call-timer.component.scss']
})
export class CallTimerComponent {

  @Select(CallTimerState.getFormattedTime) formattedTime$: Observable<string>;

}
