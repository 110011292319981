import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AssignedCallbackRequestPayload, AssignedTicketRequestPayload, AssignedTicketResponse } from '../store/types/assigned-ticket-payload.interface';
import { HttpClient } from '@angular/common/http';
import { DEFAULT_HTTP_OPTIONS, ZERO_HEX } from 'src/app/constants';
import { Observable, timer } from 'rxjs';
import { AssignableTicket, AssignableTicketRefDetails } from '../store/types/assignable-hex-id.interface';
import { Store } from '@ngxs/store';
import { CoreState } from 'src/app/core/store/state/core.state';
import { AllTicketsService } from 'src/app/features/pages/ticket/services/all-tickets.service';
import { CallbackService } from '../../callback/services/callback.service';
import { WorkableCallbackSearchCriteria } from '../../callback/store/types/callback-search-criteria.interface';
import moment from 'moment';
import { catchError, first, map, switchMap, tap } from 'rxjs/operators';
import { Utils } from 'src/app/Utils';
import { WorkableCallbackResponse } from '../../callback/store/types/workable-callback-response.interface';
import { AgentViewTicketActions } from '../store/actions/agent-view-ticket-actions';

const REQUEST_INTERVAL = 10_000;

const mapWorkableCallback = (source$: Observable<WorkableCallbackResponse>): Observable<AssignableTicketRefDetails> =>
  source$.pipe(
    map(res => {
      const callback = res?.result?.workable_callback;

      if (!callback) {
        throw new Error("No callback found");
      }

      return {
        type: "callback",
        callback,
        hexId: callback?.external_ref
      }
    })
  );


@Injectable({
  providedIn: 'root'
})
export class TicketAssignmentService {

  constructor(private http: HttpClient,
    private allTicketService: AllTicketsService,
    private callbackService: CallbackService,
    private store: Store) {
  }

  pollForAssignableTicket(): Observable<AssignableTicket> {
    const agentEmail = this.store.selectSnapshot(CoreState.getAgentEmail);
    const agentTeam = this.store.selectSnapshot(CoreState.getTeamRole);

    return timer(0, REQUEST_INTERVAL)
      .pipe(
        tap({
          next: () => this.store.dispatch(new AgentViewTicketActions.UpdateTicketRequestTime())
        }),
        switchMap(() => this.fetchTicket({ agentEmail, agentTeam })),
        first(res => Boolean(res.data)),
        map(res => res.data)
      )
  }

  private fetchTicket(requestPayload: AssignedTicketRequestPayload) {

    return this.fetchCallbackForAgentOrGroup(requestPayload)
      .pipe(
        catchError(() => this.fetchTicketForAgent(requestPayload)),
        switchMap(res => {
          const { hexId } = res ?? {};
          if (!hexId || hexId === ZERO_HEX) {
            throw new Error("Invalid Hex ID");
          }

          return this.allTicketService.getTicketByHexId(res?.hexId)
            .pipe(
              map(ticketData => {
                const { type, callback } = res ?? {};
                const { data } = ticketData ?? {};
                if (!data) {
                  throw new Error("No ticket data");
                }

                return <AssignableTicket>{
                  type,
                  callback,
                  ticket: data
                }
              })
            )
        }),
        Utils.Helpers.mapToSuccessResult(),
      );
  }

  private fetchTicketForAgent(payload: AssignedTicketRequestPayload): Observable<AssignableTicketRefDetails> {
    const { agentEmail, agentTeam } = payload;
    const url = `${environment.snowflake_api_url}/ticket/fetchfrom/${agentTeam}/${agentEmail}`;

    return this.http.get<{ data: AssignedTicketResponse }>(url, DEFAULT_HTTP_OPTIONS)
      .pipe(
        map(res => {
          const { hex_id } = res?.data ?? {}

          return {
            type: "normal",
            callback: null,
            hexId: hex_id
          }
        })
      )
  }

  /**
   * Agents will get their specific callbacks first if they are available in the window 
   * of 5mins before and 5mins after the schedules time @constant agentPayload.
   * Only check on a group level after that period @constant groupPayload
   */
  private fetchCallbackForAgentOrGroup(requestPayload: AssignedCallbackRequestPayload): Observable<AssignableTicketRefDetails> {
    const { agentTeam, agentEmail } = requestPayload;
    const fiveMinutesFromNow = moment().add(5, "minutes").valueOf() / 1000;
    const fiveMinutesInThePast = moment().subtract(5, "minutes").valueOf() / 1000;

    const agentPayload: WorkableCallbackSearchCriteria = {
      user_refs: [agentEmail],
      scheduled_date_to: fiveMinutesFromNow,
    }

    const groupPayload: WorkableCallbackSearchCriteria = {
      groups: [agentTeam],
      scheduled_date_to: fiveMinutesInThePast
    }

    //Firstly check for a callback for a specific agent and then for the group
    return this.callbackService.fetchWorkableCallback(agentPayload)
      .pipe(
        mapWorkableCallback,
        catchError(() =>
          this.callbackService.fetchWorkableCallback(groupPayload)
            .pipe(mapWorkableCallback)
        )
      );
  }


}










