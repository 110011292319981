import { Utils } from "src/app/Utils";
import { PickupPoint } from "src/app/sales/store/interfaces/pickup-point.interface";

export class FetchIfNotLoadingOrLoaded {
    static readonly type = Utils.Helpers.Type("[Pickup Points] Fetch if not loading or loaded");
}

export class Fetch {
    static readonly type = Utils.Helpers.Type("[Pickup Points] Fetch");
}

export class FetchSuccess {
    static readonly type = Utils.Helpers.Type("[Pickup Points] Fetch success");
    constructor(public payload: PickupPoint[]) { }
}

export class FetchFail {
    static readonly type = Utils.Helpers.Type("[Pickup Points] Fetch fail");
    constructor(public error: unknown) { }
}

export class SetAddressPostalCode {
    static readonly type = Utils.Helpers.Type("[Pickup Points] Set address postal code");
    constructor(public postalCode: string) { }
}

export class SetSelectedLocationId {
    static readonly type = Utils.Helpers.Type("[Pickup Points] Set selected location id");
    constructor(public locationId: string) { }
}

export class ClearSelectedOptions {
    static readonly type = Utils.Helpers.Type("[Pickup Points] Clear selected options");
}
export class Clear {
    static readonly type = Utils.Helpers.Type("[Pickup Points] Clear");
}