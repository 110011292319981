import { CustomerTicket, ShortTicket } from "src/app/shared/customer-ticket/interfaces/customer-ticket.interface";
import { Utils } from "src/app/Utils";
import { InteractionTicketRequest } from "../../interfaces/interaction-ticket-request.interface";
import { TicketInteractionResponse } from "../../interfaces/interaction-ticket-response.interface";


export class Fetch {
    static readonly type = Utils.Helpers.Type('[InteractionTicket: Fetch] Fetch');
    constructor(public payload: InteractionTicketRequest, public loaderType: keyof typeof LOADER_CONFIG = "default") { }
}

export class FetchSuccess {
    static readonly type = Utils.Helpers.Type('[InteractionTicket: Fetch] Fetch success');
    constructor(public payload: TicketInteractionResponse) { }
}

export class FetchFail {
    static readonly type = Utils.Helpers.Type('[InteractionTicket: Fetch] Fetch fail');
    constructor(public error?: unknown) { }
}

export class FetchNextPage {
    static readonly type = Utils.Helpers.Type('[InteractionTicket: Fetch] Fetch next page');
    constructor(public email: string) { }
}

export class FetchNextPageSuccess {
    static readonly type = Utils.Helpers.Type('[InteractionTicket: Fetch] Fetch next page success');
    constructor(public payload: TicketInteractionResponse) { }
}

export class FetchNextPageFail {
    static readonly type = Utils.Helpers.Type('[InteractionTicket: Fetch] Fetch next page fail');
    constructor(public error?: unknown) { }
}

export class UpdateTicket {
    static readonly type = Utils.Helpers.Type('[InteractionTicket: Set] Update ticket if present, else add it');
    constructor(public ticket: ShortTicket | CustomerTicket) { }
}

export class UpdateTicketAndSetAsAssigned {
    static readonly type = Utils.Helpers.Type('[InteractionTicket: Set] Update ticket and set as assigned');
    constructor(public ticket: ShortTicket | CustomerTicket) { }
}

export class SetAssignedHexId {
    static readonly type = Utils.Helpers.Type('[InteractionTicket: Set] Set assigned HexId');
    constructor(public hexId: string) { }
}

export class SetSelectedHexId {
    static readonly type = Utils.Helpers.Type('[InteractionTicket: Set] Set selected HexId');
    constructor(public hexId: string) { }
}

export class RefreshAllTickets {
    static readonly type = Utils.Helpers.Type('[InteractionTicket: Refresh] Refresh all tickets');
}

export class SetPageSize {
    static readonly type = Utils.Helpers.Type('[InteractionTicket: Set] Set page size');
    constructor(public size: number | "default") { }
}

export class Clear {
    static readonly type = Utils.Helpers.Type('[InteractionTicket: Clear] Clear');
    constructor(public options: { keepAssignedHexId: boolean }) { }
}


export const LOADER_CONFIG = {
    nextPage: {
        loader: { nextPageLoading: true },
        noTicketsError: "No more tickets left to load",
        ErrorAction: FetchNextPageFail,
        SuccessAction: FetchNextPageSuccess,
    },
    default: {
        loader: { loading: true },
        noTicketsError: "No tickets for this customer",
        ErrorAction: FetchFail,
        SuccessAction: FetchSuccess,
    }
} as const;

