import { Utils } from "src/app/Utils";


export class Init {
    static readonly type = Utils.Helpers.Type("[NewLoadsheddingPerSim] Fetch data if not already fetched");
    constructor(public id: string) { }
}


export class Fetch {
    static readonly type = Utils.Helpers.Type("[NewLoadsheddingPerSim] Fetch");
    constructor(public id: string) { }
}

export class FetchSuccess {
    static readonly type = Utils.Helpers.Type("[NewLoadsheddingPerSim] Fetch success");
    constructor(public id: string, public payload: any) { }
}

export class FetchFail {
    static readonly type = Utils.Helpers.Type("[NewLoadsheddingPerSim] Fetch fail");
    constructor(public id: string, public error: any) { }
}

export class Clear {
    static readonly type = Utils.Helpers.Type("[NewLoadsheddingPerSim] Clear");
    constructor(public id: string, public error: any) { }
}