import { Component, Input } from '@angular/core';
import { DataHandler } from '../../data-handler/data-handler';
import { StatusTooltip } from '../../models/status-tooltip.model';

@Component({
  selector: 'sf-info-icon',
  templateUrl: './info-icon.component.html',
  styleUrls: ['./info-icon.component.scss']
})
export class InfoIconComponent {

  @Input() statusTooltip: StatusTooltip;

  getColorCode(colorChoice: string) {
    return DataHandler.getColorCode(colorChoice);
  }

  getIcon(colorChoice: string) {
    return DataHandler.getIcon(colorChoice);
  }

}
