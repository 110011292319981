/**
 * DEFAULT_LATITUDE @param {number}
 */
export const DEFAULT_LATITUDE = -26.0505936;

/**
 * DEFAULT_LONGITUDE @param {number}
 */
export const DEFAULT_LONGITUDE = 28.0222714;

/**
 * DEFAULT_ZOOM_LEVEL @param
 */
export const DEFAULT_ZOOM_LEVEL = 14;

export const MAX_ZOOM_LEVEL_OVERLAY_SUPPORT = 18;

/**
 * DEFAULT_POSTAL_CODE @param
 */
export const DEFAULT_POSTAL_CODE = '0000';

/**
 * CONFIG_SPEND_LIMIT @param
 */
export const CONFIG_SPEND_LIMIT = 'spend-limit';

/**
 * CONFIG_MIGRATE @param
 */
export const CONFIG_MIGRATE = 'migrate';

/**
 * CONFIG_FRIENDLY_NAME @param
 */
export const CONFIG_FRIENDLY_NAME = 'friendly_name';

/**
 * CONFIG_OFF_PEAK @param
 */
export const CONFIG_OFF_PEAK = 'offPeak';

/**
 * IG_PEAK @param
 */
export const CONFIG_PEAK = 'peak';

/**
 * CONFIG_RESET_TRY @param
 */
export const CONFIG_RESET_TRY = 'reset_try';

/**
 * CONFIG_TARIF @param
 */
export const CONFIG_TARIF = 'tarif';

/**
 * CONFIG_VALUE_ADDS @param
 */
export const CONFIG_VALUE_ADDS = 'value_adds';

/**
 * CONFIG_CONFIGS @param
 */
export const CONFIG_CONFIGS = 'configs';

/**
 * CONFIG_POSITION @param
 */
export const CONFIG_POSITION = 'position';

/**
 * HTTP_HEADER_API_KEY @param
 */
export const HTTP_HEADER_API_KEY = 'apikey';

/**
 * HTTP_HEADER_KEY @param
 */
export const HTTP_HEADER_KEY = 'key';

/**
 * CONFIG_BETA @param
 */
export const CONFIG_BETA = 'beta';

/**
 * CACHE_BETA_PROGRAMS @param
 */
export const CACHE_BETA_PROGRAMS = 'CACHE_BETA_PROGRAMS';

/**
 * CACHE_PARGO @param
 */
export const CACHE_PARGO = 'CACHE_PARGO';

/**
 * CACHE_SUPPORT @param
 */
export const CACHE_SUPPORT = 'CACHE_SUPPORT';

/**
 * CACHE_MML_DATA @param
 */
export const CACHE_MML_DATA = 'CACHE_MML_DATA';

/**
 * CACHE_TOP_10_SUPPORT @param
 */
export const CACHE_TOP_10_SUPPORT = 'CACHE_TOP_10_SUPPORT';

/**
 * CACHE_SUPPORT_GROUP @param
 */
export const CACHE_SUPPORT_GROUP = 'CACHE_SUPPORT_GROUP';

/**
 * DEFAULT_TARRIF @param
 */
export const DEFAULT_TARRIF = 50;

/**
 * CACHE_CART_RESULT @param
 */
export const CACHE_CART_RESULT = 'CACHE_CART_RESULT';

/**
 * ORIGIN @param
 */
export const ORIGIN = 'ORIGIN';

/**
 * RICA_TYPE @param
 */
export const RICA_TYPE = 'rica_type';

/**
 * RICA_TYPE_COURIER @param
 */
export const RICA_TYPE_COURIER = 'courier';

/**
 * RICA_TYPE_E_RICA @param
 */
export const RICA_TYPE_E_RICA = 'e-rica';

/**
 * CACHE_SUPPORT_ADDED @param
 */
export const CACHE_SUPPORT_ADDED = 'CACHE_SUPPORT_ADDED';


/**
 * CACHE_4GTO5G_MIGRATION @param
 */
export const CACHE_4GTO5G_MIGRATION = 'CACHE_4GTO5G_MIGRATION_ADDED';

/**
 * CACHE_SUPPORT_SALE_ADDED @param
 */
export const CACHE_SUPPORT_SALE_ADDED = 'CACHE_SUPPORT_SALE_ADDED';

/**
 * CACHE_PRODUCTS_KEY @param
 */
export const CACHE_PRODUCTS_KEY = 'CACHE_PRODUCTS';

/**
 * CACHE_LOGISTIC @param
 */
export const CACHE_LOGISTIC = 'CACHE_LOGISTIC';

/**
 * CACHE_ORDERS @param
 */
export const CACHE_ORDERS = 'CACHE_ORDERS';

/**
 * CACHE_PAY_NOW @param
 */
export const CACHE_PAY_NOW = 'CACHE_PAY_NOW';

/**
 * CACHE_IDM_TOKEN @param
 */
export const CACHE_GIVE_A_GIG_PURCHASE_DETAILS = 'CACHE_GIVE_A_GIG_PURCHASE_DETAILS';

/**
 * CACHE_IDM_TOKEN @param
 */
export const CACHE_EDIT_GIVE_A_GIG = 'CACHE_EDIT_GIVE_A_GIG';

/**
 * CACHE_SCHEDULE_MIGRATION @param
 */
export const CACHE_SCHEDULE_MIGRATION = 'CACHE_SCHEDULE_MIGRATION';

/**
 * CACHE_IDM_TOKEN @param
 */
export const CACHE_IDM_TOKEN = 'CACHE_IDM_TOKEN';

/**
 * CONFIG_ICCID @param
 */
export const CONFIG_ICCID = 'iccid';

/**
 * CART_PRE_RICA_STATUS @param
 */
export const CART_PRE_RICA_STATUS = 'CART_PRE_RICA_STATUS';

/**
 * CONFIG_STAFF @param
 */
export const CONFIG_STAFF = 'staff';

/**
 * CONFIG_DATA_PLAN @param
 */
export const CONFIG_DATA_PLAN = 'dataPlan';

/**
 * CONFIG_SPEED @param
 */
export const CONFIG_SPEED = 'speed';

/**
 * CONFIG_STREAM @param
 */
export const CONFIG_STREAM = 'stream';

/**
 * CONFIG_SIM_TYPE @param
 */
export const CONFIG_SIM_TYPE = 'sim_type';

/**
 * SIM_TYPE_VIRTUAL @param
 */
export const SIM_TYPE_VIRTUAL = 'virtual';

/**
 * SIM_TYPE_PHYSICAL @param
 */
export const SIM_TYPE_PHYSICAL = 'physical';

/**
 * PAY_NOW_TYPE @param
 */
export const PAY_NOW_TYPE = 'PAY_NOW_TYPE';

/**
 * PAYMENT_STATUS @param
 */
export const PAYMENT_STATUS = 'status';

/**
 * PAYMENT_REDIRECT @param
 */
export const PAYMENT_REDIRECT = 'redirect';

/**
 * PAY_ORDER_ID @param
 */
export const PAY_ORDER_ID = 'PAY_ORDER_ID';

/**
 * ORDER_HAS_5G_PRODUCT @param
 */
export const ORDER_HAS_5G_PRODUCT = 'ORDER_HAS_5G_PRODUCT';

/**
 * SERVICE_META_DATA_CHARACTERISTICS @param
 */
export const SERVICE_META_DATA_CHARACTERISTICS = 'characteristics';

/**
 * MAP_SEARCH_CONFIG @param
 */
export const MAP_SEARCH_CONFIG = {
  componentRestrictions: {
    country: ['ZA']
  },
  types: ['address']
};

/**
 * CACHE_CART @param
 */
export const CACHE_CART = 'CART_CACHE';

/**
 * SHOW_ADDRESS_KEY @param
 */
export const SHOW_ADDRESS_KEY = 'showAddress';

/**
 * COOKIERBANNER @param
 */
export const COOKIERBANNER = 'displayCookieBanner';

/**
 * NO_MORE_4G_SIM_MESSAGE @param
 */
export const NO_MORE_4G_SIM_MESSAGE =
  "We’re sorry but we can’t complete this order as you’ve reached your limit of 4G SIMs in your account. You only have {NUMBER} available, please remove some products to continue.";

/**
 * NO_MORE_5G_SIM_MESSAGE @param
 */
export const NO_MORE_5G_SIM_MESSAGE =
  "We’re sorry but we can’t complete this order as you’ve reached your limit of 5G SIMs in your account. You only have {NUMBER} available, please remove some products to continue.";

/**
 * BETA_ERROR_MESSAGE @param
 */
export const BETA_ERROR_MESSAGE = 'You are not allowed to buy one of these beta products.';

/**
 * CACHE_COURIER_STATUS @param
 */
export const CACHE_COURIER_STATUS = 'CACHE_COURIER_STATUS';

/**
 * CACHE_LOGISTIC_STATUS @param
 */
export const CACHE_LOGISTIC_STATUS = 'CACHE_LOGISTIC_STATUS';

/**
 * CACHE_CURRENT_ORDER @param
 */
export const CACHE_CURRENT_ORDER = 'CURRENT_ORDER';

/**
 * CACHE_CURRENT_DELIVERY_ADDRESS @param
 */
export const CACHE_CURRENT_DELIVERY_ADDRESS = 'CURRENT_DELIVERY_ADDRESS';

/**
 * CACHE_CURRENT_DELIVERY_CONFIGS @param
 */
export const CACHE_CURRENT_DELIVERY_CONFIGS = 'CURRENT_DELIVERY_CONFIGS';

/**
 * CACHE_CURRENT_ORDER_ID @param
 */
export const CACHE_CURRENT_ORDER_ID = 'CURRENT_ORDER_ID';

/**
 * CACHE_PROFILE @param
 */
export const CACHE_PROFILE = 'CACHE_PROFILE';

/**
 * CACHE_SERVICES @param
 */
export const CACHE_SERVICES = 'CACHE_SERVICES';

/**
 * CACHE_SERVICE @param
 */
export const CACHE_SERVICE = 'CACHE_SERVICE_';

/**
 * CACHE_DEVICE @param
 */
export const CACHE_DEVICE = 'CACHE_DEVICE_';

/**
 * CACHE_AUTH_TOKEN @param
 */
export const CACHE_AUTH_TOKEN = 'CACHE_AUTH_TOKEN';

/**
 * CURRENT_ADDRESS @param
 */
export const CURRENT_ADDRESS = 'CURRENT_ADDRESS';

/**
 * CPEX_TAC_CODE @param
 */
export const CPEX_TAC_CODE = '86510304';

/**
 * CPE_PRO_TAC_CODE_V1 @param
 */
export const CPE_PRO_TAC_CODE_V1 = '86720604';

/**
 * CPE_PRO_TAC_CODE_V2 @param
 */
export const CPE_PRO_TAC_CODE_V2 = '86073004';

/**
 * CACHE_ADDRESS_SEARCH @param
 */
export const CACHE_ADDRESS_SEARCH = 'CACHE_ADDRESS_SEARCH';

/**
 * ORIGIN_GOT_SIM @param
 */
export const ORIGIN_GOT_SIM = 'gotSIM';

/**
 * CACHE_RAIN_NETWORK_INFO @param
 */
export const CACHE_RAIN_NETWORK_INFO = 'CACHE_RAIN_NETWORK_INFO';

/**
 * HTTP_HEADER_RE_CAPTCHA @param
 */
export const HTTP_HEADER_RE_CAPTCHA = 'g-recaptcha-response';
