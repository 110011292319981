import { StateContext } from '@ngxs/store';
import { DataHandler } from 'src/app/shared/data-handler/data-handler';
import { OneOf } from 'src/app/shared/types/one-of.type';
import { DataLoading, getDataLoadingDefaultValues } from '../../shared/interfaces/data-loading.interface';

export class DataLoadingHelper {

  static isLoading<T, E = any>(): DataLoading<T, E> {
    return {
      loading: true,
      loaded: false,
      error: null,
      data: null
    }
  }

  static clearData(ctx: StateContext<DataLoading<any, any>>) {
    ctx.patchState({
      loading: false,
      loaded: false,
      error: null,
      data: null
    });
  }

  static clearNestedData(ctx: StateContext<{ [x: string]: DataLoading<any, any> }>, key: string) {
    if (!DataLoadingHelper.hasKey(ctx, key)) {
      return;
    }

    ctx.patchState({
      [key]: {
        loading: false,
        loaded: false,
        error: null,
        data: null
      }
    });
  }

  static errorLoaded<E = string>(error: E) {
    return {
      loading: false,
      loaded: true,
      error,
      data: null
    }
  }

  static setError<E = string>(ctx: StateContext<DataLoading<any, E>>, error: E) {
    ctx.patchState({
      loading: false,
      loaded: true,
      error,
      data: null
    });
  }

  static setNestedError<E = string>(ctx: StateContext<{ [x: string]: DataLoading<any, E> }>, key: string | number, error: E) {
    if (!DataLoadingHelper.hasKey(ctx, key)) {
      return;
    }

    ctx.patchState({
      [key]: {
        loading: false,
        loaded: true,
        error,
        data: null
      }
    });
  }

  static dataLoaded<T>(data: T) {
    return {
      loading: false,
      loaded: true,
      error: null,
      data
    }
  }

  static setData<T>(ctx: StateContext<DataLoading<T, any>>, data: T) {
    ctx.patchState({
      loading: false,
      loaded: true,
      error: null,
      data
    });
  }

  static initNestedState<T>(ctx: StateContext<{ [x: string]: DataLoading<T, any> }>, key: string | number) {
    const state = ctx.getState();

    const hasKey = DataHandler.isDefined(state?.[key]);
    if (hasKey) {
      return;
    }

    const currentStateCopy = DataHandler.createDeepCopy(state);
    currentStateCopy[key] = getDataLoadingDefaultValues();
    ctx.setState(currentStateCopy);
  }

  static setNestedData<T>(ctx: StateContext<{ [x: string]: DataLoading<T, any> }>, key: string | number, data: T) {
    if (!DataLoadingHelper.hasKey(ctx, key)) {
      return;
    }

    ctx.patchState({
      [key]: {
        loading: false,
        loaded: true,
        error: null,
        data
      }
    });
  }

  static setNestedDataLoading<T>(ctx: StateContext<{ [x: string]: DataLoading<T, any> }>, key: string | number) {
    if (!DataLoadingHelper.hasKey(ctx, key)) {
      return;
    }

    ctx.patchState({
      [key]: {
        loading: true,
        loaded: false,
        error: null,
        data: null
      }
    });
  }

  static clearNestedDataForKey(ctx: StateContext<{ [x: string]: DataLoading<any, any> }>, key: string | number) {
    if (!DataLoadingHelper.hasKey(ctx, key)) {
      return;
    }

    ctx.patchState({
      [key]: {
        loading: false,
        loaded: false,
        error: null,
        data: null
      }
    });
  }

  static setFinishedLoading(ctx: StateContext<{ loading: boolean, loaded: boolean }>) {
    ctx.patchState({
      loaded: true,
      loading: false
    });
  }

  static getHasLoadedPayload<T, E = string>(info: OneOf<{ data: T, error: E }>): DataLoading<T, E> {

    return {
      loaded: true,
      loading: false,
      error: info?.error ?? null,
      data: info?.data ?? null
    }
  }

  static hasKey(ctx: StateContext<any>, key: string | number) {
    return DataHandler.isDefined(ctx.getState()?.[key]);
  }

}
