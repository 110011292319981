<app-card-template *ngIf="showShadow && showCard; else noShadow" [showTitle]="false">
    <label *ngIf="label">{{label}}</label>
    <div [formGroup]="_searchForm" class="search-lockup">
        <mat-icon>search</mat-icon>

        <input type="text" [formControl]="_searchForm.get('search_query')" [placeholder]="placeholder">
        <div class="post-icon">
            <fa-icon *ngIf="searching" [icon]="spinner" size="sm" class="text-dark-grey fa-icon"></fa-icon>
        </div>
    </div>
</app-card-template>

<ng-template #noShadow>
    <label class="text-blue" *ngIf="label">{{label}}</label>
    <div [formGroup]="_searchForm" class="search-lockup">
        <mat-icon>search</mat-icon>
        <input type="text" [formControl]="_searchForm.get('search_query')" [placeholder]="placeholder">
        <div class="post-icon">
            <fa-icon *ngIf="searching" [icon]="spinner" size="sm" class="text-dark-grey fa-icon"></fa-icon>
        </div>
    </div>
</ng-template>
