import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { Utils } from 'src/app/Utils';
import { DataLoading, getDataLoadingDefaultValues } from 'src/app/shared/interfaces/data-loading.interface';
import { DataLoadingHelper } from 'src/app/Utils/helpers';
import { FirebaseService } from 'src/app/core/services/firebase.service';
import { FirebaseFeatureFlagsActions } from '../actions/firebase-feature-flags-actions';
import { FirebaseFeatureFlagsResponse } from '../interfaces/firebase-feature-flags-response.interface';

type FirebaseFeatureFlagsStateModel = DataLoading<FirebaseFeatureFlagsResponse>
type FirebaseFeatureFlagsStateContext = StateContext<FirebaseFeatureFlagsStateModel>

@State<FirebaseFeatureFlagsStateModel>({
  name: 'sf_FirebaseFeatureFlags_state',
  defaults: getDataLoadingDefaultValues()
})
@Injectable()
export class FirebaseFeatureFlagsState {

  @Selector()
  static isLoading(state: FirebaseFeatureFlagsStateModel) { return state.loading }

  @Selector()
  static isLoaded(state: FirebaseFeatureFlagsStateModel) { return state.loaded }

  @Selector()
  static getData(state: FirebaseFeatureFlagsStateModel) { return state.data }

  @Selector()
  static getError(state: FirebaseFeatureFlagsStateModel) { return state.error }

  constructor(private firebaseService: FirebaseService) { }

  @Action(FirebaseFeatureFlagsActions.FetchIfNotLoadingOrLoaded)
  FetchIfNotLoadingOrLoaded(ctx: FirebaseFeatureFlagsStateContext) {
    const { loading, loaded } = ctx.getState();
    if (!loading || loaded) {
      return ctx.dispatch(new FirebaseFeatureFlagsActions.Fetch());
    }
  }

  @Action(FirebaseFeatureFlagsActions.Fetch)
  Fetch(ctx: FirebaseFeatureFlagsStateContext) {

    ctx.patchState({ loading: true });

    return this.firebaseService.fetchConfig<FirebaseFeatureFlagsResponse>('featureFlags')
      .pipe(
        tap({
          next: res => ctx.dispatch(new FirebaseFeatureFlagsActions.FetchSuccess(res)),
          error: (e: unknown) => ctx.dispatch(new FirebaseFeatureFlagsActions.FetchFail(e))
        })
      );
  }

  @Action(FirebaseFeatureFlagsActions.FetchSuccess)
  FetchSuccess(ctx: FirebaseFeatureFlagsStateContext, action: FirebaseFeatureFlagsActions.FetchSuccess) {
    const { response } = action;
    DataLoadingHelper.setData(ctx, response);
  }

  @Action(FirebaseFeatureFlagsActions.FetchFail)
  FetchFail(ctx: FirebaseFeatureFlagsStateContext, action: FirebaseFeatureFlagsActions.FetchFail) {
    const error = Utils.Helpers.findError(action.error, '');
    DataLoadingHelper.setError(ctx, error);
  }

  @Action([FirebaseFeatureFlagsActions.Clear])
  Clear(ctx: FirebaseFeatureFlagsStateContext) {
    DataLoadingHelper.clearData(ctx)
  }

}
