import { SFValidators } from "src/app/shared/functions/sf-validators";
import { ISuccessResult } from "src/app/shared/models/success-result.model";
import { NullLike } from "src/app/shared/types/null-like.type";

const FIRST = 0;
const START = 10;
const END = 29;
const ICCID_LENGTH = 19;
const FOUR = "4";

const CHARACTER_MAP: Record<string, string> = {
    "0": "1",
    "1": "2",
    "2": "3",
    "3": "4",
    "4": "5",
    "5": "6",
    "6": "7",
    "7": "8",
    "8": "9",
    "9": "B",
    A: "C",
    B: "D",
    C: "F",
    D: "G",
    E: "H",
    F: "J",
    G: "K",
    H: "L",
    I: "M",
    J: "N",
    K: "P",
    L: "R",
    M: "S",
    N: "T",
    O: "V",
    P: "W",
    Q: "X",
    R: "Y",
    S: "Z"
}

export const decodeICCID = (iccid: string | NullLike): ISuccessResult<string, string> => {

    if (SFValidators.isNotDefined(iccid)) {
        return {
            data: null,
            error: "Failed, missing ICCID"
        }
    }

    if (iccid.length < ICCID_LENGTH) {
        return {
            data: null,
            error: `Failed, ICCID length less than 19`
        }
    }

    const substring = iccid.substring(START);

    if (FOUR !== substring.charAt(FIRST)) {
        console.error(`Invalid character ${substring.charAt(FIRST)} in ICCID`);

        return {
            data: null,
            error: "not available"
        }
    }

    const iccidBase29 = parseInt(substring, START)
        .toString(END)
        .toUpperCase();

    const convertedArray: string[] = [];

    for (const char of iccidBase29) {

        const newChar = CHARACTER_MAP[char];

        if (newChar) {
            convertedArray.push(newChar);
        }
        else {
            return {
                data: null,
                error: `Invalid character ${char} in ICCID`
            }
        }
    }

    return {
        data: convertedArray.join(""),
        error: null
    };
}