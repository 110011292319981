<app-rain-card *ngIf="isOpen$ | async" height="30rem">

  <ng-container front>
    <sf-margin-box>
      <h2 class="text text-blue">connected site load shedding schedule for {{today}}</h2>

      <mat-icon class="default-cancel-icon" (click)="onClose()">cancel</mat-icon>

      <div class="sticky-table scrollable main-content">

        <ng-container *ngIf="isLoaded$ | async; else loading">
          <ng-container *ngIf="(schedulePerSim$ | async)?.schedule as schedule; else noData">
            <table>
              <thead>
                <tr>
                  <td>start time</td>
                  <td>end time</td>
                  <td class="filter pointer" [matMenuTriggerFor]="menu">stage
                    <mat-icon class="filter">filter_list</mat-icon>

                    <mat-menu #menu="matMenu" yPosition="below">
                      <button mat-menu-item *ngFor="let option of filterOptions" class="font-normal text-blue"
                        (click)="setFilter(option)">
                        <span [style.font-weight]="option === currentFilter ? 800 : 400">
                          {{option}}
                        </span>
                      </button>
                    </mat-menu>
                  </td>

                </tr>
              </thead>
              <tbody>
                <ng-container *ngFor="let item of schedule">
                  <tr
                    [ngClass]="currentLoadSheddingStage>item?.stage || currentLoadSheddingStage===item?.stage ? 'highlight-stage':''"
                    *ngIf="item?.stage === currentFilter || currentFilter ==='No Filter'">
                    <td>{{item?.timestamp_start | date: 'HH:mm'}}</td>
                    <td>{{item?.timestamp_end | date: 'HH:mm'}}</td>
                    <td>{{item?.stage}}</td>
                  </tr>
                </ng-container>
              </tbody>
            </table>
          </ng-container>

          <ng-template #noData>
            <ng-container *ngIf="error$ | async as error; else defaultError">
              <p class="red">error</p>
            </ng-container>

            <ng-template #defaultError>
              <p class="red">Failed to fetch load shedding data for site.</p>
            </ng-template>
          </ng-template>

        </ng-container>

        <ng-template #loading>
          <app-custom-spinner></app-custom-spinner>
        </ng-template>

      </div>
    </sf-margin-box>

    <sf-rain-button class="refresh-button" (click)="onRefresh()"
      [disabled]="(isLoading$ | async) || !(currentSiteID$ | async)">
      refresh
    </sf-rain-button>


  </ng-container>

</app-rain-card>
