

export function getUniqueArray<T extends Array<I>, I>(arr: T, key: keyof T[number]) {
    const map = new Map<I[keyof I], true>();
    const values: T[number][] = [];

    for (const item of arr) {
        const id = item[key];

        if (!map.has(id)) {
            values.push(item);
            map.set(id, true);
        }
    }

    return values;
}