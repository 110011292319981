import { Component, OnDestroy } from '@angular/core';
import { FormGroup, AbstractControl, ValidationErrors } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
@Component({
    template: ''
})
export class FormHelper implements OnDestroy {
    public _form: FormGroup;
    protected ngDestroy$: Subject<void> = new Subject();

    constructor(protected component_form?: FormGroup) {
        if (this.component_form) this._form = this.component_form;
    }

    public InitForm(component_form: FormGroup) {
        this._form = component_form;
    }

    /**
     * @param {string} control_name
     * @param {string} [group_name]
     * @returns {AbstractControl}
     * @memberof FormHelper
     */
    public getControl(control_name: string, group_name?: string): AbstractControl {
        if (group_name) return this._form.get(group_name).get(control_name);

        return this._form.get(control_name);
    }


    /**
     * @param {*} new_value
     * @param {string} control_name
     * @param {string} [group_name]
     * @memberof FormHelper
     */
    public updateControl(new_value: any, control_name: string, group_name?: string) {
        this.getControl(control_name, group_name).patchValue(new_value);
    }

    public markAllAsDirty(group_name:string) {
      Object.keys(this._form.get(group_name).value).forEach(key => {
        return this.getControl(key, group_name).markAsDirty()
      })
    }


    /**
     * @param {string} control_name
     * @param {string} [group_name]
     * @returns {ValidationErrors}
     * @memberof FormHelper
     */
    public getErrors(control_name?: string, group_name?: string): ValidationErrors {
        return control_name ? this.getControl(control_name, group_name).errors : this._form.errors;
    }

    /**
   * @param {string} control_name
   * @param {string} [group_name]
   * @returns {ValidationErrors}
   * @memberof FormHelper
   */
    public isDirty(control_name?: string): boolean {
        if(this._form) {
          return control_name ? this.getControl(control_name).dirty : this._form?.dirty;
        }
    }


    /**
     * @returns {boolean}
     * @memberof FormHelper
     */
    public isValid(control_name?: string, group_name?: string): boolean {
        return control_name ? this.getControl(control_name, group_name).valid : this._form.valid;
    }


    /**
     * @param {string} control_name
     * @param {string} [group_name]
     * @memberof FormHelper
     */
    public disable(control_name: string, group_name?: string) {
        this.getControl(control_name, group_name).disable({ emitEvent: true });
    }


    /**
     * @param {string} control_name
     * @param {string} [group_name]
     * @memberof FormHelper
     */
    public enable(control_name: string, group_name?: string) {
        this.getControl(control_name, group_name).enable({ emitEvent: true });
    }

    /**
     *
     * @param {string} [control_name]
     * @param {string} [group_name]
     * @returns {Observable<any>}
     * @memberof FormHelper
     */
    public events(control_name?: string, group_name?: string): Observable<any> {
        if (control_name && group_name) return this.getControl(control_name, group_name).valueChanges;

        if (control_name) return this.getControl(control_name).valueChanges;

        return this._form.valueChanges;
    }

    public checkForErrors(control_name?: string) {
        if(this._form) {
            if (control_name) {
                if (this.isDirty(control_name) && this.getControl(control_name).touched) {
                    return this.getErrors(control_name);
                }
            }

            return this.getErrors()
        }
    }

    protected ValidateSelect(placeholder = 'select') {
        return (control: AbstractControl): ValidationErrors | null => {
            if(typeof control?.value === 'string') {

                if (control?.value && control.value.toLowerCase().includes(placeholder.toLowerCase())) {
                    return { notSelected: true };
                  }
                  return null;
            }

            if (control?.value && typeof placeholder !== 'string' && control.value === placeholder) {
                return { notSelected: true };
              }
              return null;
        }
      }

    protected AlphaNumericValidator() {

        return (control: AbstractControl): ValidationErrors | null => {
        if (control?.value && (!(/[a-zA-Z0-9-_ ]/.test(control.value)) || !(/[0-9-_ ]/.test(control.value)))) {
            return { notAlphaNumeric: true };
        }
        return null;
        }
    }

      public onOptionSelect(o, control_name: string) {
        this._form.get(control_name).patchValue(o);
      }


    protected ValidateZipCode() {
      return (control: AbstractControl): ValidationErrors | null => {
        if (control?.value && control.value === '0000') {
          return { inValid: true };
        }
        return null;
      }
    }

    protected ValidateDoesNotStartWithZero(){
      return (control: AbstractControl): ValidationErrors | null => {
        if (control?.value && control.value.startsWith("0")) {
          return { inValid: true };
        }
        return null;
      }
    }

    protected RainNumber(){
      return (control: AbstractControl): ValidationErrors | null => {
        if (control?.value && !control.value.startsWith("2769")) {
          return { 
            inValid: true, 
            custom_message: 'Msisdn must begin with "2769"'  
          };
        }
        return null;
      }
    }

    protected NotAllowedStrings(stringList: string[], customErrorMsg?: string){
      return (control: AbstractControl): ValidationErrors | null => {

        if(!control?.value){
          return null;
        }

        for (const word of stringList) {
          if(word === control.value){
            return {
              inValid: true,
              custom_message:  customErrorMsg ?? `${word} is not allowed`
            };
          }
        }

      }
    }

    public resetForm() {
        this._form?.reset();
      }

    ngOnDestroy(): void {
        this.ngDestroy$.next(null);
        this.ngDestroy$.complete();
        this._form?.reset();
    }

}
