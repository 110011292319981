import { Action, Selector, State, StateContext } from "@ngxs/store";
import { Injectable } from "@angular/core";
import { RicaAuthorityActions } from "./actions";
import { RicaAuthCompanyDetails } from "./interfaces/rica-auth-company-details.interface";
import { DigitalIdentityRelatedParty } from "src/app/customer-data-components/axiom/digital-identity/types/digital-identity-by-customer-id-response.interface";

export interface RicaAuthorityStateModel {
  ricaAuthAccepted: boolean;
  notRegisteredSelected: boolean;
  ricaAuthCompanyDetails: RicaAuthCompanyDetails;
  relatedPartyIDs: DigitalIdentityRelatedParty[]; // TODO just putting it here for now
}

const getDefaults = (): RicaAuthorityStateModel => {
  return {
    ricaAuthAccepted: false,
    notRegisteredSelected: false,
    ricaAuthCompanyDetails: null,
    relatedPartyIDs: null
  }
}
@State<RicaAuthorityStateModel>({
  name: 'RicaAuthorityState',
  defaults: getDefaults()
})
@Injectable()
export class RicaAuthorityState {

  @Selector()
  static ricaAuthAccepted(state: RicaAuthorityStateModel) { return state.ricaAuthAccepted; }

  @Selector()
  static notRegisteredSelected(state: RicaAuthorityStateModel) { return state.notRegisteredSelected }

  @Selector()
  static ricaAuthCompanyDetails(state: RicaAuthorityStateModel) { return state.ricaAuthCompanyDetails }

  @Selector()
  static relatedPartyID(state: RicaAuthorityStateModel) { return state.relatedPartyIDs }

  @Action(RicaAuthorityActions.SetNotRegisteredSelected)
  SetNotRegistered(ctx: StateContext<RicaAuthorityStateModel>, action: RicaAuthorityActions.SetNotRegisteredSelected) {

    const { notRegisteredSelected } = action

    ctx.patchState({
      notRegisteredSelected
    })

  }

  @Action(RicaAuthorityActions.SetRicaAuthorityAccept)
  SetRicaAuthorityAccept(ctx: StateContext<RicaAuthorityStateModel>, action: RicaAuthorityActions.SetRicaAuthorityAccept) {

    const { ricaAuthAccepted } = action

    ctx.patchState({
      ricaAuthAccepted
    })

  }

  @Action(RicaAuthorityActions.SetRicaAuthCompanyDetails)
  SetRicaAuthCompanyDetails(ctx: StateContext<RicaAuthorityStateModel>, action: RicaAuthorityActions.SetRicaAuthCompanyDetails) {

    const { ricaAuthCompanyDetails } = action

    ctx.patchState({
      ricaAuthCompanyDetails
    })

  }

  @Action(RicaAuthorityActions.SetRelatedPartyIDs)
  SetRelatedPartyID(ctx: StateContext<RicaAuthorityStateModel>, action: RicaAuthorityActions.SetRelatedPartyIDs) {

    const { relatedPartyIDs } = action

    ctx.patchState({
      relatedPartyIDs
    })

  }

}
