<sf-popup-modal [isOpen]="true" [hasCloseButton]="false" [autoClose]="false"
  [successResponse]="modalOptions.successResponse" [sending]="modalOptions.sending"
  [spinnerMessage]="modalOptions.spinnerMessage" [limitHeight]="true">

  <ng-container header>retention questionnaire</ng-container>

  <ng-container mainContent>

    <sf-modal-form-container [formGroup]="form">

      <sf-select label="reason for not closing ticket" [options]="notClosingReasons" marginBottom="1rem"
        (onOptionSelect)="onSelect($event, 'not_closing_reason')" placeholder="select a reason"
        [hasError]="form.get('not_closing_reason').errors?.notSelected">
      </sf-select>

      <sf-select *ngIf="showEscalationsReason; else extraSpace" label="escalation reason" [options]="escalationReasons"
        marginBottom="1rem" (onOptionSelect)="onSelect($event, 'escalation_reason')"
        placeholder="select escalation reason" [hasError]="form.get('escalation_reason').errors?.notSelected">
      </sf-select>

      <ng-template #extraSpace>
        <div class="extra-space"></div>
      </ng-template>

    </sf-modal-form-container>

  </ng-container>

  <ng-container buttons>
    <button class="rain-button default-button-size send-button solid" (click)="onSend()"
      [disabled]="!form?.valid || modalOptions.sending">
      send
    </button>
  </ng-container>


</sf-popup-modal>
