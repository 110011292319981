<div class="container" *ngIf="canChange">
  <p class="pointer" [matMenuTriggerFor]="menu">change auth role
    <mat-icon class="text-blue">arrow_drop_down</mat-icon>
  </p>
  <mat-menu #menu="matMenu">
    <span mat-menu-item *ngFor="let option of options; let i = index" class="font-normal text-blue"
      (click)="toggleRoleActive(i)"> {{option.role}}
      <input type="checkbox" [checked]="option.hasRole">
    </span>
  </mat-menu>

</div>
