import { Component } from '@angular/core';

@Component({
  selector: 'sf-customer-info-wrapper',
  templateUrl: './customer-info-wrapper.component.html',
  styleUrls: ['./customer-info-wrapper.component.scss']
})
export class CustomerInfoWrapperComponent {


}
