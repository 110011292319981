import { Utils } from "src/app/Utils";
import { RicaAuthCompanyDetails } from "../interfaces/rica-auth-company-details.interface";
import { DigitalIdentityRelatedParty } from "src/app/customer-data-components/axiom/digital-identity/types/digital-identity-by-customer-id-response.interface";

export class SetNotRegisteredSelected {
  static readonly type = Utils.Helpers.Type('[RICA AUTH] Set not registered');
  constructor(public notRegisteredSelected: boolean ) { }
}

export class SetRicaAuthorityAccept {
    static readonly type = Utils.Helpers.Type('[RICA AUTH] Set company rica auth status');
    constructor(public ricaAuthAccepted: boolean ) { }
}

export class SetRicaAuthCompanyDetails {
    static readonly type = Utils.Helpers.Type('[RICA AUTH] Set company details');
    constructor(public ricaAuthCompanyDetails: RicaAuthCompanyDetails ) { }
}
//TODO
export class SetRelatedPartyIDs {
    static readonly type = Utils.Helpers.Type('[RICA AUTH] Set related party Id');
    constructor(public relatedPartyIDs: DigitalIdentityRelatedParty[] ) { }
}
