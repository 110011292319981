import { Utils } from "src/app/Utils";
import { NewProxyCustomerServiceResponse } from "../../interfaces/new-proxy-customer-service.interface";

export class Fetch {
  static readonly type = Utils.Helpers.Type("[New Proxy Service] Fetch");
  constructor(public payload: string) { }
}

export class FetchSuccess {
  static readonly type = Utils.Helpers.Type("[New Proxy Service] Fetch success");
  constructor(public payload: NewProxyCustomerServiceResponse) { }
}

export class FetchFail {
  static readonly type = Utils.Helpers.Type("[New Proxy Service] Fetch fail");
  constructor(public error: unknown) { }
}

export class Clear {
  static readonly type = Utils.Helpers.Type("[New Proxy Service] Clear");
}
