import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { Utils } from "src/app/Utils";
import { convertWhatsappMediaUrl } from "../store/functions/convert-whatsapp-media-url";


@Injectable({
    providedIn: 'root'
})
export class WhatsappMediaService {

    constructor(private http: HttpClient) { }

    fetchMediaData(dataUrl: string, mimeType: string) {
        const url = convertWhatsappMediaUrl(dataUrl);

        return this.http.get(url, { responseType: "arraybuffer" })
            .pipe(
                map(res => {
                    const base64String = Utils.Mappers.arrayBufferToBase64(res);
                    return `data:${mimeType?.replace("_", "/")?.toLowerCase()};base64,${base64String}`
                })
            );
    }

}