import packageInfo from '../../package.json';
import { APIGEE_SIT_APIKEY, SNOWFLAKE_FE_PROXY } from './env.constants';
import { DEFAULT_SIT_FEATURE_FLAGS } from './feature-flags';


export const FEATURE_FLAGS = DEFAULT_SIT_FEATURE_FLAGS;

const REDIRECT_URL = window.location.href.includes("echo")
    ? 'https://snowflake-echo-sit.devops.rain.co.za'
    : 'https://snowflake-sit.devops.rain.co.za';

const sit_environment = {
    production: false,
    redirectUri: REDIRECT_URL,
    main_proxy: `/sitbss/v1/bss/${SNOWFLAKE_FE_PROXY}`,
    main_proxy_api_key: APIGEE_SIT_APIKEY,

    oss: "/oss-sit",

    kube_worker: "/kube_worker_sit",

    current_env: "sit",
    can_switch_env: true,
    show_sit_warning_message: true,
    agentReporting: "/zeus/bss-sit/voice-reporting",

    sf_websocket_url: "wss://api.devops.rain.co.za/sit/snowflake-java/ws",
    whatsapp_websocket_url: "wss://api.zeus.rain.co.za/bss-sit/chat-management",

    smartSubApi: `/sitbss/v1/bss/${SNOWFLAKE_FE_PROXY}/smartsub`,
    snowflake_api_url: `https://api.devops.rain.co.za/sit/snowflake-java`,

    apm_endpoint: '/apm',
    version: packageInfo.version,
    xrayApi: '/xray',

    apigeeApiKey: APIGEE_SIT_APIKEY,
    bssEndpoint: "/sitbss/v1/bss",
    bssBaseEndpoint: "/bssbase/v1", //Does not end with /bss

    bssNetwork: "/sitbssnetwork/v1",
    bssNetworkApiKey: "1rQWEBw1pUlsfHz2kISkqFBlm6nlvBKZ",

    ltmEndpoint: "/ltmsit",

    bssIDMApiKey: "1rQWEBw1pUlsfHz2kISkqFBlm6nlvBKZ",

    idm_endpoint: "https://sit-bss-api.rain.network/v1/bss-idm",
    idm_api_key: "6a5xmlS33EQA7dpOJ89lvgAPqMkmVsGn",

    sipPassword: "LetItRain@123!",

    athenaEndpoint: "athena/bss-sit",

    voice: "https://qa.voice.rain.co.za:9443",
    voice_pbx: "https://qa.voice.rain.co.za:8443",

    erica: "/e-rica-api",

    courierTracking: "/courier-tracking-api",

    internalRain: "/internal-rain",
    internalRainKey: "KN23LvG1yp42c-3bVjC3NToBtqU-o6ajnLNEKTqQc",

    da_endpoint: "/sit-da",
    da_apikey: "b9JWTTI8365rabw5nwtww74CoUnulb9X6zZZLE1y",

    customercare: "/sitbss/v1/bss/partner/smartsub/1",

    eskom_se_push: "/esp",
    sys_notifications: "/sysnotifications",
    gemini: "/gemini",
    cdn: "https://cdn.precipitation.co.za",
    whatsapp: "/zeus/bss-sit/chat-management",

    numberPortingUrl: "/zeus/bss-sit/number-porting-service/rain",
    numberPortingKey: "",
    fabric: "/fabric-sit",
    fabricApiKey: "8glRS8U2X36fnAN9dyDlA5lWfodYnIc5LXyWnhCe",
    tariff: `/sitbss/v1/bss/${SNOWFLAKE_FE_PROXY}/tariff`,

    axessApiKey: "S2mx8uXbqlAwz8CFnKm9PBGZXEuC6OhR", //TODO: sit api key
    u2020: '/mllproxy',

    firebase: "Snowflake-SIT",
    fireStoreUrl: "website-config-dev",
    fireStoreRainConfig: "rain-config-debug",
    stunServerUrl: "stun:voice.rain.co.za:3478",

    maintenanceFireStoreCollection: "snowflake-sit",
    agentNotificationWSUrl: "wss://api.zeus.rain.co.za/bss-sit/agent-notification-service",

    forceAvailable: "/zeus/bss-sit/agent-notification-service", // TODO prod

    updatePolicy: "/zeus/bss-sit/sm"

};



const prod_environment = {
    production: false,
    redirectUri: REDIRECT_URL,
    main_proxy: `/bss/v1/bss/${SNOWFLAKE_FE_PROXY}`,
    main_proxy_api_key: "nFIArGezjys886v0Vmz46Oe88kGdwTSo",

    kube_worker: "/kube_worker_prod",

    oss: "/oss-prod",

    current_env: "prod",
    can_switch_env: true,
    show_sit_warning_message: true,
    agentReporting: "/athena/bss-prod/voice-reporting",

    sf_websocket_url: "wss://snowflake-java-api.devops.rain.co.za/ws",
    whatsapp_websocket_url: "wss://api.athena.rain.co.za/bss-prod/chat-management",

    smartSubApi: `/bss/v1/bss/${SNOWFLAKE_FE_PROXY}/smartsub`,
    snowflake_api_url: `https://snowflake-java-api.devops.rain.co.za`,

    apm_endpoint: '/apm',
    version: packageInfo.version,
    xrayApi: '/xray',

    apigeeApiKey: "wC4Qr7I1uNGmKuA6deQGC9QNVqtVTnaq",
    bssEndpoint: "/bss/v1/bss",
    bssBaseEndpoint: "/bssbase/v1", //Does not end with /bss

    bssNetwork: "https://prod-bss-cf.rain.co.za/v1",
    bssNetworkApiKey: "LrQ2oFL4NNo9jgXdOey7DGjuQoyd3xpH",

    ltmEndpoint: "/ltmsit",

    bssIDMApiKey: "qidSQZHGqFTShfuCksIZJ74aDwLpWEw5",

    idm_endpoint: "https://prod-bss-cf.rain.co.za/v1/bss-idm",
    idm_api_key: "LrQ2oFL4NNo9jgXdOey7DGjuQoyd3xpH",

    sipPassword: "LetItRain@123!",

    athenaEndpoint: "athena/bss-prod",

    voice: "https://voice.rain.co.za:9443",
    voice_pbx: "https://voice.rain.co.za:8443",

    erica: "/e-rica-api",

    courierTracking: "/courier-tracking-api",

    internalRain: "/internal-rain",
    internalRainKey: "KN23LvG1yp42c-3bVjC3NToBtqU-o6ajnLNEKTqQc",

    da_endpoint: "/da",
    da_apikey: "SsSHR8hO1gaHlx4f26Wyl5fI4LbScUUiTyZo62j8",

    customercare: "/bss/v1/bss/partner/customercare",

    eskom_se_push: "/esp",
    sys_notifications: "/sysnotifications",
    gemini: "/gemini",
    cdn: "https://cdn.rain.co.za",
    whatsapp: "/athena/bss-prod/chat-management",

    numberPortingUrl: "/bssbase/v1/web-mnp-service",
    numberPortingKey: "YbZB6iUlW2DOmMOrBCRuJm9mn8uodaSa",
    fabric: "/fabric-sit", //TODO:
    fabricApiKey: "8glRS8U2X36fnAN9dyDlA5lWfodYnIc5LXyWnhCe", //TODO:
    tariff: `/sitbss/v1/bss/${SNOWFLAKE_FE_PROXY}/tariff`, //TODO:

    axessApiKey: "S2mx8uXbqlAwz8CFnKm9PBGZXEuC6OhR",
    u2020: '/mllproxy',

    firebase: "Snowflake-PROD",
    fireStoreUrl: "website-config",
    fireStoreRainConfig: "rain-config",
    stunServerUrl: "stun:voice.rain.co.za:3478",

    maintenanceFireStoreCollection: "snowflake",
    agentNotificationWSUrl: "wss://api.athena.rain.co.za/bss-prod/agent-notification-service",

    forceAvailable: "/zeus/bss-sit/agent-notification-service", // TODO PROD

    updatePolicy: "/athena/bss-prod/sm"

};


export let environment = sit_environment;

function setupInitialEnvironment() {
    const environments = {
        sit: sit_environment,
        prod: prod_environment
    }
    const savedEnv = localStorage.getItem("saved_testing_env");
    if (savedEnv) {
        environment = environments[savedEnv];
    }
}

setupInitialEnvironment();

