import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store, } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { AddSuccessResponseNotification } from 'src/app/core/store/actions/notifications.actions';
import { CoreState } from 'src/app/core/store/state/core.state';
import { DataLoading, getDataLoadingDefaultValues } from 'src/app/shared/interfaces/data-loading.interface';
import { SuccessState } from 'src/app/shared/models/success-state.model';
import { Utils } from 'src/app/Utils';
import { DataLoadingHelper } from 'src/app/Utils/helpers';
import { VoicePlatformData } from '../../services/sip-platform/assets/voice-platform-data.interface';
import { SipPlatformLoginService } from '../../services/sip-platform/sip-platform-login.service';
import { VoiceAccessToken } from '../../services/voice-agents/assets/voice-tokens.type';
import { VoiceAuthActions } from '../actions/voice-auth-actions';


type VoiceAuthStateModel = DataLoading<VoicePlatformData>
@State<VoiceAuthStateModel>({
    name: 'sf_voice_auth_state',
    defaults: {
        ...getDataLoadingDefaultValues()
    }
})
@Injectable()
export class VoiceAuthState {

    @Selector()
    static isLoading(state: VoiceAuthStateModel) { return state.loading }

    @Selector()
    static isLoaded(state: VoiceAuthStateModel) { return state.loaded }

    @Selector()
    static getData(state: VoiceAuthStateModel) { return state.data }

    @Selector()
    static getAccessToken(state: VoiceAuthStateModel) {
        return state.data?.access_token as VoiceAccessToken
    }

    @Selector()
    static getUser(state: VoiceAuthStateModel) { return state.data?.user }

    @Selector()
    static getError(state: VoiceAuthStateModel) { return state.error }

    @Selector()
    static getState(state: VoiceAuthStateModel) { return state }

    @Selector()
    static tokenSuccessState(state: VoiceAuthStateModel): SuccessState {
        return {
            loaded: state.loaded,
            success: Boolean(state.data)
        }
    }

    constructor(private loginService: SipPlatformLoginService,
        private store: Store) {
    }

    @Action(VoiceAuthActions.FetchLoginDetails)
    Fetch(ctx: StateContext<VoiceAuthStateModel>, action: VoiceAuthActions.FetchLoginDetails) {
        const { email } = action;

        ctx.patchState({ loading: true });

        return this.loginService.getLoginDetails(email)
            .pipe(tap({
                next: res => ctx.dispatch(new VoiceAuthActions.FetchLoginDetailsSuccess(res)),
                error: e => ctx.dispatch(new VoiceAuthActions.FetchLoginDetailsFail(e))
            }));
    }


    @Action(VoiceAuthActions.FetchLoginDetailsSuccess)
    FetchSuccess(ctx: StateContext<VoiceAuthStateModel>, action: VoiceAuthActions.FetchLoginDetailsSuccess) {
        const { payload } = action;
        DataLoadingHelper.setData(ctx, payload);
    }


    @Action(VoiceAuthActions.FetchLoginDetailsFail)
    FetchFail(ctx: StateContext<VoiceAuthStateModel>, action: VoiceAuthActions.FetchLoginDetailsFail) {
        const error = Utils.Helpers.findError(action.error, '');
        const message = `iMobility Authentication Failed. ${error}`;
        DataLoadingHelper.setError(ctx, message);

        return ctx.dispatch(new AddSuccessResponseNotification({
            success: false,
            message
        }));
    }

    @Action(VoiceAuthActions.Retry)
    Retry(ctx: StateContext<VoiceAuthStateModel>) {
        const email = this.store.selectSnapshot(CoreState.getAgentEmail);

        return ctx.dispatch([
            new VoiceAuthActions.Clear(),
            new VoiceAuthActions.FetchLoginDetails(email)
        ]);
    }


    @Action(VoiceAuthActions.Clear)
    Clear(ctx: StateContext<VoiceAuthStateModel>) {
        ctx.setState(getDataLoadingDefaultValues());
    }

}   