<sf-popup-modal [isOpen]="true" [hasCloseButton]="false" [autoClose]="false"
  [successResponse]="modalOptions.successResponse" [sending]="modalOptions.sending"
  [spinnerMessage]="modalOptions.spinnerMessage">

  <ng-container header>post sales form</ng-container>

  <ng-container mainContent>
    <sf-modal-form-container [formGroup]="form">

      <ng-container *ngIf="ticketHasSaleAlready; else noSale">
        <p class="hasSuccessfulSale">
          already has a successful sale.
        </p>
      </ng-container>

      <ng-template #noSale>

        <sf-select
          label="Select Sales Channel"
          [options]="salesChannelOptions"
          marginBottom="1rem"
          (onOptionSelect)="onOptionSelect('sales_channel', $event)"
          placeholder="select sales channel"
          [hasError]="form.get('sales_channel').errors?.notSelected"
        >
        </sf-select>

        <sf-select label="Select Status" [options]="statusOptions" marginBottom="1rem"
          (onOptionSelect)="onOptionSelect('status', $event)" placeholder="select status"
          [hasError]="form.get('status').errors?.notSelected">
        </sf-select>

        <sf-select *ngIf="saleSuccessful === false && (showHomeReasons$ | async)" label="Reason" [options]="homeReasonOptions" marginBottom="1rem"
        [control]="form.get('reasonIfUnsuccessful')" placeholder="select reason"
        (onOptionSelect)="onOptionSelect('reasonIfUnsuccessful', $event)"
        [hasError]="form.get('reasonIfUnsuccessful').errors?.notSelected">
        </sf-select>

        <sf-select *ngIf="saleSuccessful === false && (showMobileReasons$ | async)" label="Reason" [options]="mobileReasonOptions" marginBottom="1rem"
        [control]="form.get('reasonIfUnsuccessful')" placeholder="select reason"
        (onOptionSelect)="onOptionSelect('reasonIfUnsuccessful', $event)"
        [hasError]="form.get('reasonIfUnsuccessful').errors?.notSelected">
        </sf-select>

        <sf-select *ngIf="saleSuccessful === false && (showWorkReasons$ | async)" label="Reason" [options]="workReasonOptions" marginBottom="1rem"
        [control]="form.get('reasonIfUnsuccessful')" placeholder="select reason"
        (onOptionSelect)="onOptionSelect('reasonIfUnsuccessful', $event)"
        [hasError]="form.get('reasonIfUnsuccessful').errors?.notSelected">
        </sf-select>

      <sf-form-input
        *ngIf="saleSuccessful"
        label="order number"
        type="number"
        [control]="form.get('orderNumber')"
          placeholder="select order number"></sf-form-input>

        <sf-select
          label="Where did the customer hear about rain"
          [options]="hearAboutOptions"
          marginBottom="1rem"
          (onOptionSelect)="onOptionSelect('hearAboutRain', $event)"
          placeholder="select area"
          [hasError]="form.get('hearAboutRain').errors?.notSelected"
        >
        </sf-select>

      </ng-template>

    </sf-modal-form-container>
  </ng-container>

  <ng-container buttons>
    <sf-rain-button *ngIf="!ticketHasSaleAlready; else cantSend" [isSolid]="true"
      [disabled]="form?.invalid || modalOptions?.sending" (click)="onSubmit()">
      send
    </sf-rain-button>

    <ng-template #cantSend>
      <sf-rain-button [isSolid]="true" [disabled]="modalOptions?.sending" (click)="move()">
        continue
      </sf-rain-button>
    </ng-template>
  </ng-container>

</sf-popup-modal>
