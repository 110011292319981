import { CustomerTicket } from "src/app/shared/customer-ticket/interfaces/customer-ticket.interface";
import { Utils } from "src/app/Utils";


export class RefreshTicket {
    static readonly type = Utils.Helpers.Type('[RefreshTicket: Fetch] Refresh ticket');
    constructor(public hexId: string) { }
}

export class RefreshTicketSuccess {
    static readonly type = Utils.Helpers.Type('[RefreshTicket: Fetch] Refresh ticket success');
    constructor(public payload: { data: CustomerTicket }) { }
}

export class RefreshTicketFail {
    static readonly type = Utils.Helpers.Type('[RefreshTicket: Fetch] Refresh ticket fail');
    constructor(public error?: any) { }
}

