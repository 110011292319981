import { HttpParams, HttpEvent, HttpRequest, HttpClient, HttpContext, HttpHeaders, HttpProgressEvent } from "@angular/common/http";
import { Observable } from "rxjs";

interface Options {
  headers?: HttpHeaders;
  context?: HttpContext;
  reportProgress?: boolean;
  params?: HttpParams;
  responseType?: 'arraybuffer' | 'blob' | 'json' | 'text';
  withCredentials?: boolean;
}


const DEFAULT_OPTIONS = {
  reportProgress: true,
  observe: 'events'
}
export class FileLoader<T> {

  constructor(private httpClient: HttpClient) {
  }

  downloadFile(url: string, httpOptions: Options = {}) {
    const req = new HttpRequest('GET', url, { ...DEFAULT_OPTIONS, ...httpOptions });
    return this.httpClient.request(req);
  }

  uploadFile(url: string, formData: FormData, httpOptions: Options = {}): Observable<HttpEvent<T>> {
    const req = new HttpRequest('POST', url, formData, { ...DEFAULT_OPTIONS, ...httpOptions });
    return this.httpClient.request(req);
  }

  static getPercentageDone(progressEvent: HttpProgressEvent) {
    return Math.round(100 * progressEvent.loaded / progressEvent.total);
  }

  static getCamundaFormData(file: File, url?: string, description?: string): FormData {
    const formData = new FormData();
    formData.append("attachment-name", file?.name);
    if (description) formData.append("attachment-description", description);
    if (url) formData.append("url", url);
    formData.append("attachment-type", file.type);
    formData.append("content", file);

    return formData;
  }

  static convertBase64toFile(base64string: string, filename: string): File {
    const arr = base64string.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

}
