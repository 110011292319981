import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AzureProfile } from './assets/azure-profile.interface';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { CustomResolver } from '../shared/models/custom-resolver/custom-resolver.model';
import { DEFAULT_PROFILE_IMAGE } from '../constants';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  profile: AzureProfile;
  private _profileImageResolver = new CustomResolver<string | ArrayBuffer>();

  sidenavOpenChanged = new Subject<boolean>();
  private sidenavOpen = false;

  constructor(private http: HttpClient,
    private router: Router) {
    this.initializeProfilePhoto();
  }

  async getprofilePhoto(){
    return this._profileImageResolver.getData();
  }

  async initializeProfilePhoto(): Promise<void> {
    const GRAPH_ENDPOINT = 'https://graph.microsoft.com/v1.0/me/photo/$value';
    const headers = new HttpHeaders({
      'Content-Type': 'image/jpg',
    });
    try {
      const photeData = await this.http.get(GRAPH_ENDPOINT, {
        headers: headers,
        responseType: 'blob' as 'json',
      }).toPromise();
      this.createImageFromBlob(photeData as Blob);
    } catch (error) {
      this._profileImageResolver.setData(DEFAULT_PROFILE_IMAGE);
    }
  }

  createImageFromBlob(blob: Blob) {
    const reader = new FileReader();
    reader.addEventListener(
      'load',
      () => {
        this._profileImageResolver.setData(reader.result);
      },
      false
    );
    if (blob) {
      reader.readAsDataURL(blob);
    }
  }

  private updateSidenavChange() {
    this.sidenavOpenChanged.next(this.sidenavOpen);
  }

  getSidenavOpen() {
    return this.sidenavOpen;
  }

  toggleSidenav() {
    this.sidenavOpen = !this.sidenavOpen;
    this.updateSidenavChange();
  }

  closeSidenav() {
    this.sidenavOpen = false;
    this.updateSidenavChange();
  }
  goHome() {
    this.router.navigate(["/home"]);
  }

}
