import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable, Subject } from 'rxjs';
import { CoreState } from 'src/app/core/store/state/core.state';
import { FetchCustomerDetails } from 'src/app/customer-info-summary-page/store/actions/customer.actions';
import { CustomerInfoState } from 'src/app/customer-info-summary-page/store/state/customer-info.state';
import { environment } from 'src/environments/environment';
import { ModalWindowService } from '../../modal-window/modal-window.service';
import { ApiControllerService } from '../../services/api-service/api-controller.service';
import { ApiResponse } from '../../services/api-service/api-response.interface';
import { CustomerTicket } from '../interfaces/customer-ticket.interface';
import { NewTicketNote } from '../interfaces/new-ticket-note.interface';
import { CamundaService } from './camunda.service';
import { RainAgent } from '../../components/agent/rain-agent.service';
import { AddNotePayload } from './assets/add-note-payload.interface';
import { DataHandler } from '../../data-handler/data-handler';
import { SF_TICKET_PREFIX, SIM_DETAILS_ROUTE } from 'src/app/constants';
import { Router } from '@angular/router';
import { NewTicketOptions } from 'src/app/interactions/new-ticket-modal/assets/new-ticket-options.model';
import { InteractionTicketsState } from 'src/app/interactions/store/state/interaction-tickets.state';
import { InteractionTicketActions } from 'src/app/interactions/store/actions/interaction-ticket-actions';
import { EscalationPayload } from 'src/app/interactions/store/interfaces/escalation-payload.interface';
import { TicketTrackingActions } from '../../components/ticket-tracking/store/actions/ticket-tracking-actions';
import { ExtendedServiceSelectors } from 'src/app/customer-info-summary-page/store/selectors/extended-service.selector';
import { SimSelectors } from 'src/app/customer-data-components/sim-details/store/selectors/sim.selectors';

@Injectable({
  providedIn: 'root'
})
export class TicketEventHandlerService {

  eventAddedSubject = new Subject<string>();

  constructor(private apiService: ApiControllerService,
    private router: Router,
    private rainAgent: RainAgent,
    private store: Store,
    private modal: ModalWindowService,
    private camundaService: CamundaService) { }

  /**
  *
  * @param action The action that was being performed eg. 'add event'
  * @param showError If the error modal should pop up. By default this is set to true.
  * @returns Returns the currently assigned hex_id if it is not null, else creates a new ticket and return that hex_id.
  */
  async getAssignedOrNewHexId(action?: string, showError = true, email?: string) {
    const hex_id = this.store.selectSnapshot(InteractionTicketsState.getCurrentHexId);
    if (hex_id) return hex_id;

    return this.createDefaultCustomerTicket(action, showError, email);
  }

  /**
   *  Creates a new ticket with customer info
   * @returns hex_id of the newly created ticket.
   */
  private async createDefaultCustomerTicket(action?: string, showError = true, email?: string): Promise<string> {
    const customerEmail = this.store.selectSnapshot(CustomerInfoState.getCustomerEmail) ?? email;
    const failMessage = action ? `Failed to ${action}, could not create new ticket.` : "could not create new ticket";

    if (!customerEmail) {
      this.modal.showError(failMessage);
      return;
    }

    const response = await this.createNewTicket(this.getDefaultTicketName(), `by ${customerEmail}`, customerEmail);
    const hex_id = response?.data?.data?.hex_id;
    if (!hex_id) {
      if (showError) this.modal.showError(failMessage, response?.errorMessage?.error);
      return;
    }
    return hex_id;
  }

  //TODO: rewrite this also
  async createNewTicket(ticketName: string, ticketDescription: string, email: string, showModal = false): Promise<ApiResponse<{ data: CustomerTicket }>> {

    const agentDetails = await this.rainAgent.getAgentDetails()
    const agentGroup = agentDetails?.getTeamRole()

    const payload = {
      name: ticketName,
      description: ticketDescription,
      state_id: 0,
      related_entity: {
        email
      },
      agent_group: agentGroup?.toUpperCase()?.includes("GENERAL") ? "CEC.TEAM.A" : agentGroup,
      agent_email: agentDetails?.email
    }

    const requestPath = `${environment.snowflake_api_url}/ticket`;
    const response = await this.apiService.post<{ data: CustomerTicket }>(requestPath, {}, payload);

    let successfull = false;
    if (response?.status === 200 || response?.status === 201) {
      successfull = true;

      const ticket = response.data?.data;
      this.store.dispatch([
        new InteractionTicketActions.UpdateTicket(ticket),
        new TicketTrackingActions.NewTicketCreated(ticket)
      ]);
    }
    if (showModal) {
      if (successfull) this.modal.showInfo("successfully added ticket.");
      else this.modal.showError("failed to add ticket", response?.errorMessage?.error);
    }

    return response;
  }

  createNewTicketV2(payload): Observable<{ data: CustomerTicket }> {
    const requestPath = `${environment.snowflake_api_url}/ticket`;
    return this.apiService.postV2(requestPath, {}, payload);
  }

  broadcastEventAdded(hexID: string) {
    this.eventAddedSubject.next(hexID);
  }

  /**
   * Add an event to a known customer's ticket. The default method addNewEvent() should be used for unknown customers.
   * @param eventComment the event comment that will be added to the note
   * @param reload if the customer data should be reloaded
   * @param specificHexId a hex_id can be specified if the current hex_id should not be used
   * @returns void
   */
  async addEventForKnownCustomer(eventComment: string, reload: boolean, specificHexId: string = null, email?: string): Promise<void> {
    const customerEmail = this.store.selectSnapshot(CustomerInfoState.getCustomerEmail);

    const hex_id = specificHexId ?? await this.getAssignedOrNewHexId("add event", true, email);
    if (!hex_id) return;

    await this.addNewEvent(hex_id, eventComment);

    if (reload) {
      this.store.dispatch(new FetchCustomerDetails({ value: customerEmail, config: { loader: false } }));
    }
    this.broadcastEventAdded(hex_id);
  }


  async addNewEvent(hexID: string, eventComment: string, call_id?: string) {
    if (!hexID) {
      return;
    }

    const payload: NewTicketNote = {
      ticket_id: BigInt(hexID)?.toString(),
      related_entity: {
        email: (await this.rainAgent.getAgentDetails()).email
      },
      comment: eventComment,
      private: true,
      channel_id: 1,
      call_id
    }
    return this.camundaService.sendPayload(payload, "add_event", hexID);
  }

  async addNote(payload: AddNotePayload, action: "add_note" | "close" = "add_note") {
    const addNotePayload: NewTicketNote = {
      ticket_id: BigInt(payload.hex_id).toString(),
      related_entity: {
        email: (await this.rainAgent.getAgentDetails()).email
      },
      comment: payload.comment,
      private: payload.isPrivate,
      channel_id: DataHandler.isDefined(payload?.channel_id) ? payload?.channel_id : 0
    }

    if (DataHandler.isDefined(payload?.state_id)) {
      addNotePayload.state_id = payload.state_id
    }

    return this.camundaService.sendPayload(addNotePayload, action, payload.hex_id);
  }

  addAttachmentToNote(ticketHexId: string, comment: string, isPrivate: boolean) {
    const agent = this.store.selectSnapshot(CoreState.getAgent);
    const payload: NewTicketNote = {
      ticket_id: BigInt(ticketHexId).toString(),
      related_entity: {
        email: agent.email
      },
      comment,
      private: isPrivate,
      channel_id: 0
    }
    return this.camundaService.sendPayload(payload, "add_note", ticketHexId);
  }

  async escalate(hex_id: string, agent_group: string, comment: string, state_id: number) {
    const payload: EscalationPayload = {
      ticket_id: BigInt(hex_id).toString(),
      hex_id,
      agent_group,
      state_id,
      comment,
      related_entity: {
        email: (await this.rainAgent.getAgentDetails()).email
      },
    }

    return this.camundaService.sendPayload(payload, "escalate", hex_id);
  }


  async createUnknownCustomerTicket(ticketName: string): Promise<CustomerTicket> {
    const agentEmail = (await this.rainAgent.getAgentDetails()).email;
    const response = await this.createNewTicket(ticketName, 'unknown customer', agentEmail);
    return response?.data?.data;
  }


  private getDefaultTicketName() {
    let baseName = `${SF_TICKET_PREFIX} general support`;
    const url = this.router.url;
    const parts = url.split("/");

    if (parts.includes(SIM_DETAILS_ROUTE)) {
      const id = parts[parts.findIndex(item => item === SIM_DETAILS_ROUTE) + 1];
      const msisdn = this.store.selectSnapshot(SimSelectors.getMSISDNforServiceID(id));
      baseName += ` ${NewTicketOptions.getMsisdnPostfix(msisdn)}`;
    }
    return baseName;
  }

}
