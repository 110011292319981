import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { AddSuccessResponseNotification } from 'src/app/core/store/actions/notifications.actions';
import { Utils } from 'src/app/Utils';
import { SalesService } from '../../../sales-portal/services/sales.service';
import { GeneratePaymentLink, GeneratePaymentLinkFail, GeneratePaymentLinkSuccess, GeneratePaymentTopupLink, CancelPaymentLinksSuccess, CancelPaymentLinksFail, CancelPaymentLinksForOrder, GeneratePaymentTopupLinkSuccess } from '../../action/payments.actions';
import { AddKnownCustomerEvent } from 'src/app/shared/customer-ticket/store/actions/ticket-event.actions';
import { PaymentLinkPayload } from '../../types/payment-link.interface';
import { AgentActions } from 'src/app/shared/components/agent/store/actions/agent-action-actions';



interface PaymentLinkStateModel {
    loading: boolean;
}
@State<PaymentLinkStateModel>({
    name: 'sf_payment_link_state',
    defaults: {
        loading: false
    }
})
@Injectable()
export class PaymentLinkState {

    @Selector()
    static isLoading(state: PaymentLinkStateModel) { return state.loading }

    constructor(private salesService: SalesService) {
    }

    @Action(GeneratePaymentLink)
    GeneratePaymentLink(ctx: StateContext<PaymentLinkStateModel>, action: GeneratePaymentLink) {
        const { payload, extraActions } = action;
        const { orderNumber, linkDetails } = payload;

        const paymentLinkPayload: PaymentLinkPayload = {
            orderName: `order - ${orderNumber}`,
            ...linkDetails
        }

        ctx.patchState({ loading: true });

        return this.salesService.generatePaymentLink(paymentLinkPayload)
            .pipe(tap({
                next: () => ctx.dispatch(new GeneratePaymentLinkSuccess(orderNumber, extraActions?.successActions)),
                error: (e: unknown) => ctx.dispatch(new GeneratePaymentLinkFail(e, extraActions?.failActions))
            }));
    }


    @Action(GeneratePaymentLinkSuccess)
    GeneratePaymentLinkSuccess(ctx: StateContext<PaymentLinkStateModel>, action: GeneratePaymentLinkSuccess) {
        ctx.patchState({ loading: false });

        const { orderNumber, successActions } = action;

        return ctx.dispatch([
            new AddSuccessResponseNotification({
                success: true,
                message: `Successfully created payment link`
            }),
            new AgentActions.SentPaymentLink(orderNumber),
            ...successActions
        ]);
    }


    @Action(GeneratePaymentTopupLink)
    GeneratePaymentTopupLink(ctx: StateContext<PaymentLinkStateModel>, action: GeneratePaymentTopupLink) {
        const { payload, extraActions } = action;

        ctx.patchState({ loading: true });

        return this.salesService.generatePaymentLink(payload)
            .pipe(tap({
                next: () => ctx.dispatch(new GeneratePaymentTopupLinkSuccess(extraActions?.successActions)),
                error: (e: unknown) => ctx.dispatch(new GeneratePaymentLinkFail(e, extraActions?.failActions))
            }));
    }


    @Action(GeneratePaymentTopupLinkSuccess)
    GeneratePaymentTopupLinkSuccess(ctx: StateContext<PaymentLinkStateModel>, action: GeneratePaymentTopupLinkSuccess) {
        ctx.patchState({ loading: false });

        const { successActions } = action;

        return ctx.dispatch([
            new AddSuccessResponseNotification({
                success: true,
                message: `Successfully created payment link`
            }),
            ...successActions
        ]);
    }


    @Action(GeneratePaymentLinkFail)
    GeneratePaymentLinkFail(ctx: StateContext<PaymentLinkStateModel>, action: GeneratePaymentLinkFail) {
        ctx.patchState({ loading: false });

        const { error, failActions } = action;

        const errorMessage = Utils.Helpers.findError(error, '');
        return ctx.dispatch([
            new AddSuccessResponseNotification({
                success: false,
                message: `Failed to generate payment link. ${errorMessage}`
            }),
            ...failActions
        ]);

    }


    @Action(CancelPaymentLinksForOrder)
    CancelPaymentLinkForOrder(ctx: StateContext<PaymentLinkStateModel>, action: CancelPaymentLinksForOrder) {
        const { paymentLinkPayload } = action;

        ctx.patchState({ loading: true });

        return this.salesService.cancelPaymentLinksForExistingOrder(paymentLinkPayload.orderNumber)
            .pipe(tap({
                next: () => ctx.dispatch(new CancelPaymentLinksSuccess(paymentLinkPayload)),
                error: () => ctx.dispatch(new CancelPaymentLinksFail())
            }));
    }


    @Action(CancelPaymentLinksSuccess)
    cancelPaymentLinksSuccess(ctx: StateContext<PaymentLinkStateModel>, action: CancelPaymentLinksSuccess) {

        ctx.patchState({ loading: false });

        const { paymentLinkPayload } = action;

        return ctx.dispatch([
            new AddKnownCustomerEvent({
                eventComment: `Cancelled all existing payment links for order: ${paymentLinkPayload.orderNumber}`,
                reload: true
            }),
            new GeneratePaymentLink(paymentLinkPayload)
        ]);
    }


    @Action(CancelPaymentLinksFail)
    cancelPaymentLinksFail(ctx: StateContext<PaymentLinkStateModel>) {

        ctx.patchState({ loading: true });

        return ctx.dispatch(new AddSuccessResponseNotification({
            success: false,
            message: `Failed to cancel payment links.`
        }));

    }

}
