import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Attachment } from 'src/app/shared/customer-ticket/interfaces/customer-ticket.interface';
import { AttachmentService } from 'src/app/shared/services/attachment.service';

@Component({
  selector: 'chat-attachment',
  templateUrl: './chat-attachment.component.html',
  styleUrls: ['./chat-attachment.component.scss']
})
export class ChatAttachmentComponent implements OnInit {

  @ViewChild("attachmentRef") attachmentRef: ElementRef;

  @Input() attachment: Attachment;

  loading = true;
  fullUrl: string;
  error: string;

  constructor(private attachmentService: AttachmentService) { }

  ngOnInit(): void {
    this.getUrl();
  }

  async getUrl() {
    const response = await this.attachmentService.getFullAttachment(this.attachment.url);
    if (response?.status === 200) {
      this.fullUrl = response.data?.data?.url;
    } else {
      this.error = response.errorMessage.error;
    }
    this.loading = false;
  }



  onOpenAttachment() {
    this.attachmentRef.nativeElement.click();
  }


}
