import { Component, OnDestroy, OnInit } from '@angular/core';
import { Actions, ofActionSuccessful, Select } from '@ngxs/store';
import { Observable, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { SetInitialAzureAuthResult } from 'src/app/core/store/actions/azure-auth.actions';
import { SfWarningState } from '../store/state/sf-warnings.state';
import { WarningBroadcasterService } from './assets/warning-broadcaster.service';

@Component({
  selector: 'sf-multiple-tabs-warning-modal',
  templateUrl: './multiple-tabs-warning-modal.component.html',
  styleUrls: ['./multiple-tabs-warning-modal.component.scss']
})
export class MultipleTabsWarningModalComponent implements OnInit, OnDestroy {
  @Select(SfWarningState.getWarningType("multiple_tabs_open")) isOpen$: Observable<boolean>;

  private readonly ngDestroy$ = new Subject<void>();

  constructor(private actions$: Actions,
    private warningBroadcaster: WarningBroadcasterService) { }

  ngOnInit(): void {

    this.actions$
      .pipe(
        ofActionSuccessful(SetInitialAzureAuthResult),
        take(1),
        takeUntil(this.ngDestroy$)
      )
      .subscribe({
        next: () => this.warningBroadcaster.initializeBroadcaster()
      });
  }

  ngOnDestroy(): void {
    this.ngDestroy$.next();
    this.ngDestroy$.complete();
  }


}
