import { State, Selector, Action, StateContext } from '@ngxs/store';
import { Injectable, } from '@angular/core';
import { navActions } from '../actions/nav-actions';


export interface NavigationStateModel {
    current_url: string;
    previous_url: string;
    was_back_button_pressed: boolean;
    route_listener_initialized: boolean;
    sentFromAgentView: boolean;
    breadcrumb_routes: string[];
}

@State<NavigationStateModel>({
    name: 'navigation',
    defaults: {
        current_url: '/',
        previous_url: '/',
        was_back_button_pressed: false,
        route_listener_initialized: false,
        sentFromAgentView: false,
        breadcrumb_routes: ["/home"]
    }
})
@Injectable()
export class NavigationState {

    @Selector()
    static getPreviousUrl(state: NavigationStateModel) { return state.previous_url }

    @Selector()
    static getCurrentUrl(state: NavigationStateModel) { return state.current_url }

    @Selector()
    static getWasBackButtonPressed(state: NavigationStateModel) { return state.was_back_button_pressed }

    @Selector()
    static getSentFromAgentViewStatus(state: NavigationStateModel) { return state.sentFromAgentView }


    @Action(navActions.SetIsFromAgentView)
    setIsAgentViewStatus(ctx: StateContext<NavigationStateModel>, action: navActions.SetIsFromAgentView) {
        ctx.patchState({
            sentFromAgentView: action.bool
        })
    }


    @Action(navActions.UpdateURL)
    updateURL(ctx: StateContext<NavigationStateModel>, action: navActions.UpdateURL) {
        const state = ctx.getState();
        const current_url = state.current_url;
        const url: string = action.payload;

        this.setBreadcrumbs(ctx, url);

        ctx.patchState({
            previous_url: current_url,
            current_url: url,
        });
    }

    private setBreadcrumbs(ctx: StateContext<NavigationStateModel>, route: string) {
        const currentRoutes = ctx.getState().breadcrumb_routes;
        const index = currentRoutes.indexOf(route);
        const was_back_button_pressed = this.didNavigateBack(currentRoutes, index);

        let newRoutes = Object.assign([], currentRoutes);

        if (route === "/home") {
            newRoutes = ["/home"];
        }
        else if (index < 0) {
            newRoutes.push(route);
        }
        else {
            newRoutes.length = (index + 1);
        }

        ctx.patchState({
            breadcrumb_routes: newRoutes,
            was_back_button_pressed
        })
    }

    private didNavigateBack(currentRoutes: string[], navigationIndex: number) {
        if (navigationIndex === -1) {
            //an index of -1 means the route was not found
            return false;
        }

        const reverseIndex = currentRoutes.length - navigationIndex;
        return reverseIndex === 2;
    }

}
