import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

export type FirebaseDocument = 'nvidia' | 'featureFlags'

@Injectable({
  providedIn: 'root'
})
export class FirebaseService {

  constructor(private firestore: AngularFirestore) { }

  fetchConfig<T>(document: FirebaseDocument) {
    return this.firestore
      .doc<T>(`${environment.firebase}/${document}`)
      .snapshotChanges()
      .pipe(
        map(actions => actions.payload.data())
      );
  }

}
