<div [style.width]="boxSize">
  <div class="circle-wrapper">
    <div class="main-circle" [style.width]="size" [style.height]="size" [ngClass]="status" [matTooltip]="errorMessage">

      <small-text-spinner *ngIf="status ==='loading'"></small-text-spinner>

      <div class="center-container" [ngClass]="status">
        {{step}}
      </div>

    </div>
  </div>

  <div class="message font-normal text-darkest-grey" [ngClass]="status">
    {{stepMessage}}
  </div>
</div>
