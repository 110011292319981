import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { AgentWebSocketTopic, TopicRequestPayload } from '../types/agent-websocket-topic.type';
import { filter, take, takeUntil } from 'rxjs/operators';
import { WebSocketNotification } from '../types/websocket-notification.interface';
import { WebSocketStatus } from '../types/agent-websocket-status.type';
import { Store } from '@ngxs/store';
import { AgentNotificationWSActions } from '../actions/agent-notification-ws-actions';
import { AgentNotificationWSState } from '../state/agent-notification-ws.state';
import { environment } from 'src/environments/environment';
import { ReConnectableWebSocket } from '../../../../shared/websocket/reconnectable-websocket';
import { ISuccessResult } from 'src/app/shared/models/success-result.model';

const SCALING_DURATION = 3000;
const MAX_ATTEMPTS = 20;
const PING_MESSAGE = "ping" as const;
const PING_INTERVAL = 5_000;

const DEFAULT_TOPICS: AgentWebSocketTopic[] = ["alert"];

type Ping = typeof PING_MESSAGE;

@Injectable({
    providedIn: 'root'
})
export class AgentNotificationWebSocketService implements OnDestroy {

    private _reConnectableWebSocket: ReConnectableWebSocket<WebSocketNotification | TopicRequestPayload | Ping>;

    private _initialized = false;

    private _notification$ = new Subject<WebSocketNotification<unknown>>();

    private readonly _destroy$ = new Subject<void>();

    constructor(private store: Store) { }

    connect(email: string) {
        if (this._initialized) {
            return;
        }

        this._initialized = true;

        this.updateStatus("connecting");

        this._reConnectableWebSocket = new ReConnectableWebSocket(
            {
                url: `${environment.agentNotificationWSUrl}/notifications?agent_email=${email}`,

                serializer: value => {
                    return value === "ping" ? "ping" : JSON.stringify(value);
                },
                openObserver: {
                    next: () => this.updateStatus("connected")
                },
                closingObserver: {
                    next: () => this.updateStatus("closing")
                },
                closeObserver: {
                    next: () => this.updateStatus("closed")
                },
            },
            {
                maxRetryAttempts: MAX_ATTEMPTS,
                scalingDuration: SCALING_DURATION
            }
        );

        this.listenToWebSocketMessages();

        this._reConnectableWebSocket.connect();
    }

    reconnect(email: string) {
        //Clear any existing connection
        this.closeConnection();

        if (!this._reConnectableWebSocket) {
            this.connect(email);
        }
        else {
            this.updateStatus("connecting");
            this._reConnectableWebSocket.reconnect();
        }
    }

    //TODO: make other stuff use this with return also
    subscribeToTopic<T>(topic: AgentWebSocketTopic, destroyer$: Subject<unknown>) {

        //This destroyer is required to make sure the server also knows to unsubscribe
        destroyer$
            .pipe(
                take(1)
            )
            .subscribe({
                complete: () => this.sendMessage({ topic, action: "unsubscribe" })
            });

        setTimeout(() => this.sendMessage({ topic, action: "subscribe" }));

        return this.getNotificationsForTopic<T>(topic);
    }

    getNotificationsForTopic<T>(topic: AgentWebSocketTopic) {
        return this._notification$
            .pipe(
                filter(notification => notification?.topic === topic)
            ) as Observable<WebSocketNotification<T>>;
    }

    private listenToWebSocketMessages() {

        this._reConnectableWebSocket
            .listen()
            .pipe(
                takeUntil(this._destroy$.pipe(take(1)))
            )
            .subscribe({
                next: (successResult: ISuccessResult<WebSocketNotification>) => {
                    const { data, error } = successResult ?? {};

                    if (data) {
                        this.onMessage(data);
                    }
                    else {
                        this.store.dispatch(new AgentNotificationWSActions.SetErrorMessage(error));
                    }
                }
            });
    }

    private onMessage(notification: WebSocketNotification) {
        const { topic, message } = notification ?? {};

        if (!topic) {
            return;
        }

        if (topic === "error") {
            this.store.dispatch(new AgentNotificationWSActions.SetErrorMessage(message));
            return;
        }

        if (topic === "connected") {
            this.onReady();
        }

        this._notification$.next(notification);
    }

    private onReady() {
        this.updateStatus("ready");

        this.store.dispatch(new AgentNotificationWSActions.SetErrorMessage(null));

        this.subscribeToDefaultTopics();

        this._reConnectableWebSocket.keepAlive(PING_INTERVAL, PING_MESSAGE);
    }

    private subscribeToDefaultTopics() {
        DEFAULT_TOPICS.forEach(topic => this.sendMessage({ topic, action: "subscribe" }));
    }

    private sendMessage(message: Ping | TopicRequestPayload) {
        const isReady = this.store.selectSnapshot(AgentNotificationWSState.hasAnyStatus(["ready"]));

        if (isReady) {
            this._reConnectableWebSocket?.send(message);
        }
        else {
            this.store.select(AgentNotificationWSState.hasAnyStatus(["ready"]))
                .pipe(
                    filter(Boolean),
                    take(1)
                )
                .subscribe({
                    next: () => this._reConnectableWebSocket?.send(message)
                });
        }
    }

    private updateStatus(status: WebSocketStatus) {
        this.store.dispatch(new AgentNotificationWSActions.UpdateStatus(status));
    }

    private closeConnection() {
        this._destroy$.next(null);

        const isClosedOrClosing = this.store.selectSnapshot(AgentNotificationWSState.hasAnyStatus(["closed", "closing"]));
        if (!isClosedOrClosing) {
            this.updateStatus("closed");
        }
    }

    ngOnDestroy(): void {
        this.closeConnection();
        this._destroy$.complete();

        this._reConnectableWebSocket.close();
    }

}