import { Component, Input } from '@angular/core';
import { Store } from '@ngxs/store';
import { TicketNote } from 'src/app/shared/customer-ticket/interfaces/customer-ticket.interface';
import { ChatFunctions } from '../../assets/chat-functions';
import moment from 'moment';
import { Navigate } from '@ngxs/router-plugin';
import { ADMIN_ROUTE, CALL_RECORDINGS_ROUTE } from 'src/app/constants';
import { CallRecordingQueryParameters } from 'src/app/features/pages/admin/pages/call-recordings/assets/call-recording-query-parameters.interface';

@Component({
  selector: 'chat-event',
  templateUrl: './chat-event.component.html',
  styleUrls: ['./chat-event.component.scss']
})
export class ChatEventComponent {

  @Input() note: TicketNote;

  constructor(private store: Store) { }

  gotoSpecifiedRecording() {
    const { comment, inserted_at } = this.note ?? {};

    const {callId , fileName} = ChatFunctions.extractCallIdOrFileNameFromText(comment) ?? {};

    if (!callId && !fileName) {
      return;
    }

    //Add a buffer amount of time

    this.store.dispatch(new Navigate(
      [ADMIN_ROUTE, CALL_RECORDINGS_ROUTE],
      <CallRecordingQueryParameters>{
        from: moment(inserted_at).subtract(5, "minute").valueOf(),
        to: moment(inserted_at).add(5, "minutes").valueOf(),
        callId,
        fileName
      },
      { queryParamsHandling: 'merge' }
    ));
  }
}
