import { Selector } from "@ngxs/store";
import { ProductCatalogState } from "../state/product-state/product-catalog.state";
import { GEFORCE_TIERS_IN_PRIORITY_ORDER, NvidiaAddon } from "../interfaces/product-catalog.interfaces";
import { NvidiaProduct } from "../interfaces/nvidia-product.interface";
import { Dictionary } from "src/app/shared/interfaces/dictionary.interface";
import { Utils } from "src/app/Utils";
import { RainoneSelectors } from "src/app/customer-info-summary-page/store/selectors/rainone.selectors";
import { AllOrderSelectors } from "src/app/customer-info-summary-page/store/selectors/all-order.selectors";
import { ExtendedServiceSelectors, MappedServicesByOrderId } from "src/app/customer-info-summary-page/store/selectors/extended-service.selector";
import { CustomerOrder } from "src/app/customer-data-components/customer-order/customer-order-card/assets/customer-order.interface";
import { SFValidators } from "src/app/shared/functions/sf-validators";
import { CUSTOMER_SERVICE_STATUS } from "src/app/shared/constants/service-status.constant";
import { CustomerService } from "src/app/shared/interfaces/smartsub-data.interface";

export class NvidiaProductSelectors {

    @Selector([
        ProductCatalogState.getProductsByType("addon"),
        RainoneSelectors.rainoneNotSelectedAndNotRainone,
        RainoneSelectors.has5gRainoneOrderOrService
    ])
    static getNvidiaProducts(
      addonProducts: NvidiaAddon[],
      rainoneNotSelectedAndNotRainone: boolean,
      has5gRainoneOrderOrService: boolean
      ): NvidiaProduct[] {
        if (!addonProducts?.length) {
            return [];
        }

        const hasRainone = has5gRainoneOrderOrService || !rainoneNotSelectedAndNotRainone
        const filteredAddons: NvidiaAddon[] = [];

        for (const addon of addonProducts) {
            const { config } = addon ?? {};
            const { tier, subtype } = config ?? {};

            if (subtype === "nvidia" && GEFORCE_TIERS_IN_PRIORITY_ORDER.includes(tier)) {
                filteredAddons.push(addon);
            }
        }

        return filteredAddons.map(addon => {
          const { recurringPrice, config } = addon ?? {}
          const { discountedPrice } = config ?? {}

          return {
            ...addon,
            recurringPrice: hasRainone ? discountedPrice : recurringPrice
          }
        });
    }

    @Selector([
        NvidiaProductSelectors.getNvidiaProducts
    ])
    static getMappedNvidiaProducts(products: NvidiaProduct[]): Dictionary<NvidiaProduct> {
        return Utils.Mappers.toHashMapV2(products, "id");
    }

    @Selector([
      AllOrderSelectors.getOrders,
      ExtendedServiceSelectors.getMappedServicesByOrderId,
      ProductCatalogState.getProductsByTypeMappedById('addon')
    ])
    static allActiveNvidiaOrders(
      orders: CustomerOrder[],
      mappedServices: MappedServicesByOrderId,
      simProductDict: Dictionary<NvidiaProduct>
    ) {

      if (!orders?.length) {
        return []
      }

      return orders?.filter((order) => {

        const { id: orderId, status: orderStatus } = order ?? {}
        const matchingService = mappedServices?.[orderId];
        const { status } = matchingService ?? {};
        const isCancelledService = SFValidators.isDefined(status) && status === CUSTOMER_SERVICE_STATUS["Service Canceled"];
        const isCancelledOrRedeliveredOrder = orderStatus === "Order Cancelled" || orderStatus === "Redelivered"

        if (isCancelledService || isCancelledOrRedeliveredOrder) {
          return false
        }

        for (const orderLine of order?.order_lines) {

          const product = simProductDict?.[orderLine?.product_id];
          const { subtype } = product?.config ?? {}
          const nvidia = subtype === 'nvidia'
          const isNvidia = nvidia && SFValidators.isDefined(product)

          if( isNvidia ){
            return true
          }

        }

        return false;
      }) ?? []
    }

    @Selector([
      NvidiaProductSelectors.allActiveNvidiaOrders,
      ProductCatalogState.getProductsByTypeMappedById('addon')
    ])
    static hasNvidiaOrder(
      allActiveNvidiaOrders: CustomerOrder[],
      simProductDict: Dictionary<NvidiaProduct>
    ) {

      return allActiveNvidiaOrders?.some(order =>
        order?.order_lines?.some(orderLine => {

        const { product_id } = orderLine ?? {}
        const { config } = simProductDict?.[product_id] ?? {};
        const { subtype } = config ?? {}

        return subtype === 'nvidia'

        })
      );
    }

    @Selector([
      ExtendedServiceSelectors.getServices,
      ProductCatalogState.getProductsByTypeMappedById("addon")
    ])
    static hasNvidiaService(
      services: CustomerService[],
      simProductDict: Dictionary<NvidiaProduct>
    ) {

      return services?.some(service => {

        const { product_id, status } = service ?? {}
        const { config } = simProductDict?.[product_id] ?? {};
        const { subtype } = config ?? {};

        const isCancelledService = SFValidators.isDefined(status) && status === CUSTOMER_SERVICE_STATUS["Service Canceled"];
        const isNvidia = subtype === 'nvidia'

        return isNvidia && !isCancelledService

      });

    }

    @Selector([
      NvidiaProductSelectors.hasNvidiaOrder,
      NvidiaProductSelectors.hasNvidiaService
    ])
    static hasNvidiaOrderOrService(
      hasNvidiaOrder: boolean,
      hasNvidiaService: boolean,
    ){
      return hasNvidiaOrder || hasNvidiaService
    }

}
