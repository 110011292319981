import { Pipe, PipeTransform } from '@angular/core';
import { SFValidators } from '../../functions/sf-validators';

const MINS_IN_DAY = 1440;
const MINS_IN_HOUR = 60;

//TODO: sure there is a better way to do this
@Pipe({ name: 'formatMinDuration' })
export class FormatMinDurationPipe implements PipeTransform {

  transform(mins: number, skipMinsIfHasDays = false): string {
    if (SFValidators.isNotDefined(mins)) {
      return;
    }

    if (mins === 0) {
      return `0 mins`;
    }

    const days = Math.floor(mins / MINS_IN_DAY);
    mins = mins - (MINS_IN_DAY * days);
    const hours = Math.floor(mins / MINS_IN_HOUR);
    mins = mins - hours * MINS_IN_HOUR;

    let str = "";
    if (days) {
      str += `${days} ${days === 1 ? "day" : "days"}`;
    }
    if (hours) {
      str += `${days ? "," : ""} ${hours} ${hours === 1 ? "hour" : "hours"}`;
    }
    if (mins && !skipMinsIfHasDays) {
      str += `${hours ? "," : ""} ${mins} ${mins === 1 ? "min" : "mins"}`;
    }

    return str;
  }

}
