import { Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { CUSTOMER_INFO_SUMMARY_ROUTE } from '../constants';
import { InteractionTicketActions } from '../interactions/store/actions/interaction-ticket-actions';
import { SipPhoneActions } from '../sip-phone/store/actions/sip-phone-actions';
import { SearchPageSelectors } from './view-model/search-page.selectors';
import { SearchPageViewModel } from './view-model/search-page.view-model';


@Component({
    selector: 'app-search-page',
    templateUrl: './search-page.component.html',
    styleUrls: ['./search-page.component.scss'],
})
export class SearchPageComponent implements OnDestroy {

    @Select(SearchPageSelectors.getViewModel) viewModel$!: Observable<SearchPageViewModel>;

    constructor(
        private store: Store,
        private router: Router,
    ) { }

    ngOnDestroy(): void {
        this.store.dispatch([
            new SipPhoneActions.SetUnknownIncomingCall(false),
            new InteractionTicketActions.Clear({ keepAssignedHexId: false })
        ]);

        const navigatingToCustomerInfo = this.router?.routerState?.snapshot?.url?.indexOf(CUSTOMER_INFO_SUMMARY_ROUTE) > -1;
        if (!navigatingToCustomerInfo) {
            this.store.dispatch(new SipPhoneActions.ClearIncomingCallDetails());
        }
    }

}
