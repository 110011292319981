import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { ResetCustomerDetails } from "src/app/customer-info-summary-page/store/actions/customer.actions";
import { ClearCoverageData, InitializedCurrentDevice, SetCustomerAddressLatLng } from "../actions/coverage-data.actions";
import { LatLngLiteral } from "@google/maps";


const getDefaults = (): CoverageDataStateModel => {
    return {
        customerAddressLatLng: null,
        initializedCurrentDevice: false
    }
}

export interface CoverageDataStateModel {
    customerAddressLatLng: LatLngLiteral;
    initializedCurrentDevice: boolean;
}

@State<CoverageDataStateModel>({
    name: 'coverage_data',
    defaults: getDefaults()
})
@Injectable()
export class CoverageDataState {

    @Selector()
    static customerAddressLatLng(state: CoverageDataStateModel) { return state.customerAddressLatLng }

    @Selector()
    static hasCustomerAddress(state: CoverageDataStateModel) { return Boolean(state.customerAddressLatLng) }

    @Selector()
    static isDeviceInitialized(state: CoverageDataStateModel) { return state.initializedCurrentDevice }

    @Action(SetCustomerAddressLatLng)
    setCurrentLngLat(ctx: StateContext<CoverageDataStateModel>, action: SetCustomerAddressLatLng) {
        ctx.patchState({
            customerAddressLatLng: action.latLng
        });
    }

    @Action(InitializedCurrentDevice)
    InitializedCurrentDevice(ctx: StateContext<CoverageDataStateModel>) {
        ctx.patchState({
            initializedCurrentDevice: true
        });
    }

    @Action([
        ResetCustomerDetails,
        ClearCoverageData
    ])
    ClearCoverageData(ctx: StateContext<CoverageDataStateModel>) {
        ctx.setState(getDefaults());
    }

}

