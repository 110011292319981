import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { CallbackService } from '../../services/callback.service';
import { UpdateCallbackExtrasActions } from '../actions/update-callback-actions';


interface UpdateCallbackStateModel {
    loading: boolean;
}
@State<UpdateCallbackStateModel>({
    name: 'sf_UpdateCallback_state',
    defaults: {
        loading: false
    }
})
@Injectable()
export class UpdateCallbackState {

    @Selector()
    static isLoading(state: UpdateCallbackStateModel) { return state.loading }

    constructor(private callbackService: CallbackService) {
    }

    @Action(UpdateCallbackExtrasActions.UpdateExtras)
    Update(ctx: StateContext<UpdateCallbackStateModel>, action: UpdateCallbackExtrasActions.UpdateExtras) {
        const { callbackId, payload } = action;

        ctx.patchState({ loading: true });

        return this.callbackService.updateExtras(callbackId, { extras: payload })
            .pipe(tap({
                next: res => ctx.dispatch(new UpdateCallbackExtrasActions.UpdateSuccess(res?.result)),
                error: (e: unknown) => ctx.dispatch(new UpdateCallbackExtrasActions.UpdateFail(e))
            }));
    }

    @Action(UpdateCallbackExtrasActions.UpdateStatusAndExtras)
    UpdateStatusAndExtras(ctx: StateContext<UpdateCallbackStateModel>, action: UpdateCallbackExtrasActions.UpdateStatusAndExtras) {
        const { callbackId, payload } = action;

        ctx.patchState({ loading: true });

        return this.callbackService.updateStatus(callbackId, payload)
            .pipe(tap({
                next: res => ctx.dispatch(new UpdateCallbackExtrasActions.UpdateSuccess(res?.result)),
                error: (e: unknown) => ctx.dispatch(new UpdateCallbackExtrasActions.UpdateFail(e))
            }));
    }


    @Action(UpdateCallbackExtrasActions.UpdateSuccess)
    UpdateSuccess(ctx: StateContext<UpdateCallbackStateModel>) {
        ctx.patchState({ loading: false });
    }


    @Action(UpdateCallbackExtrasActions.UpdateFail)
    UpdateFail(ctx: StateContext<UpdateCallbackStateModel>) {
        ctx.patchState({ loading: false });
    }

}   