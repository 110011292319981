import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { NormalSiteFeatureCollection } from 'src/app/shared/services/google-maps/assets/map-site.interfaces';
import { Store } from '@ngxs/store';
import { PersistentStorageState } from 'src/app/core/store/state/persistent-storage/persistent-storage.state';


@Injectable({
  providedIn: 'root'
})
export class SiteLoaderService {

  constructor(
    private store: Store,
    private http: HttpClient) {
  }

  fetchNormalMapSites() {
    const url = `${environment.smartSubApi}/data/get_all_sites`;
    return this.http.get<{ data: NormalSiteFeatureCollection }>(url);
  }

  shouldRefreshNormalSites(cacheTime: number) {
    const lastUpdatedTime = this.store.selectSnapshot(PersistentStorageState.get("last-updated-normal-map-sites"));
    const appVersion = this.store.selectSnapshot(PersistentStorageState.get("app-version"));

    return (
      !lastUpdatedTime
      || appVersion !== environment.version
      || (diffInHours(lastUpdatedTime) > cacheTime)
    );
  }
}


const diffInHours = (lastUpdatedTime: number) => (Date.now() - lastUpdatedTime ?? 0) / (1000 * 60 * 60);