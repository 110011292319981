
export interface FeatureFlagsActive {
    voice_reporting: boolean;
    troubleshooter: boolean;
}

export const DEFAULT_SIT_FEATURE_FLAGS: FeatureFlagsActive = {
    voice_reporting: false,
    troubleshooter: true
}

export const DEFAULT_PROD_FEATURE_FLAGS: FeatureFlagsActive = {
    voice_reporting: false,
    troubleshooter: false
}