import { Injectable } from "@angular/core";
import { Store } from "@ngxs/store";
import { CALL_ID_STR, CALL_RECORDING_FILE_NAME_STR, CALL_RECORDING_ID_STR, SF_TICKET_PREFIX } from "src/app/constants";
import { CustomerInfoState } from "src/app/customer-info-summary-page/store/state/customer-info.state";
import { InteractionTicketsState } from "src/app/interactions/store/state/interaction-tickets.state";
import { SearchService } from "src/app/search-page/search.service";
import { AddTicketEvent } from "src/app/shared/customer-ticket/store/actions/ticket-event.actions";
import { TicketEventHandlerService } from "src/app/shared/customer-ticket/ticket-event-handler/ticket-event-handler.service";
import { CustomResolveHandler } from "src/app/shared/models/custom-resolver/custom-resolve-handler.model";
import { Utils } from "src/app/Utils";
import { CallLogType } from "../assets/sip-call.type";
import { SipPhoneActions } from "../store/actions/sip-phone-actions";
import { BasicCallDetails } from "../store/interfaces/call-details.interface";
import { LogCallPayload } from "../store/interfaces/log-call-payload.interface";
import { SipPhoneState } from "../store/state/sip.state";
import { VoiceCampaignState } from "../store/state/voice-campaign.state";


@Injectable({
  providedIn: 'root'
})
export class CallLoggingService {

  private _resolveHandler: CustomResolveHandler = null;
  private _creatingTicket = false;

  constructor(private store: Store,
    private searchService: SearchService,
    private ticketEventService: TicketEventHandlerService) {
  }

  logCall(callType: CallLogType, payload: LogCallPayload) {
    const { hexId, callDetails } = payload;

    const eventComment = this.getCallMessage(callType, callDetails);
    this.store.dispatch(new AddTicketEvent({ hexId, eventComment, call_id: callDetails?.callId }));
  }

  //TODO: fix this
  private getCallMessage(callType: CallLogType, callDetails: BasicCallDetails) {
    const { callId, phoneNumber, recordingFileName } = callDetails;
    const msg = this.isCustomersNumber(phoneNumber) ? "the customer" : phoneNumber;
    const isCampaign = this.store.selectSnapshot(VoiceCampaignState.inCampaignCall);

    if (isCampaign) {
      return `Campaign call to ${msg}. ${CALL_RECORDING_FILE_NAME_STR}: ${recordingFileName}`;
    }
    if (callType === "attempt") {
      return `Agent attempting to call ${msg}. ${CALL_ID_STR}: ${callId}`;
    }
    if (callType === "outgoing") {
      return `Agent called ${msg}. ${CALL_ID_STR}: ${callId}`;
    }
    if (callType === "incomingRecordingId") {
      return `Incoming ${CALL_RECORDING_FILE_NAME_STR}: ${recordingFileName}`;
    }
    if (callType === "outgoingRecordingId") {
      return `Outgoing ${CALL_RECORDING_ID_STR}: ${callId}`;
    }
    return `Incoming call from ${msg}. ${CALL_ID_STR}: ${callId}`;
  }

  private isCustomersNumber(inputNumber: string) {
    const formattedInputNumber = Utils.Formatters.formatPhoneNumber(inputNumber);
    const customerNumber = this.store.selectSnapshot(CustomerInfoState.getCustomer)?.phone;
    const formattedCustomerNumber = Utils.Formatters.formatPhoneNumber(customerNumber);
    return (formattedCustomerNumber === formattedInputNumber);
  }


  async getHexIdForOutgoingCall() {
    const hexId = this.store.selectSnapshot(InteractionTicketsState.getCurrentHexId);
    if (hexId) {
      return hexId;
    }

    if (this._creatingTicket) {
      //Used to make sure that only one ticket ID is created at a time.
      await this._resolveHandler?.confirmationResult();
    }

    const callTicketHexId = this.store.selectSnapshot(SipPhoneState.getCallTicketHexId);
    if (callTicketHexId) {
      return callTicketHexId;
    }

    const customerEmail = this.searchService.checkForCachedCustomerEmail();
    const outgoingTicket = await this.wrapTicketCreation(customerEmail);
    return outgoingTicket?.hex_id;
  }

  private async wrapTicketCreation(customerEmail: string | null) {
    this._resolveHandler = new CustomResolveHandler();
    this._creatingTicket = true;

    const ticket = await this.createOutgoingCallTicket(customerEmail);
    this.store.dispatch(new SipPhoneActions.SetCallTicketHexId(ticket?.hex_id));

    this._resolveHandler.resolve(true);
    this._creatingTicket = false;

    return ticket;
  }

  private async createOutgoingCallTicket(customerEmail: string) {
    const newTicketName = `${SF_TICKET_PREFIX} support outgoing call`;
    if (!customerEmail) {
      return this.ticketEventService.createUnknownCustomerTicket(newTicketName);
    }

    const description = "auto created ticket";
    const response = await this.ticketEventService.createNewTicket(newTicketName, description, customerEmail);
    return response?.data?.data;
  }

}
