
<div
  class="popup-modal-overlay"
  [class.is-open]="isOpen"
  [style.z-index]="zIndex"
  [class.scrollable]="overlayScroll"
>
  <div
    class="modal-lockup"
    [class.limitHeight]="limitHeight"
    [class.scrollable]="limitHeight"
    [style.max-width]="maxWidth"
    [style.min-width]="minWidth"
    [style.width]="width"
  >

    <header>
      <div class="top-bar">
        <mat-icon
          *ngIf="hasCloseButton"
          class="default-cancel-icon"
          (click)="onClose()"
        >cancel</mat-icon>
      </div>
      <h1 class="default-card-header">
        <ng-content select="[header]"></ng-content>
      </h1>
    </header>

    <div>
      <ng-content select="[mainContent]"></ng-content>
    </div>

    <div
      *ngIf="successResponse"
      class="response-message"
    >
      <div>
        <p
          class="text"
          [style.color]="getColorCode(successResponse?.success)"
        >
          {{successResponse?.message}}
        </p>
        <mat-icon
          class="pointer"
          (click)="closeResponse()"
        >close</mat-icon>
      </div>
    </div>

    <div
      *ngIf="sending"
      class="small-spinner-wrapper"
    >
      <small-text-spinner>{{spinnerMessage}}</small-text-spinner>
    </div>

    <div class="send-button-wrapper">
      <ng-content select="[buttons]"></ng-content>
    </div>

  </div>
</div>