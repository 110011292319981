import { createSelector } from "@ngxs/store";
import { CatalogSimProduct } from "src/app/core/store/interfaces/product-catalog.interfaces";
import { SimProductSelector } from "src/app/customer-data-components/sim-details/store/selectors/sim-product.selectors";
import { FilteredServiceSelectors } from "src/app/customer-info-summary-page/store/selectors/filtered-service.selectors";
import { CustomerService } from "src/app/shared/interfaces/smartsub-data.interface";
import { TechType } from "src/app/shared/services/google-maps/assets/techtype.type";

export class SimTechServiceSelectors {

    static getServicesByTechType(techType: TechType) {
        return createSelector([
            SimProductSelector.getCombinedProducts,
            FilteredServiceSelectors.getAgeFilteredServices
        ],
            (products: CatalogSimProduct[], selectedServices: CustomerService[]) => {
                return selectedServices?.filter((service) => {
                    return products?.some(product => product?.id === service?.product_id && product?.category === techType)
                });
            });
    }

    static sfOptionsFromServicesByTechType(techType: TechType) {
        return createSelector(
            [SimTechServiceSelectors.getServicesByTechType(techType)],
            (services: CustomerService[]) => {
                return services?.map((service: CustomerService) => {
                    return ({
                        label: `${service?.name} - ${service?.service_reference}`, value: service?.service_reference
                    })
                })
            })
    }
}
