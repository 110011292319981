import { Injectable } from '@angular/core';
import { AuthenticationResult } from '@azure/msal-browser';
import { State, Action, StateContext, Selector } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/auth.service';
import { MILISECONDS_IN_MINUTE } from 'src/app/constants';
import { AzureProfile } from 'src/app/profile/assets/azure-profile.interface';
import { FetchAllAzureUsers, FetchAllAzureUsersFail, FetchAllAzureUsersSuccess, RefreshAzureToken, FetchAzureTokenFail, FetchAzureTokenSuccess, SetAllAzureUsersErrorMessage, SetInitialAzureAuthResult } from '../actions/azure-auth.actions';
import { AddSuccessResponseNotification } from '../actions/notifications.actions';
import { ToggleModalByName } from '../actions/modal.actions';

const DEFAULT_FETCH_ALL_USERS_ERROR = "Failed to load azure users.";
const _50_MINS = MILISECONDS_IN_MINUTE * 50;
const _10_MINS = MILISECONDS_IN_MINUTE * 10;


interface AzureAuthStateModel {
  authResult: AuthenticationResult;
  all_rain_azure_users: AzureProfile[];
  loading_all_users: boolean;
  loaded_all_users: boolean;
  loaded_all_users_error: string;
  loading: boolean;
  loaded: boolean;
}
@State<AzureAuthStateModel>({
  name: 'azure_auth_state',
  defaults: {
    authResult: null,
    all_rain_azure_users: [],
    loading_all_users: false,
    loaded_all_users: false,
    loaded_all_users_error: null,
    loaded: false,
    loading: false
  }
})
@Injectable()
export class AzureAuthState {


  @Selector()
  static isLoading(state: AzureAuthStateModel) { return state.loading; }

  @Selector()
  static isLoaded(state: AzureAuthStateModel) { return state.loaded; }

  @Selector()
  static getIdToken(state: AzureAuthStateModel) { return state.authResult?.idToken; }

  @Selector()
  static getAccessToken(state: AzureAuthStateModel) { return state.authResult?.accessToken; }

  @Selector()
  static getAllRainUsers(state: AzureAuthStateModel) { return state.all_rain_azure_users; }

  @Selector()
  static isAllRainUsersLoading(state: AzureAuthStateModel) { return state.loading_all_users; }

  @Selector()
  static isAllRainUsersLoaded(state: AzureAuthStateModel) { return state.loaded_all_users; }

  @Selector()
  static isAllRainUsersError(state: AzureAuthStateModel) { return state.loaded_all_users_error; }


  constructor(private authService: AuthService) {
  }


  @Action(SetInitialAzureAuthResult)
  setAzureAuthResult(ctx: StateContext<AzureAuthStateModel>, action: SetInitialAzureAuthResult) {
    const { payload } = action;
    const { idTokenClaims, account, scopes } = payload ?? {};
    const expiresOn = (idTokenClaims?.["exp"] ?? 0) * 1000;

    //Subtract 10 mins to add a bit of a buffer.
    if ((expiresOn - _10_MINS) > Date.now()) {

      //Only set the auth payload if it is not expired.
      //Then it can be used immediately before a new one is fetched.
      ctx.patchState({
        authResult: action.payload,
        loaded: true,
      });
    }

    //Make sure to get a fresh token when the app starts, MSAL uses cached tokens by default.
    return ctx.dispatch(new RefreshAzureToken(account, scopes));
  }


  @Action(RefreshAzureToken)
  refreshToken(ctx: StateContext<AzureAuthStateModel>, action: RefreshAzureToken) {
    const { account, scopes } = action;

    ctx.patchState({
      loading: true
    })

    return this.authService.getRefreshedToken(account, scopes)
      .pipe(
        tap({
          next: res => ctx.dispatch(new FetchAzureTokenSuccess(res)),
          error: (e: unknown) => ctx.dispatch(new FetchAzureTokenFail(e))
        })
      );
  }

  @Action(FetchAzureTokenSuccess)
  fetchTokenSuccess(ctx: StateContext<AzureAuthStateModel>, action: FetchAzureTokenSuccess) {
    const { payload } = action;
    const { account, scopes } = payload ?? {};
    ctx.patchState({
      loaded: true,
      loading: false,
      authResult: payload
    });

    // Refresh token every 55 mins
    setTimeout(() => { ctx.dispatch(new RefreshAzureToken(account, scopes)) }, _50_MINS);
  }


  @Action(FetchAzureTokenFail)
  fetchTokenFail(ctx: StateContext<AzureAuthStateModel>, action: FetchAzureTokenFail) {
    console.error(action.error);

    ctx.patchState({
      loaded: true,
      loading: false
    })

    ctx.dispatch(new ToggleModalByName('token_expired_modal'))
    //Add a delay so it shows up as the last message
    setTimeout(() => {
      const message = `Failed to refresh Snowflake access token. Please sign out and back in.`;
      ctx.dispatch(new AddSuccessResponseNotification({
        success: false,
        message
      }));
    }, 4000);
  }


  @Action(FetchAllAzureUsers)
  fetchAllAzureUsers(ctx: StateContext<AzureAuthStateModel>, action: FetchAllAzureUsers) {
    ctx.patchState({ loading_all_users: true });

    const { accessToken } = action;
    return this.authService.getAllAzureUsers(accessToken)
      .pipe(tap({
        next: (res) => {
          const body = res?.body;

          if (res.status !== 200) {
            ctx.dispatch(new SetAllAzureUsersErrorMessage(DEFAULT_FETCH_ALL_USERS_ERROR));
          }
          else {
            ctx.dispatch(new FetchAllAzureUsersSuccess(body?.value));
          }
        },
        error: (e: unknown) => ctx.dispatch(new FetchAllAzureUsersFail(e))
      }));
  }

  @Action(FetchAllAzureUsersSuccess)
  fetchAllAzureUsersSuccess(ctx: StateContext<AzureAuthStateModel>, action: FetchAllAzureUsersSuccess) {
    ctx.patchState({ all_rain_azure_users: action.payload });
    this.setFinishedLoadingUsers(ctx);
  }


  @Action(FetchAllAzureUsersFail)
  FetchAllAzureUsersFail(ctx: StateContext<AzureAuthStateModel>, action: FetchAllAzureUsersFail) {
    const error = action.error;
    console.error(error);
    const errorMessage = error?.error ?? DEFAULT_FETCH_ALL_USERS_ERROR;

    ctx.dispatch(new SetAllAzureUsersErrorMessage(errorMessage));
  }

  @Action(SetAllAzureUsersErrorMessage)
  setAllAzureUsersErrorMessage(ctx: StateContext<AzureAuthStateModel>, action: SetAllAzureUsersErrorMessage) {
    ctx.patchState({ loaded_all_users_error: action.errorMessage });
    this.setFinishedLoadingUsers(ctx);
  }



  private setFinishedLoadingUsers(ctx: StateContext<AzureAuthStateModel>) {
    ctx.patchState({
      loading_all_users: false,
      loaded_all_users: true
    });
  }
}
