/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSelector } from "@ngxs/store";
import { SharedSelectorOptions } from "@ngxs/store/src/internal/internals";

interface CreationMetadata {
    containerClass: any;
    selectorName: string;
    getSelectorOptions?: () => SharedSelectorOptions;
}

export interface OptionalSelectorOptions {
    rawFunction: boolean;
}

/**
 * Create a selector function that has the option te be used as a raw un-memoized function.
 * 
 * This is useful to avoid double memoization of the same arguments.
 */
export const createOptionalSelector = <T extends (...args: any[]) => any>(
    selectors: any[] | undefined,
    originalFn: T,
    { rawFunction }: OptionalSelectorOptions = { rawFunction: false },
    creationMetadata?: CreationMetadata
): T => {

    return rawFunction
        ? originalFn
        : createSelector(selectors, originalFn, creationMetadata);
}