

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AddTicketMetaDataPayload } from '../store/types/add-ticket-metadata-payload.interface';
import { DEFAULT_HTTP_OPTIONS } from 'src/app/constants';

@Injectable({
    providedIn: 'root'
})
export class TicketTrackingService {


    constructor(private http: HttpClient) { }


    addTicketMetaData(payload: AddTicketMetaDataPayload) {
        const url = `${environment.main_proxy}/ticket-metadata-service/ticket-metadata`;

        return this.http.post(url, payload, DEFAULT_HTTP_OPTIONS);
    }
    
}