import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { GeminiFeatureCollection } from "../store/interfaces/gemini-response.interface";

@Injectable({
  providedIn: 'root'
})
export class SiteDataService {

  constructor(private http: HttpClient) {

  }

  search(searchTerm: string | number) {
    const url = `${environment.main_proxy}/siteadmin/data/searchbysearchterm/${searchTerm}`;

    const headers = {
      "Content-Type": "application/json"
    }

    return this.http.get<GeminiFeatureCollection>(url, { headers });
  }

}
