
import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store, } from '@ngxs/store';
import { tap, takeUntil, take } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { DataLoading, getDataLoadingDefaultValues } from 'src/app/shared/interfaces/data-loading.interface';
import { Utils } from 'src/app/Utils';
import { DataLoadingHelper } from 'src/app/Utils/helpers';
import { CallbackService } from '../../services/callback.service';
import { AgentCallbackActions } from '../actions/agent-callback-actions';
import { CoreState } from 'src/app/core/store/state/core.state';
import { PopupError } from 'src/app/core/handlers/popup-error';
import { CallbackNotification } from '../types/callback-response.interface';
import { AddSuccessResponseNotification } from 'src/app/core/store/actions/notifications.actions';
import { Draft, produce } from 'immer';
import { AgentNotificationActions } from 'src/app/toolbar/agent-notifications/store/actions/agent-notification-actions';
import { AddCallbackActions } from '../actions/add-callback-actions';
import { AgentCallbackFunctions } from '../functions';
import moment from 'moment';
import { CallbackStatus } from '../types/callback-status.type';
import { FetchCallbackRequest } from '../actions/agent-callback-actions/agent-callback-actions';
import { UpdateCallbackExtrasActions } from '../actions/update-callback-actions';


const DEFAULT_PAGE_SIZE = 40;

const getDefaults = (): AgentCallbackStateModel => {
    return {
        ...getDataLoadingDefaultValues([]),
        page: 1,
        totalPages: 1,
        selectedStatuses: AgentCallbackFunctions.getAllStatuses(),
        silentlyLoading: false
    }
}

export interface AgentCallbackStateModel extends DataLoading<CallbackNotification[]> {
    page: number;
    totalPages: number;
    selectedStatuses: CallbackStatus[];
    silentlyLoading: boolean;
}

@State<AgentCallbackStateModel>({
    name: 'sf_AgentCallback_state',
    defaults: getDefaults()
})
@Injectable()
export class AgentCallbackState {

    @Selector([AgentCallbackState])
    static isLoaded(state: AgentCallbackStateModel) { return state.loaded; }

    @Selector([AgentCallbackState])
    static getData(state: AgentCallbackStateModel) { return state.data ?? []; }

    @Selector([AgentCallbackState])
    static getSelectedStatuses(state: AgentCallbackStateModel) { return state.selectedStatuses; }

    @Selector([AgentCallbackState.getData, AgentCallbackState.getSelectedStatuses])
    static getFilteredCallbacks(callbacks: CallbackNotification[], selectedStatuses: CallbackStatus[]) {
        return callbacks.filter(callback => selectedStatuses.includes(callback?.status));
    }

    @Selector([AgentCallbackState.getData])
    static getCheckableCallbacks(callbacks: CallbackNotification[]) {
        return callbacks.filter(callback => AgentCallbackFunctions.checkableCallback(callback));
    }

    @Selector([AgentCallbackState.getCheckableCallbacks])
    static hasCheckableCallbacks(callbacks: CallbackNotification[]) {
        return callbacks?.length > 0;
    }

    private readonly _cancelRequest$ = new Subject<null>();

    constructor(private callbackService: CallbackService,
        private store: Store) {
    }

    private getSearchPayload(page: number, pageSize = DEFAULT_PAGE_SIZE): FetchCallbackRequest {
        const email = this.store.selectSnapshot(CoreState.getAgentEmail);
        if (!email) {
            throw new PopupError("Failed to fetch callbacks for agent. No email found");
        }

        //Get all callbacks for the current day
        const startOfDayInSeconds = moment().startOf("day").valueOf() / 1000;
        const endOfDayInSeconds = moment().endOf("day").valueOf() / 1000;

        return {
            page,
            page_size: pageSize,
            criteria: {
                user_refs: [email],
                scheduled_date_from: startOfDayInSeconds,
                scheduled_date_to: endOfDayInSeconds
            }
        }
    }

    @Action(AgentCallbackActions.FetchInCtx)
    FetchInCtx(ctx: StateContext<AgentCallbackStateModel>) {
        const { page } = ctx.getState();
        const payload = this.getSearchPayload(page);

        return ctx.dispatch(new AgentCallbackActions.Fetch("normal-loader", payload));
    }


    @Action([
        AgentCallbackActions.Refresh,
        AddCallbackActions.AddCallbackSuccess,
        UpdateCallbackExtrasActions.UpdateSuccess
    ])
    Refresh(ctx: StateContext<AgentCallbackStateModel>) {
        const { page } = ctx.getState();
        const payload = this.getSearchPayload(page);

        return ctx.dispatch(new AgentCallbackActions.Fetch("silent-loader", payload));
    }


    @Action(AgentCallbackActions.Fetch)
    Fetch(ctx: StateContext<AgentCallbackStateModel>, action: AgentCallbackActions.Fetch) {
        const { loader, payload } = action;

        if (loader === "silent-loader") {
            ctx.patchState({ silentlyLoading: true });
        }
        else {
            ctx.patchState({ loading: true });
        }

        return this.callbackService.searchCallbacks(payload)
            .pipe(
                tap({
                    next: res => ctx.dispatch(new AgentCallbackActions.FetchSuccess(res?.result)),
                    error: (e: unknown) => ctx.dispatch(new AgentCallbackActions.FetchFail(e))
                }),
                takeUntil(this._cancelRequest$.pipe(take(1))),
            );
    }


    @Action(AgentCallbackActions.FetchNextPage)
    FetchNextPage(ctx: StateContext<AgentCallbackStateModel>) {
        const { page } = ctx.getState();
        const payload = this.getSearchPayload(page + 1);

        ctx.patchState({ silentlyLoading: true });

        return this.callbackService.searchCallbacks(payload)
            .pipe(
                tap({
                    next: res => ctx.dispatch(new AgentCallbackActions.FetchSuccess(res?.result)),
                    error: (e: unknown) => ctx.dispatch(new AgentCallbackActions.FetchNextPageFail(e))
                }),
                takeUntil(this._cancelRequest$.pipe(take(1))),
            );
    }


    @Action(AgentCallbackActions.FetchSuccess)
    FetchSuccess(ctx: StateContext<AgentCallbackStateModel>, action: AgentCallbackActions.FetchSuccess) {
        const currentCallbacks = ctx.getState().data;
        const { page, total_pages, results: newCallbacks } = action?.payload;

        const formattedNotifications = AgentCallbackFunctions.toCallbackNotifications(newCallbacks, currentCallbacks);

        ctx.patchState({
            page,
            totalPages: total_pages,
            silentlyLoading: false,
            ...DataLoadingHelper.dataLoaded(formattedNotifications),
        });
    }


    @Action(AgentCallbackActions.FetchFail)
    FetchFail(ctx: StateContext<AgentCallbackStateModel>, action: AgentCallbackActions.FetchFail) {
        const error = Utils.Helpers.findError(action.error, '');
        DataLoadingHelper.setError(ctx, error);
    }


    @Action(AgentCallbackActions.FetchNextPageFail)
    FetchNextPageFail(ctx: StateContext<AgentCallbackStateModel>, action: AgentCallbackActions.FetchNextPageFail) {
        const error = Utils.Helpers.findError(action.error, '');

        return ctx.dispatch(new AddSuccessResponseNotification({
            success: false,
            message: `Failed to fetch next page. ${error}`
        }));
    }

    @Action(AgentCallbackActions.CheckNotificationTime)
    CheckNotificationTime(ctx: StateContext<AgentCallbackStateModel>) {

        const notificationActions: AgentNotificationActions.AddNotification[] = [];

        //Calculate outside of loop so it is only calculated once
        const marginOfErrorInSecs = 10;
        const marginInThePast = moment().subtract(marginOfErrorInSecs, "seconds");
        const marginInTheFuture = moment().add(marginOfErrorInSecs, "seconds");

        ctx.setState(produce(draft => {
            draft.data.forEach(callback => {

                const notificationAction = this.getNotificationAction(callback, marginInThePast, marginInTheFuture);

                if (notificationAction) {
                    notificationActions.push(notificationAction);
                }

            });
        }));

        if (notificationActions.length > 0) {
            return ctx.dispatch(notificationActions);
        }
    }


    private getNotificationAction(callbackDraft: Draft<CallbackNotification>, momentBefore: moment.Moment, momentAfter: moment.Moment): AgentNotificationActions.AddNotification {

        const checkable = AgentCallbackFunctions.checkableCallback(callbackDraft);
        if (!checkable) {
            return;
        }

        const { scheduled_date_time: time, notificationDetails } = callbackDraft ?? {};

        const isTimeForNotification = (scheduledTime: string, adjustment: number) => {
            return moment(scheduledTime)
                .add(adjustment)
                .isBetween(momentBefore, momentAfter, undefined, "[]");
        }

        for (const notificationDetail of notificationDetails ?? []) {

            const shouldShow = !notificationDetail?.checked && isTimeForNotification(time, notificationDetail?.timeDelta);

            if (shouldShow) {
                notificationDetail.checked = true;
                return AgentCallbackFunctions.createNotificationAction(callbackDraft, notificationDetail);
            }
        }
    }

    @Action(AgentCallbackActions.Clear)
    Clear(ctx: StateContext<AgentCallbackStateModel>) {
        this._cancelRequest$.next(null);
        ctx.setState(getDefaults());
    }

}   