import { Utils } from 'src/app/Utils';
import { KnownCustomerEventPayload, EventPayload } from '../interfaces/add-ticket-event-request-payload.interface';
import { AddNotePayload } from '../../ticket-event-handler/assets/add-note-payload.interface';


export class AddKnownCustomerEvent {
  static readonly type = Utils.Helpers.Type('[Ticket: Event] Add event to ticket of known customer');
  constructor(public payload: KnownCustomerEventPayload) { }
}


export class AddTicketEvent {
  static readonly type = Utils.Helpers.Type('[Ticket: Event] Add event to ticket');
  constructor(public payload: EventPayload) { }
}

export class AddNoteToDoRefactor {
  static readonly type = Utils.Helpers.Type('[Ticket: Event] Add note to ticket')
  constructor(public payload: AddNotePayload) { }
}
