import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { VoiceQueueErrorComponent } from './voice-queue-error/voice-queue-error.component';
import { AgentVoiceRegisteredIconComponent } from './agent-voice-registered-icon/agent-voice-registered-icon.component';
import { AgentNotificationsModule } from './agent-notifications/agent-notifications.module';


const declarations = [
    VoiceQueueErrorComponent,
    AgentVoiceRegisteredIconComponent
];

@NgModule({
    declarations: [...declarations],
    imports: [
        CommonModule,
        AgentNotificationsModule,
        SharedModule
    ],
    exports: [
        ...declarations,
        AgentNotificationsModule
    ]
})
export class ToolbarModule { }