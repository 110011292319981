import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Actions, ofActionSuccessful, Select, Store } from '@ngxs/store';
import { CustomerInfoState } from 'src/app/customer-info-summary-page/store/state/customer-info.state';
import { WhatsappMessageActions } from '../store/actions/whatsapp-message-actions';
import { WhatsappMessagesState } from '../store/state/whatsapp-messages.state';
import { Observable, Subject } from 'rxjs';
import { WhatsappMessage } from '../store/types/whatsapp-message.interface';
import { filter, takeUntil } from 'rxjs/operators';
import { SFValidators } from 'src/app/shared/functions/sf-validators';
import { createWhatsappMsgPayload } from '../store/functions/convert-to-formatted-whatsapp-msg';
import { CoreState } from 'src/app/core/store/state/core.state';
import { WhatsappSendMessageState } from '../store/state/whatsapp-send-message.state';

@Component({
  selector: 'sf-whatsapp-interactions-container',
  templateUrl: './whatsapp-interactions-container.component.html',
  styleUrls: ['./whatsapp-interactions-container.component.scss']
})
export class WhatsappInteractionsContainerComponent implements OnInit, AfterViewInit, OnDestroy {

  @Select(WhatsappMessagesState.getData) messages$: Observable<WhatsappMessage[]>;
  @Select(WhatsappMessagesState.getError) error$: Observable<string>;
  @Select(WhatsappMessagesState.isLoading) loading$: Observable<boolean>;
  @Select(WhatsappMessagesState.isLoaded) isLoaded$: Observable<boolean>;
  @Select(WhatsappMessagesState.isSilentlyReloading) silentReload$: Observable<boolean>;
  @Select(WhatsappSendMessageState.isLoading) isSending$: Observable<boolean>;
  @Select(WhatsappMessagesState.isPaginatingOrSilentlyLoading) isPaginatingOrSilentlyLoading$: Observable<boolean>;

  @ViewChild('messages') private messageScrollbar: ElementRef<HTMLDivElement>;

  isExpanded = false;
  form: FormGroup;

  private readonly ngDestroy$ = new Subject<null>();

  constructor(private store: Store,
    private actions$: Actions,
    private fb: FormBuilder) {
  }

  ngOnInit(): void {
    const initialMessage = this.getDefaultStartMessage();

    this.form = this.fb.group({
      message: this.fb.control(initialMessage, Validators.required)
    });
  }

  ngAfterViewInit(): void {
    this.isLoaded$
      .pipe(
        filter(loaded => loaded),
        takeUntil(this.ngDestroy$)
      )
      .subscribe({
        next: () => this.scrollToBottom()
      })

    this.actions$.pipe(
      ofActionSuccessful(WhatsappMessageActions.ReceivedNewMessage),
      takeUntil(this.ngDestroy$)
    )
      .subscribe({
        next: () => this.scrollToBottom()
      })
  }

  private getDefaultStartMessage() {
    const firstName = this.store.selectSnapshot(CustomerInfoState.getCustomer)?.first_name;
    return firstName ? `Hi ${firstName},` : "Hi";
  }

  private getCustomerIdentifier() {
    return this.store.selectSnapshot(CustomerInfoState.getWhatsappIdentifier);
  }

  fetchNextPage() {
    if (!this.store.selectSnapshot(WhatsappMessagesState.isPaginatingOrLoading)) {
      this.store.dispatch(new WhatsappMessageActions.FetchNextPage());
    }
  }

  private scrollToBottom(): void {
    try {
      this.messageScrollbar.nativeElement.scrollTop = this.messageScrollbar.nativeElement.scrollHeight;
    }
    catch (err) { /* empty */ }
  }

  toggleTextExpand() {
    this.isExpanded = !this.isExpanded;
  }

  sendMessage() {
    const { message } = this.form.getRawValue();
    const customerIdentifier = this.getCustomerIdentifier();
    const agentEmail = this.store.selectSnapshot(CoreState.getAgentEmail);
    SFValidators.warnAboutMissingFields({ message, customerIdentifier, agentEmail }, "Failed to send message.");

    const msgPayload = createWhatsappMsgPayload(message, agentEmail, customerIdentifier);
    this.store.dispatch(new WhatsappMessageActions.Send(msgPayload));

    this.form.get("message").patchValue(this.getDefaultStartMessage());
  }

  ngOnDestroy(): void {
    this.ngDestroy$.next(null);
    this.ngDestroy$.complete();
  }

}
