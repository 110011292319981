<div *ngIf="(isOpen$ | async) && (isLoaded$ | async)" class="wrapper">

  <ng-container *ngIf="currentReport$ | async as report; else error">
    <mat-icon class="default-cancel-icon" (click)="onClose()">cancel</mat-icon>

    <ng-container *ngIf="report?.current?.stage > 0; else noLoadshedding">
      <p class="text red">load shedding stage {{report?.current?.stage}}</p>
    </ng-container>

    <ng-template #noLoadshedding>
      <p class="text green">no load shedding</p>
    </ng-template>

  </ng-container>

  <ng-template #error>
    <p class="text red">Error: Failed to fetch loadshedding status.</p>
  </ng-template>

</div>
