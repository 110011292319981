import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { AuthenticationComponent } from './auth/auth.component';
import { MsalGuard } from '@azure/msal-angular';
import { SearchPageComponent } from './search-page/search-page.component';
import { ALL, SF_AUTH_ROLES } from './auth/assets/auth-role.config';
import { CustomerInfoWrapperComponent } from './customer-info-summary-page/components/customer-info-wrapper/customer-info-wrapper.component';
import { AdminComponent } from './features/pages/admin/admin.component';
import { AuthGuardService } from './core/guards/auth-guard.service';
import { AgentViewWrapperComponent } from './agent-view-page/agent-view-wrapper/agent-view-wrapper.component';
import { SalesPortalComponent } from './sales/sales-portal/sales-portal.component';
//TODO: Enable the gaurd when users have been assigned the roles
const routes: Routes = [
    {
        path: '',
        redirectTo: '/home',
        pathMatch: 'full',
    },
    {
        path: 'authentication',
        component: AuthenticationComponent,
    },
    {
        path: 'home',
        component: HomeComponent,
        // canActivate: [MsalGuard]
    },
    {
        path: 'admin',
        component: AdminComponent,
        canActivate: [MsalGuard, AuthGuardService],
        data: {
            roles: [SF_AUTH_ROLES.MANAGEMENT, SF_AUTH_ROLES.WIPROGEN],
        },
        loadChildren: () =>
            import('./features/pages/admin/admin.module').then(
                (m) => m.AdminModule,
            ),
    },
    {
        path: 'search',
        component: SearchPageComponent,
        canActivate: [MsalGuard], //AuthGuardService
        loadChildren: () =>
            import('./search-page/search-page.module').then(
                (m) => m.SearchPageModule,
            ),
        data: {
            roles: [SF_AUTH_ROLES.GENERAL, SF_AUTH_ROLES.AGENT],
        },
    },
    {
        path: 'customer-info-summary/:query',
        component: CustomerInfoWrapperComponent,
        canActivate: [MsalGuard], //AuthGuardService
        loadChildren: () =>
            import(
                './customer-info-summary-page/customer-info-summary-page.module'
            ).then((m) => m.CustomerInfoSummaryPageModule),
        data: {
            roles: [SF_AUTH_ROLES.AGENT, SF_AUTH_ROLES.GENERAL],
        },
    },
    {
        path: 'agent-view',
        component: AgentViewWrapperComponent,
        canActivate: [MsalGuard], //AuthGuardService
        loadChildren: () =>
            import('./agent-view-page/agent-view.module').then(
                (m) => m.AgentViewModule,
            ),
        data: {
            roles: [SF_AUTH_ROLES.AGENT],
        },
    },
    {
        path: 'tickets',
        canActivate: [MsalGuard, AuthGuardService], //AuthGuardService
        loadChildren: () =>
            import('./features/pages/ticket/ticket.module').then(
                (m) => m.TicketModule,
            ),
        data: {
            roles: [ALL],
            unallowed_roles: ["CEC.UPGRADES.A",
                "CEC.SALES.A",
                "CEC.SALES.B",
                "CEC.SALES.C",
                "CEC.SALES.CCI",
                "CEC.NO_CONTACT.CCI",
                "CEC.SALESPRIORITY.A",
                "CEC.SALES_REALTIME.A"
            ]
        },
    },
    {
        path: 'sales-portal',
        canActivate: [MsalGuard], //AuthGuardService,
        component: SalesPortalComponent,
        loadChildren: () =>
            import('./sales/sales.module').then(
                (m) => m.SalesModule,
            ),
        data: {
            roles: [SF_AUTH_ROLES.GENERAL],
        },
    },
    {
        // Needed for Error routing
        path: "**",
        redirectTo: "",
        component: HomeComponent,
        canActivate: [MsalGuard],
    },
];

const isIframe = window !== window.parent && !window.opener;

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            // Don't perform initial navigation in iframes
            initialNavigation: !isIframe ? 'enabled' : 'disabled',
        }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule { }
