import { Selector, createSelector } from "@ngxs/store";
import { RainAppResponse } from "src/app/customer-data-components/sim-details/assets/rain-app-response.interface";
import { SimCardDetails } from "src/app/customer-data-components/sim-details/assets/sim-card-details.interface";
import { SimResource } from "src/app/shared/interfaces/sim-resource.interface";
import { CustomerInfoState } from "../state/customer-info.state";
import { ExtendedServiceSelectors } from "./extended-service.selector";
import { ExtendedCustomerService } from "../interfaces/proxy-customer-service.interface";
import { SmartSubData } from "src/app/shared/interfaces/smartsub-data.interface";


export class CustomerResourceSelectors {

    @Selector([CustomerInfoState.getSmartsubData])
    static getSimResources(smartsubData: SmartSubData) { return smartsubData?.resources ?? []; }

    @Selector([CustomerInfoState.getSmartsubData])
    static getRainAppResponses(smartsubData: SmartSubData) { return smartsubData?.my_rain_app?.responses ?? []; }

    static getSimResourceById(id: string) {
        return createSelector(
            [
                CustomerResourceSelectors.getSimResources,
                ExtendedServiceSelectors.getServiceById(id)
            ],
            (resources: SimResource[], service: ExtendedCustomerService) => {
                if (!id) return null;
                const { associated_resource: iccid } = service ?? {};

                return resources?.find(item => item?.iccid === iccid)
            });
    }

    static getRainAppResponseById(id: string) {
        return createSelector(
            [
                CustomerResourceSelectors.getRainAppResponses,
                CustomerResourceSelectors.getSimResourceById(id)
            ],
            (rainAppResponses: RainAppResponse[], resource: SimResource) => {
                if (!id || !resource) return null;

                return rainAppResponses?.find(response => response?.imsi === resource?.imsi);
            });
    }

    static getSimDataById(id: string) {
        return createSelector(
            [
                ExtendedServiceSelectors.getServiceById(id),
                CustomerResourceSelectors.getRainAppResponseById(id),
                CustomerResourceSelectors.getSimResourceById(id)
            ],
            (service: ExtendedCustomerService, rainAppResponse: RainAppResponse, resource: SimResource): SimCardDetails => {
                if (!id) {
                    return null;
                }

                return {
                    service,
                    rainAppResponse,
                    resource
                };
            });
    }

    static getRainAppResponseByIMSI(imsi: string) {
        return createSelector(
            [CustomerResourceSelectors.getRainAppResponses],
            (rainAppResponses: RainAppResponse[]) => {
                if (!imsi) return null;

                return rainAppResponses?.find(response => response?.imsi === imsi);
            });
    }

    static getServiceByIMSI(imsi: string) {
        return createSelector(
            [CustomerResourceSelectors.getSimResources, ExtendedServiceSelectors.getServices],
            (resources: SimResource[], services: ExtendedCustomerService[]) => {
                if (!imsi) return null;
                const { iccid: associated_resource } = resources?.find(resource => resource?.imsi === imsi) ?? {};

                return services?.find(service => service?.associated_resource === associated_resource);
            });
    }



}
