import { Component, Input } from '@angular/core';

@Component({
  selector: 'sf-icon',
  templateUrl: './snowflake-icon.component.html',
  styleUrls: ['./snowflake-icon.component.scss']
})
export class SnowflakeIconComponent {
  @Input() name: string;
  @Input() folder = 'icons';
  @Input() height: string;

  public fetchIcon() {
    if (this.name) return this.name.includes('.svg') || this.name.includes('.png') ? `/assets/${this.folder}/${this.name}` : `/assets/${this.folder}/${this.name}.svg`;
  }

}
