import { Component, Input } from '@angular/core';
import { Ticks } from '../chat-ticks/ticks.type';

@Component({
  selector: 'sf-chat-date-time',
  templateUrl: './chat-date-time.component.html',
  styleUrls: ['./chat-date-time.component.scss']
})
export class ChatDateTimeComponent {

  @Input() dateTime: string | number;
  @Input() ticks?: Ticks;
  @Input() ticksDirection: "left" | "right";

}
