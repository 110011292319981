import { getCorrectProductName } from "src/app/customer-data-components/sim-details/assets/product-lookup-table";


const otherFourGServices = ['19 hours unlimited off-peak'];

export enum ServiceImages {
  fourG = 'assets/images/4g-sim.png',
  fiveGSIM = 'assets/images/devices/5g',
  fiveG = "assets/images/devices/router.png",
  default = 'assets/images/devices/defaultphone.svg',
}

export function getServiceImage(name?: string) {
  if (!name) {
    return ServiceImages.default;
  }

  const produnctName = name.toLowerCase();
  if (produnctName.toLowerCase().includes("swap")) {
    return produnctName.includes('4g') ? ServiceImages.fourG : ServiceImages.default;
  }
  const fmtName = getCorrectProductName(name).toLowerCase();
  if (fmtName.includes('4g') || otherFourGServices.includes(fmtName)) return ServiceImages.default;
  if (fmtName.includes('5g')) return ServiceImages.fiveG;

  return ServiceImages.default;
}
