import { AfterViewInit, Directive, EventEmitter, Output, ViewContainerRef } from '@angular/core'

@Directive({
    selector: '[isVisible]',
})
export class IsVisibleEmitter implements AfterViewInit {

    @Output() inView = new EventEmitter<void>();

    constructor(private viewContainerRef: ViewContainerRef) {
    }

    ngAfterViewInit() {
        const observedElement = this.viewContainerRef.element.nativeElement?.parentElement;

        const observer = new IntersectionObserver(([entry]) => {
            if (entry.isIntersecting) {
                this.inView.next();
            }
        });

        if (observedElement) {
            observer.observe(observedElement);
        }

    }
}