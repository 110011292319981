import { Component, Input } from '@angular/core';
import { FormGroup, AbstractControl } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { SFValidators } from 'src/app/shared/functions/sf-validators';
import { AppDateAdapter } from '../../../rain-forms/formatters/adapter.format';
import { validationMessages } from '../../../rain-forms/pipes';
import { FieldConfig } from '../../interfaces/field-config.interface';
import { Field } from '../../interfaces/field.interface';
import { FormDateFilter } from './form-date-filter.interface';

@Component({
  selector: 'sf-form-datepicker',
  templateUrl: './form-datepicker.component.html',
  styleUrls: ['./form-datepicker.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: AppDateAdapter },
  ]
})
export class DynamicFormDatepickerComponent implements Field {
  private _config: FieldConfig;
  @Input() set config(config: FieldConfig) {
    this._config = config;
  }
  get config() {
    return this._config;
  }

  private _value: string | number | Date;
  @Input() set value(value: string | number | Date) {
    if (value) {
      this._value = value;
      this.setControl(value);
    }
  }

  @Input() group: FormGroup;
  @Input() pickerType: "calendar" | "timer" | "both" = "both";
  @Input() min: Date = null;
  @Input() max: Date = null;

  /**
   * The filter by default returns true for any date specified
   */
  @Input() dateFilter: FormDateFilter = () => true;

  messages = validationMessages;
  public control: AbstractControl;

  ngOnInit(): void {
    if (SFValidators.isNotDefined(this._value)) {
      this.setControl(this.config.value);
    }
  }

  setControl(value: string | number | Date) {
    const d = new Date(value);
    setTimeout(() => {
      this.getControl().patchValue(d, { emitEvent: false, onlySelf: true });
    }, 0);
  }

  public getControl() {
    if (this.group) {
      return this.control = this.group.get(this.formatLabel(this.config.name));
    }
  }

  public formatLabel(l: string) {
    return l;
  }

  public checkForErrors() {
    const control = this.group.get(this.formatLabel(this.config.name));
    if (control) {
      if (control.dirty && control.touched) {
        return control.errors;
      }
    }
  }
}
