import { Component, Input } from '@angular/core';
@Component({
  selector: 'sf-pretty-json-note',
  templateUrl: './pretty-json-note.component.html',
  styleUrls: ['./pretty-json-note.component.scss']
})
export class PrettyJsonNoteComponent {

  readonly maxLevel = 15;

  @Input() level = 0;

  @Input() data: unknown;

  originalOrder(): number {
    return 0;
  }

  trackByFn(index: number) {
    return index;
  }

}
