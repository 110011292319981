import { Injectable } from '@angular/core';
import { State, Selector, Store, Action, StateContext } from '@ngxs/store';
import { PaymentMethodService } from '../../../customer-data-components/account-details/sub-components/payment-method/payment-method.service';
import * as fromPaymentActions from '../actions/payment.actions';
import { DebitAccountPayload } from '../../interfaces/debit-account-payload.interface';
import { AddInfoNotification, AddSuccessResponseNotification } from '../actions/notifications.actions';
import { CustomerInfoSummaryDestroyed, FetchCustomerAccountActivity, FetchCustomerDetails, } from 'src/app/customer-info-summary-page/store/actions/customer.actions';
import { CustomerInfoState } from 'src/app/customer-info-summary-page/store/state/customer-info.state';
import { getBillSupportErrorMessage } from 'src/app/customer-data-components/account-details/sub-components/payment-method/assets/billing-support-error-codes.constant';
import { AddKnownCustomerEvent } from 'src/app/shared/customer-ticket/store/actions/ticket-event.actions';
import { take, takeUntil, tap } from 'rxjs/operators';
import { PopupError } from '../../handlers/popup-error';
import { Subject } from 'rxjs';
import { Utils } from 'src/app/Utils';
import { NO_DATA_MESSAGE } from 'src/app/constants';
import { PAYMENT_STATUS_MAPPING } from 'src/app/customer-data-components/customer-order/customer-order-page/assets/payment-status-mapping.constant';

const getDefaults = (): BillingStateModel => {
    return {
        paymentAmount: null,
        paymentInProgress: false
    }
}

export interface BillingStateModel {
    paymentAmount: number;
    paymentInProgress: boolean;
}
@State<BillingStateModel>({
    name: 'billing',
    defaults: getDefaults()
})
@Injectable()
export class BillingState {

    @Selector()
    static paymentInProgress(state: BillingStateModel) { return state.paymentInProgress; }

    constructor(
        private store: Store,
        private _svc: PaymentMethodService
    ) { }


    private readonly _cancel$ = new Subject<void>();

    private getCancelEvent() {
        return this._cancel$.pipe(take(1));
    }

    @Action(fromPaymentActions.DebitAccount)
    DebitAccount(ctx: StateContext<BillingStateModel>, action: fromPaymentActions.DebitAccount) {

        const customerId = this.store.selectSnapshot(CustomerInfoState.getCustomerID);

        if (!customerId) {
            throw new PopupError("Failed to to TMMN, missing customer ID.");
        }

        ctx.patchState({
            paymentAmount: action.payload,
            paymentInProgress: true,
        });

        const { invoiceId, minimumPayment } = this.store.selectSnapshot(CustomerInfoState.getAccountStatus) ?? {};

        const payload: DebitAccountPayload = {
            invoiceId,
            minAmount: minimumPayment,
            amount: action.payload,
            meta: {},
        };

        const initiatedTime = Date.now();

        return this._svc.debit(customerId, payload)
            .pipe(
                tap({
                    next: () => ctx.dispatch(new fromPaymentActions.DebitAccountSuccess(invoiceId, initiatedTime)),
                    error: (err: unknown) => ctx.dispatch(new fromPaymentActions.DebitAccountFail(err)),
                }),
                takeUntil(this.getCancelEvent())
            );
    }

    @Action(fromPaymentActions.DebitAccountSuccess)
    DebitAccountSuccess(ctx: StateContext<BillingStateModel>, action: fromPaymentActions.DebitAccountSuccess) {

        //Keep this action as the modal listens to this event to close
        const { invoiceId, initiatedTime } = action;
        return ctx.dispatch([
            new AddInfoNotification("Request sent, awaiting payment response."),
            new fromPaymentActions.CheckPaymentStatus(invoiceId, initiatedTime)
        ]);

    }

    @Action(fromPaymentActions.DebitAccountFail)
    DebitAccountFail(ctx: StateContext<BillingStateModel>, action: fromPaymentActions.DebitAccountFail) {
        const { payload } = action;

        const { paymentAmount } = ctx.getState();
        const message = `Take My Money Now for R${paymentAmount} failed.`;

        const error = getBillSupportErrorMessage(payload);
        const errorMessage = error ? `${message}. Error: ${error}` : message;

        this.onError(ctx, errorMessage);
    }

    @Action(fromPaymentActions.CheckPaymentStatus)
    CheckPaymentStatus(ctx: StateContext<BillingStateModel>, action: fromPaymentActions.CheckPaymentStatus) {
        const { invoiceId, initiatedTime } = action;

        if (!invoiceId) {
            return ctx.dispatch(new fromPaymentActions.PaymentFailed("No Invoice ID."));
        }

        return this._svc.pollForPaymentStatus(invoiceId, initiatedTime)
            .pipe(
                tap({
                    next: res => {
                        const { status } = res?.data ?? {};

                        if (!status) {
                            return ctx.dispatch(new fromPaymentActions.PaymentFailed(NO_DATA_MESSAGE));
                        }

                        const mappingResult = PAYMENT_STATUS_MAPPING[status];
                        if (mappingResult.successful) {
                            return ctx.dispatch(new fromPaymentActions.PaymentSuccessful(mappingResult.status));
                        }
                        else {
                            return ctx.dispatch(new fromPaymentActions.PaymentFailed(mappingResult.status));
                        }
                    },
                    error: (e: unknown) => ctx.dispatch(new fromPaymentActions.PaymentFailed(e))
                }),
                takeUntil(this.getCancelEvent())
            );
    }

    @Action(fromPaymentActions.PaymentSuccessful)
    CheckPaymentStatusSuccess(ctx: StateContext<BillingStateModel>) {

        //keep reference for event
        const amount = ctx.getState().paymentAmount;
        const message = `Successfully debited account for R${amount}. (TMMN)`;

        ctx.patchState({
            paymentAmount: null,
            paymentInProgress: false,
        });

        const usersEmail = this.store.selectSnapshot(CustomerInfoState.getCustomerEmail);

        ctx.dispatch([
            new FetchCustomerDetails({
                value: usersEmail,
                config: { loader: false },
            }),
            new FetchCustomerAccountActivity(),
            new AddSuccessResponseNotification({
                success: true,
                message
            }),
            new AddKnownCustomerEvent({
                eventComment: message,
                reload: false
            })
        ]);
    }

    @Action(fromPaymentActions.PaymentFailed)
    CheckPaymentStatusFail(ctx: StateContext<BillingStateModel>, action: fromPaymentActions.PaymentFailed) {
        const { paymentAmount } = ctx.getState();

        const error = Utils.Helpers.findError(action.error, "");
        const message = `Take My Money Now for R${paymentAmount} failed. ${error}`;

        this.onError(ctx, message);
    }

    private onError(ctx: StateContext<BillingStateModel>, errorMessage: string) {

        ctx.patchState({
            paymentAmount: null,
            paymentInProgress: false,
        });

        ctx.dispatch([
            new AddSuccessResponseNotification({
                success: false,
                message: errorMessage
            }),
            new AddKnownCustomerEvent({
                eventComment: errorMessage,
                reload: false
            })
        ]);
    }


    @Action(CustomerInfoSummaryDestroyed)
    Clear(ctx: StateContext<BillingStateModel>) {
        this._cancel$.next(null);
        ctx.setState(getDefaults());
    }

}
