import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { SalesCartActions } from '../action/sales-cart-actions';
import { SalesPortalDestroyed } from '../action/sales-portal.actions';
import { GeForceTier } from 'src/app/core/store/interfaces/product-catalog.interfaces';


const getDefaults = (): SalesCartStateModel => {
  return {
    bundleId: null,
    selectedNvidiaTier: null,
    rainOneSelected: false,
    smeSelected: false,
    nvidiaSelected: false,
    mobileSelected: false,
    onePackSelected: false,
    threePackSelected: false,
    fivePackSelected: false,
    quantity: 0
  }
}

interface SalesCartStateModel {
  bundleId: string;
  rainOneSelected: boolean;
  smeSelected: boolean;
  selectedNvidiaTier: GeForceTier;
  nvidiaSelected: boolean;
  mobileSelected: boolean;
  onePackSelected: boolean;
  threePackSelected: boolean;
  fivePackSelected: boolean;
  quantity: number;
}
@State<SalesCartStateModel>({
    name: 'sf_sales_cart_state',
    defaults: getDefaults()
})
@Injectable()
export class SalesCartState {

    @Selector([SalesCartState])
    static getSelectedBundleId(state: SalesCartStateModel) { return state.bundleId }

    @Selector([SalesCartState])
    static rainOneSelected(state: SalesCartStateModel) { return state.rainOneSelected }
    @Selector([SalesCartState])
    static smeSelected(state: SalesCartStateModel) { return state.smeSelected }

    @Selector([SalesCartState])
    static nvidiaSelected(state: SalesCartStateModel) { return state.nvidiaSelected }

    @Selector([SalesCartState])
    static getSelectedNvidiaTier(state: SalesCartStateModel) { return state.selectedNvidiaTier }

  @Selector([SalesCartState])
  static mobileSelected(state: SalesCartStateModel) { return state.mobileSelected }

  @Selector([SalesCartState])
  static onePackSelected(state: SalesCartStateModel) { return state.onePackSelected }

  @Selector([SalesCartState])
  static threePackSelected(state: SalesCartStateModel) { return state.threePackSelected }

  @Selector([SalesCartState])
  static fivePackSelected(state: SalesCartStateModel) { return state.fivePackSelected }

  @Selector([SalesCartState])
  static quantity(state: SalesCartStateModel) { return state.quantity }

    // ================================== Actions ==================================== //


    @Action(SalesCartActions.SetRainOneSelected)
    SetRainOneSelected(ctx: StateContext<SalesCartStateModel>, action: SalesCartActions.SetRainOneSelected) {
        ctx.patchState({
            rainOneSelected: action.selected
        });
    }

    @Action(SalesCartActions.SetWorkProductSelected)
    SetWorkProductSelected(ctx: StateContext<SalesCartStateModel>, action: SalesCartActions.SetWorkProductSelected) {
        ctx.patchState({
            smeSelected: action.isSME
        });
    }

  @Action(SalesCartActions.SetMobileProductSelected)
  SetStandaloneMobileProductSelected(ctx: StateContext<SalesCartStateModel>, action: SalesCartActions.SetMobileProductSelected) {
    ctx.patchState({
      mobileSelected: action.isMobileSelected
    });
  }

  @Action(SalesCartActions.SetOnePackSelected)
  onePackSelected(ctx: StateContext<SalesCartStateModel>, action: SalesCartActions.SetOnePackSelected) {
    ctx.patchState({
      onePackSelected: action.isOnePackSelected,
      threePackSelected: false,
      fivePackSelected: false,
      quantity: 1,
    });
  }

  @Action(SalesCartActions.SetThreePackSelected)
  threePackSelected(ctx: StateContext<SalesCartStateModel>, action: SalesCartActions.SetThreePackSelected) {
    ctx.patchState({
      threePackSelected: action.isThreePackSelected,
      onePackSelected: false,
      fivePackSelected:false,
      quantity: 3,
    });
  }

  @Action(SalesCartActions.SetFivePackSelected)
  fivePackSelected(ctx: StateContext<SalesCartStateModel>, action: SalesCartActions.SetFivePackSelected) {
    ctx.patchState({
      fivePackSelected: action.isFivePackSelected,
      onePackSelected: false,
      threePackSelected: false,
      quantity: 5,
    });
  }

    @Action(SalesCartActions.SetNvidiaSelected)
    SetNvidiaSelected(ctx: StateContext<SalesCartStateModel>, action: SalesCartActions.SetNvidiaSelected) {
        ctx.patchState({
            nvidiaSelected: action.selected
        });
    }


    @Action(SalesCartActions.SetSelectedBundleId)
    SetSelectedBundleId(ctx: StateContext<SalesCartStateModel>, action: SalesCartActions.SetSelectedBundleId) {
        ctx.patchState({
            bundleId: action.bundleId
        });
    }
    @Action(SalesCartActions.SetSelectedNvidiaTier)
    SetSelectedNvidiaTier(ctx: StateContext<SalesCartStateModel>, action: SalesCartActions.SetSelectedNvidiaTier) {
        ctx.patchState({
            selectedNvidiaTier: action.nvidiaTier
        });
    }

    @Action(SalesCartActions.ClearSelectedBundleId)
    ClearSelectedBundleId(ctx: StateContext<SalesCartStateModel>) {
        ctx.patchState({
            bundleId: null
        });
    }


    @Action([SalesCartActions.Clear, SalesPortalDestroyed])
    Clear(ctx: StateContext<SalesCartStateModel>) {
        ctx.setState(getDefaults());
    }


}
