import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';

import { NgxsModule } from '@ngxs/store';
import { FAQ_ScenariosState } from './store/state/faq-scenarios.state';
import { FAQsModalComponent } from './faqs-modal/faqs-modal.component';
import { TimeoutModalComponent } from './timeout-modal/timeout-modal.component';
import { AgentTimeOutState } from './store/state/timeout.state';
import { ExpiredTokenReminderModalComponent } from './expired-token-reminder-modal/expired-token-reminder-modal.component';

const declarations = [FAQsModalComponent, TimeoutModalComponent, ExpiredTokenReminderModalComponent];
@NgModule({
    declarations: [...declarations],
    imports: [
        CommonModule,
        SharedModule,
        NgxsModule.forFeature([FAQ_ScenariosState, AgentTimeOutState]),
    ],
    exports: [...declarations],
})
export class MainModalModule {}
