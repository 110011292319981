<div class="legend">
    <div class="pl-30 pt-20"></div>
</div>

<div
    *ngIf="showCoverageAndSites"
    class="floating-panel"
    #floatingPanel
>
    <div class="check-buttons">
        <button
            *ngFor="let layerId of layerIds"
            [class.checked]="mapLayerService.isLayerVisible(layerId)"
            (click)="toggleSites(layerId)"
        >
            <label>{{ layerId }} sites</label>
        </button>

        <button
            [class.checked]="isCoverageVisible('fourg_105')"
            (click)="toggleCoverage('fourg_105')"
        >
            <label>4G Home Coverage</label>
        </button>

        <button
            [class.checked]="isCoverageVisible('home_5G')"
            (click)="toggleCoverage('home_5G')"
        >
            <label>5G Home Coverage</label>
        </button>

        <button
            [class.checked]="isCoverageVisible('fourg_roaming_shp')"
            (click)="toggleCoverage('fourg_roaming_shp')"
        >
            <label>4G Mobile Coverage</label>
        </button>

    </div>
</div>

<div class="coverage-check">
    <div
        #fullscreenSearch
        id="fullScreenTopCenterControl"
        [class.hide]="hideFullScreenTopCenterControl"
    ></div>
    <agm-map
        (mapReady)="mapReady($event)"
        [tilt]="0"
        [zoom]="zoom"
        [latitude]="_latitude ?? defaultLat"
        [longitude]="_longitude ?? defaultLong"
        [scrollwheel]="null"
        style="min-height: 300px; height: 100%"
        [streetViewControl]="true"
        [mapTypeControl]="showMapControls"
        [fullscreenControl]="showFullscreenControls"
        [zoomControl]="true"
        (fullscreenchange)="fullscreenchange()"
        (zoomChange)="zoomChange($event)"
    >
        <agm-marker
            *ngIf="_latitude && _longitude && showMarker"
            [latitude]="_latitude"
            [longitude]="_longitude"
        ></agm-marker>

        <ng-container *ngFor="let pickupPoint of (formattedPickupPoints$ | async) ?? []">
            <agm-marker
                (markerClick)="setSelectedPickupPoint(pickupPoint)"
                [latitude]="pickupPoint?.address?.gps_coordinate?.latitude"
                [longitude]="pickupPoint?.address?.gps_coordinate?.longitude"
                [iconUrl]="pickupPoint?.selected ? selectedMarkerIcon : markerIcon"
            >
            </agm-marker>
        </ng-container>
    </agm-map>
</div>