import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Store } from "@ngxs/store";
import { AGENT_VIEW_ROUTE, SEARCH_ROUTE, SUMMARY_ROUTES } from "src/app/constants";
import { AddAgentBrowserActionTracker, AssignAgent } from "src/app/shared/components/agent/store/actions/agent.actions";
import { CustomRouterEventHandler, RouterUrls } from "src/app/shared/services/router-event-controller/custom-router-event-handler.interface";
import { stringIncludesArrayItem } from "../../functions/includes-array-item/string-includes-array-item.function";
import { CustomResolver } from "../../models/custom-resolver/custom-resolver.model";
import { RouterEventControllerService } from "../../services/router-event-controller/router-event-controller.service";
import { AgentDetails } from "./assets/agent-details.model";
import { AgentStatus } from "./assets/agent-status.type";
import { ToggleHasMoreOpenTicketsModal } from "src/app/interactions/store/actions/interaction-actions/interactions.actions";
import { VoiceAuthState } from "src/app/sip-phone/store/state/voice-auth.state";
import { filter, take } from "rxjs/operators";
import { VoiceAuthActions } from "src/app/sip-phone/store/actions/voice-auth-actions";
import { AgentVoiceQueueActions } from "src/app/sip-phone/store/actions/agent-voice-queue-actions";
import { SetBusy, SetPostTicket } from "./store/actions/agent-status-actions/agent-status.actions";
import { AgentState } from "./store/state/agent.state";
import { AgentTrackingService } from "./services/agent-tracking.service";
import { CoreState } from "src/app/core/store/state/core.state";

@Injectable({
  providedIn: 'root'
})
export class RainAgent implements CustomRouterEventHandler {

  private _agentDetailResolver = new CustomResolver<AgentDetails>();

  //TODO: 
  constructor(private routerController: RouterEventControllerService,
    private router: Router,
    private store: Store,
    private agentTrackingService: AgentTrackingService) {
    this.routerController.addCustomEventHandler(this);
  }

  setAgentDetails(name: string, email: string, roles: string[]) {
    this.store.dispatch(new AssignAgent(new AgentDetails(name, email, roles || [])));
    this._agentDetailResolver.setData(new AgentDetails(name, email, roles));

    this.agentTrackingService.initializeTracking(email);

    this.store.dispatch(new AddAgentBrowserActionTracker());

    this.initSipLogin(email);
  }

  private initSipLogin(email: string) {
    this.store.dispatch(new VoiceAuthActions.FetchLoginDetails(email));

    this.store.select(VoiceAuthState.getState)
      .pipe(
        filter(state => state.loaded),
        take(1)
      )
      .subscribe(state =>
        this.store.dispatch(new AgentVoiceQueueActions.FetchQueues(state.data?.access_token))
      );
  }

  //TODO: 
  async getAgentDetails() {
    return this._agentDetailResolver.getData();
  }

  continueToNextTicket({ checkOpenTickets }: { checkOpenTickets: boolean }) {
    const hasOpenTickets = this.store.selectSnapshot(CoreState.getOpenTicketsForGroup)?.length > 0;

    if (checkOpenTickets && hasOpenTickets) {
      return this.store.dispatch(new ToggleHasMoreOpenTicketsModal(true));
    }

    this.router.navigate([AGENT_VIEW_ROUTE]);
    this.store.dispatch(new SetPostTicket());
  }

  handleRouteChange(router: Router, routerUrls: RouterUrls) {
    const movedFromAgentViewToSearch = routerUrls.previousUrl.includes(AGENT_VIEW_ROUTE) && routerUrls.currentUrl.includes(SEARCH_ROUTE);
    const status = this.store.selectSnapshot(AgentState.getStatus);

    if (!status || movedFromAgentViewToSearch) {
      return;
    }

    //nonAutoRoutes and nonAutoStates will not automatically change the status
    const nonAutoRoutes = [...SUMMARY_ROUTES, AGENT_VIEW_ROUTE];
    const nonAutoStates: AgentStatus[] = ["logged on", "in call"];

    const isAutoChangeRoute = !inRoutes(nonAutoRoutes);
    const isAutoChangeState = !nonAutoStates.includes(status);
    const isNotInSummaryRoute = !inRoutes(SUMMARY_ROUTES);

    if ((isAutoChangeRoute && isAutoChangeState) || (status === "in ticket" && isNotInSummaryRoute)) {
      this.store.dispatch(new SetBusy());
    }


    function inRoutes(routes: string[]) {
      const currentRoute = router.url;
      return stringIncludesArrayItem(currentRoute, routes);
    }
  }
}