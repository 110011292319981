import { Pipe, PipeTransform } from '@angular/core';
import { AudioFunctions } from '../../components/media-player/audio-player/audio-functions';
import { SFValidators } from '../../functions/sf-validators';

@Pipe({ name: 'duration' })
export class DurationPipe implements PipeTransform {

    transform(seconds: number, withHours = false): string {
        return SFValidators.isDefined(seconds) ? AudioFunctions.formatDuration(seconds, withHours) : null;
    }

}