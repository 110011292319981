<div class="wrapper" [ngClass]="direction">

    <div class="bubble-wrapper" [ngClass]="direction">

        <div *ngIf="loading; else notLoading" class="bubble-message" [class.colored]="colored">
            <div class="loading-message"></div>
        </div>

        <ng-template #notLoading>

            <div *ngIf="isHtml; else defaultNote" class="bubble-message" [class.allow-click]="allowContentClick"
                (click)="onContentClicked()" [class.colored]="colored" [matTooltip]="contentClickMessage">
                <div [innerHtml]="content | stripTag: 'a' | safe : 'html'" [class.emailFormat]="isEmailString"></div>
            </div>

            <ng-template #defaultNote>
                <div class="bubble-message font-normal text-dark-grey" [class.allow-click]="allowContentClick"
                    (click)="onContentClicked()" [class.colored]="colored" [matTooltip]="contentClickMessage">
                    <sf-pretty-json-note [data]="content | toJson"></sf-pretty-json-note>
                </div>
            </ng-template>

        </ng-template>
    </div>

    <div class="bottom-wrapper" [ngClass]="direction">

        <ng-container *ngIf="direction === 'left'; else isRight">
            <sf-chat-letter-bubble [matTooltip]="hoverMessage" [isCustomer]="true">{{letter}}</sf-chat-letter-bubble>

            <sf-chat-date-time [dateTime]="dateTime" ticksDirection="left" [ticks]="ticks"></sf-chat-date-time>
        </ng-container>


        <ng-template #isRight>
            <sf-chat-date-time [dateTime]="dateTime" [ticks]="ticks" ticksDirection="right"></sf-chat-date-time>

            <sf-chat-letter-bubble [matTooltip]="hoverMessage" [isCustomer]="false">{{letter}}</sf-chat-letter-bubble>
        </ng-template>

    </div>

</div>
