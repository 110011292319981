import { BasicCustomer } from "src/app/shared/interfaces/smartsub-data.interface";
import { Utils } from "src/app/Utils";
import { SearchByNamePayload } from "./search.interfaces";


export class FetchCustomerDetailsByUUID {
  static readonly type = Utils.Helpers.Type('[Search: Fetch] Fetch customer details by UUID');
  constructor(public uuid: string) { }
}

export class FetchCustomerDetailsByOrderNumber {
  static readonly type = Utils.Helpers.Type('[Search: Fetch] Fetch customer details by order number');
  constructor(public orderNumber: number) { }
}

export class FetchCustomerDetailsByIMSI {
  static readonly type = Utils.Helpers.Type('[Search: Fetch] Fetch customer details by IMSI');
  constructor(public imsi: string) { }
}

export class FetchCustomerDetailsByName {
  static readonly type = Utils.Helpers.Type('[Search: Fetch] Fetch customer details by name');
  constructor(public payload: SearchByNamePayload) { }
}

export class FetchCustomerDetailsByPassport{
  static readonly type = Utils.Helpers.Type('[Search: Fetch] Fetch customer details by passport');
  constructor(public searchString: string) { }
}

export class FetchCustomerDetailsByICCID{
  static readonly type = Utils.Helpers.Type('[Search: Fetch] Fetch customer details by ICCID');
  constructor(public searchString: string) { }
}

export class FetchCustomerDetailsByPassportFail{
  static readonly type = Utils.Helpers.Type('[Search: Fetch] Fetch customer details by passport fail');
  constructor(public error: unknown) { }
}

export class FetchCustomerDetailsByNameSuccess {
  static readonly type = Utils.Helpers.Type('[Search: Success] Fetch customer details by name success');
  constructor(public payload: BasicCustomer[]) { }
}

export class FetchFullProfileByParam {
  static readonly type = Utils.Helpers.Type('[Search: Fetch] Fetch full profile by param');
  constructor(public param: string, public autoNavigate = false) { }
}

export class FetchCustomerDetailsByParamFail {
  static readonly type = Utils.Helpers.Type('[Search: Fail] Fetch customer details by name failed');
  constructor(public error: unknown) { }
}

export class ResetCustomerSearch {
  static readonly type = Utils.Helpers.Type('[Search: Reset] Reset customer search');
}
