import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'chat-updated-message',
  templateUrl: './chat-updated-message.component.html',
  styleUrls: ['./chat-updated-message.component.scss']
})
export class ChatUpdatedMessageComponent implements OnInit {
  @Output() close = new EventEmitter();
  @Input() success: boolean;
  @Input() description: string;
  backgroundColor: string;
  header: string;
  time: string;

  ngOnInit(): void {
    this.setTime();
    this.setHeader();
    this.setBackgroundColor();
  }

  setHeader() {
    this.header = this.success ? "success" : "failed";
  }

  setBackgroundColor() {
    this.backgroundColor = this.success ? "#D9FFEA" : "#ffcbd0";
  }

  setTime() {
    this.time = new Date().toString();
  }

  onClose() {
    this.close.next();
  }
}
