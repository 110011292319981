import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { CheckContinueAfterEscalationFailed, ClearContinueState, ShowContinueButton } from "../actions/continue-flow.actions";


const MAX_ATTEMPTS = 3;

function getDefaultValues(): ContinueFromTicketStateModel {
  return {
    can_continue: false,
    escalation_attempts: 0
  }
}

interface ContinueFromTicketStateModel {
  can_continue: boolean;
  escalation_attempts: number;
}

@State<ContinueFromTicketStateModel>({
  name: 'can_continue_button',
  defaults: getDefaultValues()
})
@Injectable()
export class ContinueFromTicketState {

  /**
   * If the agent is able to continue to the next ticket.
   */
  @Selector()
  static canContinue(state: ContinueFromTicketStateModel) { return state.can_continue }

  @Action(ShowContinueButton)
  toggleEscalationsModal(ctx: StateContext<ContinueFromTicketStateModel>, action: ShowContinueButton) {
    const can_continue = action.can_continue;
    ctx.patchState({
      can_continue
    });
  }

  @Action(CheckContinueAfterEscalationFailed)
  checkContinue(ctx: StateContext<ContinueFromTicketStateModel>) {
    const { escalation_attempts } = ctx.getState();

    const attempts = escalation_attempts + 1;
    if (attempts >= MAX_ATTEMPTS) {
      ctx.patchState({
        can_continue: true
      });
    }

    ctx.patchState({
      escalation_attempts: attempts
    })
  }



  @Action(ClearContinueState)
  clear(ctx: StateContext<ContinueFromTicketStateModel>) {
    ctx.setState(getDefaultValues);
  }


}
