import { Invitation, UserAgentOptions } from "sip.js";
import { URI } from "sip.js/lib/core";
import { IS_TESTING_ENV } from "src/app/constants";
import { ICallQueues } from "./call-queue.interface";
import { environment } from "src/environments/environment";

const SCHEME = "sip"
const SERVER = IS_TESTING_ENV ? "qa.voice.rain.co.za" : "voice.rain.co.za";

export const ACCOUNT_NAME = "CEC";
export const REALM = `${ACCOUNT_NAME}.${SERVER}`;

export const ACCOUNT = "c7a096f39aa1c4e027779d34ff5a324c";

export const getCallTransferQueue = (ext: string): URI => new URI(SCHEME, ext, REALM);

export const CALL_QUEUE_DETAILS: { sales: ICallQueues, support: ICallQueues, voiceSupport: ICallQueues, retentions: ICallQueues } = {
    sales: { name: 'Sales', ext: '3000', icon: 'receipt', id: "50bcbebc-3f95-4e3d-9487-6c3c9800f692" },
    support: { name: 'Support', ext: '1000', icon: 'support_agent', id: "005bfbe9-3d98-48a4-aa7f-af6504388cd7" },
    voiceSupport: { name: 'Voice Support', ext: "8000", icon: 'sim_card', id: "656dd8a9-5727-4de3-ba32-1c0e135f072d" },
    retentions: { name: 'Retentions', ext: "7990", icon: 'save', id: "cbc9dfff-3e61-45f3-ad0c-3eefe62fdd07" },
} as const;


export class SipConfig {
    static getSipConfig(username: string, password: string, onInvite: (invitation: Invitation) => void): UserAgentOptions {
        const uri = new URI(SCHEME, username, REALM);
        const userAgentOptions: UserAgentOptions = {
            authorizationUsername: username,
            authorizationPassword: password,
            transportOptions: {
                server: `wss://${SERVER}:5065`
            },
            delegate: {
                onInvite
            },
            uri,
            sessionDescriptionHandlerFactoryOptions: {
                constraints: {
                    audio: true,
                    video: false
                },

                // iceCheckingTimeout: 1000,
                peerConnectionConfiguration: {
                    iceServers: [
                        {
                            urls: environment.stunServerUrl
                        }
                    ],
                }
            }
        }
        return userAgentOptions;
    }

}

//OLD 'stun:stun.l.google.com:19302'


/** FULL  peerConnectionOptions*/
// https://www.appsloveworld.com/webrtc/100/12/how-to-change-stun-server-in-sipjs

// bundlePolicy: "balanced", // Note: max-bundle is not supported by the demo backend currently (5/15/17)
// certificates: undefined,
// iceCandidatePoolSize: 0,
// iceServers: [{ urls: "stun:stun.l.google.com:19302" }], // TURN URL example: "turn:88.88.88.0:3478", "test", "test"
// iceTransportPolicy: "all",
// peerIdentity: undefined,
// rtcpMuxPolicy: "require"
