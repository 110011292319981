import { DataHandler } from "src/app/shared/data-handler/data-handler";
import { DataLoading } from "src/app/shared/interfaces/data-loading.interface";


export function getLoadedSuccessState(dataLoadingItem: DataLoading) {
  return {
    loaded: dataLoadingItem?.loaded,
    success: DataHandler.isDefined(dataLoadingItem?.data)
  }
}
