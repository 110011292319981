import { Pipe, PipeTransform } from '@angular/core';
import { DataHandler } from '../../data-handler/data-handler';

@Pipe({ name: 'rainColorCode' })
export class RainColorCodePipe implements PipeTransform {

  transform(value: string, defaultColor = "grey"): string {
    if (!value) {
      return defaultColor ? DataHandler.getColorCode(defaultColor) : null;
    }
    else {
      return DataHandler.getColorCode(value);
    }
  }

}
