import { DataLoading } from "src/app/shared/interfaces/data-loading.interface";


/**
 * Check if any of the items in the state are still loading.
 */
export function isInProgress(state: { [x: string]: DataLoading<any, any> }): boolean {
  for (const key in state) {
    const item = state[key];
    if (item.loading) {
      return true;
    }
  }

  return false;
}
