import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { MainWebSocketService } from 'src/app/shared/services/main-web-socket/main-web-socket.service';

@Component({
  selector: 'sf-websocket-error',
  templateUrl: './websocket-error.component.html',
  styleUrls: ['./websocket-error.component.scss']
})
export class WebsocketErrorComponent implements OnInit, OnDestroy {

  loaded = false;

  get errorMessage() {
    let message = "Websocket disconnected";
    const retries = this.mainWebsocket.currentRetries;
    if (retries) {
      message += `, reconnect attempt ${retries}.`;
    }
    return message;
  }


  private _connectedSub: Subscription;

  constructor(public mainWebsocket: MainWebSocketService) { }


  ngOnInit(): void {
    this._connectedSub = this.mainWebsocket.getWebsocketEventSubject("connected").subscribe(
      () => this.loaded = true
    );
  }


  ngOnDestroy(): void {
    if (this._connectedSub) this._connectedSub.unsubscribe();
  }
}
