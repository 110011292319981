import { SnowflakeSelectOption } from "src/app/shared/elements/rain-forms/elements/snowflake-select/interfaces/rain-select-option.interface";




export const QUERY_OPTIONS: SnowflakeSelectOption[] = [
  {
      label: 'No Connection',
      value: 'No Connection'
  },
  {
      label: 'Slow speeds',
      value: 'Slow speeds'
  },
  {
      label: 'Intermittent connection ',
      value: 'Intermittent connection '
  },
  {
      label: 'Can`t get into a game',
      value: 'Can`t get into a game'
  },
  {
      label: 'Poor picture quality ',
      value: 'Poor picture quality ',
  },
  {
      label: 'Lag / High latency ',
      value: 'Lag / High latency '
  },
  {
      label: 'Game frozen',
      value: 'Game frozen'
  },
  {
      label: 'Kicked out of the queue. ',
      value: 'Kicked out of the queue. ',
  },
  {
      label: 'Load shedding ',
      value: 'Load shedding ',
  },
  {
      label: 'Gaming on 4G fallback ',
      value: 'Gaming on 4G fallback ',
  },
  {
      label: 'Unable to link gaming account to NVIDIA.',
      value: 'Unable to link gaming account to NVIDIA.',
},
{
      label: 'Other',
      value: 'Other',
},
];


export const HOME_NETWORK_TYPE_OPTIONS: SnowflakeSelectOption[] = [
  {
      label: 'WIFI 2.4GHz',
      value: 'WIFI 2.4GHz'
  },
  {
      label: 'WIFI 5 GHz',
      value: 'WIFI 5 GHz'
  },
  {
      label: 'LAN cable',
      value: 'LAN cable'
  }
];

export const CONNECTION_TECHNOLOGY_OPTIONS: SnowflakeSelectOption[] = [
  {
      label: '4G',
      value: '4G'
  },
  {
      label: '5G',
      value: '5G'
  },
  {
      label: 'Fibre',
      value: 'Fibre'
  },
  {
      label: 'LTE',
      value: 'LTE'
  }
];


export const TICKET_OUTCOME_OPTIONS: SnowflakeSelectOption[] = [
  {
      label: 'Dasher request',
      value: 'Dasher request '
  },
  {
      label: 'Device swap',
      value: 'Device swap'
  },
  {
      label: 'Optimized',
      value: 'Optimized'
  },
  {
      label: 'Escalated to NVIDIA' ,
      value: 'Escalated to NVIDIA'
  },
  {
      label: 'Escalated to cancellations',
      value: 'Escalated to cancellations',
  },
  {
      label: 'Change Wi-Fi frequency ',
      value: 'Change Wi-Fi frequency '
  },
  {
      label: 'Use the LAN cable.',
      value: 'Use the LAN cable.'
  },
  {
      label: 'Assisted NVIDIA setup.',
      value: 'Assisted NVIDIA setup.',
  }
];

export const PLATFORM_OPTIONS: SnowflakeSelectOption[] = [
  {
      label: 'X-BOX',
      value: 'X-BOX'
  },
  {
      label: 'Play Station',
      value: 'Play Station'
  },
  {
      label: 'PC',
      value: 'PC'
  },
  {
      label: 'Android',
      value: 'Android'
  },
  {
      label: 'iOS',
      value: 'iOS'
  },
];

export const QUERY_OUTCOME_OPTIONS: SnowflakeSelectOption[] = [
  {
      label: 'Resolved',
      value: 'Resolved'
  },
  {
      label: 'Pending',
      value: 'Pending'
  },
  {
      label: 'Escalated',
      value: 'Escalated'
  }
];






export const RATE_GAME_EXPERIENCE: SnowflakeSelectOption[] = [
  {
      label: '1',
      value: '1'
  },
  {
      label: '2',
      value: '2'
  },
  {
      label: '3',
      value: '3'
  },
  {
      label: '4',
      value: '4'
  },
  {
      label: '5',
      value: '5'
  }
] ;

