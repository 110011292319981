import { Dictionary } from "src/app/shared/interfaces/dictionary.interface";


const noWhitespaceRegex = /\s/g;

const backgroundStyle = `
    position: relative;
    border-color: red;
    max-width: 550px;
    width: 450px;
    max-height: 325px;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    margin-right: 0.75rem;
    font-family: Poppins;
    font-weight: 400;
`.replace(noWhitespaceRegex, '');

const headerStyle = `
    text-align: center;
    margin-bottom: 0.25rem;
    font-size: 1rem;
    font-weight: 600;
`.replace(noWhitespaceRegex, '');

const listItem = `
    width: 100%;
    display: grid;
    grid-template-columns: 20% 75%;
`.replace(noWhitespaceRegex, '');


export const createInfoWindowLayout = (header: string, info: Dictionary) =>
     /*html*/`
    <div style=${backgroundStyle}>
        <h1 style=${headerStyle}>${header}</h1>
        <ul>
            ${getLiElements(info)}
        </ul>
    </div>`;


const getLiElements = (info: Dictionary) =>
    Object.entries(info)
        .map(([key, value]) =>
                 /*html*/`
                 <li style=${listItem}>
                    <span>${key}:</span>
                    <span style="color:#9e9e9e;">${value}</span>
                </li>`
        )
        .join("")


export const createErrorLayout = (error: string) =>
        /*html*/`
       <div style="color:red">
           ${error}
       </div>`;

