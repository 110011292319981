
import { Pipe, PipeTransform } from '@angular/core';
import { DataHandler } from '../../data-handler/data-handler';

@Pipe({ name: 'yesNo' })
export class YesNoPipe implements PipeTransform {

  transform(value: string | number | boolean, strict = true) {
    if (!DataHandler.isDefined(value) && strict) {
      return;
    }

    return value ? "yes" : "no";
  }

}
