import { NormalSiteFeatureCollection } from "src/app/shared/services/google-maps/assets/map-site.interfaces";
import { Utils } from "src/app/Utils";


export class Initialize {
    static readonly type = Utils.Helpers.Type("[NormalSite: Init] Initialize");
}

export class CheckStorage {
    static readonly type = Utils.Helpers.Type("[NormalSite: Check] Check storage");
}

export class FetchFromStorage {
    static readonly type = Utils.Helpers.Type("[NormalSite: Fetch] Fetch from storage");
}

export class FetchOldDataFromStorage {
    static readonly type = Utils.Helpers.Type("[NormalSite: Fetch] Fetch old data from storage");
    constructor(public error: any) { }
}

export class FetchFromStorageSuccess {
    static readonly type = Utils.Helpers.Type("[NormalSite: Fetch] Fetch from storage success");
    constructor(public payload: NormalSiteFeatureCollection) { }
}

export class Fetch {
    static readonly type = Utils.Helpers.Type("[NormalSite: Fetch] Fetch");
}

export class FetchSuccess {
    static readonly type = Utils.Helpers.Type("[NormalSite: Fetch] Fetch success");
    constructor(public payload: NormalSiteFeatureCollection) { }
}

export class FetchFail {
    static readonly type = Utils.Helpers.Type("[NormalSite: Fetch] Fetch fail");
    constructor(public error: any) { }
}

export class SetError {
    static readonly type = Utils.Helpers.Type("[NormalSite: Set] Set Error");
    constructor(public error: any) { }
}
