import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ModalWindowService } from '../../modal-window/modal-window.service';

@Component({
  selector: 'app-file-uploader',
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.scss']
})

export class FileUploaderComponent {

  @Output() newFiles = new EventEmitter<File[]>();
  @Output() close = new EventEmitter<boolean>();
  @Input() acceptedFormats = "image/jpeg,image/jpg,image/png,image/gif,.geojson,.json,.pdf,.xlsx,.csv,.mp3";
  @Input() canBeMultiple = true;
  @Input() header = "attach file";
  @Input() fullModal = true;
  isValidFile: boolean;

  files: File[] = [];

  constructor(private modal: ModalWindowService) { }


  getRejectedFileNames(rejectedFiles: File[]) {
    let fileNameString = "";
    rejectedFiles.forEach(file => {
      fileNameString += `, ${file?.name}`;
    });
    return fileNameString.replace(", ", "") + ".";
  }

  onSelect(event) {
    if (event?.addedFiles?.length > 0) {
      this.files.push(...event?.addedFiles);
    } else if (event?.rejectedFiles?.length > 0) {
      this.modal.showError("these file/s were rejected: " +
        this.getRejectedFileNames(event?.rejectedFiles));
    }
  }

  onRemove(event) {
    this.files.splice(this.files.indexOf(event), 1);
  }

  addAttachment() {
    this.newFiles.next(this.files);
  }

  onClose() {
    this.close.next();
  }


}
