import { Pipe, PipeTransform } from "@angular/core";
import { SFValidators } from "../../functions/sf-validators";

@Pipe({ name: 'hideString' })
export class HideStringPipe implements PipeTransform {
  transform(value: string | number, [amount = 3, character = '#']: [number, string]) {

    if (SFValidators.isNotDefined(value)) {
      return null;
    }

    return value?.toString().slice(0, -amount) + character.repeat(amount);

  }
}
