/* eslint-disable @typescript-eslint/no-explicit-any */
import { PopupError } from "src/app/core/handlers/popup-error";
import { AbstractControl, ValidationErrors, ValidatorFn as AngularValidatorFn } from '@angular/forms';
import moment from "moment";
import { NullLike } from "../../types/null-like.type";


type ValidatorFn<T> = (v: T[keyof T]) => boolean;

/**
 * Returns all the keys of an object that pass the given validation function.
 */
export const validateFields = <T extends Record<string, any>>(obj: T, validatorFn: ValidatorFn<T>) => {
    return Object
        .keys(obj)
        .filter(key => validatorFn(obj[key]));
};

export const isDefined = <T>(value: T | NullLike): value is T => value !== null && value !== undefined;

export const isNotDefined = <T>(value: T | NullLike): value is NullLike => !isDefined<T>(value);

export const hasAllFields = <T extends Record<string, any>>(obj: T) => validateFields(obj, isDefined)?.length === Object.keys(obj)?.length;

export const warnAboutMissingFields = <T extends Record<string, any>>(obj: T, warning: string) => {
    const nullFields = validateFields(obj, isNotDefined);
    if (!nullFields.length) {
        return obj;
    }

    const message = `${warning}. These fields are missing: ${nullFields.join(", ")}.`;
    throw new PopupError(message, false);
}

export const oneTrue = (bools: boolean[]) => Boolean(bools.filter(bool => bool).length);
export const allTrue = (bools: boolean[]) => Boolean(bools.filter(bool => bool).length === bools.length);

// TODO @Stefan - the type check breaks the code
// export const hasProperty = <T = any>(obj: T, property: keyof T): boolean => {
export const hasProperty = (obj: any, property: string | number): boolean => {
    if (isNotDefined(obj)) {
        return false;
    }
    return Object.prototype.hasOwnProperty.call(obj, property);
}


export function noSpecialCharsValidator(exception: string[] = []): AngularValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const format = /[`!@#$%^&*()_+=[\]{};':"\\|,.<>/?~]/g;
        if (format.test(control.value)) {
            let matchesExempted = false;
            const matches: string[] = control.value.match(format);
            if (matches) matchesExempted = matches.every(value => exception.includes(value));
            return matchesExempted ? null : { specialChars: true };
        }
        return null;
    };
}


export function yymmddValidator(): AngularValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
        const value = control.value as string;
        if (value && value.length >= 6) {

            const datePart = value.substring(0, 6);
            let year = parseInt(value.substring(0, 2), 10);
            const month = parseInt(value.substring(2, 4), 10);
            const day = parseInt(value.substring(4, 6), 10);
            const parsedDate = new Date(datePart.replace(/(\d{2})(\d{2})(\d{2})/, '$1-$2-$3'));

            if (year <= parseInt(moment().format('YY'))) {
                year += 2000;
            } else {
                year += 1900;
            }

            const newDateOfBirth = new Date(year, month - 1, day);
            const isValidDate = !isNaN(parsedDate.getTime());

            const isOlderThan18 = moment().diff(newDateOfBirth, 'years') >= 18;
            return (!isValidDate || !isOlderThan18) ? { idValidation: true } : null;

        }
        return null;
    };
}

export function phoneNumberValidator(): AngularValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const format = /^((27)|0){1,2}[0-9]{9,13}$/g;
        const isPhoneNumber = format.test(control.value)

        return isPhoneNumber ? null : { phoneNumberValidation: true }

    };
}

export function optionalPhoneNumberValidator(): AngularValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const { value } = control

        if (!value) {
            return
        }

        const format = /^((27)|0){1,2}[0-9]{9,13}$/g;
        const isPhoneNumber = format.test(value)

        return isPhoneNumber ? null : { phoneNumberValidation: true }

    };
}
