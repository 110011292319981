import moment from "moment";
import { BillCyclePeriod } from "src/app/shared/interfaces/smartsub-data.interface";
import { Monad } from "src/app/Utils/functional/monads/monads";
import { ExtendedWallet, ExtendedWalletUnitType, Wallet, WalletExpiry, WalletName, WalletTopupType, WalletTopupTypeWithPrimary, WalletUnitType } from "../../interfaces/wallet.interfaces";
import { formatDate as AngularFormatDate } from '@angular/common';
import { Utils } from "src/app/Utils";
import { ONE_GIGABYTE_IN_BYTES } from "src/app/constants";
import { WalletData } from "../../interfaces/wallet-data.interface";
import { formatNumber } from "@angular/common";
import { SA_LOCALE } from 'src/app/constants';


export const getMinAndSecFromMin = (mins: number) => {
  const wholeMinutes = Math.floor(mins);
  const seconds = Math.round((mins - wholeMinutes) * 60);
  return { min: wholeMinutes, sec: seconds };
}


export const formatMinSecTime = (mins: number): string => {
  const { min, sec } = getMinAndSecFromMin(mins);
  return `${min}m ${sec}s`;
}

//TODO: this would make more sense as an object
export const getDefaultWalletData = (): WalletData => {

  const getBasic = () => ({
    data: null,
    sms: null,
    voice: null
  });

  return {
    sub: getBasic(),
    prePaid: getBasic(),
    totals: {
      data: {
        amount: 0,
        formattedAmount: "0 GB",
        formatFn: amount => `${formatNumber((amount / 1024), SA_LOCALE, "1.2-2")} GB`
      },
      sms: {
        amount: 0,
        formattedAmount: "0 SMS",
        formatFn: amount => `${amount} SMS`
      },
      voice: {
        amount: 0,
        formattedAmount: "0m 0s",
        formatFn: formatMinSecTime
      },
    }
  }
}

const PLURAL_UNIT_MAP: { [key in ExtendedWalletUnitType]: string } = {
  R: "Rands",
  ZAR: "ZAR",
  MB: "MBs",
  SMS: "SMSes",
  Min: "Mins",
};

const DESCRIPTION_MAPPING: { [key in WalletTopupType]: string } = {
  data: "gigs",
  voice: "mins",
  sms: "smses"
}

const TOPABLE_WALLET_NAMES = ["primary0", "data0", "sms0", "voice0"] as const;
export type TopableWalletName = typeof TOPABLE_WALLET_NAMES[number];

const TOPUP_UNIT_RATIO_MAP: {
  [key in TopableWalletName]: number
} = {
  primary0: 60,
  voice0: 60,
  sms0: 1,
  data0: ONE_GIGABYTE_IN_BYTES,
}

const WALLET_TYPE_MAP: { [key in WalletUnitType]: WalletTopupTypeWithPrimary } = {
  ZAR: "primary",
  MB: "data",
  SMS: "sms",
  Min: "voice"
}

export const formatWallet = (wallets: Wallet[], cyclePeriod: BillCyclePeriod): ExtendedWallet[] => {

  return wallets
    .filter(wallet => wallet?.name !== "uncapped")
    .map(wallet => {
      const { name, available, expiry, quantity, unit_type } = wallet ?? {};
      const { start, end } = Utils.Formatters.getBillCycleDates(cyclePeriod);

      const isZar = unit_type === "ZAR";
      const total = convertAmount(quantity);
      const remaining = convertAmount(available);
      const unit = isZar ? "R" : unit_type;
      const units = getPluralUnit(unit);
      const type = getWalletType(unit_type);
      const description = DESCRIPTION_MAPPING?.[type];

      return {
        name,
        smallHeader: `MY ${getFormattedWalletName(name)}`,
        measurement: units,
        timelineStart: walletHasDates(name) ? null : formatDate(start),
        timelineEnd: formatExpiry(name, expiry, end),
        total: remaining > total ? remaining : total,
        remaining,
        unit,
        units,
        unitPosition: isZar ? "front" : "back",
        canTopup: TOPABLE_WALLET_NAMES.includes(<TopableWalletName>name),
        type,
        description
      }
    });
}

const getWalletType = (unit_type: WalletUnitType) => WALLET_TYPE_MAP?.[unit_type];

export const convertToTopupUnits = (walletName: TopableWalletName, units: number) =>
  units * TOPUP_UNIT_RATIO_MAP[walletName];

export const convertFromTopupUnits = (walletName: TopableWalletName, units: number) =>
  units / TOPUP_UNIT_RATIO_MAP[walletName];


export const getFormattedWalletName = (walletName: WalletName) =>
  walletName
    ?.toUpperCase()
    ?.replace("_", " ")
    ?.replace("0", "");

//Just return unit as is if it is not found
export const getPluralUnit = (unitType: ExtendedWalletUnitType) => PLURAL_UNIT_MAP[unitType] ?? unitType;


const walletHasDates = (walletName: WalletName) => (walletName === "primary" || !walletName?.includes("sub"));

const formatExpiry = (walletName: WalletName, expiry: WalletExpiry, billCycleEndDate: moment.Moment): string | null => {
  if (walletHasDates(walletName)) {
    return null;
  }

  if (!expiry || expiry === "NOT SET") {
    return formatDate(billCycleEndDate);
  }
  return getMonthFormat(<string>expiry);
}

export const formatDate = (moment: moment.Moment) =>
  new Monad.Maybe(moment)
    .bind(getDateString)
    .bind(getMonthFormat)
    .value;


const getDateString = (moment: moment.Moment) => moment.toISOString();

const getMonthFormat = (dateString: string) => AngularFormatDate(dateString, "d MMM", "en-za");

const convertAmount = (amount: string | number) => Number.isNaN(+amount) ? 0 : +amount;

