import { Utils } from "src/app/Utils";

export class OptIn {
  static readonly type = Utils.Helpers.Type('[Whatsapp Notifications] - Opt in for whatsapp');
  constructor(public customerId: string, public optIn: boolean) { }
}

export class OptInSuccess {
  static readonly type = Utils.Helpers.Type('[Whatsapp Notifications] - Opt in for whatsapp success');
  constructor(public optIn: boolean) { }
}

export class OptInFail {
  static readonly type = Utils.Helpers.Type('[Whatsapp Notifications] - Opt in for whatsapp fail');
  constructor(public error: unknown) { }
}
