<sf-popup-modal
    [isOpen]="true"
    [limitHeight]="true"
    (close)="onClose()"
    *ngIf="viewModel$ | async as viewModel"
>
    <ng-container header>
        call backs for today
    </ng-container>

    <ng-container mainContent>

        <sf-loader-template
            [loading]="viewModel.loading"
            [hasData]="viewModel.data?.length > 0"
            [error]="viewModel.error"
            [useDefaultError]="true"
        >

            <div
                loading
                class="content spinner-wrapper"
            >
                <small-text-spinner size="2.5rem">
                    loading..
                </small-text-spinner>
            </div>

            <ng-container dataContent>

                <div class="scrollable neat-table table-height">
                    <table>
                        <thead>
                            <tr>
                                <td>time</td>
                                <td>customer email</td>
                                <td>status</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                *ngFor="let callback of viewModel.data ?? []"
                                class="selectable-row pointer"
                                [class.selected]="callback?.id === viewModel.selectedId"
                                (click)="gotoCustomerPage(callback)"
                            >
                                <td>{{ callback?.scheduled_date_time | date: 'HH:mm' }}</td>
                                <td>{{ callback?.recipient_ref }}</td>
                                <td>{{ callback?.status | formatCallbackStatus}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div
                    class="small-spinner"
                    *ngIf="viewModel.silentlyLoading; else notSilentlyLoading"
                >
                    <small-text-spinner size="1.5rem">
                        loading..
                    </small-text-spinner>
                </div>


                <ng-template #notSilentlyLoading>
                    <div
                        class="load-more pointer"
                        (click)="loadMore()"
                    >load more</div>
                </ng-template>

            </ng-container>

            <div
                errorContent
                class="content error-content"
            >
                {{viewModel.error}}
            </div>

            <div
                noDataContent
                class="content no-data"
            >
                no callbacks for today
            </div>

        </sf-loader-template>

    </ng-container>

    <ng-container buttons>
        <sf-rain-button
            [isDeclineButton]="true"
            [isSolid]="true"
            (click)="onClose()"
        >
            close
        </sf-rain-button>
    </ng-container>

</sf-popup-modal>