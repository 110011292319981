import { LatLngLiteral } from '@google/maps';
import { Utils } from 'src/app/Utils';


// Fetch actions //
export class FetchCellRadioData {
    static readonly type = Utils.Helpers.Type('[CoverageData: Fetch] Fetch DA cell radio experience data');
    constructor(public enodebId: number, public cellId: number, public imsi: string) { }
}

export class FetchUserRadioData {
    static readonly type = Utils.Helpers.Type('[CoverageData: Fetch] Fetch DA user radio experience data');
    constructor(public enodebId: number, public cellId: number, public imsi: string) { }
}

export class FetchSickcellData {
    static readonly type = Utils.Helpers.Type('[CoverageData: Fetch] Fetch sickcell data');
    constructor(public enodebId: number, public cellId: number) { }
}

export class FetchDaOcularIpData {
    static readonly type = Utils.Helpers.Type('[CoverageData: Fetch] Fetch DA ocular-ip data');
    constructor(public siteId: string) { }
}


export class FetchUserOnRegularSite {
    static readonly type = Utils.Helpers.Type('[CoverageData: Fetch] fetch da user on regular site data');
    constructor(public payload: { enodebId: number, cellId: number, imsi: string }) { }
}

export class FetchDaUserKeepsReconnecting {
    static readonly type = Utils.Helpers.Type('[CoverageData: Fetch] fetch da user keeps reconnecting');
    constructor(public imsi: string) { }
}

export class FetchAlarmsInAreaForSite {
    static readonly type = Utils.Helpers.Type('[CoverageData: Fetch] fetch alarms in area for site');
    constructor(public coordinates: number[], public tech: "4G" | "5G") { }
}

// Set actions //

export class SetCustomerAddressLatLng {
    static readonly type = Utils.Helpers.Type('[CoverageData: Set] Set the current lnglat');
    constructor(public latLng: LatLngLiteral) { }
}

export class InitializedCurrentDevice {
    static readonly type = Utils.Helpers.Type('[CoverageData: Set] Initialized current device');
}

export class ClearCoverageData {
    static readonly type = Utils.Helpers.Type('[CoverageData: Clear] clear coverage data');
}

export class NoMatchingEnodeBIDfound {
    static readonly type = Utils.Helpers.Type('[CoverageData: Fail] no matching enodebid was found in feature');
    constructor(public error: string) { }
}
