import { Pipe, PipeTransform } from '@angular/core';
import { SFValidators } from '../../functions/sf-validators';

@Pipe({ name: 'multiply' })
export class MultiplyPipe implements PipeTransform {

    transform(value: number, multiplyArg: number): number {
        if (SFValidators.isNotDefined(value) || Number.isNaN(+value)) {
            return value;
        }

        return +value * multiplyArg;
    }
}