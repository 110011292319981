<ng-container *ngIf="loading; else loaded">
    <ng-content select="[loading]"></ng-content>
</ng-container>

<ng-template #loaded>

    <ng-container *ngIf="hasData; else noData">
        <ng-content select="[dataContent]"></ng-content>
    </ng-container>

    <ng-template #noData>

        <ng-container *ngIf="useDefaultError; else notDefaultError">
            <div class="no-data-container">
                <div class="red" *ngIf="error; else hasNoError">
                    {{error}}
                </div>

                <ng-template #hasNoError>
                    <div class="text-grey" [class.red]="noDataRed">
                        {{noDataMessage}}
                    </div>
                </ng-template>
            </div>
        </ng-container>

        <ng-template #notDefaultError>
            <ng-container *ngIf="error || useErrorTemplate; else noError">
                <ng-content select="[errorContent]"></ng-content>
            </ng-container>

            <ng-template #noError>
                <ng-content select="[noDataContent]"></ng-content>
            </ng-template>
        </ng-template>

    </ng-template>

</ng-template>