import { DataHandler } from "src/app/shared/data-handler/data-handler";
import { formatDate } from '@angular/common';


export function epochToDate(value: number, dateFormat: string){
  if(!DataHandler.isDefined(value) || isNaN(value)){
    return;
  }

  return formatDate(new Date(value), dateFormat, 'en-za');
}
