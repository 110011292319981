import { Selector } from "@ngxs/store";
import { CatalogSimProduct } from "src/app/core/store/interfaces/product-catalog.interfaces";
import { ProductCatalogState } from "src/app/core/store/state/product-state/product-catalog.state";
import { CUSTOMER_SERVICE_STATUS } from "src/app/shared/constants/service-status.constant";
import { SFValidators } from "src/app/shared/functions/sf-validators";
import { Dictionary } from "src/app/shared/interfaces/dictionary.interface";
import { CustomerService } from "src/app/shared/interfaces/smartsub-data.interface";
import { ExtendedServiceSelectors } from "./extended-service.selector";


export class LegacySelectors {

  @Selector([
    ExtendedServiceSelectors.getServices,
    ProductCatalogState.getProductsByTypeMappedById("sim")
  ])
  static has5gLegacyService(
    services: CustomerService[],
    simProductDict: Dictionary<CatalogSimProduct>
  ) {

    return services?.some(service => {
      const { product_id, status } = service ?? {}
      const { category, config } = simProductDict?.[product_id] ?? {};
      const { legacy } = config ?? {};

      const is5G = category === '5G';
      const isLegacy = SFValidators.isDefined(legacy) || legacy;

      const isCancelledService = SFValidators.isDefined(status) && status === CUSTOMER_SERVICE_STATUS["Service Canceled"];

      return is5G && isLegacy && !isCancelledService;
    });
  }

  @Selector([
    ExtendedServiceSelectors.getServices,
    ProductCatalogState.getProductsByTypeMappedById("sim")
  ])
  static has4gService(
    services: CustomerService[],
    simProductDict: Dictionary<CatalogSimProduct>
  ) {

    return services?.some(service => {
      const { product_id, status } = service ?? {}
      const { category } = simProductDict?.[product_id] ?? {};

      const is4G = category === '4G';

      const isCancelledService = SFValidators.isDefined(status) && status === CUSTOMER_SERVICE_STATUS["Service Canceled"];

      return is4G && !isCancelledService;
    });
  }

}
