<sf-popup-modal [isOpen]="true" [hasCloseButton]="false" [autoClose]="false"
  [successResponse]="modalOptions.successResponse" [sending]="modalOptions.sending"
  [spinnerMessage]="modalOptions.spinnerMessage" [limitHeight]="true">

  <ng-container header>post ticket form</ng-container>

  <ng-container mainContent>
    <sf-modal-form-container [formGroup]="form">
      <div class="checkbox-div">
        <sf-form-checkbox [control]="this.form.get('correct_classification')"
          [label]="'Was the ticket related to ' + classificationMessage + '?'">
        </sf-form-checkbox>
      </div>
      <div class="select-options">


        <sf-rain-multi-select label="network" [options]="networkSelectOptions" labelStyle="thin-font-no-gap"
          (onOptionSelect)="onSelectOptions('network',$event)">
        </sf-rain-multi-select>
        <sf-rain-multi-select *ngIf="otherOptionsSelected['msisdns']" label="selected msisdns" [options]="msisdnOptions"
          labelStyle="thin-font-no-gap" (onOptionSelect)="onSelectMSISDN($event)"
          [control]="form?.get('msisdns')"></sf-rain-multi-select>
        <div>
          <sf-form-input *ngIf="otherOptionsSelected['network_other']" label="other network reasons" placeholder="other"
            [control]="form?.get('network_other')">
          </sf-form-input>
        </div>

        <sf-rain-multi-select label="billing" [options]="billingSelectOptions" labelStyle="thin-font-no-gap"
          (onOptionSelect)="onSelectOptions('billing',$event)">
        </sf-rain-multi-select>
        <sf-select *ngIf="this.otherOptionsSelected['account_in_arrears']" label="account arrears reason"
          [options]="accountInArrearsOptions" labelStyle="thin-font-no-gap"
          (onOptionSelect)="onSelectOptions('account_arrears',$event)">
        </sf-select>
        <sf-select *ngIf="this.otherOptionsSelected['billing_disputes']" label="billing dispute reason"
          [options]="billingDisputesOptions" labelStyle="thin-font-no-gap"
          (onOptionSelect)="onSelectOptions('billing_disputes',$event)">
        </sf-select>
<sf-select
  *ngIf="this.otherOptionsSelected['refund']"
  label="refund reasons"
  [options]="refundOptions"
  labelStyle="thin-font-no-gap"
  (onOptionSelect)="onSelectOptions('refund',$event)"
>
</sf-select>

<sf-select
  *ngIf="this.otherOptionsSelected['allocation']"
  label="allocation reasons"
  [options]="allocationOptions"
  labelStyle="thin-font-no-gap"
  (onOptionSelect)="onSelectOptions('allocation',$event)"
>
</sf-select>

<sf-select
  *ngIf="this.otherOptionsSelected['profile_management']"
  label="profile management reasons"
  [options]="profileManagementOptions"
  labelStyle="thin-font-no-gap"
  (onOptionSelect)="onSelectOptions('profile_management',$event)"
>
</sf-select>

        <div>
          <sf-form-input *ngIf="otherOptionsSelected['billing_other']" label="other billing reasons" placeholder="other"
            [control]="form?.get('billing_other')">
          </sf-form-input>
        </div>

        <sf-rain-multi-select label="device" [options]="deviceSelectOptions" labelStyle="thin-font-no-gap"
          (onOptionSelect)="onSelectOptions('device',$event)">
        </sf-rain-multi-select>

        <sf-form-input *ngIf="otherOptionsSelected['device_other']" label="other device reasons"
          placeholder="other device reasons" [control]="form?.get('device_other')">
        </sf-form-input>

        <sf-rain-multi-select label="onboarding reasons" [options]="onboardingSelectOptions"
          labelStyle="thin-font-no-gap" (onOptionSelect)="onSelectOptions('onboarding',$event)">
        </sf-rain-multi-select>

        <div>
          <sf-form-input *ngIf="otherOptionsSelected['onboarding_other']" label="other onboarding reasons"
            placeholder="other onboarding reasons " [control]="form?.get('onboarding_other')">
          </sf-form-input>

          <div class="group-together">
            <sf-rain-multi-select label="business operations" [options]="businessOperationsSelectOptions"
              labelStyle="thin-font-no-gap" (onOptionSelect)="onSelectOptions('business_operations',$event)">
            </sf-rain-multi-select>
            <div>
              <sf-form-input *ngIf="otherOptionsSelected['business_operations_other']"
                label="other business operations reasons" placeholder="other business operations reasons"
                [control]="this.form?.get('business_operations_other')">
              </sf-form-input>
            </div>
          </div>
        </div>

      </div>

      <div class="spacer"></div>
      <p class="red error" *ngIf="!isAnyOptionSelected">Atleast one option needs to be selected.</p>
    </sf-modal-form-container>

  </ng-container>
  <ng-container buttons>
    <button class=" rain-button default-button-size send-button solid" (click)="onSend()"
      [disabled]="!form || form?.invalid || modalOptions.sending || !isAnyOptionSelected ||(this.otherOptionsSelected['msisdns'] && this.selectedMSISDNs.length<1)">
      send
    </button>
  </ng-container>
</sf-popup-modal>
