import { ChatJsonNote } from "src/app/interactions/chat-interaction/components/chat-json-note/assets/chat-json-note.interface";
import { ShortTicket } from "src/app/shared/customer-ticket/interfaces/customer-ticket.interface";
import { DataHandler } from "src/app/shared/data-handler/data-handler";



export function sortTickets(tickets: ShortTicket[]) {
    return tickets?.sort((a, b) => DataHandler.sortByDate(a?.updated_at, b?.updated_at, "newestFirst"));
}

export function getTicketAgeInHours(shortTicket: ShortTicket): number {
    const date = new Date(shortTicket?.updated_at);
    return (Date.now() - date.getTime()) / (1000 * 60 * 60);
}

export function getChatJsonNote<T = Record<string, unknown>>(comment: string): ChatJsonNote<T> | null {
    try {
        const json: ChatJsonNote<T> = JSON.parse(comment);
        if (!json?.detail || !json?.message) {
            return null;
        }
        return json;
    }
    catch {
        return null;
    }
}

