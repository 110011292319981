import { Pipe, PipeTransform } from '@angular/core';
import { TypeICouldBe } from '../../interfaces/type-I-could-be.type';


@Pipe({ name: 'formatType' })
export class FormatTypePipe implements PipeTransform {

  transform(data: unknown, type?: TypeICouldBe) {

    if (type === 'nullLike' || type === 'emptyString' || type === 'emptyObject' ) {
      return 'no data';
    }

    if (type === 'number' || type === 'string') {
      return data;
    }

    if (type === 'boolean') {
      return data ? "yes" : "no";
    }

    if (type === 'array' || type === 'object') {
      return;
    }
  }

}
