import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext, Store } from "@ngxs/store";
import { ShowContinueButton } from "src/app/interactions/store/actions/continue-flow.actions";
import { RainAgent } from "src/app/shared/components/agent/rain-agent.service";
import { CustomerTicket, ShortTicket } from "src/app/shared/customer-ticket/interfaces/customer-ticket.interface";
import { InteractionActions } from "../actions/interaction-actions";
import { InteractionType } from "../interfaces/interaction-type.type";


export interface InteractionsStateModel {
    createdAutoTicket: boolean;
    initializedTicket: CustomerTicket | ShortTicket;
    active: boolean;
    interactionType: InteractionType
}

@State<InteractionsStateModel>({
    name: 'interactions',
    defaults: {
        createdAutoTicket: false,
        initializedTicket: null,
        active: false,
        interactionType: "ticket"
    },
})
@Injectable()
export class InteractionsState {

    @Selector()
    static getInteractionType(state: InteractionsStateModel) { return state.interactionType; }

    @Selector()
    static createdAutoTicket(state: InteractionsStateModel) { return state.createdAutoTicket; }

    @Selector()
    static isActive(state: InteractionsStateModel) { return state.active; }

    @Selector()
    static getInitializedTicket(state: InteractionsStateModel) { return state.initializedTicket }

    constructor(private store: Store,
        private rainAgent: RainAgent) { }

    @Action(InteractionActions.ContinueFromPostTicketModal)
    continueFromPostTicketModal(_ctx: StateContext<InteractionsStateModel>, action: InteractionActions.ContinueFromPostTicketModal) {

        this.store.dispatch(new ShowContinueButton(true));

    }

    @Action(InteractionActions.CreatedAutoTicket)
    createdAutoTicket(ctx: StateContext<InteractionsStateModel>, action: InteractionActions.CreatedAutoTicket) {
        ctx.patchState({
            createdAutoTicket: action.bool,
        });
    }

    @Action(InteractionActions.SetIsActive)
    SetIsActive(ctx: StateContext<InteractionsStateModel>, action: InteractionActions.SetIsActive) {
        ctx.patchState({
            active: action.active,
        });
    }

    @Action(InteractionActions.InteractionTicketInit)
    interactionTicketInit(ctx: StateContext<InteractionsStateModel>, action: InteractionActions.InteractionTicketInit) {
        ctx.patchState({
            initializedTicket: action.payload
        });
    }

    @Action(InteractionActions.ClearInitializedTicket)
    clearInitializedTicket(ctx: StateContext<InteractionsStateModel>) {
        ctx.patchState({
            initializedTicket: null
        });
    }

    @Action(InteractionActions.SetInteractionType)
    SetInteractionType(ctx: StateContext<InteractionsStateModel>, action: InteractionActions.SetInteractionType) {
        ctx.patchState({
            interactionType: action.type
        });
    }
}
