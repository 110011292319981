import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-detailed-card',
  templateUrl: './detailed-card.component.html',
  styleUrls: ['./detailed-card.component.scss']
})
export class DetailedCardComponent {

  @Input() header: string;
  @Input() rightHeader: string;
  @Input() fullContent = false;

  @Input() showDogEar = false;
  @Input() dogEarMessage: string;
}
