import { Component, Input } from '@angular/core';
import { RouterState } from '@ngxs/router-plugin';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { ToggleModalByName } from 'src/app/core/store/actions/modal.actions';
import { GotoSalesForKnownCustomer } from 'src/app/customer-info-summary-page/store/actions/customer.actions';
import { CustomerInfoState } from 'src/app/customer-info-summary-page/store/state/customer-info.state';
import { SFValidators } from 'src/app/shared/functions/sf-validators';
import { SipPhoneService } from 'src/app/sip-phone/services/sip-phone.service';
import { InteractionButtonsSelectors, InteractionButtonsViewModel } from './selectors/interaction-buttons-selectors';
import { SalesCartActions } from 'src/app/sales/store/action/sales-cart-actions';
import { DigitalIdentityByUserIdSelectors } from 'src/app/customer-data-components/axiom/digital-identity/store/selectors/digital-identity-by-user-id.selectors';
import { InteractionTicketsState } from '../store/state/interaction-tickets.state';
import { PopupError } from 'src/app/core/handlers/popup-error';
import { NO_SELECTED_TICKET_MESSAGE } from '../assets/interaction.constants';

@Component({
    selector: 'app-interaction-buttons',
    templateUrl: './interaction-buttons.component.html',
    styleUrls: ['./interaction-buttons.component.scss']
})
export class InteractionButtonsComponent {

    @Select(InteractionButtonsSelectors.getViewModel) viewModel$: Observable<InteractionButtonsViewModel>

    @Input() showCart = false;

    readonly buttonSize = "3.5rem";

    constructor(
      private store: Store,
      private sipPhoneService: SipPhoneService,
    ) { }

    onCallCustomer() {
        const customerNumber = this.store.selectSnapshot(CustomerInfoState.getCustomer)?.phone;
        this.sipPhoneService.sendCallSubjectMessage("call_number", customerNumber);
    }

    onShoppingCart() {
        const { product } = this.store.selectSnapshot(RouterState)?.state?.queryParams ?? {}

        const isWork = product === 'work'
        const isMobile = product === 'mobile'

        const email = this.store.selectSnapshot(CustomerInfoState.getCustomerEmail)

        const isWorkSelected = isWork || email?.endsWith('_sme')

        const showModal = SFValidators.isNotDefined(product) && !isWorkSelected

        const currentHexId = this.store.selectSnapshot(InteractionTicketsState.getCurrentHexId)

        if(!currentHexId){
          throw new PopupError(NO_SELECTED_TICKET_MESSAGE)
        }

        return this.store.dispatch(
          showModal
          ? new ToggleModalByName('select_product_modal', true)
          : [
            new SalesCartActions.SetWorkProductSelected(isWorkSelected),
            new SalesCartActions.SetMobileProductSelected(isMobile),
            new SalesCartActions.SetOnePackSelected(isMobile),
            new GotoSalesForKnownCustomer("sales-cart")
          ]
        );
    }

}
