import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DndDirective } from './dnd/directives/dnd.directive';
import { MakeDroppable } from './dnd/directives/dnd-droppable.directive';
import { DndMakedraggableDirective } from './dnd/directives/dnd-makedraggable.directive';

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [
    DndDirective,
    MakeDroppable,
    DndMakedraggableDirective,
  ],
  exports: [
    DndDirective,
    MakeDroppable,
    DndMakedraggableDirective,
  ]
})
export class UtilsModule { }
