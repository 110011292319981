import { SnowflakeSelectOption } from "src/app/shared/elements/rain-forms/elements/snowflake-select/interfaces/rain-select-option.interface";

export const UPGRADE_STATUS_OPTIONS: SnowflakeSelectOption[] = [
    {
        label: 'successful',
        value: 'successful'
    },
    {
        label: 'unsuccessful',
        value: 'unsuccessful'
    },
];

export const REASON_OPTIONS: SnowflakeSelectOption[] = [
    {
        label: 'No contact made',
        value: 'No contact made'
    },
    {
        label: 'Looking for legacy Product',
        value: 'Looking for legacy Product'
    },
    {
        label: 'No Funds',
        value: 'No Funds'
    },
    {
        label: 'No coverage',
        value: 'No coverage'
    },
    {
        label: 'Product Information only',
        value: 'Product Information only',
    },
    {
        label: 'Billing Query',
        value: 'Billing Query'
    },
    {
        label: 'Networks Query',
        value: 'Networks Query'
    },
    {
        label: 'Logistics/Delivery Query',
        value: 'Logistics/Delivery Query'
    },
    {
        label: 'Voice Query',
        value: 'Voice Query'
    },
    {
        label: 'Queued for delivery',
        value: 'Queued for delivery',
    },
    {
        label: 'Delivered Already',
        value: 'Delivered Already',
    },
    {
        label: 'Duplicate ticket',
        value: 'Duplicate ticket',
    },
    {
        label: 'Too expensive',
        value: 'Too expensive',
    },
    {
        label: 'Cancellation',
        value: 'Cancellation',
    },
    {
        label: 'No RICA documents',
        value: 'No RICA documents ',
    },
    {
        label: 'Online banking error',
        value: 'Online banking error',
    },
];


export const SUCCESSFUL_UPGRADE_OPTIONS: SnowflakeSelectOption[] = [
    {
        label: 'Successful sale - ticket',
        value: 'Successful sale  ticket'
    },
    {
        label: 'Successful sale - inbound call',
        value: 'Successful sale - inbound call'
    },
    {
        label: 'VAS Sale - Level up',
        value: 'VAS Sale -Level up'
    },

    {
        label: 'VAS Sale - Speed up',
        value: 'VAS Sale - Speed up.',
    }
];


