import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { CustomerInfoSummaryDestroyed } from 'src/app/customer-info-summary-page/store/actions/customer.actions';
import { BasicStateUpdater } from 'src/app/shared/state-updaters/basic.state-updater';
import { BasicStateModel } from 'src/app/shared/state-updaters/types/basic-state-updater.types';
import { Subject } from 'rxjs';
import { CustomerServiceService } from 'src/app/customer-data-components/sim-details/store/services/customer-service.service';
import { MobileProductVasId, PossibleMobileProductVasIdResponse } from 'src/app/customer-info-summary-page/store/interfaces/mobile-product-vas-id-response.interface';
import { MobileProductVasIdActions } from '../actions/mobile-product-vas-id';
import { Dictionary } from 'src/app/shared/interfaces/dictionary.interface';
import { CatalogProduct } from 'src/app/core/store/interfaces/product-catalog.interfaces';
import { ProductCatalogState } from 'src/app/core/store/state/product-state/product-catalog.state';

export type MobileProductVasIdStateModel = BasicStateModel<PossibleMobileProductVasIdResponse>;

@State<MobileProductVasIdStateModel>({
  name: 'MobileProductVasIdState',
  defaults: BasicStateUpdater.getDefaultState()
})
@Injectable()
export class MobileProductVasIdState {

  @Selector()
  static isLoading(state: MobileProductVasIdStateModel) { return state.loading }

  @Selector()
  static isLoaded(state: MobileProductVasIdStateModel) { return state.loaded }

  @Selector()
  static getData(state: MobileProductVasIdStateModel) { return state.data?.result }

  @Selector([
    MobileProductVasIdState.getData,
    ProductCatalogState.getProductsMappedById,
  ])
  static getMobileLevel(
    data: MobileProductVasId,
    catalogDict: Dictionary<CatalogProduct>
  ) {
    return catalogDict?.[data?.vasId]?.config?.['level']
  }

  @Selector()
  static getError(state: MobileProductVasIdStateModel) { return state.error }

  private readonly _cancelRequest$ = new Subject();

  private readonly _stateUpdater = new BasicStateUpdater<PossibleMobileProductVasIdResponse, string>({
    cancelRequest$: this._cancelRequest$,
    errorMessage: "Failed to fetch services.",
    notFoundMessage: "No services.",
    baseFetchFn: (request: string) => this.customerServiceService.fetchMobileProductVasId(request),
    ...MobileProductVasIdActions,
  });

  constructor(private customerServiceService: CustomerServiceService) { }

  @Action(MobileProductVasIdActions.Fetch)
  Fetch(ctx: StateContext<MobileProductVasIdStateModel>, action: MobileProductVasIdActions.Fetch) {
    return this._stateUpdater.Fetch(ctx, action);
  }

  @Action(MobileProductVasIdActions.FetchSuccess)
  FetchSuccess(ctx: StateContext<MobileProductVasIdStateModel>, action: MobileProductVasIdActions.FetchSuccess) {
    this._stateUpdater.FetchSuccess(ctx, action);
  }

  @Action(MobileProductVasIdActions.FetchFail)
  FetchFail(ctx: StateContext<MobileProductVasIdStateModel>, action: MobileProductVasIdActions.FetchFail) {
    this._stateUpdater.FetchFail(ctx, action);
  }

  @Action([CustomerInfoSummaryDestroyed, MobileProductVasIdActions.Clear])
  Clear(ctx: StateContext<MobileProductVasIdStateModel>) {
    this._stateUpdater.Clear(ctx);
  }

}
