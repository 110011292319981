import { Selector, createSelector } from "@ngxs/store";
import { ExtendedCustomerService } from "src/app/customer-info-summary-page/store/interfaces/proxy-customer-service.interface";
import { CustomerResourceSelectors } from "src/app/customer-info-summary-page/store/selectors/customer-resource.selectors";
import { ExtendedServiceSelectors } from "src/app/customer-info-summary-page/store/selectors/extended-service.selector";
import { SimResource } from "src/app/shared/interfaces/sim-resource.interface";
import { RainAppResponse } from "../../assets/rain-app-response.interface";
import { SimCardDetails } from "../../assets/sim-card-details.interface";
import { SimState } from "../state/sim.state";
import { getServiceIDforMSISDN } from "src/app/Utils/helpers/service-details.helper";


export class SimSelectors {

    @Selector([
        SimState.getSelectedID,
        ExtendedServiceSelectors.getServices,
        CustomerResourceSelectors.getRainAppResponses,
        CustomerResourceSelectors.getSimResources])
    static getSelectedSimData(
        id: string,
        services: ExtendedCustomerService[],
        responses: RainAppResponse[],
        resources: SimResource[]): SimCardDetails {

        const service = ExtendedServiceSelectors.getServiceById(id)(services);
        const resource = CustomerResourceSelectors.getSimResourceById(id)(resources, service);
        const rainAppResponse = CustomerResourceSelectors.getRainAppResponseById(id)(responses, resource);

        return {
            service,
            rainAppResponse,
            resource
        }
    }

    @Selector([SimSelectors.getSelectedSimData])
    static getSelectedRainAppResponse(simData: SimCardDetails) { return simData?.rainAppResponse; }

    @Selector([SimSelectors.getSelectedSimData])
    static getSelectedSimResource(simData: SimCardDetails) { return simData?.resource; }



    @Selector([SimSelectors.getSelectedSimData])
    static selectedIMSI(simData: SimCardDetails) { return simData?.rainAppResponse?.imsi ?? simData?.resource?.imsi; }

    @Selector([SimSelectors.selectedIMSI])
    static hasSelectedIMSI(imsi: string) { return Boolean(imsi) }

    static getServiceIDforMSISDN(msisdn: string) {
        return createSelector(
            [ExtendedServiceSelectors.getServices],
            (services: ExtendedCustomerService[]) => {
                return getServiceIDforMSISDN(services, msisdn);
            }
        );
    }

    static getMSISDNforServiceID(id: string) {
        return createSelector(
            [CustomerResourceSelectors.getSimDataById(id)],
            (simData: SimCardDetails) => {
                return simData?.service?.service_reference
            }
        );
    }

    static getIMSIforServiceID(id: string) {
        return createSelector(
            [CustomerResourceSelectors.getSimDataById(id)],
            (simData: SimCardDetails) => {
                return simData?.rainAppResponse?.imsi ?? simData?.resource?.imsi
            }
        );
    }

    static getServiceIdforIMSI(imsi: string) {
        return createSelector(
            [CustomerResourceSelectors.getServiceByIMSI(imsi)],
            (service: ExtendedCustomerService) => service?.id
        );
    }

    static getMSISDNforIMSI(imsi: string) {
        return createSelector(
            [CustomerResourceSelectors.getRainAppResponseByIMSI(imsi)],
            (response: RainAppResponse) => response?.msisdn
        );
    }
}