import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { debounceTime, takeUntil, tap } from 'rxjs/operators';
import { GeocoderService } from 'src/app/shared/components/geocoder/geocoder.service';
import { FormHelper } from '../../helpers/form.helper';
import { validationMessages } from '../../pipes';
import { faSearch, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { AddressDetail, IAddress } from 'src/app/shared/elements/map/models/addressDetail.model';
import { Store } from '@ngxs/store';
import { RicaAuthorityState } from 'src/app/sales/sales-portal/rica-authority-page/rica-authority-store/rica-authority-state';

export interface AddressResult {
  address: IAddress,
  description: string;
}

@Component({
  selector: 'sf-address-search',
  templateUrl: './address-search.component.html',
  styleUrls: ['./address-search.component.scss']
})
export class FormAddressSearchComponent extends FormHelper implements OnInit, AfterViewInit {

  @ViewChild("blankDiv") blankDiv: ElementRef;

  @Output() onAddressSelect = new EventEmitter<AddressResult>();

  @Input() control: FormControl;
  @Input() messages = validationMessages;
  @Input() label: string;
  @Input() secondaryLabel: string;
  @Input() type = 'text';
  @Input() placeholder = 'type the address here...';
  @Input() autocomplete = 'address';
  @Input() focusInput = false;
  @Input() onlyBottomLine = false;
  @Input() autoSelect = false

  searchResults: google.maps.places.AutocompletePrediction[];
  search_control: FormControl;
  showResultsList = false;
  icon = faSearch;
  spinner = faSpinner;
  searching = false;
  previousSearch: string = ""

  constructor(
    private geocoderService: GeocoderService,
    private store: Store
  ) {
    super();
  }

  ngOnInit(): void {
    this.search_control = new FormControl(this.control.value);
  }

  ngAfterViewInit(): void {
    this.search_control.valueChanges
      .pipe(
        tap(() => this.searching = true),
        debounceTime(500),
        takeUntil(this.ngDestroy$)
      )
      .subscribe({
        next: (res) => {
          this.addressSearch(res)
        }
      })

      this.store.select(RicaAuthorityState.ricaAuthCompanyDetails)
        .pipe(takeUntil(this.ngDestroy$))
        .subscribe((res) => {
          if (res?.addressSearch && res?.addressSearch !== this.previousSearch && this.autoSelect) {
            this.previousSearch = res?.addressSearch;
            this.addressSearch(res?.addressSearch)
            const interval = setInterval(() => {
              if (this.searchResults?.length) {
                const { place_id, description } = this.searchResults?.[0];
                this.getAddress(place_id, description);
                clearInterval(interval)
                this.searchResults.length = null;
              }
            }, 200)

          }
        })
  }

  addressSearch(searchTerm: string) {
  if (!searchTerm) return;
  if (searchTerm !== this.control.value) this.showResultsList = true;
  this.geocoderService.onAutoComplete(searchTerm).subscribe({
    next: (response) => {
      this.searchResults = response
      this.searching = false
    },
    error: (error) => console.error(error),
  })
  }

  onSelectAddress(result) {
    this.searchResults = null;
    const { place_id, description } = result;
    this.search_control.patchValue(description);
    this.control.patchValue(description);
    this.showResultsList = false;
    this.getAddress(place_id, description);
  }

  getAddress(placeId: string, description: string) {
    const placesService = new google.maps.places.PlacesService(this.blankDiv.nativeElement);

    placesService.getDetails({ placeId: placeId }, (result) => {
      const address = AddressDetail.fromAddressComponents(result.address_components);
      address.gps_coordinates = {
        latitude: result.geometry.location.lat(),
        longitude: result.geometry.location.lng()
      }

      this.onAddressSelect.emit({ address: address, description: description });
      return address;
    });
  }
}
