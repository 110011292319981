import { createSelector } from "@ngxs/store";
import { CallbackSchedulerViewModel } from "./callback-scheduler-view-model.interface";
import { CallTimerState } from "src/app/sip-phone/store/state/call-timer.state";
import { ChatTicketState } from "src/app/interactions/store/state/chat-ticket.state";
import moment from "moment";
import { CALL_ENDED, SCHEDULED_CALLBACK } from "src/app/constants";
import { TicketNote } from "src/app/shared/customer-ticket/interfaces/customer-ticket.interface";

const MINIMUM_CALL_DURATION_IN_SECONDS = 10;
const MAX_CALLBACKS_PER_TICKET_PER_AGENT = 3;

export class CallbackSchedulerSelector {


    static isCallDurationCorrect(agentEmail: string) {

        return createSelector(
            [
                CallTimerState.getCurrentCallDurationInSeconds,
                CallTimerState.getLastCallDurationInSeconds,
                ChatTicketState.getAllTicketEventsForAgent(agentEmail)
            ],
            (currentCallTimeInSeconds: number, lastCallDuration: number, eventNotes: TicketNote[]): boolean => {

                if (currentCallTimeInSeconds > MINIMUM_CALL_DURATION_IN_SECONDS || lastCallDuration > MINIMUM_CALL_DURATION_IN_SECONDS) {
                    return true;
                }

                return eventNotes.some(note => {
                    const { comment } = note ?? {};

                    const isCallEndedComment = comment?.includes(CALL_ENDED);
                    if (!isCallEndedComment) {
                        return false;
                    }


                    const duration = +comment?.match(/Duration:\s(\d+)\sseconds/)?.[1];
                    return duration > MINIMUM_CALL_DURATION_IN_SECONDS;
                });
            }
        );

    }


    static exceedsMaxAllowedCallbacksPerTicket(agentEmail: string) {

        return createSelector(
            [ChatTicketState.getAllTicketEventsForAgent(agentEmail)],
            (eventNotes: TicketNote[]): boolean => {

                const scheduledCallbacksOnTicket = eventNotes
                    .filter(note => {

                        const { comment } = note ?? {};
                        const isCallbackTicket = comment?.includes(SCHEDULED_CALLBACK);

                        return isCallbackTicket;
                    })
                    .length;

                return scheduledCallbacksOnTicket >= MAX_CALLBACKS_PER_TICKET_PER_AGENT;

            }
        );

    }


    static hasCallOnCurrentTicket(agentEmail: string) {

        return createSelector(
            [ChatTicketState.getAllTicketEventsForAgent(agentEmail)],
            (eventNotes: TicketNote[]): boolean => {

                return eventNotes.some(note => {
                    const { inserted_at, comment } = note ?? {};

                    const isSameHour = moment(inserted_at).isSame(undefined, "hour");
                    const isCustomerCall = comment?.includes("Agent called") || comment?.includes("Incoming call");

                    return isSameHour && isCustomerCall;
                });
            }
        );

    }

    static getViewModel(agentEmail: string, isSalesAgent: boolean,) {

        return createSelector(
            [
                CallbackSchedulerSelector.isCallDurationCorrect(agentEmail),
                CallbackSchedulerSelector.hasCallOnCurrentTicket(agentEmail),
                CallbackSchedulerSelector.exceedsMaxAllowedCallbacksPerTicket(agentEmail)
            ],
            (isCallDurationCorrect: boolean, calledCustomerOnCurrentTicket: boolean, exceedsMaxAllowedCallbacksPerTicket: boolean): CallbackSchedulerViewModel => {

                const canScheduleCallback = isCallDurationCorrect && calledCustomerOnCurrentTicket && !exceedsMaxAllowedCallbacksPerTicket;

                //These rules should only apply to sales agents
                if (!isSalesAgent || canScheduleCallback) {
                    return {
                        canScheduleCallback: true,
                        notAllowedReason: null
                    }
                }

                let reason = "Exceeds maximum allowed callbacks for this ticket.";

                if (!isCallDurationCorrect || !calledCustomerOnCurrentTicket) {
                    reason = "Call duration with customer was not sufficient.";
                }

                return {
                    canScheduleCallback: false,
                    notAllowedReason: `Can not schedule callback. ${reason}`
                }
            }
        );

    }

}