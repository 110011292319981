
import { Pipe, PipeTransform } from '@angular/core';
import { Utils } from 'src/app/Utils';

@Pipe({ name: 'stripTag' })
export class StripHTMLtagPipe implements PipeTransform {

    transform(htmlString: string, tagLetter: string) {
        return Utils.Formatters.stripHTMLTag(htmlString, tagLetter);
    }

}