import { Component, Input } from '@angular/core';
import { Store } from '@ngxs/store';
import { Query2for1VoucherResponse } from 'src/app/customer-data-components/customer-order/store/interfaces/query-voucher.interface';
import { FetchDifferentCustomerDetails } from 'src/app/customer-info-summary-page/store/actions/customer.actions';

@Component({
  selector: 'sf-connected-customer-info',
  templateUrl: './connected-customer-info.component.html',
  styleUrls: ['./connected-customer-info.component.scss']
})
export class ConnectedCustomerInfoComponent {

  isPrimaryCustomer = false;

  private _mainCustomerEmail: string
  @Input() set mainCustomerEmail(email: string) {
    this._mainCustomerEmail = email;
    this.setIsPrimaryCustomer();
  }
  get mainCustomerEmail() { return this._mainCustomerEmail; }

  private _voucherData: Query2for1VoucherResponse;
  @Input() set voucherData(voucherData: Query2for1VoucherResponse) {
    this._voucherData = voucherData;
    this.setIsPrimaryCustomer();
  }
  public get voucherData(): Query2for1VoucherResponse { return this._voucherData; }

  constructor(private store: Store) { }

  onViewFullProfile(email: string) {
    this.store.dispatch(new FetchDifferentCustomerDetails(email));
  }

  private setIsPrimaryCustomer() {
    if (!this._voucherData || !this._mainCustomerEmail) {
      return;
    }

    const { primaryUserEmailAddress } = this._voucherData;
    this.isPrimaryCustomer = primaryUserEmailAddress?.toLowerCase() === this._mainCustomerEmail.toLowerCase();
  }

}
