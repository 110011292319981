<div
    class="main-div"
    *ngIf="viewModel$ | async as viewModel"
>
    <div
        class="center-div"
        [class.is-interactions-visible]="viewModel.showTickets"
    >
        <app-header-box [isInteractionsShown]="false">
            <ng-container header>search</ng-container>
        </app-header-box>

        <sf-customer-search-bar></sf-customer-search-bar>
    </div>

    <app-interactions
        *ngIf="viewModel.showTickets"
        [unknownCustomer]="true"
        [fromSearchPage]="true"
    >
    </app-interactions>
</div>