<div class="wrapper" [class.expanded]="isExpanded"
    *ngIf="{value: isPaginatingOrSilentlyLoading$ | async} as isPaginatingOrSilentlyLoading">

    <div #messages class="messages scrollable" infiniteScroll [infiniteScrollDistance]="1"
        [infiniteScrollThrottle]="100" [scrollWindow]="false" (scrolledUp)="fetchNextPage()">

        <sf-loader-template [loading]="loading$ | async" [hasData]="(messages$ | async)?.length > 0"
            [error]="error$ | async" [useDefaultError]="true" noDataMessage="no messages">

            <app-custom-spinner loading></app-custom-spinner>

            <ng-container dataContent>

                <div class="load-more">
                    <div class="loading" *ngIf="isPaginatingOrSilentlyLoading.value; else canLoadMore">
                        loading..
                    </div>
                    <ng-template #canLoadMore>
                        <button class="load" (click)="fetchNextPage()">load more</button>
                    </ng-template>
                </div>

                <sf-whatsapp-message-renderer *ngFor="let message of (messages$ | async)"
                    [id]="message?.id"></sf-whatsapp-message-renderer>

            </ng-container>

        </sf-loader-template>
    </div>


    <div class="chat-section">
        <mat-icon class="text-blue pointer expand-text-icon" (click)="toggleTextExpand()">
            {{ isExpanded ? "expand_more":"expand_less" }}
        </mat-icon>

        <textarea class="scrollable" [formControl]="form.get('message')" [class.error]="form.invalid && form.dirty">
      </textarea>

        <div class="send-container">
            <p class="required-message" *ngIf="form.invalid && form.dirty">
                This field is required
            </p>

            <sf-rain-button [disabled]="(isSending$ | async) || form.invalid"
                (click)="sendMessage()">send</sf-rain-button>
        </div>

    </div>

</div>