import { Utils } from "src/app/Utils";
import { SmartSubData } from "src/app/shared/interfaces/smartsub-data.interface";
import { TicketTrackingData } from "../../types/ticket-tracking-data.interface";
import { AddTicketMetaDataPayload, TicketMetaData } from "../../types/add-ticket-metadata-payload.interface";
import { CustomerTicket } from "src/app/shared/customer-ticket/interfaces/customer-ticket.interface";

export class NewTicketCreated {
    static readonly type = Utils.Helpers.Type("[Ticket Tracking] new ticket created");
    constructor(public payload: CustomerTicket) { }
}

export class InteractedWithTicket<T = SmartSubData> {
    static readonly type = Utils.Helpers.Type("[Ticket Tracking] Interacted with ticket");
    constructor(public payload: TicketTrackingData<T>) { }
}

export class AddMetaData {
    static readonly type = Utils.Helpers.Type("[Ticket Tracking] Log meta data");
    constructor(public payload: AddTicketMetaDataPayload<TicketMetaData>) { }
}

export class AddMetaDataSuccess {
    static readonly type = Utils.Helpers.Type("[Ticket Tracking] Log meta data success");
}

export class AddMetaDataFail {
    static readonly type = Utils.Helpers.Type("[Ticket Tracking] Log meta data fail");
}