import { Injectable } from '@angular/core';
import { Action, createSelector, State, StateContext } from '@ngxs/store';
import { ClearPersistentStorageItem, SetPersistentStorageItem } from '../../actions/persistent-storage.actions';
import { PersistentStorageItems, PersistentStorageKey } from './persistent-storage-items.interface';




interface PersistentStorageStateModel {
  data: {
    [key in PersistentStorageKey]?: PersistentStorageItems[key];
  }

}
@State<PersistentStorageStateModel>({
  name: 'persistent_storage_state',
  defaults: {
    data: {}
  }
})
@Injectable()
export class PersistentStorageState {


  static get<Key extends keyof PersistentStorageItems>(key: Key) {
    return createSelector([PersistentStorageState], (state: PersistentStorageStateModel) => {
      return <PersistentStorageItems[Key]>state.data[key];
    });
  }

  @Action(SetPersistentStorageItem)
  setPersistentStorageItem(ctx: StateContext<PersistentStorageStateModel>, action: SetPersistentStorageItem) {

    const copyOfState = Object.assign({}, ctx.getState().data);
    copyOfState[action.key] = action.value;

    ctx.patchState({ data: copyOfState });
  }

  @Action(ClearPersistentStorageItem)
  clearPersistentStorageItem(ctx: StateContext<PersistentStorageStateModel>, action: ClearPersistentStorageItem) {
    return ctx.dispatch(new SetPersistentStorageItem(action.key, null));
  }


}
