import { CustomResolveHandler } from "./custom-resolve-handler.model"

/**
 * Used to wait for objects that have not finished loading.
 */
export class CustomResolver<T> {

  private _data: T = null;
  private _loaded = false;
  private _resolveHandler = new CustomResolveHandler();

  setData(data: T) {
    this._data = data;
    this._loaded = true;
    this._resolveHandler.resolve(true);
  }

  async getData(): Promise<T> {
    if (this._loaded) return this._data;
    else {
      await this._resolveHandler.confirmationResult();
      return this._data;
    }
  }

}
