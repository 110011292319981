import { Injectable } from '@angular/core';
import { State, Action, StateContext, Selector } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { MILISECONDS_IN_MINUTE } from 'src/app/constants';
import { DataHandler } from 'src/app/shared/data-handler/data-handler';
import { DataLoading, getDataLoadingDefaultValues } from 'src/app/shared/interfaces/data-loading.interface';
import { SuccessState } from 'src/app/shared/models/success-state.model';
import { Utils } from 'src/app/Utils';
import { DataLoadingHelper } from 'src/app/Utils/helpers';
import { PbxAccessToken } from '../../services/voice-agents/assets/voice-tokens.type';
import { VoiceAgentsService } from '../../services/voice-agents/voice-agents.service';
import { AuthenticatePBXvoiceFail, AuthenticatePBXvoice, AuthenticatePBXvoiceSuccess, RefreshPBXToken } from '../actions/voice-pbx-auth.actions';



type VoicePBXAuthStateModel = DataLoading<PbxAccessToken>
@State<VoicePBXAuthStateModel>({
  name: 'voice_agents_state',
  defaults: getDataLoadingDefaultValues()
})
@Injectable()
export class VoicePBXAuthState {

  @Selector()
  static isLoading(state: VoicePBXAuthStateModel) { return state.loading; }

  @Selector()
  static isLoaded(state: VoicePBXAuthStateModel) { return state.loaded; }

  @Selector()
  static getAccessToken(state: VoicePBXAuthStateModel) { return state.data; }

  @Selector()
  static getError(state: VoicePBXAuthStateModel) { return state.error; }


  @Selector()
  static tokenSuccessState(state: VoicePBXAuthStateModel): SuccessState {
    return {
      loaded: state.loaded,
      success: DataHandler.isDefined(state.data)
    }
  }

  constructor(private _voiceAgentsService: VoiceAgentsService) {

  }


  @Action(AuthenticatePBXvoice)
  authenticatePBXvoice(ctx: StateContext<VoicePBXAuthStateModel>, action: AuthenticatePBXvoice) {
    const { email, password } = action.credentials;

    if (!email || !password) {
      ctx.dispatch(new AuthenticatePBXvoiceFail("Missing credentials."));
      return;
    }

    ctx.patchState({ loading: true });

    return this._voiceAgentsService.getPBXvoiceAuthToken(email, password)
      .pipe(tap({
        next: (res) => {
          if (!res?.auth_token) {
            ctx.dispatch(new AuthenticatePBXvoiceFail("Auth token is missing."));
            return;
          }
          ctx.dispatch(new AuthenticatePBXvoiceSuccess(res, action.credentials));
        },
        error: (e) => ctx.dispatch(new AuthenticatePBXvoiceFail(e))
      }));
  }


  @Action(AuthenticatePBXvoiceSuccess)
  authenticatePBXvoiceSuccess(ctx: StateContext<VoicePBXAuthStateModel>, action: AuthenticatePBXvoiceSuccess) {
    const accessToken = action.payload.auth_token;
    DataLoadingHelper.setData(ctx, accessToken);
    ctx.dispatch(new RefreshPBXToken(action.credentials));
  }


  @Action(AuthenticatePBXvoiceFail)
  authenticatePBXvoiceFailed(ctx: StateContext<VoicePBXAuthStateModel>, action: AuthenticatePBXvoiceFail) {
    const error = Utils.Helpers.findError(action.error, "");
    const errorMessage = `Failed to authenticate with PBX platform. Error: ${error}`;

    DataLoadingHelper.setError(ctx, errorMessage);
  }


  @Action(RefreshPBXToken)
  RefreshPBXToken(ctx: StateContext<VoicePBXAuthStateModel>, action: RefreshPBXToken) {
    const _30minutes = MILISECONDS_IN_MINUTE * 30;

    setTimeout(() => {
      ctx.dispatch(new AuthenticatePBXvoice(action.credentials));
    }, _30minutes);
  }



}
