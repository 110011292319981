import { SF_AUTH_ROLES } from "src/app/auth/assets/auth-role.config";

function hasCorrectRole(agentRoles: string[], allowedRoles: string[], unallowedRoles: string[] = []) {

  const caseInSensitiveRouteRoles = allowedRoles.map(r => r?.toLowerCase());
  const caseInSensitiveUnallowedRouteRoles = unallowedRoles?.map(r => r?.toLowerCase());


  for (let i = 0; i < agentRoles.length; i++) {

    const role = agentRoles[i]?.toLowerCase();

    if (caseInSensitiveUnallowedRouteRoles.includes(role)) {
      return false;
    }

    if (allowedRoles.includes('all') || caseInSensitiveRouteRoles.includes(role)) {
      return true;
    }
  }

  return false;
}



export function hasAccess(agentRoles: string[], allowedRoles: string[], unallowedRoles?:string[]) {

  const isAdmin = agentRoles?.includes(SF_AUTH_ROLES.ADMIN);
  if (isAdmin) {
    //Admins can access any resource
    return true;
  }

  const correctRole = hasCorrectRole(agentRoles, allowedRoles, unallowedRoles);
  if (correctRole) {
    return true;
  }

  return false;

}


