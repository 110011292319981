import { Action, State, StateContext } from "@ngxs/store";
import { SaveBillingCycle, SaveBillingCycleFail, SetBillingCycleID, SaveBillingCycleSuccess } from "../../action/payments.actions";
import { tap } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { SalesService } from "../../../sales-portal/services/sales.service";
import { AddSuccessResponseNotification } from "src/app/core/store/actions/notifications.actions";
import { SalesPortalDestroyed } from "../../action/sales-portal.actions";
import { Utils } from "src/app/Utils";
import { PAYMENT_DATE_OPTIONS } from "src/app/sales/sales-portal/assets/sales.constants";
import { SalesCycleDetails } from "../../interfaces/proxy-bill-cycle-details.interface";



const getDefaults = (): SalesBillingCycleStateModel => {

    const day = 1;
    const { id } = PAYMENT_DATE_OPTIONS.find(option => option.day === day);

    return {
        loading: false,
        cycleDetails: {
            id,
            day
        }
    }
}

export interface SalesBillingCycleStateModel {
    loading: boolean;
    cycleDetails: SalesCycleDetails;
}

/**
 * Do not use this state directly in components. Make use of the SalesBillingSelectors class
 */

@State<SalesBillingCycleStateModel>({
    name: 'sf_sales_billing_cycle_state',
    defaults: getDefaults()
})
@Injectable()
export class SalesBillingCycleState {

    constructor(private salesService: SalesService) { }

    @Action(SetBillingCycleID)
    setBillingCycleID(ctx: StateContext<SalesBillingCycleStateModel>, action: SetBillingCycleID) {
        const { cycleId, paymentDay } = action;

        ctx.patchState({
            cycleDetails: {
                id: cycleId,
                day: paymentDay
            }
        });
    }


    @Action(SaveBillingCycle)
    setBillingCycle(ctx: StateContext<SalesBillingCycleStateModel>, action: SaveBillingCycle) {
        const { payload, extraActions } = action;
        const { successActions, failActions } = extraActions;

        ctx.patchState({
            loading: true
        });

        return this.salesService.setBillingCycle(payload)
            .pipe(
                tap({
                    next: res => {
                        const { error, success } = res ?? {};
                        if (success) {
                            return ctx.dispatch(new SaveBillingCycleSuccess(successActions));
                        }
                        return ctx.dispatch(new SaveBillingCycleFail(error, failActions));
                    },
                    error: (e: unknown) => ctx.dispatch(new SaveBillingCycleFail(e))
                }));
    }

    @Action(SaveBillingCycleSuccess)
    setBillingCycleSuccessful(ctx: StateContext<SalesBillingCycleStateModel>, action: SaveBillingCycleSuccess) {

        ctx.patchState({
            loading: false
        });

        return ctx.dispatch([
            new AddSuccessResponseNotification({
                success: true,
                message: 'Billing cycle successfully set.'
            }),
            ...action.successActions
        ]);
    }

    @Action(SaveBillingCycleFail)
    setBillingCycleFailed(ctx: StateContext<SalesBillingCycleStateModel>, action: SaveBillingCycleFail) {
        const error = Utils.Helpers.findError(action.error, "");

        ctx.patchState({
            loading: false
        });

        return ctx.dispatch([
            new AddSuccessResponseNotification({
                success: false,
                message: `Failed to set billing cycle. ${error}`
            }),
            ...action.failActions
        ])
    }


    @Action(SalesPortalDestroyed)
    Clear(ctx: StateContext<SalesBillingCycleStateModel>) {
        ctx.setState(getDefaults());
    }
}
