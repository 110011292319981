import { Injectable } from '@angular/core';
import { Action, createSelector, State, StateContext, } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { InteractionActions } from 'src/app/interactions/store/actions/interaction-actions';
import { DataLoading } from 'src/app/shared/interfaces/data-loading.interface';
import { Utils } from 'src/app/Utils';
import { DataLoadingHelper } from 'src/app/Utils/helpers';
import { WhatsappMediaService } from '../../services/whatsapp-media.service';
import { FetchOrToggle, Fetch, FetchFail, FetchSuccess, Clear, ToggleVisibility } from '../actions/whatsapp-media-actions';
import { produce } from 'immer';
import { WhatsappSavedMedia } from '../types/whatsapp-saved-media.interface';
import { SFValidators } from 'src/app/shared/functions/sf-validators';



export interface WhatsappMediaStateModel {
    messageIds: {
        [messageId: string]: DataLoading<WhatsappSavedMedia>;
    }
}

const getDefaults = (): WhatsappMediaStateModel => {
    return {
        messageIds: {}
    }
}

@State<WhatsappMediaStateModel>({
    name: 'sf_WhatsappMedia_state',
    defaults: getDefaults()
})
@Injectable()
export class WhatsappMediaState {

    static getState(id: string) {
        return createSelector(
            [WhatsappMediaState],
            (state: WhatsappMediaStateModel) => state.messageIds?.[id]
        );
    }

    static isLoading(id: string) {
        return createSelector(
            [WhatsappMediaState],
            (state: WhatsappMediaStateModel) => state.messageIds?.[id]?.loading
        );
    }

    static isLoaded(id: string) {
        return createSelector(
            [WhatsappMediaState],
            (state: WhatsappMediaStateModel) => state.messageIds?.[id]?.loaded
        );
    }

    static getData(id: string) {
        return createSelector(
            [WhatsappMediaState],
            (state: WhatsappMediaStateModel) => state.messageIds?.[id]?.data
        );
    }

    static getBase64String(id: string) {
        return createSelector(
            [WhatsappMediaState],
            (state: WhatsappMediaStateModel) => state.messageIds?.[id]?.data?.base64string
        );
    }

    static getError(id: string) {
        return createSelector(
            [WhatsappMediaState],
            (state: WhatsappMediaStateModel) => state.messageIds?.[id]?.error
        );
    }

    constructor(private whatsappMediaService: WhatsappMediaService) {
    }

    @Action(FetchOrToggle)
    Init(ctx: StateContext<WhatsappMediaStateModel>, action: FetchOrToggle) {
        const { messageIds } = ctx.getState();
        const { id } = action.payload;

        if (id in messageIds) {
            return ctx.dispatch(new ToggleVisibility(id));
        }
        return ctx.dispatch(new Fetch(action.payload));
    }

    @Action(Fetch)
    Fetch(ctx: StateContext<WhatsappMediaStateModel>, action: Fetch) {
        const { id, type, dataUrl, mimeType } = action.payload;

        ctx.setState(
            produce(draft => {
                draft.messageIds[id] = DataLoadingHelper.isLoading();
            })
        );

        return this.whatsappMediaService.fetchMediaData(dataUrl, mimeType)
            .pipe(tap({
                next: response => ctx.dispatch(new FetchSuccess({ id, type, response })),
                error: e => ctx.dispatch(new FetchFail(id, e))
            }));
    }


    @Action(FetchSuccess)
    FetchSuccess(ctx: StateContext<WhatsappMediaStateModel>, action: FetchSuccess) {
        const { id, response, type } = action.payload;
        ctx.setState(
            produce(draft => {
                draft.messageIds[id] = DataLoadingHelper.dataLoaded({
                    visible: true,
                    type,
                    base64string: response
                });
            })
        );
    }


    @Action(FetchFail)
    FetchFail(ctx: StateContext<WhatsappMediaStateModel>, action: FetchFail) {
        const { error, id } = action;
        const err = Utils.Helpers.findError(error, '');
        const message = `Failed to load media. ${err}`;

        ctx.setState(
            produce(draft => {
                draft.messageIds[id] = DataLoadingHelper.errorLoaded(message);
            })
        );
    }

    @Action(ToggleVisibility)
    ToggleVisibility(ctx: StateContext<WhatsappMediaStateModel>, action: ToggleVisibility) {
        const { messageIds } = ctx.getState();
        const { id, visible } = action;

        const currentVisibility = messageIds[id]?.data?.visible;
        const visibleValue = SFValidators.isDefined(visible) ? visible : !currentVisibility;

        ctx.setState(
            produce(draft => {
                if (draft.messageIds[id]?.data) {
                    draft.messageIds[id].data.visible = visibleValue;
                }
            })
        );
    }

    @Action([InteractionActions.InteractionMenuDestroyed, Clear])
    Clear(ctx: StateContext<WhatsappMediaStateModel>) {
        ctx.setState(getDefaults());
    }

}       