import { Utils } from "src/app/Utils";
import { Product } from "../../interfaces/rain-product.interface";


export class GetAll {
  static readonly type = Utils.Helpers.Type('[Products] Get all');
}

export class GetAllSuccess {
  static readonly type = Utils.Helpers.Type('[Products] Get all success');
  constructor(public payload: { products: Product[] }) { }
}

export class GetAllFail {
  static readonly type = Utils.Helpers.Type('[Products] Get all fail');
  constructor(public error: any) { }
}


