import { Utils } from "src/app/Utils";
import { CurrentDeviceRequest, PossibleCurrentDevice } from "../../interfaces/current-device.interfaces";


export class DirtyFetch {
    static readonly type = Utils.Helpers.Type("[Current Device] Dirty Fetch");
    constructor(public payload: { imsi: string | null }) { }
}

export class Fetch {
    static readonly type = Utils.Helpers.Type("[Current Device] Fetch");
    constructor(public payload: CurrentDeviceRequest) { }
}

export class FetchSuccess {
    static readonly type = Utils.Helpers.Type("[Current Device] Fetch Success");
    constructor(public payload: PossibleCurrentDevice) { }
}

export class FetchFail {
    static readonly type = Utils.Helpers.Type("[Current Device] Fetch Fail");
    constructor(public error: unknown) { }
}

export class Clear {
    static readonly type = Utils.Helpers.Type("[Current Device] Clear");
}
