import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'sf-default-page-template',
  templateUrl: './default-page-template.component.html',
  styleUrls: ['./default-page-template.component.scss']
})
export class DefaultPageTemplateComponent {

  @Output() nextPressed = new EventEmitter();
  @Output() backPressed = new EventEmitter();

  @Input() isBackButtonShown = true;
  @Input() isNextButtonShown = false;
  @Input() scrollable = false;
  @Input() defaultBack = true;
  @Input() wider = false;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) { }

  onBack() {
    if (this.defaultBack) {
      this.router.navigate(['.'], { relativeTo: this.activatedRoute.parent });
    }

    this.backPressed.next();
  }

  onNext() {
    this.nextPressed.next();
  }

}
