import { Component } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { CustomerInfoState } from 'src/app/customer-info-summary-page/store/state/customer-info.state';

@Component({
  selector: 'sf-test-account-copy',
  templateUrl: './test-account-copy.component.html',
  styleUrls: ['./test-account-copy.component.scss']
})
export class TestAccountCopyComponent {

  @Select(CustomerInfoState.isTestAccount) isTestAccount$: Observable<boolean>

}
