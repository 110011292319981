/// <reference types="@types/googlemaps" />

import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Actions, Store, ofActionSuccessful } from '@ngxs/store';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { RefreshMaps } from 'src/app/customer-info-summary-page/store/actions/events.actions';
import { GeocoderService } from 'src/app/shared/components/geocoder/geocoder.service'; 
import { GeocoderActions } from './actions';
import { MAP_STATUS_MESSAGES } from 'src/app/constants';

@Component({
    selector: 'custom-geocoder',
    templateUrl: './geocoder.component.html',
    styleUrls: ['./geocoder.component.scss']
})
export class GeocoderComponent implements OnInit, OnDestroy {

    @ViewChild('blankDiv') blankDiv: ElementRef;
    @Input() address: string;
    @Output() results = new EventEmitter<string>();
    @Output() error = new EventEmitter<string>();
    
    addressInput: string;
    addressResults = [];
    initilaSearchedAddress: string = null;
    private searchSubject = new Subject<string>();
    // used to unsubscribe subscriptions
    private ngDestroy: Subject<void> = new Subject();

    constructor(private geocoderService: GeocoderService, private store: Store, private actions: Actions) { }

    ngOnInit(): void {
        this.addressInput = this.address?.valueOf();
        this.searchInitialAddress();
        this.actions.pipe(
            ofActionSuccessful(RefreshMaps),
            takeUntil(this.ngDestroy)
        )
            .subscribe({
                next: () => {
                    this.moveToAddress(this.initilaSearchedAddress, this.addressInput);
                }
            })

        this.searchSubject.pipe(debounceTime(1000)).subscribe(searchValue => this.search(searchValue))
    }

    private searchInitialAddress() {
        if (!this.address || !this.address.length) return;
        this.geocoderService.onAutoComplete(this.address).pipe(takeUntil(this.ngDestroy)).subscribe({
            next: (response) => {
                const [place] = response
                this.moveToAddress(place.place_id)
            },
            error: (error) => this.error.next(MAP_STATUS_MESSAGES[error])
        })
    }

    onSearch(searchValue: string) {
        this.searchSubject.next(searchValue)
    }

    private search(searchValue: string) {
        if (!searchValue || !searchValue.length) return;
        this.geocoderService.onAutoComplete(searchValue).pipe(takeUntil(this.ngDestroy)).subscribe({
            next: (response) => this.addressResults = response,
            error: (error) => this.error.next(MAP_STATUS_MESSAGES[error])

        })
        this.store.dispatch(new GeocoderActions.SearchForAddress(searchValue));
    }

    moveToAddress(placeId: string, description?: string) {
        if (!placeId || !placeId.length) return;
        if (description) {
            this.addressInput = description;
        }
        this.initilaSearchedAddress = placeId;
        this.geocoderService.onSetCustomerCoordinates(placeId, this.blankDiv.nativeElement)
        .pipe(takeUntil(this.ngDestroy))
        .subscribe();
        this.results.emit(placeId);
        this.clearSearchResults();
    }

    clearSearchResults() {
        this.addressResults = [];
    }

    undo() {
        this.addressInput = this.address?.valueOf();
        this.searchInitialAddress();
    }

    clearInput() {
        this.addressInput = "";
    }

    ngOnDestroy(): void {
        this.ngDestroy.next(null);
        this.ngDestroy.complete();
        this.searchSubject.next(null);
        this.searchSubject.complete();
    }
}
