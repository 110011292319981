import { Selector } from "@ngxs/store";
import { RainPaymentType } from "src/app/core/store/interfaces/product-catalog.interfaces";
import { CustomerInfoSelector } from "src/app/customer-info-summary-page/store/selectors/customer-info.selector";
import { SalesBillingCycleState, SalesBillingCycleStateModel } from "../state/shared-sales-states/sales-billing-cycle.state";
import { ProxyBillCycleDetails, SalesCycleDetails } from "../interfaces/proxy-bill-cycle-details.interface";
import { BillCycleSpecDetail } from "src/app/shared/interfaces/smartsub-data.interface";


export class SalesBillingSelectors {


    /**
     * Do not use this method directly in your component, make use of getBillCycleProxyDetails
     */
    @Selector([SalesBillingCycleState])
    static getSelectedBillCycle(billCycleState: SalesBillingCycleStateModel): SalesCycleDetails {
        return billCycleState?.cycleDetails;
    }


    @Selector([
        CustomerInfoSelector.getBillCycleSpecDetail,
        SalesBillingSelectors.getSelectedBillCycle
    ])
    static getProxyBillCycleDetails(currentCustomerSpecDetail: null | BillCycleSpecDetail, salesCycle: SalesCycleDetails): ProxyBillCycleDetails {

        const { bill_cycle_spec, cycle_period } = currentCustomerSpecDetail ?? {};

        if (bill_cycle_spec) {
            return {
                fromSavedCustomer: true,
                cycleDetails: {
                    id: bill_cycle_spec,
                    day: cycle_period?.start
                }
            }
        }

        return {
            fromSavedCustomer: false,
            cycleDetails: salesCycle
        }
    }

    @Selector([
        SalesBillingSelectors.getProxyBillCycleDetails
    ])
    static getProxyBillCycle(details: ProxyBillCycleDetails) { return details?.cycleDetails; }

    @Selector([SalesBillingCycleState])
    static savingBillCycle(state: SalesBillingCycleStateModel) { return state.loading; }


    @Selector([CustomerInfoSelector.getPaymentType])
    static shouldApplyProRata(paymentType: RainPaymentType) {
        return paymentType === "upfront";
    }

}