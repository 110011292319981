import { Utils } from "src/app/Utils";
import { PersistentStorageItems, PersistentStorageKey } from "../state/persistent-storage/persistent-storage-items.interface";

export class SetPersistentStorageItem<Key extends keyof PersistentStorageItems = any> {
  static readonly type = Utils.Helpers.Type('[Persistent-storage: Set] set item in persistent storage');
  constructor(public key: Key, public value: PersistentStorageItems[Key]) { }
}

export class ClearPersistentStorageItem {
  static readonly type = Utils.Helpers.Type('[Persistent-storage: Clear] clear item in persistent storage');
  constructor(public key: PersistentStorageKey) { }
}
