import { StateContext } from "@ngxs/store";
import { BasicStateUpdaterOptions, BasicStateModel, BasicStateContext } from "./types/basic-state-updater.types";
import { HttpStatusCode } from "@angular/common/http";
import { DataLoadingHelper } from "src/app/Utils/helpers";
import { take, takeUntil, tap } from "rxjs/operators";
import { getDataLoadingAndSilentlyLoadingValues } from "../interfaces/data-loading.interface";
import { IPayload } from "../types/payload.interface";
import { Utils } from "src/app/Utils";


export class BasicStateUpdater<T, RequestPayloadT> {

    constructor(private _options: BasicStateUpdaterOptions<T, RequestPayloadT>) { }


    static getDefaultState<T>(data: T | null = null): BasicStateModel<T> {
        return {
            ...getDataLoadingAndSilentlyLoadingValues(data),
            errorStatusCode: null
        }
    }

    FetchOrFetchSilentlyIfHasData(ctx: StateContext<BasicStateModel<T>>, action: IPayload<RequestPayloadT>) {
        const { Fetch, FetchSilently } = this._options;
        if (!FetchSilently) {
            throw new Error("FetchSilently is not defined. Please specify this in the list of initialization options.");
        }

        const { payload } = action;

        const { data } = ctx.getState();

        if (data) {
            return ctx.dispatch(new FetchSilently(payload));
        }
        else {
            return ctx.dispatch(new Fetch(payload));
        }
    }

    Fetch(ctx: StateContext<BasicStateModel<T>>, action: IPayload<RequestPayloadT>) {
        const { baseFetchFn, cancelRequest$, FetchFail, FetchSuccess } = this._options;
        const { payload } = action;

        ctx.patchState({
            loading: true,
            loaded: false
        });

        return baseFetchFn(payload)
            .pipe(
                tap({
                    next: res => ctx.dispatch(new FetchSuccess(res)),
                    error: (e: unknown) => ctx.dispatch(new FetchFail(e))
                }),
                takeUntil(cancelRequest$.pipe(take(1))),
            );
    }

    FetchSuccess(ctx: BasicStateContext<T>, action: IPayload<T>) {
        const { payload } = action;

        ctx.patchState({
            ...DataLoadingHelper.dataLoaded(payload),
            silentlyLoading: false,
            errorStatusCode: null
        });
    }

    FetchFail(ctx: BasicStateContext<T>, action: { error: unknown }) {
        const { error, statusCode } = Utils.Helpers.findErrorAndStatusCode(action.error, '');
        const { errorMessage, notFoundMessage } = this._options;

        const message = statusCode === HttpStatusCode.NotFound
            ? notFoundMessage
            : `${errorMessage} ${error}`;

        ctx.patchState({
            ...DataLoadingHelper.errorLoaded(message),
            silentlyLoading: false,
            errorStatusCode: statusCode
        });
    }

    FetchSilently(ctx: StateContext<BasicStateModel<T>>, action: IPayload<RequestPayloadT>) {
        const { baseFetchFn, cancelRequest$, FetchSilentlyFail, FetchSuccess } = this._options;

        if (!FetchSilentlyFail) {
            throw new Error("FetchSilentlyFail is not defined. Please specify this in the list of initialization options.");
        }

        const { payload } = action;

        ctx.patchState({ silentlyLoading: true });

        return baseFetchFn(payload)
            .pipe(
                tap({
                    next: res => ctx.dispatch(new FetchSuccess(res)),
                    error: (e: unknown) => ctx.dispatch(new FetchSilentlyFail(e))
                }),
                takeUntil(cancelRequest$.pipe(take(1))),
            );
    }

    FetchSilentlyFail(ctx: BasicStateContext<T>) {

        ctx.patchState({
            silentlyLoading: false
        });
    }

    Clear(ctx: BasicStateContext<T>) {
        this._options.cancelRequest$.next(null);
        ctx.setState(BasicStateUpdater.getDefaultState());
    }

}   