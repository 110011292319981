import { Pipe, PipeTransform } from "@angular/core";
import { FullAgent } from "../../store/interfaces/full-agent.interface";


@Pipe({
  name: 'formatHasDeviceMessage'
})
export class FormatHasDeviceMessagePipe implements PipeTransform {

  transform(fullAgent: FullAgent): string {
    if (!fullAgent) {
      return;
    }

    const { devices, possibleDevices } = fullAgent;

    if (!devices?.length && !possibleDevices?.length) {
      return "none";
    }

    return fullAgent.hasCorrectDevice ? "correct" : "incorrect";
  }
}
