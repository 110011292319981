import { Component, OnDestroy, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { BehaviorSubject, Observable, Subject, combineLatest } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SessionState } from 'sip.js';
import { SipMessageSubject } from 'src/app/sip-phone/assets/sip-message-subject.type';
import { SipPhoneService } from 'src/app/sip-phone/services/sip-phone.service';
import { LoginAction } from 'src/app/sip-phone/services/sip-platform/login-action.type';
import { AgentVoiceQueueActions } from 'src/app/sip-phone/store/actions/agent-voice-queue-actions';
import { ToggleAcceptingCalls } from '../store/actions/agent.actions';
import { AgentState } from '../store/state/agent.state';

@Component({
    selector: 'app-agent-phone-available-modal',
    templateUrl: './agent-phone-available-modal.component.html',
    styleUrls: ['./agent-phone-available-modal.component.scss']
})
export class AgentPhoneAvailableModalComponent implements OnInit, OnDestroy {

    @Select(AgentState.isAcceptingCalls) isAcceptingCalls$: Observable<boolean>;

    private _callTerminated$ = new BehaviorSubject<void>(null);

    private readonly ngDestroy$: Subject<void> = new Subject();

    constructor(
        private sipPhoneService: SipPhoneService,
        private store: Store) { }

    ngOnInit(): void {
        this.listenToStatusAndCallUpdates();

        this.sipPhoneService.callSubject
            .pipe(
                takeUntil(this.ngDestroy$)
            )
            .subscribe(
                (response: Map<SipMessageSubject, unknown>) => {
                    if (response.has("state_changed")) {
                        const state = <SessionState>response.get("state_changed");
                        if (state === SessionState.Terminated) {
                            this._callTerminated$.next();
                        }
                    }
                }
            );
    }

    private listenToStatusAndCallUpdates() {
        combineLatest([
            this.store.select(AgentState.getState),
            this._callTerminated$
        ])
            .pipe(
                takeUntil(this.ngDestroy$)
            )
            .subscribe({
                next: ([agentState]) => {
                    const { status, acceptingCalls } = agentState ?? {};

                    if (status === "available" && acceptingCalls) {
                        this.dispatchLoginAction("login");
                    }
                    else {
                        this.dispatchLoginAction("logout");
                    }
                }
            })
    }

    onToggleCalls() {
        this.store.dispatch(new ToggleAcceptingCalls());
    }

    private dispatchLoginAction(loginAction: LoginAction) {
        this.store.dispatch(new AgentVoiceQueueActions.ToggleLogin({ loginAction }));
    }

    ngOnDestroy(): void {
        this.ngDestroy$.next(null);
        this.ngDestroy$.complete();
    }


}
