import { Utils } from "src/app/Utils";


export class MapLoaded {
    static readonly type = Utils.Helpers.Type("[MapActions: Loaded]");
    constructor(public map: google.maps.Map) { }
}

export class ShowPinDrop {
    static readonly type = Utils.Helpers.Type('[MapActions] Show PinDrop.');
    constructor(public show: boolean) { }
}

export class MapDestroyed {
    static readonly type = Utils.Helpers.Type("[MapActions: Destroyed]");
}

export class ToggleCenteredOnOption {
    static readonly type = Utils.Helpers.Type('[MapActions] Toggle Centered On.');
}