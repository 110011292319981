import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-header-box',
  templateUrl: './header-box.component.html',
  styleUrls: ['./header-box.component.scss']
})
export class HeaderBoxComponent {

  @Input() isInteractionsShown = true;
  @Input() showCart = false;

}
