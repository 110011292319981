import { Utils } from "src/app/Utils";

export class DebitAccount {
    static readonly type = Utils.Helpers.Type('[Accounts: Payments] Debit customers account');
    constructor(public payload: number) { }
}

export class DebitAccountSuccess {
    static readonly type = Utils.Helpers.Type('[Accounts: Payments] Debit customers account Success');
    constructor(public invoiceId: string, public initiatedTime: number) { }
}

export class DebitAccountFail {
    static readonly type = Utils.Helpers.Type('[Accounts: Payments] Debit customers account fail');
    constructor(public payload: unknown) { }
}

export class CheckPaymentStatus {
    static readonly type = Utils.Helpers.Type('[Accounts: Payments] Check payment status');
    constructor(public invoiceId: string, public initiatedTime: number) { }
}

export class PaymentSuccessful {
    static readonly type = Utils.Helpers.Type('[Accounts: Payments] Check payment status Success');
    constructor(public message: string) { }
}

export class PaymentFailed {
    static readonly type = Utils.Helpers.Type('[Accounts: Payments] Check payment status fail');
    constructor(public error: unknown) { }
}
