
import { Observable } from "rxjs";
import { catchError, delay, retryWhen, take } from "rxjs/operators";
import { AutoRetryOptions } from "./auto-retry.interface";


export const autoRetryObs = <E = string>
    (options: AutoRetryOptions<E>) => {

    const { maxAttempts, cleanupFn, delayAmount, error } = options;

    return <T>(source$: Observable<T>) =>
        source$
            .pipe(
                retryWhen(errors => errors
                    .pipe(
                        delay(delayAmount),
                        take(maxAttempts),
                        catchError((err: unknown) => {
                            if (cleanupFn) {
                                cleanupFn(err);
                            }
                            throw error ? error : err;
                        })
                    ))
            )
}



