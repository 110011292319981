import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { SetCustomerAddressLatLng } from 'src/app/customer-data-components/sim-details/sim-card-page/sub-components/coverage/store/actions/coverage-data.actions';

@Injectable({
  providedIn: 'root'
})
export class GeocoderService {

  constructor(private store: Store) { }

  onAutoComplete(input: string): Observable<google.maps.places.AutocompletePrediction[]> {
    return new Observable<google.maps.places.AutocompletePrediction[]>(observer => {
      const autoCompleteService = new google.maps.places.AutocompleteService();
      autoCompleteService.getPlacePredictions(
        {
          input,
          componentRestrictions: {
            country: "ZA"
          }
        }, (result, status) => {
          if (status === "OK") {
            observer.next(result)
            observer.complete()
          } else {
            observer.error(status)
          }
        });
    })
  }

  onForwardGeocode(address: string): Observable<google.maps.GeocoderResult[]>{
    return new Observable<google.maps.GeocoderResult[]>(observer => {
      const geocoder = new google.maps.Geocoder()
      geocoder.geocode({
        address,
        componentRestrictions: {
          country: "ZA"
        }
      }, (result, status) => {
        if (status === "OK") {
          observer.next(result)
          observer.complete()
        } else {
          observer.error(status)
        }
      })
    })

  }

  onSetCustomerCoordinates(placeId: string, divElement: HTMLDivElement): Observable<void> {
    return new Observable<void>(observer => {
      const placesService = new google.maps.places.PlacesService(divElement);
      placesService.getDetails(
        { placeId },
        (result, status) => {
          if (status === "OK") {
            const latLng = { lng: result.geometry.location.lng(), lat: result.geometry.location.lat() };
            this.store.dispatch(new SetCustomerAddressLatLng(latLng));
            observer.next()
            observer.complete()
          } else {
            observer.error(status)
          }
        });

    })
  }
}
