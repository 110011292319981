import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Select, Store } from '@ngxs/store';
import { takeUntil } from 'rxjs/operators';
import { ToggleModalByName } from 'src/app/core/store/actions/modal.actions';
import { RainAgent } from 'src/app/shared/components/agent/rain-agent.service';
import { NOTE_CHANNELS } from 'src/app/shared/customer-ticket/ticket-event-handler/assets/ticket.constants';
import { CamundaService } from 'src/app/shared/customer-ticket/ticket-event-handler/camunda.service';
import { TicketEventHandlerService } from 'src/app/shared/customer-ticket/ticket-event-handler/ticket-event-handler.service';
import { PostTicketModalOptions } from 'src/app/shared/services/post-ticket/post-ticket-modal-options.model';
import { PostTicketService } from 'src/app/shared/services/post-ticket/post-ticket.service';
import { FormHelper } from 'src/app/Utils/helpers';
import { ChatTicketState } from '../../store/state/chat-ticket.state';
import { InteractionTicketsState } from '../../store/state/interaction-tickets.state';
import { PostSalesModalForm } from './assets/post-sales-modal-form.interface';
import { SalesModalOptions } from './assets/sales-modal-options';
import { AgentActions } from 'src/app/shared/components/agent/store/actions/agent-action-actions';
import { CustomerOrderSelectors } from 'src/app/customer-data-components/customer-order/store/selectors/customer-order.selectors';
import { ChatJsonNote } from '../components/chat-json-note/assets/chat-json-note.interface';
import { CoreState } from 'src/app/core/store/state/core.state';
import { Observable } from 'rxjs';


const ACCEPTABLE_STATUSES = [200, 201, 204];

@Component({
    selector: 'sf-post-sales-modal',
    templateUrl: './post-sales-modal.component.html',
    styleUrls: ['./post-sales-modal.component.scss'],
})
export class PostSalesModalComponent extends FormHelper implements OnInit, OnDestroy {

    @Select(CoreState.hasOneOfTheseRoles(['CEC.SALES.A', 'CEC.SALESPRIORITY.A'])) showHomeReasons$: Observable<boolean>
    @Select(CoreState.hasOneOfTheseRoles(['CEC.SALES.B'])) showMobileReasons$: Observable<boolean>
    @Select(CoreState.hasOneOfTheseRoles(['CEC.SALES.C'])) showWorkReasons$: Observable<boolean>

    modalOptions: PostTicketModalOptions;
    saleSuccessful = null;
    form: FormGroup;

    ticketHasSaleAlready = false;
    isOtherHearAbout = false;

    readonly salesChannelOptions= SalesModalOptions.SALES_CHANNEL_OPTIONS;
    readonly hearAboutOptions = SalesModalOptions.HEAR_ABOUT_OPTIONS;
    readonly statusOptions = SalesModalOptions.STATUS_OPTIONS;
    readonly homeReasonOptions = SalesModalOptions.HOME_REASON_OPTIONS;
    readonly mobileReasonOptions = SalesModalOptions.MOBILE_REASON_OPTIONS;
    readonly workReasonOptions = SalesModalOptions.WORK_REASON_OPTIONS;

    constructor(
        private store: Store,
        private fb: FormBuilder,
        private rainAgent: RainAgent,
        private camundaService: CamundaService,
        private ticketEventHandler: TicketEventHandlerService,
        private postTicketService: PostTicketService
    ) {
        super();
    }

    ngOnInit(): void {
        this.modalOptions = new PostTicketModalOptions({
            acceptableStatuses: ACCEPTABLE_STATUSES,
        });

        this.store.select(ChatTicketState.getTicketHelper)
            .pipe(takeUntil(this.ngDestroy$))
            .subscribe({
                next: tHelper => this.ticketHasSaleAlready = tHelper?.hasSuccessfulSale()
            });

        this.form = this.fb.group({
            sales_channel:this.fb.control(null, Validators.compose([Validators.required])),
            status: this.fb.control(null, Validators.compose([Validators.required])),
            hearAboutRain: this.fb.control(null, Validators.compose([Validators.required])),
            otherHearAbout: this.fb.control(null),
            orderNumber: this.fb.control(null),
            reasonIfUnsuccessful: this.fb.control(null),
        });

        this.InitForm(this.form);
        this.listenToFormChanges();
    }

    private listenToFormChanges() {

        this.getControl("status").valueChanges
            .pipe(takeUntil(this.ngDestroy$))
            .subscribe({
                next: (res: string) => {
                    const successful = res === "successful";
                    this.saleSuccessful = successful;

                    this.updateRequired("orderNumber", successful);
                    this.updateRequired("reasonIfUnsuccessful", !successful);
                }
            });
    }

    private updateRequired(controlName: string, required: boolean) {
        const control = this.getControl(controlName);
        if (required) {
            control.addValidators(Validators.required);
            control.updateValueAndValidity();
        }
        else {
            control.removeValidators(Validators.required);
            control.patchValue(null);
        }
    }

    onOptionSelect(formField: string, value: string) {
        this.getControl(formField).patchValue(value);
    }

    async onSubmit() {
        const hexId = this.store.selectSnapshot(InteractionTicketsState.getCurrentHexId);
        const { status, orderNumber,sales_channel, reasonIfUnsuccessful, hearAboutRain } = this.form.getRawValue();

        const isSuccessful = status === "successful";
        const message = isSuccessful ? 'Sale Successful' : 'Sale Unsuccessful';

        const payload: PostSalesModalForm = {
            ticket_id: BigInt(hexId).toString(),
            agent_order_status: message,
            related_entity: {
                email: (await this.rainAgent.getAgentDetails()).email,
            },
            sales_channel: sales_channel,
            order_number: orderNumber ?? null,
            change_reason: this.saleSuccessful ? orderNumber : reasonIfUnsuccessful,
            hearAboutRain: hearAboutRain
        };

        this.modalOptions.sending = true;

        const response = await this.camundaService.sendPayload(payload, 'agent_order', hexId);
        if (ACCEPTABLE_STATUSES.includes(response?.status)) {
            this.addPostSalesForm(hexId, payload);

            const orderTotal = this.store.selectSnapshot(CustomerOrderSelectors.getOrderTotal(orderNumber));

            const action = isSuccessful
                ? new AgentActions.SuccessfulSale(hexId, orderNumber, orderTotal)
                : new AgentActions.UnsuccessfulSale(hexId, orderNumber, orderTotal);

            this.store.dispatch(action);
        }

        this.postTicketService.checkPostTicketMoveOnState(
            response,
            this.modalOptions,
            this.onClose.bind(this)
        );
    }

    private async addPostSalesForm(hexId: string, detail: PostSalesModalForm) {
        const payload: ChatJsonNote<PostSalesModalForm> = {
            message: "Post Sales Form",
            detail,
            type: "sales_form"
        }

        return this.ticketEventHandler.addNote(
            {
                hex_id: hexId,
                comment: JSON.stringify(payload),
                isPrivate: true,
                channel_id: NOTE_CHANNELS.WORKAROUND_FORMS
            }
        );
    }

    move() {
        this.postTicketService.moveOn(this.modalOptions, this.onClose.bind(this));
    }

    onClose() {
        this.store.dispatch(new ToggleModalByName('post_sales_modal', false));
    }

    ngOnDestroy(): void {
        this.ngDestroy$.next(null);
        this.ngDestroy$.complete();
        this.onClose();
    }
}
