<ng-container *ngIf="jsonNote">

  <chat-box-element
    backgroundColor="#f4ebff"
    [time]="note?.inserted_at"
    [hasShowMoreButton]="true"
    (showMore)="onToggleExpanded()"
  >
    <ng-container header>{{jsonNote?.message}}</ng-container>
    <ng-container subHeader>{{note?.email}}</ng-container>
  </chat-box-element>

  <div
    *ngIf="isExpanded$ | async"
    class="pretty-note-container"
  >
    <sf-pretty-json-note [data]="detail"></sf-pretty-json-note>
  </div>
</ng-container>
