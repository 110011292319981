import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { SetSelectedAnswerQuestion } from '../actions/faq-scenarios.actions';

export interface FAQScenariosStateModel {
    faq_category: string;
    faq_answer: string;
}

@State<FAQScenariosStateModel>({
    name: 'faq_scenarios_state',
    defaults: {
        faq_category: null,
        faq_answer: null,
    },
})
@Injectable()
export class FAQ_ScenariosState {

    @Selector()
    static getAnswer(state: FAQScenariosStateModel) {
        return state.faq_answer;
    }

    @Selector()
    static getQuestion(state: FAQScenariosStateModel) {
        return state.faq_category;
    }

    @Action(SetSelectedAnswerQuestion)
    setSelectedFAQcategory(
        ctx: StateContext<FAQScenariosStateModel>,
        action: SetSelectedAnswerQuestion,
    ) {
        ctx.patchState({
            faq_category: action.faq_question,
            faq_answer: action.faq_answer,
        });
    }
}
