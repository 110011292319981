import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { CustomerOrderActions } from "../actions/customer-order-actions";


const getDefaults = (): CustomerOrderStateModel => {
    return {
        selectedOrderId: null
    }
}

interface CustomerOrderStateModel {
    selectedOrderId: string;
}

@State<CustomerOrderStateModel>({
    name: 'sf_CustomerOrder_state',
    defaults: getDefaults()
})
@Injectable()
export class CustomerOrderState {

    @Selector()
    static getSelectedOrderId(state: CustomerOrderStateModel) { return state.selectedOrderId; }


    @Action(CustomerOrderActions.SetSelectedOrderId)
    SetSelectedOrderNumber(ctx: StateContext<CustomerOrderStateModel>, action: CustomerOrderActions.SetSelectedOrderId) {
        ctx.patchState({
            selectedOrderId: action.orderId
        });
    }


    @Action(CustomerOrderActions.OrderPageDestroyed)
    Clear(ctx: StateContext<CustomerOrderStateModel>) {
        ctx.setState(getDefaults());
    }
}