<div class="interactions" [class.is-open]="open" [class.banner-open]="isLoadsheddingBannerOpen$ | async">
  <div class="content">
    <div [class.hidden]="open" class="solo-header">
      <div class="content" (click)="onToggleOpen()">
        <app-chat-header [header]="(hasSelectedTicket$ | async) ? 'chat history' : 'interactions'">
          <span class="default-spacer"></span>
          <mat-icon class="minimize-icon">minimize</mat-icon>
        </app-chat-header>
      </div>
    </div>

    <app-rain-card [class.hidden]="!open" [frontColor]="'#F5F5F5'">
      <ng-container front>
        <app-chat-header [header]="(hasSelectedTicket$ | async) ? 'chat history' : 'interactions'"
          (click)="onToggleOpen()">
          <span class="default-spacer"></span>
          <mat-icon class="minimize-icon">minimize</mat-icon>
        </app-chat-header>

        <mat-toolbar *ngIf="!(hasSelectedTicket$ | async)" class="toolbar">
          <span class="first-spacer"></span>
          <span class="toolbar-option text pointer" [class.selected]="(interactionType$ | async) === 'ticket'"
            (click)="setInteractionType('ticket')">
            tickets
            <span class="badge" [matBadge]="(shortTickets$ | async)?.length ?? 0" matBadgePosition='above after'
              matBadgeColor="warn" matBadgeSize="medium" matBadgeOverlap="true">
            </span>
          </span>
          <span class="toolbar-option text pointer" [class.selected]="(interactionType$ | async) === 'whatsapp'"
            (click)="setInteractionType('whatsapp')">
            whatsapp
            <span class="badge" [matBadge]="( whatsappNotificationCount$ | async) ?? 0" matBadgePosition='above after'
              matBadgeColor="warn" matBadgeSize="medium" matBadgeOverlap="true">
            </span>
          </span>
          <span class="spacer"></span>

          <mat-icon class="green pointer" (click)="openNewTicketModal()" matTooltip="Add new ticket">add_circle
          </mat-icon>
        </mat-toolbar>

        <div class="main-content">

          <ng-container *ngIf="(interactionType$ | async) === 'whatsapp'; else normalTicket">
            <div class="whatsapp-interactions">
              <sf-whatsapp-interactions-container></sf-whatsapp-interactions-container>
            </div>
          </ng-container>

          <ng-template #normalTicket>
            <div *ngIf="!(hasSelectedTicket$ | async)" class="all-interactions scrollable">

              <app-custom-spinner *ngIf="loading$ | async; else loaded"></app-custom-spinner>

              <ng-template #loaded>
                <ng-container *ngIf="(shortTickets$ | async) as shortTickets">

                  <ng-container *ngIf="shortTickets?.length; else noData">
                    <div *ngFor="let shortTicket of shortTickets" (click)="showSelectedTicket(shortTicket?.hex_id)">

                      <app-customer-ticket-interaction [hexId]="shortTicket?.hex_id">
                      </app-customer-ticket-interaction>

                    </div>

                    <div class="load-more" [class.is-disabled]="!(canLoadMore$ | async)" (click)="loadNextPage()">
                      <div>
                        {{(isNextPageLoading$ | async) ? "loading.." : "load more"}}
                      </div>
                    </div>
                  </ng-container>

                  <ng-template #noData>
                    <div class="red no-data">
                      {{(error$ | async) ?? "No tickets found for customer."}}
                    </div>
                  </ng-template>

                </ng-container>
              </ng-template>
            </div>

            <app-chat-interaction class="chat-content" *ngIf="(selectedShortTicket$ | async) as selectedTicket"
              [hexId]="selectedTicket?.hex_id" [externalId]="selectedTicket?.external_id">
            </app-chat-interaction>

          </ng-template>

        </div>
      </ng-container>
    </app-rain-card>
  </div>
</div>

<sf-interactions-modal-container [numOpenTickets]="(openTicketsForGroup$ | async)?.length"
  [customerEmail]="customerEmail">
</sf-interactions-modal-container>
