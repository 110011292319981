import { Utils } from 'src/app/Utils';


export class StartTimer {
  static readonly type = Utils.Helpers.Type(
    '[TimeOut Timer: Start] Start timer for agent inactivity',
  );
}

export class ResetTimer {
  static readonly type = Utils.Helpers.Type(
    '[TimeOut Timer: Reset] Reset timer for agent when they interact',
  );
}
