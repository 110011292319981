import { DataHandler } from "../data-handler/data-handler";
import { ChatData } from "./interfaces/chat-data.interface";
import { Attachment, CustomerTicket, EntityTicket, TicketNote } from "./interfaces/customer-ticket.interface";
import { NoteChatType, SanitizedChatNote } from "./interfaces/sanitized-chat-note.interface";
import { NOTE_CHANNELS } from "./ticket-event-handler/assets/ticket.constants";


function getChatType(channel_id: number) {
  const channelMap: { [x: number]: NoteChatType } = {
    [NOTE_CHANNELS.UNKNOWN]: "note",
    [NOTE_CHANNELS.WORKAROUND_FORMS]: "json-note",
    [NOTE_CHANNELS.HTML]: "html-note"
  }
  return channelMap[channel_id];
}


export class TicketNoteHelper {
  customerTicket: CustomerTicket;
  sanitizedTicketNotes: SanitizedChatNote[] = [];
  sanitizedHTMLNotes: SanitizedChatNote[] = [];
  chatDataList: ChatData[] = [];


  constructor(customerTicket: CustomerTicket) {
    this.customerTicket = customerTicket;
    this.populateChatDataList();
  }

  private populateChatDataList() {
    this.createSanitizedNotes();
    //Fields with valid dates will be added first
    const [hasInsertedAtTime, doesNotHaveTime] = this.validateTimeField();
    hasInsertedAtTime.sort((a, b) => DataHandler.sortByDate(a.data.inserted_at, b.data.inserted_at));
    this.chatDataList.push(...hasInsertedAtTime, ...doesNotHaveTime);
  }

  private createSanitizedNotes() {
    const notes: TicketNote[] = this.customerTicket?.notes;
    const entityTickets: EntityTicket[] = this.customerTicket?.entity_ticket;
    if (!DataHandler.isDefined(notes) || notes?.length === 0) return;

    for (let i = 0; i < notes.length; i++) {
      const note = notes[i];
      const channel_id = note?.channel_id;

      if ([NOTE_CHANNELS.UNKNOWN, NOTE_CHANNELS.HTML, NOTE_CHANNELS.WORKAROUND_FORMS].includes(channel_id)) {
        const chatType = getChatType(channel_id)
        const sanitizedNote: SanitizedChatNote = this.getSanitizedNote(chatType, note, entityTickets);
        this.sanitizedTicketNotes.push(sanitizedNote);
      }
    }
  }



  private getSanitizedNote(chatType: NoteChatType, note: TicketNote, entityTickets: EntityTicket[]): SanitizedChatNote {
    if (!note) return;
    const isRequester = this.getIsRequester(note, entityTickets);

    return {
      chatType,
      hex_id: note?.hex_id,
      email: this.getEmail(note, isRequester),
      floatDirection: isRequester ? "left" : "right",
      roleLetter: this.getRoleLetter(note, isRequester),
      noteClass: isRequester ? 'customer-img' : 'agent-img',
      comment: this.getDecodedComment(note?.comment),
      inserted_at: note?.inserted_at,
      isRequester: isRequester,
      private: note?.private
    };
  }

  private getRoleLetter(note: TicketNote, isRequester: boolean) {
    const defaultLetter = isRequester ? "R" : "A"; //A for assignee
    const letter = note?.related_entity?.email?.charAt(0)?.toUpperCase();
    return letter ? letter : defaultLetter;
  }

  private getEmail(note: TicketNote, isRequester: boolean) {
    const defaultResponse = isRequester ? "requester" : "agent";
    const email = note?.related_entity?.email;
    return email ? email : defaultResponse;
  }

  private getDecodedComment(comment: string) {
    if (!comment) return;
    try {
      return unescape(comment);
      // return decodeURIComponent(comment);
    } catch (error) {
      console.error("In TicketNoteHelper.getDecodedComment() could not decode uri:");
      console.error(error);
      return comment;
    }

  }

  //TODO: improve this
  private getIsRequester(note: TicketNote, entityTickets: EntityTicket[]): boolean {
   
    for (const eTicket of entityTickets) {
      const { entity_type, hex_related_entity_id: relatedEntityHex, related_entity } = eTicket ?? {};
      const isRequesterType = entity_type?.description === "Requester";

      if (!isRequesterType) {
        continue;
      }

      const hexMatches = note?.hex_related_entity_id === relatedEntityHex;
      const emailMatches = note?.related_entity?.email
        ?.toLowerCase()
        ?.includes(related_entity?.email?.toLowerCase())

      if (relatedEntityHex && (hexMatches || emailMatches)) {
        return true;
      }
    }

    return false
  }

  private validateTimeField(): [ChatData[], ChatData[]] {
    const ticketEvents = this.getTicketEvents();
    const attachments: Attachment[] = this.customerTicket?.attachments ? this.customerTicket?.attachments : [];
    const hasInsertedAtTime: ChatData[] = [];
    const doesNotHaveTime: ChatData[] = [];

    [...this.sanitizedTicketNotes, ...this.sanitizedHTMLNotes, ...attachments, ...ticketEvents].forEach(item => {
      if (!DataHandler.isDefined(item)) return; //skip to next item
      if (!DataHandler.isDefined(item?.inserted_at)) {
        doesNotHaveTime.push(this.getChatDataWithType(item));
      }
      else hasInsertedAtTime.push(this.getChatDataWithType(item));
    });
    return [hasInsertedAtTime, doesNotHaveTime];
  }

  private getChatDataWithType(item: Attachment | SanitizedChatNote | TicketNote): ChatData {
    //only a SanitizedChatNote will have floatDirection
    if ((item as SanitizedChatNote)?.chatType) {
      return { chatType: (item as SanitizedChatNote).chatType, data: item };
    }
    else if ((item as Attachment)?.attachment_type) return { chatType: "attachment", data: item };
    else if (this.isValidTicketEvent(item)) return { chatType: "event", data: item };
  }

  private getTicketEvents() {
    const ticketEvents = this?.customerTicket?.notes?.filter(note => this.isValidTicketEvent(note));
    if (!ticketEvents) return [];
    return ticketEvents;
  }

  private isValidTicketEvent(note: TicketNote) {
    if (!note?.channel_id) return false;
    return note?.channel_id === NOTE_CHANNELS.EVENTS;
  }

  reverseDirection(sanitizedNote: SanitizedChatNote) {
    return sanitizedNote?.floatDirection === "left" ? "right" : "left";
  }

  refreshNotes() {
    this.sanitizedTicketNotes = [];
    this.chatDataList = [];
    this.populateChatDataList();
  }

}
