import { Utils } from "src/app/Utils";
import { NewOrder, NewOrderCustomerDetails, OrderResponse } from "../interfaces/create-order.interface";
import { PaymentLinkType } from "../types/payment-link.interface";

export class CreateNewRainOneOrder {
  static readonly type = Utils.Helpers.Type('[Sales] Create a OneRain Order');
  constructor(public comms: PaymentLinkType, public payload: NewOrder, public customer: NewOrderCustomerDetails) { }
}

export class CreateNewRainOneOrderSuccessful {
  static readonly type = Utils.Helpers.Type('[Sales] Create a OneRain Order Success');
  constructor(
    public comms: PaymentLinkType,
    public response: OrderResponse,
    public customer: NewOrderCustomerDetails,
    public createOrderAction: CreateNewRainOneOrder
  ) { }
}

export class CreateNewRainOneOrderFail {
  static readonly type = Utils.Helpers.Type('[Sales] Create a OneRain Order Fail');
  constructor(public error: unknown) { }
}

export class RecreateMostRecentOrder {
  static readonly type = Utils.Helpers.Type('[Sales] Recreate most recent order');
  constructor(public orderNumber: string | number) { }
}

