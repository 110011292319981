import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DEFAULT_HTTP_OPTIONS } from 'src/app/constants';
import { environment } from 'src/environments/environment';
import { DigitalIdentityCustomer } from '../types/digital-identity-by-customer-id-response.interface';
import { AssignedSim } from '../types/assigned-sim-by-user-id-response.interface';

@Injectable({
  providedIn: "root"
})
export class DigitalIdentityService {

  constructor(
    private http: HttpClient
  ) { }

  fetchDigitalIdentityByUserId(customerId: string) {
    const url = `${environment.main_proxy}/axiom/digital-identity/digitalIdentity/${customerId}`;
    return this.http.get<DigitalIdentityCustomer>(url, DEFAULT_HTTP_OPTIONS);
  }

  fetchAssignedSimsByUserId(customerId: string) {
    const url = `${environment.main_proxy}/axiom/digital-identity/assigned/primary/${customerId}`;
    return this.http.get<AssignedSim>(url, DEFAULT_HTTP_OPTIONS);
  }

}
