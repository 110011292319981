import { Component, OnInit } from '@angular/core';
import { SnowflakeSelectOption } from 'src/app/shared/elements/rain-forms/elements/snowflake-select/interfaces/rain-select-option.interface';
import { createSFselectOptions } from 'src/app/shared/functions/create-sf-select-options.function';
import { PostTicketModalOptions } from 'src/app/shared/services/post-ticket/post-ticket-modal-options.model';
import { NETWORK_QUERY_OPTIONS, BILLING_QUERY_OPTIONS, MOBILE_QUERY_OPTIONS, QUERY_OUTCOME_OPTIONS, CONNECTION_TYPE_OPTIONS, ESCALATION_OPTIONS } from './assets/rain101-ticket-options.constant';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngxs/store';
import { PostTicketService } from 'src/app/shared/services/post-ticket/post-ticket.service';
import { FormHelper } from 'src/app/Utils/helpers';
import { Subject } from 'rxjs';
import { ToggleModalByName } from 'src/app/core/store/actions/modal.actions';
import { InteractionTicketsState } from '../../store/state/interaction-tickets.state';
import { ChatJsonNote } from '../components/chat-json-note/assets/chat-json-note.interface';
import { CoreState } from 'src/app/core/store/state/core.state';
import { BaseAgent } from 'src/app/core/interfaces/agent.interface';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'sf-rain101-post-close-modal',
  templateUrl: './rain101-post-close-modal.component.html',
  styleUrls: ['./rain101-post-close-modal.component.scss']
})
export class Rain101PostCloseModalComponent extends FormHelper implements OnInit {
  modalOptions: PostTicketModalOptions;
  networkQueryOptions: SnowflakeSelectOption[] = createSFselectOptions(NETWORK_QUERY_OPTIONS);
  billingQueryOptions: SnowflakeSelectOption[] = createSFselectOptions(BILLING_QUERY_OPTIONS);
  mobileQueryOptions: SnowflakeSelectOption[] = createSFselectOptions(MOBILE_QUERY_OPTIONS);
  queryOutcomeOptions: SnowflakeSelectOption[] = createSFselectOptions(QUERY_OUTCOME_OPTIONS);
  escalationReasons: SnowflakeSelectOption[] = createSFselectOptions(ESCALATION_OPTIONS);
  connectionTypeOptions: SnowflakeSelectOption[] = createSFselectOptions(CONNECTION_TYPE_OPTIONS);
  isEscalated = false;
  agent: BaseAgent = null;
  isRain101Agent = false;
  isMistake = false;

  form: FormGroup;
  labelStyle = "thin-large-font";
  ngDestroy$: Subject<void> = new Subject();


  constructor(private fb: FormBuilder, private store: Store, private postTicketService: PostTicketService) {
    super();
  }

  ngOnInit(): void {
    this.modalOptions = new PostTicketModalOptions();

    this.agent = this.store.selectSnapshot(CoreState.getAgent);

    this.isRain101Agent = this.agent.roles.includes('CEC.THE101.A');

    this.form = this.fb.group({
      network_query: this.fb.control(null),
      billing_query: this.fb.control(null),
      mobile_query: this.fb.control(null),
      general: this.fb.control(''),
      mistake: this.fb.control(false),
      query_outcome: this.fb.control(null, Validators.compose([Validators.required])),
      escalation_type: this.fb.control(null),
      connection_type: this.fb.control(null, Validators.compose([Validators.required])),
    })
    this.InitForm(this.form);
    this.listenToFormChanges();
  }

  onSelect(controlName: string, value: string) {
    this.getControl(controlName)?.patchValue(value);

    if (controlName === 'query_outcome') {
      if (value === 'Escalated') {
        this.isEscalated = true;
        this.addRemoveValidators('escalation_type', true);
      } else {
        this.isEscalated = false;
        this.addRemoveValidators('escalation_type', false);
      }
    }
  }

  private addRemoveValidators(controlName: string, required: boolean) {

    const control = this?.getControl(controlName);
    if (required) {
      control?.addValidators(Validators.required);
      control?.updateValueAndValidity();
    }
    else {
      control?.removeValidators(Validators?.required);
      control?.patchValue(null);
    }
  }


  getPayload() {
    const formValues = this.form.getRawValue();

    const payload = {};
    const shortTicket = this.store.selectSnapshot(InteractionTicketsState.getSelectedShortTicket);
    payload["ticket_hex_id"] = shortTicket.hex_id;
    payload["ticket_number"] = shortTicket.external_id;
    payload["start_time"] = shortTicket.inserted_at;
    payload["completion_time"] = shortTicket.updated_at;
    payload["customer_info"] = { ...this.postTicketService.getRelevantPostTicketCustomerData() };
    payload["agent_info"] = { "agent_name": this.agent.name }
    payload["feedback"] = { ...formValues };
    return payload;
  }
  listenToFormChanges() {
    this.events('mistake').pipe(takeUntil(this.ngDestroy$)).subscribe({
      next: (res) => {
        if (res) {
          this.isMistake = true;
          this.addRemoveValidators('network_query', false);
          this.addRemoveValidators('billing_query', false);
          this.addRemoveValidators('mobile_query', false);
          this.addRemoveValidators('general', false);

        } else {
          this.isMistake = false;
        }
      },
    });
  }


  onSend() {
    const data = this.getPayload();
    const payload: ChatJsonNote = {
      message: "rain 101 post close form",
      detail: data,
      type: "rain101_post_ticket_form"
    }


    const hexId = this.store.selectSnapshot(InteractionTicketsState.getSelectedHexId);
    this.postTicketService.sendPostTicketPayload(
      hexId, payload, this.modalOptions, this.close.bind(this)
    );
  }

  close() {
    this.store.dispatch(new ToggleModalByName("rain_101_post_close_modal", false));
  }

  ngOnDestroy(): void {
    this.ngDestroy$.next(null);
    this.ngDestroy$.complete();
    this.close();
  }
}
