import { Injectable } from '@angular/core';
import { State, Selector, Action, StateContext, createSelector, Store } from '@ngxs/store';
import { AuthService } from 'src/app/auth/auth.service';
import { Utils } from 'src/app/Utils';
import { Agent, BaseAgent } from '../../interfaces/agent.interface';
import { Group } from '../../interfaces/groups.interface';
import { GroupService } from '../../services/groups.service';
import { AssignAgent, FetchAllAgents, FetchAllAgentsFail, FetchAllAgentsSuccess, StoreAgentRoles, UpdateAgentRoles } from '../../../shared/components/agent/store/actions/agent.actions';
import * as fromGroupsActions from '../actions/groups.actions';
import { RainAgent } from 'src/app/shared/components/agent/rain-agent.service';
import { environment } from 'src/environments/environment';
import { DataHandler } from 'src/app/shared/data-handler/data-handler';
import { AzureProfile } from 'src/app/profile/assets/azure-profile.interface';
import { SetPersistentStorageItem } from '../actions/persistent-storage.actions';
import { AllAgentRoleOption } from 'src/app/shared/interfaces/agent-role-options.type';
import { tap } from 'rxjs/operators';
import { APMWrapperService } from '../../services/apm-wrapper.service';
import { InteractionTicketsState } from 'src/app/interactions/store/state/interaction-tickets.state';
import { TICKET_STATES } from 'src/app/shared/customer-ticket/ticket-event-handler/assets/ticket.constants';
import { ShortTicket } from 'src/app/shared/customer-ticket/interfaces/customer-ticket.interface';
import { OrderMetaData } from 'src/app/sales/sales-portal/components/level-up/store/types/migrate-level.interfaces';
import { Dictionary } from 'src/app/shared/interfaces/dictionary.interface';
import { AgentState, RainAgentStateModel } from 'src/app/shared/components/agent/store/state/agent.state';
import { AgentTrackingDetails } from 'src/app/shared/components/agent/store/types/agent-tracking-details.interface';
import { QueueData } from 'src/app/sip-phone/services/sip-platform/assets/voice-queue-info.interface';
import { AgentVoiceQueueState } from 'src/app/sip-phone/store/state/agent-voice-queue-state';
import { CustomerInfoState } from 'src/app/customer-info-summary-page/store/state/customer-info.state';

export interface CoreStateModel {
    loading: boolean;
    loaded: boolean;
    agent: BaseAgent;
    layout: string;
    agents: Dictionary<AzureProfile>;
    groups: Dictionary<Group>;
}

@State<CoreStateModel>({
    name: 'core',
    defaults: {
        loading: false,
        loaded: false,
        layout: null,
        agent: null,
        agents: {},
        groups: null
    }
})
@Injectable()
export class CoreState {

    @Selector()
    static isLoading(state: CoreStateModel) { return state.loading; }

    @Selector()
    static hasLoaded(state: CoreStateModel) { return state.loaded; }

    @Selector()
    static getAgent(state: CoreStateModel) { return state.agent; }

    @Selector()
    static getAgentEmail(state: CoreStateModel) { return state.agent?.email; }

    @Selector()
    static getAgentRoles(state: CoreStateModel) { return state.agent?.roles; }

    @Selector()
    static getAllGroups(state: CoreStateModel) { return Utils.Mappers.FromHashMap<Group>(state.groups); }

    @Selector()
    static getAgentGroup(state: CoreStateModel) {
        const agentRole = Utils.SfHelpers.getTeamRole(state?.agent?.roles);
        const groups = Utils.Mappers.FromHashMap<Group>(state.groups);

        return groups?.find((g) => g?.description === agentRole);
    }

    static getGroupNameById(id: number) {
        return createSelector([CoreState], (state: CoreStateModel) => {
            const groups = Utils.Mappers.FromHashMap<Group>(state.groups);
            return groups?.find((g) => g.id === id);
        });
    }

    @Selector()
    static getTeamRole(state: CoreStateModel): string | null {
        return Utils.SfHelpers.getTeamRole(state?.agent?.roles);
    }

    static isInTeam(team: string) {
        return createSelector([CoreState], (state: CoreStateModel) => {
            const teamRole = Utils.SfHelpers.getTeamRole(state?.agent?.roles);
            return teamRole?.includes(team?.toUpperCase());
        });
    }

    @Selector([CoreState.isInTeam("SALES"), CoreState.hasOneOfTheseRoles(["CEC.UPGRADES.A", "CEC.NO_CONTACT.CCI", "RETAIL.CUSTOMER_ENGAGEMENT.A"])])
    static isInSales(inSales: boolean, hasSalesRelatedRole: boolean) { return inSales || hasSalesRelatedRole }

    @Selector([CoreState.hasOneOfTheseRoles(["AUTH.USER.ADMIN"])])
    static isAdmin(isAdmin: boolean) { return isAdmin }

    static hasOneOfTheseRoles(selectedRoles: AllAgentRoleOption[]) {
        return createSelector([CoreState], (state: CoreStateModel) => {
            const roles = state?.agent?.roles;
            if (!roles?.length || !selectedRoles?.length) {
                return false;
            }

            const lowercaseRoles = roles.map(role => role?.toLowerCase());

            for (const selectedRole of selectedRoles) {
                if (lowercaseRoles?.includes(selectedRole?.toLowerCase())) {
                    return true;
                }
            }
            return false;
        });
    }

    @Selector([CoreState.getAgentGroup, InteractionTicketsState.getTickets])
    static getOpenTicketsForGroup(agentGroup: Group, tickets: ShortTicket[]) {

        return tickets?.filter((t) =>
            ![TICKET_STATES.HELD, TICKET_STATES.CLOSED].includes(t.state_id) && t.ticket_type_id === agentGroup?.id
        ) ?? [];
    }

    @Selector([CoreState.getAgentEmail, CoreState.getTeamRole, CoreState.isInSales])
    static getBasicAgentDetails(email: string, team: string, isInSales: boolean) {
        return {
            email,
            team,
            isInSales
        }
    }

    @Selector([CoreState.getAgentEmail, InteractionTicketsState.getCurrentHexId])
    static getOrderMetaData(agentEmail: string, ticketHexId: string): OrderMetaData {
        return {
            agent_email: agentEmail,
            ticket_hex_id: ticketHexId
        }
    }

    //TODO: split some of this out so core state can be properly used in other places
    @Selector([
        AgentState,
        CoreState.getAgent,
        CoreState.getTeamRole,
        AgentVoiceQueueState.getQueue
    ])
    static getAgentTrackingDetails(
        agentStateModel: RainAgentStateModel,
        agent: BaseAgent,
        groupName: string | null,
        voiceQueue: QueueData | null
    ): AgentTrackingDetails {

        const { name, email } = agent ?? {};
        const { status, acceptingCalls, acceptingTickets } = agentStateModel;

        return {
            name,
            email,
            status,
            acceptingCalls,
            acceptingTickets,
            groupName,
            queue: voiceQueue?.name,
        }
    }

    @Selector([
        CustomerInfoState.getCustomerEmail,
        CoreState.getAgentEmail,
    ])
    static isAgentProfile(customerEmail: string, agentEmail: string) {
        return customerEmail.toLowerCase() === agentEmail.toLowerCase();
    }

    constructor(
        private _svc: AuthService,
        private _gSVC: GroupService,
        private store: Store,
        private rainAgent: RainAgent,
        private apmWrapperService: APMWrapperService
    ) { }

    @Action(AssignAgent)
    assignAgent(ctx: StateContext<CoreStateModel>, action: AssignAgent) {
        const payload = action.payload;
        ctx.patchState({
            agent: {
                ...payload
            }
        });
        ctx.dispatch(new StoreAgentRoles(payload.roles));
        this.apmWrapperService.initializeAPMLogger(payload?.email);
    }

    @Action(UpdateAgentRoles)
    updateAgentRoles(ctx: StateContext<CoreStateModel>, action: UpdateAgentRoles) {
        if (environment.production) {
            //agent roles should not be updateable in prod
            return;
        }

        const state = ctx.getState();
        const roles = action.roles;

        const agentCopy = DataHandler.createDeepCopy(state.agent);
        agentCopy.roles = roles;

        ctx.patchState({
            agent: agentCopy
        });

        //TODO: move a lot more of the rainAgent into the store WIP
        this.rainAgent.getAgentDetails()?.then(details => details.roles = roles);
    }


    @Action(StoreAgentRoles)
    storeAgentRoles(ctx: StateContext<CoreStateModel>, action: StoreAgentRoles) {
        //Agent roles are stored in persistent storage so it can be retrieved by the authGuard service
        //once azure redirects back to the page
        this.store.dispatch(new SetPersistentStorageItem("agent-roles", action.roles));
    }

    @Action(FetchAllAgents)
    fetchAllAgents(ctx: StateContext<CoreStateModel>) {
        return this._svc.getAllAgents()
            .pipe(tap({
                next: (res) => ctx.dispatch(new FetchAllAgentsSuccess(res)),
                error: (e: unknown) => ctx.dispatch(new FetchAllAgentsFail(e))
            }))
    }

    @Action(FetchAllAgentsSuccess)
    fetchAllAgentsSuccess(ctx: StateContext<CoreStateModel>, action: FetchAllAgentsSuccess) {
        const state = ctx.getState();
        const payload = action.payload;

        ctx.patchState({
            agents: Utils.Mappers.ToHashMap<Agent>(<Agent[]>payload.value, state.agent, 'id')
        })
    }

    @Action(fromGroupsActions.FetchAllGroups)
    fetchAllGroups(ctx: StateContext<CoreStateModel>) {
        return this._gSVC.getAllGroups()
            .pipe(tap({
                next: (res) => ctx.dispatch(new fromGroupsActions.FetchAllGroupsSuccess(res.data)),
                error: (e: unknown) => ctx.dispatch(new fromGroupsActions.FetchAllGroupsFail(e)),
            }))
    }

    @Action(fromGroupsActions.FetchAllGroupsSuccess)
    fetchAllGroupsSuccess(ctx: StateContext<CoreStateModel>, action: fromGroupsActions.FetchAllGroupsSuccess) {
        const state = ctx.getState();
        const payload = action.payload;

        ctx.patchState({
            groups: Utils.Mappers.ToHashMap(payload, state, 'id')
        });
    }


}
