import { Utils } from "src/app/Utils";

export class FetchCurrentLoadsheddingReport {
  static readonly type = Utils.Helpers.Type('[Loadshedding: Fetch] Fetch current loadshedding report');
}

export class FetchCurrentLoadsheddingReportSuccess {
  static readonly type = Utils.Helpers.Type('[Loadshedding: Fetch] Fetch current loadshedding report success');
  constructor(public payload: any) { }
}

export class FetchCurrentLoadsheddingReportFail {
  static readonly type = Utils.Helpers.Type('[Loadshedding: Fetch] Fetch current loadshedding report fail');
  constructor(public error: any) { }
}

export class StartLoadsheddingReportRefetchLoop {
  static readonly type = Utils.Helpers.Type('[Loadshedding: Refetch] Start loadshedding report refresh loop');
}


export class SetCurrentLoadsheddingReportError {
  static readonly type = Utils.Helpers.Type('[Loadshedding: Set] Set current loadshedding report error');
  constructor(public errorMessage: string) { }
}

export class CloseCurrentLoadsheddingReport {
  static readonly type = Utils.Helpers.Type('[Loadshedding: Close] Close current loadshedding report');
}
