<div
    [class.minimized-modal]="minimized"
    class="basic-phone-modal main-div"
>
    <div class="content">

        <span style="display: flex; justify-content: center; align-items: center;">
            <span
                *ngIf="callStateMessage === 'connected'"
                class="header font-normal text-white"
                style="margin: .1rem;"
            >
                <button
                    title="Transfer"
                    mat-icon-button
                    [matMenuTriggerFor]="menu"
                    aria-label="Example icon-button with a menu"
                >
                    <mat-icon>phone_forwarded</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                    <button
                        (click)="transferCall(callQueue)"
                        *ngFor="let callQueue of callQueues"
                        mat-menu-item
                    >
                        <mat-icon>{{callQueue.icon}}</mat-icon>
                        <span>{{callQueue.name}}</span>
                    </button>
                </mat-menu>
            </span>
            <h3
                *ngIf="!(minimized && callStateMessage ==='connected')"
                style="margin: .1rem;"
                class="header font-normal text-white"
            >
                {{callStateMessage}}</h3>
        </span>
        <mat-icon
            class="default-cancel-icon"
            (click)="toggleMinimized()"
        >minimize</mat-icon>

        <div
            [class.top-section-minimized]="minimized"
            [class.hidden]="(minimized && callStateMessage !=='connected')"
        >
            <div class="flex-content call-number-div">
                <app-call-timer *ngIf="showCallTimer"></app-call-timer>
                <h3
                    *ngIf="callStateMessage !=='make a call'"
                    class="text text-white"
                >{{callerNumber}}</h3>
                <input
                    *ngIf="callStateMessage ==='make a call'"
                    class="textBox"
                    type="text"
                    [(ngModel)]="callerNumber"
                >
            </div>
        </div>

        <div
            [class.hidden]="minimized"
            class="keypad-wrapper"
        >
            <div class="keypad">
                <div
                    class="key flex-content pointer"
                    *ngFor="let key of keypadKeys"
                    (click)="addClickedKey(key)"
                >
                    {{key}}
                </div>
            </div>
        </div>

        <div
            *ngIf="!minimized"
            class="button-wrapper"
        >
            <div class="button-group">
                <sf-button
                    icon="ring_volume"
                    text="end"
                    *ngIf="callStateMessage !=='make a call'"
                    buttonClass="blank-button decline-button"
                    (click)="onEndCall()"
                ></sf-button>

                <sf-button
                    icon="phone_forwarded"
                    text="call"
                    buttonClass="blank-button accept-button"
                    *ngIf="callStateMessage ==='make a call'"
                    (click)="onCall()"
                    [disabled]="sipPhoneService.activeSession"
                >
                </sf-button>

                <sf-button
                    icon="pause"
                    text="hold"
                    *ngIf="callStateMessage ==='connected'"
                    buttonClass="blank-button pause-button"
                    (click)="onHold()"
                ></sf-button>

                <sf-button
                    icon="play_arrow"
                    text="resume"
                    *ngIf="callStateMessage ==='on hold'"
                    buttonClass="blank-button accept-button"
                    (click)="resumeCall()"
                ></sf-button>
            </div>
        </div>

    </div>
</div>