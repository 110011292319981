<div *ngIf="open" class="overlay">
  <div *ngIf="isFeatureFlagModalOpen$ | async">
    <sf-feature-flag-selector-modal></sf-feature-flag-selector-modal>
  </div>
</div>

<div *ngIf="open" class="sidenav-container noscroll" [class.less-z]="isFeatureFlagModalOpen$ | async">
  <div class="sidenav-content">
    <img src="assets\images\rain-logo.png" class="rain-logo" alt="rain logo" />

    <div class="profile-items scrollable">
      <img class="avatar" [src]="profileImage" alt="Avatar" />
      <p>{{ profile?.displayName }}</p>
      <div class="status-modal">
        <app-agent-status-modal class="agent-status-modal" width="5rem" height="2rem"></app-agent-status-modal>
      </div>


      <p>login time: 
        <span class="text-blue" *ngIf="{time: (loggedOnTime$ | async)} as loggedOnDetails">
          {{ loggedOnDetails?.time ? (loggedOnDetails?.time | date: 'h:mm:ss a') : "--" }}
        </span>
      </p>
      <div class="team_role">
        <p>
          roles:
        </p>
        <ng-container *ngIf="agentRoles$ | async as agentRoles">
          <ul *ngFor="let agentRole of agentRoles">
            <li class="text-blue">{{agentRole}}</li>
          </ul>
        </ng-container>
      </div>

      <div class="info-container">
        <p>voice login status: </p>
        <span>
          <ng-container *ngIf="voiceLoginError$ | async as voiceError; else voiceStatus">
            <p class="red">{{voiceError}}</p>
          </ng-container>
          <ng-template #voiceStatus>
            <p class="text-blue">{{voiceLoginStatus$ | async}}</p>
          </ng-template>

          <ng-container *ngIf="(voiceLoginLoading$ | async) || (voiceAuthLoading$ | async); else loginStatusLoaded">
            <div class="spinner-wrapper">
              <small-text-spinner size="15px"></small-text-spinner>
            </div>
          </ng-container>

          <ng-template #loginStatusLoaded>
            <div class="refresh-wrapper">
              <custom-icon-button matTooltip="refresh voice platform login" (click)="retryVoiceLogin()" size="1.7rem">
                refresh
              </custom-icon-button>
            </div>
          </ng-template>

        </span>
      </div>

      <div class="info-container">
        <p>
          voice queues:
        </p>
        <ng-container *ngIf="(allQueues$ | async) as voiceQueues;">

          <ng-container *ngIf="voiceQueues?.length > 0; else noQueues">
            <ul *ngFor="let queue of voiceQueues">
              <li class="text-blue">{{queue?.name}}</li>
            </ul>
          </ng-container>
          <ng-template #noQueues>
            <p class="red">none</p>
          </ng-template>
        </ng-container>
      </div>

      <div class="info-container">
        <p>
         actions
        </p>
        <sf-rain-button [matMenuTriggerFor]="supportTypesMenu" (click)="getSupportTypes()">agent support
        </sf-rain-button>
        <mat-menu #supportTypesMenu="matMenu" yPosition="above">
          <div *ngFor="let supportType of supportTypes">
            <button mat-menu-item [matMenuTriggerFor]="optionsMenu" (mouseenter)="getOptions(supportType)"
              class="font-normal text-blue">
              {{supportType}}
            </button>
          </div>
        </mat-menu>
        <mat-menu #optionsMenu yPosition="above">
          <div *ngIf="isFAQ; else notFAQ">
            <button mat-menu-item *ngFor="let option of options" (mouseenter)="getQuestions(option)"
              [matMenuTriggerFor]="nestedOptions" class="font-normal text-blue">
              {{option}}
            </button>
          </div>
          <ng-template #notFAQ>
            <button mat-menu-item *ngFor="let optione of options" (mouseenter)="getAnswers(optione)"
              class="font-normal text-blue">
              {{optione}}
            </button>
          </ng-template>
        </mat-menu>
        <div>
          <mat-menu #nestedOptions yPosition="above">
            <button mat-menu-item *ngFor="let question of questions" (mouseenter)="getAnswers(question)"
              class="font-normal text-blue">
              {{question}}
            </button>
          </mat-menu>
        </div>

        <sf-rain-button (click)="showCallbacks()">show callbacks
        </sf-rain-button>

      </div>

      <ng-container *ngIf="canSwitchEnvironments">
        <div class="info-container">
          <p>
            current environment
          </p>
          <ul>
            <li class="text-blue">{{currentEnvironment}}</li>
          </ul>
          <sf-rain-button (click)="toggleEnvironment()">switch environment</sf-rain-button>
        </div>


        <div class="info-container">
          <p class="pointer" [matMenuTriggerFor]="menu">change team
            <mat-icon class="text-blue">arrow_drop_down</mat-icon>
          </p>
          <mat-menu #menu="matMenu" yPosition="above">
            <button mat-menu-item *ngFor="let option of roleOptions" class="font-normal text-blue"
              (click)="setTeamRole(option)">
              {{option}}
            </button>
          </mat-menu>
        </div>
        <div class="info-container">
          <sf-auth-role-setter></sf-auth-role-setter>
        </div>

        <div class="info-container">
          <sf-rain-button (click)="openFeatureFlagModal()">set feature flags</sf-rain-button>
        </div>

      </ng-container>

    </div>
  </div>