<div [class.hover-card]="clickable" class="rain-card" (click)="toggleFlip()" [@flipState]="flip" [style.width]="width"
  [style.height]="height">
  <!-- Front Card -->
  <div class="rain-card__side rain-card__front" [style.background-color]="frontColor" [style.padding]="padding">

    <ng-container *ngIf="showDogEar">
      <div class="dog-ear-cover">
        <div class="dog-ear">
        </div>
      </div>
      <span class="dog-ear-text">
        <span>{{dogEarMessage}}</span>
      </span>
    </ng-container>

    <ng-container *ngIf="showSimAssigned">
      <div class="sim-assigned-container">
        <p>
          {{simAssignedText}}
        </p>
      </div>
    </ng-container>

    <ng-content select="[front]"></ng-content>
  </div>
  <!-- Back Card -->
  <div class="rain-card__side rain-card__back" [style.background-color]="backColor" [style.padding]="padding">
    <ng-content select="[back]"></ng-content>
  </div>
</div>