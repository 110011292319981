import { Selector } from "@ngxs/store";
import { AgentCallbackState, AgentCallbackStateModel } from "../../../store/state/agent-callback.state";
import { CallbackNotification } from "../../../store/types/callback-response.interface";
import { UpcomingCallbackViewModel } from "./upcoming-callback-view-model.interface";
import { ModalState } from "src/app/core/store/state/modal.state";
import { CallbackModalData } from "../../../store/types/callback-modal-data.interface";
import { Utils } from "src/app/Utils";

export class AgentCallbackSelector {


    @Selector([
        AgentCallbackState,
        AgentCallbackState.getFilteredCallbacks,
        ModalState.getModalData("upcoming_callback_modal")
    ])
    static getViewModel(
        state: AgentCallbackStateModel,
        filteredCallbacks: CallbackNotification[],
        modalData: CallbackModalData | null
    ): UpcomingCallbackViewModel {

        const { loading, loaded, error, silentlyLoading } = state ?? {};

        return {
            data: Utils.Helpers.SortBy(filteredCallbacks ?? [], "scheduled_date_time", "asc"),
            loading,
            loaded,
            error,
            silentlyLoading,
            selectedId: modalData?.callbackId
        }

    }
}