<ng-container *ngIf="incomingModalOpen"> incomingModalOpen
  <div [class.minimized-modal]="minimized" class="basic-phone-modal main-div">
    <div class="content">
      <span style="display: flex; justify-content: center; align-items: center;">
        <span *ngIf="callStateMessage === 'connected'" class="header font-normal text-white" style="margin: .1rem;">
          <button title="Transfer" mat-icon-button [matMenuTriggerFor]="menu"
            aria-label="Example icon-button with a menu">
            <mat-icon>phone_forwarded</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button (click)="transferCall(callQueue)" *ngFor="let callQueue of callQueues" mat-menu-item>
              <mat-icon>{{callQueue.icon}}</mat-icon>
              <span>{{callQueue.name}}</span>
            </button>
          </mat-menu>
        </span>
        <h3 *ngIf="!(minimized && callStateMessage ==='connected')" style="margin: .1rem;"
          class="header font-normal text-white">
          {{getCallMessage(callStateMessage)}}</h3>
      </span>
      <mat-icon *ngIf="canMinimize" class="default-cancel-icon" (click)="toggleMinimized()">minimize</mat-icon>

      <div [ngClass]="minimized? 'top-section-minimized': 'center-container'">
        <div [ngClass]="minimized? 'flex-content': 'text-center'" class="call-number-div">
          <app-call-timer *ngIf="showCallTimer"></app-call-timer>
          <h3 class="text text-white">{{displayName}}</h3>
        </div>
      </div>

      <div *ngIf="!minimized" class="button-wrapper">
        <div class="button-group">

          <!-- Incoming call -->
          <ng-container *ngIf="callStateMessage==='incoming call'">
            <sf-button icon="ring_volume" text="decline" buttonClass="blank-button decline-button"
              (click)="onRejectCall()"></sf-button>

            <sf-button icon="phone_forwarded" text="accept" buttonClass="blank-button accept-button"
              (click)="onAcceptCall()"></sf-button>
          </ng-container>

          <!-- Connected or connecting call -->
          <sf-button icon="ring_volume" *ngIf="canEndCallMessages.includes(callStateMessage)" text="end"
            buttonClass="blank-button decline-button" (click)="onEndCall()"></sf-button>

          <sf-button icon="pause" text="hold" *ngIf="callStateMessage ==='connected'"
            buttonClass="blank-button pause-button" (click)="onHold()"></sf-button>

          <sf-button icon="play_arrow" text="resume" *ngIf="callStateMessage ==='on hold'"
            buttonClass="blank-button accept-button" (click)="resumeCall()"></sf-button>

        </div>
      </div>

    </div>
  </div>
</ng-container>