

export function getTeamRole(roles: string[]): string | null {
    if (!roles) {
        return null;
    }

    return roles.find(role => {
        if (role?.toLowerCase()?.includes("auth")) {
            return false;
        }
        return role?.includes(".");
    });
}
