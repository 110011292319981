import { Component, Input } from '@angular/core';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { ButtonHelper } from 'src/app/Utils/helpers/button.helper';

@Component({
  selector: 'rain-edit-button',
  templateUrl: './rain-edit-button.component.html',
  styleUrls: ['./rain-edit-button.component.scss']
})
export class RainEditButtonComponent extends ButtonHelper {

  editIcon = faEdit;
  @Input() disabled = false;

}
