export const PROMISE_TO_PAY_STATUS_TYPES = {
  ACTIVE: 1,
  PENDING: 2,
  CANCELLED: 3,
  OVERDUE: 4,
  SETTLED: 5,
  FAILED: 6,
  MANUAL_PAY: 7,
  NO_PAYMENT: 8
} as const;

export type P2PStatusType = keyof typeof PROMISE_TO_PAY_STATUS_TYPES;
