import { Component, OnDestroy, OnInit } from '@angular/core';
import { AgentControlService } from './agent-control.service';
import { Subject } from 'rxjs';

@Component({
    selector: 'sf-agent-control',
    template: ''
})
export class AgentControlComponent implements OnInit, OnDestroy {

    private readonly destroy$ = new Subject<void>()

    constructor(private agentControlService: AgentControlService) { }

    ngOnInit(): void {
        this.agentControlService.listenToForceAvailableMessage(this.destroy$)
    }

    ngOnDestroy(): void {
        this.destroy$.next(null);
        this.destroy$.complete();
    }

}
