import { unitOfTime } from "moment";
import { PaymentType } from "src/app/core/store/interfaces/rain-product.interface";
import { ProductFunctions } from "src/app/core/store/state/product-state/assets/product-functions";
import { PRODUCT_ID_NAME_MAP } from "src/app/shared/constants/product-id-to-name-mapping.constant";
import { CUSTOMER_SERVICE_STATUS } from "src/app/shared/constants/service-status.constant";
import { DataHandler } from "src/app/shared/data-handler/data-handler";
import { AccountStatus } from "src/app/shared/interfaces/account-status.interface";
import { CustomerService, OrderRequest } from "src/app/shared/interfaces/smartsub-data.interface";
import { Utils } from "src/app/Utils";
import { CustomerOrder, OrderLine, UNSUCCESSFUL_ORDER_STATES } from "../../customer-order/customer-order-card/assets/customer-order.interface";
import { NewProxyCustomerService } from "src/app/customer-info-summary-page/store/interfaces/new-proxy-customer-service.interface";
import { CancellationType } from "../../services/cancellation.service";

export class SimMethods {


    static getFilteredServices(services: CustomerService[], maxCancelledAge: number): CustomerService[] {
        if (!services) return [];
        const uniqueServices = {};

        return services.filter(service => {
            const { id, status } = service ?? {};
            const serviceCancelled = status === CUSTOMER_SERVICE_STATUS["Service Canceled"];
            const serviceRecentlyCancelled = SimMethods.isServiceRecentlyCancelled(service, maxCancelledAge);

            if (!id || (id in uniqueServices)) {
                return;
            }

            if (!serviceCancelled || serviceRecentlyCancelled) {
                uniqueServices[id] = id
                return true;
            }
        });
    }

    static isServiceRecentlyCancelled(service: CustomerService, maxAge: number): boolean | null {
        const cancelDate = service?.meta?.cancel_date;
        if (!cancelDate) {
            return null;
        }

        const daysOld = DataHandler.getDiffInTime("now", cancelDate, "days");
        return daysOld <= maxAge;
    }

    static getSortedActiveServices(services: CustomerService[], order: "asc" | "desc") {
        if (!services?.length) {
            return [];
        }
        const activeServices = services?.filter(service => service?.status === 0);
        return Utils.Helpers.SortBy(activeServices, "activation_date", order);
    }

    static calculateSimAge(service: CustomerService | NewProxyCustomerService, timeUnit: unitOfTime.Diff) {
        if (!service?.activation_date) {
            return null;
        }
        return DataHandler.getDiffInTime("now", service?.activation_date, timeUnit);
    }

    static isCustomerInGoodStanding(accountStatus: AccountStatus) {
        const notAcceptableStatuses = ["overdue", "blacklist", "overdueptp", "suspended", "arrears"];
        if (notAcceptableStatuses.includes(accountStatus?.accountState?.toLowerCase())) {
            return false;
        }
        return true;
    }

    static isLevelDownScheduled(service: CustomerService) {
        if (!service) {
            return null;
        }

        const { action_type } = service?.scheduled_action?.find(action => action?.action_type === "product_migration") ?? {}

        return action_type === "product_migration";
    }

    static isSpeedDownScheduled(service: CustomerService) {
        if (!service) {
            return null;
        }

        const { action_type } = service?.scheduled_action?.find(action => action?.action_type === 'change_speed') ?? {}

        return action_type === "change_speed";
    }

    static isCancellationScheduled(service: CustomerService) {
        if (!service) {
            return null;
        }

        const { action_type } = service?.scheduled_action?.find(action => action?.action_type === 'Cancellation') ?? {}

        return action_type === "Cancellation";
    }

    static isPreCancellationScheduled(service: CustomerService) {
        if (!service) {
            return null;
        }

        const { action_type } = service?.scheduled_action?.find(action => action?.action_type === 'Pre-Cancel') ?? {}

        return action_type === "Pre-Cancel";
    }

    static hasScheduledActions(service: CustomerService) {
        const actions = service?.scheduled_action;
        if (!actions) {
            return false;
        }

        if (Array.isArray(actions)) {
            return actions?.length > 0;
        }
        if (typeof actions === "object") {
            return Object.keys(actions)?.length > 0;
        }
        return false;
    }

    static hasPendingActionsOrRequests(service: CustomerService) {
        return SimMethods.hasScheduledActions(service) || service?.order_requests?.length > 0;
    }

    static hasScheduled4Gto5GMigration(service: CustomerService) {
        if (!service) {
            return null;
        }

        const order_requests = service?.order_requests;
        const migrationRequest = order_requests?.find(
            request => request?.action === "migration" && request?.type === "4Gto5G"
        );

        return DataHandler.isDefined(migrationRequest);
    }

    static hasPendingSimSwop(orderRequests: OrderRequest[]) {
        if (!orderRequests?.length) {
            return false;
        }

        const request = orderRequests?.find(r => r?.action === "simswap");
        return DataHandler.isDefined(request);
    }

    static hasScheduledMigration(service: CustomerService) {
        const { action_type } = service?.scheduled_action?.find(action => action?.action_type === 'Change')
        return action_type === 'Change'
      }


    static is2for1MainService(service: CustomerService) {
        const main2for1Id = PRODUCT_ID_NAME_MAP['Unlimited home 5G Premium DUO1'];
        return (main2for1Id === service?.product_id);
    }


    static hasUpfrontOrder(orders: CustomerOrder[], products: { id: string, paymentType: PaymentType }[]) {
        if (!orders?.length) {
            return false;
        }

        for (const order of orders) {
            const pendingOrSuccessfulOrder = !UNSUCCESSFUL_ORDER_STATES.includes(order?.status);
            if (!pendingOrSuccessfulOrder) {
                //Skip
                continue;
            }

            for (const orderLine of order?.order_lines ?? []) {
                const productId = orderLine?.product_id;
                if (orderLine?.category === "product" && ProductFunctions.isUpfrontProduct(productId, products)) {
                    return true;
                }
            }
        }

        return false;
    }

    static hasPendingOrsuccessfulOrder(orders: CustomerOrder[]) {
        return orders?.some(order => {
            const pendingOrSuccessfulOrder = !UNSUCCESSFUL_ORDER_STATES.includes(order?.status);
            return pendingOrSuccessfulOrder;
        })
    }


    // Used to keep the message formatting consistent
    static getCancellationMessage(msisdn: string, type?: CancellationType) {
        return `${type ?? 'scheduled'} cancellation for ${msisdn}`;
    }

    static getRevertCancelMessage(msisdn: string) {
        return `revoked cancellation for ${msisdn}`;
    }

    static getCancelCompletedMessage(msisdn: string,) {
        return `completed cancellation for ${msisdn}`;
    }

    static findProductIdFromOrderLines(orderLines: OrderLine[]) {
        return orderLines?.find(item => item?.category === "product")?.product_id;
    }
}


