export const TicketStateDict: { [num: number]: { stateId: number, state: string } } = {
    0: {
        stateId: 0,
        state: 'INITIATED'
    },
    1: {
        stateId: 1,
        state: 'ACKNOWLEDGED'
    },
    2: {
        stateId: 2,
        state: 'REJECTED'
    },
    3: {
        stateId: 3,
        state: 'PENDING'
    },
    4: {
        stateId: 4,
        state: 'HELD'
    },
    5: {
        stateId: 5,
        state: 'IN PROGRESS'
    },
    6: {
        stateId: 6,
        state: 'CANCELLED'
    },
    7: {
        stateId: 7,
        state: 'CLOSED'
    },
    8: {
        stateId: 8,
        state: 'RESOLVED'
    }
};