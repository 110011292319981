import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-color-box',
  templateUrl: './color-box.component.html',
  styleUrls: ['./color-box.component.scss']
})
export class ColorBoxComponent {

  @Input() inputColor: string;
  @Input() fontColor: string;
  @Input() disabled = false;
  @Input() largeFontStyle = false;
}
