import { Component } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TODAY } from 'src/app/constants';
import { ClearLoadsheddingScheduleForSiteID, RefreshLoadsheddingForSiteID } from '../store/actions/loadshedding-schedule-per-sim.actions';
import { LoadsheddingReport } from '../store/interfaces/loadshedding-report.interface';
import { LoadsheddingSchedulePerSite } from '../store/interfaces/loadshedding-schedule-per-site';
import { CurrentLoadsheddingReportState } from '../store/state/current-loadshedding-report.state';
import { LoadsheddingForSiteIDstate } from '../store/state/loadshedding-schedule-for-site.state';

const NO_FILTER = "No Filter";

@Component({
  selector: 'sf-loadshedding-schedule-per-sim-card',
  templateUrl: './loadshedding-schedule-per-sim-card.component.html',
  styleUrls: ['./loadshedding-schedule-per-sim-card.component.scss']
})
export class LoadsheddingSchedulePerSimCardComponent {
  @Select(LoadsheddingForSiteIDstate.getData) schedulePerSim$: Observable<LoadsheddingSchedulePerSite>;
  @Select(LoadsheddingForSiteIDstate.isLoading) isLoading$: Observable<boolean>;
  @Select(LoadsheddingForSiteIDstate.isLoaded) isLoaded$: Observable<boolean>;
  @Select(LoadsheddingForSiteIDstate.isLoading) error$: Observable<boolean>;
  @Select(LoadsheddingForSiteIDstate.getCurrentSiteID) currentSiteID$: Observable<number>;
  @Select(LoadsheddingForSiteIDstate.isOpen) isOpen$: Observable<boolean>;
  @Select(CurrentLoadsheddingReportState.getData) currentReport$: Observable<LoadsheddingReport>;

  today = TODAY();

  private ngDestroy$ = new Subject<void>();
  filterOptions = [NO_FILTER, ...Array.from({ length: 8 }, (v, k) => (k + 1).toString())];
  currentFilter: string = NO_FILTER;
  currentLoadSheddingStage: string;

  constructor(private store: Store) {

  }

  ngOnInit() {
    this.currentReport$
      .pipe(
        takeUntil(this.ngDestroy$)
      )
      .subscribe((res) => {
        this.currentLoadSheddingStage = res.current.stage + '';
      })
  }

  setFilter(value: string) {
    this.currentFilter = value;
  }

  onRefresh() {
    this.store.dispatch(new RefreshLoadsheddingForSiteID());
  }

  onClose() {
    this.store.dispatch(new ClearLoadsheddingScheduleForSiteID());
  }
  ngOnDestroy() {
    this.ngDestroy$.next(null);
    this.ngDestroy$.complete();
  }
}
