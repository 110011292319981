import { Component, Input } from '@angular/core';

@Component({
  selector: 'sf-chat-letter-bubble',
  templateUrl: './chat-letter-bubble.component.html',
  styleUrls: ['./chat-letter-bubble.component.scss']
})
export class ChatLetterBubbleComponent {

  @Input() isCustomer = false;
  @Input() letter: string;

}
