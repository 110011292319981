import { Selector } from "@ngxs/store";
import { SimMethods } from "src/app/customer-data-components/sim-details/assets/sim-methods";
import { CustomerInfoStateModel } from "../interfaces/customer-info-state.model";
import { CustomerInfoState } from "../state/customer-info.state";
import { ExtendedServiceSelectors } from "./extended-service.selector";
import { ExtendedCustomerService } from "../interfaces/proxy-customer-service.interface";
import { NvidiaProductSelectors } from "src/app/core/store/selectors/nvidia-product.selectors";
import { NvidiaProduct } from "src/app/core/store/interfaces/nvidia-product.interface";
import { Dictionary } from "src/app/shared/interfaces/dictionary.interface";
import { SFValidators } from "src/app/shared/functions/sf-validators";
import { CustomerService } from "src/app/shared/interfaces/smartsub-data.interface";
import { ProductCatalogState } from "src/app/core/store/state/product-state/product-catalog.state";
import { CatalogSimProduct } from "src/app/core/store/interfaces/product-catalog.interfaces";
import { HomeOrWork, SelectedHomeOrWorkServicesState } from "../state/home-or-work-services.state";


export class FilteredServiceSelectors {

    @Selector([CustomerInfoState])
    static getMaxCancelledServiceDays(state: CustomerInfoStateModel) { return state.max_cancelled_service_days; }

    @Selector([
        ExtendedServiceSelectors.getServices,
        FilteredServiceSelectors.getMaxCancelledServiceDays
    ])
    static getAgeFilteredServices(services: ExtendedCustomerService[], maxCancelledDays: number) {
        return SimMethods.getFilteredServices(services, maxCancelledDays);
    }


    @Selector([
        FilteredServiceSelectors.getAgeFilteredServices,
        NvidiaProductSelectors.getMappedNvidiaProducts
    ])
    static getFilteredNormalServices(services: ExtendedCustomerService[], mappedNvidiaProducts: Dictionary<NvidiaProduct>) {

        if (!mappedNvidiaProducts) {
            //Allow all services to pass through if no products are available
            return services;
        }

        //Not an Nvidia service
        return services.filter(service => SFValidators.isNotDefined(mappedNvidiaProducts?.[service?.product_id]));
    }

    @Selector([FilteredServiceSelectors.getFilteredNormalServices])
    static getFilteredAndSortedNormalServices(filteredServices: ExtendedCustomerService[]) {
        return filteredServices.sort((service1, service2) =>
            SimMethods.calculateSimAge(service1, "days") - SimMethods.calculateSimAge(service2, "days")
        );
    }

    @Selector([
      FilteredServiceSelectors.getFilteredAndSortedNormalServices,
      ProductCatalogState.getProductsByTypeMappedById("sim")
    ])
    static getHomeServices(
      filteredAndSortedNormalServices: CustomerService[],
      simProductDict: Dictionary<CatalogSimProduct>
    ): CustomerService[]{

      return filteredAndSortedNormalServices?.filter(service => {

        const { name } = simProductDict?.[service?.product_id] ?? {}
        const lowerCaseName = name?.toLocaleLowerCase()

        const isNotSME = !lowerCaseName?.includes('sme');
        const isNotWork = !lowerCaseName?.includes('work');

        return isNotSME && isNotWork

      })

    }

    @Selector([
      FilteredServiceSelectors.getFilteredAndSortedNormalServices,
      ProductCatalogState.getProductsByTypeMappedById("sim")
    ])
    static getWorkServices(
      filteredAndSortedNormalServices: CustomerService[],
      simProductDict: Dictionary<CatalogSimProduct>
    ): CustomerService[] {

      return filteredAndSortedNormalServices?.filter(service => {

        const { name } = simProductDict?.[service?.product_id] ?? {}
        const lowerCaseName = name?.toLocaleLowerCase()

        const isSME = lowerCaseName?.includes('sme');
        const isWork = lowerCaseName?.includes('work');

        return isSME || isWork

      })

    }

    @Selector([
      FilteredServiceSelectors.getHomeServices,
      FilteredServiceSelectors.getWorkServices,
      SelectedHomeOrWorkServicesState.getSelectedHomeOrWorkServices
    ])
    static getHomeOrWorkServices(
      homeServices: CustomerService[],
      workServices: CustomerService[],
      selectedHomeOrWorkServices: HomeOrWork
    ) {

      const showHomeServices = (homeServices.length > 0) && (selectedHomeOrWorkServices === 'home')

      return showHomeServices ? homeServices : workServices

    }

}
