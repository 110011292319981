import { Component, Input } from '@angular/core';
import { SanitizedChatNote } from 'src/app/shared/customer-ticket/interfaces/sanitized-chat-note.interface';
import { TicketNoteHelper } from 'src/app/shared/customer-ticket/ticket-note-helper';
import { Utils } from 'src/app/Utils';
import { isHTMLString } from 'src/app/Utils/helpers';

@Component({
  selector: 'chat-bubble',
  templateUrl: './chat-bubble.component.html',
  styleUrls: ['./chat-bubble.component.scss']
})
export class ChatBubbleComponent {

  @Input() note: SanitizedChatNote;
  @Input() noteHelper: TicketNoteHelper;

  checkIfHasHTML(comment: string) {
    return isHTMLString(comment);
  }

  checkIfIsEmailString(comment: string) {
    return Utils.Helpers.isEmailString(comment);
  }

}
