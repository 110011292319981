<div class="card">
    <app-blank-card>
        <div class="content">
            <div class="default-input">
                <input [formControl]="searchControl" type="text" placeholder="msisdn, email, id or cell number" />

                <div class="customer-list scrollable">

                    <ng-container *ngIf="(smartsubData$ | async)?.user as customer; else customerList">

                        <div class="search" (click)="onNavigate(customer?.email)">
                            <p>
                                <span>
                                    {{ customer?.first_name + " " + customer?.last_name }}
                                </span>
                                {{ customer?.email }}
                            </p>
                        </div>

                    </ng-container>

                    <ng-template #customerList>
                        <div class="search" *ngFor="let customer of customerList$ | async"
                            (click)="onSearchByParam(customer?.email, true)" [value]="customer">
                            <p>
                                <span>
                                    {{ customer?.first_name + " " + customer?.last_name }}
                                </span>
                                {{ customer?.email }}
                            </p>
                        </div>
                    </ng-template>

                </div>
            </div>

            <div class="spinner">
                <fa-icon *ngIf="(isLoading$ | async) || (isCustomerNamesLoading$ | async)" [icon]="spinner" size="sm"
                    class="text-dark-grey fa-icon">spinner</fa-icon>
            </div>

            <div class="clear">
                <button *ngIf="(smartsubData$ | async) || (customerList$ | async)"
                    class="blank-button grey-button default-button-size small-font-size"
                    (click)="clearSmartsubData(); clear()">
                    clear
                </button>
            </div>
        </div>

    </app-blank-card>
</div>

<!-- error display -->

<div *ngIf="errorMessage" class="card search-card">
    <app-blank-card>
        <mat-icon class="default-cancel-icon" (click)="onCloseErrorMessage()">cancel</mat-icon>
        <div class="content">
            <h3 class="text red header">error: {{ errorMessage }}</h3>

            <div class="default-spacer"></div>

            <sf-rain-button *ngIf="isEmail" (click)="gotoInfoSummaryPage()" width="8rem">
                check interactions
            </sf-rain-button>

        </div>
    </app-blank-card>
</div>