import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxsModule } from '@ngxs/store';
import { SharedModule } from 'src/app/shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ChatElementsModule } from 'src/app/shared/components/chat-elements/chat-elements.module';
import { WhatsappInteractionsContainerComponent } from './whatsapp-interactions-container/whatsapp-interactions-container.component';
import { WhatsappMessagesState } from './store/state/whatsapp-messages.state';
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { WhatsappSendMessageState } from './store/state/whatsapp-send-message.state';
import { IsVisibleModule } from 'src/app/shared/directives/is-visible/is-visible-emitter.module';
import { WhatsappNotificationState } from './store/state/whatsapp-notification.state';
import { WhatsappMessageRendererComponent } from './elements/whatsapp-message-renderer/whatsapp-message-renderer.component';
import { WhatsappChatBubbleComponent } from './elements/whatsapp-chat-bubble/whatsapp-chat-bubble.component';
import { WhatsappMediaState } from './store/state/whatsapp-media.state';
import { WhatsappMediaMessageComponent } from './elements/whatsapp-media-message/whatsapp-media-message.component';

const declarations = [
  WhatsappInteractionsContainerComponent,
  WhatsappMessageRendererComponent,
  WhatsappChatBubbleComponent,
  WhatsappMediaMessageComponent
]

@NgModule({
  declarations: [
    ...declarations
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    ChatElementsModule,
    InfiniteScrollModule,
    IsVisibleModule,
    NgxsModule.forFeature([
      WhatsappMessagesState,
      WhatsappSendMessageState,
      WhatsappNotificationState,
      WhatsappMediaState
    ])
  ],
  exports: [
    ...declarations,
    ChatElementsModule,
  ]
})
export class WhatsappInteractionsModule { }
