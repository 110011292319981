

<div class="form-group" [class.mark-valid]="markValid">
    <div [class]="'form-row ' + display + ' ' + type" [class.has-errors]="checkForErrors()">
      <div class="field-container">
        <label [for]="formatLabel(label)">{{label}}:</label>
        <textarea class="textarea" cols="15" rows="5" [formControl]="control" [placeholder]="placeholder"  matInput cdkTextareaAutosize></textarea>
        <!-- <mat-form-field class="form-field " appearance="outline">
            <textarea class="textarea" cols="15" rows="5" [formControl]="control" [placeholder]="placeholder"  matInput cdkTextareaAutosize></textarea>
            <mat-label class="text">{{label}}: </mat-label>
        </mat-form-field> -->
      </div>
      <div class="form-errors">
        <ul>
          <li *ngFor="let error of checkForErrors()  | errorKeys">
            <p>{{messages[error]}}</p>
          </li>
        </ul>
      </div>
    </div>
  </div>
