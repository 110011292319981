<div class="datepicker-wrapper" *ngIf="group" [formGroup]="group">
  <label *ngIf="config.label" [for]="formatLabel(config.name)">{{config.label}}</label>
  <label *ngIf="!config.label && config.name">{{config.name}}</label>
  <div class="input-wrapper" [owlDateTimeTrigger]="dt2">
    <input [min]="min" [max]="max" [owlDateTimeFilter]="dateFilter" [owlDateTime]="dt2" placeholder="Choose a date"
      [formControl]="getControl()" readonly>
    <span><i><svg viewBox="0 0 24 24" width="24px" height="24px" fill="#0077C8" focusable="false"
          class="mat-datepicker-toggle-default-icon ng-star-inserted">
          <path
            d="M19 3h-1V1h-2v2H8V1H6v2H5c-1.11 0-1.99.9-1.99 2L3 19c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5V8h14v11zM7 10h5v5H7z">
          </path>
        </svg></i></span>
  </div>
  <owl-date-time #dt2 [pickerType]="pickerType"></owl-date-time>
</div>
