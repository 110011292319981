

export class StatusTooltip {
  statusColor: string;
  toolTipDescription: string;

  constructor(statusColor: string, toolTipDescription: string = null) {
    this.statusColor = statusColor;
    this.toolTipDescription = toolTipDescription;
  }
}
