import { PRODUCT_ID_NAME_MAP } from "src/app/shared/constants/product-id-to-name-mapping.constant";
import { PaymentType, Product } from "../../../../interfaces/rain-product.interface";
import { CatalogBundle, CatalogISim, CatalogSimProduct } from "src/app/core/store/interfaces/product-catalog.interfaces";
import { SFValidators } from "src/app/shared/functions/sf-validators";


export function is4GUnlimited(productId: string) {
  return productId === PRODUCT_ID_NAME_MAP["4G 24/7 Unlimited"];
}

export function findProduct<T extends Product>(productId: string, products: T[]) {
  if (!products?.length || !productId) {
    return null;
  }
  return products.find(p => p?.id === productId);
}

export function isUpfrontProduct(productId: string, products: { id: string, paymentType: PaymentType }[]) {
  const product = findProduct(productId, products);
  return product?.paymentType === "upfront";
}

export function isRainOne101Bundle(serviceOrBundle: CatalogSimProduct | CatalogBundle, iSimProducts: CatalogISim[]) {
  const iSimProductIds = iSimProducts?.map(product => product?.id)

  const {items, name} = serviceOrBundle ?? {}
  const nameMatch = name?.toLocaleLowerCase().includes('rainone 101a')
  const hasIsimProduct = items?.find(item => iSimProductIds?.includes(item?.id))

  return SFValidators.isDefined(serviceOrBundle?.items) ?
    hasIsimProduct && nameMatch :
    nameMatch
}

export function isRainOneWorkBundle(serviceOrBundle: CatalogSimProduct | CatalogBundle, iSimProducts: CatalogISim[]) {
  const iSimProductIds = iSimProducts?.map(product => product?.id)
  const {items, name} = serviceOrBundle ?? {}
  const nameMatch = name?.toLocaleLowerCase().includes('rainone 101a work')
  const hasIsimProduct = items?.find(item => iSimProductIds?.includes(item?.id))

  return SFValidators.isDefined(serviceOrBundle?.items) ?
    hasIsimProduct && nameMatch :
    nameMatch
}

export function isRainOneOffPeakBundle(serviceOrBundle: CatalogSimProduct | CatalogBundle, products: CatalogSimProduct[]) {
  const productIds5G = products?.map((product) => {
    if (product?.category === '5G') {
      return product?.id
    }
  })
  const is4G = SFValidators.isDefined(serviceOrBundle?.items) ? !(serviceOrBundle?.items.filter(item => productIds5G?.includes(item?.id))?.length) : !productIds5G?.includes(serviceOrBundle?.id)
  const isOffPeak = SFValidators.isDefined(serviceOrBundle?.items) ? serviceOrBundle?.items?.length < 4 : serviceOrBundle?.name?.toLocaleLowerCase().includes('off-peak')
  return is4G && isOffPeak

}
