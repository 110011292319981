import { Utils } from "src/app/Utils";

export class FetchAllGroups {
    static readonly type = Utils.Helpers.Type('[Customer: Groups] Fetch all groups');
}

export class FetchAllGroupsSuccess {
    static readonly type = Utils.Helpers.Type('[Customer: Groups] Fetch all groups success');
    constructor(public payload: any) { }
}

export class FetchAllGroupsFail {
    static readonly type = Utils.Helpers.Type('[Customer: Groups] Fetch all groups fail');
    constructor(public payload: any) { }
}