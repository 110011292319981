import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { DebitAccountPayload } from 'src/app/core/interfaces/debit-account-payload.interface';
import { PaymentStatusResponse } from 'src/app/core/interfaces/payment-status.interface';
import { CustomerInfoState } from 'src/app/customer-info-summary-page/store/state/customer-info.state';
import { TicketEventHandlerService } from 'src/app/shared/customer-ticket/ticket-event-handler/ticket-event-handler.service';
import { ModalWindowService } from 'src/app/shared/modal-window/modal-window.service';
import { ApiControllerService } from 'src/app/shared/services/api-service/api-controller.service';
import { ApiOptions } from 'src/app/shared/services/api-service/api-response.interface';
import { environment } from 'src/environments/environment';
import { getBillSupportErrorMessage } from './assets/billing-support-error-codes.constant';
import { Utils } from 'src/app/Utils';
import { retryWhen } from 'rxjs/operators';



@Injectable({
    providedIn: 'root'
})
export class PaymentMethodService {

    constructor(
        private store: Store,
        private apiService: ApiControllerService,
        private modal: ModalWindowService,
        private http: HttpClient,
        private ticketEventHandler: TicketEventHandlerService) {
    }

    pollForPaymentStatus(invoiceId: string, initiatedTimeStamp: number) {

        const maxAttempts = 10;
        const delay = 5000;



        return this.getPaymentStatus(invoiceId)
            .pipe(
                Utils.Helpers.pollUntil({
                    initialDelay: delay,
                    maxAttempts,
                    pollInterval: delay,
                    responsePredicate: (res: PaymentStatusResponse) => res?.data?.recordedDate >= initiatedTimeStamp,
                    errorMessage: "Response took too long"
                }),
                retryWhen(Utils.Rxjs.onErrorCodeRetryStrategy({
                    errorCodesToInclude: [404, 502, 503],
                    maxRetryAttempts: maxAttempts,
                }))
            )
    }

    getPaymentStatus(invoiceId: string) {
        const url = `${environment.main_proxy}/payment-gateway/payments/status/${invoiceId}`;

        const httpOptions: ApiOptions = {
            headers: new HttpHeaders({
                "Content-Type": "application/json",
                apiKey: environment.apigeeApiKey
            })
        };

        return this.http.get<PaymentStatusResponse>(url, httpOptions);
    }

    debit(customerId: string, data: DebitAccountPayload) {
        const url = `${environment.bssEndpoint}/partner/smartsub/1/north/billing/1/billing-support/web/payment/debit`;

        const httpOptions: ApiOptions = {
            headers: new HttpHeaders({
                subject: customerId,
                "Content-Type": "application/json",
                apiKey: environment.apigeeApiKey
            })
        };

        return this.http.post(url, data, httpOptions);
    }

    async payNow() {
        const customer = this.store.selectSnapshot(CustomerInfoState.getSmartsubData)?.user;
        const accepted = await this.modal.showConfirmation(
            `are you sure you want to send ${customer?.first_name} a paynow sms?`
        );

        if (!accepted) {
            return;
        }

        const url = `${environment.bssEndpoint}/partner/smartsub/1/north/billing/1/billing-support/web/billing/payat/notification`;

        const httpOptions: ApiOptions = {
            headers: new HttpHeaders({
                "Content-Type": "application/json",
                apiKey: environment.apigeeApiKey
            })
        };

        const payload = {
            customerId: customer.id,
            msisdn: customer?.phone
        }

        const response = await this.apiService.post(url, httpOptions, payload);

        if (response.status === 200) {
            const message = "paynow sms was successfully sent.";
            this.ticketEventHandler.addEventForKnownCustomer(message, true);
            this.modal.showSuccess(message);
        }
        else {
            const error = getBillSupportErrorMessage(response.errorMessage,);
            this.modal.showError("sending paynow failed.", error);
        }
    }


}
