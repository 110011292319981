import moment from 'moment';

/**
 *
 *
 * @export
 * @param {*} json
 * @description Function to add syntax highlighting to json
 * @return {*} json
 */
 export function syntaxHighlight(json): string {
    if (typeof json != 'string') {
         json = JSON.stringify(json, undefined, 2);
    }
    json = json.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;');
    return json.replace(/("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+-]?\d+)?)/g, (match: string) => {
        let cls = 'number';
        if (/^"/.test(match)) {
            if (/:$/.test(match)) {
                cls = 'key';
            } else {
                cls = 'string';
            }
        } else if (/true|false/.test(match)) {
            cls = 'boolean';
        } else if (/null/.test(match)) {
            cls = 'null';
        }

        // formating date
        if(moment(match.split('"').join('')).isValid() && match.length > 20) {
            const date = moment(match.split('"').join(''));
            cls = 'date';
            return '<span class="' + cls + '">' + date.format('dddd, MMMM Do YYYY, H:mm:ss a') + '</span>';
        }
        return '<span class="' + cls + '">' + match + '</span>';
    });
}