import { Component, Input } from '@angular/core';
import { ButtonHelper } from 'src/app/Utils/helpers/button.helper';

@Component({
  selector: 'app-link-button',
  templateUrl: './link-button.component.html',
  styleUrls: ['./link-button.component.scss']
})
export class LinkButtonComponent extends ButtonHelper {

  @Input() disabled = false;

}
