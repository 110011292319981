import { DataHandler } from "src/app/shared/data-handler/data-handler";
import { SnowflakeSelectOption } from "src/app/shared/elements/rain-forms/elements/snowflake-select/interfaces/rain-select-option.interface";
import { SFValidators } from "src/app/shared/functions/sf-validators";
import { MultiSelectConfig } from "./multi-select-config.interface";
import { MultiSelectInfo, SuccessAndValue } from "./multi-select-info.type";
import { MultiSelectOption } from "./multi-select-option.model";

function isOther(selectOption: MultiSelectOption) {
  return selectOption.value?.toString()?.toLowerCase() === "other";
}

export class MultiSelect {

  isOpen: boolean;
  showOtherOption = false;
  otherOption = "";
  selectOptions: MultiSelectOption[] = [];
  private _selectedValues: MultiSelectInfo[] = [];

  //Saving select object reference so it does not need to be
  private _otherSelectOption: MultiSelectOption;

  constructor(options: SnowflakeSelectOption[], config: MultiSelectConfig = { isOpen: false, showSuccessToggle: false }) {
    this.isOpen = config.isOpen;
    this.initializeSelectOptions(options, config.showSuccessToggle);
  }

  private initializeSelectOptions(options: SnowflakeSelectOption[], showSuccessToggle: boolean) {
    options.forEach(option => {
      this.selectOptions.push(new MultiSelectOption(option, showSuccessToggle));
    })
  }

  toggleSelectOption(index: number) {
    const selectOption = this.selectOptions[index];
    selectOption.toggleIsSelected();

    if (isOther(selectOption) && selectOption.selected) {
      this.showOtherOption = true;
      this.selectOptions.forEach(item => {
        if (!isOther(item)) item.selected = false;
      });
      this._otherSelectOption = selectOption;
    }
    else {
      //Should toggle to the other option to false
      if (this._otherSelectOption) this._otherSelectOption.selected = false;
      this.showOtherOption = false;
      this.otherOption = "";
    }
    this.updateSelectedOptions();
  }

  updateSelectedOptions(): void {
    const selectedOptions: MultiSelectInfo[] = [];
    this.selectOptions.forEach(option => {
      if (option.selected) {
        selectedOptions.push(option.getInfo());
      }
    });
    this._selectedValues = selectedOptions;
  }

  toggleIsOpen() {
    this.isOpen = !this.isOpen;
  }

  get isValid() {
    if (this.showOtherOption) {
      return this.isOtherOptionValid();
    }
    return (this.selectedValues.length > 0);
  }

  private isOtherOptionValid() {
    return this.otherOption !== "" && DataHandler.isDefined(this.otherOption);
  }

  isValidExcluding(valuesToExclude: Array<string | number>) {
    if (this.showOtherOption) {
      return this.isOtherOptionValid();
    }
    return (this.selectedValues
      ?.filter(v => !valuesToExclude.includes(this.getRawValue(v))).length > 0);
  }

  public get selectedValues(): MultiSelectInfo[] {
    if (this.showOtherOption) return ["other - " + this.otherOption];
    return this._selectedValues;
  }

  getRawValue(infoItem: MultiSelectInfo) {
    if (SFValidators.isDefined(infoItem?.["value"])) {
      return (infoItem as SuccessAndValue).value;
    }
    return infoItem as string | number;
  }

  isValueSelected(value: string | number): boolean {
    for (const item of this.selectedValues) {
      if (this.getRawValue(item) === value) {
        return true;
      }
    }
    return false;
  }


}
