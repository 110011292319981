<div class="form-group">
  <div [class]="'form-row ' + display + ' ' + type" [class.has-errors]="checkForErrors()">
    <div class="field-container">
      <label *ngIf="label" [for]="formatLabel(label)">{{label}}</label>
      <div class="input-container">
        <input (click)="clicked($event)" [name]="formatLabel(label)" [id]="formatLabel(label)" [formControl]="control"
          [placeholder]="placeholder" type="checkbox">
      </div>
    </div>
    <!-- <div class="form-errors">
      <ul>
        <li *ngFor="let error of checkForErrors()  | errorKeys">
          <p>{{messages[error]}}</p>
        </li>
      </ul>
    </div> -->
  </div>
</div>
