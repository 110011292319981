import { Component, Input } from '@angular/core';

@Component({
  selector: 'small-text-spinner',
  templateUrl: './small-text-spinner.component.html',
  styleUrls: ['./small-text-spinner.component.scss']
})
export class SmallTextSpinnerComponent {

  @Input() size = "40px";

}
