import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store, createSelector } from '@ngxs/store';
import { AgentNotificationWSActions } from '../actions/agent-notification-ws-actions';
import { WebSocketStatus } from '../types/agent-websocket-status.type';
import { CoreState } from 'src/app/core/store/state/core.state';
import { AgentNotificationWebSocketService } from '../services/agent-notification-websocket.service';
import { Utils } from 'src/app/Utils';


interface AgentNotificationWSStateModel {
    status: WebSocketStatus;
    error: string;
}
@State<AgentNotificationWSStateModel>({
    name: 'sf_agent_notification_ws_state',
    defaults: {
        status: "closed",
        error: null
    }
})
@Injectable()
export class AgentNotificationWSState {

    @Selector()
    static getStatus(state: AgentNotificationWSStateModel) { return state.status; }

    static hasAnyStatus(statuses: WebSocketStatus[]) {
        return createSelector(
            [AgentNotificationWSState.getStatus],
            (currentStatus: WebSocketStatus) => statuses.includes(currentStatus)
        );
    }

    constructor(private wsService: AgentNotificationWebSocketService,
        private store: Store) {
    }

    @Action(AgentNotificationWSActions.ConnectInCtx)
    ConnectInCtx(ctx: StateContext<AgentNotificationWSStateModel>) {

        const email = this.store.selectSnapshot(CoreState.getAgentEmail);

        return ctx.dispatch(new AgentNotificationWSActions.Connect(email));
    }

    @Action(AgentNotificationWSActions.Connect)
    Connect(_ctx: StateContext<AgentNotificationWSStateModel>, action: AgentNotificationWSActions.Connect) {
        const { email } = action;

        this.wsService.connect(email);
    }

    @Action(AgentNotificationWSActions.UpdateStatus)
    UpdateStatus(ctx: StateContext<AgentNotificationWSStateModel>, action: AgentNotificationWSActions.UpdateStatus) {

        ctx.patchState({
            status: action.status
        });
    }

    @Action(AgentNotificationWSActions.SetErrorMessage)
    SetErrorMessage(ctx: StateContext<AgentNotificationWSStateModel>, action: AgentNotificationWSActions.SetErrorMessage) {

        const error = Utils.Helpers.findError(action.error, "");

        ctx.patchState({
            error: `Failed to connect. ${error}`
        });
    }

}   