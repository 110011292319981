<sf-notifications-lockup></sf-notifications-lockup>
<div class="toolbar">
    <app-toolbar></app-toolbar>
</div>

<sf-profile-side-nav></sf-profile-side-nav>

<div class="main-router scrollable">
    <sf-loadshedding-banner></sf-loadshedding-banner>

    <div>
        <!--This is to avoid reload during acquireTokenSilent() because of hidden iframe -->
        <router-outlet *ngIf="!isIframe"></router-outlet>
    </div>

</div>
<app-modal-window></app-modal-window>
<sf-warning-modals></sf-warning-modals>
<sf-main-modals-container></sf-main-modals-container>
<sf-maintenance-overlay></sf-maintenance-overlay>
<sf-agent-control></sf-agent-control>