
import { Injectable } from '@angular/core';
import { Action, createSelector, Selector, State, StateContext, } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { AddSuccessResponseNotification } from 'src/app/core/store/actions/notifications.actions';
import { AllTicketsService } from 'src/app/features/pages/ticket/services/all-tickets.service';
import { ShortTicket } from 'src/app/shared/customer-ticket/interfaces/customer-ticket.interface';
import { TICKET_STATES } from 'src/app/shared/customer-ticket/ticket-event-handler/assets/ticket.constants';
import { Utils } from 'src/app/Utils';
import { InteractionTicketActions } from '../actions/interaction-ticket-actions';
import { RefreshTicketActions } from '../actions/refresh-ticket-actions';
import { TicketFunctions } from '../assets/ticket-functions';
import { InteractionTicketRequest } from '../interfaces/interaction-ticket-request.interface';
import { PaginateInfo } from '../interfaces/interaction-ticket-response.interface';


const MAX_TICKET_AGE_IN_HOURS = 24;
const DEFAULT_PAGE_SIZE = 10;

function getDefaults(pageSize: number = DEFAULT_PAGE_SIZE): InteractionTicketsStateModel {
    return {
        sortedTickets: [],
        pageInfo: {
            size: pageSize,
            page: 1
        },
        error: null,
        nextPageError: null,
        loading: false,
        nextPageLoading: false,
        assignedHexId: null,
        selectedHexId: null
    }
}

export interface InteractionTicketsStateModel {
    sortedTickets: ShortTicket[];
    pageInfo: PaginateInfo,
    error: string;
    nextPageError: string;
    loading: boolean;
    nextPageLoading: boolean;
    assignedHexId: string;
    selectedHexId: string;
}
@State<InteractionTicketsStateModel>({
    name: 'sf_interaction_tickets_state',
    defaults: getDefaults()
})
@Injectable()
export class InteractionTicketsState {

    @Selector()
    static isLoading(state: InteractionTicketsStateModel) { return state.loading }

    @Selector()
    static isNextPageLoading(state: InteractionTicketsStateModel) { return state.nextPageLoading }

    @Selector()
    static getTickets(state: InteractionTicketsStateModel) { return state.sortedTickets ?? [] }

    @Selector()
    static getError(state: InteractionTicketsStateModel) { return state.error }

    @Selector()
    static getNextPageError(state: InteractionTicketsStateModel) { return state.nextPageError }

    @Selector()
    static getAssignedHexId(state: InteractionTicketsStateModel) { return state.assignedHexId }

    @Selector()
    static getSelectedHexId(state: InteractionTicketsStateModel) { return state.selectedHexId }

    @Selector()
    static getPageInfo(state: InteractionTicketsStateModel) { return state.pageInfo }

    @Selector()
    static canLoadMore(state: InteractionTicketsStateModel) {
        const { pageInfo, sortedTickets, loading } = state;
        //This is a basic check until the total tickets are returned
        return !loading && (sortedTickets?.length >= pageInfo?.size);
    }

    @Selector()
    static getSelectedShortTicket(state: InteractionTicketsStateModel) {
        const { selectedHexId, sortedTickets } = state;
        if (selectedHexId) {
            return sortedTickets.find(t => t.hex_id === selectedHexId);
        }
    }

    @Selector()
    static getAssignedShortTicket(state: InteractionTicketsStateModel) {
        const { assignedHexId, sortedTickets } = state;
        if (assignedHexId) {
            return sortedTickets.find(t => t.hex_id === assignedHexId);
        }
    }

    @Selector([InteractionTicketsState.getAssignedShortTicket])
    static isAssignedTicketClosed(ticket: ShortTicket) {
        return ticket?.state_id === TICKET_STATES.CLOSED;
    }

    @Selector()
    static hasSelectedTicket(state: InteractionTicketsStateModel) {
        return Boolean(state.selectedHexId);
    }

    @Selector()
    static hasAssignedHexId(state: InteractionTicketsStateModel) {
        return Boolean(state.assignedHexId);
    }

    static getTicketByHexId(hexId: string) {
        return createSelector([InteractionTicketsState], (state: InteractionTicketsStateModel) => {
            return state.sortedTickets.find((t) => t.hex_id === hexId);
        });
    }

    /**Returns the hexId of the selected/open ticket or defaults to the assignedHexId if no ticket is currently open. */
    @Selector()
    static getCurrentHexId(state: InteractionTicketsStateModel) {
        const { selectedHexId, assignedHexId } = state;
        return selectedHexId ?? assignedHexId;
    }


    constructor(private allTicketsService: AllTicketsService) {
    }

    @Action(InteractionTicketActions.Fetch)
    Fetch(ctx: StateContext<InteractionTicketsStateModel>, action: InteractionTicketActions.Fetch) {
        const savedPageInfo = ctx.getState().pageInfo;
        const { payload, loaderType } = action;
        const { pageInfo } = payload;

        //Use default values if none are specified
        payload.pageInfo = pageInfo ? pageInfo : savedPageInfo;

        const { ErrorAction, SuccessAction, loader, noTicketsError } = InteractionTicketActions.LOADER_CONFIG[loaderType];

        ctx.patchState(loader);

        return this.allTicketsService.getShortTicketsByEmailv2(payload)
            .pipe(tap({
                next: res => {
                    if (!res?.data || !res?.meta) {
                        return ctx.dispatch(new ErrorAction());
                    }
                    if (res?.data?.length === 0) {
                        return ctx.dispatch(new ErrorAction(noTicketsError));
                    }
                    return ctx.dispatch(new SuccessAction(res));
                },
                error: (e: unknown) => ctx.dispatch(new ErrorAction(e))
            }));
    }

    @Action(InteractionTicketActions.FetchSuccess)
    FetchSuccess(ctx: StateContext<InteractionTicketsStateModel>, action: InteractionTicketActions.FetchSuccess) {
        const { payload } = action;
        const sortedTickets = TicketFunctions.sortTickets(payload.data);

        ctx.patchState({
            sortedTickets,
            loading: false,
            error: null,
            pageInfo: payload.meta
        });

        return this.checkTicketsForAssignableHexId(ctx);
    }


    @Action(InteractionTicketActions.FetchFail)
    FetchFail(ctx: StateContext<InteractionTicketsStateModel>, action: InteractionTicketActions.FetchFail) {
        const error = Utils.Helpers.findError(action?.error, "Failed to fetch tickets.");
        ctx.patchState({
            loading: false,
            error
        });
    }


    /*======================================== Next Page Section ====================================== */


    @Action(InteractionTicketActions.FetchNextPage)
    FetchNextPage(ctx: StateContext<InteractionTicketsStateModel>, action: InteractionTicketActions.FetchNextPage) {
        const { email } = action;
        const { page, size } = ctx.getState().pageInfo;

        const payload: InteractionTicketRequest = {
            email,
            pageInfo: {
                page: page + 1,
                size
            }
        }

        return ctx.dispatch(new InteractionTicketActions.Fetch(payload, "nextPage"));
    }


    @Action(InteractionTicketActions.FetchNextPageSuccess)
    FetchNextPageSuccess(ctx: StateContext<InteractionTicketsStateModel>, action: InteractionTicketActions.FetchNextPageSuccess) {
        const { payload } = action;
        const currentTickets = ctx.getState().sortedTickets;

        const sortedTickets = Utils.Functional.pipe(
            [...currentTickets, ...payload.data],
            (tickets) => Utils.Helpers.getUniqueArray(tickets, "hex_id"),
            TicketFunctions.sortTickets
        );

        ctx.patchState({
            nextPageLoading: false,
            nextPageError: null,
            sortedTickets,
            pageInfo: payload.meta
        });

        this.checkTicketsForAssignableHexId(ctx);

        return ctx.dispatch(new AddSuccessResponseNotification({
            success: true,
            message: "Loaded next tickets",
        }));
    }

    @Action(InteractionTicketActions.FetchNextPageFail)
    FetchNextPageFail(ctx: StateContext<InteractionTicketsStateModel>, action: InteractionTicketActions.FetchNextPageFail) {
        const message = Utils.Helpers.findError(action?.error, "Failed to fetch more tickets.");

        ctx.patchState({
            nextPageLoading: false,
            nextPageError: message
        });

        return ctx.dispatch(new AddSuccessResponseNotification({
            success: false,
            message
        }));
    }


    private checkTicketsForAssignableHexId(ctx: StateContext<InteractionTicketsStateModel>) {
        const { assignedHexId, sortedTickets } = ctx.getState();
        if (assignedHexId) {
            return this.checkIfAssignedTicketIsPresent(ctx);
        }

        const newestHexId = sortedTickets?.filter(t => {
            if (t?.state_id === TICKET_STATES.CLOSED) {
                return false;
            }

            return (TicketFunctions.getTicketAgeInHours(t) < MAX_TICKET_AGE_IN_HOURS);
        })?.[0]?.hex_id;

        if (newestHexId) {
            return ctx.dispatch(new InteractionTicketActions.SetAssignedHexId(newestHexId));
        }
    }

    private checkIfAssignedTicketIsPresent(ctx: StateContext<InteractionTicketsStateModel>) {
        const { assignedHexId, sortedTickets } = ctx.getState();
        const hexIdInTickets = Boolean(sortedTickets.find(t => t.hex_id === assignedHexId));

        if (!hexIdInTickets) {
            //This refresh will add the ticket to the current list of tickets so it's viewable from the start
            return ctx.dispatch(new RefreshTicketActions.RefreshTicket(assignedHexId));
        }
    }

    @Action(InteractionTicketActions.SetAssignedHexId)
    SetAssignedHexId(ctx: StateContext<InteractionTicketsStateModel>, action: InteractionTicketActions.SetAssignedHexId) {
        ctx.patchState({ assignedHexId: action.hexId });
    }

    @Action(InteractionTicketActions.SetSelectedHexId)
    SetSelectedHexId(ctx: StateContext<InteractionTicketsStateModel>, action: InteractionTicketActions.SetSelectedHexId) {
        ctx.patchState({ selectedHexId: action.hexId });
    }


    @Action(InteractionTicketActions.SetPageSize)
    SetPageSize(ctx: StateContext<InteractionTicketsStateModel>, action: InteractionTicketActions.SetPageSize) {
        const pageInfoCopy = { ...ctx.getState().pageInfo };
        const { size } = action;
        pageInfoCopy.size = size === "default" ? DEFAULT_PAGE_SIZE.valueOf() : size;

        ctx.patchState({ pageInfo: pageInfoCopy });
    }


    @Action(InteractionTicketActions.UpdateTicket)
    SetFreshTicket(ctx: StateContext<InteractionTicketsStateModel>, action: InteractionTicketActions.UpdateTicket) {
        const { ticket } = action;
        const { sortedTickets } = ctx.getState();

        //Filter out same ticket
        const filteredTickets = sortedTickets.filter(t => t.hex_id !== ticket.hex_id);
        ctx.patchState({
            sortedTickets: TicketFunctions.sortTickets([...filteredTickets, ticket])
        });

        return this.checkTicketsForAssignableHexId(ctx);
    }

    @Action(InteractionTicketActions.UpdateTicketAndSetAsAssigned)
    UpdateTicketAndSetAsAssigned(ctx: StateContext<InteractionTicketsStateModel>, action: InteractionTicketActions.UpdateTicketAndSetAsAssigned) {
        const { ticket } = action;

        return ctx.dispatch([
            new InteractionTicketActions.UpdateTicket(ticket),
            new InteractionTicketActions.SetAssignedHexId(ticket?.hex_id)
        ]);
    }

    @Action(InteractionTicketActions.Clear)
    Clear(ctx: StateContext<InteractionTicketsStateModel>, action: InteractionTicketActions.Clear) {
        const { keepAssignedHexId } = action.options ?? {};
        const { assignedHexId, pageInfo } = ctx.getState();

        //Save the user defined default page size
        const defaults = getDefaults(pageInfo?.size);

        if (keepAssignedHexId) {
            defaults.assignedHexId = assignedHexId;
        }
        ctx.setState(defaults);
    }

}


