import { Component, Input, OnDestroy } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { RainAgent } from 'src/app/shared/components/agent/rain-agent.service';
import { ToggleHasMoreOpenTicketsModal } from '../../store/actions/interaction-actions/interactions.actions';
import { InteractionsModalState } from '../../store/state/interaction-modal-state';

@Component({
  selector: 'sf-more-open-tickets-modal',
  templateUrl: './more-open-tickets.component.html',
  styleUrls: ['./more-open-tickets.component.scss']
})
export class MoreOpenTicketsComponent implements OnDestroy {
  @Input() numOpenTickets: number;
  @Select(InteractionsModalState.showMoreOpenTicketsModal) isOpen$: Observable<boolean>;

  constructor(private store: Store,
    private rainAgent: RainAgent) {

  }

  public onClose() {
    this.store.dispatch(new ToggleHasMoreOpenTicketsModal(false));
  }

  public onProceed() {
    this.rainAgent.continueToNextTicket({ checkOpenTickets: false });
  }

  ngOnDestroy(): void {
    this.onClose();
  }


}
