import { ExtendedCustomerService } from "src/app/customer-info-summary-page/store/interfaces/proxy-customer-service.interface";
import { CUSTOMER_SERVICE_STATUS } from "src/app/shared/constants/service-status.constant";

export function getServiceIDforMSISDN(services: ExtendedCustomerService[], msisdn: string) {
    if (!services?.length) return null;

    const activeServices = [];
    const notActiveServices = [];

    services.forEach(service => {
        if (service?.status === CUSTOMER_SERVICE_STATUS['Active']) {
            activeServices.push(service)
        } else {
            notActiveServices.push(service)
        }
    })

    const { id: activeServiceId } = activeServices?.find((service: ExtendedCustomerService) => service?.service_reference === msisdn) ?? {};
    const { id: notActiveServiceId } = notActiveServices?.find((service: ExtendedCustomerService) => service?.service_reference === msisdn) ?? {};

    return activeServiceId ?? notActiveServiceId;
}