<div *ngIf="isUserAgentProcessing$ | async; else doneProcessing" class="spin-wrapper">
    <small-text-spinner size="1rem">
    </small-text-spinner>
</div>

<ng-template #doneProcessing>
    <div class="wrapper">
        <mat-icon class="pointer" [style.color]="(sipPhone?.isUserAgentConnected ? 'green' : 'red' ) | rainColorCode"
            matTooltip="Restart voice connection" (click)="sipPhone?.retryUserAgentConnection()">
            devices
        </mat-icon>
    </div>
</ng-template>