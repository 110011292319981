import { Pipe, PipeTransform } from "@angular/core";
import { BasicVoiceQueue } from "src/app/sip-phone/services/voice-agents/assets/voice-queue-configs.interface";


@Pipe({
  name: 'formatVoiceQueuesMessage'
})
export class FormatVoiceQueuesMessagePipe implements PipeTransform {

  transform(queues: BasicVoiceQueue[]): string {
    if (!queues) {
      return;
    }

    if (!queues?.length) {
      return "none";
    }

    let result = queues[0]?.name;
    queues.forEach((queue, i) => {
      if (i > 0) {
        result += ", " + queue?.name;
      }
    })

    return result;
  }
}
