<sf-popup-modal [isOpen]="true" (close)="onClose()">

  <ng-container header>create new ticket</ng-container>

  <ng-container mainContent>

    <sf-modal-form-container [formGroup]="form">

      <sf-select label="ticket area" [options]="ticketNameOptions" [marginBottom]="marginBottom"
        (onOptionSelect)="onOptionSelect($event, 'ticketName')" placeholder="select ticket area"
        [hasError]="form.get('ticketName').errors?.notSelected">
      </sf-select>

      <sf-select *ngIf="selectedNewOptions && selectedNewOptions?.subOptions?.length > 0"
        [label]="selectedNewOptions.subOptionLabel" [options]="selectedNewOptions.subOptions" [marginBottom]="marginBottom"
        (onOptionSelect)="onOptionSelect($event, 'postfix')" [placeholder]="'select ' + selectedNewOptions.subOptionLabel"
        [hasError]="form.get('postfix').errors?.notSelected">
      </sf-select>

    </sf-modal-form-container>

  </ng-container>

  <ng-container buttons>
    <button class="rain-button default-button-size send-button" (click)="onClose()">cancel</button>
    <button class="rain-button default-button-size send-button solid" (click)="onAddNewTicket()"
      [disabled]="!isFormValid">create</button>
  </ng-container>


</sf-popup-modal>
