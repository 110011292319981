
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { CallbackModule } from '../callback.module';
import { UpcomingCallbackModalComponent } from './upcoming-callback-modal/upcoming-callback-modal.component';
import { FormatCallbackStatusPipe } from './upcoming-callback-modal/pipes/format-callback-status.pipe';


const declarations = [
    UpcomingCallbackModalComponent,
    FormatCallbackStatusPipe
];

@NgModule({
    declarations: [
        ...declarations
    ],
    imports: [
        CommonModule,
        SharedModule,
        CallbackModule
    ],
    exports: [
        ...declarations
    ],
})
export class CallbackComponentModule { }