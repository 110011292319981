/**
 * Generator function to yield available numbers within a specified limit that are not present in the given array.
 *
 * @param {number[]} arr - The array of numbers to check against.
 * @param {number} limit - The upper limit for the range of numbers.
 * @yields {number} - The next available number that is not in the given array.
 *
 * @example
 * const arr = [1, 3, 4];
 * const limit = 10;
 * const generator = generateFirstAvailableNumber(arr, limit);
 *
 * console.log(generator.next().value); // First available number
 * console.log(generator.next().value); // Next available number
 */
export function* generateFirstAvailableNumber(arr: number[], limit: number, start: number) {
  const arrSet = new Set(arr);
  for (let i = start; i <= limit; i++) {
    if (!arrSet.has(i)) {
      yield i;
    }
  }
}

