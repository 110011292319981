import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, } from '@ngxs/store';
import { Subject } from 'rxjs';
import { BasicStateModel } from 'src/app/shared/state-updaters/types/basic-state-updater.types';
import { BasicStateUpdater } from 'src/app/shared/state-updaters/basic.state-updater';
import { CurrentDeviceActions } from '../actions/current-device-actions';
import { CurrentDeviceRequest, PossibleCurrentDevice } from '../interfaces/current-device.interfaces';
import { DeviceDataService } from '../../services/device-data.service';
import { PossibleGPSCoordinates } from '../interfaces/gps-coordinates.interface';
import { GeocoderActions } from 'src/app/shared/components/geocoder/actions';
import { SimCardPageActions } from 'src/app/customer-data-components/sim-details/store/actions/sim-card-page-actions';
import { InitializedCurrentDevice } from '../actions/coverage-data.actions';


export type CurrentDeviceStateModel = BasicStateModel<PossibleCurrentDevice>;

@State<CurrentDeviceStateModel>({
    name: 'CurrentDeviceState',
    defaults: BasicStateUpdater.getDefaultState()
})
@Injectable()
export class CurrentDeviceState {

    @Selector()
    static isLoading(state: CurrentDeviceStateModel) { return state.loading; }

    @Selector()
    static isLoaded(state: CurrentDeviceStateModel) { return state.loaded; }

    @Selector()
    static getData(state: CurrentDeviceStateModel) { return state.data; }

    @Selector([
        CurrentDeviceState.getData
    ])
    static getDeviceCoordinates(data: PossibleCurrentDevice): PossibleGPSCoordinates {
        const { cpe_latitude, cpe_longitude } = data?.gps_coords?.[0] ?? {}

        if (!cpe_latitude || !cpe_longitude) {
            return null;
        }

        return {
            lat: cpe_latitude,
            lng: cpe_longitude
        }
    }

    @Selector()
    static getError(state: CurrentDeviceStateModel) { return state.error }

    private readonly _cancelRequest$ = new Subject();

    private readonly _stateUpdater = new BasicStateUpdater<PossibleCurrentDevice, CurrentDeviceRequest>({
        cancelRequest$: this._cancelRequest$,
        errorMessage: "Failed to fetch device data.",
        notFoundMessage: "No device data.",
        baseFetchFn: (request: CurrentDeviceRequest) => this.deviceService.getDevice(request),
        ...CurrentDeviceActions,
    });

    constructor(private deviceService: DeviceDataService) { }

    @Action(CurrentDeviceActions.DirtyFetch)
    DirtyFetch(ctx: StateContext<CurrentDeviceStateModel>, action: CurrentDeviceActions.DirtyFetch) {
        const { imsi } = action?.payload ?? {};

        if (!imsi) {
            ctx.dispatch(new CurrentDeviceActions.FetchFail("Missing IMSI"));
        }
        else {
            ctx.dispatch(new CurrentDeviceActions.Fetch({ imsi }));
        }
    }

    @Action(CurrentDeviceActions.Fetch)
    Fetch(ctx: StateContext<CurrentDeviceStateModel>, action: CurrentDeviceActions.Fetch) {
        return this._stateUpdater.Fetch(ctx, action);
    }

    @Action(CurrentDeviceActions.FetchSuccess)
    FetchSuccess(ctx: StateContext<CurrentDeviceStateModel>, action: CurrentDeviceActions.FetchSuccess) {
        this._stateUpdater.FetchSuccess(ctx, action);

        ctx.dispatch(new InitializedCurrentDevice());
    }

    @Action(CurrentDeviceActions.FetchFail)
    FetchFail(ctx: StateContext<CurrentDeviceStateModel>, action: CurrentDeviceActions.FetchFail) {
        this._stateUpdater.FetchFail(ctx, action);

        ctx.dispatch(new InitializedCurrentDevice());
    }

    @Action([
        CurrentDeviceActions.Clear,
        GeocoderActions.SearchForAddress,
        SimCardPageActions.SimCardPageDestroyed
    ])
    Clear(ctx: StateContext<CurrentDeviceStateModel>) {
        this._stateUpdater.Clear(ctx);
    }

}