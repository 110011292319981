<sf-popup-modal [isOpen]="true" [hasCloseButton]="false" [autoClose]="false"
  [successResponse]="modalOptions.successResponse" [sending]="modalOptions.sending"
  [spinnerMessage]="modalOptions.spinnerMessage" [limitHeight]="true">

  <ng-container header>rica questionnaire</ng-container>

  <ng-container mainContent>

    <sf-modal-form-container [formGroup]="form">

      <sf-select label="contacted" [options]="selectOptions.contacted" marginBottom="1rem"
        (onOptionSelect)="onSelect($event, 'contacted')" placeholder="select contacted option"
        [hasError]="form.get('contacted').errors?.notSelected">
      </sf-select>

      <sf-select label="outcome" [options]="selectOptions.outcome" marginBottom="1rem"
        (onOptionSelect)="onSelect($event, 'outcome')" placeholder="select outcome"
        [hasError]="form.get('outcome').errors?.notSelected">
      </sf-select>

      <sf-select label="escalation required" [options]="selectOptions.escalation_required" marginBottom="3rem"
        (onOptionSelect)="onSelect($event, 'escalation_required')" placeholder="select escalation required"
        [hasError]="form.get('escalation_required').errors?.notSelected">
      </sf-select>

    </sf-modal-form-container>

  </ng-container>

  <ng-container buttons>
    <button class="rain-button default-button-size send-button solid" (click)="onSend()"
      [disabled]="!form?.valid || modalOptions.sending">
      send
    </button>
  </ng-container>

</sf-popup-modal>
