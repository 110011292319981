import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild, } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { ContinueFromPostTicketModal, SetIsPrivateReply } from 'src/app/interactions/store/actions/interaction-actions/interactions.actions';
import { CustomerTicketHelper } from 'src/app/shared/customer-ticket/customer-ticket-helper';
import { NOTE_CHANNELS, TICKET_STATES } from 'src/app/shared/customer-ticket/ticket-event-handler/assets/ticket.constants';
import { TicketEventHandlerService } from 'src/app/shared/customer-ticket/ticket-event-handler/ticket-event-handler.service';
import { TicketUpdateMessageHandler } from './assets/ticket-update-message-handler.function';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { take, takeUntil } from 'rxjs/operators';
import { AddNotePayload } from 'src/app/shared/customer-ticket/ticket-event-handler/assets/add-note-payload.interface';
import { Utils } from 'src/app/Utils';
import { AgentGroupType } from 'src/app/shared/interfaces/agent-group-type.type';
import { ModalWindowService } from 'src/app/shared/modal-window/modal-window.service';
import { ToggleModalByName } from 'src/app/core/store/actions/modal.actions';
import { AddKnownCustomerEvent } from 'src/app/shared/customer-ticket/store/actions/ticket-event.actions';
import { FormHelper } from 'src/app/Utils/helpers';
import { MAX_TICKET_NOTE_LENGTH } from 'src/app/constants';
import { ChatTemplate } from './assets/chat-template';
import { ChatUpdateSettings } from './assets/chat-update-settings';
import { CustomerInfoState } from 'src/app/customer-info-summary-page/store/state/customer-info.state';
import { ChatFunctions } from './assets/chat-functions';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { InteractionTicketActions } from '../store/actions/interaction-ticket-actions';
import { ChatTicketState } from '../store/state/chat-ticket.state';
import { Observable, timer } from 'rxjs';
import { ChatTicketActions } from '../store/actions/chat-ticket-actions';
import { CoreState } from 'src/app/core/store/state/core.state';
import { ChatTemplateCategory } from './assets/template-messages';
import { RefreshTicketActions } from '../store/actions/refresh-ticket-actions';
import { ChatNoteActions } from '../store/actions/chat-note-actions';

const DELAY = 10000;


@Component({
    selector: 'app-chat-interaction',
    templateUrl: './chat-interaction.component.html',
    styleUrls: ['./chat-interaction.component.scss'],
})
export class ChatInteractionComponent extends FormHelper implements OnInit, OnDestroy {

    @Select(ChatTicketState.getTicketHelper) ticketHelper$: Observable<CustomerTicketHelper>;
    @Select(ChatTicketState.isLoading) loading$: Observable<CustomerTicketHelper>;
    @Select(ChatTicketState.isTicketClosed) isTicketClosed$: Observable<CustomerTicketHelper>;
    @Select(ChatTicketState.getError) error$: Observable<CustomerTicketHelper>;

    @ViewChild('notesScrollbar') private myScrollContainer: ElementRef;
    @Input() hexId: string;
    @Input() externalId: string;

    files: File[] = [];
    callBackForm: FormGroup;
    noteForm: FormGroup;

    chatUpdateSettings = new ChatUpdateSettings();

    isPrivate = false;
    attachmentModalOpen = false;
    isExpanded = false;
    menuOpen = false;

    categorySelected: ChatTemplateCategory;
    categories: string[] = ChatTemplate.getCategories();
    templateOptions: string[] = [];

    hasSalesSuccessful = false;
    agentType: AgentGroupType = 'NORMAL';
    email = '';

    constructor(
        private ticketEventHandler: TicketEventHandlerService,
        private store: Store,
        private fb: FormBuilder,
        private modal: ModalWindowService,
    ) {
        super();
    }

    ngOnInit(): void {
        this.store.dispatch(new ChatTicketActions.FetchTicket(this.hexId))
            .pipe(
                take(1),
                takeUntil(this.ngDestroy$)
            )
            .subscribe(() => this.startUpdater());
        const teamRole = this.store.selectSnapshot(CoreState.getTeamRole);
        this.agentType = Utils.SfHelpers.getAgentGroupType(teamRole);

        this.setNoteForm();
    }

    private startUpdater() {
        timer(DELAY, DELAY)
            .pipe(
                takeUntil(this.ngDestroy$)
            )
            .subscribe({
                next: () => this.refreshTicket()
            });
    }

    refreshTicket() {
        this.store.dispatch(new RefreshTicketActions.RefreshTicket(this.hexId));
    }

    private setNoteForm() {
        const msg = this.isPrivate ? '' : this.getStartingMessage();
        this.noteForm = this.fb.group({
            comment: this.fb.control(
                msg,
                Validators.compose([
                    Validators.required,
                    Validators.maxLength(MAX_TICKET_NOTE_LENGTH),
                ]),
            ),
        });

        this.InitForm(this.noteForm);
    }

    private getStartingMessage() {
        const customerName = this.getCustomerName();
        return ChatTemplate.getStartingMessage(customerName);
    }

    private getCustomerName() {
        return this.store.selectSnapshot(CustomerInfoState.getCustomer)?.first_name;
    }

    private setIsPrivate(bool: boolean) {
        this.isPrivate = bool;
    }


    onAddNote(type: "close" | "update") {
        const clickActions = {
            close: this.onCloseTicket.bind(this),
            update: this.onUpdateTicket.bind(this)
        }

        if (this.getTicketHelper().isTicketClosed()) {
            this.modal.showError("Can not update a closed ticket!'");
            return
        }

        clickActions[type]();
    }

    private showUpdatingResultMessage(success: boolean, message: string) {
        this.chatUpdateSettings.sending = false;
        this.chatUpdateSettings.success = success;
        this.chatUpdateSettings.message = message;
        setTimeout(() => (this.chatUpdateSettings.message = null), 10000);
        this.scrollToBottom();
    }

    toggleTextExpand() {
        this.isExpanded = !this.isExpanded;
    }

    onCloseUpdateMessage() {
        this.chatUpdateSettings.message = null;
    }

    toggleAttachmentModal() {
        this.attachmentModalOpen = !this.attachmentModalOpen;
    }

    private getTicketHelper() {
        return this.store.selectSnapshot(ChatTicketState.getTicketHelper);
    }

    onUpdateTicket() {
        const ticketHelper = this.getTicketHelper();
        if (
            ticketHelper.isTicketClosed() ||
            ticketHelper.doesEscalationExistFromAgent(this.email) ||
            ticketHelper.doesPublicNoteExistForAgent(this.email) ||
            !this.isPrivate
        ) {
            this.handleButtonEvent(TICKET_STATES.HELD);
        } else {
            this.onUpdateWarningFlow();
        }
    }

    private async onUpdateWarningFlow() {
        const msg =
            'Are you sure you want to add a private note without replying to the customer first?';
        const confirm = await this.modal.showConfirmation(msg);
        if (!confirm) {
            return;
        }

        const eventComment = 'Added private note before replying to customer.';
        this.store.dispatch(
            new AddKnownCustomerEvent({
                eventComment,
                reload: false,
                specificHexId: this.hexId,
            }),
        );
        this.handleButtonEvent(TICKET_STATES.HELD);
    }

    async onCloseTicket() {
        const msg = `Are you sure you want to close this ticket? NOTE: Close means completely resolved.`;
        const confirm = await this.modal.showConfirmation(msg);
        if (!confirm) {
            return;
        }

        this.handleButtonEvent(TICKET_STATES.CLOSED);
    }

    showWarning() {
        const msg = 'Cannot allow escalation of a closed ticket!';

        this.modal.showError(msg);
    }

    async checkClosed() {
        if (this.getTicketHelper().isTicketClosed()) {
            this.showWarning();
        } else {
            this.toggleEscalationsModal();
        }
    }

    async handleButtonEvent(state_id: number) {
        this.chatUpdateSettings.sending = true;

        const addedNote = await this.addNote(state_id);
        const message = TicketUpdateMessageHandler.getMessage(addedNote);

        if (!addedNote) {
            this.showUpdatingResultMessage(false, message);
            return;
        }
      if ((this.agentType === 'SALES' || this.agentType === 'RETENTIONS' || this.agentType === 'UPGRADE') &&
        state_id === TICKET_STATES.CLOSED) {

        this.store.dispatch([
          new SetIsPrivateReply(this.isPrivate),
          ChatFunctions.getModalEvent(state_id, this.agentType),
        ]);
      }
      else {
        this.store.dispatch([
          new ContinueFromPostTicketModal(this.isPrivate),
          new SetIsPrivateReply(this.isPrivate),
        ]);
      }
        this.showUpdatingResultMessage(true, message);
    }

    toggleIsPrivate(event: MatTabChangeEvent) {
        const isPrivate = event.index === 1;
        this.setIsPrivate(isPrivate);
        this.updatePlaceholderMessage();
    }

    private updatePlaceholderMessage() {
        const control = this.noteForm.get('comment');
        const text = control.value?.trim();
        const startingMessage = this.getStartingMessage();
        if (text !== '' && text !== startingMessage) {
            //Don't update if user changed message
            return;
        }
        const msg = this.isPrivate ? '' : this.getStartingMessage();
        control.setValue(msg);
    }

    //TODO: improve this
    async addNote(state_id: number) {
        const commentControl = this.noteForm.get('comment');
        const comment: string = commentControl.value;
        if (!comment) return false; //If message is empty return early

        const payload: AddNotePayload = {
            hex_id: this.hexId,
            comment: Utils.Functional.pipe(
                comment,
                ChatTemplate.populateLinks,
                ChatTemplate.formatTextToHTML,
            ),
            channel_id: NOTE_CHANNELS.HTML,
            isPrivate: this.isPrivate,
        };

        if (state_id === TICKET_STATES.CLOSED) {
            payload['state_id'] = state_id;
        }

        commentControl.setValue('');
        const response = await this.ticketEventHandler.addNote(payload)

        const success = response?.status === 200 || response?.status === 201;
        return success;
    }

    attachFileData(files: File[]) {
        this.attachmentModalOpen = false;
        this.files = [...this.files, ...files];
    }

    onRemoveFile(i: number) {
        this.files.splice(i, 1);
    }

    scrollToBottom(): void {
        try {
            this.myScrollContainer.nativeElement.scrollTop =
                this.myScrollContainer.nativeElement.scrollHeight;
        }
        catch (err) { /* empty */ }
    }

    toggleEscalationsModal() {
        this.store.dispatch(new ToggleModalByName('escalation_modal', true));
    }

    setTemplateCategory(category: ChatTemplateCategory) {
        this.categorySelected = category;
        this.templateOptions = ChatTemplate.getTemplateOptions(category);
    }

    setTemplateMessage(option: string) {
        const customerName = this.getCustomerName();
        const accountNumber = this.store.selectSnapshot(CustomerInfoState.getCustomer)?.account_number;
        const agentName = this.store.selectSnapshot(CoreState.getAgent).name;
        const templateMessage = ChatTemplate.getTemplateMessage({
            option,
            data: {
                customerName,
                agentName,
                accountNumber
            },
            category: this.categorySelected,
        });
        this.noteForm.get('comment').setValue(templateMessage);
    }

    onCloseChat() {
        this.store.dispatch([
          new InteractionTicketActions.SetSelectedHexId(null),
          new InteractionTicketActions.RefreshAllTickets() // used this because it wasn't being used
        ]);
    }

    ngOnDestroy(): void {
        this.ngDestroy$.next(null);
        this.ngDestroy$.complete();

        this.store.dispatch([
            new InteractionTicketActions.SetSelectedHexId(null),
            new ChatNoteActions.ChatNoteInteractionsDestroyed()
        ]);
    }
}
