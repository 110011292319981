import { Injectable } from '@angular/core';
import { Action, createSelector, State, StateContext, Store, } from '@ngxs/store';
import { produce } from 'immer';
import { tap } from 'rxjs/operators';
import { ResetCustomerDetails } from 'src/app/customer-info-summary-page/store/actions/customer.actions';
import { DataLoading } from 'src/app/shared/interfaces/data-loading.interface';
import { Utils } from 'src/app/Utils';
import { DataLoadingHelper } from 'src/app/Utils/helpers';
import { LoadsheddingService } from '../../loadshedding.service';
import { LoadsheddingPerSimActions } from '../actions/loadshedding-per-sim-actions';
import { NewLoadsheddingPerSite } from '../interfaces/new-loadshedding-per-site.interface';
import { SimSelectors } from 'src/app/customer-data-components/sim-details/store/selectors/sim.selectors';


const getDefaults = (): NewLoadsheddingPerSimStateModel => {
    return {
        ids: {}
    }
}

interface NewLoadsheddingPerSimStateModel {
    ids: {
        [id: string]: DataLoading<NewLoadsheddingPerSite>
    }
}
@State<NewLoadsheddingPerSimStateModel>({
    name: 'sf_new_loadshedding_per_sim_state',
    defaults: getDefaults()
})
@Injectable()
export class NewLoadsheddingPerSimState {

    static isLoading(id: string) {
        return createSelector([NewLoadsheddingPerSimState], (state: NewLoadsheddingPerSimStateModel) => {
            return state.ids?.[id]?.loading;
        });
    }

    static isLoaded(id: string) {
        return createSelector([NewLoadsheddingPerSimState], (state: NewLoadsheddingPerSimStateModel) => {
            return state.ids?.[id]?.loaded;
        });
    }

    static getError(id: string) {
        return createSelector([NewLoadsheddingPerSimState], (state: NewLoadsheddingPerSimStateModel) => {
            return state.ids?.[id]?.error;
        });
    }

    static getData(id: string) {
        return createSelector([NewLoadsheddingPerSimState], (state: NewLoadsheddingPerSimStateModel) => {
            return state.ids?.[id]?.data;
        });
    }

    constructor(private loadsheddingService: LoadsheddingService, private store: Store) {
    }



    @Action(LoadsheddingPerSimActions.Init)
    Init(ctx: StateContext<NewLoadsheddingPerSimStateModel>, action: LoadsheddingPerSimActions.Init) {
        const { ids } = ctx.getState();
        const { id } = action;

        const { loaded, loading } = ids?.[id] ?? {};

        if (loading || loaded) {
            return;
        }

        return ctx.dispatch(new LoadsheddingPerSimActions.Fetch(id));
    }

    @Action(LoadsheddingPerSimActions.Fetch)
    Fetch(ctx: StateContext<NewLoadsheddingPerSimStateModel>, action: LoadsheddingPerSimActions.Fetch) {
        const { id } = action;
        const msisdn = this.store.selectSnapshot(SimSelectors.getMSISDNforServiceID(id));

        ctx.setState(
            produce(draft => {
                draft.ids[id] = DataLoadingHelper.isLoading();
            })
        );

        return this.loadsheddingService.getLoadsheddingForMSISDN(msisdn)
            .pipe(tap({
                next: res => ctx.dispatch(new LoadsheddingPerSimActions.FetchSuccess(id, res)),
                error: (e: unknown) => ctx.dispatch(new LoadsheddingPerSimActions.FetchFail(id, e))
            }));
    }


    @Action(LoadsheddingPerSimActions.FetchSuccess)
    FetchSuccess(ctx: StateContext<NewLoadsheddingPerSimStateModel>, action: LoadsheddingPerSimActions.FetchSuccess) {
        const { id, payload } = action;

        ctx.setState(
            produce(draft => {
                draft.ids[id] = DataLoadingHelper.dataLoaded(payload);
            })
        );
    }


    @Action(LoadsheddingPerSimActions.FetchFail)
    FetchFail(ctx: StateContext<NewLoadsheddingPerSimStateModel>, action: LoadsheddingPerSimActions.FetchFail) {
        const { id, error } = action;
        const err = Utils.Helpers.findError(error, '');
        const message = `Failed to loadshedding info for site. ${err}`;

        ctx.setState(
            produce(draft => {
                draft.ids[id] = DataLoadingHelper.errorLoaded(message);
            })
        );
    }

    @Action([ResetCustomerDetails, LoadsheddingPerSimActions.Clear])
    Clear(ctx: StateContext<NewLoadsheddingPerSimStateModel>) {
        ctx.setState(getDefaults());
    }

}   