import { MILISECONDS_IN_MINUTE, SA_LOCALE } from "src/app/constants";
import { Callback, CallbackNotification, CallbackNotificationDetail } from "../types/callback-response.interface";
import { formatDate } from "@angular/common";
import { ToggleModalByName } from "src/app/core/store/actions/modal.actions";
import { AgentNotificationActions } from "src/app/toolbar/agent-notifications/store/actions/agent-notification-actions";
import { CallbackModalData } from "../types/callback-modal-data.interface";
import { Utils } from "src/app/Utils";
import moment from "moment";
import { CallbackStatus } from "../types/callback-status.type";


export const getAllStatuses = (): CallbackStatus[] => ["INITIATED", "PENDING", "ACKNOWLEDGED"];

export const checkableCallback = (callback: CallbackNotification) => {
    const { status, notificationDetails, scheduled_date_time } = callback ?? {};
    if (!scheduled_date_time || !notificationDetails?.length) {
        return false;
    }

    const lastTimeDelta = getDefaultCallbackNotificationDetails().pop().timeDelta;
    const isPastLastCheckTime = moment(scheduled_date_time)
        .add(lastTimeDelta)
        .isBefore();

    const isLastItemChecked = notificationDetails[notificationDetails.length - 1]?.checked;

    return status !== "ACKNOWLEDGED" && !isPastLastCheckTime && !isLastItemChecked;
}

/**
 *  Get the default notification details ordered in ASC order
 */
export const getDefaultCallbackNotificationDetails = (): CallbackNotificationDetail[] => {
    return [
        {
            id: "2-minutes-before",
            message: "You have a callback in two minutes",
            timeDelta: -MILISECONDS_IN_MINUTE * 2,
            checked: false
        },
        {
            id: "now",
            message: "You have a callback scheduled for now.",
            timeDelta: 0,
            checked: false
        },
        {
            id: "4-minutes-after",
            message: "Your callback will be added back into the queue in 1 minute.",
            timeDelta: MILISECONDS_IN_MINUTE * 4,
            checked: false
        },
    ]
}

export const toCallbackNotifications = (newCallbacks: Callback[], currentCallbackNotifications: CallbackNotification[]): CallbackNotification[] => {

    const newMappedNotifications = Utils.Mappers.toHashMapV2(newCallbacks ?? [], "id");
    const currentMappedNotifications = Utils.Mappers.toHashMapV2(currentCallbackNotifications ?? [], "id");

    //All current callback notifications that are not also part of the new ones
    const combinedNotifications = (currentCallbackNotifications ?? [])
        .filter(callback => !newMappedNotifications[callback?.id]);

    //Update new callbacks with the current notification details
    for (const callback of newCallbacks ?? []) {
        const currentCallback = currentMappedNotifications[callback?.id];

        combinedNotifications.push({
            ...callback,
            notificationDetails: currentCallback
                ? currentCallback.notificationDetails
                : getDefaultCallbackNotificationDetails()
        });
    }

    return combinedNotifications;
}

export const createNotificationAction = (callback: Callback, notificationDetail: CallbackNotificationDetail): AgentNotificationActions.AddNotification => {

    const { id, recipient_ref: customerEmail, scheduled_date_time: time } = callback ?? {};

    return new AgentNotificationActions.AddNotification({
        alertType: "toast",
        clickActions: [
            new ToggleModalByName<CallbackModalData>("upcoming_callback_modal", true, { callbackId: id })
        ],
        message: notificationDetail.message,
        description: `Callback with ${customerEmail} scheduled for ${formatDate(time, "HH:mm", SA_LOCALE)}`,
        icon: "perm_phone_msg"
    });
}