import { Observable, of } from "rxjs";
import { map, catchError } from "rxjs/operators";
import { ISuccessResult } from "src/app/shared/models/success-result.model";


export const mapToSuccessResult = <T>() =>
    (obs$: Observable<T>): Observable<ISuccessResult<T>> =>
        obs$.pipe(
            map((data) => ({ data, error: null })),
            catchError((e: unknown) => of({ data: null, error: e }))
        );