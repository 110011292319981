
/**
 *
 * @export
 * @param {(HTMLElement | string)} [to]
 * @description Scrolls the page to either the top of the page or to an element if "to" argument is passed in.
 * @return {*} 
 */
export function ScrollTo(to?: HTMLElement | string) {
    if (to) {
        if (typeof to === 'string') {
            const elem = document.querySelector(to);
            const {x} = elem.getBoundingClientRect();
            return window.scrollTo({top:x, behavior: 'smooth'});
        }

        const {x} = to.getBoundingClientRect();
        return window.scrollTo({top:x, behavior: 'smooth'});
    }

    return window.scrollTo({top:0, behavior: 'smooth'});
}