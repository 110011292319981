export const AGENT_GROUP_TYPES = [
    "NORMAL",
    "SALES",
    "CEC.TEAM.A",
    "RETENTIONS",
    "CEC.COLLECTIONS.A",  //Billing collections
    "CEC.COLLECTIONS.B",
    "ACTIVATIONS",
    "CSAT",
    "RICA",
    "EARLYOPTIMIZATION",
    "UPGRADE",
    "NO_CONTACT",
    "CEC.NVIDIA.A",
    "FULFILLMENT.DELIVERIES_A",
    "FULFILLMENT.CPE.COLLECTIONS_A",
    "FULFILLMENT.RICA_A",
    "RETAIL.CUSTOMER_ENGAGEMENT.A",
    "CEC.CALLBACK.A",
    "THE101"


] as const;

export type AgentGroupType = typeof AGENT_GROUP_TYPES[number];
