import moment from "moment"

interface DateRangeFilter<T = moment.Moment> {
    from: T,
    to: T
}

export const getDefault = () => {
    return {
        stateId: [0],
        date_range: getDefaultUpdatedAt()
    }
}

export const getDefaultUpdatedAt = (options?: { toDate: boolean }): DateRangeFilter<Date | moment.Moment> => {
    const { toDate } = options ?? {};

    const today = startOfDayMoment(moment());
  // const tomorrow = startOfDayMoment(moment().add(1, "day"));
    return {
        from: toDate ? today.toDate() : today,
      to: toDate ? today.toDate() : today
    }
}

export const startOfDayMoment = (inp?: moment.MomentInput) => moment(inp).startOf('day').utcOffset(2);
const formatMomentDate = (inp?: moment.MomentInput) => moment(inp).toISOString(true).replace(".000", "");
const formattedStartOffDay = (inp?: moment.MomentInput) => formatMomentDate(startOfDayMoment(inp));

export const formatUpdatedAtString = (rangeFilter: DateRangeFilter<moment.Moment | string>) => {
    const { from, to } = rangeFilter;
    const toDate = moment(to).add(1, "day");
    return `{from: "${formattedStartOffDay(from)}", to: "${formattedStartOffDay(toDate)}"}`;
}

