
type ReplaceType = null | undefined | number | string | boolean;

export const replaceNaN = (jsonString: string, replacer: ReplaceType = null) =>
    jsonString?.replace(/NaN/g, <any>replacer);


export const getNonNaNJson = <T>(jsonString: string, replacer?: null | undefined | number | string | boolean): T | string => {
    const str = replaceNaN(jsonString, replacer);

    try {
        return JSON.parse(str);
    }
    catch (error) {
        return str;
    }
}


