import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotificationsLockupComponent } from './lockup/notifications-lockup.component';
import { NotifcationComponent } from './elements/notifcation/notifcation.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { MatIconModule } from '@angular/material/icon';



@NgModule({
  declarations: [
    NotificationsLockupComponent,
    NotifcationComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    MatIconModule,
  ],
  exports: [
    NotificationsLockupComponent
  ]
})
export class NotifiactionsModule { }
