import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DEFAULT_HTTP_OPTIONS } from 'src/app/constants';
import { Callback, CallbackExtras } from '../store/types/callback-response.interface';
import { PageResult } from 'src/app/shared/interfaces/page-result.interface';
import { PageSearchRequest } from 'src/app/shared/interfaces/page-search-request.interface';
import { CallbackSearchCriteria } from '../store/types/callback-search-criteria.interface';
import { UpdateCallbackStatusPayload } from '../store/types/callback-status.type';
import { AddCallbackRequestPayload } from '../store/types/add-callback-payload.interface';
import { environment } from 'src/environments/environment';
import { WorkableCallbackResponse } from '../store/types/workable-callback-response.interface';

@Injectable({
    providedIn: 'root'
})
export class CallbackService {

    constructor(private http: HttpClient) { }

    fetchWorkableCallback(payload: CallbackSearchCriteria) {
        const url = `${environment.main_proxy}/callback-service/callback/search/workable`;
        return this.http.post<WorkableCallbackResponse>(url, payload, DEFAULT_HTTP_OPTIONS);
    }

    fetchById(id: string) {
        const url = `${environment.main_proxy}/callback-service/callback/${id}`;
        return this.http.get<{ result: Callback }>(url, DEFAULT_HTTP_OPTIONS);
    }

    updateStatus(id: string, payload: UpdateCallbackStatusPayload) {
        const url = `${environment.main_proxy}/callback-service/callback/${id}/status`;
        return this.http.put<{ result: Callback }>(url, payload, DEFAULT_HTTP_OPTIONS);
    }

    updateExtras(id: string, payload: { extras: CallbackExtras }) {
        const url = `${environment.main_proxy}/callback-service/callback/${id}/extras`;
        return this.http.put<{ result: Callback }>(url, payload, DEFAULT_HTTP_OPTIONS);
    }

    searchCallbacks(payload: PageSearchRequest<CallbackSearchCriteria>) {
        const url = `${environment.main_proxy}/callback-service/callback/search`;
        return this.http.post<{ result: PageResult<Callback> }>(url, payload, DEFAULT_HTTP_OPTIONS);
    }

    addCallback(payload: AddCallbackRequestPayload) {
        const url = `${environment.main_proxy}/callback-service/callback/`;
        return this.http.post<{ result: Callback }>(url, payload, DEFAULT_HTTP_OPTIONS);
    }
}