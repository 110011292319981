import { Pipe, PipeTransform } from '@angular/core';
import { PROMISE_TO_PAY_STATUS_TYPES } from 'src/app/customer-data-components/account-details/sub-components/promise-to-pay/assets/promise-to-pay.constants';
import { DataHandler } from '../../data-handler/data-handler';

@Pipe({ name: 'getPromiseToPayStatus' })
export class GetPromiseToPayStatusPipe implements PipeTransform {

  transform(value: number): string {
    if (!DataHandler.isDefined(value) || isNaN(value)) {
      return;
    }
    return Object.keys(PROMISE_TO_PAY_STATUS_TYPES).find(key => value === PROMISE_TO_PAY_STATUS_TYPES[key]);
  }

}
