import { SnowflakeSelectOption } from "src/app/shared/elements/rain-forms/elements/snowflake-select/interfaces/rain-select-option.interface";


export const NETWORK_DEFAULT_POST_TICKET_OPTIONS: SnowflakeSelectOption[] = [
    { label: 'No Connection', value: 'No Connection' },
    { label: 'Intermittent Connectivity', value: 'Intermittent Connectivity' },
    { label: 'Slow Speed', value: 'Slow Speed' },
    { label: 'Buffering - wifi', value: 'Buffering - wifi' },
    { label: 'Mostly on 4G fallback', value: 'Mostly on 4G fallback' },
    { label: 'Network incident', value: 'Network incident' },
]

export const BILLING_DISPUTE_POST_TICKET_OPTIONS: SnowflakeSelectOption[] = [
    { label: 'Multiple Invoices', value: 'Multiple Invoices' },
    { label: 'First invoice', value: 'First invoice' },
    { label: 'Zero usage', value: 'Zero usage' },
    { label: 'Product Migration', value: 'Product Migration' },
    { label: 'Post Paid last charge - cancellation', value: 'Post Paid last charge - cancellation' },
]

export const BILLING_ACCOUNT_ARREARS_POST_TICKET_OPTIONS: SnowflakeSelectOption[] = [
    { label: 'Customer wants to make payment / needs assistance with payment', value: 'Customer wants to make payment / needs assistance with payment' },
    { label: 'Insufficient funds now', value: 'Insufficient funds now' },
    { label: 'Long term affordability', value: 'Long term affordability' },
    { label: 'Expired Card', value: 'Expired Card' },
    { label: 'Late Cancellation', value: 'Late Cancellation' },
    { label: 'Late SIM Swap', value: 'Late SIM Swap' },
    { label: 'Zero usage', value: 'Zero usage' }
]

export const SYSTEM_DEFAULT_POST_TICKET_OPTIONS: SnowflakeSelectOption[] = [
    { label: 'MPS not updating after payment', value: 'MPS not updating after payment' },
    { label: 'Payment not allocating', value: 'Payment not allocating' },
    { label: 'Debited on previous payment date', value: 'Debited on previous payment date' },
    { label: 'Double debit', value: 'Double debit' },
]

export const DELIVERY_DEFAULT_POST_TICKET_OPTIONS: SnowflakeSelectOption[] = [
    { label: 'Delayed delivery', value: 'Delayed delivery' },
    { label: 'Incorrect/partial delivery', value: 'Incorrect/partial delivery' },
    { label: 'Delivered but not activated', value: 'Delivered but not activated' },
    { label: 'Documents required/RICA', value: 'Documents required/RICA' },
]

export const RAINONE_DEFAULT_POST_TICKET_OPTIONS: SnowflakeSelectOption[] = [
    { label: 'Migration', value: 'Migration' },
    { label: 'Level up', value: 'Level up' },
    { label: 'Speed up', value: 'Speed up' },
    { label: 'Sim swap/lost sim', value: 'Sim swap/lost sim' },
    { label: 'Raintalk app', value: 'Raintalk app' },
    { label: 'APN settings', value: 'APN settings' },
    { label: 'Raintalk query', value: 'Raintalk query' },
    { label: "Can't make/receive calls", value: "Can't make/receive calls" },
]
