import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { MainWebSocketService } from 'src/app/shared/services/main-web-socket/main-web-socket.service';
import { AgentStatus, AgentStatusAction } from '../assets/agent-status.type';
import { Select, Selector, Store } from '@ngxs/store';
import { SetAvailable, SetBreak, SetBusy, SetCoaching, SetLoggedOff, SetMeeting, SetTeaBreak1, SetTeaBreak2 } from '../store/actions/agent-status-actions/agent-status.actions';
import { AgentState } from '../store/state/agent.state';
import { TextAndBackgroundColor } from '../assets/text-and-background-color.interface';
import { getAgentStatusColorChoice } from '../assets/rain-agent-colors.constant';

const STATUS_OPTIONS: { [key in AgentStatus]?: new () => AgentStatusAction } = {
  available: SetAvailable,
  busy: SetBusy,
  "tea break 1": SetTeaBreak1,
  break: SetBreak,
  "tea break 2": SetTeaBreak2,
  "coaching": SetCoaching,
  "meeting": SetMeeting,
  "logged off": SetLoggedOff
}

const STATUS_AND_COLOR_CHOICES: { status: AgentStatus, colorChoice: TextAndBackgroundColor }[] = [
  { status: "available", colorChoice: getAgentStatusColorChoice("available") },
  { status: "busy", colorChoice: getAgentStatusColorChoice("busy") },
  { status: "tea break 1", colorChoice: getAgentStatusColorChoice("tea break 1") },
  { status: "break", colorChoice: getAgentStatusColorChoice("break") },
  { status: "tea break 2", colorChoice: getAgentStatusColorChoice("tea break 2") },
  { status: "coaching", colorChoice: getAgentStatusColorChoice("coaching") },
  { status: "meeting", colorChoice: getAgentStatusColorChoice("meeting") },
  { status: "logged off", colorChoice: getAgentStatusColorChoice("logged off") }
];

interface AgentStatusComponentViewModel {
  status: AgentStatus;
  colorChoice: TextAndBackgroundColor;
}
class AgentStatusComponentSelectors {

  @Selector([AgentState.getStatus, AgentState.getTextAndBackgroundColor])
  static getViewModel(status: AgentStatus, colorChoice: TextAndBackgroundColor): AgentStatusComponentViewModel {
    return {
      status,
      colorChoice
    }
  }

}

@Component({
  selector: 'app-agent-status-modal',
  templateUrl: './agent-status-modal.component.html',
  styleUrls: ['./agent-status-modal.component.scss']
})
export class AgentStatusModalComponent {

  @Select(AgentStatusComponentSelectors.getViewModel) viewModel$: Observable<AgentStatusComponentViewModel>;

  @Input() width = "fit-content";
  @Input() height: "2rem";
  @Input() showDropdown = false;
  errorMessage = "error with websocket connection.";

  readonly selectableStatusChoices = STATUS_AND_COLOR_CHOICES;

  constructor(private store: Store,
    public mainWebsocket: MainWebSocketService) { }

  onSetStatus(status: AgentStatus) {
    this.store.dispatch(new STATUS_OPTIONS[status]);
  }

  onSetLoggedOff() {
    this.store.dispatch(new SetLoggedOff());
  }

}
