import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { SetIsPrivateReply, ToggleHasMoreOpenTicketsModal } from "../actions/interaction-actions/interactions.actions";



export interface InteractionsModalStateModel {
  show_more_tickets_modal: boolean;
  is_private_reply: boolean;
}

@State<InteractionsModalStateModel>({
  name: 'agent_interaction',
  defaults: {
    show_more_tickets_modal: false,
    is_private_reply: false
  }
})

@Injectable()
export class InteractionsModalState {

  @Selector()
  static isPrivateReply(state: InteractionsModalStateModel) { return state.is_private_reply; }


  @Selector()
  static showMoreOpenTicketsModal(state: InteractionsModalStateModel) { return state.show_more_tickets_modal; }


  @Action(SetIsPrivateReply)
  setIsPrivateReply(ctx: StateContext<InteractionsModalStateModel>, action: SetIsPrivateReply) {
    ctx.patchState({
      is_private_reply: action.isPrivate,
    });
  }

  @Action(ToggleHasMoreOpenTicketsModal)
  ToggleHasMoreOpenTicketsModal(ctx: StateContext<InteractionsModalStateModel>, action: ToggleHasMoreOpenTicketsModal) {
    ctx.patchState({
      show_more_tickets_modal: action.payload
    });
  }

}
