<div [class.hidden]="mainWebsocket.isConnected || !loaded" class="wrapper">
  <mat-icon class="red pointer" [matTooltip]="errorMessage"
    [matMenuTriggerFor]="menu">
    error
  </mat-icon>


  <mat-menu #menu="matMenu" xPosition="before">
    <button mat-menu-item class="menu-button" [disabled]="mainWebsocket.reconnecting"
      (click)="mainWebsocket.reconnect()">
      <span>{{mainWebsocket.reconnecting ? "reconnecting..": "reconnect"}}</span>
      <mat-icon>cached</mat-icon>
    </button>
  </mat-menu>
</div>
