import { DOCUMENT } from '@angular/common';
import { AfterViewInit, Component, Inject, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { FormHelper } from '../../helpers/form.helper';
import { validationMessages } from '../../pipes';

@Component({
  selector: 'sf-form-textarea',
  templateUrl: './form-textarea.component.html',
  styleUrls: ['./form-textarea.component.scss']
})
export class FormTextareaComponent extends FormHelper implements AfterViewInit {
  @Input() control: FormControl;
  @Input() messages = validationMessages;
  @Input() public label: string;
  @Input() public type = 'text';
  @Input() public placeholder = 'type here...';
  @Input() public autocomplete = '';
  @Input() public display: 'column' | 'row' = 'column';
  @Input() public markValid = false;
  @Input() public focusInput = false;
  
  constructor(@Inject(DOCUMENT) private dom: Document) { 
    super();
  }

  ngAfterViewInit() {
    if (this.focusInput) {
      return this.focusInputElement();
    }
    if(this.control) {
      this.InitFormControl(this.control);
    }
  }

  public onBoundaryClick() {
    return this.focusInputElement();
  }

  private focusInputElement() {
    const inputEl: HTMLInputElement = this.dom.querySelector(`input[name="${this.formatLabel(this.label)}"]`);
    if (inputEl !== undefined) inputEl.focus();
  }

  public formatLabel(l: string) {
    return l.replace(' ', '_');
  }
}