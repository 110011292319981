import { Injectable } from "@angular/core";
import { Store } from "@ngxs/store";
import { RxStomp } from "@stomp/rx-stomp";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { WhatsappMessageActions } from "../store/actions/whatsapp-message-actions";
import { WhatsappMessage } from "../store/types/whatsapp-message.interface";
import { WhatsappIdentifier } from "../store/types/whatsapp-send-message-payload.interface";


type TopicType = "status" | "message";

@Injectable({
    providedIn: 'root'
})
export class WhatsappStompSocketService {

    private readonly _rxStomp = new RxStomp();
    private readonly _close = new Subject<null>();

    constructor(private store: Store) { }

    createWatchers(identifier: WhatsappIdentifier) {
        if (this._rxStomp.active) {
            //Return if already active
            return;
        }

        this.configure();
        this.activate();

        return {
            messageTopic$: this.watchMessageTopic(identifier),
            statusTopic$: this.watchStatusTopic(identifier)
        }
    }

    //TODO: use same handler possibly
    private watchMessageTopic(identifier: WhatsappIdentifier) {
        const messageTopic$ = this.watchTopic(identifier, "message")
            .subscribe({
                next: msg => {
                    const whatsappMsg = <WhatsappMessage>JSON.parse(msg.body);
                    this.store.dispatch(new WhatsappMessageActions.ReceivedNewMessage(whatsappMsg));
                }
            });

        return messageTopic$;
    }

    private watchStatusTopic(identifier: WhatsappIdentifier) {
        const statusTopic$ = this.watchTopic(identifier, "status")
            .subscribe({
                next: msg => {
                    const whatsappMsg = <WhatsappMessage>JSON.parse(msg.body);
                    this.store.dispatch(new WhatsappMessageActions.ReceivedUpdatedMessage(whatsappMsg));
                }
            });

        return statusTopic$;
    }

    private configure() {
        this._rxStomp.configure({
            brokerURL: `${environment.whatsapp_websocket_url}/chat`,
            heartbeatIncoming: 0,
            heartbeatOutgoing: 20000,
            reconnectDelay: 500
        });
    }

    private activate() {
        this._rxStomp.activate();
    }

    private watchTopic(identifier: WhatsappIdentifier, type: TopicType) {
        return this._rxStomp.watch(this.getTopicPath(identifier, type))
            .pipe(
                takeUntil(this._close)
            );
    }

    private getTopicPath(identifier: WhatsappIdentifier, type: TopicType) {
        if ("recipient" in identifier) {
            return `/topic/chat/channel/recipient/${type}/1/${identifier.recipient}`;
        }
        return `/topic/chat/channel/customerid/recipient/${type}/1/${identifier.recipientCustomerId}`;
    }

    deactivate() {
        this._rxStomp.deactivate();
        this._close.next(null);
    }
}