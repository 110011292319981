<div class="form-row-group address">
  <h2>{{header}}</h2>
  <ul [style.maxHeight]="maxHeight" class="scrollable">
    <li class="form-row">
      <sf-form-input label="unit no" [control]="form.get('alt_address').get('unit_number')"></sf-form-input>
      <sf-form-input label="floor level" [control]="form.get('alt_address').get('floor_level')"></sf-form-input>
    </li>

    <li class="form-row">
      <sf-form-input label="building name" [control]="form.get('alt_address').get('building_name')"></sf-form-input>
    </li>

    <li class="form-row">
      <sf-form-input [markValid]="autoFilled"  label="street no" [control]="form.get('alt_address').get('street_number')"></sf-form-input>
      <sf-form-input [markValid]="autoFilled"  label="street name" [control]="form.get('alt_address').get('street_name')"></sf-form-input>
    </li>
    <li class="form-row">
      <sf-form-input [markValid]="autoFilled" label="suburb" [control]="form.get('alt_address').get('suburb')"></sf-form-input>
      <sf-form-input [markValid]="autoFilled" label="city" [control]="form.get('alt_address').get('city')"></sf-form-input>
    </li>
    <li class="form-row">
      <sf-form-input [markValid]="autoFilled" label="province" [control]="form.get('alt_address').get('province')"></sf-form-input>
      <sf-form-input [markValid]="autoFilled" label="postal code" [control]="form.get('alt_address').get('postal_code')"></sf-form-input>
    </li>
  </ul>
</div>
