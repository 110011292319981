
export const PRODUCT_MIGRATION_FIELD = "Product migration";
export const NO_SOLUTION = "No Solution";
export const HELP_DECLINED = "Help Declined";



export const RETENTIONS_NESTED_LEVEL_OPTIONS = {

  "Network Experience": {
    "No Connection": [
      NO_SOLUTION,
      "Optimisation via call",
      "Optimisation with dasher (post-visit)",
      "Dasher with ODU router (post-visit)",
      "Credit offered",
      PRODUCT_MIGRATION_FIELD //to which product (with or without credit)
    ],
    "Intermittent connection": [
      NO_SOLUTION,
      "Optimisation via call",
      "Optimisation with dasher (post-visit)",
      "Dasher with ODU router (post-visit)",
      "Credit offered",
      PRODUCT_MIGRATION_FIELD
    ],
    "Slow speeds": [
      NO_SOLUTION,
      "Optimisation via call",
      "Optimisation with dasher (post-visit)",
      "Dasher with ODU router (post-visit)",
      "Credit offered",
      PRODUCT_MIGRATION_FIELD
    ]
  },
  "Affordability": {
    "Short-term": [
      NO_SOLUTION,
      "Credit offered",
      "Change payment date",
      "Part pay",
      PRODUCT_MIGRATION_FIELD
    ],
    "Long-term": [
      NO_SOLUTION,
      "Credit offered",
      PRODUCT_MIGRATION_FIELD
    ],
    "Price change": [
      NO_SOLUTION,
      "Credit offered",
      PRODUCT_MIGRATION_FIELD
    ]
  },
  "Out of Coverage": {
    "Relocating out": [
      NO_SOLUTION,
      PRODUCT_MIGRATION_FIELD
    ],
    "Purchased out of coverage": [
      NO_SOLUTION,
      PRODUCT_MIGRATION_FIELD
    ]
  },
  Other: {
    "Bad customer service experience": [
      NO_SOLUTION,
      "Credit offered"
    ],
    "Billing dispute": [
      NO_SOLUTION,
      "Credit offered",
      "Change payment date",
      "Part pay",
      PRODUCT_MIGRATION_FIELD
    ],
    "Product pricing": [
      NO_SOLUTION,
      "Credit offered",
      PRODUCT_MIGRATION_FIELD
    ]
  }
};


export const RETENTION_OUTCOME_OPTIONS = [
  "Retained",
  HELP_DECLINED,
  "No Contact",
  "Migrated to 5G",
  "Downgraded to 4G",
  "Package Downgrade",
  "Package Upgrade",
  "Scheduled cancellation",
  "Immediate cancellation"
];

