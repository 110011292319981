import { Antenna, GeminiFeature } from "../store/interfaces/gemini-response.interface";
import { MultiLayer } from "./multilayer";


const DISTANCE = 1100;
const TOWER_DISTANCE = 2000;
const COLOR_LIGHT_BLUE = '#0283cf';


export class AzimuthLayer {

  private _layer: MultiLayer;

  constructor(private map: google.maps.Map, private feature: GeminiFeature) {
    this.drawAzimuth();
  }


  private drawAzimuth() {
    const { properties, geometry } = this.feature;
    const { coordinates } = geometry;

    const latLng = new google.maps.LatLng(coordinates[1], coordinates[0]);
    const bounds = new google.maps.LatLngBounds();
    bounds.extend(latLng);

    const polygons = this.getPolygons(latLng, properties.antenna);
    const circle = this.createCircle(latLng);

    this._layer = new MultiLayer(this.map, circle, polygons);
    this._layer.setVisibility(true);
  }


  private getPolygons(latLng: google.maps.LatLng, antennas: Antenna[]) {
    const polygons: google.maps.Polygon[] = [];
    const bounds = new google.maps.LatLngBounds();

    for (const antenna of antennas) {
      const { azi, rad } = antenna;

      const points: google.maps.LatLng[] = [];
      //starting point
      points.push(latLng);
      points.push(google.maps.geometry.spherical.computeOffset(latLng, DISTANCE, wrapDegrees(azi, rad, false)));
      points.push(google.maps.geometry.spherical.computeOffset(latLng, DISTANCE, wrapDegrees(azi, rad, true)));
      //ending point
      points.push(latLng);

      polygons.push(this.createPolygon(points));

      for (const point of points) {
        bounds.extend(point);
      }
    }

    return polygons;
  }





  private createPolygon(points: google.maps.LatLng[]) {
    return new google.maps.Polygon({
      paths: points,
      strokeWeight: 0,
      fillColor: COLOR_LIGHT_BLUE,
      zIndex: 2,
      fillOpacity: 0.5
    });
  }


  private createCircle(latLng: google.maps.LatLng) {
    return new google.maps.Circle({
      center: latLng,
      radius: (TOWER_DISTANCE / 2) + 100,
      strokeColor: COLOR_LIGHT_BLUE,
      fillColor: COLOR_LIGHT_BLUE,
      zIndex: 1,
      fillOpacity: 0.1
    });
  }


  cleanUp() {
    this._layer.removeAllListeners();
  }


  public get layer(): MultiLayer {
    return this._layer;
  }
}


function wrapDegrees(fromDegree: number, degree: number, add: boolean): number {
  let returnValue: number;

  if (add) {
    returnValue = fromDegree + degree;
    if (returnValue >= 360) {
      returnValue = Math.abs(360 - degree);
    }

  }
  else {
    returnValue = fromDegree - degree;
    if (returnValue <= 0) {
      returnValue = returnValue + 360;
    }
  }

  return returnValue;
}


