<sf-popup-modal
  [isOpen]="true"
  [hasCloseButton]="false"
  [autoClose]="false"
  [successResponse]="modalOptions?.successResponse"
  [sending]="modalOptions?.sending"
  [spinnerMessage]="modalOptions?.spinnerMessage"
>

  <ng-container header>post upgrade form</ng-container>
  <ng-container mainContent>
    <sf-modal-form-container [formGroup]="form">

        <sf-select
          label="Select Status"
          [options]="statusOptions"
          marginBottom="1rem"
          (onOptionSelect)="onOptionSelect('status', $event)"
          placeholder="select status"
          [hasError]="form.get('status').errors?.notSelected"
        >
        </sf-select>
        <sf-select
          *ngIf="upgradeSuccessful === false"
          label="Reason"
          [options]="reasonOptions"
          marginBottom="1rem"
          [control]="form.get('reasonIfUnsuccessful')"
          placeholder="select reason"
          (onOptionSelect)="onOptionSelect('reasonIfUnsuccessful', $event)"
          [hasError]="form.get('reasonIfUnsuccessful').errors?.notSelected"
        >
        </sf-select>

       <sf-select
          *ngIf="upgradeSuccessful"
          label="successful upgrade type"
          [options]="upgradeOptions"
          marginBottom="1rem"
          [control]="form.get('successfulUpgradeType')"
          placeholder="select reason"
          (onOptionSelect)="onOptionSelect('successfulUpgradeType', $event)"
          [hasError]="form.get('successfulUpgradeType').errors?.notSelected"
          >
      </sf-select>
        <sf-form-input
          *ngIf="upgradeSuccessful"
          label="order number"
          type="number"
          [control]="form.get('orderNumber')"
          placeholder="enter order number"
        ></sf-form-input>
    </sf-modal-form-container>
  </ng-container>

  <ng-container buttons>
    <sf-rain-button
      [isSolid]="true"
      [disabled]="form?.invalid || modalOptions?.sending"
      (click)="onSend()"
    >
      send
    </sf-rain-button>

    <ng-template #cantSend>
      <sf-rain-button
        [disabled]="modalOptions?.sending"
        (click)="move()"
      >
        continue
      </sf-rain-button>
    </ng-template>
  </ng-container>

</sf-popup-modal>
