import { SiteAlarmDetails } from "../interfaces/site-alarm-details.interface";


export interface FormattedSiteAlarmDetails {
    "site name": string;
    "site ID": number;
    "alarm name": string;
    description: string;
    "raised time": string;
    severity: string;
    source: string;
}


export const formatSiteAlarmDetails = (siteName: string, data: SiteAlarmDetails): FormattedSiteAlarmDetails => {
    const { location_id, alarm_name, relevance, alarm_raised_time, severity, src } = data ?? {};
    return {
        "site name": siteName,
        "site ID": location_id,
        "alarm name": alarm_name,
        description: relevance,
        "raised time": alarm_raised_time,
        severity,
        source: src
    }
}
