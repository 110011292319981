import { Utils } from "src/app/Utils";


export class SetIsKnownCustomer {
    static readonly type = Utils.Helpers.Type("[Sales Settings] set is known customer");
    constructor(public isKnown: boolean) { }
}

export class SetHasBillCycleSpec {
    static readonly type = Utils.Helpers.Type("[Sales Settings] set if known customer has bill cycle spec");
    constructor(public hasSpec: boolean) { }
}

export class SetIsMigration {
    static readonly type = Utils.Helpers.Type("[Sales Settings] set is migration");
    constructor(public isMigration: boolean) { }
}

export class Clear {
    static readonly type = Utils.Helpers.Type("[Sales Settings] clear");
}