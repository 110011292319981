

export const SNOWFLAKE_FE_PROXY = "snowflake-fe";
export const APIGEE_SIT_APIKEY = "1rQWEBw1pUlsfHz2kISkqFBlm6nlvBKZ";
export const BSS_CF_API_KEY = "LrQ2oFL4NNo9jgXdOey7DGjuQoyd3xpH";

export const FIREBASE_CONFIG = {
    apiKey: 'AIzaSyC7lUkmE329eyqBa_0_b9NlYFtL6b6IG1g',
    authDomain: 'rain-mobile-a12e2.firebaseapp.com',
    databaseURL: 'https://rain-mobile-a12e2.firebaseio.com',
    projectId: 'rain-mobile-a12e2',
    storageBucket: 'rain-mobile-a12e2.appspot.com',
    messagingSenderId: '32563084554',
    appId: '1:32563084554:web:7422db729959f2df264ef3',
    measurementId: 'G-Q790WB54ES'
}

