import { Selector, createSelector } from "@ngxs/store";
import { CustomerInfoState } from "../state/customer-info.state";
import { SmartSubData } from "src/app/shared/interfaces/smartsub-data.interface";
import { OrderProductSelector } from "./order-product.selector";
import { SimProductSelector } from "src/app/customer-data-components/sim-details/store/selectors/sim-product.selectors";
import { AccountState } from "src/app/shared/interfaces/account-status.interface";
import { SelectedServiceSelectors } from "./selected-service.selectors";
import { RainoneSelectors } from "./rainone.selectors";
import { CatalogSimProduct } from "src/app/core/store/interfaces/product-catalog.interfaces";
import { LegacySelectors } from "./legacy.selectors";
import { CustomerInfoSelector } from "./customer-info.selector";
import { ServicePolicy } from "../interfaces/basic-customer-service.interface";
import { CustomerServiceState } from "../state/customer-service.state";
import { CheckMigrationState } from "src/app/sales/store/state/check-migration.state";

const ALLOWED_ACCOUNT_STATES: AccountState[] = ['NORMAL', 'FUTUREPAYRUN']
const ALLOWED_SERVICE_STATES_FOR_SPEEDUP: ServicePolicy[] = ['High Speed', 'High_Speed_30', 'High_Speed_60', 'High_Speed_Unlimited']

export interface DisableConditionOptions {
  prohibitedAccountState: CantPurchaseReason,
  prohibitedServicePolicyForSpeedUp: CantPurchaseReason,
  hasRainOneOrderOrService: CantPurchaseReason,
  isCardExpired: CantPurchaseReason,
  noMigrationOptions: CantPurchaseReason,
  canMigrate: CantPurchaseReason,
  hasScheduledActionsForServiceFamily: CantPurchaseReason,
  has4gRainoneOrderOrService: CantPurchaseReason,
  hasUOP4gRainoneOrderOrService: CantPurchaseReason,
  has5gRainoneOrderOrService: CantPurchaseReason,
  has5gRainoneOrderOrServiceForSales: CantPurchaseReason,
  has5gRainone101OrderOrServiceForSales: CantPurchaseReason,
  hasMax4gMobileOrdersOrServicesForSales: CantPurchaseReason,
  only4GServices: CantPurchaseReason,
  hasMax5gRainone101HomeOrWorkOrderOrServiceForSales: CantPurchaseReason,
  hasMax5gRainone101WorkOrderOrServiceForSales: CantPurchaseReason,
  all?: string
}

export type DisableConditions = keyof DisableConditionOptions;

export interface CantPurchaseReason {
  isTrue: boolean,
  reason: string
}

export class PurchaseValidationSelectors {


  @Selector([SelectedServiceSelectors.hasScheduledActionsForSelectedServiceFamily])
  static hasScheduledActionsForServiceFamily(hasActions: boolean) {
    return {
      isTrue: hasActions,
      reason: '• this service or service family currently has a scheduled action'
    }
  }

  @Selector([CustomerInfoState.getAccountState])
  static prohibitedAccountState(accountState: AccountState) {
    return {
      isTrue: !(ALLOWED_ACCOUNT_STATES.includes(accountState)),
      reason: '• account is overdue/suspended'
    }
  }

  @Selector([CustomerServiceState.getCurrentServicePolicy])
  static prohibitedServicePolicyForSpeedUp(policy: ServicePolicy) {
    return {
      isTrue: !(ALLOWED_SERVICE_STATES_FOR_SPEEDUP.includes(policy)),
      reason: `• unable to speed up due to prohibited service policy: ${policy}`
    }
  }

  @Selector([OrderProductSelector.hasActiveRainOneOrderOrService])
  static hasRainOneOrderOrService(hasOrderService: boolean) {
    return {
      isTrue: hasOrderService,
      reason: '• account already has an active Rain One service or order'
    }
  }

  @Selector([
    RainoneSelectors.has4gRainoneOrderOrService,
    SimProductSelector.getSelectedCombinedProduct
  ])
  static has4gRainoneOrderOrService(
    has4gRainoneOrderOrService: boolean,
    selectedProduct: CatalogSimProduct
  ) {
    return {
      isTrue: (selectedProduct?.category === "4G" && !selectedProduct?.name?.includes('off-peak')) && has4gRainoneOrderOrService,
      reason: '• account already has an active 4G rainone order or service'
    }
  }

  @Selector([
    RainoneSelectors.hasUOP4gRainoneOrderOrService,
    SimProductSelector.getSelectedCombinedProduct
  ])
  static hasUOP4gRainoneOrderOrService(
    hasUOP4gRainoneOrderOrService: boolean,
    selectedProduct: CatalogSimProduct
  ) {

    // TODO still need to check for UOP - this logic is incorrect

    const { config, category , name} = selectedProduct ?? {}

    const isLegacy = config?.legacy
    const is4G = category === '4G'
    const isOffPeak = name?.includes('off-peak') // TODO i know :(
    const isUOP = isLegacy && is4G && isOffPeak

    return {
      isTrue: isUOP && hasUOP4gRainoneOrderOrService,
      reason: '• account already has an active UOP 4G rainone order or service'
    }
  }

  @Selector([
    RainoneSelectors.has5gRainoneOrderOrService,
    SimProductSelector.getSelectedCombinedProduct
  ])
  static has5gRainoneOrderOrService(
    has5gRainoneOrderOrService: boolean,
    selectedProduct: CatalogSimProduct
  ) {
    return {
      isTrue: selectedProduct?.category === '5G' && has5gRainoneOrderOrService,
      reason: '• account already has an active 5G rainone order or service'
    }
  }

  @Selector([
    RainoneSelectors.has5gRainone101OrderOrService,
    SimProductSelector.getSelectedCombinedProduct
  ])
  static has5gRainone101OrderOrService(
    has5gRainoneOrderOrService: boolean,
    selectedProduct: CatalogSimProduct
  ) {
    return {
      isTrue: selectedProduct?.category === '5G' && has5gRainoneOrderOrService,
      reason: '• account already has an active rainone 101 order or service'
    }
  }

  @Selector([RainoneSelectors.has5gRainoneOrderOrService])
  static has5gRainoneOrderOrServiceForSales(has5gRainoneOrderOrService: boolean) {
    return {
      isTrue: has5gRainoneOrderOrService,
      reason: '• account already has an active rainone 5G order or service'
    }
  }
  @Selector([RainoneSelectors.has5gRainone101OrderOrService])
  static has5gRainone101OrderOrServiceForSales(has5gRainoneOrderOrService: boolean) {
    return {
      isTrue: has5gRainoneOrderOrService,
      reason: '• account already has an active rainone 101 order or service'
    }
  }

  @Selector([RainoneSelectors.hasMax4GMobileOrdersOrServices])
  static hasMax4gMobileOrdersOrServicesForSales(hasMax4GMobileOrdersOrServices: boolean) {
    return {
      isTrue: hasMax4GMobileOrdersOrServices,
      reason: '• account already has max active 4G Mobile orders or services'
    }
  }

  @Selector([RainoneSelectors.has5gRainone101WorkOrderOrService])
  static has5gRainone101WorkOrderOrServiceForSales(has5gRainoneOrderOrService: boolean) {
    return {
      isTrue: has5gRainoneOrderOrService,
      reason: '• account already has an active rainone 101 work order or service'
    }
  }
  @Selector([RainoneSelectors.hasMax5gRainone101WorkOrderOrService])
  static hasMax5gRainone101WorkOrderOrServiceForSales(has5gRainoneOrderOrService: boolean) {
    return {
      isTrue: has5gRainoneOrderOrService,
      reason: '• account already has reached max amount of active rainone 101 work order or service'
    }
  }

  @Selector([RainoneSelectors.hasMax5gRainone101WorkOrderOrService, RainoneSelectors.has5gRainone101OrderOrService])
  static hasMax5gRainone101HomeOrWorkOrderOrServiceForSales(hasMax5GRainoneWorkOrderOrService: boolean,has5gRainoneOrderOrService: boolean) {
    return {
      isTrue: hasMax5GRainoneWorkOrderOrService && has5gRainoneOrderOrService,
      reason: '• account already reached max amount of active rainone 101 & rainone 101 work orders or services'
    }
  }



  @Selector([CustomerInfoState.getSmartsubData])
  static isCardExpired(smartSubData: SmartSubData) {
    return {
      isTrue: Object.keys(smartSubData?.payment_method).length ? new Date() > new Date(smartSubData.payment_method.card_expiry.split('/').join('-')) : true,
      reason: '• current payment method/card is expired or invalid'
    }
  }

  @Selector([SimProductSelector.hasMigrationOptions])
  static noMigrationOptions(hasOptions: boolean) {
    return {
      isTrue: !hasOptions,
      reason: '• service has no migration options'
    }
  }

  @Selector([CheckMigrationState.canMigrate])
  static canMigrate(canMigrate: boolean) {
    return {
      isTrue: !canMigrate,
      reason: '• service already has a pending migration'
    }
  }

  @Selector([
    CustomerInfoSelector.has5gService,
    LegacySelectors.has4gService,
  ])
  static only4GServices(
    has5GService: boolean,
    has4gService: boolean
    ) {

    return {
      isTrue: !has5GService && has4gService,
      reason: '• customer only has 4G services'
    }
  }

  @Selector([
    PurchaseValidationSelectors.prohibitedAccountState,
    PurchaseValidationSelectors.prohibitedServicePolicyForSpeedUp,
    PurchaseValidationSelectors.hasRainOneOrderOrService,
    PurchaseValidationSelectors.isCardExpired,
    PurchaseValidationSelectors.noMigrationOptions,
    PurchaseValidationSelectors.canMigrate,
    PurchaseValidationSelectors.hasScheduledActionsForServiceFamily,
    PurchaseValidationSelectors.has4gRainoneOrderOrService,
    PurchaseValidationSelectors.hasUOP4gRainoneOrderOrService,
    PurchaseValidationSelectors.has5gRainoneOrderOrService,
    PurchaseValidationSelectors.has5gRainoneOrderOrServiceForSales,
    PurchaseValidationSelectors.has5gRainone101OrderOrServiceForSales,
    PurchaseValidationSelectors.hasMax4gMobileOrdersOrServicesForSales,
    PurchaseValidationSelectors.only4GServices,
    PurchaseValidationSelectors.hasMax5gRainone101HomeOrWorkOrderOrServiceForSales,
    PurchaseValidationSelectors.hasMax5gRainone101WorkOrderOrServiceForSales,
  ])
  static conditionsForPurchaseDisable(
    prohibitedAccountState: CantPurchaseReason,
    prohibitedServicePolicyForSpeedUp: CantPurchaseReason,
    hasRainOneOrderOrService: CantPurchaseReason,
    isCardExpired: CantPurchaseReason,
    noMigrationOptions: CantPurchaseReason,
    canMigrate: CantPurchaseReason,
    hasScheduledActionsForServiceFamily: CantPurchaseReason,
    has4gRainoneOrderOrService: CantPurchaseReason,
    hasUOP4gRainoneOrderOrService: CantPurchaseReason,
    has5gRainoneOrderOrService: CantPurchaseReason,
    has5gRainoneOrderOrServiceForSales: CantPurchaseReason,
    has5gRainone101OrderOrServiceForSales: CantPurchaseReason,
    hasMax4gMobileOrdersOrServicesForSales: CantPurchaseReason,
    only4GServices: CantPurchaseReason,
    hasMax5gRainone101HomeOrWorkOrderOrServiceForSales: CantPurchaseReason,
    hasMax5gRainone101WorkOrderOrServiceForSales: CantPurchaseReason
  ): DisableConditionOptions {
    return {
      prohibitedAccountState,
      prohibitedServicePolicyForSpeedUp,
      hasRainOneOrderOrService,
      isCardExpired,
      noMigrationOptions,
      canMigrate,
      hasScheduledActionsForServiceFamily,
      has4gRainoneOrderOrService,
      hasUOP4gRainoneOrderOrService,
      has5gRainoneOrderOrService,
      has5gRainoneOrderOrServiceForSales,
      has5gRainone101OrderOrServiceForSales,
      only4GServices,
      hasMax5gRainone101HomeOrWorkOrderOrServiceForSales,
      hasMax5gRainone101WorkOrderOrServiceForSales,
      hasMax4gMobileOrdersOrServicesForSales
    }
  }

  static conditionsForDisable(selectedConditions: DisableConditions[]) {

    return createSelector(
      [PurchaseValidationSelectors.conditionsForPurchaseDisable],
      (allDisableConditions: CantPurchaseReason[]): CantPurchaseReason => {

        let reasonsForDisable: string[]

        if (selectedConditions.includes('all')) {

          reasonsForDisable = Object.values(allDisableConditions)
            .map(condition => { return (condition.isTrue) ? condition.reason : undefined })
            .filter(reason => reason)

          return {
            isTrue: reasonsForDisable.length > 0,
            reason: reasonsForDisable.join('\n')
          }
        }

        reasonsForDisable = selectedConditions
          .map(selectedCondition => { return (allDisableConditions[selectedCondition].isTrue) ? allDisableConditions[selectedCondition].reason : undefined })
          .filter(reason => reason)

        return {
          isTrue: reasonsForDisable.length > 0,
          reason: reasonsForDisable.join('\n')
        }
      })
  }
}
