<ng-container *ngIf="viewModel$ | async as viewModel">

  <div
    *ngIf="viewModel?.status"
    class="agent-status-modal pointer text"
    [style.width]="width"
    [style.height]="height"
    [matMenuTriggerFor]="menu"
    [style.background-color]="viewModel?.colorChoice?.backgroundColor"
    [class.has-error]="!mainWebsocket.isConnected"
    [class.dont-point]="!showDropdown"
  >
    <p [style.color]="viewModel?.colorChoice?.textColor">
      {{viewModel?.status}}
    </p>
  </div>

  <mat-menu
    #menu="matMenu"
    [style.width]="width"
    class="menu"
  >
    <button
      mat-menu-item
      *ngFor="let option of selectableStatusChoices"
      [style.color]="option.colorChoice.textColor"
      class="font-normal"
      [style.font-family]="'Poppins'"
      (click)="onSetStatus(option.status)"
    >
      {{option.status}}
    </button>
  </mat-menu>

</ng-container>