import { Injectable } from '@angular/core';
import { ApiControllerService } from '../../../../shared/services/api-service/api-controller.service';
import { environment } from 'src/environments/environment';
import { ApiResponse } from '../../../../shared/services/api-service/api-response.interface';
import { CustomerTicket } from '../../../../shared/customer-ticket/interfaces/customer-ticket.interface';
import { Observable } from 'rxjs';
import { TicketResponse } from '../interfaces/ticket-response.interface';
import { TicketFilter } from '../interfaces/ticket-filter.interface';
import { HttpClient, HttpParams } from '@angular/common/http';
import { InteractionTicketRequest } from 'src/app/interactions/store/interfaces/interaction-ticket-request.interface';
import { TicketInteractionResponse } from 'src/app/interactions/store/interfaces/interaction-ticket-response.interface';


export interface TicketNumerItem {
  id: number;
  updated_at?: string;
  hex_id?: string;
  state_id?: number;
}

@Injectable({
  providedIn: 'root'
})
export class AllTicketsService {

  constructor(private apiService: ApiControllerService,
    private http: HttpClient) {
  }

  fetchAllTickets(page = 1, pageSize = 10): Observable<TicketResponse> {
    return this.apiService.getV2(`${environment.snowflake_api_url}/ticket?page=${page}&size=${pageSize}`, {}) as any;
  }

  filterAllTickets(page = 1, pageSize = 10, filters: TicketFilter): Observable<TicketResponse> {
    const payload = {
      pageSize: pageSize,
      pageNo: page,
      criteria: {
        ...filters
      }
    };
    return this.apiService.postV2(`${environment.snowflake_api_url}/ticket/query`, {}, payload) as any;
  }

  async getTicketCount() {
    const response = await this.apiService.get(`${environment.snowflake_api_url}/ticket`, {});
    return response.data?.meta?.total;
  }

  async getTicketFromId(hexId: string): Promise<ApiResponse<CustomerTicket>> {
    const response = await this.apiService.get(`${environment.snowflake_api_url}/ticket/${BigInt(hexId)}`,
      {}, 2);

    if (response?.status !== 200 || !response?.data?.data) return response;

    const ticketData: CustomerTicket | CustomerTicket[] = response?.data?.data;
    let ticket: CustomerTicket;

    if (Array.isArray(ticketData)) ticket = ticketData[0];
    else ticket = ticketData;

    return { data: ticket, errorMessage: null, status: 200 };
  }

  getTicketByHexId(hexId: string) {
    const id = BigInt(hexId)?.toString();
    return this.getTicketById(id);
  }

  getTicketById(id: string) {
    const url = `${environment.snowflake_api_url}/ticket/${id}`;
    return this.http.get<{ data: CustomerTicket }>(url);
  }

  getTicketFromIdV2(id: string): Observable<ApiResponse<CustomerTicket>> {
    return this.apiService.getV2(`${environment.snowflake_api_url}/ticket/${id}`, {});
  }

  //TODO: replace this one
  async getShortTicketsByEmail(email: string): Promise<ApiResponse<any>> {
    const response = await this.apiService.get(
      `${environment.snowflake_api_url}/ticket/interaction/${email}`,
      {}, 2);
    return response;
  }

  getShortTicketsByEmailv2(payload: InteractionTicketRequest) {
    const { email, pageInfo } = payload;
    const { page, size } = pageInfo;

    const params = new HttpParams()
      .set("page", page)
      .set("size", size)

    const url = `${environment.snowflake_api_url}/ticket/interaction/${email}`;
    return this.http.get<TicketInteractionResponse>(url, { params });
  }

  async getPhoneCallTicket(msisdn: string): Promise<ApiResponse<any>> {
    return this.apiService.get(`${environment.snowflake_api_url}/ticket/filter/msisdn/${msisdn}`,
      {}, 2);
  }


}


