
export interface TemplateData {
    customerName: string;
    agentName: string;
    accountNumber: string;
}

export type ChatTemplateCategory = keyof typeof CHAT_TEMPLATE_MESSAGES;
export const DEFAULT_GOODBYE_MSG = ["Cheers", "rain South Africa"];

const HELLO_PETER_WHATSAPP_LINK = "https://api.whatsapp.com/send/?phone=27212066516&text=I+would+like+to+write+a+review+about+Rain+Internet+Service+Provider&type=phone_number&app_absent=0";

export const TEMPLATE_LINKS = {
    "1": HELLO_PETER_WHATSAPP_LINK
} as const;

export const NON_MANUALLY_SELECTABLE_CATEGORIES: ChatTemplateCategory[] = ["Collection Issues"];


export const TEMPLATE_LINK_REF = "$link#";
const LINK_REF = (key: keyof typeof TEMPLATE_LINKS) => TEMPLATE_LINK_REF + key;

export const CHAT_TEMPLATE_MESSAGES = {
    "Billing and Account": {
        "Where can I see my bill for the month?": () => [
            "You can view your bill on your myrain page under account",
            "Here you will also find the date of your next bill and your previous invoices.",
            "You can also access the bill of each of your SIMs on myrain by tapping ‘manage' on the SIM, and scrolling down to view your monthly bill, invoices and usage.",
            ...DEFAULT_GOODBYE_MSG
        ],
        "How do I switch to another 4G plan?": () => [
            "It's easy to upgrade and downgrade plans for your SIMs",
            "Go to myrain, click ‘manage' on the SIM you want to change. Scroll down and select the plan you want to switch to.",
            "You'll receive a mail from us as soon as we've made the switch.",
            "Upgrades are immediate.",
            "Downgrades happen at the end of the month.",
            ...DEFAULT_GOODBYE_MSG
        ],
        "How will I be charged if I switch to another 4G plan?": () => [
            "We don't charge any upfront fees if you switch to another plan.",
            "If you upgrade, your bill at the end of the month will be worked out by calculating the days of the month you were on your current plan.",
            "and then adding that amount to the remaining days in the month that you're enjoying your new plan.",
            "Your bill for your first month should therefore be higher than the usual monthly subscription of your current plan. And lower than the usual monthly subscription for your new, upgraded plan.",
            "If you're moving up from our off-peak 4G plan, the cost of any peak gigs you may have used before the upgraded will be added to your next monthly bill.",
            "If you downgrade, your bill will only change from the end of the following month.",
            ...DEFAULT_GOODBYE_MSG
        ],
        "How can I update my payment details?": () => [
            "You can update your bank card details on myrain, under ‘account'.",
            ...DEFAULT_GOODBYE_MSG
        ],
        "How does billing work?": () => [
            "At rain, we bill upfront. And you pay for the whole month's unlimited data, no matter when in the month you join.",
            "But don't worry. The extra money makes its way back to you as a credit in your second month. So, in month two, you pay less than a full month.",
            "You pay your monthly package minus the number of days you used in month one. In month three and after that, it becomes really simple. You pay your selected product amount every month on your chosen payment date.",
            ...DEFAULT_GOODBYE_MSG
        ],
        "How do I switch to another 5G plan?": () => [
            "It's easy to upgrade or switch your 5G plan.",
            "Go to myrain, click ‘manage' on the plan you want to change, scroll down, select the plan you want to switch to. You will receive an email from us once your upgrade or downgrade has been successful.",
            ...DEFAULT_GOODBYE_MSG
        ],
        "How do I switch from 4G to 5G?": () => [
            "It's easy to upgrade to 5G.",
            "Go to myrain, click ‘manage' on the plan you want to change, scroll down and select the plan you want to switch to. You will receive an email from us once your upgrade or downgrade has been successful.",
            ...DEFAULT_GOODBYE_MSG
        ],
        "I have hit my gig limit. How do I change it?": () => [
            "If you've hit your peak gig-limit, no problem. Just sign into myrain and view your SIM. You can increase it from there.",
            ...DEFAULT_GOODBYE_MSG
        ],
        "What's a gig limit? ": () => [
            "If you are on the unlimited off-peak plan, you can control how many peak gigs (how much you want to spend additionally) every month by setting a gig limit.",
            ...DEFAULT_GOODBYE_MSG
        ]
    },
    "Network queries": {
        "How do I optimise my signal?": () => [
            "Use ‘optimise my signal' on myrain (via rain website) to find the best location in your house to place your device. ",
            "To get to ‘optimise your signal':",
            "Sign in to myrain",
            "View your 5G product tile",
            "Type in your location on the map and move your device as instructed.",
            ...DEFAULT_GOODBYE_MSG
        ],
        "I'm connected but can't reach the internet.": () => [
            "This might be because of your APN settings. For phones and most other devices your APN settings are automatically set up. If not, the default APN settings are as follows:",
            "APN name: rain",
            "All other settings can be left blank",
            "",
            "For a mobile or fixed Router:",
            "Connect your phone to the router WiFi",
            "Open a browser on your phone and connect to the router software by typing the following into the browser address line:",
            "The router software home page will display, the username is admin and the password is admin.",
            "You can change the password to something you will remember easily. This username/password is when you want to log into the router software again.",
            "Click on Settings > Dial Up > Profile Management. Even though the Profile name displays you must add a new profile.",
            "Click on New Profile.",
            "Profile Name - rain ",
            "APN - rain",
            "Save the Profile.",
            "Make sure the new rain profile is selected.",
            "You can now set up your own personalised WiFi log in username and password. You will use this username/password when you want to connect your phone/laptop/tablet to the router WiFi.",
            "Click Finish. You can now log out and close the browser tab.",
            "If the device does not connect to the rain network automatically, restart the device and log in with the new WiFi password you created.",
            "",
            "If it's not your APN settings, this might be the issue:",
            "You're using the incorrect device - make sure you're using a 4G enabled device for your 4G SIM. If you are on a 4G for phones product, your SIM will only work in a 4G phone.",
            "5G sim needs to be in a 5G device and not in a 4G device",
            "You've reached your gig limit - you can manage your limit on myrain",
            "An unsuccessful debit order - your rain account may be in arrears. Sign in to myrain to pay now and get reconnected.",
            ...DEFAULT_GOODBYE_MSG
        ],
        "How do I setup my 5G router?": () => [
            "A few tips:",
            "Move the router around the house until you find the best spot.",
            "Putting it high up always helps. ",
            "It normally works when placed in front of a window.",
            "It normally works when placed in front of a window.",
            "Make sure there's a clear line of sight and less barriers (like buildings and hills) between the router and outside your window.",
            "Click on the link below to watch the how to set up my router tutorial. https://www.rain.co.za/tutorials",
            ...DEFAULT_GOODBYE_MSG
        ]
    },
    "Deliveries": {
        "I have ordered my sim, when will I get it?": () => [
            "If you have successfully ordered from rain.co.za, our courier partners will get in touch with you about your delivery.",
            "Delivery usually takes between 3 and 5 working days.",
            "You can track your delivery as soon as the courier SMSs you your reference number. If you haven't received any communication within 5 working days, please go to ‘my orders' on your myrain page and contact support.",
            ...DEFAULT_GOODBYE_MSG
        ],
        "How do I change my delivery address?": () => [
            "if you've ordered from rain.co.za, and supplied the correct contact details in your profile, one of our courier partners will SMS you about delivery.",
            "To change your delivery address, please use the reference number in that SMS to contact the courier partner directly.",
            ...DEFAULT_GOODBYE_MSG
        ],
        "What do I need to RICA online?": () => [
            "The RICA process can be done online if you bought your SIM from one of our partners (Takealot, Hi Online, Mr D food or Pargo).",
            "You'll need:",
            "A smart phone.",
            "A valid South African ID, South African passport or non-South African passport.",
            "Proof of address not older than 3 months (like a municipal utility bill and bank statement) It needs to show your name, address and the date. Lease agreement also accepted (needs to have customers name and lessor name and surname, ID number, address, lease period at least 2 signatures).",
            "Yourself! Only you can RICA your SIM. No one can do it on your behalf.",
            ...DEFAULT_GOODBYE_MSG
        ],
        "What will I need to RICA with RAM /CCD when they deliver my SIM?": () => [
            "You will need the following:",
            "A valid South African ID, South African passport or non-South African.",
            "Proof of address not older than 3 months (like a municipal utility bill, lease agreement or bank statement) It needs to show your name, surname, address, date and logo of bank/municipality.",
            "Yourself! Only you can RICA your SIM. No one can do it on your behalf.",
            ...DEFAULT_GOODBYE_MSG
        ],
        "How do I activate my SIM from Takealot, Hi Online, Mr D or Pargo?": () => [
            "If you got your SIM from one of our partners, select 'activate SIM' on the home page and follow the 4 simple steps. You can also click the activate SIM button below.Remember, to complete the RICA online process and activate your SIM, you'll need the following:",
            "A valid South African ID, South African passport or foreign passport.",
            "Proof of address not older than 3 months (like a municipal utility bill or bank statement) It needs to show your name, address and the date.",
            "Lease agreements (Information needed: Customer & Lessor name & surname; ID number; address; Lease period; at least 2 signatures.)",
            "Yourself! Only you can RICA your SIM. No one can do it on your behalf.",
            ...DEFAULT_GOODBYE_MSG
        ]
    },
    "VoLTE queries": {
      "OTP SMS not received ?": () => [
          "If you do not receive your sms to view the otp please kindly open your rain app - called MyRain and go to my notifications, you will be able to view the OTP under all messages",
          ...DEFAULT_GOODBYE_MSG
      ],
      "Sim swop request before troubleshooting?": () => [
          "Please kindly confirm if the sim is correctly inserted and try inserting the sim into another mobile device to see if the same error occurs",
          ...DEFAULT_GOODBYE_MSG
      ],
      // "Sim not registering, receiving or making calls": () => [
      //     "Here is a guideline to assist you with registering your sim for making calls:",
      //     "If you have a dual sim and the Rain sim is in slot 1 for example then ensure the mobile data and calls are set for slot 1, it is highly recommended to use slot 1",
      //     "If you still struggle with making calls after trying the below, you will then need to install the rainapp -  raintalk",
      //     "\n",
      //     "To set up APN settings (android), follow the instructions given below: ",
      //     "• First, go to Settings ",
      //     "• Go to Mobile networks ",
      //     "• Access point names ",
      //     "• Add a New Access point",
      //     "• Ensure APN and Name is set to Rain and save",
      //     "\n",
      //     "To set up APN settings (iphone), follow the instructions given below:",
      //     "• Go to settings, mobile data, mobile data network",
      //     "• Under Mobile data set the APN to 'rain'",
      //     "• Under LTE setup set the APN to 'Rain' (on the later versions of iOS)",
      //     "• Leave the username and password empty",
      //     "• Go back to mobile data",
      //     "• Select Mobile data options",
      //     "• Select Voice and Data and make sure the option that has LTE/4g is selected ",
      //     "\n",
      //     "Disable Wi-Fi calling ?",
      //     "Wi-Fi calling allows you to make calls over Wi-Fi. ability to receive calls. As a quick fix, disable the function to see if it solves the problem. While this is a very useful function, it  may conflict with the ability to receive calls",
      //     "\n",
      //     "Perform a network reset ?",
      //     "A network reset restores a number of network-related functions to their predetermined configuration. In addition to mobile data, Wi-Fi, and Bluetooth, it also restores network connection settings, such as network selection mode.",
      //     "\n",
      //     "Airplane Mode ?",
      //     "A network reset restores a number of network-related functions to their predetermined configuration. In addition to mobile data, Wi-Fi, and Bluetooth, it also restores network connection settings, such as network selection mode.",
      //     "\n",
      //     "Perform a network reset ?",
      //     "A network reset restores a number of network-related functions to their predetermined configuration. In addition to mobile data, Wi-Fi, and Bluetooth, it also restores network connection settings, such as network selection mode.",

      //     ...DEFAULT_GOODBYE_MSG
      // ],
      "Unable to receive or make calls from/to specific numbers to Rain sim?": () => [
        "Please kindly provide us the following details in order for us to investigate accordingly regarding this issue that you are experiencing",
        "Your Rain number:",
        "Date of the call:",
        "Time of the call:",
        "Number unable to dial or receive call from:",
          ...DEFAULT_GOODBYE_MSG
      ]
  },
    "RICA AGENTS ONLY": {
        "Call Successful": () => [
            "We just chatted on the phone about your RICA process.",
            "The RICA Act is required by law and rain needs to make sure that valid RICA documents are recorded. We've found a small issue with your RICA documents.",
            "Please can you resend us the following RICA Documents? (Choose option based upon what is needed):",
            "• Your marriage certificate to validate the difference in surnames on ID/Passport/Asylum seeker/Refugee document or proof of address.",
            "• Your original ID/Passport/Asylum seeker/Refugee document (with clear photo)/Proof of address with clear details.",
            "• An original Passport/Travel document/Asylum seeker/Refugee document/Proof of address with a valid date (not older than 3 months).",
            "• A clear Ward council letter/Affidavit with a valid stamp and date (not older than 3 months).",
            "• A valid lease agreement, with an ID/Passport number, lease duration period and signatures clearly visible.",
            "• A third-party ID or a declaration letter to support the Proof of address in the third party's name.",
            "• Your original ID/Passport/Asylum seeker/Refugee document (not a digital copy i.e., a picture taken of your ID/Passport on your phone).",
            "Please can you send the correct documents to the following password protected and secure email address: rica-customers@rain.co.za",
            "Kindly indicate RICA UPDATE in the subject line as reference.",
            ...DEFAULT_GOODBYE_MSG
        ],
        "Call Unsuccessful": () => [
            "I just tried calling you about your RICA process.",
            "The RICA Act is required by law and rain needs to make sure that valid RICA documents are recorded. We've found a small issue with your RICA documents.",
            "Please can you resend us the following RICA Documents? (Choose option based upon what is needed):",
            "• Your marriage certificate to validate the difference in surnames on ID/Passport/Asylum seeker/Refugee document or proof of address.",
            "• Your original ID/Passport/Asylum seeker/Refugee document (with clear photo)/Proof of address with clear details.",
            "• An original Passport/Travel document/Asylum seeker/Refugee document/Proof of address with a valid date (not older than 3 months).",
            "• A clear Ward council letter/Affidavit with a valid stamp and date (not older than 3 months).",
            "• A valid lease agreement, with an ID/Passport number, lease duration period and signatures clearly visible.",
            "• A third-party ID or a declaration letter to support the Proof of address in the third party's name.",
            "• Your original ID/Passport/Asylum seeker/Refugee document (not a digital copy i.e., a picture taken of your ID/Passport on your phone).",
            "Please can you send the correct documents to the following password protected and secure email address: rica-customers@rain.co.za",
            "Kindly indicate RICA UPDATE in the subject line as reference.",
            ...DEFAULT_GOODBYE_MSG
        ]
    },
    "Request Review": {
        "Support": (data: TemplateData) => [
            "Thanks for the chat, I’m glad I could help! Before you get going, please could you spare a quick moment to add a review on Hello Peter about the helpful service you received?",
            `You can click here ${LINK_REF("1")} to write your review directly from WhatsApp.`,
            "Happy internetting!",
            data.agentName,
            "rain South Africa"
        ],
        "Sales": (data: TemplateData) => [
            "Thanks for taking my call. I’m glad I was able to help. Before you get going, please could you spare a moment to add a review on Hello Peter about the helpful service you received?",
            `You can click here ${LINK_REF("1")} to write your review directly from WhatsApp.`,
            "Thanks!",
            data.agentName,
            "rain South Africa"
        ],
    },
    "Collection Issues": {
        "Limit Exceeded": () => [
            "We viewed your profile and saw that you were struggling to make payment. This could be because of the settings on your banking app.",
            "Please sign in to your banking app, click on the cards option and go to update permanent limit, then tap on the online / scan / phone purchases and it will turn blue, allowing you to adjust your online shopping limit. Once the settings are saved you can then try to process payment on your rain dashboard.",
            "Thanks, rain."
        ],
        "Expired Card": () => [
            "Kindly note that your bank card details have expired and were unable to deduct your monthly subscription. Please see the below steps to help you update your payment details.",
            "1) Sign in to your rain account at https://www.rain.co.za/login/home using your email and password.",
            `2) Click on the 3 horizontal lines in the top left corner and click on “My rain“`,
            "3) Go to 'My account' and click manage",
            "4) Scroll down to “Payment method” and Click on “edit”",
            "5) Enter your card information:",
            "- Card number",
            "- Cardholder",
            "- Expiry date",
            "- CVV no (Last three digits at the back of the card)",
            "6) Lastly click on “Submit”",
            "7) You now need to add OTP in (A temporary R1 will be taken from the account and immediately reversed). This step is required to confirm the card is active and complete the process.",
            "N.B. Make sure the new card is active for 3d secure online purchases and the limit is not R0.",
            "Thanks, rain."
        ],
        "Credit Exceeded": () => [
            "If you are having difficulty paying with your bank card, why not try one of our other payment methods:",
            " • Instant EFT",
            " • Scan to Pay (Zapper, Snaps can, Masterpass)",
            " • Pay In - store - You can now pay your account at Checkers, Shoprite, Makro, USave, Game, Pick n Pay, PEP, Boxer, Ackermans, Rhino, Cambridge food, Builders or Kazang merchant",
            "Thanks, rain."
        ],
        "50% Campaign": () => [
            "To keep your unlimited rain data active, you can now part pay by settling your outstanding bill in smaller amounts. To pay off the first 50%, go to www.rain.co.za/my-rain and select “manage“.",
            "Thanks, rain."
        ],
        "Missed Payment": (data: TemplateData) => [
            "Just a heads up that your recent card payment was unsuccessful. To stay connected please go to manage myaccount and pay now.",
            `Alternatively, pay here https://payat.io/rain/11964${data.accountNumber}.`,
            "Thanks, rain."
        ]
    }
} as const;

