

export interface CircleOptions {
  degrees: number;
  lineWidth: number;
  backgroundColor: string;
  fillColor: string;
  text?: string | number;
  textSize?: number;
}


export type CountDownCircleOptions = Omit<CircleOptions, "degrees" | "text"> & {
  size: number;
}

export interface CountdownCircleValues {
  text?: string | number;
  percentageLeft: number;
}

export interface CountdownCircleInput {
  options?: CountDownCircleOptions;
  values: CountdownCircleValues;
}


export function getDefaultCountDownCircleValues(): CountDownCircleOptions {
  return {
    fillColor: "#0077c8", //Primary
    backgroundColor: "#d8e9f5", //Light blue
    size: 250, //In pixels
    lineWidth: 18,
    textSize: 135,
  }
}

