import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Hasher } from 'src/app/shared/models/hasher.model';
import { environment } from 'src/environments/environment';
import { ACCOUNT, ACCOUNT_NAME, REALM } from '../../assets/sip-config';
import { UpdateVoiceDeviceConfig } from './assets/attach-device-config.interface';
import { AttachDeviceResponse } from './assets/attach-device-response.interface';
import { CreateDeviceResponse } from './assets/create-device-response.interface';
import { CreateVoiceUserConfig } from './assets/create-voice-user-config.interface';
import { CreateVoiceUserResponse } from './assets/create-voice-user-response.interface';
import { getVoiceHeaders } from './assets/functions/get-voice-headers';
import { PBXauthResponse } from './assets/pbx-auth-response.interface';
import { VoiceAgent } from './assets/voice-agent.interface';
import { VoiceDeviceResponse } from './assets/voice-device-response.interface';
import { VoiceQueueConfigResponse } from './assets/voice-queue-configs.interface';
import { PbxAccessToken, VoiceAccessToken } from './assets/voice-tokens.type';
import { DEFAULT_CALL_RESTRICTIONS, DEFAULT_DEVICE_TYPE, DEFAULT_MEDIA_SETTINGS } from './assets/voice.constants';


@Injectable({
  providedIn: 'root'
})
export class VoiceAgentsService {

  constructor(private http: HttpClient) { }

  getVoiceUsers(voiceAccessToken: VoiceAccessToken) {
    const url = `${environment.voice}/api/v2/config/users`
    const headers = getVoiceHeaders(voiceAccessToken);

    return this.http.get<VoiceAgent[]>(url, { headers });
  }


  getAgentsInVoiceQueues(voiceAccessToken: VoiceAccessToken) {

    const url = `${environment.voice}/api/v2/config/queues`
    const headers = getVoiceHeaders(voiceAccessToken);

    return this.http.get<VoiceQueueConfigResponse[]>(url, { headers });
  }

  getPBXvoiceAuthToken(email: string, password: string) {
    const url = `${environment.voice_pbx}/v2/user_auth`;

    const headers = {
      "Content-Type": "application/json"
    }

    const payload = {
      data: {
        credentials: Hasher.createHashedPassword(`${email?.toLowerCase()}:${password}`),
        account_name: ACCOUNT_NAME
      }
    }

    return this.http.put<PBXauthResponse>(url, payload, { headers });
  }


  getDevices(pbxAccessToken: PbxAccessToken, paginate = false) {
    const timeStamp = Date.now();
    const url = `${environment.voice_pbx}/v2/accounts/${ACCOUNT}/devices?paginate=${paginate}&_=${timeStamp}`;

    const headers = {
      "Content-Type": "application/json",
      "X-Auth-Token": pbxAccessToken,
    }

    return this.http.get<VoiceDeviceResponse>(url, { headers });
  }

  createVoiceUser(config: CreateVoiceUserConfig, voiceAccessToken: VoiceAccessToken) {
    const url = `${environment.voice}/api/v2/config/users`;
    const password = environment.sipPassword;
    const headers = getVoiceHeaders(voiceAccessToken);

    const payload = {
      isNew: true,
      include_in_directory: true,
      level: "user",
      email: config.email.toLowerCase(),
      password,
      tags: [],
      tags_restriction: false,
      conference: {
        enabled: false
      },
      first_name: config.first_name,
      last_name: config.last_name,
      extension: config.extension.toString(),
      confirm_password: password,
      is_operator: true
    }

    return this.http.post<CreateVoiceUserResponse>(url, payload, { headers });
  }


  createDevice(email: string, owner_id: string, pbxAccessToken: PbxAccessToken) {
    const url = `${environment.voice_pbx}/v2/accounts/${ACCOUNT}/devices`;
    const username = email.replace("@", ".").substring(0, 32);

    const headers = {
      "Content-Type": "application/json",
      "X-Auth-Token": pbxAccessToken,
    }

    const payload = {
      data: {
        sip: {
          password: environment.sipPassword,
          realm: REALM,
          username
        },
        call_restriction: DEFAULT_CALL_RESTRICTIONS,
        device_type: DEFAULT_DEVICE_TYPE,
        enabled: true,
        media: DEFAULT_MEDIA_SETTINGS,
        suppress_unregister_notifications: true,
        name: email,
        ignore_completed_elsewhere: false,
        owner_id
      }
    }

    return this.http.put<CreateDeviceResponse>(url, payload, { headers });
  }

  updateDevice(config: UpdateVoiceDeviceConfig, pbxAccessToken: PbxAccessToken) {
    const { email, device_id, owner_id, device_type } = config;
    const username = email.replace("@", ".").substring(0, 32);

    const url = `${environment.voice_pbx}/v2/accounts/${ACCOUNT}/devices/${device_id}`;

    const headers = {
      "Content-Type": "application/json",
      "X-Auth-Token": pbxAccessToken,
    }

    const payload = {
      data: {
        sip: {
          password: environment.sipPassword,
          username,
          expire_seconds: 300,
          invite_format: "contact",
          method: "password"
        },
        call_restriction: DEFAULT_CALL_RESTRICTIONS,
        device_type,
        enabled: true,
        media: DEFAULT_MEDIA_SETTINGS,
        suppress_unregister_notifications: true,
        name: email,
        ignore_completed_elsewhere: false,

        contact_list: {},
        exclude_from_queues: false,
        music_on_hold: {},
        mwi_unsolicitated_updates: true,
        register_overwrite_notify: false,
        ringtones: {},
        id: device_id,
        owner_id
      }
    }

    return this.http.post<AttachDeviceResponse>(url, payload, { headers });
  }


  updateVoiceQueue(queueId: string, payload: VoiceQueueConfigResponse, voiceAccessToken: VoiceAccessToken) {
    const url = `${environment.voice}/api/v2/config/queue/${queueId}`;
    const headers = getVoiceHeaders(voiceAccessToken);

    return this.http.put<null>(url, payload, { headers });
  }


  toggleVoiceStatus(agentID:string, agentQueueID:string,enabled:boolean, accessToken:string){
    const action= enabled ? 'logout': 'login'
    const url = `${environment.voice}/callcenter/admin/agent/${agentID}/${action}?queueID=${agentQueueID}`;
    const headers = {
      "X-Account-ID": ACCOUNT,
      "Authorization": `Bearer ${accessToken}`
    }
    return this.http.put(url, {headers})
  }

}

