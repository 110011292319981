import { Injectable } from '@angular/core';
import { Navigate, RouterState } from '@ngxs/router-plugin';
import { Action, createSelector, Selector, State, StateContext, Store, } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { CUSTOMER_INFO_SUMMARY_ROUTE, HOME_ROUTE, NO_DATA_MESSAGE, SALES_PORTAL_ROUTE } from 'src/app/constants';
import { PaymentHistoryService } from 'src/app/customer-data-components/account-details/sub-components/payment-history/payment-history.service';
import { WhatsappIdentifier } from 'src/app/interactions/whatsapp-interactions/store/types/whatsapp-send-message-payload.interface';
import { SFValidators } from 'src/app/shared/functions/sf-validators';
import { AccountStatus, AccountStatusFlag } from 'src/app/shared/interfaces/account-status.interface';
import { Customer, SmartSubData, } from 'src/app/shared/interfaces/smartsub-data.interface';
import { CustomerDataService } from 'src/app/shared/services/customer-data.service';
import { Utils } from 'src/app/Utils';
import * as fromCustomerActions from '../actions/customer.actions';
import { CustomerInfoStateModel } from '../interfaces/customer-info-state.model';
import { Monad } from 'src/app/Utils/functional/monads/monads';
import { WalletFunctions } from 'src/app/customer-data-components/sim-details/store/functions/wallet-functions';
import { Dictionary } from 'src/app/shared/interfaces/dictionary.interface';
import { AccountActivity } from 'src/app/core/interfaces/account-activity.interface';
import { SalesPortalDestroyed } from 'src/app/sales/store/action/sales-portal.actions';
import { GeforceEligibilityActions } from 'src/app/customer-data-components/nvidia-geforce-now/store/actions/geforce-eligibility-actions';
import { NewProxyServiceActions } from '../actions/new-proxy-service';

const MAX_ORDER_DAYS = 31;
const MAX_CANCELLED_SERVICE_DAYS = 60;

@State<CustomerInfoStateModel>({
    name: 'customer_info_page',
    defaults: {
        loading: false,
        silent_loading: false,
        loaded: false,
        smartsubData: null,
        errorMessage: null,

        account_activities: null,
        account_activity_error: null,
        most_recent_search_param: null,

        max_order_days: MAX_ORDER_DAYS,
        max_cancelled_service_days: MAX_CANCELLED_SERVICE_DAYS
    },
})
@Injectable() //TODO: clean up this state, do not add as much logic in one place
export class CustomerInfoState {

    @Selector()
    static isLoading(state: CustomerInfoStateModel) { return state.loading; }

    @Selector()
    static isSilentlyLoading(state: CustomerInfoStateModel) { return state.silent_loading; }

    @Selector()
    static isLoaded(state: CustomerInfoStateModel) { return state.loaded; }

    @Selector()
    static getSmartsubData(state: CustomerInfoStateModel) { return state.smartsubData; }

    @Selector([CustomerInfoState.getSmartsubData])
    static getCustomer(smartsubData: SmartSubData) { return smartsubData?.user; }

    @Selector([CustomerInfoState.getCustomer])
    static getCustomerID(customer: Customer) { return customer?.id; }

    @Selector([CustomerInfoState.getCustomer])
    static getWhatsappIdentifier(customer: Customer): WhatsappIdentifier {
        const recipient = Utils.Formatters.formatPhoneNumber(customer?.phone);
        return { recipient } as const;
    }

    @Selector()
    static getErrorMessage(state: CustomerInfoStateModel) { return state.errorMessage; }

    @Selector()
    static getAccountActivityErrorMessage(state: CustomerInfoStateModel) { return state.account_activity_error; }

    @Selector()
    static getAccountActivities(state: CustomerInfoStateModel) {
        return state.account_activities?.filter(item => item?.doc_status !== "SUPPRESSED");
    }

    @Selector()
    static getAccountActivitiesWithRefNumbers(state: CustomerInfoStateModel) {
        return state.account_activities?.filter((a) => a?.refund_payment_id);
    }

    @Selector([CustomerInfoState.getAccountActivities])
    static getRecentPaymentDate(accountActivities: AccountActivity[]) {
        return accountActivities?.filter((a) => a?.doc_type === 'I' && a?.doc_status === 'PAID')[0];
    }
    @Selector([CustomerInfoState.getCustomer])
    static getCustomerEmail(customer: Customer) { return customer?.email; }

    @Selector()
    static getMostRecentSearchParam(state: CustomerInfoStateModel) { return state.most_recent_search_param; }

    @Selector([CustomerInfoState.getSmartsubData])
    static getAccountStatus(smartsubData: SmartSubData): AccountStatus {
        return smartsubData?.account_status;
    }

    @Selector([CustomerInfoState.getAccountStatus])
    static isTwoInvoicesBehind(accountStatus: AccountStatus): boolean {
      const { amountArrears, amountCurrent } = accountStatus ?? {}
      return amountArrears >= (amountCurrent * 2);
    }

    @Selector([CustomerInfoState.getSmartsubData])
    static getAccountState(smartsubData: SmartSubData) {
        return smartsubData?.account_status?.accountState;
    }

    static hasFlag(accountStatusFlag: AccountStatusFlag) {
        return createSelector(
            [CustomerInfoState],
            (state: CustomerInfoStateModel) => {
                return state.smartsubData?.account_status?.flag === accountStatusFlag;
            }
        );
    }

    @Selector([CustomerInfoState.getCustomer])
    static startOfBillCycleDate(customer: Customer) {
        const { bill_cycle_spec_detail } = customer ?? {};
        return new Monad.Maybe(bill_cycle_spec_detail?.cycle_period)
            .bind(Utils.Formatters.getBillCycleDates)
            .bind(val => WalletFunctions.formatDate(val?.start.add(1, "month")))
            .value;
    }

    @Selector([CustomerInfoState.getCustomerEmail])
    static isTestAccount(email: string): boolean {
      return Utils.Regex.isTestAccount(email)
    }

    constructor(
        private store: Store,
        private _svc: CustomerDataService,
        private _phSVC: PaymentHistoryService
    ) { }

    /* ==========================================  ACTIONS  ========================================== */

    @Action(fromCustomerActions.ReloadCustomerDetails)
    reloadCustomerDetails(ctx: StateContext<CustomerInfoStateModel>, action: fromCustomerActions.ReloadCustomerDetails) {
        const lastQuery = this.store.selectSnapshot(CustomerInfoState.getMostRecentSearchParam);
        if (!lastQuery) return;

        const hard_refresh = action.hard_refresh;

        ctx.patchState({
            silent_loading: !hard_refresh,
        });

        const payload = {
            value: lastQuery,
            config: {
                loader: hard_refresh,
            },
        };
        if (hard_refresh) {
            ctx.dispatch(new fromCustomerActions.ResetCustomerDetails());
        }
        ctx.dispatch(new fromCustomerActions.FetchCustomerDetails(payload));
    }

    @Action(fromCustomerActions.FetchCustomerDetails)
    fetchCustomerDetails(ctx: StateContext<CustomerInfoStateModel>, action: fromCustomerActions.FetchCustomerDetails) {
        const payload = action.payload;

        ctx.patchState({
            most_recent_search_param: payload.value,
        });

        if (payload.config.loader) {
            ctx.patchState({
                loaded: false,
                loading: true,
            });
        }

        return this._svc.getSmartsubData(payload.value)
            .pipe(
                tap({
                    next: res => {
                        if (!res) {
                            return ctx.dispatch(new fromCustomerActions.FetchCustomerDetailsFail(NO_DATA_MESSAGE));
                        }

                        return ctx.dispatch(new fromCustomerActions.FetchCustomerDetailsSuccess(res, payload.config?.autoNavigate));
                    },
                    error: (e: unknown) => ctx.dispatch(new fromCustomerActions.FetchCustomerDetailsFail((e as { message: string })?.message)),
                }));
    }

    @Action([fromCustomerActions.ResetCustomerDetails, SalesPortalDestroyed])
    resetCustomerDetails(ctx: StateContext<CustomerInfoStateModel>) {

        ctx.patchState({
            loaded: false,
            loading: false,
            silent_loading: false,
            smartsubData: null,
            most_recent_search_param: null,
            errorMessage: null,
            account_activities: null,
            account_activity_error: null,
            max_order_days: MAX_ORDER_DAYS,
            max_cancelled_service_days: MAX_CANCELLED_SERVICE_DAYS
        });
    }

    @Action(fromCustomerActions.FetchCustomerDetailsSuccess)
    fetchCustomerDetailsSuccess(ctx: StateContext<CustomerInfoStateModel>, action: fromCustomerActions.FetchCustomerDetailsSuccess) {
        const payload = action.payload;
        const { user } = payload ?? {};
        const routerState = this.store.selectSnapshot(RouterState.state);
        const currentRoute = routerState?.url ?? window.location.pathname;

        ctx.patchState({
            smartsubData: payload,
            loaded: true,
            loading: false,
            silent_loading: false,
        });

        const { email, id } = user ?? {};
        const actions: Dictionary[] = [
            new fromCustomerActions.FetchCustomerAccountActivity(id),
            new NewProxyServiceActions.Fetch(id),
            new GeforceEligibilityActions.FetchEligibility({ email })
        ];

        //TODO: only add navigate on auto navigate
        if (
            action.autoNavigate
            && !currentRoute.includes(CUSTOMER_INFO_SUMMARY_ROUTE)
            && !currentRoute.includes('tickets')
            && !currentRoute.includes(SALES_PORTAL_ROUTE)
        ) {
            actions.push(new Navigate([`${CUSTOMER_INFO_SUMMARY_ROUTE}/${email}`], [], {queryParamsHandling: 'merge'}));
        }
        return ctx.dispatch(actions);
    }

    @Action(fromCustomerActions.SetCustomerDetails)
    SetCustomerDetails(ctx: StateContext<CustomerInfoStateModel>, action: fromCustomerActions.SetCustomerDetails) {
        const { payload } = action ?? {};

        ctx.patchState({
            most_recent_search_param: payload?.user?.email
        });

        return ctx.dispatch(new fromCustomerActions.FetchCustomerDetailsSuccess(payload, false));
    }


    @Action(fromCustomerActions.SetCustomerDetailsSearchParam)
    setCustomerDetailsSearchParam(ctx: StateContext<CustomerInfoStateModel>, action: fromCustomerActions.SetCustomerDetailsSearchParam) {
        const { searchParam } = action ?? {};

        ctx.patchState({
            most_recent_search_param: searchParam,
        });
    }


    @Action(fromCustomerActions.FetchCustomerDetailsFail)
    fetchCustomerDetailsSFail(ctx: StateContext<CustomerInfoStateModel>, action: fromCustomerActions.FetchCustomerDetailsFail) {
        const payload = action.payload;
        ctx.patchState({
            loaded: true,
            loading: false,
            silent_loading: false,
            smartsubData: null,
            account_activities: null,
            account_activity_error: null,
            errorMessage: payload,
        });
    }

    @Action(fromCustomerActions.FetchCustomerAccountActivity)
    fetchCustomerAccountActivity(ctx: StateContext<CustomerInfoStateModel>, action: fromCustomerActions.FetchCustomerAccountActivity) {
        const state = ctx.getState();
        const payload = action.payload || state.smartsubData.user.id;

        return this._phSVC.getAccountActivityV2(payload)
            .pipe(tap({
                next: (resp) => {
                    if (!resp?.data
                        || SFValidators.hasProperty(resp?.data, "error")
                        || SFValidators.hasProperty(resp, "error")
                    ) {
                        const errorMessage = resp?.errorMessage ? resp?.errorMessage : 'No account activity data was found.';
                        return ctx.dispatch(new fromCustomerActions.FetchCustomerAccountActivityFail(errorMessage));
                    }

                    return ctx.dispatch(
                        new fromCustomerActions.FetchCustomerAccountActivitySuccess(resp.data)
                    );
                },
                error: (e: unknown) => ctx.dispatch(new fromCustomerActions.FetchCustomerAccountActivityFail(e)),
            }));
    }

    @Action(fromCustomerActions.FetchCustomerAccountActivitySuccess)
    fetchCustomerAccountActivitySuccess(ctx: StateContext<CustomerInfoStateModel>, action: fromCustomerActions.FetchCustomerAccountActivitySuccess) {
        const payload = action.payload;

        ctx.patchState({
            account_activities: payload,
        });
    }

    @Action(fromCustomerActions.FetchCustomerAccountActivityFail)
    fetchCustomerAccountActivitySFail(ctx: StateContext<CustomerInfoStateModel>, action: fromCustomerActions.FetchCustomerAccountActivityFail) {
        const payload = action.payload;
        ctx.patchState({
            loaded: true,
            loading: false,
            account_activities: null,
            account_activity_error: payload,
        });
    }

    //TODO: Find and fix the reason this hack is needed
    @Action(fromCustomerActions.FetchDifferentCustomerDetails)
    fetchDifferentCustomerDetails(ctx: StateContext<CustomerInfoStateModel>, action: fromCustomerActions.FetchDifferentCustomerDetails) {
        const { searchParam, queryParams, navigationExtras } = action;

        ctx.dispatch([
            new fromCustomerActions.ResetCustomerDetails(),
            new Navigate([HOME_ROUTE]),
        ]);

        setTimeout(() => ctx.dispatch(
            new Navigate([`${CUSTOMER_INFO_SUMMARY_ROUTE}/${searchParam}`], queryParams, navigationExtras)
        ), 25
        );
    }


    @Action(fromCustomerActions.SetMaxCancelledServiceDaysShown)
    setMaxCancelledServiceDaysShown(ctx: StateContext<CustomerInfoStateModel>, action: fromCustomerActions.SetMaxCancelledServiceDaysShown) {
        ctx.patchState({ max_cancelled_service_days: action.maxDays });
    }

    @Action(fromCustomerActions.SetMaxOrderDaysShown)
    setMaxOrderDaysShown(ctx: StateContext<CustomerInfoStateModel>, action: fromCustomerActions.SetMaxOrderDaysShown) {
        ctx.patchState({ max_order_days: action.maxDays });
    }
}
