import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RainInputComponent } from './elements/rain-input/rain-input.component';
import { FormGroupDirective, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ErrorKeysPipe } from './pipes';
import { FormAddressSearchComponent } from './elements/snowflake-address-search/address-search.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SFCheckboxComponent } from './elements/rain-checkbox/snowflake-checkbox.component';
import { FormTextareaComponent } from './elements/form-textarea/form-textarea.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { SnowflakeSelectComponent } from './elements/snowflake-select/snowflake-select.component';
import { SnowflakeDateRangeComponent } from './elements/snowflake-daterange/snowflake-date-range.component';
import { MatNativeDateModule, MAT_DATE_FORMATS } from '@angular/material/core';
import { CUSTOM_DATE_FORMATS, MY_MOMENT_FORMATS } from './formatters/custom-date.format';
import { SnowflakeAddressInputsComponent } from './elements/snowflake-address-inputs/snowflake-address-inputs.component';
import { SnowflakeDatePickerComponent } from './elements/snowflake-date-picker/snowflake-date-picker.component';
import { OwlDateTimeModule, OWL_DATE_TIME_FORMATS } from '@danielmoncada/angular-datetime-picker';
import { OwlMomentDateTimeModule } from '@danielmoncada/angular-datetime-picker-moment-adapter';
import { MultiLevelSelectComponent } from './multi-level-select/multi-level-select.component';
import { SelectSpanComponent } from './multi-level-select/select-span/select-span.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { YesNoDropdownSelectComponent } from './elements/yes-no-dropdown-select/yes-no-dropdown-select.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        FontAwesomeModule,
        MatFormFieldModule,
        MatInputModule,
        MatTooltipModule,
        MatDatepickerModule,
        MatNativeDateModule,
        OwlDateTimeModule,
        OwlMomentDateTimeModule,
    ],
    declarations: [
        RainInputComponent,
        ErrorKeysPipe,
        FormAddressSearchComponent,
        SFCheckboxComponent,
        FormTextareaComponent,
        SnowflakeSelectComponent,
        YesNoDropdownSelectComponent,
        SnowflakeAddressInputsComponent,
        SnowflakeDateRangeComponent,
        SnowflakeDatePickerComponent,
        MultiLevelSelectComponent,
        SelectSpanComponent
    ],
    providers: [
        FormGroupDirective,
        { provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS },
        { provide: OWL_DATE_TIME_FORMATS, useValue: MY_MOMENT_FORMATS },
    ],
    exports: [
        RainInputComponent,
        ErrorKeysPipe,
        FormAddressSearchComponent,
        SFCheckboxComponent,
        FormTextareaComponent,
        SnowflakeSelectComponent,
        YesNoDropdownSelectComponent,
        SnowflakeDateRangeComponent,
        SnowflakeAddressInputsComponent,
        SnowflakeDatePickerComponent,
        MultiLevelSelectComponent,
        SelectSpanComponent
    ]
})
export class RainFormsModule { }

