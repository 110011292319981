import { SFValidators } from "../sf-validators";


export function pad(value: number | string, len: number): string {
    if (SFValidators.isNotDefined(value)) {
        return;
    }

    const valueLen = value?.toString().length;
    if (valueLen >= len) {
        return value.toString();
    }
    const padding = len - valueLen;
    const padString = [...Array(padding)].map(() => "0").join("");

    return `${padString}${value}`;
}


export const convertToNumElseNull = (value: string | number) => {
    if (SFValidators.isDefined(value) && !isNaN(+value)) {
        return +value;
    }
    return null;
}