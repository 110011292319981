import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { TicketStateDict } from 'src/app/features/pages/ticket/dictionaries/ticket-state.dictionary';
import { ShortTicket } from 'src/app/shared/customer-ticket/interfaces/customer-ticket.interface';
import { InteractionTicketsState } from '../store/state/interaction-tickets.state';
import { CoreState } from 'src/app/core/store/state/core.state';

@Component({
  selector: 'app-customer-ticket-interaction',
  templateUrl: './customer-ticket-interaction.component.html',
  styleUrls: ['./customer-ticket-interaction.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomerTicketInteractionComponent implements OnInit {

  @Input() hexId: string;

  shortTicket$: Observable<ShortTicket>;
  active$: Observable<boolean>;
  isOpenTicketForGroup$: Observable<boolean>;

  constructor(private store: Store) {

  }
  ngOnInit(): void {
    this.shortTicket$ = this.store.select(InteractionTicketsState.getTicketByHexId(this.hexId));
    this.active$ = this.store.select(InteractionTicketsState.getAssignedHexId)
      .pipe(
        switchMap(hexId => of(hexId === this.hexId))
      );

    this.isOpenTicketForGroup$ = this.store.select(CoreState.getOpenTicketsForGroup)
      .pipe(
        switchMap(tickets =>
          of(Boolean(tickets?.find(t => t.hex_id === this.hexId)))
        )
      );
  }

  public getTicketStatus(statusId: number) {
    return TicketStateDict[statusId]?.state;
  }
}



