import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AgentNotificationsComponent } from './agent-notifications.component';
import { CallbackComponentModule } from 'src/app/shared/components/callback/components/callback-component.module';
import { NgxsModule } from '@ngxs/store';
import { AgentNotificationState } from './store/state/agent-notifications.state';
import { AgentNotificationComponent } from './components/agent-notification/agent-notification.component';
import { MatBadgeModule } from '@angular/material/badge';
import { SharedModule } from 'src/app/shared/shared.module';
import { AgentNotificationWSState } from './store/state/agent-notification-ws.state';

const declarations = [
    AgentNotificationsComponent,
    AgentNotificationComponent
];

@NgModule({
    declarations: [
        ...declarations
    ],
    imports: [
        CommonModule,
        CallbackComponentModule,
        SharedModule,
        MatBadgeModule,
        NgxsModule.forFeature([
            AgentNotificationState,
            AgentNotificationWSState
        ])
    ],
    exports: [
        ...declarations
    ],
})
export class AgentNotificationsModule { }