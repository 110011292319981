

export function createTimestampReference(value: string) {
  const timeStamp = new Date().toISOString();
  return `${value}-${timeStamp}`;
}

export const createRandomPassword = (length: number): string => {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return result;
}
