import { Component, HostBinding } from "@angular/core";

/**
 * A default button class that should be added to all custom icon buttons
 * to ensure that the disabled property actually works.
 */
@Component({
  template: ''
})
export abstract class ButtonHelper {

  disabled = false;

  /**
   * This is required to actually disable the button
   */
  @HostBinding('style.pointer-events')
  get pEvents(): string {
    return this.disabled ? "none" : "auto";
  }

}
