import { Component, Input } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';

@Component({
  selector: 'app-rain-card',
  templateUrl: './rain-card.component.html',
  styleUrls: ['./rain-card.component.scss'],
  animations: [
    trigger('flipState', [
      state('active', style({
        transform: 'rotateY(179deg)'
      })),
      state('inactive', style({
        transform: 'rotateY(0)'
      })),
      transition('active => inactive', animate('500ms ease-out')),
      transition('inactive => active', animate('500ms ease-in'))
    ])
  ]
})
export class RainCardComponent {

  flip = 'inactive';
  @Input() clickable = false;
  @Input() frontColor = "white";
  @Input() backColor = "rgb(0,58,200)";
  @Input() flipable = false;
  @Input() width = "100%";
  @Input() height = "100%";

  @Input() showDogEar = false;
  @Input() dogEarMessage: string;
  @Input() padding: string;

  @Input() showSimAssigned = false;
  @Input() simAssignedText: string;

  toggleFlip() {
    if (!this.flipable) return;
    this.flip = (this.flip == 'inactive') ? 'active' : 'inactive';
  }

  //use this method if you don't want the default flip behaviour
  setFlip(active: boolean) {
    this.flip = active ? "active" : "inactive";
  }

}
