import { Component } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { SFNotification } from '../interfaces/notifiactions.interface';
import { NotificationsState } from '../../store/state/notifications.state';

@Component({
  selector: 'sf-notifications-lockup',
  templateUrl: './notifications-lockup.component.html',
  styleUrls: ['./notifications-lockup.component.scss']
})
export class NotificationsLockupComponent {
  @Select(NotificationsState.getAllNotifactions) allNotifications$: Observable<SFNotification[]>;

}
