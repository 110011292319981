import { Component, Input } from '@angular/core';
import { ButtonHelper } from 'src/app/Utils/helpers/button.helper';

@Component({
  selector: 'sf-square-icon-button',
  templateUrl: './square-icon-button.component.html',
  styleUrls: ['./square-icon-button.component.scss']
})
export class SquareIconButtonComponent extends ButtonHelper {

  @Input() size = "2.3rem";
  @Input() backgroundColor: string;
  @Input() disabled = false;

  @Input() iconColor: string;

}
