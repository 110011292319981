

import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, createSelector, } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { AllTicketsService } from 'src/app/features/pages/ticket/services/all-tickets.service';
import { CustomerTicketHelper } from 'src/app/shared/customer-ticket/customer-ticket-helper';
import { CustomerTicket, TicketNote } from 'src/app/shared/customer-ticket/interfaces/customer-ticket.interface';
import { TICKET_STATES } from 'src/app/shared/customer-ticket/ticket-event-handler/assets/ticket.constants';
import { DataHandler } from 'src/app/shared/data-handler/data-handler';
import { DataLoading, getDataLoadingDefaultValues } from 'src/app/shared/interfaces/data-loading.interface';
import { Utils } from 'src/app/Utils';
import { DataLoadingHelper } from 'src/app/Utils/helpers';
import { ChatTicketActions } from '../actions/chat-ticket-actions';
import { InteractionTicketActions } from '../actions/interaction-ticket-actions';


type ChatTicketStateModel = DataLoading<CustomerTicket>
@State<ChatTicketStateModel>({
    name: 'sf_chat_ticket_state',
    defaults: getDataLoadingDefaultValues()
})
@Injectable()
export class ChatTicketState {

    @Selector()
    static isLoading(state: ChatTicketStateModel) { return state.loading }

    @Selector()
    static isLoaded(state: ChatTicketStateModel) { return state.loaded }

    @Selector()
    static getData(state: ChatTicketStateModel) { return state.data }

    @Selector()
    static getTicketHelper(state: ChatTicketStateModel) {
        const ticket = DataHandler.createDeepCopy(state.data);
        return new CustomerTicketHelper(ticket);
    }

    static getAllTicketEventsForAgent(agentEmail: string) {

        return createSelector(
            [ChatTicketState.getTicketHelper],
            (ticketHelper: CustomerTicketHelper): TicketNote[] => {

                const { chatDataList } = ticketHelper?.noteHelper ?? {};
                if (!chatDataList?.length) {
                    return [];
                }

                return chatDataList
                    .filter(chatData => {
                        const { chatType, data } = chatData ?? {};
                        const noteEmail = (<TicketNote>data)?.related_entity?.email?.toLowerCase();
                        return chatType === "event" && noteEmail === agentEmail?.toLowerCase();

                    })
                    .map(chatData => chatData.data);
            }
        );

    }

    @Selector()
    static isTicketClosed(state: ChatTicketStateModel) {
        return state.data?.state_id === TICKET_STATES.CLOSED;
    }

    @Selector()
    static getError(state: ChatTicketStateModel) { return state.error }

    constructor(private allTicketsService: AllTicketsService) {
    }

    @Action(ChatTicketActions.FetchTicket)
    FetchTicket(ctx: StateContext<ChatTicketStateModel>, action: ChatTicketActions.FetchTicket) {
        const { hexId } = action;
        if (!hexId) {
            return ctx.dispatch(new ChatTicketActions.FetchTicketFail("Failed to fetch ticket. Hex ID is missing."));
        }

        ctx.patchState({
            loading: true
        });

        return this.allTicketsService.getTicketByHexId(hexId)
            .pipe(tap({
                next: res => {
                    if (res?.data) {
                        return ctx.dispatch(new ChatTicketActions.FetchTicketSuccess(res));
                    }
                    else {
                        return ctx.dispatch(new ChatTicketActions.FetchTicketFail())
                    }
                },
                error: (e: unknown) => ctx.dispatch(new ChatTicketActions.FetchTicketFail(e))
            }));
    }


    @Action(ChatTicketActions.FetchTicketSuccess)
    FetchTicketSuccess(ctx: StateContext<ChatTicketStateModel>, action: ChatTicketActions.FetchTicketSuccess) {
        const { data } = action.payload;
        DataLoadingHelper.setData(ctx, data);
    }

    @Action(ChatTicketActions.FetchTicketFail)
    FetchTicketFail(ctx: StateContext<ChatTicketStateModel>, action: ChatTicketActions.FetchTicketFail) {
        const error = Utils.Helpers.findError(action.error, '');
        DataLoadingHelper.setError(ctx, error);
    }

    @Action(ChatTicketActions.SetTicket)
    SetTicket(ctx: StateContext<ChatTicketStateModel>, action: ChatTicketActions.SetTicket) {
        ctx.patchState({ data: action.payload });
    }

    @Action(ChatTicketActions.Clear)
    Clear(ctx: StateContext<ChatTicketStateModel>) {
        ctx.setState(getDataLoadingDefaultValues());
        return ctx.dispatch(new InteractionTicketActions.SetSelectedHexId(null));
    }

}   