import { SFValidators } from "src/app/shared/functions/sf-validators";

export function isTestAccount(email: string): boolean {

  if (SFValidators.isNotDefined(email)) {
    return
  }

  const testAccounts = [
    'quintons',
    'seshnit',
    'gregh',
    'hlonid',
    'muhammadm',
    'kerryc',
    'luyanda',
    'george',
    'noluth',
    'qschorrain',
    'gregtest1223',
    'motestingacc001',
    'seshrain',
    'seshnirain',
    'prodkgosirain',
    'testhlonirain',
    'abdulrain',
    'kerryraintest',
    'kagisoprod',
    'rain.test.abdul.malik',
    'abdul.test.rain'
  ].join('|');

  const domains = ['\\@gmail.com', '\\@test.rain.co.za'].join('|');

  const regex = new RegExp(`^(${testAccounts})\\+\\d+(${domains})$`);

  return regex.test(email)

}
