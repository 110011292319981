import { KeyValue } from "@angular/common";
import { HashMap } from "src/app/shared/interfaces/hash-map.interface";
import { StringKey } from "src/app/shared/types/string-of.type";


export function mapKeyValue<T extends HashMap<V>, V = any>
    (keyValueList: KeyValue<StringKey<T>, V>[]): T | null {

    if (!keyValueList?.length) {
        return null;
    }
    const map: HashMap<V> = {};

    keyValueList.forEach(item => {
        const key = item?.key;
        if (key) {
            map[key] = item?.value
        }
    });
    return map as T;
}
