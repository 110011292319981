import { PopupError } from "src/app/core/handlers/popup-error";
import { SFValidators } from "src/app/shared/functions/sf-validators";
import { WhatsappIdentifier } from "../types/whatsapp-send-message-payload.interface";


export const validateWhatsappIdentifier = (identifier: WhatsappIdentifier, displayError = false) => {
    const hasNonNullValue = Object
        .values(identifier ?? {})
        .filter(v => SFValidators.isDefined(v))?.length > 0;

    if (hasNonNullValue) {
        return identifier;
    }

    const message = "Could not load whatsapp messages. No customer identifier was found.";
    if (displayError) {
        throw new PopupError(message);
    }

    throw new Error(message);
}