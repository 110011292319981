import { NextObserver, Observable, Subject, merge, timer } from "rxjs";
import { WebSocketSubject, WebSocketSubjectConfig, webSocket } from "rxjs/webSocket";
import { take, takeUntil } from "rxjs/operators";
import { SetTimeout } from "../types/set-timeout.type";
import { ISuccessResult } from "../models/success-result.model";


const STARTING_ATTEMPT = 0;
const MAX_ATTEMPTS = 3;
const SCALING_DURATION = 1000;

interface ReConnectOptions {
    maxRetryAttempts?: number;
    scalingDuration?: number;
}

export class ReConnectableWebSocket<T> {

    private _webSocketSubject$: WebSocketSubject<T>;

    private _wsConfig: WebSocketSubjectConfig<T>;
    private _reconnectOptions: ReConnectOptions;

    private _currentAttempt = STARTING_ATTEMPT;
    private _timeoutId: SetTimeout = null;

    private readonly _messageResult$ = new Subject<ISuccessResult<T>>();

    private readonly _closed$ = new Subject<void>();
    private readonly _destroy$ = new Subject<void>();

    constructor(
        wsConfig: WebSocketSubjectConfig<T>,
        { maxRetryAttempts = MAX_ATTEMPTS, scalingDuration = SCALING_DURATION }: ReConnectOptions = {}
    ) {

        this._wsConfig = this.createWSConfig(wsConfig);

        this._reconnectOptions = {
            maxRetryAttempts,
            scalingDuration
        };
    }

    private createWSConfig(wsConfig: WebSocketSubjectConfig<T>) {
        const { openObserver, closeObserver } = wsConfig;

        wsConfig.openObserver = this.getStatusUpdateObserver(openObserver, () => this.onConnected());

        wsConfig.closeObserver = this.getStatusUpdateObserver(closeObserver, () => this.onClosed());

        return wsConfig;
    }

    private onConnected() {
        this._currentAttempt = STARTING_ATTEMPT;
        this.clearOnClosedTimeout();
    }

    private clearOnClosedTimeout() {
        if (this._timeoutId !== null) {

            clearTimeout(this._timeoutId);
            this._timeoutId = null;
        }
    }

    private onClosed() {
        const { scalingDuration, maxRetryAttempts } = this._reconnectOptions;

        this._currentAttempt++;
        this._closed$.next(null);

        const delayAmount = this._currentAttempt * scalingDuration;
        const canRetry = this._currentAttempt < maxRetryAttempts;

        if (!canRetry) {
            return;
        }

        this._timeoutId = setTimeout(() => this.connect(), delayAmount);
    }

    private getStatusUpdateObserver<E extends Event>(observer: NextObserver<E>, callback: (value: E) => void): NextObserver<E> {
        if (observer) {
            const inputNextFn = observer.next;

            return {
                ...observer,
                next: event => {
                    callback(event);
                    inputNextFn(event);
                }
            }
        }
        else {
            return {
                next: (event) => callback(event)
            }
        }
    }

    connect(): ReConnectableWebSocket<T> {
        //Clear any current connections
        this._destroy$.next();

        this._webSocketSubject$ = webSocket(this._wsConfig);

        this._webSocketSubject$
            .pipe(
                takeUntil(this._destroy$.pipe(take(1)))
            )
            .subscribe({
                next: message => {
                    this._messageResult$.next({
                        data: message,
                        error: null
                    });
                },
                error: (e: unknown) => {
                    this._messageResult$.next({
                        data: null,
                        error: e
                    });
                }
            });

        return this;
    }

    reconnect(): ReConnectableWebSocket<T> {
        this._currentAttempt = 0;
        return this.connect();
    }

    send(value: T): ReConnectableWebSocket<T> {
        if (this._webSocketSubject$) {
            this._webSocketSubject$.next(value);
        }
        return this;
    }

    keepAlive(interval: number, message: T): ReConnectableWebSocket<T> {

        timer(interval, interval)
            .pipe(
                takeUntil(
                    merge(this._closed$, this._destroy$)
                        .pipe(take(1))
                )
            )
            .subscribe({
                next: () => this.send(message)
            });

        return this;
    }

    /**
     * Listen to any messages from the websocket connection, this will not throw an error
     */
    listen(): Observable<ISuccessResult<T>> {
        return this._messageResult$.asObservable();
    }

    close(): void {
        this.clearOnClosedTimeout();

        this._destroy$.next(null);

        [
            this._destroy$,
            this._messageResult$,
            this._closed$
        ]
            .forEach(subject => subject.complete());
    }

}