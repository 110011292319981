import { Utils } from "src/app/Utils";
import { PBXauthResponse } from "../../services/voice-agents/assets/pbx-auth-response.interface";
import { PBXCredentials } from "../interfaces/pbx-credentials.interface";

export class AuthenticatePBXvoice {
  static readonly type = Utils.Helpers.Type('[Voice: Auth] authenticate with the PBX platform for voice');
  constructor(public credentials: PBXCredentials) { }
}


export class AuthenticatePBXvoiceSuccess {
  static readonly type = Utils.Helpers.Type('[Voice: Auth] authenticate with the PBX platform for voice success');
  constructor(public payload: PBXauthResponse, public credentials: PBXCredentials) { }
}


export class AuthenticatePBXvoiceFail {
  static readonly type = Utils.Helpers.Type('[Voice: Auth] authenticate with the PBX platform for voice failed');
  constructor(public error: any) { }
}

export class RefreshPBXToken {
  static readonly type = Utils.Helpers.Type('[Voice: Auth] refresh the PBX access token');
  constructor(public credentials: PBXCredentials) { }
}



