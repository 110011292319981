import { Injectable } from '@angular/core';
import { AgentNotificationWebSocketService } from '../toolbar/agent-notifications/store/services/agent-notification-websocket.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AgentWebSocketTopic } from '../toolbar/agent-notifications/store/types/agent-websocket-topic.type';
import { Store } from '@ngxs/store';
import { ForceAvailabilityData } from '../features/pages/admin/pages/voice-queues-page/store/interfaces/force-availability-data.interface';
import { WebSocketNotification } from '../toolbar/agent-notifications/store/types/websocket-notification.interface';
import { CoreState } from '../core/store/state/core.state';
import { SipPhoneService } from '../sip-phone/services/sip-phone.service';
import { Router } from '@angular/router';
import { AGENT_VIEW_ROUTE, CUSTOMER_INFO_SUMMARY_ROUTE, SALES_PORTAL_ROUTE } from '../constants';
import { Navigate } from '@ngxs/router-plugin';
import { Dictionary } from '../shared/interfaces/dictionary.interface';
import { SetAcceptingCalls, SetAcceptingTickets } from '../shared/components/agent/store/actions/agent.actions';
import { SetAvailable } from '../shared/components/agent/store/actions/agent-status-actions/agent-status.actions';
import { AgentState } from '../shared/components/agent/store/state/agent.state';

@Injectable({
    providedIn: 'root'
})
export class AgentControlService {

    constructor(
        private agentNotificationWSService: AgentNotificationWebSocketService,
        private sipPhoneService: SipPhoneService,
        private router: Router,
        private store: Store
      ) { }

    listenToForceAvailableMessage(destroy$: Subject<unknown>) {
        const isAdmin = this.store.selectSnapshot(CoreState.hasOneOfTheseRoles([
            "AUTH.USER.ADMIN",
            "AUTH.USER.MANAGEMENT",
            "CEC.WIPROGEN.A"
        ]));

        if (isAdmin) {
            return;
        }

        const topic: AgentWebSocketTopic = "force_available";

        this.agentNotificationWSService.subscribeToTopic<ForceAvailabilityData>(topic, destroy$)
            .pipe(
                takeUntil(destroy$)
            )
            .subscribe({
                next: notification => {
                    const shouldHandle = this.shouldHandleForceAvailable(notification);
                    if (shouldHandle) {
                        this.handleForceAvailableMessage(notification);
                    }
                }
            });
    }

    private shouldHandleForceAvailable(notification: WebSocketNotification<ForceAvailabilityData>): boolean {

        const { groupOrEmail, senderEmail } = notification?.data ?? {}

        const hasActiveSession = this.sipPhoneService.hasActiveSession$.value;

        const busyWithTaskRoutes = [SALES_PORTAL_ROUTE, CUSTOMER_INFO_SUMMARY_ROUTE];
        const busyWithTask = busyWithTaskRoutes.some(route => this.router.url.includes(route));

        const groups = this.store.selectSnapshot(CoreState.getAgentRoles) ?? [];
        const agentEmail = this.store.selectSnapshot(CoreState.getAgentEmail)

        const agentStatus = this.store.selectSnapshot(AgentState.getStatus)
        const agentBusy = agentStatus === 'busy'
        const agentLoggedOff = agentStatus === 'logged off'
        const agentOnBreak = agentStatus === 'break'
        const agentNotAvailable = agentBusy || agentLoggedOff || agentOnBreak

        const inOneOfGroups = groups.some(group => groupOrEmail?.includes(group))
        const isAgentToForce = agentEmail === groupOrEmail
        const isSenderEmail = agentEmail === senderEmail

        const force = (inOneOfGroups || isAgentToForce) && !isSenderEmail

        const ignoreConditions = [
          !force,
          busyWithTask,
          hasActiveSession,
          agentNotAvailable,
        ];

        return !ignoreConditions.some(Boolean);
    }

    private handleForceAvailableMessage(notification: WebSocketNotification<ForceAvailabilityData>) {

        const { forceAcceptCalls, forceAcceptTickets } = notification?.data ?? {};

        const actions: Dictionary[] = [
            new Navigate([AGENT_VIEW_ROUTE]),
            new SetAvailable()
        ];

        if (forceAcceptCalls) {
            actions.push(new SetAcceptingCalls(true));
        }

        if (forceAcceptTickets) {
            actions.push(new SetAcceptingTickets(true));
        }

        this.store.dispatch(actions);
    }

}
