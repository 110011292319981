import { SuccessResponse } from "src/app/shared/success-response-handler/success-response.model";
import { Utils } from "src/app/Utils";
import { SFNotification } from "../../notifiactions/interfaces/notifiactions.interface";

export class AddNotification {
  static readonly type = Utils.Helpers.Type('[Core: Notification] Add new notification');
  constructor(public payload: SFNotification) { }
}

export class AddSuccessResponseNotification {
  static readonly type = Utils.Helpers.Type('[Core: Notification] Add new notification with SuccesssResponse as input');
  constructor(public successResponse: SuccessResponse, public isWarning = false) { }
}

export class AddInfoNotification {
  static readonly type = Utils.Helpers.Type('[Core: Notification] Add new default info notification');
  constructor(public message: string, public title = "INFO") { }
}

export class RemoveNotification {
  static readonly type = Utils.Helpers.Type('[Core: Notification] Remove notification');
  constructor(public payload: SFNotification) { }
}
