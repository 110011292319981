import { Injectable, NgZone } from '@angular/core';
import { fromEvent, merge, Observable } from 'rxjs';
import { throttleTime } from 'rxjs/operators';

const FIVE_SECONDS = 5000;

@Injectable({
    providedIn: 'root'
})
export class UserActionsService {

    private _mainActions$: Observable<Event>;


    constructor(private zone: NgZone) {
        this.createUserActionsStream();
    }


    getMainActions(defaultThrottle = true): Observable<Event> {
        return defaultThrottle
            ? this._mainActions$.pipe(throttleTime(FIVE_SECONDS))
            : this._mainActions$;
    }


    private createUserActionsStream() {
        this.zone.runOutsideAngular(() => {
            const windowClick$ = fromEvent(window, "click");
            const mousemove$ = fromEvent(window, "mousemove");
            const keydown$ = fromEvent(window, "keydown");

            this._mainActions$ = merge(windowClick$, mousemove$, keydown$);
        });
    }

}