import * as fromHelpers from './helpers';
import * as fromMappers from './mappers';
import * as fromFormatters from './formatters';
import * as fromSfHelpers from './sf-helpers';
import * as fromRegex from './regex';
import * as fromFunctional from './functional';
import * as fromFilters from './filters';
import * as fromRxjs from "./rxjs";

export const Utils = {
    Formatters: { ...fromFormatters },
    Helpers: { ...fromHelpers },
    SfHelpers: { ...fromSfHelpers },
    Mappers: { ...fromMappers },
    Regex: { ...fromRegex },
    Functional: { ...fromFunctional },
    Filters: { ...fromFilters },
    Rxjs: { ...fromRxjs }
};

