import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngxs/store';
import { ToggleModalByName } from 'src/app/core/store/actions/modal.actions';
import { SnowflakeSelectOption } from 'src/app/shared/elements/rain-forms/elements/snowflake-select/interfaces/rain-select-option.interface';
import { createSFselectOptions } from 'src/app/shared/functions/create-sf-select-options.function';
import { PostTicketModalOptions } from 'src/app/shared/services/post-ticket/post-ticket-modal-options.model';
import { PostTicketService } from 'src/app/shared/services/post-ticket/post-ticket.service';
import { FormHelper } from 'src/app/Utils/helpers';
import { InteractionTicketsState } from '../../store/state/interaction-tickets.state';
import { ChatJsonNote } from '../components/chat-json-note/assets/chat-json-note.interface';


@Component({
    selector: 'sf-rica-post-close-modal',
    templateUrl: './rica-post-close-modal.component.html',
    styleUrls: ['./rica-post-close-modal.component.scss']
})
export class RicaPostCloseModalComponent extends FormHelper implements OnInit, OnDestroy {

    form: FormGroup;
    modalOptions: PostTicketModalOptions;

    selectOptions: { [key: string]: SnowflakeSelectOption[] } = {
        contacted: createSFselectOptions([
            "Yes - Successful - Email",
            "Yes - Successful - Call",
            "No - Unsuccessful - Email",
            "No - Unsuccessful - Call"
        ]),
        outcome: createSFselectOptions([
            "Requirements received - Non-compliant",
            "Requirements received - Compliant",
            "Requirements received - Courier - Compliant",
            "Call Back"
        ]),
        escalation_required: createSFselectOptions([
            "None",
            "Advanced",
            "Billing"
        ]),
    }

    contactedOptions: SnowflakeSelectOption[] = [];

    constructor(private fb: FormBuilder,
        private store: Store,
        private postTicketService: PostTicketService,) {
        super();
    }

    ngOnInit(): void {
        this.modalOptions = new PostTicketModalOptions();

        this.form = this.fb.group({
            contacted: this.fb.control(null, [Validators.required]),
            outcome: this.fb.control(null, [Validators.required]),
            escalation_required: this.fb.control(null, [Validators.required]),
        });

        this.InitForm(this.form);
    }

    onSelect(value: string, controlName: string) {
        this.getControl(controlName).patchValue(value);
    }

    onSend() {
        const hexId = this.store.selectSnapshot(InteractionTicketsState.getSelectedHexId);

        const data = this.form.getRawValue();
        const payload: ChatJsonNote = {
            message: "RICA post close form",
            detail: data,
            type: "rica_form"
        }

        this.postTicketService.sendPostTicketPayload(
            hexId, payload, this.modalOptions, this.close.bind(this)
        );
    }

    close() {
        this.store.dispatch(new ToggleModalByName("rica_post_close_modal", false));
    }

    ngOnDestroy(): void {
        this.close();
    }

}
