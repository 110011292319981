import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, OnDestroy, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DataHandler } from 'src/app/shared/data-handler/data-handler';
import { SnowflakeSelectOption } from '../snowflake-select/interfaces/rain-select-option.interface';

const getSelectedOptions = (options: SnowflakeSelectOption[]) => options.filter(o => o?.selected);

const defaultFormatTextFn = (options: SnowflakeSelectOption[]) => {
  const selectedOptions = getSelectedOptions(options);
  if (selectedOptions.length === options.length) {
    return selectedOptions[0].label.includes('No active sims') ?  'No active sims on this account' : 'all'
  }

  return selectedOptions
    .map(o => o.label)
    .join(", ");
};

@Component({
  selector: 'sf-rain-multi-select',
  templateUrl: './rain-multi-select.component.html',
  styleUrls: ['./rain-multi-select.component.scss']
})
export class RainMultiSelectComponent implements OnInit, OnDestroy {

  @Input() preIcon?: string;
  @Input() placeholder = "select option";
  @Input() options: SnowflakeSelectOption[] = [];
  @Input() isDisabled = false;
  @Input() hasError = false;
  @Input() bgColor = '#F5F5F5';
  @Input() label: string;
  @Input() labelStyle: "default" | "thin-large-font" | "thin-font-no-gap";
  @Input() menuPosition: 'top' | 'bottom' = "bottom";
  @Input() marginBottom = "0rem";
  @Input() formatTextFn: (options: SnowflakeSelectOption[]) => string = defaultFormatTextFn;
  @Output() onOptionSelect: EventEmitter<SnowflakeSelectOption["value"][]> = new EventEmitter();

  public form: FormGroup;
  public _isOpen = false;

  selectOptions: SnowflakeSelectOption[] = [];

  private readonly ngDestroy$ = new Subject<void>();

  constructor(private fb: FormBuilder, private eRef: ElementRef) {
  }


  ngOnInit(): void {
    const options = DataHandler.createDeepCopy(this.options ?? []);
    const formattedText = this.formatTextFn(options);
    const text = formattedText ? formattedText : this.placeholder;

    this.form = this.fb.group({
      text: this.fb.control(text),
      selectOptions: this.fb.control(options, Validators.required)
    });

    this.listenToFormChanges();
    this.selectOptions = options;
  }

  private listenToFormChanges() {
    const textControl = this.form.get('text');

    this.form.get('selectOptions').valueChanges
      .pipe(takeUntil(this.ngDestroy$))
      .subscribe({
        next: (selectedOptions: SnowflakeSelectOption[]) => {
          textControl.setValue(this.formatTextFn(selectedOptions));
          this.selectOptions = selectedOptions;
        }
      });
  }


  public resetForm() {
    this.form.reset();
  }


  public selectOption(option: SnowflakeSelectOption) {
    if (this.isDisabled || !option) return;

    const { selectOptions } = <{ text: string, selectOptions: SnowflakeSelectOption[] }>this.form.getRawValue();
    const selectedOption = selectOptions.find(o => o.label === option.label);
    selectedOption.selected = !selectedOption.selected;
    this.form.get('selectOptions').patchValue(selectOptions);

    this.onOptionSelect.next(getSelectedOptions(selectOptions).map(o => o?.value));
  }

  public toggleDropdown() {
    if (this.isDisabled) return;
    this._isOpen = !this._isOpen;
  }

  @HostListener('document:click', ['$event'])
  private onOutsideClick(e: Event) {
    if (!this.eRef.nativeElement.contains(e.target)) {
      this._isOpen = false;
    }
  }

  ngOnDestroy(): void {
    this.ngDestroy$.next(null);
    this.ngDestroy$.complete();
  }


}
