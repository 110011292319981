import { Injectable, OnDestroy } from "@angular/core";
import { Store } from "@ngxs/store";
import { fromEvent, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { NavigationState } from "src/app/core/store/state/navigations.state";
import { AgentActions } from "../store/actions/agent-action-actions";


@Injectable({
  providedIn: 'root'
})
export class AgentBrowserActionTracker implements OnDestroy {

  private ngDestroy$ = new Subject<void>();
  private _listeningBackButtonClicks = false;

  constructor(private store: Store) {
  }

  listenForBackButtonClicks() {
    if (this._listeningBackButtonClicks) {
      return;
    }

    this._listeningBackButtonClicks = true;

    fromEvent(window, 'popstate')
      .pipe(takeUntil(this.ngDestroy$))
      .subscribe(() => {
        //Set timeout is needed to make sure the breadCrumbService is updated before this is called
        setTimeout(() => {

          const wasBackButtonPressed = this.store.selectSnapshot(NavigationState.getWasBackButtonPressed);

          if (wasBackButtonPressed) {
            this.store.dispatch(new AgentActions.BrowserBackButtonClicked());
          }

        }, 0);

      });
  }

  ngOnDestroy(): void {
    this.ngDestroy$.next(null);
    this.ngDestroy$.complete();
  }

}
