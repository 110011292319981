import { Component, Input } from '@angular/core';
import { ButtonHelper } from 'src/app/Utils/helpers/button.helper';

@Component({
  selector: 'sf-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent extends ButtonHelper {
  @Input() buttonClass = "";
  @Input() icon = "";
  @Input() text = "";
  @Input() disabled = false;
}
