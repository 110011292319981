import { Component, Input } from '@angular/core';
import { Navigate } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';

@Component({
  selector: 'sf-back-btn',
  templateUrl: './back-btn.component.html',
  styleUrls: ['./back-btn.component.scss']
})
export class BackBtnComponent {
  @Input() url: string;
  constructor(private store: Store) { }

  public goBack() {
    const path = this.url ? this.url : './';
    return this.store.dispatch(new Navigate([path]));
  }

}
