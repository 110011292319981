<div class="credit-modal-overlay" [class.is-open]="( isOpen$ | async )">
    <div class="modal-lockup">
        <header>
            <div class="top-bar">
                <mat-icon class="default-cancel-icon" (click)="onClose()">cancel</mat-icon>
            </div>
            <h1 class="default-card-header">there are still open tickets available</h1>
        </header>
        <p>There are still {{numOpenTickets}} open {{numOpenTickets > 1 ? 'tickets' : 'ticket'}} for this customer, do you wish to proceed without working on them?</p>
        <div class="send-button-wrapper">
            <button class="rain-button default-button-size send-button" (click)="onProceed()">continue</button>
            <button class="rain-button default-button-size send-button solid" (click)="onClose()">stay</button>
        </div>
    </div>
</div>
