import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { tap, takeUntil, filter, take } from 'rxjs/operators';
import { Subject, merge } from 'rxjs';
import { DataLoading, getDataLoadingDefaultValues } from 'src/app/shared/interfaces/data-loading.interface';
import { Utils } from 'src/app/Utils';
import { DataLoadingHelper } from 'src/app/Utils/helpers';
import { AgentViewTicketActions } from '../actions/agent-view-ticket-actions';
import { AgentViewDestroyed } from 'src/app/agent-view-page/store/actions/agent-view.actions';
import { TicketAssignmentService } from '../../services/ticket-assignment.service';
import { AssignableTicket } from '../types/assignable-hex-id.interface';
import { CustomerTicketHelper } from 'src/app/shared/customer-ticket/customer-ticket-helper';
import { InteractionTicketActions } from 'src/app/interactions/store/actions/interaction-ticket-actions';
import { AgentTicketTimerActions } from 'src/app/agent-view-page/store/actions/agent-ticket-timer-actions';
import { CoreState } from 'src/app/core/store/state/core.state';


const getDefaults = (): AgentViewTicketStateModel => {
    return {
        ...getDataLoadingDefaultValues(),
        lastRequestTime: null
    }
}

export interface AgentViewTicketStateModel extends DataLoading<AssignableTicket> {
    lastRequestTime: string;
}

@State<AgentViewTicketStateModel>({
    name: 'sf_AgentViewTicket_state',
    defaults: getDefaults()
})
@Injectable()
export class AgentViewTicketState {

    @Selector()
    static isLoading(state: AgentViewTicketStateModel) { return state.loading }

    @Selector()
    static isLoaded(state: AgentViewTicketStateModel) { return state.loaded }

    @Selector()
    static getData(state: AgentViewTicketStateModel) { return state.data }

    @Selector()
    static getError(state: AgentViewTicketStateModel) { return state.error }

    @Selector([AgentViewTicketState.getData])
    static getTicketHelper(data: AssignableTicket) {
        const { ticket } = data ?? {};
        return ticket ? new CustomerTicketHelper(ticket) : null;
    }

    @Selector([AgentViewTicketState, AgentViewTicketState.getTicketHelper])
    static getState(state: AgentViewTicketStateModel, ticketHelper: CustomerTicketHelper): AgentViewTicketStateModel & { ticketHelper: CustomerTicketHelper } {
        return { ...state, ticketHelper };
    }

    private readonly _cancelRequest$ = new Subject<null>();

    constructor(private ticketAssignmentService: TicketAssignmentService,
        private store: Store) {
    }

    @Action(AgentViewTicketActions.FetchTicketToAssign)
    Fetch(ctx: StateContext<AgentViewTicketStateModel>) {

        ctx.patchState({
            loading: true
        });

        const notAcceptingTickets$ = this.store.select(CoreState.getAgentTrackingDetails)
            .pipe(
                filter(res => {
                    const { acceptingTickets, status } = res ?? {};
                    return !acceptingTickets || status !== "available";
                })
            );

        const cancel$ = merge(notAcceptingTickets$, this._cancelRequest$)
            .pipe(
                tap(() => ctx.patchState({ loading: false })),
                take(1)
            );

        return this.ticketAssignmentService.pollForAssignableTicket()
            .pipe(
                tap({
                    next: res => ctx.dispatch(new AgentViewTicketActions.FetchSuccess(res)),
                    error: (e: unknown) => ctx.dispatch(new AgentViewTicketActions.FetchFail(e))
                }),
                takeUntil(cancel$)
            );
    }


    @Action(AgentViewTicketActions.FetchSuccess)
    FetchSuccess(ctx: StateContext<AgentViewTicketStateModel>, action: AgentViewTicketActions.FetchSuccess) {
        const { payload } = action;
        DataLoadingHelper.setData(ctx, payload);

        return ctx.dispatch([
            new InteractionTicketActions.SetAssignedHexId(payload?.ticket?.hex_id),
            new AgentTicketTimerActions.StartTicketTimer()
        ])
    }

    @Action(AgentViewTicketActions.FetchFail)
    FetchFail(ctx: StateContext<AgentViewTicketStateModel>, action: AgentViewTicketActions.FetchFail) {
        const error = Utils.Helpers.findError(action.error, '');
        DataLoadingHelper.setError(ctx, error);
    }

    @Action(AgentViewTicketActions.UpdateTicketRequestTime)
    UpdateTicketRequestTime(ctx: StateContext<AgentViewTicketStateModel>) {
        ctx.patchState({
            lastRequestTime: new Date(Date.now()).toString()
        });
    }

    @Action(AgentViewTicketActions.SetLoading)
    SetLoading(ctx: StateContext<AgentViewTicketStateModel>, action: AgentViewTicketActions.SetLoading) {
        ctx.patchState({
            loading: action.loading
        });
    }

    @Action([AgentViewTicketActions.Clear, AgentViewDestroyed])
    Clear(ctx: StateContext<AgentViewTicketStateModel>) {
        this._cancelRequest$.next(null);
        ctx.patchState({
            ...getDataLoadingDefaultValues()
        })
    }

}   