import { Injectable } from '@angular/core';
import { Action, createSelector, Selector, State, StateContext, } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { MILISECONDS_IN_HOUR } from 'src/app/constants';
import { SetPersistentStorageItem } from 'src/app/core/store/actions/persistent-storage.actions';
import { SiteLoaderService } from 'src/app/shared/elements/map/services/site-loader.service';
import { GeoFunctions } from 'src/app/shared/functions/geo-functions';
import { DataLoading, getDataLoadingDefaultValues } from 'src/app/shared/interfaces/data-loading.interface';
import { NormalSiteFeatureCollection, NormalSiteProperties } from 'src/app/shared/services/google-maps/assets/map-site.interfaces';
import { IndexedDBService } from 'src/app/shared/services/indexed-db/indexed-db.service';
import { IndexedDBActions } from 'src/app/shared/services/indexed-db/store/actions/indexed-db-actions';
import { Utils } from 'src/app/Utils';
import { DataLoadingHelper } from 'src/app/Utils/helpers';
import { environment } from 'src/environments/environment';
import { NormalSiteActions } from '../actions/normal-site-actions';

const ALL_SITES_CACHE_TIME_IN_HOURS = 8;

export type NormalSitesStateModel = DataLoading<NormalSiteFeatureCollection>
@State<NormalSitesStateModel>({
    name: 'sf_normal_sites_state',
    defaults: {
        ...getDataLoadingDefaultValues()
    }
})
@Injectable()
export class NormalSitesState {

    @Selector()
    static isLoading(state: NormalSitesStateModel) { return state.loading }

    @Selector()
    static isLoaded(state: NormalSitesStateModel) { return state.loaded }

    @Selector()
    static getData(state: NormalSitesStateModel) { return state.data }

    @Selector()
    static getError(state: NormalSitesStateModel) { return state.error }

    @Selector()
    static getState(state: NormalSitesStateModel) { return state }

    static getFeatureByEnodeBId(enodeBId: string | number) {
        return createSelector(
            [NormalSitesState.getData],
            (features: NormalSiteFeatureCollection) => {
                if (!features?.length || !enodeBId) {
                    return null;
                }

                return features?.find(f => {
                    const { enodeb_list, enbid } = f?.properties ?? {};
                    const enodebStr = enodeBId?.toString();

                    const enodebIdsMatch = enodebStr === enbid?.toString();
                    const enodebInList = (enodeb_list ?? [])?.includes(enodebStr);

                    return enodebIdsMatch || enodebInList;
                });
            });
    }

    static getFeaturesByProperty<P extends keyof NormalSiteProperties, V extends NormalSiteProperties[P]>
        (property: P, value: V) {
        return createSelector(
            [NormalSitesState.getData],
            (features: NormalSiteFeatureCollection) => GeoFunctions.filterFeaturesByProperty(features ?? [], property, value)
        );
    }

    @Selector([NormalSitesState.getData])
    static hasData(data: NormalSiteFeatureCollection) { return data?.length > 0 }

    constructor(private siteLoaderService: SiteLoaderService,
        private indexedDbService: IndexedDBService) {
    }

    @Action(NormalSiteActions.Initialize)
    Initialize(ctx: StateContext<NormalSitesStateModel>) {
        const { loading, data } = ctx.getState();
        //Return if loading
        if (loading) {
            return;
        }

        const shouldRefresh = this.siteLoaderService.shouldRefreshNormalSites(ALL_SITES_CACHE_TIME_IN_HOURS);
        if (shouldRefresh) {
            return ctx.dispatch([
                new SetPersistentStorageItem("app-version", environment.version),
                new NormalSiteActions.Fetch()
            ]);
        }

        //If data has not already been set, check local storage
        if (!data?.length) {
            return ctx.dispatch(new NormalSiteActions.FetchFromStorage());
        }
    }

    @Action(NormalSiteActions.FetchFromStorage)
    FetchFromStorage(ctx: StateContext<NormalSitesStateModel>) {
        ctx.patchState({ loading: true });

        return this.indexedDbService.get("data-all-sites")
            .pipe(
                tap({
                    next: savedFeatures => {
                        if (!savedFeatures?.length) {
                            return ctx.dispatch(new NormalSiteActions.Fetch());
                        }
                        return ctx.dispatch(new NormalSiteActions.FetchFromStorageSuccess(savedFeatures));
                    },
                    //If loading from localstorage failed, fetch the data from backend
                    error: () => ctx.dispatch(new NormalSiteActions.Fetch())
                }));
    }

    @Action(NormalSiteActions.Fetch)
    Fetch(ctx: StateContext<NormalSitesStateModel>) {
        ctx.patchState({ loading: true });

        return this.siteLoaderService.fetchNormalMapSites()
            .pipe(tap({
                next: res => {
                    if (!res?.data?.length) {
                        return ctx.dispatch(new NormalSiteActions.FetchFail("Failed to load normal map sites. Response was empty."));
                    }
                    return ctx.dispatch(new NormalSiteActions.FetchSuccess(res.data));
                },
                error: (e: unknown) => ctx.dispatch(new NormalSiteActions.FetchFail(e))
            }));
    }

    @Action(NormalSiteActions.FetchFromStorageSuccess)
    FetchFromStorageSuccess(ctx: StateContext<NormalSitesStateModel>, action: NormalSiteActions.FetchFromStorageSuccess) {
        const { payload } = action;
        DataLoadingHelper.setData(ctx, payload);
    }


    @Action(NormalSiteActions.FetchSuccess)
    FetchSuccess(ctx: StateContext<NormalSitesStateModel>, action: NormalSiteActions.FetchSuccess) {
        const { payload } = action;
        DataLoadingHelper.setData(ctx, payload);

        return ctx.dispatch([
            new IndexedDBActions.SetItem("data-all-sites", payload),
            new SetPersistentStorageItem("last-updated-normal-map-sites", Date.now())
        ]);
    }


    @Action(NormalSiteActions.FetchFail)
    FetchFail(ctx: StateContext<NormalSitesStateModel>, action: NormalSiteActions.FetchFail) {
        const { data } = ctx.getState();
        const { error } = action;

        /*
           If there is an error, but there is loaded or saved data 
           keep the current data and don't set an error.
           Retry the call again in 2 hours.
         */
        if (data?.length) {

            ctx.patchState({
                loading: false,
                loaded: true
            });
            return ctx.dispatch(new SetPersistentStorageItem("last-updated-normal-map-sites", getAdjustedTime()));
        }

        return ctx.dispatch(new NormalSiteActions.FetchOldDataFromStorage(error));
    }

    @Action(NormalSiteActions.FetchOldDataFromStorage)
    FetchOldDataFromStorage(ctx: StateContext<NormalSitesStateModel>, action: NormalSiteActions.FetchOldDataFromStorage) {
        const { error } = action;
        return this.indexedDbService.get("data-all-sites")
            .pipe(
                tap({
                    next: savedFeatures => {
                        if (!savedFeatures?.length) {
                            return ctx.dispatch(new NormalSiteActions.SetError(error));
                        }
                        return ctx.dispatch([
                            new SetPersistentStorageItem("last-updated-normal-map-sites", getAdjustedTime()),
                            new NormalSiteActions.FetchFromStorageSuccess(savedFeatures)
                        ]);
                    },
                    error: (e: unknown) => ctx.dispatch(new NormalSiteActions.SetError(e))
                }));
    }

    @Action(NormalSiteActions.SetError)
    SetError(ctx: StateContext<NormalSitesStateModel>, action: NormalSiteActions.FetchFail) {
        const error = Utils.Helpers.findError(action.error, "Failed to load normal map sites.");
        DataLoadingHelper.setError(ctx, error);
    }

}


//Adjusted time is 2hours before cache expiry 
const getAdjustedTime = () => Date.now() - (ALL_SITES_CACHE_TIME_IN_HOURS - 2) * MILISECONDS_IN_HOUR;

