import { Injectable } from '@angular/core';
import { Action, createSelector, Selector, State, StateContext, } from '@ngxs/store';
import { InteractionActions } from 'src/app/interactions/store/actions/interaction-actions';
import { FilterFunctions } from 'src/app/shared/functions/filter';
import { Utils } from 'src/app/Utils';
import { Add, Remove, Clear } from '../actions/whatsapp-notification-actions';


interface WhatsappNotificationStateModel {
    messageIds: {
        [id: string]: string;
    }
}
@State<WhatsappNotificationStateModel>({
    name: 'sf_whatsapp_message_notification_state',
    defaults: {
        messageIds: {}
    }
})
@Injectable()
export class WhatsappNotificationState {

    @Selector()
    static count(state: WhatsappNotificationStateModel) {
        return Object.keys(state.messageIds).length;
    }

    static hasId(id: string) {
        return createSelector(
            [WhatsappNotificationState],
            (state: WhatsappNotificationStateModel) => id in state.messageIds
        );
    }


    @Action(Add)
    Add(ctx: StateContext<WhatsappNotificationStateModel>, action: Add) {
        const { messageIds } = ctx.getState();
        const { id } = action;

        if (!id) {
            return;
        }

        ctx.patchState({
            messageIds: Utils.Mappers.patch(messageIds, { [id]: id })
        });
    }

    @Action(Remove)
    Remove(ctx: StateContext<WhatsappNotificationStateModel>, action: Remove) {
        const { messageIds } = ctx.getState();
        const { id } = action;

        ctx.patchState({
            messageIds: FilterFunctions.removeProperties(messageIds, [id])
        });
    }

    @Action([InteractionActions.InteractionMenuDestroyed, Clear])
    Clear(ctx: StateContext<WhatsappNotificationStateModel>) {
        ctx.setState({
            messageIds: {}
        });
    }


}   