import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SitWarningModalComponent } from './sit-warning-modal/sit-warning-modal.component';
import { SharedModule } from '../shared/shared.module';
import { WarningModalsComponent } from './warning-modals/warning-modals.component';
import { MultipleTabsWarningModalComponent } from './multiple-tabs-warning-modal/multiple-tabs-warning-modal.component';
import { NgxsModule } from '@ngxs/store';
import { SfWarningState } from './store/state/sf-warnings.state';

const declarations = [
  SitWarningModalComponent,
  MultipleTabsWarningModalComponent,
  WarningModalsComponent
];

@NgModule({
  declarations: [...declarations],
  imports: [
    CommonModule,
    NgxsModule.forFeature([SfWarningState]),
    SharedModule
  ],
  exports: [...declarations]
})
export class WarningsModule { }
