import { Utils } from "src/app/Utils";
import { CallLogType, SipCallType } from "../../../assets/sip-call.type";
import { CallDetails } from "../../interfaces/call-details.interface";
import { LogCallPayload } from "../../interfaces/log-call-payload.interface";


export class SetUserAgentProcessing {
  static readonly type = Utils.Helpers.Type('[Sip: set] set whether or not the user agent is busy creating or destroying a connection');
  constructor(public processing: boolean) { }
}

export class SetIncomingCallDetails {
  static readonly type = Utils.Helpers.Type('[Sip: set] set incoming call details');
  constructor(public payload: CallDetails) { }
}

export class ClearIncomingCallDetails {
  static readonly type = Utils.Helpers.Type('[Sip: clear] clear incoming call details');
}

export class SetOutgoingCallDetails {
  static readonly type = Utils.Helpers.Type('[Sip: set] set outgoing call details');
  constructor(public payload: CallDetails) { }
}

export class LogCall {
  static readonly type = Utils.Helpers.Type('[Sip: Log] log incoming or outgoing call');
  constructor(public callType: CallLogType, public payload: LogCallPayload<CallDetails>) { }
}

export class SetCallTicketHexId {
  static readonly type = Utils.Helpers.Type('[Sip: Set] set call ticket hex ID for ticket created specifically for a call');
  constructor(public hexId: string) { }
}

export class CallSessionEnded {
  static readonly type = Utils.Helpers.Type('[Sip: Notify] Call ended');
  constructor(public callType: SipCallType) { }
}

export class SetUnknownIncomingCall {
  static readonly type = Utils.Helpers.Type('[Sip: Set] set uknownIncomingCall');
  constructor(public isUnknown: boolean) { }
}

export class RetryUserAgentRegistration {
  static readonly type = Utils.Helpers.Type('[Sip: Retry] retry user agent registration');
}


export class SetCurrentCallType {
  static readonly type = Utils.Helpers.Type('[Sip: CallType] set current call type');
  constructor(public callType: SipCallType) { }
}


