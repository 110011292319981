import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { Product, ProductType, RainProductsMap } from "../store/interfaces/rain-product.interface";
import { CatalogProduct, CatalogSimProduct, ProductCatalogMap, ProductCatalogType } from "../store/interfaces/product-catalog.interfaces";
import { map } from "rxjs/operators";
import { SNOWFLAKE_FE_PROXY } from "src/environments/env.constants";
import * as backup from "./prod-catalog-json/catalog.json"
import { of } from "rxjs";


@Injectable({
    providedIn: 'root'
})
export class ProductService {


    constructor(private http: HttpClient) {
    }

    getAll() {
        const headers = {
            'Content-Type': 'application/json',
        }

        const url = `${environment.cdn}/web/json/products.json`;
        return this.http.get<{ products: Product[] }>(url, { headers });
    }

    getProductCatalog() {
        const url = `${environment.main_proxy}/firestore/base`;
        const headers = {
            'Content-Type': 'application/json',
        }

        return this.http.get<{ result: CatalogProduct[] }>(url, { headers })
            .pipe(
                map(res => {
                    if (environment.production) {
                        return res;
                    }
                    res?.result.push(tempProduct)
                    return res;
                })
            );
    }

    //TODO: pretty much the same method
    getProductsCatalogMap(products: CatalogProduct[]) {
        const productMap: ProductCatalogMap = {
            addon: [],
            bundle: [],
            sim: [],
            delivery: [],
            device: [],
            accessory: [],
            isim: []
        };

        const productTypes = Object.keys(productMap);
        if (!products?.length) {
            return productMap;
        }

        for (const product of products) {
            const productType: ProductCatalogType = product?.type;
            if (productTypes.includes(productType)) {
                productMap?.[productType]?.push(product as any);
            }
        }
        return productMap;
    }

    getProductsMap(products: Product[]) {
        const productMap: RainProductsMap = {
            addon: [],
            bundle: [],
            product: [],
            delivery: []
        };

        const productTypes = Object.keys(productMap);
        if (!products?.length) {
            return productMap;
        }

        for (const product of products) {
            const productType: ProductType = product?.type;
            if (productTypes.includes(productType)) {
                productMap?.[productType]?.push(product as any);
            }
        }
        return productMap;
    }

}


const tempProduct: CatalogSimProduct = {
    "name": "Rainone Voice Testing Level 1",
    "id": "c44d207e-b561-44d4-b0d1-dd8233281f2f",
    "type": "sim",
    "category": "4G",
    "sku": "RAIN-DIR-00103",
    "config": {
        "level": 1,
        "migration": [],
        "paymentType": "prepaid" as any,
        "maxLines": 1,
        "spend-limit":{ default: 0,max: 0, min: 0, }
    }
}
