<sf-popup-modal
  [isOpen]='true'
  [hasCloseButton]='false'
  [autoClose]='false'
  [successResponse]='modalOptions.successResponse'
  [sending]='modalOptions.sending'
  [spinnerMessage]='modalOptions.spinnerMessage'
  [limitHeight]='true'
>
  <ng-container header>nvidia questionnaire</ng-container>

  <ng-container mainContent>
    <sf-modal-form-container [formGroup]="form">
      <div class="select-options">
        <div class="form-row">
          <sf-form-input
            label="date/time played"
            [control]="form.get('date_time_played')"
            [type]="'text'"
          ></sf-form-input>
        </div>

        <div class="form-row">
          <sf-form-input
            label="game played"
            [control]="form.get('game_played')"
            [type]="'text'"
          ></sf-form-input>
        </div>

        <div class="form-row">

          <sf-address-search
            [control]="form.get('game_played_address')"
            (onAddressSelect)="onAddressSelected($event)"
            label="address where the game was played"
          >
          </sf-address-search>

          <sf-snowflake-address-inputs
            (customerAddressOut)="setAddress($event)"
            [inputAddress]="inputAddress"
          >
          </sf-snowflake-address-inputs>

        </div>

        <div class="form-row">
          <sf-rain-multi-select
            label="query"
            [options]="queryOptions"
            placeholder="query"
            labelStyle="thin-font-no-gap"
            (onOptionSelect)="onOptionSelect('query',$event)"
          >
          </sf-rain-multi-select>
        </div>


        <div class="form-row">
          <div class="checkbox">
            <sf-form-checkbox
              [control]="this.form.get('screenshotsCheckbox')"
              label="Add screenshots"
              (onClick)="toggleScreenShots()"
            >
            </sf-form-checkbox>
          </div>

          <div *ngIf="hasScreenshots">

            <app-file-uploader
              [control]="this.form.get('attachmentsUploader')"
              *ngIf="form"
              (newFiles)="attachFileData($event)"
              (close)="toggleScreenShots()"
            ></app-file-uploader>
          </div>
        </div>


        <div class="form-row">
          <sf-form-input
            label="ISP"
            [control]="form.get('ISP')"
            [type]="'text'"
          ></sf-form-input>
        </div>

        <div class="form-row">
          <sf-select
            label="home network type"
            [options]="homeNetworkTypeOptions"
            placeholder="home network type"
            labelStyle="thin-font-no-gap"
            (onOptionSelect)="onOptionSelect('home_network_type', $event)"
          ></sf-select>
        </div>

        <div class="form-row">
          <sf-select
            label="connection technology"
            [options]="connectionTechnologyOptions"
            placeholder="connection technology"
            labelStyle="thin-font-no-gap"
            (onOptionSelect)="onOptionSelect('connection_technology', $event)"
          ></sf-select>
        </div>

        <div class="form-row">
          <sf-select
            label="ticket outcome"
            [options]="ticketOutcomeOptions"
            placeholder="ticket outcome"
            labelStyle="thin-font-no-gap"
            (onOptionSelect)="onOptionSelect('ticket_outcome', $event)"
          ></sf-select>
        </div>
        <div class="form-row">
          <sf-select
            label="platform"
            [options]="platformOptions"
            placeholder="platform"
            labelStyle="thin-font-no-gap"
            (onOptionSelect)="onOptionSelect('platform', $event)"
          ></sf-select>
        </div>

        <div class="form-row">
          <sf-select
            label="query outcome"
            [options]="queryOutcomeOptions"
            placeholder="query outcome"
            labelStyle="thin-font-no-gap"
            (onOptionSelect)="onOptionSelect('query_outcome', $event)"
          ></sf-select>
        </div>

        <div class="form-row">
          <sf-select
            label="rate game expericence between 1 and 5"
            [options]="gameExperienceOptions"
            placeholder="game experience rating"
            (onOptionSelect)="onOptionSelect('game_experience', $event)"
            labelStyle="thin-font-no-gap"
          ></sf-select>
        </div>

        <div class="form-row">
          <sf-form-textarea
            label="comments"
            [control]="this.form.get('comments')"
            placeholder="comments"
          ></sf-form-textarea>
        </div>

      </div>

      <div class="spacer"></div>
    </sf-modal-form-container>
  </ng-container>

  <ng-container buttons>

    <button
      class="rain-button default-button-size send-button solid"
      (click)="onSend()"
      [disabled]="form.invalid || modalOptions.sending"
    >
      send
    </button>
  </ng-container>
</sf-popup-modal>
