import { Injectable } from '@angular/core';
import { State, Action, StateContext } from '@ngxs/store';
import { TicketEventHandlerService } from 'src/app/shared/customer-ticket/ticket-event-handler/ticket-event-handler.service';
import { AddKnownCustomerEvent, AddNoteToDoRefactor, AddTicketEvent } from '../actions/ticket-event.actions';


type TicketEventStateModel = Record<string, unknown>;
@State<TicketEventStateModel>({
  name: 'ticket_event_state',
  defaults: {}
})
@Injectable()
export class TicketEventState {

  constructor(private ticketEventService: TicketEventHandlerService) {
  }

  @Action(AddKnownCustomerEvent)
  addKnownCustomerEvent(_ctx: StateContext<TicketEventStateModel>, action: AddKnownCustomerEvent) {
    const { eventComment, reload, specificHexId, email } = action.payload;
    return this.ticketEventService.addEventForKnownCustomer(eventComment, reload, specificHexId, email);
  }


  @Action(AddTicketEvent)
  addUnknownCustomerEvent(_ctx: StateContext<TicketEventStateModel>, action: AddTicketEvent) {
    const { hexId, eventComment, call_id } = action.payload;
    return this.ticketEventService.addNewEvent(hexId, eventComment, call_id);
  }

  @Action(AddNoteToDoRefactor)
  addNote(_ctx: StateContext<TicketEventStateModel>, action: AddNoteToDoRefactor) {
    return this.ticketEventService.addNote(action.payload);
  }

}
