import { Utils } from "src/app/Utils";
import { QueueData } from "../../../services/sip-platform/assets/voice-queue-info.interface";
import { LoginAction } from "../../../services/sip-platform/login-action.type";
import { VoiceAccessToken } from "../../../services/voice-agents/assets/voice-tokens.type";
import { ToggleLoginPayload } from "../../interfaces/toggle-login-payload.interface";


export interface FetchFailOptions {
    dontNotify?: boolean,
    isWarning?: boolean
}

export class FetchQueues {
    static readonly type = Utils.Helpers.Type('[Voice: AgentQueues] Fetch Agent queues');
    constructor(public voiceToken: VoiceAccessToken) { }
}

export class FetchQueuesSuccess {
    static readonly type = Utils.Helpers.Type('[Voice: AgentQueues] Fetch Agent queues success');
    constructor(public payload: QueueData[]) { }
}

export class FetchQueuesFail {
    static readonly type = Utils.Helpers.Type('[Voice: AgentQueues] Fetch Agent queues fail');
    constructor(public error: any, public options: FetchFailOptions = { dontNotify: false, isWarning: false }) { }
}

export class ToggleLogin {
    static readonly type = Utils.Helpers.Type('[Voice: AgentQueues] Toggle voice queue login');
    constructor(public payload: ToggleLoginPayload) { }
}

export class ToggleLoginSuccess {
    static readonly type = Utils.Helpers.Type('[Voice: AgentQueues] Toggle voice queue login success');
    constructor(public loginAction: LoginAction, public showMessage = false) { }
}

export class ToggleLoginFail {
    static readonly type = Utils.Helpers.Type('[Voice: AgentQueues] Toggle voice queue login fail');
    constructor(public loginAction: LoginAction, public error: any, public showError = false) { }
}

export class RetryLogin {
    static readonly type = Utils.Helpers.Type('[Voice: AgentQueues] Retry login');
    constructor(public hardRefresh = false) { }
}

export class Clear {
    static readonly type = Utils.Helpers.Type('[Voice: AgentQueues] Clear');
}

