import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { Store } from '@ngxs/store';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil, tap } from 'rxjs/operators';
import { SalesCartState } from 'src/app/sales/store/state/sales-cart.state';

@Component({
  selector: 'sf-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss']
})
export class SearchBarComponent implements OnInit, OnChanges, OnDestroy {
  @Input() showShadow = true;
  @Input() placeholder = "search...";
  @Input() label?: string;
  @Input() showCard = true;
  @Input() setValue?: string;
  @Input() initialValue?: string;
  @Input() delay = 1000;
  @Input() minLength = 1;
  @Input() alwaysEmit = false;
  @Output() onSearch: EventEmitter<string> = new EventEmitter();
  public _searchForm: FormGroup;
  public spinner = faSpinner;
  public searching = false;

  private ngDestroy: Subject<void> = new Subject();

  constructor(private fb: FormBuilder, private store: Store) { }

  ngOnInit(): void {
    this._searchForm = this.fb.group({
      search_query: this.fb.control(this.initialValue ?? "", Validators.compose([Validators.required, Validators.minLength(this.minLength)]))
    });

    this._searchForm.get('search_query').valueChanges.
      pipe(
        tap(() => this.searching = true),
        debounceTime(this.delay),
        takeUntil(this.ngDestroy)
      )
      .subscribe({
        next: (q) => this.onUserTyping(q)
      });

      this.store.selectSnapshot(SalesCartState.getSelectedBundleId)
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.setValue?.currentValue && (changes.setValue.currentValue !== changes.setValue.previousValue)) {
      if (this._searchForm) {
        setTimeout(() => {
          this.patchSearchQuery(changes.setValue.currentValue);
        }, 500);
      } else {
        setTimeout(() => {
          this._searchForm = this.fb.group({
            search_query: this.fb.control(changes.setValue.currentValue, Validators.compose([Validators.required, Validators.minLength(this.minLength)]))
          });
        }, 500);
      }
    }
  }

  private patchSearchQuery(value: string) {
    this._searchForm.get('search_query').patchValue(value, { emitEvent: false, onlySelf: true });
  }

  setSearchText(text: string) {
    this.patchSearchQuery(text);
  }


  private onUserTyping(query: string) {
    if (!this._searchForm.valid && !this.alwaysEmit) {
      this.searching = false;
      return;
    }

    this.onSearch.emit(query);
    this.searching = false;
  }

  public ngOnDestroy() {
    this.ngDestroy.next(null);
    this.ngDestroy.complete();
  }

}
