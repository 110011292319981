import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Subject } from 'rxjs';
import { FormHelper } from 'src/app/Utils/helpers';
import { PostTicketModalOptions } from 'src/app/shared/services/post-ticket/post-ticket-modal-options.model';
import { ChatJsonNote } from '../components/chat-json-note/assets/chat-json-note.interface';
import { Store } from '@ngxs/store';
import { PostTicketService } from 'src/app/shared/services/post-ticket/post-ticket.service';
import { InteractionTicketsState } from '../../store/state/interaction-tickets.state';
import { ToggleModalByName } from 'src/app/core/store/actions/modal.actions';

@Component({
  selector: 'sf-fulfillment-rica-post-close-modal',
  templateUrl: './fulfillment-rica-post-close-modal.component.html',
  styleUrls: ['./fulfillment-rica-post-close-modal.component.scss']
})
export class FulfillmentRicaPostCloseModalComponent extends FormHelper implements OnInit {
  readonly FULFILLMENT_RICA_OPTIONS: string[]= [
    'Rica',
    'AI',
    'QA'
  ]
  selectedRicaOption: string = null;
  form: FormGroup;
  modalOptions: PostTicketModalOptions;
  readonly ngDestroy$ = new Subject<void>();

  constructor(private formBuilder:FormBuilder, private store:Store, private postTicketService:PostTicketService ) {
    super();
  }

  createForm()
  {
    this.form = this.formBuilder.group({
      fulfillment_rica_option: this.formBuilder.control('')
    })
  }
  ngOnInit(): void {
    this.createForm();
    this.InitForm(this.form);
    this.modalOptions = new PostTicketModalOptions();
  }

  updateSelectedOption(option: string) {
    this.selectedRicaOption = option;
}

onSubmit(){
  const data = {
    "rica reason": this.selectedRicaOption,
  }
  const payload: ChatJsonNote = {
      message: "fulfillment rica post close form",
      detail: data,
      type: 'fulfillment_rica_form'
  }
  const hexId = this.store.selectSnapshot(InteractionTicketsState.getSelectedHexId);
  this.postTicketService.sendPostTicketPayload(
    hexId, payload, this.modalOptions, this.close.bind(this)
);
}

close(){
  this.store.dispatch(new ToggleModalByName('fulfillment_rica_post_close_modal'));
}

 ngOnDestroy() {
  this.close();
  this.ngDestroy$.next(null);
  this.ngDestroy$.complete();
}
}

