<sf-popup-modal
  [isOpen]="true"
  [hasCloseButton]="false"
  [autoClose]="false"
  [successResponse]="modalOptions.successResponse"
  [sending]="modalOptions.sending"
  [spinnerMessage]="modalOptions.spinnerMessage"
  [limitHeight]="true"
>
  <ng-container
    header
  > {{ isRain101Agent ? 'rain101' : 'rain101 weekone'}} questionnaire</ng-container>


  <ng-container mainContent>
    <sf-modal-form-container [formGroup]="form">
      <h3>(1) {{ isRain101Agent ? ' Why did the customer request for help?' : ' Why was the ticket escalated?'}}</h3>
      <div *ngIf="!isMistake">
        <sf-select
        [labelStyle]="labelStyle"
        label="(a) Network Query"
        [options]="networkQueryOptions"
        marginBottom="1rem"
        (onOptionSelect)="onSelect('network_query', $event)"
        placeholder="select network"
      >
      </sf-select>

      <sf-select
        [labelStyle]="labelStyle"
        label="(b) Billing Query"
        [options]="billingQueryOptions"
        marginBottom="1rem"
        (onOptionSelect)="onSelect('billing_query', $event)"
        placeholder="select billing query"
      >
      </sf-select>
      <sf-select
        [labelStyle]="labelStyle"
        label="(c) Mobile Query"
        [options]="mobileQueryOptions"
        marginBottom="1rem"
        (onOptionSelect)="onSelect('mobile_query', $event)"
        placeholder="select mobile query"
      >
      </sf-select>

      <sf-form-textarea
        label="(d) general"
        [control]="this.form.get('general')"
        placeholder="general"
      ></sf-form-textarea>

      </div>



      <sf-form-checkbox
        [control]="this.form.get('mistake')"
        label="(e) Help requested by mistake?"
        marginBottom="1rem"
      >
      </sf-form-checkbox>


      <sf-select
        [labelStyle]="labelStyle"
        label="2. Query Outcome"
        [options]="queryOutcomeOptions"
        marginBottom="1rem"
        (onOptionSelect)="onSelect('query_outcome', $event)"
        placeholder="select query outcome"
      >
      </sf-select>

      <sf-select
        *ngIf="isEscalated"
        [labelStyle]="labelStyle"
        label="escalation reason"
        [options]="escalationReasons"
        marginBottom="1rem"
        (onOptionSelect)="onSelect('escalation_type', $event)"
        placeholder="select escalation reason"
      >
      </sf-select>

      <sf-select
        [labelStyle]="labelStyle"
        label="3. connection type"
        [options]="connectionTypeOptions"
        marginBottom="1rem"
        (onOptionSelect)="onSelect('connection_type', $event)"
        placeholder="select connection type"
      >
      </sf-select>
    </sf-modal-form-container>
  </ng-container>

  <ng-container buttons>
    <button
      class="rain-button default-button-size send-button solid"
      (click)="onSend()"
      [disabled]="form?.invalid || modalOptions.sending"
    >
      send
    </button>
  </ng-container>
</sf-popup-modal>
