import { Utils } from "src/app/Utils";
import { IndexedDBItems } from "../../../indexed-db-key.type";


export class SetItem<Key extends keyof IndexedDBItems = any> {
    static readonly type = Utils.Helpers.Type("[IndexedDB: Set]")
    constructor(public key: Key, public value: IndexedDBItems[Key]) { }
}

export class ClearItem<Key extends keyof IndexedDBItems = any> {
    static readonly type = Utils.Helpers.Type("[IndexedDB: Get]")
    constructor(public key: Key) { }
}
