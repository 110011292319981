import { PopupError } from "./popup-error";


export class InfoPopupError extends PopupError {

    //Set default logging for info popup to false
    constructor(public message: string, public log = false) {
        super(message, log);
    }

}