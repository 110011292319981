import { Component, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { CloseCurrentLoadsheddingReport, FetchCurrentLoadsheddingReport } from '../store/actions/current-loadshedding-report.actions';
import { LoadsheddingReport } from '../store/interfaces/loadshedding-report.interface';
import { CurrentLoadsheddingReportState } from '../store/state/current-loadshedding-report.state';

@Component({
  selector: 'sf-loadshedding-banner',
  templateUrl: './loadshedding-banner.component.html',
  styleUrls: ['./loadshedding-banner.component.scss']
})
export class LoadsheddingBannerComponent implements OnInit {
  @Select(CurrentLoadsheddingReportState.getData) currentReport$: Observable<LoadsheddingReport>;
  @Select(CurrentLoadsheddingReportState.isLoaded) isLoaded$: Observable<boolean>;
  @Select(CurrentLoadsheddingReportState.isOpen) isOpen$: Observable<boolean>;

  constructor(private store: Store) { }


  ngOnInit(): void {
    this.store.dispatch(new FetchCurrentLoadsheddingReport());
  }


  onClose() {
    this.store.dispatch(new CloseCurrentLoadsheddingReport());
  }

}
