<div class="wrapper" [class.is-blue]="isBlue">

    <ng-container [ngSwitch]="ticks">

        <span class="none" *ngSwitchCase="'none'">--</span>

        <mat-icon *ngSwitchCase="'failed'" class="failed pointer" matTooltip="failed">
            error
        </mat-icon>

        <mat-icon *ngSwitchCase="'deleted'" class="failed pointer" matTooltip="deleted">
            error
        </mat-icon>

        <ng-container *ngSwitchDefault>
            <span class="checkmark" [class.has-two]="hasTwo">
                <div class="checkmark-stem"></div>
                <div class="checkmark-kick"></div>
            </span>

            <span *ngIf="hasTwo" class="checkmark">
                <div class="checkmark-stem"></div>
                <div class="checkmark-kick" [class.has-two]="hasTwo"></div>
            </span>
        </ng-container>

    </ng-container>


</div>