import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxsModule } from '@ngxs/store';
import { TicketTrackingState } from './store/state/ticket-tracking.state';

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        NgxsModule.forFeature([
            TicketTrackingState
        ]),
    ],
})
export class TicketTrackingModule { }