import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store, } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { Utils } from 'src/app/Utils';
import { AgentViewTicketResponseActions } from '../actions/agent-view-ticket-response-actions';
import { TicketResponseService } from '../../services/ticket-response.service';
import { AddSuccessResponseNotification } from 'src/app/core/store/actions/notifications.actions';
import { RespondToTicketPayload, SFResponseType } from '../types/respond-to-ticket-payload.interface';
import { CoreState } from 'src/app/core/store/state/core.state';
import { PopupError } from 'src/app/core/handlers/popup-error';
import { AgentActions } from '../actions/agent-action-actions';
import { AgentViewTicketState } from './agent-view-ticket.state';


interface AgentViewTicketResponseStateModel {
    loading: boolean;
}
@State<AgentViewTicketResponseStateModel>({
    name: 'sf_AgentViewTicketResponse_state',
    defaults: {
        loading: false
    }
})
@Injectable()
export class AgentViewTicketResponseState {

    @Selector()
    static isLoading(state: AgentViewTicketResponseStateModel) { return state.loading }

    constructor(private ticketResponseService: TicketResponseService,
        private store: Store) {
    }

    @Action(AgentViewTicketResponseActions.AcceptTicketInCtx)
    AcceptTicket(ctx: StateContext<AgentViewTicketResponseStateModel>) {
        const payload = this.getPayload("accept");

        return ctx.dispatch(new AgentViewTicketResponseActions.RespondToTicket(payload));
    }


    @Action(AgentViewTicketResponseActions.RejectTicketInCtx)
    RejectTicket(ctx: StateContext<AgentViewTicketResponseStateModel>) {
        const payload = this.getPayload("reject");
        const { hexId, customerEmail } = payload ?? {};

        return ctx.dispatch([
            new AgentViewTicketResponseActions.RespondToTicket(payload),
            new AgentActions.TicketRejected({ hexId, customerEmail })
        ]);
    }


    @Action(AgentViewTicketResponseActions.RespondToTicket)
    RespondToTicket(ctx: StateContext<AgentViewTicketResponseStateModel>, action: AgentViewTicketResponseActions.RespondToTicket) {
        const { payload } = action;
        const { type, hexId, customerEmail } = payload;

        ctx.patchState({ loading: true });

        return this.ticketResponseService.respondToTicket(payload)
            .pipe(
                tap({
                    next: () => ctx.dispatch(new AgentViewTicketResponseActions.RespondToTicketSuccess(type, { hexId, customerEmail })),
                    error: (e: unknown) => ctx.dispatch(new AgentViewTicketResponseActions.RespondToTicketFail(type, e))
                }));
    }


    @Action(AgentViewTicketResponseActions.RespondToTicketSuccess)
    AcceptTicketSuccess(ctx: StateContext<AgentViewTicketResponseStateModel>, action: AgentViewTicketResponseActions.RespondToTicketSuccess) {
        ctx.patchState({ loading: false });

        const { type, metaData } = action;

        if (type === "accept") {
            return ctx.dispatch([
                new AddSuccessResponseNotification({
                    success: true,
                    message: "Accepted ticket."
                }),
                new AgentActions.TicketAccepted(metaData)
            ]);
        }
    }


    @Action(AgentViewTicketResponseActions.RespondToTicketFail)
    AcceptTicketFail(ctx: StateContext<AgentViewTicketResponseStateModel>, action: AgentViewTicketResponseActions.RespondToTicketFail) {
        ctx.patchState({ loading: false });

        const { type, error } = action;
        const errorMessage = Utils.Helpers.findError(error, '');

        if (type === "accept") {
            return ctx.dispatch(new AddSuccessResponseNotification({
                success: true,
                message: `Failed to accept ticket ${errorMessage}`
            }));
        }
    }

    private getPayload(type: SFResponseType): RespondToTicketPayload {
        const agentEmail = this.store.selectSnapshot(CoreState.getAgentEmail);
        const agentTeam = this.store.selectSnapshot(CoreState.getTeamRole);
        const ticketHelper = this.store.selectSnapshot(AgentViewTicketState.getTicketHelper);

        const assignedHexId = ticketHelper?.customerTicket?.hex_id;
        const customerEmail = ticketHelper?.customerEmail;

        if (!assignedHexId) {
            throw new PopupError("No assigned ticket id was found");
        }

        return {
            type,
            hexId: assignedHexId,
            agentEmail,
            agentTeam,
            customerEmail
        }
    }


}   