import { Component, Input } from '@angular/core';

@Component({
  selector: 'sf-rain-button',
  templateUrl: './rain-button.component.html',
  styleUrls: ['./rain-button.component.scss']
})
export class RainButtonComponent {

  @Input() type: 'submit' | 'reset' | 'button' | undefined;
  @Input() width = "7rem";
  @Input() minWidth = "4rem";
  @Input() height = "2rem";
  @Input() isAcceptButton = false;
  @Input() isDeclineButton = false;
  @Input() isSolid = false;
  @Input() disabled = false;

}
