import { Pipe, PipeTransform } from '@angular/core';
import { Utils } from 'src/app/Utils';
import { DataHandler } from '../../data-handler/data-handler';

@Pipe({ name: 'calcPercentage' })
export class CalcPercentagePipe implements PipeTransform {

    transform(value: number, total: number): number {
        if (!DataHandler.isDefined(value) || !DataHandler.isDefined(total)) {
            return;
        }
        return Utils.Helpers.calcPercentage(value, total);
    }

}
