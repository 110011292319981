import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { HomeOrWorkServicesActions } from "../actions/home-or-work-services";

export type HomeOrWork = 'home' | 'work'

const getDefaults = (): SelectedHomeOrWorkServicesStateModel => {
  return {
    selectedHomeOrWorkServices: 'home'
  }
}

interface SelectedHomeOrWorkServicesStateModel {
  selectedHomeOrWorkServices: HomeOrWork
}
@State<SelectedHomeOrWorkServicesStateModel>({
  name: 'SelectedServiceTypeState',
  defaults: getDefaults()
})
@Injectable()
export class SelectedHomeOrWorkServicesState {

  @Selector()
  static getSelectedHomeOrWorkServices(state: SelectedHomeOrWorkServicesStateModel) {
    return state.selectedHomeOrWorkServices
  }

  @Action(HomeOrWorkServicesActions.Select)
  Select(ctx: StateContext<SelectedHomeOrWorkServicesStateModel>, action: HomeOrWorkServicesActions.Select ) {
    const { selectedHomeOrWorkServices } = action;
    ctx.patchState({selectedHomeOrWorkServices})
  }

}
