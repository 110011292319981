<sf-popup-modal
  [isOpen]="true"
  [hasCloseButton]="false"
  [autoClose]="false"
  [successResponse]="modalOptions.successResponse"
  [sending]="modalOptions.sending"
  [spinnerMessage]="modalOptions.spinnerMessage"
  [limitHeight]="true"
>
  <ng-container header>retention questionnaire</ng-container>

  <ng-container mainContent>
    <sf-modal-form-container [formGroup]="form">
      <ng-container>
        <sf-select
          [labelStyle]="
                labelStyle"
          label="Call Type"
          [options]="callTypes"
          marginBottom="1rem"
          (onOptionSelect)="onSelect('call_type', $event)"
          placeholder="select type of call"
          [hasError]="form.get('call_type').errors?.notSelected"
        >
        </sf-select>

        <sf-select
          *ngIf="selectedTypeOfCall=== 'Outgoing (ticket)'"
          [labelStyle]="
            labelStyle"
          label="Outgoing call status"
          [options]="outgoingCallOptions"
          marginBottom="1rem"
          (onOptionSelect)="onSelect('outgoing_call_status', $event)"
          placeholder="select outgoing call status"
          [hasError]="form.get('outgoing_call_status').errors?.notSelected"
        >
        </sf-select>
        <sf-select
          *ngIf="selectedTypeOfCall=== 'Incoming'"
          [labelStyle]="
                                      labelStyle"
          label="Incoming call status"
          [options]="incomingCallOptions"
          marginBottom="1rem"
          (onOptionSelect)="onSelect('incoming_call_status', $event)"
          placeholder="select incoming call status"
          [hasError]="form.get('incoming_call_status').errors?.notSelected"
        >
        </sf-select>

        <ng-container *ngIf="ableToContinueTheForm">
          <sf-select
            [labelStyle]="labelStyle"
            label="Product"
            [options]="productOptions"
            marginBottom="1rem"
            (onOptionSelect)="onSelect('product_type', $event)"
            placeholder="select product"
            [hasError]="form.get('product_type').errors?.notSelected"
          >
          </sf-select>
          <sf-select
            #outcomeSelect
            [labelStyle]="labelStyle"
            label="Ticket Outcome"
            [options]="outcomeOptions"
            marginBottom="1rem"
            (onOptionSelect)="onSelect('ticket_outcome', $event)"
            placeholder="select outcome"
            [hasError]="form.get('ticket_outcome').errors?.notSelected"
          >
          </sf-select>

          <sf-select
            *ngIf="selectedOutcomeOption === 'Cancellation immediate'"
            [labelStyle]="labelStyle"
            label="Reason for immediate cancellation cancellation"
            [options]="immediateCancellationReasons"
            marginBottom="1rem"
            (onOptionSelect)="onSelect('cancellation_immediate_reason', $event)"
            placeholder="select reason for immediate  cancellation"
            [hasError]="form.get('cancellation_immediate_reason').errors?.notSelected"
          >
          </sf-select>

          <sf-select
            *ngIf="selectedOutcomeOption=== 'Cancellation scheduled'"
            [labelStyle]="labelStyle"
            label="Reason for scheduled cancellation cancellation"
            [options]="scheduledCancellationReasons"
            marginBottom="1rem"
            (onOptionSelect)="onSelect('cancellation_scheduled_reason', $event)"
            placeholder="select reason for scheduled  cancellation"
            [hasError]="form.get('cancellation_scheduled_reason').errors?.notSelected"
          >
          </sf-select>

          <sf-select
            *ngIf="selectedOutcomeOption=== 'Retained: cancellation revoked by agent' || selectedOutcomeOption=== 'Retained: no cancellation scheduled'"
            [labelStyle]="labelStyle"
            label="Solution provided to retain"
            [options]="solutionProvidedToRetain"
            marginBottom="1rem"
            (onOptionSelect)="onSelect('solutions_provided_to_retain', $event)"
            placeholder="select solution provided"
            [hasError]="form.get('solutions_provided_to_retain').errors?.notSelected"
          >
          </sf-select>

          <sf-form-textarea
            *ngIf="altSolutionProvided"
            label="alternative solution provided"
            [control]="this.form.get('solutions_text')"
            placeholder="type alternative solution provided"
          ></sf-form-textarea>

          <sf-form-textarea
            label="comments"
            [control]="this.form.get('comments')"
            placeholder="comments"
          ></sf-form-textarea>

        </ng-container>

      </ng-container>
    </sf-modal-form-container>
  </ng-container>

  <ng-container buttons>
    <button
      class="rain-button default-button-size send-button solid"
      (click)="onSend()"
      [disabled]="form?.invalid || modalOptions.sending"
    >
      send
    </button>
  </ng-container>
</sf-popup-modal>
