import { Injectable, OnDestroy } from '@angular/core';
import { Store } from '@ngxs/store';
import { Subject, combineLatest, merge } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { MainWebSocketService } from 'src/app/shared/services/main-web-socket/main-web-socket.service';
import { SipPhoneService } from 'src/app/sip-phone/services/sip-phone.service';
import { SFValidators } from 'src/app/shared/functions/sf-validators';
import { SipPhoneState } from 'src/app/sip-phone/store/state/sip.state';
import { VoiceLoggingActions } from 'src/app/core/store/actions/voice-logging-actions';
import { SetLoggedOn } from '../store/actions/agent-status-actions/agent-status.actions';
import { environment } from 'src/environments/environment';
import { CoreState } from 'src/app/core/store/state/core.state';
import { AgentAction } from '../assets/agent-action.type';
import { Router } from '@angular/router';
import { InteractionTicketsState } from 'src/app/interactions/store/state/interaction-tickets.state';
import { AgentState } from '../store/state/agent.state';
import { AgentStatus } from '../assets/agent-status.type';

@Injectable({
    providedIn: 'root'
})
export class AgentTrackingService implements OnDestroy {

    private readonly ngDestroy$ = new Subject<void>();

    private _initialized = false;
    private _initialStatusSet = false;

    constructor(private store: Store,
        private router: Router,
        private mainWebsocket: MainWebSocketService,
        private sipPhoneService: SipPhoneService) { }


    initializeTracking(agentEmail: string) {
        if (this._initialized) {
            return;
        }
        this._initialized = true;

        this.listenToWebsocketChanges();
        this.listenToStatusChanges();

        this.mainWebsocket.createWSconnection(`${environment.sf_websocket_url}?email=${agentEmail}`);
    }

    private listenToWebsocketChanges() {

        merge(
            this.mainWebsocket.getWebsocketEventSubject("connected"),
            this.mainWebsocket.getWebsocketEventSubject("error")
        )
            .pipe(
                takeUntil(this.ngDestroy$)
            )
            .subscribe({
                next: () => this.setInitialStatus()
            });
    }

    private setInitialStatus() {
        if (this._initialStatusSet) {
            return;
        }
        this._initialStatusSet = true;

        this.store.dispatch(new SetLoggedOn());
    }

    private listenToStatusChanges() {

        combineLatest([
            this.store.select(CoreState.getAgentTrackingDetails),
            this.store.select(SipPhoneState.isUserAgentProcessing),
            this.sipPhoneService.hasActiveSession$
        ])
            .pipe(
                debounceTime(50),
                takeUntil(this.ngDestroy$),
            )
            .subscribe({
                next: ([trackingDetails]) => {
                    const { status, email, name, acceptingCalls, acceptingTickets, groupName, queue } = trackingDetails ?? {};
                    const { inCall, isInboundCall, registered } = this.sipPhoneService.getCurrentCallStateDetails();

                    if (!SFValidators.hasAllFields({ status, name, email })) {
                        return;
                    }

                    this.store.dispatch(new VoiceLoggingActions.LogStatus({
                        name,
                        email,
                        status,
                        acceptingCalls,
                        acceptingTickets,
                        inCall,
                        isInboundCall,
                        registered,
                        groupName,
                        queue
                    }));
                }
            })
    }

    sendAgentStatusUpdate(status: AgentStatus) {
        this.sendAgentUpdate({ status });
    }

    sendAgentActionUpdate(action: AgentAction) {
        const status = this.store.selectSnapshot(AgentState.getStatus);
        this.sendAgentUpdate({ status, action });
    }

    private sendAgentUpdate({ status, action }: { status: AgentStatus, action?: AgentAction }) {
        const { email, roles } = this.store.selectSnapshot(CoreState.getAgent);

        if (!this.mainWebsocket.isConnected || !email) {
            return;
        }

        const hexId = this.store.selectSnapshot(InteractionTicketsState.getCurrentHexId);

        this.mainWebsocket.sendData({
            action: "updateAgentStatus",
            data: {
                email,
                hex_id: hexId ?? null, //This can be null
                page: this.router.url,
                agent_status: status,
                agent_action: action ?? null,
                roles
            }
        });
    }

    ngOnDestroy(): void {
        this.ngDestroy$.next(null);
        this.ngDestroy$.complete();
    }
}