import { Injectable } from '@angular/core';
import { Action, createSelector, Selector, State, StateContext, } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { DataLoading, getDataLoadingDefaultValues } from 'src/app/shared/interfaces/data-loading.interface';
import { Utils } from 'src/app/Utils';
import { ProductService } from '../../../services/products.service';
import { ProductActions } from '../../actions/product-actions';
import { BundleOrProduct, Product, ProductType, RainProductsMap } from '../../interfaces/rain-product.interface';
import { ProductFunctions } from './assets/product-functions';


export interface ProductsStateModel extends DataLoading<Product[]> {
  productsMap: RainProductsMap;
}
@State<ProductsStateModel>({
  name: 'sf_products_state',
  defaults: {
    ...getDataLoadingDefaultValues(),
    productsMap: {
      product: [],
      bundle: [],
      addon: [],
      delivery: []
    }
  }
})
@Injectable()
export class ProductsState {

  @Selector()
  static isLoading(state: ProductsStateModel) { return state.loading }

  @Selector()
  static getState(state: ProductsStateModel) { return state }

  @Selector()
  static getProductsMap(state: ProductsStateModel) { return state.productsMap }

  @Selector()
  static getProductsAndBundles(state: ProductsStateModel): BundleOrProduct[] {
    const { product, bundle } = state.productsMap;
    return [...product ?? [], ...bundle ?? []];
  }

  static getProductsByType(productType: ProductType) {
    return createSelector([ProductsState], (state: ProductsStateModel) => {
      return state.productsMap?.[productType];
    });
  }

  static getProductImage(productId?: string) {
    return createSelector([ProductsState], (state: ProductsStateModel) => {
      const product = ProductFunctions.findProduct(productId, state.productsMap?.product);
      if (!product) {
        return Utils.Helpers.ServiceImages.default;
      }

      return Utils.Helpers.getServiceImage(product?.name);
    });
  }

  static isUpfrontProduct(productId: string) {
    return createSelector([ProductsState], (state: ProductsStateModel) => {
      return ProductFunctions.isUpfrontProduct(productId, state.productsMap?.product);
    });
  }

  static paymentType(productId: string) {
    return createSelector([ProductsState], (state: ProductsStateModel) => {
      return ProductFunctions.findProduct(productId, state.productsMap?.product)?.paymentType;
    });
  }

  static getProduct(productId: string) {
    return createSelector([ProductsState], (state: ProductsStateModel) => {
      return ProductFunctions.findProduct(productId, state.productsMap?.product);
    });
  }

  constructor(private productService: ProductService) {
  }


  @Action(ProductActions.GetAll)
  getAll(ctx: StateContext<ProductsStateModel>) {

    ctx.patchState({
      loading: true
    });

    return this.productService.getAll()
      .pipe(tap({
        next: res => ctx.dispatch(new ProductActions.GetAllSuccess(res)),
        error: (e: unknown) => ctx.dispatch(new ProductActions.GetAllFail(e))
      }));
  }


  @Action(ProductActions.GetAllSuccess)
  getAllSuccess(ctx: StateContext<ProductsStateModel>, action: ProductActions.GetAllSuccess) {
    const { products } = action.payload;

    ctx.patchState({
      data: products,
      productsMap: this.productService.getProductsMap(products),
      loaded: true,
      loading: false,
      error: null,
    });
  }



}
