import { Component, OnDestroy, OnInit } from '@angular/core';
import { Actions, ofActionSuccessful, Store } from '@ngxs/store';
import { FormHelper } from 'src/app/Utils/helpers';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { getTicketMainSubject } from './assets/ticket-subjects.function';
import { CustomerInfoSummaryService } from 'src/app/customer-info-summary-page/store/services/customer-info-summary.service';
import { DEVICE_DEFAULT_POST_TICKET_OPTIONS, NETWORK_DEFAULT_POST_TICKET_OPTIONS, BILLING_DEFAULT_POST_TICKET_OPTIONS, ONBOARDING_DEFAULT_POST_TICKET_OPTIONS, BUSINESS_OPERATIONS_DEFAULT_POST_TICKET_OPTIONS, BILLING_ACCOUNT_ARREARS_POST_TICKET_OPTIONS, BILLING_DISPUTE_POST_TICKET_OPTIONS, REFUND_DEFAULT_POST_TICKET_OPTIONS, ALLOCATION_DEFAULT_POST_TICKET_OPTIONS, PROFILE_MANAGEMENT_POST_TICKET_OPTIONS } from './assets/default-post-ticket-options.constant';
import { PostTicketModalOptions } from 'src/app/shared/services/post-ticket/post-ticket-modal-options.model';
import { PostTicketService } from 'src/app/shared/services/post-ticket/post-ticket.service';
import { ToggleModalByName } from 'src/app/core/store/actions/modal.actions';
import { InteractionTicketsState } from '../../store/state/interaction-tickets.state';
import { NWDAFConnectedSiteState } from 'src/app/customer-data-components/sim-details/store/state/nwdaf-connected-site.state';
import { filter, takeUntil } from 'rxjs/operators';
import { NWDAFConnectedSiteActions } from 'src/app/customer-data-components/sim-details/store/actions/nwdaf-connected-site-actions';
import { ChatJsonNote } from '../components/chat-json-note/assets/chat-json-note.interface';
import { SnowflakeSelectOption } from 'src/app/shared/elements/rain-forms/elements/snowflake-select/interfaces/rain-select-option.interface';
import { SimSelectors } from 'src/app/customer-data-components/sim-details/store/selectors/sim.selectors';


const FORM_KEYS = ["network", "billing", "device", "onboarding", "business_operations", "account_arrears", "billing_disputes", "refund", "allocation", "profile_management"];

type ConnectedSiteData =
    {
        live_connection?: boolean,
        enodeb_id?: number | string,
        cell_id?: number
    } |
    {
        error: string
    }

@Component({
    selector: 'sf-default-post-ticket-modal',
    templateUrl: './default-post-ticket-modal.component.html',
    styleUrls: ['./default-post-ticket-modal.component.scss']
})
export class DefaultPostTicketModalComponent extends FormHelper implements OnInit, OnDestroy {

    readonly networkSelectOptions: SnowflakeSelectOption[] = NETWORK_DEFAULT_POST_TICKET_OPTIONS;
    readonly billingSelectOptions: SnowflakeSelectOption[] = BILLING_DEFAULT_POST_TICKET_OPTIONS;
    readonly deviceSelectOptions: SnowflakeSelectOption[] = DEVICE_DEFAULT_POST_TICKET_OPTIONS;
    readonly onboardingSelectOptions: SnowflakeSelectOption[] = ONBOARDING_DEFAULT_POST_TICKET_OPTIONS;
    readonly businessOperationsSelectOptions: SnowflakeSelectOption[] = BUSINESS_OPERATIONS_DEFAULT_POST_TICKET_OPTIONS;
    readonly billingDisputesOptions: SnowflakeSelectOption[] = BILLING_DISPUTE_POST_TICKET_OPTIONS;
    readonly accountInArrearsOptions: SnowflakeSelectOption[] = BILLING_ACCOUNT_ARREARS_POST_TICKET_OPTIONS;
    readonly refundOptions: SnowflakeSelectOption[]= REFUND_DEFAULT_POST_TICKET_OPTIONS;
    readonly allocationOptions: SnowflakeSelectOption[]= ALLOCATION_DEFAULT_POST_TICKET_OPTIONS;
    readonly profileManagementOptions: SnowflakeSelectOption[]= PROFILE_MANAGEMENT_POST_TICKET_OPTIONS;


    otherOptionsSelected: { [option: string]: boolean } = {
        network_other: false,
        billing_other: false,
        device_other: false,
        onboarding_other: false,
        business_operations_other: false,
        msisdns: false,
        billing_disputes: false,
        account_in_arrears: false,
        refund: false,
        allocation:false,
        profile_management:false
    };
    msisdnOptions: SnowflakeSelectOption<{msisdn: string, id: string} | string>[];
    isAnyOptionSelected = false;

    selectedMSISDNs: {msisdn: string, id: string}[] = [];
    classificationMessage: string;
    form: FormGroup;
    connectedSiteDataMap: ConnectedSiteData = {};
    modalOptions: PostTicketModalOptions;

    constructor(private fb: FormBuilder,
        private store: Store,
        private customerInfoSummaryService: CustomerInfoSummaryService,
        private postTicketService: PostTicketService,
        private actions$: Actions) {
        super();
    }

    ngOnInit(): void {
        this.modalOptions = new PostTicketModalOptions();
        this.setValues();

        const multiSelectOptions = FORM_KEYS.reduce((obj, key) => {
            obj[key] = this.fb.control([]);
            return obj;
        }, {});

        this.form = this.fb.group({
            correct_classification: this.fb.control(false),
            msisdns: this.fb.control(''),
            network_other: this.fb.control(''),
            billing_other: this.fb.control(''),
            device_other: this.fb.control(''),
            onboarding_other: this.fb.control(''),
            business_operations_other: this.fb.control([]),

            ...multiSelectOptions
        });
        this.InitForm(this.form);

        this.store.select(NWDAFConnectedSiteState.loadingIMSIs)
            .pipe(takeUntil(this.ngDestroy$))
            .subscribe({
                next: loadingImsis => {
                    const loadingCurrentSelected = this.getCurrentIMSIList().some(imsi => loadingImsis?.includes(imsi));

                    if (loadingCurrentSelected) {
                        this.modalOptions.spinnerMessage = "working..";
                        this.modalOptions.sending = true;
                    }
                    else {
                        //Reset message to default
                        this.modalOptions.spinnerMessage = "sending..";
                        this.modalOptions.sending = false;
                    }
                }
            });

        this.actions$
            .pipe(
                ofActionSuccessful(NWDAFConnectedSiteActions.FetchByIMSISuccess),
                filter((res: NWDAFConnectedSiteActions.FetchByIMSISuccess) => this.getCurrentIMSIList()?.includes(res?.imsi)),
                takeUntil(this.ngDestroy$),
            )
            .subscribe({
                next: (res: NWDAFConnectedSiteActions.FetchByIMSISuccess) => {
                    const { imsi } = res ?? {};
                    const id = this.getServiceIdForIMSI(imsi);
                    this.setConnectedSiteDataFromStore(id, imsi);
                }
            })

    }

    setValues() {
        const selectedTicket = this.store.selectSnapshot(InteractionTicketsState.getSelectedShortTicket);
        this.classificationMessage = getTicketMainSubject(selectedTicket);
        this.msisdnOptions = this.customerInfoSummaryService.getCurrentSimSFOptions();
    }

    onSelectOptions(key: string, selectedOptions: string[]) {
        this.form.get(key).patchValue(selectedOptions);

        const otherSelected = this.checkIfHasOtherSelected(selectedOptions);
        const otherKey = `${key}_other`;
        this.otherOptionsSelected[otherKey] = otherSelected;



        if (key === "network") {
            this.otherOptionsSelected['msisdns'] = this.form.get('network').value.length > 0;
        }

        if (key === "billing") {
            this.otherOptionsSelected['billing_disputes'] = selectedOptions.includes("Bill disputes");
            this.otherOptionsSelected['billing_disputes'] ? this.addRemoveValidators('billing_disputes', true) : this.addRemoveValidators('billing_disputes', false);

            this.otherOptionsSelected['account_in_arrears'] = selectedOptions.includes("Account in Arrears")
            this.otherOptionsSelected['account_in_arrears'] ? this.addRemoveValidators('account_arrears', true) : this.addRemoveValidators('account_arrears', false);

            this.otherOptionsSelected['refund'] = selectedOptions.includes("Refund")
            this.otherOptionsSelected['refund'] ? this.addRemoveValidators('refund', true) : this.addRemoveValidators('refund', false);

            this.otherOptionsSelected['allocation'] = selectedOptions.includes("Allocation")
            this.otherOptionsSelected['allocation'] ? this.addRemoveValidators('allocation', true) : this.addRemoveValidators('allocation', false);

            this.otherOptionsSelected['profile_management'] = selectedOptions.includes("Profile management")
            this.otherOptionsSelected['profile_management'] ? this.addRemoveValidators('profile_management', true) : this.addRemoveValidators('profile_management', false);
        }

        this.addRemoveValidators(otherKey, otherSelected);

        this.isAnyOptionSelected = this.checkIfAnOptionWasSelected();
    }
    checkIfHasOtherSelected(selectedOptions: string[]) {
        return selectedOptions.includes('Other');
    }

    onSelectMSISDN(selectedMSISDNs: {msisdn: string, id: string}[]) {
        this.selectedMSISDNs = selectedMSISDNs;
        selectedMSISDNs.forEach(option => this.addConnectedSiteData(option?.msisdn, option?.id));
    }

    addConnectedSiteData(msisdn: string, id: string) {
        if (this.connectedSiteDataMap?.[msisdn]) {
            return;
        }

        const imsi = this.getIMSIforServiceId(id);
        if (!imsi) {
            this.connectedSiteDataMap[id] = { error: "No IMSI found for MSISDN" };
            return;
        }

        const hasStoreData = this.setConnectedSiteDataFromStore(id, imsi);
        if (!hasStoreData) {
            this.store.dispatch(new NWDAFConnectedSiteActions.FetchByIMSIIfNotPreset(imsi));
        }
    }

    private setConnectedSiteDataFromStore(id: string, imsi: string): boolean {
        const savedConnectionData = this.store.selectSnapshot(NWDAFConnectedSiteState.getConnectionData(imsi));
        if (!savedConnectionData) {
            return false;
        }

        const { liveConnection, enodeBId, cellId } = savedConnectionData ?? {};
        this.connectedSiteDataMap[id] = {
            live_connection: liveConnection,
            enodeb_id: enodeBId,
            cell_id: cellId
        };
        return true;
    }

    onSend() {
        const data = this.getFormattedData();
        const payload: ChatJsonNote = {
            message: "Default support post close form",
            detail: data,
            type: "default_support_form"
        }
        const hexId = this.store.selectSnapshot(InteractionTicketsState.getSelectedHexId);
        this.postTicketService.sendPostTicketPayload(
            hexId, payload, this.modalOptions, this.close.bind(this)
        );
    }

    private getFormattedData() {
        const { network, network_other, billing, billing_other, account_arrears,
            billing_disputes, refund, allocation, profile_management, device, device_other, onboarding, onboarding_other,
            business_operations, business_operations_other, correct_classification } = this.form?.getRawValue() ?? {};

        return {
            "network": [...network, `Other - ${network_other}`],
            "connected site data": this.getConnectedData(),
            "billing": [...billing, `Other - ${billing_other}`],
            "account arrears more information": account_arrears,
            "billing dispute more information": billing_disputes,
            "refund": refund,
            "allocation": allocation,
            "profile_management": profile_management,
            "device": [...device, `Other - ${device_other}`],
            "onboarding": [...onboarding, `Other - ${onboarding_other}`],
            "business operations": [...business_operations, `Other - ${business_operations_other}`],
            "classification": {
                "subject": this.classificationMessage,
                "is-correct": correct_classification
            }
        }
    }

    private addRemoveValidators(controlName: string, required: boolean) {
        const control = this.getControl(controlName);
        if (required) {
            control?.addValidators(Validators.required);
            control?.updateValueAndValidity();
        }
        else {
            control?.removeValidators(Validators.required);
            control?.patchValue(null);
        }
    }

    getConnectedData() {
        return this.selectedMSISDNs.map(option => ({ msisdn: option?.msisdn, ...this.connectedSiteDataMap?.[option?.id] }));
    }

    checkIfAnOptionWasSelected() {
        for (const key of FORM_KEYS) {
            const hasOption = (this.getControl(key)?.value as string[])?.length > 0;
            if (hasOption) {
                return true;
            }
        }
        return false;
    }

    // private getIMSIforMSISDN(msisdn: string) {
    //     return this.store.selectSnapshot(SimSelectors.getIMSIforMSISDN(msisdn));
    // }

    private getIMSIforServiceId(id: string) {
        return this.store.selectSnapshot(SimSelectors.getIMSIforServiceID(id));
    }

    private getCurrentIMSIList() {
        return this.selectedMSISDNs
            .map(option => this.getIMSIforServiceId(option?.id))
            .filter(imsi => Boolean(imsi));
    }

    private getServiceIdForIMSI(imsi: string) {
        return this.store.selectSnapshot(SimSelectors.getServiceIdforIMSI(imsi));
    }

    close() {
        this.store.dispatch(new ToggleModalByName("default_post_ticket_modal", false));
    }

    ngOnDestroy(): void {
        this.close();
        this.ngDestroy$.next(null);
        this.ngDestroy$.complete();
    }
}
