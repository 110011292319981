<ng-container *ngIf="!skipNoDataCheck && (text === '--' || text === 'no data'); else hasData">
  <sf-labelled-info [label]="label" [text]="text" [fontSize]="largeFontStyle ? largeFont: ''">
  </sf-labelled-info>
</ng-container>

<ng-template #hasData>
  <sf-labelled-info [label]="label" [fontSize]="largeFontStyle ? largeFont: ''">
    <span class="spacer" *ngIf="largeFontStyle"></span>
    <div class="content">
      <app-color-box [matTooltip]="textToolTip" matTooltipPosition="right" [largeFontStyle]="largeFontStyle"
        [inputColor]="backgroundColor ?  backgroundColor : ((text | wellnessColorIndicator: indicatorType) ?? fallbackColor)">
        {{text}}
      </app-color-box>
    </div>
  </sf-labelled-info>
</ng-template>