import { Selector } from "@ngxs/store";
import { SearchPageViewModel } from "./search-page.view-model";
import { SipPhoneState } from "src/app/sip-phone/store/state/sip.state";
import { RouteParamSelectors } from "src/app/shared/selectors/route-param.selectors";


export class SearchPageSelectors {

    @Selector([
        SipPhoneState.isUnknownIncomingCall,
        RouteParamSelectors.getQueryParamMapValue("isUnknownEmailFromAgentView")
    ])
    static getViewModel(isUnknownIncomingCall: boolean, isUnknownEmailFromAgentView: string | undefined): SearchPageViewModel {

        return {
            showTickets: isUnknownIncomingCall || !!isUnknownEmailFromAgentView
        }

    }
}