import { findError } from "../helpers";
import { GenericFunc } from "./assets/generic-func.type";
import { SafeResponse } from "./assets/safe-response";

/**
 * A simple try catch wrapper for a function
 * @param value The value to try
 * @param fn The function to attempt on the value
 * @param rawError If the error should not be converted to a string
 * @returns 
 */
export function tryFn<T, F extends GenericFunc<T>, R extends ReturnType<F>>
    (value: T, fn: F, rawError = false): typeof rawError extends true ? SafeResponse<R, any> : SafeResponse<R, string | null> {

    try {
        return new SafeResponse<R>(fn(value));
    }
    catch (e) {
        if (rawError) {
            return new SafeResponse<R, any>(null, e);
        }
        else {
            return new SafeResponse<R>(null, findError(e, null));
        }
    }

}