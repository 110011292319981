import { ACCOUNT } from "src/app/sip-phone/assets/sip-config"
import { VoiceAccessToken } from "../voice-tokens.type"


export const getVoiceHeaders = (voiceAccessToken: VoiceAccessToken) => {
    return {
        "Content-Type": "application/json",
        Authorization: `Bearer ${voiceAccessToken}`,
        "X-Account-ID": ACCOUNT
    }
}