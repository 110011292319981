<sf-popup-modal [isOpen]="true" [hasCloseButton]="false" [autoClose]="false"
    [successResponse]="modalOptions.successResponse" [sending]="modalOptions.sending"
    [spinnerMessage]="modalOptions.spinnerMessage" [limitHeight]="true">

    <ng-container header>early optimisation questionnaire</ng-container>

    <ng-container mainContent>
        <sf-modal-form-container *ngIf="{
            disableMost: !(isCallOutcomeAnswered$ | async),
            isCancelledImmediately: isCancelledImmediately$ | async
        } as formValues" [formGroup]="form">

            <sf-select [label]="callOutcomeLabel" [options]="selectOptions.callOutcome" marginBottom="1rem"
                (onOptionSelect)="onSelect($event, 'callOutcome')" placeholder="select call outcome"
                [hasError]="form.get('callOutcome').errors?.notSelected">
            </sf-select>

            <sf-select label="did the customer know that they had an issue" [options]="selectOptions.knownCustomerIssue"
                marginBottom="1rem" (onOptionSelect)="onSelect($event, 'knownCustomerIssue')"
                [isDisabled]="formValues.disableMost" placeholder="select yes or no"
                [hasError]="form.get('knownCustomerIssue').errors?.notSelected">
            </sf-select>

            <sf-select label="did the customer have an existing OPEN ticket on Snowflake"
                [options]="selectOptions.hadTicketOpen" marginBottom="1rem"
                (onOptionSelect)="onSelect($event, 'hadTicketOpen')" placeholder="select yes or no"
                [isDisabled]="formValues.disableMost" [hasError]="form.get('hadTicketOpen').errors?.notSelected">
            </sf-select>

            <sf-select label="what was the cause of the customer's low network signal"
                [options]="selectOptions.causeOfLowSignal" marginBottom="1rem"
                (onOptionSelect)="onSelect($event, 'causeOfLowSignal')" placeholder="select cause"
                [isDisabled]="formValues.disableMost" [hasError]="form.get('causeOfLowSignal').errors?.notSelected">
            </sf-select>

            <sf-select label="solution provided" [options]="selectOptions.solutionsProvided" marginBottom="1rem"
                (onOptionSelect)="onSelect($event, 'solutionsProvided')" placeholder="select solutions provided"
                [isDisabled]="formValues.disableMost" [hasError]="form.get('solutionsProvided').errors?.notSelected">
            </sf-select>

            <sf-select label="query outcome" [options]="selectOptions.outcome" marginBottom="1rem"
                (onOptionSelect)="onSelect($event, 'outcome')" placeholder="select outcome"
                [isDisabled]="formValues.disableMost" [hasError]="form.get('outcome').errors?.notSelected">
            </sf-select>

            <sf-select [label]="additionalAssistanceLabel" [options]="selectOptions.additionalAssistanceOffered"
                marginBottom="2rem" (onOptionSelect)="onSelect($event, 'additionalAssistanceOffered')"
                placeholder="select assistance offered"
                [isDisabled]="formValues.disableMost || formValues.isCancelledImmediately"
                [hasError]="form.get('additionalAssistanceOffered').errors?.notSelected">
            </sf-select>

        </sf-modal-form-container>
    </ng-container>

    <ng-container buttons>

        <sf-rain-button [isSolid]="true" [disabled]="!form?.valid || modalOptions.sending" (click)="onSend()">
            send
        </sf-rain-button>
    </ng-container>

</sf-popup-modal>