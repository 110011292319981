import { AccountActivityItem } from 'src/app/customer-data-components/account-details/sub-components/payment-history/assets/account-activity.interface';
import { SmartSubData } from 'src/app/shared/interfaces/smartsub-data.interface';
import { Utils } from 'src/app/Utils';
import { FetchCustomerPayload } from '../interfaces/fetch-customer-payload.interface';
import { Dictionary } from 'src/app/shared/interfaces/dictionary.interface';
import { SalesNestedRouteOption } from 'src/app/sales/store/interfaces/sales-path-options.type';
import { NavigationExtras, Params } from '@angular/router';
import { SFQueryParams } from 'src/app/shared/interfaces/sf-query-params.interface';

export class FetchCustomerDetails {
    static readonly type = Utils.Helpers.Type('[Customer: Fetch] Fetch customer details');
    constructor(public payload: FetchCustomerPayload) { }
}

export class FetchCustomerDetailsSuccess {
    static readonly type = Utils.Helpers.Type('[Customer: Fetch] Fetch customer details success');
    constructor(public payload: SmartSubData, public autoNavigate = true) { }
}

export class SetCustomerDetails {
    static readonly type = Utils.Helpers.Type('[Customer: Fetch] Set customer details');
    constructor(public payload: SmartSubData) { }
}

export class FetchCustomerDetailsFail {
    static readonly type = Utils.Helpers.Type('[Customer: Fetch] Fetch customer details fail');
    constructor(public payload: any) { }
}

export class SetCustomerDetailsSearchParam {
    static readonly type = Utils.Helpers.Type('[Customer: Fetch] Set customer details search param');
    constructor(public searchParam: string) { }
}

export class ResetCustomerDetails {
    static readonly type = Utils.Helpers.Type('[Customer: Reset] Reset customer details');
}

export class ReloadCustomerDetails {
    static readonly type = Utils.Helpers.Type('[Customer: Reload] Reload customer details');
    constructor(public hard_refresh = false) { }
}

export class FetchCustomerAccountActivity {
    static readonly type = Utils.Helpers.Type('[Customer: Fetch] Fetch customers account activities');
    constructor(public payload?: string) { }
}
export class FetchCustomerAccountActivitySuccess {
    static readonly type = Utils.Helpers.Type('[Customer: Fetch] Fetch customers account activities success');
    constructor(public payload: AccountActivityItem[]) { }
}
export class FetchCustomerAccountActivityFail {
    static readonly type = Utils.Helpers.Type('[Customer: Fetch] Fetch customers account activities fail');
    constructor(public payload: any) { }
}

export class FetchDifferentCustomerDetails {
    static readonly type = Utils.Helpers.Type('[Customer: Fetch] Fetch a different customers details');
    constructor(public searchParam: string, public queryParams?: SFQueryParams, public navigationExtras?: NavigationExtras) { }
}

export class SetMaxCancelledServiceDaysShown {
    static readonly type = Utils.Helpers.Type('[Customer: Services] Set the max days shown for the filtered services');
    constructor(public maxDays: number) { }
}

export class SetMaxOrderDaysShown {
    static readonly type = Utils.Helpers.Type('[Customer: Orders] Set the max days shown for the filtered orders');
    constructor(public maxDays: number) { }
}

export class GotoSalesForKnownCustomer {
    static readonly type = Utils.Helpers.Type("[Customer Info Summary] goto sales for known customer");
    constructor(public path: SalesNestedRouteOption, public extraActions: Dictionary[] = [], public queryParams?: SFQueryParams | Params | undefined) { }
}

export class CustomerInfoSummaryDestroyed {
    static readonly type = Utils.Helpers.Type("[Customer Info Summary] destroyed");
}

