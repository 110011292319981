import { Observable, of, throwError, timer } from "rxjs";
import { catchError, delay, mergeMap, retryWhen, switchMapTo } from "rxjs/operators";


export const RETRY_TOPUP_OPTIONS = {
    initialDelay: 0,
    delayInterval: 3000,
    retries: 5
};
type RetryTopupOptions = typeof RETRY_TOPUP_OPTIONS;


export type RetryPredicate = <E = any>(error: E, index: number) => boolean;
const defaultRetryPredicate = (error: any) => error?.status === 404;


export interface RetryWhenPayload {
    retryPredicate?: RetryPredicate;
    options?: RetryTopupOptions;
}

export const customRetryWhen = <T>(payload?: RetryWhenPayload) => {
    const { initialDelay, delayInterval, retries } = payload?.options ?? RETRY_TOPUP_OPTIONS;
    const retryPredicate = payload?.retryPredicate ?? defaultRetryPredicate;

    return (source$: Observable<T>) =>
        timer(initialDelay).pipe(
            switchMapTo(
                source$.pipe(
                    catchError(error => throwError(error)),
                    retryWhen((notifier) =>
                        notifier.pipe(
                            delay(delayInterval),
                            mergeMap((error, index) => {
                                if (index < retries && retryPredicate(error, index)) {
                                    return of(null);
                                }
                                return throwError(error);
                            })
                        )
                    )
                )
            )
        );
}