import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { SmartSubData } from '../interfaces/smartsub-data.interface';
import { HttpClient } from '@angular/common/http';
import { DEFAULT_HTTP_OPTIONS } from 'src/app/constants';
import { map, switchMap, tap } from 'rxjs/operators';
import { SFValidators } from '../functions/sf-validators';
import { Utils } from 'src/app/Utils';
import { Observable } from 'rxjs';
import { SimResource } from '../interfaces/sim-resource.interface';

type SmartsubDataWithoutResources = Omit<SmartSubData, "resources">;


@Injectable({
  providedIn: 'root'
})
export class CustomerDataService {

  constructor(private http: HttpClient) {
  }

  private getServiceResources(userId: string) {
    const url = `${environment.main_proxy}/customer-info-service/resource/user_id/${userId}`;
    return this.http.get<{ result: { resource_list: SimResource[] } }>(url, DEFAULT_HTTP_OPTIONS);
  }

  private patchInResources(payload: SmartsubDataWithoutResources): Observable<SmartSubData> {

    return this.getServiceResources(payload?.user?.id)
      .pipe(
        Utils.Helpers.mapToSuccessResult(),
        map(successResult => {
          const { resource_list } = successResult?.data?.result ?? {};

          return {
            ...payload,
            resources: resource_list ?? []
          }
        })
      );
  }

  getSmartsubData(query: string) {
    const url = `${environment.smartSubApi}/data/help/search/${query}`;
    return this.http.get<SmartsubDataWithoutResources>(url, DEFAULT_HTTP_OPTIONS)
      .pipe(
        tap({
          next: res => {
            const hasError = SFValidators.hasProperty((res as any)?.data, "error") || SFValidators.hasProperty(res, "error");

            if (hasError) {
              const errorMessage = Utils.Helpers.findError(res, "Failed to fetch data.");
              throw new Error(errorMessage);
            }

          }
        }),
        switchMap(res => this.patchInResources(res))
      )
  }

  getWrappedSmartsubDataAsPromise(query: string) {
    return this.getSmartsubData(query)
      .pipe(Utils.Helpers.mapToSuccessResult())
      .toPromise();
  }

}
