import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { Subject } from 'rxjs';
import { SnowflakeSelectOption } from '../../../shared/elements/rain-forms/elements/snowflake-select/interfaces/rain-select-option.interface';
import { DetailedPageChoice } from '../../assets/detailed-page-choice.type';
import { DetailedPageOptions } from '../../assets/detailed-page-options.interface';
import { CustomerInfoState } from '../state/customer-info.state';
import { Navigate } from '@ngxs/router-plugin';
import { SALES_PORTAL_ROUTE } from '../../../constants';
import { PopupError } from '../../../core/handlers/popup-error';
import { FetchCustomerDetails, SetCustomerDetails } from '../actions/customer.actions';
import { InteractionTicketsState } from '../../../interactions/store/state/interaction-tickets.state';
import { Dictionary } from '../../../shared/interfaces/dictionary.interface';
import { navActions } from '../../../core/store/actions/nav-actions';
import { InteractionTicketActions } from '../../../interactions/store/actions/interaction-ticket-actions';
import { SalesNestedRouteOption } from '../../../sales/store/interfaces/sales-path-options.type';
import { SetIsKnownCustomer } from '../../../sales/store/action/sales-setting-actions/sales-setting.actions';
import { Params } from '@angular/router';
import { FilteredServiceSelectors } from '../selectors/filtered-service.selectors';
import { SetRainOneSelected, SetSelectedBundleId, SetWorkProductSelected } from 'src/app/sales/store/action/sales-cart-actions/sales-cart.actions';
import { SalesCartActions } from 'src/app/sales/store/action/sales-cart-actions';
import { SalesCartState } from 'src/app/sales/store/state/sales-cart.state';
import { ProductCatalogState } from 'src/app/core/store/state/product-state/product-catalog.state';
import { CustomerInfoSelector } from '../selectors/customer-info.selector';


@Injectable({
    providedIn: 'root'
})
export class CustomerInfoSummaryService {

    readonly showDetailedPage$ = new Subject<DetailedPageOptions>();

    constructor(private store: Store) {
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    showDetailedPage(choice: DetailedPageChoice, routerParams?: any) {
        this.showDetailedPage$.next({ choice, routerParams });
    }

    getCurrentSimSFOptions(): SnowflakeSelectOption<{msisdn: string, id: string}>[] {
        const services = this.store.selectSnapshot(FilteredServiceSelectors.getAgeFilteredServices);
        return services.map(service => {
            return { label: `${service?.name} - ${service?.service_reference}`, value: {msisdn: service?.service_reference, id: service?.id} }
        });
    }

    fetchCustomerInfoIfNotPresent(query: string) {
        const loadedOrLoading = this.store.selectSnapshot(CustomerInfoState.isLoaded)
            || this.store.selectSnapshot(CustomerInfoState.isLoading);

        if (!loadedOrLoading) {
            this.store.dispatch(new FetchCustomerDetails({
                value: query,
                config: {
                    loader: true
                }
            }));
        }
    }

    //TODO: improve this using queryParameters
    gotoCart(pathSegment: SalesNestedRouteOption, extraActions: Dictionary[] = [], queryParams?: Params | undefined) {
        const { isWork } = queryParams ?? {}
        const smartsubData = this.store.selectSnapshot(CustomerInfoState.getSmartsubData);
        const ticket = this.store.selectSnapshot(InteractionTicketsState.getAssignedShortTicket);
        const url = `${SALES_PORTAL_ROUTE}/${pathSegment}`;


        if (!smartsubData) {
            throw new PopupError("Can't navigate. No customer details were found.");
        }

        const actions: Dictionary[] = [
            ...extraActions,
            new SetIsKnownCustomer(true),
            new navActions.AddQueueAction({
                action: new SetCustomerDetails(smartsubData),
                routerSegment: url
            })
        ]

        if (isWork) {
            actions.push(
                new SetWorkProductSelected(!!isWork)
            )
    }

        if (ticket?.hex_id) {

            actions.push(
                new navActions.AddQueueAction({
                    action: new InteractionTicketActions.UpdateTicketAndSetAsAssigned(ticket),
                    routerSegment: url
                })
            );

        }

        // actions.push(new Navigate([url], queryParams, {queryParamsHandling: 'merge'}));
        actions.push(new Navigate([url]));

        this.store.dispatch(actions);
    }

}
