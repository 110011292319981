import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Subject } from 'rxjs';
import { take, takeUntil, tap } from 'rxjs/operators';
import { VoiceReportingService } from '../../services/voice-reporting.service';
import { VoiceLoggingActions } from '../actions/voice-logging-actions';


interface VoiceLoggingStateModel {
    loading: boolean;
}
@State<VoiceLoggingStateModel>({
    name: 'sf_voice_logging_state',
    defaults: {
        loading: false
    }
})
@Injectable()
export class VoiceLoggingState {

    @Selector()
    static isLoading(state: VoiceLoggingStateModel) { return state.loading }

    private readonly _cancel$ = new Subject<null>();

    constructor(private agentLoggingService: VoiceReportingService) {
    }

    @Action(VoiceLoggingActions.LogStatus)
    LogStatus(ctx: StateContext<VoiceLoggingStateModel>, action: VoiceLoggingActions.LogStatus) {
        const { payload } = action;

        this._cancel$.next(null);
        ctx.patchState({ loading: true });

        return this.agentLoggingService.logStatus(payload)
            .pipe(
                tap({
                    next: () => ctx.dispatch(new VoiceLoggingActions.LogStatusSuccess()),
                    error: () => ctx.dispatch(new VoiceLoggingActions.LogStatusFail())
                }),
                takeUntil(this._cancel$.pipe(take(1)))
            );
    }

    @Action(VoiceLoggingActions.LogStatusSuccess)
    LogStatusSuccess(ctx: StateContext<VoiceLoggingStateModel>) {
        ctx.patchState({ loading: false });
    }


    @Action(VoiceLoggingActions.LogStatusFail)
    LogStatusFail(ctx: StateContext<VoiceLoggingStateModel>) {
        ctx.patchState({ loading: false });
    }

}       