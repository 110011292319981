import { Injectable } from "@angular/core";
import { Action, createSelector, Selector, State, StateContext } from "@ngxs/store";
import { DataHandler } from "src/app/shared/data-handler/data-handler";
import { getModalStartingData, SfModalName, SF_MODAL_DEFAULTS, ModalOptions } from "../../../customer-info-summary-page/store/types/modal-name.type";
import { ToggleModalByName } from "../actions/modal.actions";



export interface CustomerInfoModalStateModel {
    is_open_options: ModalOptions,
    modal_data: { [key in SfModalName]?: unknown }
}

@State<CustomerInfoModalStateModel>({
    name: 'customer_modal_states',
    defaults: {
        is_open_options: { ...SF_MODAL_DEFAULTS },
        modal_data: getModalStartingData()
    }
})
@Injectable()
export class ModalState {

    @Selector()
    static getOpenOptions(state: CustomerInfoModalStateModel): ModalOptions { return state.is_open_options; }

    static isModalOpen(modalName: SfModalName) {
        return createSelector([ModalState], (state: CustomerInfoModalStateModel) => {
            return state.is_open_options[modalName];
        });
    }

    static getModalData<T>(modalName: SfModalName) {
        return createSelector([ModalState], (state: CustomerInfoModalStateModel) => {
            return state.modal_data?.[modalName] as T;
        });
    }

    @Action(ToggleModalByName)
    toggleModalByName(ctx: StateContext<CustomerInfoModalStateModel>, action: ToggleModalByName) {
        const { modalName, open, data } = action;

        if (DataHandler.isDefined(open)) {
            this.setIsModalOpen(ctx, action.modalName, open);
        }
        else {
            this.toggleOpen(ctx, modalName);
        }
        this.updateData(ctx, modalName, data);
    }

    private setIsModalOpen(ctx: StateContext<CustomerInfoModalStateModel>, modalName: SfModalName, isOpen: boolean) {
        const is_open_options = Object.assign({}, ctx.getState().is_open_options);
        is_open_options[modalName] = isOpen;

        ctx.patchState({
            is_open_options
        });
    }

    private toggleOpen(ctx: StateContext<CustomerInfoModalStateModel>, modalName: SfModalName) {
        const is_open_options = Object.assign({}, ctx.getState().is_open_options);
        is_open_options[modalName] = !is_open_options[modalName];

        ctx.patchState({
            is_open_options
        });
    }

    private updateData(ctx: StateContext<CustomerInfoModalStateModel>, modalName: SfModalName, data: any) {
        const state = ctx.getState();
        const dataCopy = DataHandler.createDeepCopy(state.modal_data);
        const isOpen = state.is_open_options?.[modalName];

        dataCopy[modalName] = isOpen ? data : null;
        ctx.patchState({
            modal_data: dataCopy
        });
    }

}
