

export const SIM_TYPE_CODES = {
    physicalSIM: "30",
    eSIM: "32",
    iSIM: "00"
} as const;

type SimTypeCodeMap = typeof SIM_TYPE_CODES;
type Keys = keyof SimTypeCodeMap;

export type SimTypeCode = SimTypeCodeMap[Keys];

