
import { Pipe, PipeTransform } from '@angular/core';
import { CallbackStatus } from '../../../store/types/callback-status.type';

const CALLBACK_STATUS_MAP: { [key in CallbackStatus]: string } = {
    INITIATED: "created",
    PENDING: "pending agent response",
    ACKNOWLEDGED: "acknowledged"
}


@Pipe({ name: 'formatCallbackStatus' })
export class FormatCallbackStatusPipe implements PipeTransform {
    transform(value: CallbackStatus): string {
        if (!value) {
            return null;
        }

        return CALLBACK_STATUS_MAP[value];
    }
}