import { Utils } from "src/app/Utils";
import { AddNotificationPayload } from "../../types/agent-notification.type";


export class AddNotification {
    static readonly type = Utils.Helpers.Type("[Agent Notification] Add");
    constructor(public payload: AddNotificationPayload) { }
}

export class RemoveNotification {
    static readonly type = Utils.Helpers.Type("[Agent Notification] Remove");
    constructor(public id: string) { }
}

export class MarkAsViewed {
    static readonly type = Utils.Helpers.Type("[Agent Notification] Mark as viewed");
    constructor(public id: string) { }
}

export class SetOpen {
    static readonly type = Utils.Helpers.Type("[Agent Notification] Set Open");
    constructor(public open: boolean) { }
}

export class CloseAll {
    static readonly type = Utils.Helpers.Type("[Agent Notification] Close all notifications");
}
