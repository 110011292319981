import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DynamicFormInputComponent } from './partials/form-input/form-input.component';
import { FormComponent } from './component/form.component';
import { DynamicFormDirective } from './directives/dynamic-form.directive';
import { ReactiveFormsModule } from '@angular/forms';
import { InputElementNumberComponent } from './partials/input-element-number/form-input-number.component';
import { SliderComponent } from './partials/slider/slider.component';
import { RainFormsModule } from '../rain-forms/rain-forms.module';
import { DynamicFormTextareaComponent } from './partials/form-textarea/form-textarea.component';
import { DynamicFormCheckboxComponent } from './partials/form-checkbox/form-checkbox.component';
import { DynamicFormBtnComponent } from './partials/form-btn/dynamic-form-btn.component';
import { DynamicFormSelectComponent } from './partials/snowflake-select/snowflake-select.component';
import { SelectComponent } from './partials/select/select.component';
import { DynamicFormDatepickerComponent } from './partials/form-datepicker/form-datepicker.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { OwlDateTimeModule, OWL_DATE_TIME_FORMATS } from '@danielmoncada/angular-datetime-picker';
import { OwlMomentDateTimeModule } from '@danielmoncada/angular-datetime-picker-moment-adapter';
import { MY_MOMENT_FORMATS } from '../rain-forms/formatters/custom-date.format';

@NgModule({
	declarations: [
		DynamicFormInputComponent,
		DynamicFormTextareaComponent,
		FormComponent,
		DynamicFormDirective,
		InputElementNumberComponent,
		SliderComponent,
		DynamicFormCheckboxComponent,
		DynamicFormBtnComponent,
		DynamicFormSelectComponent,
		SelectComponent,
		DynamicFormDatepickerComponent
	],
	imports: [
		CommonModule,
		ReactiveFormsModule,
		RainFormsModule,
		MatDatepickerModule,
		MatNativeDateModule,
		MatFormFieldModule,
		MatInputModule,
		OwlDateTimeModule,
		OwlMomentDateTimeModule,
	],
	entryComponents: [
		DynamicFormInputComponent,
		DynamicFormTextareaComponent,
		InputElementNumberComponent,
		SliderComponent,
		DynamicFormCheckboxComponent,
		DynamicFormBtnComponent,
		DynamicFormSelectComponent,
		SelectComponent,
		DynamicFormDatepickerComponent
	],
	exports: [
		FormComponent,
		InputElementNumberComponent,
		SliderComponent,
		DynamicFormDatepickerComponent
	],
	providers: [
		{ provide: OWL_DATE_TIME_FORMATS, useValue: MY_MOMENT_FORMATS },
	],
})
export class SFDynamicFormsModule { }
