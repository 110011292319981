import { Selector } from "@ngxs/store";
import { CustomerOrder } from "src/app/customer-data-components/customer-order/customer-order-card/assets/customer-order.interface";
import { SimProductSelector } from "src/app/customer-data-components/sim-details/store/selectors/sim-product.selectors";
import { ProductCatalogState } from "src/app/core/store/state/product-state/product-catalog.state";
import { CatalogSimProduct } from "src/app/core/store/interfaces/product-catalog.interfaces";
import { CUSTOMER_SERVICE_STATUS } from "src/app/shared/constants/service-status.constant";
import { SFValidators } from "src/app/shared/functions/sf-validators";
import { ExtendedServiceSelectors, MappedServicesByOrderId } from "./extended-service.selector";
import { AllOrderSelectors } from "./all-order.selectors";

export class OrderProductSelector {

    @Selector([
        AllOrderSelectors.getOrders,
        ExtendedServiceSelectors.getMappedServicesByOrderId,
        ProductCatalogState.getProductsByType("sim")
    ])
    static hasActiveRainOneOrderOrService(orders: CustomerOrder[], mappedServices: MappedServicesByOrderId, catalogSimProducts: CatalogSimProduct[]) {

        for (const order of orders ?? []) {

            const matchingService = mappedServices?.[order?.id];
            const { product_id, status } = matchingService ?? {};
            const isCancelledService = SFValidators.isDefined(status) && status === CUSTOMER_SERVICE_STATUS["Service Canceled"];

            const isCancelledOrRedeliveredOrder = order?.status === "Order Cancelled" || order?.status === "Redelivered"

            if (isCancelledService || isCancelledOrRedeliveredOrder) {
                //Skip the rest of the check for cancelled services and orders
                continue;
            }

            const isRainOneService = product_id
                ? SimProductSelector.isRainOneProduct(product_id)(catalogSimProducts)
                : false;

            if (isRainOneService) {
                return true;
            }

            for (const orderLine of order?.order_lines ?? []) {

                const isRainOneProduct = SimProductSelector.isRainOneProduct(orderLine?.product_id)(catalogSimProducts);
                if (isRainOneProduct) {
                    return true;
                }

            }
        }

        return false;

    }


}