import { Component, Input } from '@angular/core';
import { SuccessLoaderStatus } from './success-loader-status.type';

@Component({
  selector: 'sf-success-loader',
  templateUrl: './success-loader.component.html',
  styleUrls: ['./success-loader.component.scss']
})
export class SuccessLoaderComponent {

  @Input() boxSize: "4rem";
  @Input() size = "3.5rem";
  @Input() step: number | string = 1;
  @Input() status: SuccessLoaderStatus = "not-started";
  @Input() stepMessage: string;
  @Input() errorMessage?: string;

}
