import { Dictionary } from "../../interfaces/dictionary.interface";

type TransformerFn<T> = (v: T) => string;
type MatchType = "partial" | "full";


export const filterSearch = <T extends Dictionary, Filter extends { [Key in keyof T]?: any }>
    (data: T[], filterObj: Filter, matchType: MatchType = "full", stringifyCompare = false) =>
    data.filter(stringifyCompare ? stringifyMatch(filterObj, matchType) : match(filterObj, matchType));


export const match = <T extends Dictionary, Filter extends { [Key in keyof T]?: any }>
    (filterObj: Filter, matchType: MatchType = "full") =>
    (item: T) => matcher(
        filterObj,
        item,
        value => (value as Record<string, unknown> | string | number)?.toString(),
        matchType
    )


export const stringifyMatch = <T extends Dictionary, Filter extends { [Key in keyof T]?: any }>
    (filterObj: Filter, matchType: MatchType = "full") =>
    (item: T) => matcher(filterObj, item, JSON.stringify, matchType)


const matcher = <T extends Dictionary, Filter extends { [Key in keyof T]?: any }, TFn extends TransformerFn<T[keyof T] | Filter[keyof T]>>
    (filterObj: Filter, item: T, transformerFn: TFn, matchType: MatchType = "full") => {

    const keys = Object.keys(filterObj);
    if (!keys.length) {
        //Return true if there is no filter to apply
        return true;
    }

    for (const key of keys) {
        const itemValue = transformerFn(item?.[key]);
        const filterValue = transformerFn(filterObj[key as keyof T]);

        const comparisonFn = matchType === "full"
            ? () => itemValue === filterValue
            : () => itemValue?.includes(filterValue)


        if (!comparisonFn()) {
            return false;
        }
    }
    return true;
}