<ul>
  <li class="label" [style.font-size]="fontSize">
    {{label}}
  </li>
  <li class="filler">
    <span *ngIf="text; else customContent" class="text-blue" [class.pointer]="textToolTip"
      [style.color]="indicateWellness ? (text | wellnessColorIndicator: indicatorType) : textColor"
      [matTooltip]="textToolTip" matTooltipPosition="right">
      {{text}}
    </span>
    <ng-template #customContent>
      <ng-content></ng-content>
    </ng-template>
  </li>
</ul>