import { Injectable } from '@angular/core';
import { Action, State, StateContext, Selector } from '@ngxs/store';
import { defer, from } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AllTicketsService } from 'src/app/features/pages/ticket/services/all-tickets.service';
import { SearchService } from 'src/app/search-page/search.service';
import { CustomerTicketHelper } from 'src/app/shared/customer-ticket/customer-ticket-helper';
import { TICKET_STATES } from 'src/app/shared/customer-ticket/ticket-event-handler/assets/ticket.constants';
import { IncomingCallService } from '../../incoming-call-modal/incoming-call.service';
import { SipPhoneActions } from '../actions/sip-phone-actions';
import { VoiceCampaignActions } from '../actions/voice-campaign-actions';
import { VoiceTicketActions } from '../actions/voice-ticket-actions';
import { CallTicketEventName } from '../interfaces/call-ticket-event-names.interface';

interface VoiceCampaignStateModel {
    inCampaignCall: boolean;
}
@State<VoiceCampaignStateModel>({
    name: 'sf_voice_campaign_state',
    defaults: {
        inCampaignCall: false
    }
})
@Injectable()
export class VoiceCampaignState {

    @Selector()
    static inCampaignCall(state: VoiceCampaignStateModel) { return state.inCampaignCall }


    constructor(private allTicketsService: AllTicketsService,
        private searchService: SearchService,
        private incomingCallService: IncomingCallService) {
    }


    @Action(VoiceCampaignActions.SetupTicketFlow)
    setupTicketFlow(ctx: StateContext<VoiceCampaignStateModel>, action: VoiceCampaignActions.SetupTicketFlow) {
        const { payload } = action;
        const ticketId = payload['Q-Ticketid'];

        ctx.dispatch(new VoiceTicketActions.SetCallTicketSubject("rain support outgoing call"));
        ctx.patchState({
            inCampaignCall: true
        })

        if (!ticketId) {
            return ctx.dispatch(new VoiceCampaignActions.NoMatchingTicketId(payload));
        }

        return this.allTicketsService.getTicketById(ticketId)
            .pipe(
                tap({
                    next: res => {
                        if (res?.data) {
                            return ctx.dispatch(new VoiceCampaignActions.TicketIdMatches(res.data, payload.customerNumber));
                        }
                        return ctx.dispatch(new VoiceCampaignActions.NoMatchingTicketId(payload));
                    },
                    error: () => ctx.dispatch(new VoiceCampaignActions.NoMatchingTicketId(payload))
                })
            );
    }


    @Action(VoiceCampaignActions.TicketIdMatches)
    ticketIdMatches(ctx: StateContext<VoiceCampaignStateModel>, action: VoiceCampaignActions.TicketIdMatches) {
        const { ticket, customerNumber } = action;
        const { state_id } = ticket ?? {};

        const email = new CustomerTicketHelper(ticket).customerEmail;
        const found = Boolean(email);
        const isClosed = TICKET_STATES.CLOSED === state_id;
        const message: CallTicketEventName = isClosed ? "dialer ticketID matched but ticket closed" : "dialer ticketID matched";

        ctx.dispatch(new VoiceTicketActions.SetCallTicketEventName(message));
        this.incomingCallService.navigateBasedOnIdentification(found, email ?? customerNumber);
    }

    @Action(VoiceCampaignActions.NoMatchingTicketId)
    noMatchingTicketId(ctx: StateContext<VoiceCampaignStateModel>, action: VoiceCampaignActions.NoMatchingTicketId) {
        const { customerNumber } = action.payload;

        const foundNavigation = (found: boolean) => {
            ctx.dispatch(new VoiceTicketActions.SetCallTicketEventName(
                found ? "dialer lead matched" : "dialer lead did not match"
            ));
            this.incomingCallService.navigateBasedOnIdentification(found, customerNumber);
        }

        return defer(() => from(this.searchService.checkIfOnSmartsub(customerNumber)))
            .pipe(
                tap({
                    next: found => foundNavigation(found),
                    error: () => foundNavigation(false)
                })
            )
    }

    @Action(SipPhoneActions.CallSessionEnded)
    callSessionEnded(ctx: StateContext<VoiceCampaignStateModel>, action: SipPhoneActions.CallSessionEnded) {
        const { inCampaignCall } = ctx.getState();
        const isIncomingCall = action.callType === "incoming";

        if (inCampaignCall && isIncomingCall) {
            ctx.dispatch(new VoiceCampaignActions.CampaignCallEnded());
        }

        ctx.patchState({
            inCampaignCall: false
        });
    }



}