import { Utils } from "src/app/Utils";
import { Callback, ShowNotificationForCallbackPayload } from "../../types/callback-response.interface";
import { PageResult } from "src/app/shared/interfaces/page-result.interface";
import { PageSearchRequest } from "src/app/shared/interfaces/page-search-request.interface";
import { CallbackSearchCriteria } from "../../types/callback-search-criteria.interface";
import { LoaderType } from "src/app/shared/types/loader.type";


export type FetchCallbackRequest = PageSearchRequest<CallbackSearchCriteria>;


export class FetchInCtx {
    static readonly type = Utils.Helpers.Type("[Agent Callback] Fetch in Ctx");
}

export class Fetch {
    static readonly type = Utils.Helpers.Type("[Agent Callback] Fetch");
    constructor(public loader: LoaderType, public payload: FetchCallbackRequest) { }
}

export class FetchSuccess {
    static readonly type = Utils.Helpers.Type("[Agent Callback] Fetch success");
    constructor(public payload: PageResult<Callback>) { }
}

export class FetchFail {
    static readonly type = Utils.Helpers.Type("[Agent Callback] Fetch fail");
    constructor(public error: unknown) { }
}

export class FetchNextPage {
    static readonly type = Utils.Helpers.Type("[Agent Callback] Fetch next page");
}

export class FetchNextPageFail {
    static readonly type = Utils.Helpers.Type("[Agent Callback] Fetch next page fail");
    constructor(public error: unknown) { }
}

//TODO:
export class CheckNotificationTime {
    static readonly type = Utils.Helpers.Type("[Agent Callback] Check the notification time on the callbacks");
}

export class ShowNotificationForCallbacks {
    static readonly type = Utils.Helpers.Type("[Agent Callback] Snow notification for callbacks");
    constructor(public payload: ShowNotificationForCallbackPayload[]) { }
}

export class Refresh {
    static readonly type = Utils.Helpers.Type("[Agent Callback] Refresh in Ctx");
}

export class Clear {
    static readonly type = Utils.Helpers.Type("[Agent Callback] clear");
}


