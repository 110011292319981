import { Injectable } from '@angular/core';
import { State, Selector, Store, Action, StateContext } from '@ngxs/store';
import { SuccessResponse } from 'src/app/shared/success-response-handler/success-response.model';
import { Utils } from 'src/app/Utils';
import { SFNotification } from '../../notifiactions/interfaces/notifiactions.interface';
import { AddInfoNotification, AddNotification, AddSuccessResponseNotification, RemoveNotification } from '../actions/notifications.actions';


export interface NotificationsStateModel {
    notifications: {[id: number]: SFNotification}
}
@State<NotificationsStateModel>({
    name: 'notifications',
    defaults: {
        notifications: null
    }
})
@Injectable()
export class NotificationsState {

    constructor(
        private store: Store
        ) { }

    @Selector()
    static getAllNotifactions(state: NotificationsStateModel) { return Utils.Mappers.FromHashMap(state.notifications) }


    @Action(AddNotification)
    addNotification(ctx: StateContext<NotificationsStateModel>, action: AddNotification) {
        const state = ctx.getState();
        const payload = action.payload;
        const cNotifications = Utils.Mappers.FromHashMap<SFNotification>(state.notifications) || new Array<SFNotification>() as SFNotification[];
        const lastId = cNotifications.length > 1 ? cNotifications.pop().id : 1;

        const n = Object.assign({id: lastId}, payload);
        cNotifications.push(n);

        ctx.patchState({
            notifications: Utils.Mappers.ToHashMap(cNotifications, state.notifications || {}, 'id')
        });
    }

    @Action(RemoveNotification)
    removeNotification(ctx: StateContext<NotificationsStateModel>, action: RemoveNotification) {
        const state = ctx.getState();
        const payload = action.payload;
        const cNotifications = Utils.Mappers.FromHashMap<SFNotification>(state.notifications);
        const parsedNotifications = cNotifications.filter((n) => n !== payload);

        ctx.patchState({
            notifications: Utils.Mappers.ToHashMap(parsedNotifications, state.notifications, 'id')
        });
    }

    @Action(AddSuccessResponseNotification)
    addNotificationSuccessResponse(ctx: StateContext<NotificationsStateModel>, action: AddSuccessResponseNotification) {
        const successResponse: SuccessResponse = action.successResponse;
        const isWarning = action.isWarning;
        const success = successResponse.success;

        const defaultType = isWarning ? 1 : 2;
        const defaultFailMessage = isWarning ? "WARNING" : "ERROR";

        this.store.dispatch(new AddNotification(
          {
            type: success ? 0 : defaultType,
            title: success ? 'SUCCESS' : defaultFailMessage,
            message: successResponse.message
          }));

    }


    @Action(AddInfoNotification)
    addInfoNotification(ctx: StateContext<NotificationsStateModel>, action: AddInfoNotification) {
        ctx.dispatch(new AddNotification(
          {
            type:3,
            title: action.title,
            message: action.message
          }));
    }

}
