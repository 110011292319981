import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { AddSuccessResponseNotification } from 'src/app/core/store/actions/notifications.actions';
import { CoreState } from 'src/app/core/store/state/core.state';
import { TICKET_STATES } from 'src/app/shared/customer-ticket/ticket-event-handler/assets/ticket.constants';
import { CamundaService } from 'src/app/shared/customer-ticket/ticket-event-handler/camunda.service';
import { Utils } from 'src/app/Utils';
import { ChatJsonNote } from '../../chat-interaction/components/chat-json-note/assets/chat-json-note.interface';
import { EscalationActions } from '../actions/escalation-actions';
import { EscalationPayload } from '../interfaces/escalation-payload.interface';


interface EscalationStateModel {
    loading: boolean;
}
@State<EscalationStateModel>({
    name: 'sf_escalation_state',
    defaults: {
        loading: false
    }
})
@Injectable()
export class EscalationState {

    @Selector()
    static escalating(state: EscalationStateModel) { return state.loading }

    constructor(private camundaService: CamundaService,
        private store: Store) {
    }

    @Action(EscalationActions.Escalate)
    Escalate(ctx: StateContext<EscalationStateModel>, action: EscalationActions.Escalate) {
        const { hex_id, agent_group, detail } = action?.payload;
        const email = this.store.selectSnapshot(CoreState.getAgentEmail);

        if (!email) {
            return ctx.dispatch(new EscalationActions.EscalateFail("Agent email is missing."));
        }

        const payload: ChatJsonNote = {
            message: "Escalation form",
            detail,
            type: "escalations_form"
        }
        const comment = JSON.stringify(payload);

        const escalationPayload: EscalationPayload = {
            ticket_id: BigInt(hex_id).toString(),
            hex_id,
            agent_group,
            comment,
            state_id: TICKET_STATES.PENDING,
            related_entity: {
                email
            }
        }

        ctx.patchState({ loading: true });

        return this.camundaService.sendPayloadV2<EscalationPayload>(escalationPayload, "escalate")
            .pipe(tap({
                next: res => ctx.dispatch(new EscalationActions.EscalateSuccess(res)),
                error: (e: unknown) => ctx.dispatch(new EscalationActions.EscalateFail(e))
            }));
    }


    @Action(EscalationActions.EscalateSuccess)
    EscalateSuccess(ctx: StateContext<EscalationStateModel>) {
        ctx.patchState({ loading: false });

        return ctx.dispatch(new AddSuccessResponseNotification({
            success: true,
            message: "Successfully escalated ticket"
        }));
    }


    @Action(EscalationActions.EscalateFail)
    EscalateFail(ctx: StateContext<EscalationStateModel>, action: EscalationActions.EscalateFail) {
        ctx.patchState({ loading: false });

        const error = Utils.Helpers.findError(action.error, '');
        return ctx.dispatch(new AddSuccessResponseNotification({
            success: false,
            message: `Failed to escalate ticket. ${error}`
        }));

    }

}