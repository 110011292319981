/* eslint-disable @typescript-eslint/no-this-alias */
import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { map, timeout, catchError } from 'rxjs/operators';

import { ConfigService } from './config.service';
import { HttpClient } from '@angular/common/http';
import { ServiceHelper } from '../helpers/service.helper';
import { Result } from '../models/result.model';
import { AddressDetail } from '../models/addressDetail.model';

/**
 * CoverageMapModes @param
 */
export enum CoverageMapModes {
  Both = 0,
  FourG,
  FiveG
}

@Injectable({
  providedIn: 'root'
})

/**
 * GeoService @param
 */
export class GeoService {
  constructor(private configService: ConfigService, private http: HttpClient) { }

  getDistance(lat1: number, lon1: number, lat2: number, lon2: number): number {
    const unit = 'K';

    if (lat1 == lat2 && lon1 == lon2) {
      return 0;
    } else {
      const radlat1 = (Math.PI * lat1) / 180;
      const radlat2 = (Math.PI * lat2) / 180;
      const theta = lon1 - lon2;
      const radtheta = (Math.PI * theta) / 180;
      let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);

      if (dist > 1) {
        dist = 1;
      }

      dist = Math.acos(dist);
      dist = (dist * 180) / Math.PI;
      dist = dist * 60 * 1.1515;

      if (unit == 'K') {
        dist = dist * 1.609344;
      }

      return dist;
    }
  }

  get5GEngineering() {
    const self = this;

    return new google.maps.ImageMapType({
      getTileUrl(coords: google.maps.Point, zoom: number) {
        const tileCoords = self.getTileCoords(coords, zoom);

        if (!tileCoords) {
          return null;
        }

        const bound = Math.pow(2, zoom);

        return (
          'https://maps.rain.co.za/engineering_5g_coverage/' +
          zoom +
          '_' +
          tileCoords.x +
          '_' +
          (bound - tileCoords.y - 1) +
          '.png'
        );
      },
      tileSize: new google.maps.Size(256, 256)
    });
  }

  get4GEngineering(lteOnly) {
    const self = this;
    const layers = [];
    let bands = [];
    if (lteOnly) {
      bands = ['105', '95', '85', '75'];
    } else {
      bands = ['120', '115', '105', '95', '85', '75'];
    }

    bands.forEach(band => {
      const fourGEngineeringOptions: google.maps.ImageMapTypeOptions = {
        getTileUrl(coords: google.maps.Point, zoom: number) {
          const tileCoords = self.getTileCoords(coords, zoom);

          if (!tileCoords) {
            return null;
          }

          const bound = Math.pow(2, zoom);

          return (
            'https://maps.rain.co.za/eng_4g/' +
            band +
            '/' +
            zoom +
            '_' +
            tileCoords.x +
            '_' +
            (bound - tileCoords.y - 1) +
            '.png'
          );
        },
        tileSize: new google.maps.Size(256, 256)
      };

      const fourGEngineeringOverlay = new google.maps.ImageMapType(fourGEngineeringOptions);
      layers.push(fourGEngineeringOverlay);
    });

    return layers;
  }

  getWeather(lat: number, lng: number) {
    const WEATHER_APP_ID = this.configService.WEATHER_API_KEY;
    const WEATHER_URL = this.configService.WEATHER_URL;
    const requestPath =
      WEATHER_URL + `?lat=${lat}&lon=${lng}&appid=${WEATHER_APP_ID}&units=metric&callback=JSONP_CALLBACK`;

    return this.http.jsonp(requestPath, 'JSONP_CALLBACK').pipe(
      timeout(this.configService.API_TIMEOUT),
      map((result: any) => {
        return result;
      }),
      catchError(result => ServiceHelper.handleError<any>(result))
    );
  }

  private getLayerByName(name: string) {

    const testLayer: google.maps.ImageMapTypeOptions = {
      getTileUrl: (coords: google.maps.Point, zoom: number) => {
        const tileCoords = this.getTileCoords(coords, zoom);

        if (!tileCoords) {
          return null;
        }

        const bound = Math.pow(2, zoom);

        return `https://localmaps.oss.rain.network/${name}/${zoom}_${tileCoords.x}_${(bound - tileCoords.y - 1)}.png`;
      },
      tileSize: new google.maps.Size(256, 256),
    };

    const testLayerOverlay = new google.maps.ImageMapType(testLayer);
    testLayerOverlay.set("tech", name)

    return testLayerOverlay;
  }

  getCoverageLayers(mode: CoverageMapModes = CoverageMapModes.Both): google.maps.ImageMapType[] {
    const self = this;

    const layers = [];

    layers.push(this.getLayerByName("fourg_105"), this.getLayerByName("home_5G"), this.getLayerByName("fourg_roaming_shp"));

    if (mode === CoverageMapModes.Both || mode == CoverageMapModes.FourG) {
      const fourGOptions: google.maps.ImageMapTypeOptions = {
        getTileUrl(coords: google.maps.Point, zoom: number) {
          const tileCoords = self.getTileCoords(coords, zoom);

          if (!tileCoords) {
            return null;
          }

          const bound = Math.pow(2, zoom);

          return (
            'https://maps.rain.co.za/mobile' +
            '/' +
            zoom +
            '_' +
            tileCoords.x +
            '_' +
            (bound - tileCoords.y - 1) +
            '.png'
          );
        },
        tileSize: new google.maps.Size(256, 256)
      };

      const fourGOverlay = new google.maps.ImageMapType(fourGOptions);
      fourGOverlay.set("tech", "4G")

      layers.push(fourGOverlay);
    }

    if (mode == CoverageMapModes.Both || mode == CoverageMapModes.FiveG) {
      //TODO CPT 5g need to change url
      const fiveGCptOptions: google.maps.ImageMapTypeOptions = {
        getTileUrl(coords: google.maps.Point, zoom: number) {
          const tileCoords = self.getTileCoords(coords, zoom);

          if (!tileCoords) {
            return null;
          }

          const bound = Math.pow(2, zoom);

          return (
            'https://maps.rain.co.za/mobile_5g_ct_coverage' +
            '/' +
            zoom +
            '_' +
            tileCoords.x +
            '_' +
            (bound - tileCoords.y - 1) +
            '.png'
          );
        },
        tileSize: new google.maps.Size(256, 256)
      };

      const fiveGCptOverlay = new google.maps.ImageMapType(fiveGCptOptions);

      fiveGCptOverlay.set("tech", "5G-cpt")

      layers.push(fiveGCptOverlay);

      const fiveGOptions: google.maps.ImageMapTypeOptions = {
        getTileUrl(coords: google.maps.Point, zoom: number) {
          const tileCoords = self.getTileCoords(coords, zoom);

          if (!tileCoords) {
            return null;
          }

          const bound = Math.pow(2, zoom);

          return (
            'https://maps.rain.co.za/mobile_5g_coverage' +
            '/' +
            zoom +
            '_' +
            tileCoords.x +
            '_' +
            (bound - tileCoords.y - 1) +
            '.png'
          );
        },
        tileSize: new google.maps.Size(256, 256)
      };

      const fiveGOverlay = new google.maps.ImageMapType(fiveGOptions);
      fiveGOverlay.set("tech", "5G")

      layers.push(fiveGOverlay);
    }

    return layers;
  }

  geocode(latitude: number, longitude: number): Observable<Result<any[]>> {
    const geocoder = new google.maps.Geocoder();

    const promise = new Promise<google.maps.GeocoderResult[]>((resolve, reject) => {
      const request = { location: new google.maps.LatLng(latitude, longitude) };

      geocoder.geocode(request, (results, status) => {
        if (status === google.maps.GeocoderStatus.OK) {
          resolve(results);
        } else {
          reject(status);
        }
      });
    });

    return from(promise).pipe(
      map((results: []) => {
        const geos = results.map(x => AddressDetail.fromGeoCode(x));

        return Result.success(geos);
      })
    );
  }

  private getTileCoords(coords: google.maps.Point, zoom: number) {
    const y = coords.y;

    let x = coords.x;

    const range = 1 << zoom;

    if (y < 0 || y >= range) {
      return null;
    }

    if (x < 0 || x >= range) {
      x = ((x % range) + range) % range;
    }

    return {
      x: x,
      y: y
    };
  }
}
