import { Component, OnDestroy, OnInit, ViewChildren } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { ToggleModalByName } from 'src/app/core/store/actions/modal.actions';
import { SnowflakeSelectOption } from 'src/app/shared/elements/rain-forms/elements/snowflake-select/interfaces/rain-select-option.interface';
import { SnowflakeSelectComponent } from 'src/app/shared/elements/rain-forms/elements/snowflake-select/snowflake-select.component';
import { PostTicketModalOptions } from 'src/app/shared/services/post-ticket/post-ticket-modal-options.model';
import { PostTicketService } from 'src/app/shared/services/post-ticket/post-ticket.service';
import { FormHelper } from 'src/app/Utils/helpers';
import { InteractionTicketsState } from '../../store/state/interaction-tickets.state';
import { EarlyOptimizationConstants } from './assets/early-optimization-constants';
import { ChatJsonNote } from '../components/chat-json-note/assets/chat-json-note.interface';


const CALL_OUTCOME_KEY = "callOutcome";
const OUTCOME_KEY = "outcome";

@Component({
    selector: 'sf-early-optimizations-close-modal',
    templateUrl: './early-optimizations-close-modal.component.html',
    styleUrls: ['./early-optimizations-close-modal.component.scss']
})
export class EarlyOptimizationsCloseModalComponent extends FormHelper implements OnInit, OnDestroy {

    @ViewChildren(SnowflakeSelectComponent) selectComponents: SnowflakeSelectComponent[];

    isCallOutcomeAnswered$: Observable<boolean>;
    isCancelledImmediately$: Observable<boolean>;

    form: FormGroup;
    modalOptions: PostTicketModalOptions;

    selectOptions = EarlyOptimizationConstants.EARLY_OPT_SELECT_OPTIONS;
    contactedOptions: SnowflakeSelectOption[] = [];

    readonly callOutcomeLabel = "call outcome";
    readonly additionalAssistanceLabel = "additional assistance offered";

    constructor(private fb: FormBuilder,
        private store: Store,
        private postTicketService: PostTicketService,) {
        super();
    }

    ngOnInit(): void {
        this.modalOptions = new PostTicketModalOptions();

        this.form = this.fb.group({
            [CALL_OUTCOME_KEY]: this.fb.control(null, [Validators.required]),
            knownCustomerIssue: this.fb.control(null, [Validators.required]),
            hadTicketOpen: this.fb.control(null, [Validators.required]),
            causeOfLowSignal: this.fb.control(null, [Validators.required]),
            solutionsProvided: this.fb.control(null, [Validators.required]),
            [OUTCOME_KEY]: this.fb.control(null, [Validators.required]),
            additionalAssistanceOffered: this.fb.control(null, [Validators.required])
        });

        this.InitForm(this.form);
        this.listenToFormChanges();
    }

    private listenToFormChanges() {
        this.isCallOutcomeAnswered$ = this.form.get(CALL_OUTCOME_KEY).valueChanges
            .pipe(
                map(value => value === EarlyOptimizationConstants.ANSWERED),
                takeUntil(this.ngDestroy$)
            )

        this.isCancelledImmediately$ = this.form.get(OUTCOME_KEY).valueChanges
            .pipe(
                map(value => value === EarlyOptimizationConstants.CANCELLED_IMMEDIATELY),
                takeUntil(this.ngDestroy$),
            )

        this.getControl(OUTCOME_KEY).valueChanges
            .pipe(takeUntil(this.ngDestroy$))
            .subscribe({
                next: () => this.updateAdditionalAssistanceControl()
            });

        this.listenToCallOutcomeChanges();
    }

    private listenToCallOutcomeChanges() {
        const allOtherFormKeys = Object.keys(this.selectOptions).filter(key => key !== CALL_OUTCOME_KEY);

        this.isCallOutcomeAnswered$
            .subscribe({
                next: (isTrue: boolean) => {
                    allOtherFormKeys.forEach(key => {
                        const control = this.getControl(key);
                        if (isTrue) {
                            control.addValidators(Validators.required);
                            this.updateAdditionalAssistanceControl();
                        }
                        else {
                            this.selectComponents
                                .filter(item => item.label !== this.callOutcomeLabel)
                                .forEach(item => item.resetForm());
                            control.reset();
                            control.removeValidators(Validators.required);
                        }
                        control.updateValueAndValidity();
                    });
                }
            });
    }

    private updateAdditionalAssistanceControl() {
        const additionalAssistanceControl = this.getControl("additionalAssistanceOffered");
        const noAnswer = this.form.get(CALL_OUTCOME_KEY).value !== EarlyOptimizationConstants.ANSWERED;
        const cancelledImmediately = this.getControl(OUTCOME_KEY).value === EarlyOptimizationConstants.CANCELLED_IMMEDIATELY;

        if (noAnswer || cancelledImmediately) {
            this.selectComponents
                .find(item => item.label === this.additionalAssistanceLabel)
                ?.resetForm();
            additionalAssistanceControl.reset();
            additionalAssistanceControl.removeValidators(Validators.required);
        }
        else {
            additionalAssistanceControl.addValidators(Validators.required);
        }
        additionalAssistanceControl.updateValueAndValidity();
    }

    onSelect(value: string, controlName: string) {
        this.getControl(controlName).patchValue(value);
    }

    onSend() {
        const hexId = this.store.selectSnapshot(InteractionTicketsState.getSelectedHexId);

        const data = this.form.getRawValue();
        const payload: ChatJsonNote = {
            message: "Early Optimisation queue - Post close form",
            detail: data,
            type: "early_optimisation_form"
        }

        this.postTicketService.sendPostTicketPayload(
            hexId, payload, this.modalOptions, this.close.bind(this)
        );
    }

    close() {
        this.store.dispatch(new ToggleModalByName("early_optimizations_close_modal", false));
    }

    ngOnDestroy(): void {
        this.close();
    }

}
