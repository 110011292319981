import { Utils } from "src/app/Utils";
import { ChatTemplateCategory, CHAT_TEMPLATE_MESSAGES, DEFAULT_GOODBYE_MSG, NON_MANUALLY_SELECTABLE_CATEGORIES, TemplateData, TEMPLATE_LINKS, TEMPLATE_LINK_REF } from "../template-messages";


const TEXT_COLOR = "#6D7278";

export interface Config {
  category: ChatTemplateCategory,
  option: string,
  data: TemplateData;
}

export function getStartingMessage(customerName?: string | null) {
  let message = getHello(customerName);
  message += "\n"; //Add extra spacing
  message += getGoodbye();
  return message;
}

const getGoodbye = () => DEFAULT_GOODBYE_MSG.join("\n");

export function getCategories() {
  return Object
    .keys(CHAT_TEMPLATE_MESSAGES)
    .filter((category: ChatTemplateCategory) => !NON_MANUALLY_SELECTABLE_CATEGORIES.includes(category));
}

export function getTemplateOptions(category: string) {
  return Object.keys(CHAT_TEMPLATE_MESSAGES[category]);
}

export function getTemplateMessage(config: Config) {
  const { category, option, data } = config;

  return Utils.Functional.pipe(
    CHAT_TEMPLATE_MESSAGES[category][option](data),
    buildMessage,
    (msg: string) => addHello(data.customerName, "Hey", msg),
  );

}

const addHello = (customerName: string | null, hiOrHey: "Hi" | "Hey" = "Hi", message: string) => {
  return `${getHello(customerName, hiOrHey) + message}`;
}

const buildMessage = (message: string | string[]) => {
  if (typeof message === 'string') {
    return message + '\n';
  }
  return message.join("\n");
}

const getHello = (customerName: string | null, hiOrHey: "Hi" | "Hey" = "Hi") => {
  return customerName ? `${hiOrHey} ${customerName},\n` : `${hiOrHey},\n`;
}

export function formatTextToHTML(text: string) {
  let formattedText = '';
  const textArray = text.trim().split('\n');

  for (const text of textArray) {
    if (text === "") {
      formattedText += `<p></p>`;
    }
    else {
      formattedText += `<p style="color:${TEXT_COLOR};">${text}</p>`;
    }
  }

  return formattedText;
}

export function populateLinks(message: string): string {
  if (!message?.includes(TEMPLATE_LINK_REF)) {
    return message;
  }
  const items = message.split(" ");

  return items
    .map((word, i) => {
      const nextWord = items[i + 1];
      if (!nextWord || !nextWord.includes(TEMPLATE_LINK_REF)) {
        return word;
      }
      const linkRefNum = nextWord.split(TEMPLATE_LINK_REF)?.[1];
      return getLinkTag(word, linkRefNum);
    })
    .filter((word) => !word.includes(TEMPLATE_LINK_REF))
    .join(" ");
}

const getLinkTag = (aTagWord: string, linkRef: string) => {
  const link = TEMPLATE_LINKS[linkRef];
  if (!link) {
    throw Error(`${linkRef} does not exist on TEMPLATE_LINKS.`);
  }

  return `<a href="${link}" style="display:inline;">${aTagWord}</a>`;
}


