import { environment } from "src/environments/environment";
import { TechType } from "./shared/services/google-maps/assets/techtype.type";
import { AllAgentRoleOption } from "./shared/interfaces/agent-role-options.type";
import { SalesNestedRouteOption, SalesRouteOption } from "./sales/store/interfaces/sales-path-options.type";

export const CLIENT_ID = '524389f9-b3a0-48a3-aca2-0ae426c0fb14';
export const AUTHORITY = 'https://login.microsoftonline.com/5b67f9a1-ea53-476b-a4ed-075b7bab99f8';
export const APP_NAME = "snowflake";

export const IS_TESTING_ENV = environment.current_env === "sit" || environment.current_env === "local";
export const IS_LOCALHOST = location.hostname === "localhost";

export const CUSTOMER_INFO_SUMMARY_ROUTE = "customer-info-summary";
export const SUMMARY_ROUTES = [CUSTOMER_INFO_SUMMARY_ROUTE];
export const AGENT_VIEW_ROUTE = "agent-view";
export const SIM_DETAILS_ROUTE = "sim-card-details";
export const NUMBER_PORTING_ROUTE = "number-porting";
export const SEARCH_ROUTE = "search";
export const TICKETS_ROUTE = "tickets";
export const ADMIN_ROUTE = "admin";
export const HOME_ROUTE = "home";
export const ACCOUNT_DETAILS_ROUTE = "account-details";
export const SALES_PORTAL_ROUTE: SalesRouteOption = "sales-portal";
export const SALES_CART_ROUTE: SalesNestedRouteOption = "sales-cart";
export const SALES_DELIVERY_PAGE: SalesNestedRouteOption = "customer-delivery";
export const PAST_ORDERS_ROUTE = "past-orders";
export const CALL_RECORDINGS_ROUTE = "call-recordings";

export const CANCELLATION_REASONS = ["Moved to another ISP", "Affordability", "Network Issues", "Not in Coverage", "Fiber", "Other"];
export const MIGRATION_REASONS = ["Affordability", "Network Issues", "Not in Coverage", "Fiber", "Other"];

export const DEFAULT_PROFILE_IMAGE = 'assets/images/avatardefault.png';
export const SECONDS_IN_DAY = 86400;
export const TODAY = () => new Date().toISOString().split('T')[0];

export const USER_STATES = {
    LOGGED_OFF: 0,
    LOGGED_ON: 1,
    AVAILABLE: 2,
    PENDING_CLIENT_RESPONSE: 3,
    BUSY: 4,
    RECEIVED_TICKET: 5,
    CLOSING_OFF_TICKET: 6
}

export const POPUP_TYPES = {
    AGENT_AVAILABLE_POPUP: "agent-available"
}


export const DEFAULT_HTTP_OPTIONS = {
    headers: {
        'Content-Type': 'application/json',
    }
} as const;

export const MONTHS = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

export const ONE_SECOND_IN_MILI = 1000;

export const ONE_KILOBYTE_IN_BYTES = 1024;
export const ONE_MEGABYTE_IN_BYTES = 1048576;
export const ONE_GIGABYTE_IN_BYTES = 1073741824;

export const SF_TICKET_PREFIX = "rain";

export const MILISECONDS_IN_MINUTE = 60000;
export const MILISECONDS_IN_HOUR = 3600000;

export const MAX_TICKET_NOTE_LENGTH = 1500; //Previous was 2000

export const ONLY_NUM_REGEX = /^\d+$/;

export const SA_LOCALE = "en-ZA" as const;

export const ZERO_HEX = "0x0";

export const MAP_GEO_ZOOM_LEVEL = 15;

export const TECH_TYPES: TechType[] = ["4G", "5G"];

export const NO_DATA_MESSAGE = "No data returned";

export const ID_NUMBER_LENGTH = 13;
export const ONLY_LETTERS_AND_HYPHEN_REGEX = /^[a-zA-Z-]*$/;

export const HAS_WALLET_ADJUSTMENT_ACCESS: AllAgentRoleOption[] = ["CEC.BILLING.A", "CEC.MNP.ESCALATIONS", "CEC.VOICE.A", "CEC.VOICE.ADMIN", "RETAIL.CUSTOMER_ENGAGEMENT.A", "CEC.COLLECTIONS.B"]
export const HAS_VAS_ACCESS: AllAgentRoleOption[] = [
  'CEC.BILLING.A',
  'CEC.COLLECTIONS.A',
  'CEC.RETENTIONS.A',
  'CEC.ACTIVATIONS.A',
  'CEC.RETENTIONS_RAINONE.A',
  'LOGISTICS.DELIVERIES.A',
  'CEC.SALES.A',
  'CEC.SALES.B',
  'CEC.SALES.C',
  'CEC.CSAT.A',
  'CEC.ERICA.ESCALATION',
  'CEC.MNP.ESCALATIONS',
  'CEC.VOICE.A',
  'CEC.VOICESIMSWAP.A',
  'CEC.VOICE.ADMIN',
  'CEC.ONBOARDING_5G.A',
  'CEC.ADVANCED.A',
  'CEC.TEAM.A',
  'CEC.SOCIAL.A',
  "CEC.WHATSAPP.A",
  "RETAIL.CUSTOMER_ENGAGEMENT.A",
  "CEC.COLLECTIONS.B",
  "CEC.CALLBACK.A",
  "CEC.SALESPRIORITY.A",
  "CEC.SALES_REALTIME.A",
  'CEC.THE101.WEEKONE',
  'CEC.THE101.A'
]

export const CALL_ID_STR = "Call ID";
export const CALL_RECORDING_ID_STR = "Call Recording ID";
export const CALL_RECORDING_FILE_NAME_STR = "Call Recording File Name";

export const SCHEDULED_CALLBACK = "Scheduled callback";
export const CALL_ENDED = "Call ended";

export const RAINONE_4G_UNLIMITED = 'rainOne 4G unlimited any device'
export const RAINONE_4G_UOP = 'rainOne 4G unlimited off-peak'
export const LEGACY_4G_UOP = '19 hours unlimited\n off-peak for any device'
export const STAND_ALONE_4G = 'standalone 4G generic consumer SIM AU'

export const NO_EMAIL_EMAIL = "no-email@rain.co.za";

export const MAP_STATUS_MESSAGES = {
    "ZERO_RESULTS": "Address does not exist. Please try a different address.",
    "OVER_DAILY_LIMIT": "Search limit has been exceeded. Request has not been processed.",
    "OVER_QUERY_LIMIT": "Quota has been reached. Request has not been processed.",
    "REQUEST_DENIED": "Request denied. Contact development team for further information.",
    "INVALID_REQUEST": "Invalid address. Please check address entered and try again.",
    "UNKNOWN_ERROR": "Unable to make a request. Please try again later."
}
export const GROUPS_TO_HIDE = ["ADVANCED.TEAM.A","OPERATIONS.BILLING.A","RETENTIONS.TEAM.A", "CEC.5GCOVERAGE.A","CEC.CAMPAIGN.A",
    "CEC.COLLECTIONS.A","CEC.CSAT.A", "ANALYTICS.GENERAL.A", "CEC.MERCHANTS.GENERAL","CONSUMER.GENERAL.A","FINANCE.GENERAL.A",
    "GENERAL.TEAM.A","HR.GENERAL.A","HR.GENERAL.A","CEC.MERCHANTS.GENERAL","CEC.MNP.FAILURES","CONSUMER.GENERAL.A",
    "CSI.FACILITATORS.A","CSI.SCHOOLS.A","FINANCE.GENERAL.A","HR.GENERAL.A","IT.CYBER.A","LOGISTICS.DELIVERIES_RAIN.A",
    "RETENTIONS.TEAM.A"];
