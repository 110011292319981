import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngxs/store';
import { FormHelper } from 'src/app/Utils/helpers';
import { ToggleModalByName } from 'src/app/core/store/actions/modal.actions';
import { PostTicketModalOptions } from 'src/app/shared/services/post-ticket/post-ticket-modal-options.model';
import { PostTicketService } from 'src/app/shared/services/post-ticket/post-ticket.service';
import { InteractionTicketsState } from '../../store/state/interaction-tickets.state';
import { CustomerInfoState } from 'src/app/customer-info-summary-page/store/state/customer-info.state';
import { takeUntil } from 'rxjs/operators';
import { UpgradeModalOptions } from './assets/upgrade-modal-options';
import { ChatJsonNote } from '../components/chat-json-note/assets/chat-json-note.interface';


@Component({
    selector: 'sf-post-upgrade-modal',
    templateUrl: './post-upgrade-modal.component.html',
    styleUrls: ['./post-upgrade-modal.component.scss']
})
export class PostUpgradeModalComponent extends FormHelper implements OnInit {

    modalOptions: PostTicketModalOptions;
    upgradeSuccessful = null;
    form: FormGroup;

    readonly statusOptions = UpgradeModalOptions.UPGRADE_STATUS_OPTIONS;
    readonly reasonOptions = UpgradeModalOptions.REASON_OPTIONS;
    readonly upgradeOptions = UpgradeModalOptions.SUCCESSFUL_UPGRADE_OPTIONS;

    constructor(private store: Store,
        private fb: FormBuilder,
        private postTicketService: PostTicketService) {
        super();
    }

    ngOnInit(): void {
        this.form = this.fb.group({
            status: this.fb.control(null, Validators.compose([Validators.required])),
            orderNumber: this.fb.control(null),
            successfulUpgradeType: this.fb.control(null),
            reasonIfUnsuccessful: this.fb.control(null),
        });

        this.InitForm(this.form);
        this.listenToFormChanges();
        this.modalOptions = new PostTicketModalOptions();

    }


    private listenToFormChanges() {

        this.getControl("status").valueChanges
            .pipe(takeUntil(this.ngDestroy$))
            .subscribe({
                next: (res: string) => {
                    const successful = res === "successful";
                    this.upgradeSuccessful = successful;

                    this.updateRequired("orderNumber", successful);
                    this.updateRequired("successfulUpgradeType", successful)
                    this.updateRequired("reasonIfUnsuccessful", !successful);
                }
            });
    }


    private updateRequired(controlName: string, required: boolean) {
        const control = this.getControl(controlName);
        if (required) {
            control.addValidators(Validators.required);
            control.updateValueAndValidity();
        }
        else {
            control.removeValidators(Validators.required);
            control.patchValue(null);
        }
    }

    onOptionSelect(formField: string, value: string) {
        this.getControl(formField).patchValue(value);
    }

    private getFormattedData() {
        const hexId = this.store.selectSnapshot(InteractionTicketsState.getSelectedHexId);
        const { status, orderNumber, reasonIfUnsuccessful, successfulUpgradeType } = this.form.getRawValue();
        const isSuccessful = status === "successful";
        const message = isSuccessful ? 'Upgrade Successful' : 'Upgrade Unsuccessful';
        const usersEmail = this.store.selectSnapshot(
            CustomerInfoState.getCustomerEmail
        );
        return {
            "ticket_id": BigInt(hexId).toString(),
            "agent_order_status": message,
            "related_entity": {
                "email": usersEmail
            },
            "order_number": orderNumber ?? null,
            "type_of_successful_upgrade": this.upgradeSuccessful ? successfulUpgradeType : message,
            "unsuccessful_upgrade_reason": this.upgradeSuccessful ? orderNumber : reasonIfUnsuccessful,
        }
    }

    onSend() {
        const data = this.getFormattedData();
        const payload: ChatJsonNote = {
            message: "Upgrade post close form",
            detail: data,
            type: 'upgrade_form'
        }
        const hexId = this.store.selectSnapshot(InteractionTicketsState.getSelectedHexId);
        this.postTicketService.sendPostTicketPayload(
            hexId, payload, this.modalOptions, this.onClose.bind(this)
        );
    }


    move() {
        this.postTicketService.moveOn(this.modalOptions, this.onClose.bind(this));
    }

    onClose() {
        this.store.dispatch(new ToggleModalByName('post_upgrade_modal', false));
    }

    ngOnDestroy(): void {
        this.ngDestroy$.next(null);
        this.ngDestroy$.complete();
        this.onClose();
    }

}
