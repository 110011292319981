<div class="auth-container">
    <div class="panel-box">
        <div class="header">
            <h1 class="header-text">{{appName}}</h1>
        </div>
        <div *ngIf="!loginStatus">
            <p class="login-text">Please Login to Continue</p>
            <button class="toggle-buttons login" (click)="login()">
                <p class="toggle-button-text">
                    Login with Microsoft
                </p>
            </button>

        </div>
        <div *ngIf="loginStatus">
            <button class="toggle-buttons login" (click)="homeRoute()">
                <p class="toggle-button-text">
                    Continue
                </p>
            </button>
            <button class="toggle-buttons logout" (click)="logout()">
                <p class="toggle-button-text">
                    Logout
                </p>
            </button>
        </div>
    </div>

    <div class="restriction-container">

        <div class="restriction-header-container">
            <h3> M3 - RESTRICTED WEBSITE</h3>
            <p class="text"> This restricted website is the property of Rain Networks (Pty) Ltd.
                (‘rain’) and may only be viewed or edited by the EXCO. and other viewers

                as authorised by rain networks CEO. Any use, editing, reproduction,
                retention or distribution of this
                document external to the listed parties is strictly prohibited. </p>
        </div>
    </div>
</div>
