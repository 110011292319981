import { Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { combineLatest, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { AGENT_VIEW_ROUTE } from '../constants';
import { SetPersistentStorageItem } from '../core/store/actions/persistent-storage.actions';
import { PersistentStorageState } from '../core/store/state/persistent-storage/persistent-storage.state';
import { InteractionsState } from '../interactions/store/state/interactions.state';
import { DataHandler } from '../shared/data-handler/data-handler';
import { CustomRouterEventHandler } from '../shared/services/router-event-controller/custom-router-event-handler.interface';
import { RouterEventControllerService } from '../shared/services/router-event-controller/router-event-controller.service';
import { ICallQueues } from './assets/call-queue.interface';
import { SipCallType } from './assets/sip-call.type';
import { CALL_QUEUE_DETAILS } from './assets/sip-config';
import { SipMessageSubject } from './assets/sip-message-subject.type';
import { SipPhoneService } from './services/sip-phone.service';
import { SipPhoneActions } from './store/actions/sip-phone-actions';
import { SipPhoneState } from './store/state/sip.state';


@Component({
  selector: 'app-sip-phone',
  templateUrl: './sip-phone.component.html',
  styleUrls: ['./sip-phone.component.scss', './assets/sip-phone-styles.scss']
})
export class SipPhoneComponent implements OnInit, OnDestroy, CustomRouterEventHandler {

  @ViewChild("mainModal") mainModal: ElementRef;

  @Output() modalChanged = new EventEmitter<boolean>();

  phoneVolumeSettings = {
    ring: 0.4,
    voice: 0.9,
  }

  modalOpen = false;
  manualOpen = false;
  callType: SipCallType;
  isPositionStatic = false;
  callQueues: ICallQueues[] = [];

  private ngDestroy$ = new Subject<void>();

  constructor(private sipPhoneService: SipPhoneService,
    private store: Store,
    private routerEventService: RouterEventControllerService) {
    this.routerEventService.addCustomEventHandler(this);
  }


  ngOnInit(): void {
    this.callQueues = DataHandler.getObjectAsArray(CALL_QUEUE_DETAILS);

    this.sipPhoneService.callSubject
      .pipe(takeUntil(this.ngDestroy$))
      .subscribe(
        (response: Map<SipMessageSubject, any>) => {
          if (response.has("call_type_changed")) this.setCallType(response.get("call_type_changed"));
        }
      );

    this.sipPhoneService.initializeUserAgent();
    this.setIncomingCallLoggingListener();

    this.setSavedVolumeSettings();
  }

  private setIncomingCallLoggingListener() {
    const ticket$ = this.store.select(InteractionsState.getInitializedTicket);
    const incomingCallDetails$ = this.store.select(SipPhoneState.getIncomingCallDetails);

    combineLatest([ticket$, incomingCallDetails$])
      .pipe(
        filter(([ticket, callDetails]) => Boolean(ticket?.hex_id) && Boolean(callDetails?.callId)),
        takeUntil(this.ngDestroy$)
      )
      .subscribe({
        next: ([ticket, callDetails]) => {

          this.store.dispatch([
            new SipPhoneActions.LogCall("incoming", { hexId: ticket.hex_id, callDetails }),
            new SipPhoneActions.LogCall("incomingRecordingId", { hexId: ticket.hex_id, callDetails })
          ]);

        }
      });
  }

  private setSavedVolumeSettings() {
    setTimeout(() => {
      //will use the default values if no values are saved
      const savedSettings = this.store.selectSnapshot(PersistentStorageState.get("phone-volume-settings"))
      if (savedSettings) {
        this.phoneVolumeSettings = savedSettings;
      }
    }, 3000);
  }

  saveVolumeChange() {
    setTimeout(() =>
      this.store.dispatch(new SetPersistentStorageItem("phone-volume-settings", this.phoneVolumeSettings))
      , 50);
  }

  setCallType(callType: SipCallType) {
    this.callType = callType;
    if (!this.callType) this.setModalOpen(this.manualOpen);
    else this.setModalOpen(true);
  }

  sendModalChangedEvent() {
    this.modalChanged.next(this.modalOpen);
  }

  toggleModalOpen() {
    this.modalOpen = !this.modalOpen;
    this.manualOpen = this.modalOpen;
    this.sendModalChangedEvent();
  }

  setModalOpen(open: boolean) {
    this.modalOpen = open;
    this.sendModalChangedEvent();
  }

  originalOrder(): number {
    return 0;
  }

  trackByFn(index: number) {
    return index;
  }


  handleRouteChange(router: Router): void {
    this.isPositionStatic = router?.url?.includes(AGENT_VIEW_ROUTE);
  }

  ngOnDestroy() {
    this.ngDestroy$.next(null);
    this.ngDestroy$.complete();
    this.sipPhoneService.stopUserAgentConnection();
  }

}
