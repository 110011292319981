import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { AgentActionLoggingService } from '../../services/agent-action-logging.service';
import { AgentActionLoggingActions } from '../actions/agent-action-logging-actions';
import { CoreState } from 'src/app/core/store/state/core.state';


interface AgentActionLoggingStateModel {
    loading: boolean;
}
@State<AgentActionLoggingStateModel>({
    name: 'sf_agent_action_logging_state',
    defaults: {
        loading: false
    }
})
@Injectable()
export class AgentActionLoggingState {

    @Selector()
    static isLoading(state: AgentActionLoggingStateModel) { return state.loading }

    constructor(private agentActionLoggingService: AgentActionLoggingService,
        private store: Store) {
    }

    @Action(AgentActionLoggingActions.LogAction)
    LogAction(ctx: StateContext<AgentActionLoggingStateModel>, action: AgentActionLoggingActions.LogAction) {
        const { payload } = action;
        const { agent_email } = payload ?? {};
        const inSales = this.store.selectSnapshot(CoreState.isInSales);

        if (!inSales || !agent_email || !payload?.action) {
            return;
        }

        ctx.patchState({ loading: true });

        return this.agentActionLoggingService.logAction(payload)
            .pipe(tap({
                next: () => ctx.dispatch(new AgentActionLoggingActions.LogActionSuccess()),
                error: () => ctx.dispatch(new AgentActionLoggingActions.LogActionFail())
            }));
    }


    @Action(AgentActionLoggingActions.LogActionSuccess)
    LogActionSuccess(ctx: StateContext<AgentActionLoggingStateModel>) {
        ctx.patchState({ loading: false });
    }


    @Action(AgentActionLoggingActions.LogActionFail)
    LogActionFail(ctx: StateContext<AgentActionLoggingStateModel>) {
        ctx.patchState({ loading: false });
    }

}