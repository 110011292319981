import { Feature, Point } from "geojson";
import { combineLatest, Observable } from "rxjs";
import { filter, delay, map } from "rxjs/operators";
import { MapActions } from "src/app/shared/elements/map/store/actions/map-actions";
import { SFValidators } from "src/app/shared/functions/sf-validators";


interface Loaders<P> {
    mapLoaded$: Observable<MapActions.MapLoaded>;
    featuresLoaded$: Observable<boolean>;
    features$: Observable<Array<Feature<Point, P>>>;
}

export const createMapAndFeatureLoadedObs = <P>(loaders: Loaders<P>) => {
    const { mapLoaded$, featuresLoaded$, features$ } = loaders;
    return combineLatest([mapLoaded$, featuresLoaded$, features$])
        .pipe(
            filter(([mapLoaded, featuresLoaded]) => SFValidators.allTrue([Boolean(mapLoaded), featuresLoaded])),
            delay(200),
            map(([mapLoaded, _, features]) => ({ map: mapLoaded.map, features }))
        )
}
