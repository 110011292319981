import { Injectable } from '@angular/core';
import { Action, State, StateContext, } from '@ngxs/store';
import { FeatureOptionActions } from '../actions/feature-option-actions';



type FeatureOptionsStateModel = {
    //TODO:
}
@State<FeatureOptionsStateModel>({
    name: 'sf_feature_options_state',
    defaults: {

    }
})
@Injectable()
export class FeatureOptionsState {


    @Action(FeatureOptionActions.Update)
    Update(ctx: StateContext<FeatureOptionsStateModel>, action: FeatureOptionActions.Update) {
        //TODO:
    }




}       