import { Component, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store, Select } from '@ngxs/store';
import { ToggleModalByName } from 'src/app/core/store/actions/modal.actions';
import { SnowflakeSelectOption } from 'src/app/shared/elements/rain-forms/elements/snowflake-select/interfaces/rain-select-option.interface';
import { createSFselectOptions } from 'src/app/shared/functions/create-sf-select-options.function';
import { PostTicketModalOptions } from 'src/app/shared/services/post-ticket/post-ticket-modal-options.model';
import { PostTicketService } from 'src/app/shared/services/post-ticket/post-ticket.service';
import { FormHelper } from 'src/app/Utils/helpers';
import { InteractionTicketsState } from '../../store/state/interaction-tickets.state';
import { ChatJsonNote } from '../components/chat-json-note/assets/chat-json-note.interface';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { GetRetention4GSelectors, ViewModelRetention4G } from './view-model/retentions-update-modal-4g-view-model.selector';
import { CustomerInfoState } from 'src/app/customer-info-summary-page/store/state/customer-info.state';
import { takeUntil } from 'rxjs/operators';
import { SnowflakeSelectComponent } from 'src/app/shared/elements/rain-forms/elements/snowflake-select/snowflake-select.component';

const NO_NETWORK = 'Network Issues';

@Component({
    selector: 'sf-retentions-update-modal-4g',
    templateUrl: './retentions-update-modal-4g.component.html',
    styleUrls: ['./retentions-update-modal-4g.component.scss']
})
export class RetentionsUpdateModal4GComponent extends FormHelper implements OnInit, OnDestroy {

    @Select(GetRetention4GSelectors.getViewModel) viewModel$: Observable<ViewModelRetention4G>;

    @ViewChildren(SnowflakeSelectComponent) private sfOptionsComponents: QueryList<SnowflakeSelectComponent>;

    readonly retained$ = new BehaviorSubject<boolean>(false);
    readonly isNetworkIssue$ = new BehaviorSubject<boolean>(false);

    msisdnOptions: SnowflakeSelectOption[] = [];
    form: FormGroup;

    readonly modalOptions = new PostTicketModalOptions();

    readonly noNetworkReasonList = createSFselectOptions([
        "Intermittent connection",
        "Slow speeds",
        "No connection"
    ]);

    readonly nonRetentionReasons = createSFselectOptions([
        "No Contact",
        "Network Issues",
        "Moved to New ISP/Fibre",
        "Billing",
        "Affordability",
        "Cancelled - purchased rainOne"
    ]);

    readonly retentionReasons = createSFselectOptions([
        'Sim swap',
        'Billing correction/Credit',
        'Incorrect cancellation requested',
        'Trouble shooting',
        'Cancellation already revoked by customer'
    ]);

    readonly labelStyle = "thin-large-font";

    public retentionReasonsDynamic: SnowflakeSelectOption[] = this.nonRetentionReasons;

    constructor(private fb: FormBuilder,
        private store: Store,
        private postTicketService: PostTicketService) {
        super();
    }

    ngOnInit(): void {

        this.form = this.fb.group({
            retained: this.fb.control(false),
            retainedOrNotReason: this.fb.control(null, [Validators.required]),
            noNetworkReason: this.fb.control(null),
            comments: this.fb.control(''),
            msisdn: this.fb.control('', [Validators.required])
        });

        this.InitForm(this.form);
        this.setupFormListeners();
    }

    private switchReasonOptions(retainedChecked: boolean) {

        this.sfOptionsComponents.forEach((component) => {
            if (component.label === 'reasons') {
                component.resetForm();
            }
        })
        this.retentionReasonsDynamic = retainedChecked ? this.retentionReasons : this.nonRetentionReasons;

    }

    private setupFormListeners() {
        this.getControl("retained")
            .valueChanges
            .pipe(
                takeUntil(this.ngDestroy$)
            )
            .subscribe({
                next: (val: boolean) => {
                    this.retained$.next(val);
                    this.switchReasonOptions(val);
                }
            });

        return combineLatest([this.retained$,  this.getControl("retainedOrNotReason").valueChanges])
            .pipe(
                takeUntil(this.ngDestroy$)
            )
            .subscribe(([retained, retainedOrNotReason])  => {

                if (!retained && retainedOrNotReason === NO_NETWORK) {
                    this.isNetworkIssue$.next(true);
                } else this.isNetworkIssue$.next(false);
            })
    }


    onSelect(value: string, controlName: string) {
        this.getControl(controlName).patchValue(value);
        return;
    }

    onSend() {
        const data = {
            ...this.form.getRawValue(),
            customerEmail: this.store.selectSnapshot(CustomerInfoState.getCustomerEmail)
        };

        const payload: ChatJsonNote = {
            message: "Retentions post update form 4G",
            detail: data,
            type: "retentions_update_form"
        }

        const hexId = this.store.selectSnapshot(InteractionTicketsState.getSelectedHexId);
        this.postTicketService.sendPostTicketPayload(
            hexId, payload, this.modalOptions, this.close.bind(this)
        );
    }

    public resetForm() {
        this.form.reset();
    }

    close() {
        this.store.dispatch(new ToggleModalByName("retentions_update_4G_modal", false));
        this.resetForm();
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
        this.close();
    }
}
