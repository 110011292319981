import { Selector } from "@ngxs/store";
import { DigitalIdentityByUserIdState } from "../state/digital-identity-by-user-id.state";
import { DigitalIdentityCustomer, DigitalIdentityRelatedParty } from "../../types/digital-identity-by-customer-id-response.interface";
import { CustomerInfoState } from "src/app/customer-info-summary-page/store/state/customer-info.state";

export interface DigitalIdentityByUserIdViewModel {
  hasWorkAccount: boolean;
  hasSMERole: boolean;
  relatedParty: DigitalIdentityRelatedParty[];
}

export class DigitalIdentityByUserIdSelectors {

  @Selector([
    DigitalIdentityByUserIdState.getData,
    CustomerInfoState.getCustomerEmail
  ])
  static getViewModel(
    digitalIdentityCustomerData : DigitalIdentityCustomer,
    email: string
  ): DigitalIdentityByUserIdViewModel {

    const { partyRoleIdentified, relatedParty } = digitalIdentityCustomerData ?? {}
    const { role } = partyRoleIdentified?.find(party => party?.role === 'SME') ?? {}
    const hasSMERole = role === 'SME'

    return {
      hasWorkAccount: hasSMERole || email?.endsWith("_sme"),
      hasSMERole,
      relatedParty
    }
  }

}
