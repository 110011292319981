import { NonTextCurrentMediaType, MessageContent, AudioMessage, VideoMessage, ImageMessage } from "../types/whatsapp-message.interface";


export const getWhatsappMessageContent =
    (type: NonTextCurrentMediaType, content: MessageContent<NonTextCurrentMediaType>) => {
        if (type === "AUDIO") {
            return (content as AudioMessage)?.audioMessage;
        }

        if (type === "VIDEO") {
            return (content as VideoMessage)?.videoMessage;
        }

        if (type === "IMAGE") {
            return (content as ImageMessage)?.imageMessage;
        }
    }