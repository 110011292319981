import { Component, Input } from '@angular/core';

@Component({
  selector: 'sf-widget-template',
  templateUrl: './widget-template.component.html',
  styleUrls: ['./widget-template.component.scss']
})
export class WidgetTemplateComponent {
  @Input() title?: string;

}
