import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store, } from '@ngxs/store';
import { tap, takeUntil, take } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { DataLoading, getDataLoadingDefaultValues } from 'src/app/shared/interfaces/data-loading.interface';
import { Utils } from 'src/app/Utils';
import { DataLoadingHelper } from 'src/app/Utils/helpers';
import { CallbackService } from '../../services/callback.service';
import { CustomerCallbackActions } from '../actions/customer-callback-actions';
import { Callback, CallbackExtras } from '../types/callback-response.interface';
import { CustomerInfoSummaryDestroyed } from 'src/app/customer-info-summary-page/store/actions/customer.actions';
import { SetConnectedPostCall } from '../../../agent/store/actions/agent-status-actions/agent-status.actions';
import { UpdateCallbackExtrasActions } from '../actions/update-callback-actions';
import { CoreState } from 'src/app/core/store/state/core.state';
import { AddTicketEvent } from 'src/app/shared/customer-ticket/store/actions/ticket-event.actions';
import { InteractionTicketsState } from 'src/app/interactions/store/state/interaction-tickets.state';
import { ShowContinueButton } from 'src/app/interactions/store/actions/continue-flow.actions';
import { Dictionary } from 'src/app/shared/interfaces/dictionary.interface';
import { CALL_ID_STR } from 'src/app/constants';

const haveCalledBackCustomer = (callbackExtras: CallbackExtras) => {
    const { agentResponses } = callbackExtras ?? {};
    if (!agentResponses?.length) {
        return false;
    }

    return agentResponses?.some(item => item?.response === "called_back");
}


type CustomerCallbackStateModel = DataLoading<Callback>;

@State<CustomerCallbackStateModel>({
    name: 'sf_CustomerCallback_state',
    defaults: {
        ...getDataLoadingDefaultValues()
    }
})
@Injectable()
export class CustomerCallbackState {

    @Selector()
    static isLoading(state: CustomerCallbackStateModel) { return state.loading }

    @Selector()
    static isLoaded(state: CustomerCallbackStateModel) { return state.loaded }

    @Selector()
    static getData(state: CustomerCallbackStateModel) { return state.data }

    @Selector()
    static getError(state: CustomerCallbackStateModel) { return state.error }

    private readonly _cancelRequest$ = new Subject<null>();

    constructor(private callbackService: CallbackService,
        private store: Store) {
    }

    @Action(CustomerCallbackActions.FetchByIdIfNotPresent)
    FetchByIdIfNotPresent(ctx: StateContext<CustomerCallbackStateModel>, action: CustomerCallbackActions.FetchById) {
        const { callbackId } = action;

        const { loading, data } = ctx.getState();
        if (loading || data?.external_ref === callbackId) {
            return;
        }

        return ctx.dispatch(new CustomerCallbackActions.FetchById(callbackId));
    }


    @Action(CustomerCallbackActions.FetchById)
    FetchById(ctx: StateContext<CustomerCallbackStateModel>, action: CustomerCallbackActions.FetchById) {
        const { callbackId } = action;

        ctx.patchState({ loading: true });

        return this.callbackService.fetchById(callbackId)
            .pipe(
                tap({
                    next: res => ctx.dispatch(new CustomerCallbackActions.FetchByIdSuccess(res?.result)),
                    error: (e: unknown) => ctx.dispatch(new CustomerCallbackActions.FetchByIdFail(e))
                }),
                takeUntil(this._cancelRequest$.pipe(take(1)))
            );
    }


    @Action([CustomerCallbackActions.FetchByIdSuccess, CustomerCallbackActions.SetCallbackData])
    FetchByIdSuccess(ctx: StateContext<CustomerCallbackStateModel>, action: CustomerCallbackActions.FetchByIdSuccess | CustomerCallbackActions.SetCallbackData) {
        const { payload } = action;
        DataLoadingHelper.setData(ctx, payload);
    }


    @Action(CustomerCallbackActions.FetchByIdFail)
    FetchByIdFail(ctx: StateContext<CustomerCallbackStateModel>, action: CustomerCallbackActions.FetchByIdFail) {
        const error = Utils.Helpers.findError(action.error, '');
        DataLoadingHelper.setError(ctx, error);
    }


    @Action(SetConnectedPostCall)
    CheckCallbackStatePostCall(ctx: StateContext<CustomerCallbackStateModel>, action: SetConnectedPostCall) {
        const { extras, id, external_ref, status } = ctx.getState().data ?? {};
        const { callType, callId } = action ?? {};

        if (!id || callType === "incoming" || haveCalledBackCustomer(<CallbackExtras>extras)) {
            return;
        }

        const agentEmail = this.store.selectSnapshot(CoreState.getAgentEmail);

        const updatedExtras: CallbackExtras = {
            agentResponses: [
                ...extras?.agentResponses ?? [],
                {
                    email: agentEmail,
                    call_id: callId,
                    inserted_at: Math.round(Date.now() / 1000),
                    response: "called_back"
                }
            ]
        };

        const updateAction = status === "ACKNOWLEDGED"
            ? new UpdateCallbackExtrasActions.UpdateExtras(id, updatedExtras)
            : new UpdateCallbackExtrasActions.UpdateStatusAndExtras(id, { status: "ACKNOWLEDGED", extras: updatedExtras });

        const actions: Dictionary[] = [
            updateAction,
            new AddTicketEvent({
                hexId: external_ref,
                eventComment: `Called back customer. ${CALL_ID_STR}: ${callId}`
            })
        ];

        const isAssignedTicketClosed = this.store.selectSnapshot(InteractionTicketsState.isAssignedTicketClosed);
        if (isAssignedTicketClosed) {
            actions.push(new ShowContinueButton(true));
        }

        return ctx.dispatch(actions);
    }


    @Action([CustomerCallbackActions.Clear, CustomerInfoSummaryDestroyed])
    Clear(ctx: StateContext<CustomerCallbackStateModel>) {
        this._cancelRequest$.next(null);
        ctx.setState(getDataLoadingDefaultValues());
    }

}   