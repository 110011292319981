<sf-popup-modal [isOpen]="true" [hasCloseButton]="false" [autoClose]="false" [limitHeight]="true" [sending]="loading">
    <ng-container header>post campaign call form</ng-container>

    <ng-container mainContent>

        <ng-container *ngIf="responded; else showDefault">
            <sf-select label="customer issue" [options]="customerIssues" (onOptionSelect)="onSelect($event)"
                placeholder="select issue" [hasError]="form?.get('customerIssue').errors?.notSelected">
            </sf-select>
            <div class="spacer"></div>
        </ng-container>


        <ng-template #showDefault>
            <div class="message">
                <p>select the response from the customer</p>
            </div>
        </ng-template>

    </ng-container>

    <ng-container buttons>

        <ng-container *ngIf="responded; else notResponded">
            <sf-rain-button [isSolid]="true" (click)="onSubmit()" [disabled]="from?.invalid || loading">
                continue
            </sf-rain-button>
        </ng-container>

        <ng-template #notResponded>
            <sf-rain-button [isDeclineButton]="true" (click)="onNotAnswered('declined')">call declined</sf-rain-button>
            <sf-rain-button (click)="onNotAnswered('voicemail')">voicemail</sf-rain-button>
            <sf-rain-button [isAcceptButton]="true" (click)="onAnswered()">call answered</sf-rain-button>
        </ng-template>

    </ng-container>

</sf-popup-modal>