<div class="card-container" [class.fit-center]="fitCenter">
    <app-blank-card
        *ngFor="let item of routeInfoList"
        (click)="onRedirect(item)"
        [clickable]="!item.disabled"
    >
        <div front class="center-container">
            <h2 class="text text-blue" [class.text-dark-grey]="item.disabled">
                {{ item.header }}
            </h2>
            <fa-icon
                [icon]="item?.icon"
                size="3x"
                class="text-blue"
                [class.text-dark-grey]="item.disabled"
            ></fa-icon>
        </div>
    </app-blank-card>
</div>
