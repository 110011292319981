import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { DEFAULT_PROFILE_IMAGE } from '../constants';
import { AzureProfile } from './assets/azure-profile.interface';
import { ProfileService } from './profile.service';


@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  profile: AzureProfile;
  profileImage: string | ArrayBuffer = DEFAULT_PROFILE_IMAGE;
  profileOpen = false;

  constructor(
    private authService: AuthService,
    private profileService: ProfileService) { }

  ngOnInit(): void {
    this.getProfile();
  }

  toggleProfileSettings() {
    this.profileOpen = !this.profileOpen;
    this.profileService.toggleSidenav();
  }

  closeProfileSettings() {
    this.profileOpen = false;
  }

  async getProfile() {
    this.profile = await this.authService.getProfile();
    this.profileImage = await this.profileService.getprofilePhoto();
  }

  logout() {
    this.authService.logout();
    this.toggleProfileSettings();
  }


}
