<sf-popup-modal [isOpen]="true" [hasCloseButton]="false" [autoClose]="false"
  [successResponse]="modalOptions.successResponse" [sending]="modalOptions.sending"
  [spinnerMessage]="modalOptions.spinnerMessage" [limitHeight]="true">

  <ng-container header>post ticket form</ng-container>

  <ng-container mainContent>
    <sf-modal-form-container [formGroup]="form">
      <div class="checkbox-div">
        <sf-form-checkbox [control]="this.form.get('correct_classification')"
          [label]="'Was the ticket related to ' + classificationMessage + '?'">
        </sf-form-checkbox>
      </div>
      <div class="select-options">

        <sf-rain-multi-select label="network" [options]="networkSelectOptions" labelStyle="thin-font-no-gap"
          (onOptionSelect)="onSelectOptions('network',$event)">
        </sf-rain-multi-select>

        <sf-rain-multi-select *ngIf="otherOptionsSelected['msisdns']" label="selected msisdns" [options]="msisdnOptions"
          labelStyle="thin-font-no-gap" (onOptionSelect)="onSelectMSISDN($event)"
          [control]="form?.get('msisdns')"></sf-rain-multi-select>

        <sf-rain-multi-select label="billing - billing disputes" labelStyle="thin-font-no-gap" [options]="billingDisputesOptions"
          (onOptionSelect)="onSelectOptions('billing_disputes',$event)">
        </sf-rain-multi-select>

        <sf-rain-multi-select label="billing - account in arrears" labelStyle="thin-font-no-gap" [options]="accountInArrearsOptions"
          (onOptionSelect)="onSelectOptions('account_arrears',$event)">
        </sf-rain-multi-select>


        <sf-rain-multi-select label="system issues" [options]="systemIssueSelectOptions" labelStyle="thin-font-no-gap"
          (onOptionSelect)="onSelectOptions('system_issue',$event)">
        </sf-rain-multi-select>


        <sf-rain-multi-select label="delivery" [options]="deliverySelectOptions" labelStyle="thin-font-no-gap"
          (onOptionSelect)="onSelectOptions('delivery',$event)">
        </sf-rain-multi-select>

        <sf-rain-multi-select label="rain one query" [options]="rainOneQuerySelectOptions" labelStyle="thin-font-no-gap"
          (onOptionSelect)="onSelectOptions('rain_one_query',$event)">
        </sf-rain-multi-select>

        <div>
          <sf-form-checkbox [control]="this.form.get('device_issue')"
            [label]="'device issues'">
          </sf-form-checkbox>

          <sf-form-checkbox [control]="this.form.get('change_update_customer_details')"
            [label]="'change/update customer details'">
          </sf-form-checkbox>
        </div>

      </div>

      <div class="spacer"></div>
      <p class="red error" *ngIf="!isAnyOptionSelected">Atleast one option needs to be selected.</p>
    </sf-modal-form-container>

  </ng-container>
  <ng-container buttons>
    <button class=" rain-button default-button-size send-button solid" (click)="onSend()"
      [disabled]="!form || form?.invalid || modalOptions.sending || !isAnyOptionSelected ||(this.otherOptionsSelected['msisdns'] && this.selectedMSISDNs.length<1)">
      send
    </button>
  </ng-container>
</sf-popup-modal>
