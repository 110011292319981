import { Utils } from "src/app/Utils";
import { GeminiFeature } from "../interfaces/gemini-response.interface";


export class FetchAzimuthData {
  static readonly type = Utils.Helpers.Type('[Map: Azimuth] Fetch azimuth data');
  constructor(public siteID: number) { }
}

export class FetchAzimuthDataSuccess {
  static readonly type = Utils.Helpers.Type('[Map: Azimuth] Fetch azimuth data success');
  constructor(public siteID: number, public payload: GeminiFeature) { }
}

export class FetchAzimuthDataFail {
  static readonly type = Utils.Helpers.Type('[Map: Azimuth] Fetch azimuth data fail');
  constructor(public siteID: number, public error: any) { }
}
