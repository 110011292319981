import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Select, Store } from '@ngxs/store';
import { CustomerInfoSummaryService } from 'src/app/customer-info-summary-page/store/services/customer-info-summary.service';
import { SnowflakeSelectOption } from 'src/app/shared/elements/rain-forms/elements/snowflake-select/interfaces/rain-select-option.interface';
import { MultiSelectOption } from 'src/app/shared/elements/rain-forms/multi-level-select/assets/multi-select-option.model';
import { NestedMultiSelect } from 'src/app/shared/elements/rain-forms/multi-level-select/assets/nested-multi-select.model';
import { PostTicketModalOptions } from 'src/app/shared/services/post-ticket/post-ticket-modal-options.model';
import { PostTicketService } from 'src/app/shared/services/post-ticket/post-ticket.service';
import { Observable, Subject } from 'rxjs';
import { ToggleModalByName } from 'src/app/core/store/actions/modal.actions';
import { ProductsState } from 'src/app/core/store/state/product-state/products.state';
import { createSFselectOptions } from 'src/app/shared/functions/create-sf-select-options.function';
import { takeUntil } from 'rxjs/operators';
import { SnowflakeSelectComponent } from 'src/app/shared/elements/rain-forms/elements/snowflake-select/snowflake-select.component';
import { InteractionTicketsState } from '../../store/state/interaction-tickets.state';
import { ChatJsonNote } from '../components/chat-json-note/assets/chat-json-note.interface';
import { CoreState } from 'src/app/core/store/state/core.state';
import { RETENTION_CONTACT_TYPE, RETENTION_PRODUCT_TYPE, RETENTION_SOLUTIONS_PROVIDED, RETENTION_REASON_FOR_CANCELLATION_OPTIONS } from '../retentions-main-post-close-modal/assets/rain-main-ticket-options.constant';
import { RETENTION_OUTCOME_OPTIONS, RETENTIONS_NESTED_LEVEL_OPTIONS, HELP_DECLINED, PRODUCT_MIGRATION_FIELD, NO_SOLUTION } from './assets/retentions-options.constants';

@Component({
    selector: 'sf-retentions-modal',
    templateUrl: './retentions-modal.component.html',
    styleUrls: ['./retentions-modal.component.scss']
})
export class RetentionsModalComponent implements OnInit, OnDestroy {
    @Select(ProductsState.isLoading) isLoadingProducts$: Observable<boolean>;

    @ViewChild("outcomeSelect") outcomeSelect: SnowflakeSelectComponent;
    @ViewChild("contactTypeSelect") contactTypeSelect: SnowflakeSelectComponent;
    modalOptions: PostTicketModalOptions;

    nestedMultiSelect: NestedMultiSelect;
    hasSolutionsOffered = false;
    hasOneOrMoreFieldSelected = false;

    showMigrationOptions = false;
    sfSelectMigrationOptions: SnowflakeSelectOption[] = [];
    msisdnOptions: SnowflakeSelectOption<{msisdn: string, id: string}>[] = [];
    outcomeOptions: SnowflakeSelectOption[] = createSFselectOptions(RETENTION_OUTCOME_OPTIONS);
    contactType: SnowflakeSelectOption[] = createSFselectOptions(RETENTION_CONTACT_TYPE);
    productOptions: SnowflakeSelectOption[] = createSFselectOptions(RETENTION_PRODUCT_TYPE);
    solutionProvidedToRetain: SnowflakeSelectOption[] = createSFselectOptions(RETENTION_SOLUTIONS_PROVIDED);
    reasonForCancellation: SnowflakeSelectOption[] = createSFselectOptions(RETENTION_REASON_FOR_CANCELLATION_OPTIONS);
    contactMade: string;

    form: FormGroup;
    labelStyle = "thin-large-font";
    private ngDestroy$: Subject<void> = new Subject();

    constructor(private fb: FormBuilder,
        private store: Store,
        private postTicketService: PostTicketService,
        private customerInfoSummaryService: CustomerInfoSummaryService) {
    }


    ngOnInit(): void {
        this.modalOptions = new PostTicketModalOptions();

        this.form = this.fb.group({
            was_help_declined: this.fb.control(false),
            selected_msisdn: this.fb.control(null, [Validators.required]),
            ticket_outcome: this.fb.control(null),
            migrated_product_id: this.fb.control(null),
            contact_type: this.fb.control(null, [Validators.required]),
            product_type: this.fb.control(null),
            migration_at_50_percent_off: this.fb.control(null),
            customer_previously_in_contact_with_support: this.fb.control(null),
            reason_for_cancellation: this.fb.control(null),
            solutions_provided_to_retain: this.fb.control(null),
        });

        this.msisdnOptions = this.customerInfoSummaryService.getCurrentSimSFOptions();
        if (!(this.msisdnOptions?.length > 0)) {
            //Remove the required validator if there are no msisdns to choose from
            this.form.get("selected_msisdn").removeValidators([Validators.required]);
        }

        this.nestedMultiSelect = new NestedMultiSelect(
            RETENTIONS_NESTED_LEVEL_OPTIONS,
            {
                showSuccessToggle: true,
                isTopLevelOpen: true
            }
        );

        this.listenToFormChanges();
    }

    listenToFormChanges() {
        const outcomeControl = this.form.get("ticket_outcome");

        this.form.get("was_help_declined").valueChanges
            .pipe(takeUntil(this.ngDestroy$))
            .subscribe({
                next: (value: boolean) => {
                    const outcomeValue = outcomeControl?.value;
                    if (outcomeValue && outcomeValue !== HELP_DECLINED) {
                        return;
                    }

                    if (value) {
                        const declineOption = this.outcomeOptions.find(o => o.value === HELP_DECLINED);
                        this.outcomeSelect.selectOption(declineOption);
                    }
                    else {
                        this.outcomeSelect.resetForm();
                        outcomeControl.patchValue(null);
                    }
                }
            })

        this.form.get("contact_type").valueChanges
            .pipe(takeUntil(this.ngDestroy$))
            .subscribe((value) => {
                this.contactMade = value;
                if (this.contactMade === 'No contact made after multiple attempts') {

                    this.form.get("product_type").removeValidators([Validators.required]);
                    this.form.get("solutions_provided_to_retain").removeValidators([Validators.required]);
                    this.form.get("reason_for_cancellation").removeValidators([Validators.required]);
                }
                else {
                    this.form.get("product_type").addValidators([Validators.required]);
                    this.form.get("solutions_provided_to_retain").addValidators([Validators.required]);
                    this.form.get("reason_for_cancellation").addValidators([Validators.required]);
                }
            })
    }


    onSelect(controlName: string, value: string) {
        this.form.get(controlName)?.patchValue(value);
    }


    onOptionSelected($event: { key: string, option: MultiSelectOption }) {
        if ($event.option.label === PRODUCT_MIGRATION_FIELD) {
            this.showMigrationOptions = this.nestedMultiSelect.isValueSelected(PRODUCT_MIGRATION_FIELD);
        }

        this.hasSolutionsOffered = this.nestedMultiSelect.hasOneOrMoreFieldsSelected({ excludeValues: [NO_SOLUTION] });
        this.hasOneOrMoreFieldSelected = this.nestedMultiSelect.hasOneOrMoreFieldsSelected();

        this.updateForm();
    }

    updateForm() {
        this.form.get("was_help_declined").patchValue(!this.hasSolutionsOffered);

        const product_id_control = this.form.get("migrated_product_id");
        if (this.showMigrationOptions) {
            product_id_control.addValidators([Validators.required]);
            product_id_control.patchValue(product_id_control?.value);
        }
        else {
            product_id_control.removeValidators([Validators.required]);
            product_id_control.patchValue(null);
        }
    }

    onMsisdnSelected(value: {msisdn: string, id: string}) {
        this.onSelect("selected_msisdn", value?.msisdn);
    }

    onSend() {
        const data = this.getPayload();

        const payload: ChatJsonNote = {
            message: "Retentions post close form",
            detail: data,
            type: "retentions_form"
        }

        const hexId = this.store.selectSnapshot(InteractionTicketsState.getSelectedHexId);
        this.postTicketService.sendPostTicketPayload(
            hexId, payload, this.modalOptions, this.close.bind(this)
        );
    }

    getPayload() {
        const formValues = this.form.getRawValue();
        if (formValues?.was_help_declined === null) {
            formValues.was_help_declined = false;
        }
        const agentName = this.store.selectSnapshot(CoreState.getAgent)?.name;

        const payload = {};
        const hexId = this.store.selectSnapshot(InteractionTicketsState.getSelectedHexId);
        const shortTicket = this.store.selectSnapshot(InteractionTicketsState.getAssignedShortTicket);
        payload["ticket_hex_id"] = hexId;
        payload["ticket_number"] = shortTicket.id;
        payload["start_time"]=shortTicket.inserted_at;
        payload["completion_time"]=shortTicket.updated_at;
        payload["customer_info"] = { ...this.postTicketService.getRelevantPostTicketCustomerData() };
        payload["agent_info"] = { "agent_name": agentName }
        payload["feedback"] = { ...this.nestedMultiSelect.getInfo(), ...formValues };
        return payload;
    }

    originalOrder = (): number => {
        return 0;
    }

    close() {
        this.store.dispatch(new ToggleModalByName("retentions_close_modal", false));
    }

    ngOnDestroy(): void {
        this.ngDestroy$.next(null);
        this.ngDestroy$.complete();
        this.close();
    }

}
