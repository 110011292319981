import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngxs/store';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { ToggleModalByName } from 'src/app/core/store/actions/modal.actions';
import { CoreState } from 'src/app/core/store/state/core.state';
import { CustomerInfoState } from 'src/app/customer-info-summary-page/store/state/customer-info.state';
import { RainAgent } from 'src/app/shared/components/agent/rain-agent.service';
import { AddTicketEvent } from 'src/app/shared/customer-ticket/store/actions/ticket-event.actions';
import { AddNotePayload } from 'src/app/shared/customer-ticket/ticket-event-handler/assets/add-note-payload.interface';
import { NOTE_CHANNELS, TICKET_STATES } from 'src/app/shared/customer-ticket/ticket-event-handler/assets/ticket.constants';
import { TicketEventHandlerService } from 'src/app/shared/customer-ticket/ticket-event-handler/ticket-event-handler.service';
import { SnowflakeSelectOption } from 'src/app/shared/elements/rain-forms/elements/snowflake-select/interfaces/rain-select-option.interface';
import { createSFselectOptions } from 'src/app/shared/functions/create-sf-select-options.function';
import { SFValidators } from 'src/app/shared/functions/sf-validators';
import { Customer } from 'src/app/shared/interfaces/smartsub-data.interface';
import { ShowContinueButton } from '../../store/actions/continue-flow.actions';
import { InteractionTicketsState } from '../../store/state/interaction-tickets.state';
import { ChatTemplate } from '../assets/chat-template';

type Response = "declined" | "voicemail" | "answered";

const CUSTOMER_ISSUES = [
  "Limit Exceeded",
  "Expired Card",
  "Credit Exceeded",
  "50% Campaign",
  "Missed Payment"
];

@Component({
  selector: 'sf-post-campaign-call-modal',
  templateUrl: './post-campaign-call-modal.component.html',
  styleUrls: ['./post-campaign-call-modal.component.scss']
})
export class PostCampaignCallModalComponent implements OnInit, OnDestroy {

  responded = false;
  response: Response;
  form: FormGroup;
  readonly customerIssues: SnowflakeSelectOption[] = createSFselectOptions(CUSTOMER_ISSUES);

  loading = false;

  private readonly ngDestroy$ = new Subject<void>();

  constructor(private store: Store,
    private ticketEventService: TicketEventHandlerService,
    private fb: FormBuilder,
    private rainAgent: RainAgent) { }


  ngOnInit(): void {
    this.form = this.fb.group({
      customerIssue: this.fb.control(null, Validators.compose([Validators.required]))
    })
  }

  onSelect(option: string) {
    this.form.get("customerIssue").patchValue(option);
  }

  onAnswered() {
    this.addEvent("answered");
    this.close();
    this.store.dispatch(new ShowContinueButton(true));
  }

  private getHexId() {
    return this.store.selectSnapshot(InteractionTicketsState.getCurrentHexId);
  }

  private addEvent(response: Response) {
    return this.store.dispatch(new AddTicketEvent({
      eventComment: `Campaign call result: ${response.toUpperCase()}`,
      hexId: this.getHexId()
    }));
  }

  onNotAnswered(response: Response) {
    this.response = response;
    this.responded = true;
  }

  onSubmit() {
    this.loading = true;

    this.addEvent(this.response)
      .pipe(
        take(1),
        takeUntil(this.ngDestroy$)
      )
      .subscribe({
        next: () => {
          this.loading = false;
          // this.addReplyNote();
          this.rainAgent.continueToNextTicket({ checkOpenTickets: false });
        }
      });
  }

  private addReplyNote() {
    const customer = this.store.selectSnapshot(CustomerInfoState.getCustomer);
    //Unknown customer
    if (!customer) {
      return;
    }

    const message = this.getTemplateMessage(customer);
    const comment = ChatTemplate.formatTextToHTML(message);

    const payload: AddNotePayload = {
      hex_id: this.getHexId(),
      comment,
      channel_id: NOTE_CHANNELS.HTML,
      isPrivate: false,
      state_id: TICKET_STATES.HELD
    };

    this.ticketEventService.addNote(payload);
  }

  private getTemplateMessage(customer: Customer) {
    const { customerIssue } = <{ customerIssue: string }>this.form.getRawValue() ?? {};
    const agentName = this.store.selectSnapshot(CoreState.getAgent)?.name;
    const { account_number, first_name } = customer ?? {};

    SFValidators.warnAboutMissingFields({ customerIssue, agentName }, "Failed to add note.");

    return ChatTemplate.getTemplateMessage({
      category: "Collection Issues",
      option: customerIssue,
      data: {
        customerName: first_name,
        accountNumber: account_number,
        agentName
      }
    });
  }

  private close() {
    this.store.dispatch(new ToggleModalByName("post_campaign_call_modal", false));
  }

  ngOnDestroy(): void {
    this.ngDestroy$.next(null);
    this.ngDestroy$.complete();
    this.close();
  }
}
