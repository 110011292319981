import { Pipe, PipeTransform } from "@angular/core";
import { validationMessages } from "../../elements/rain-forms/pipes/form-errors.pipe";

@Pipe({
  name: 'errorValue'
})
export class ErrorValuePipe implements PipeTransform {
  transform(key: string) {
    if (!key) {
      return;
    }

    return validationMessages[key]
  }
}
