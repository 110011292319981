<sf-popup-modal
  [isOpen]="true"
  [hasCloseButton]="false"
  [autoClose]="false"
  [successResponse]="modalOptions.successResponse"
  [sending]="modalOptions.sending"
  [spinnerMessage]="modalOptions.spinnerMessage"
  [limitHeight]="true">
  <ng-container header>retention questionnaire</ng-container>

  <ng-container mainContent>
    <sf-modal-form-container [formGroup]="form">

      <div *ngIf="!hasSolutionsOffered" class="checkbox-div">
        <sf-form-checkbox [control]="this.form.get('was_help_declined')" label="Was help declined?">
        </sf-form-checkbox>
      </div>

      <sf-select [labelStyle]="labelStyle" label="Services" [options]="msisdnOptions" marginBottom="1rem"
        (onOptionSelect)="onMsisdnSelected($event)" placeholder="select service"
        [hasError]="form.get('selected_msisdn').errors?.notSelected">
      </sf-select>

      <div class="section" *ngFor="let item of nestedMultiSelect.nestedOptions | keyvalue: originalOrder">
        <h3>{{ item.key }}</h3>
        <sf-multi-level-select [multiSelectMap]="item.value.multiSelectMap" (optionSelected)="onOptionSelected($event)">
        </sf-multi-level-select>
      </div>

      <sf-select #outcomeSelect [labelStyle]="labelStyle" label="Ticket Outcome" [options]="outcomeOptions"
        marginBottom="1rem" (onOptionSelect)="onSelect('ticket_outcome', $event)" placeholder="select outcome"
        [hasError]="form.get('ticket_outcome').errors?.notSelected">
      </sf-select>

      <ng-container *ngIf="showMigrationOptions">

        <ng-container *ngIf="(isLoadingProducts$ | async); else showOptions">
          <p class="font-normal">loading migration options...</p>
        </ng-container>

        <ng-template #showOptions>
          <sf-select *ngIf="sfSelectMigrationOptions?.length > 0; else noOptions" label="Migration Package"
            [labelStyle]="labelStyle" [options]="sfSelectMigrationOptions" marginBottom="1rem"
            (onOptionSelect)="onSelect('migrated_product_id', $event)" placeholder="select package customer migrated to"
            [hasError]="form.get('migrated_product_id').errors?.notSelected">
          </sf-select>

          <ng-template #noOptions>
            <p class="red">No migration options to choose.</p>
          </ng-template>
        </ng-template>

      </ng-container>
    </sf-modal-form-container>
  </ng-container>

  <ng-container buttons>
    <button class="rain-button default-button-size send-button solid" (click)="onSend()"
      [disabled]="!form?.valid || modalOptions.sending || !hasOneOrMoreFieldSelected">
      send
    </button>
  </ng-container>
</sf-popup-modal>
