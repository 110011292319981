import { CallStateMessage } from "../call-state-message.interface";
import { CustomVoiceHeaderInfo, I_MOB_EXTRA_HEADERS, SIPHeaders } from "../sip-headers";

const CAMPAIGN_MESSAGE_MAP: { [key in CallStateMessage]?: string } = {
    "connecting call..": "connecting campaign call..",
    "connected": "campaign call in progress.."
}


export function isCampaign(headers: SIPHeaders) {
    return I_MOB_EXTRA_HEADERS["Q-Campaign"] in headers ?? {};
}

export function getCustomerFromHeader(headers: SIPHeaders) {
    const customerHeader = headers?.[I_MOB_EXTRA_HEADERS["Q-Cname"]];
    return customerHeader?.[0]?.raw;
}

export function getCustomHeaderInfo(headers: SIPHeaders) {
    const info: Partial<CustomVoiceHeaderInfo> = {};

    Object.keys(I_MOB_EXTRA_HEADERS)
        .forEach(key => {
            const value = headers?.[key]?.[0]?.raw;
            if (value) {
                info[key] = value;
            }
        });

    return info;
}

export function getCampaignMessage(callStateMessage: CallStateMessage) {
    return CAMPAIGN_MESSAGE_MAP[callStateMessage] ?? callStateMessage;
}

