import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Utils } from 'src/app/Utils';
import { ApiOptions, ApiResponse } from './api-response.interface';


@Injectable({
  providedIn: 'root'
})
export class ApiControllerService {

  constructor(private http: HttpClient) { }

  async get(requestPath: string, httpOptions?: ApiOptions, retries?: number): Promise<ApiResponse<any>> {
    return this.convertResponse(
      this.http.get(requestPath, { ...httpOptions, observe: 'response' }).toPromise(), retries
    );
  }

  getV2<T>(requestPath: string, httpOptions: ApiOptions): Observable<ApiResponse<T>> {
    return this.http.get<ApiResponse<any>>(requestPath, httpOptions)
  }

  async post<T = any>(requestPath: string, httpOptions: ApiOptions, body: any, retries?: number): Promise<ApiResponse<T>> {
    return this.convertResponse(
      this.http.post(requestPath, body, { ...httpOptions, observe: 'response' }).toPromise(), retries
    );
  }
  postV2(requestPath: string, httpOptions: ApiOptions, body: any): Observable<any> {
    return this.http.post<any>(requestPath, body, { ...httpOptions })
  }

  async patch(requestPath: string, httpOptions: ApiOptions, body: any): Promise<ApiResponse<any>> {
    return this.convertResponse(
      this.http.patch(requestPath, body, { ...httpOptions, observe: 'response' }).toPromise()
    );
  }

  async put(requestPath: string, httpOptions: ApiOptions, body: any) {
    return this.convertResponse(
      this.http.put(requestPath, body, { ...httpOptions, observe: 'response' }).toPromise()
    );
  }

  async delete(requestPath: string, httpOptions: ApiOptions) {
    return this.convertResponse(
      this.http.delete(requestPath, { ...httpOptions, observe: 'response' }).toPromise()
    );
  }

  private async convertResponse(promise: Promise<any>, retries = 0): Promise<ApiResponse<any>> {
    const apiResponse: ApiResponse<any> = { data: null, errorMessage: null };
    let count = 0;
    let success = false;
    let error: HttpErrorResponse = null;
    while (count <= retries && !success) {
      count += 1;
      try {
        const returnData = await promise;
        apiResponse.data = returnData.body;
        apiResponse.status = returnData.status;
        error = null;
        success = true;
      } catch (err) {
        const e: HttpErrorResponse = err;
        error = e;
        apiResponse.status = e?.status;
        apiResponse.errorMessage = {
          error: Utils.Helpers.findError(e, ""),
          message: e?.message,
          text: e?.error?.text
        };
      }
    }
    if (error) {
      console.error(error);
      console.error(`tried this api call ${count} times`);
    }
    return apiResponse;
  }

  getNoDataResponse(message?: string): ApiResponse<any> {
    const error = message ? message : "no data";
    return {
      data: null,
      errorMessage: {
        error: error
      },
      status: 204
    }
  }

}

