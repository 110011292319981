

export const calculateBuffered = (audio: HTMLAudioElement) => audio.buffered.end(audio.buffered.length - 1);

export const calculateSeekable = (audio: HTMLAudioElement) => audio.seekable.end(audio.seekable.length - 1);

export const formatDuration = (seconds: number, withHours = false): string => {

    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;

    const minsAndSecs = `${minutes.toString().padStart(2, "0")}:${secs.toString().padStart(2, "0")}`;

    if (!withHours && hours === 0) {
        return minsAndSecs;
    }
    return `${hours.toString().padStart(2, "0")}:${minsAndSecs}`;
}