

export class SoundHandler {

  static toggleSound(soundElement: HTMLAudioElement, play: boolean) {
    if (!soundElement) {
      return;
    }
    play ? soundElement?.play() : soundElement?.pause();
  }

}
