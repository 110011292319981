import { Utils } from "src/app/Utils";
import { RespondToTicketMetaData, RespondToTicketPayload, SFResponseType } from "../../types/respond-to-ticket-payload.interface";


export class AcceptTicketInCtx {
    static readonly type = Utils.Helpers.Type("[Agent View Ticket Response] Accept");
}

export class RejectTicketInCtx {
    static readonly type = Utils.Helpers.Type("[Agent View Ticket Response] Reject");
}

export class RespondToTicket {
    static readonly type = Utils.Helpers.Type("[Agent View Ticket Response] Respond");
    constructor(public payload: RespondToTicketPayload) { }
}

export class RespondToTicketSuccess {
    static readonly type = Utils.Helpers.Type("[Agent View Ticket Response] Respond success");
    constructor(public type: SFResponseType, public metaData: RespondToTicketMetaData) { }
}

export class RespondToTicketFail {
    static readonly type = Utils.Helpers.Type("[Agent View Ticket Response] Respond fail");
    constructor(public type: SFResponseType, public error: unknown) { }
}