import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RainMultiSelectComponent } from './rain-multi-select.component';

const declarations = [
    RainMultiSelectComponent
];

@NgModule({
    declarations,
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
    ],
    exports: declarations
})
export class RainMultiSelectModule { }

