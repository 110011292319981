import { Component } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { ToggleAcceptingTickets } from '../store/actions/agent.actions';
import { AgentState } from '../store/state/agent.state';
import { Observable } from 'rxjs';

@Component({
  selector: 'sf-agent-ticket-toggle',
  templateUrl: './agent-ticket-toggle.component.html',
  styleUrls: ['./agent-ticket-toggle.component.scss']
})
export class AgentTicketToggleComponent {

  @Select(AgentState.isAcceptingTickets) acceptingTickets$: Observable<boolean>;

  constructor(private store: Store) { }

  onToggle() {
    this.store.dispatch(new ToggleAcceptingTickets());
  }

}
