import { Component, Input, OnInit } from '@angular/core';
import { formatDate } from '@angular/common';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LoadsheddingPerSimActions } from '../store/actions/loadshedding-per-sim-actions';
import { NewLoadsheddingPerSimState } from '../store/state/new-loadshedding-per-sim.state';


@Component({
  selector: 'sf-new-loadshedding-icon',
  templateUrl: './new-loadshedding-icon.component.html',
  styleUrls: ['./new-loadshedding-icon.component.scss']
})
export class NewLoadsheddingIconComponent implements OnInit {

  @Input() id: string;

  isLoading$: Observable<boolean>;
  error$: Observable<string>;
  message$: Observable<string>;

  constructor(private store: Store) { }

  ngOnInit(): void {

    this.isLoading$ = this.store.select(NewLoadsheddingPerSimState.isLoading(this.id));
    this.error$ = this.store.select(NewLoadsheddingPerSimState.getError(this.id));

    this.message$ = this.store.select(NewLoadsheddingPerSimState.getData(this.id))
      .pipe(
        map(info => {
          const { loadshedding_information, tower_type } = info ?? {};
          const { is_loadshedding, start_time, end_time } = loadshedding_information ?? {};

          if (!is_loadshedding) {
            return;
          }



          if (!start_time || !end_time) {
            return `Loadshedding on ${tower_type ?? 'current'} site`;
          }

          return `Loadshedding on ${tower_type ?? 'current'} site from ${this.formatDate(start_time)} to ${this.formatDate(end_time)}`;
        })
      )


    this.store.dispatch(new LoadsheddingPerSimActions.Init(this.id));
  }

  private formatDate(num: number) {
    if (typeof num === "number") {
      return formatDate(num, "H:mm EEEE", "en-ZA");
    }
    return num;
  }

}
