import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, } from '@ngxs/store';
import { tap, takeUntil, take } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { DataLoading, getDataLoadingDefaultValues } from 'src/app/shared/interfaces/data-loading.interface';
import { Utils } from 'src/app/Utils';
import { DataLoadingHelper } from 'src/app/Utils/helpers';
import { PickupPointActions } from '../action/pickup-point-actions';
import { PickupPointService } from '../../sales-portal/services/pickup-point.service';
import { SalesPortalDestroyed } from '../action/sales-portal.actions';
import { FormattedPickupPoint, PickupPoint } from '../interfaces/pickup-point.interface';


interface PickupPointsStateModel extends DataLoading<PickupPoint[]> {
    addressPostalCode: string;
    selectedLocationId: string;
}

const getDefaults = (): PickupPointsStateModel => {
    return {
        ...getDataLoadingDefaultValues([]),
        addressPostalCode: null,
        selectedLocationId: null
    }
}

@State<PickupPointsStateModel>({
    name: 'sf_pickup_points_state',
    defaults: getDefaults()

})
@Injectable()
export class PickupPointsState {

    @Selector()
    static isLoading(state: PickupPointsStateModel) { return state.loading }

    @Selector()
    static isLoaded(state: PickupPointsStateModel) { return state.loaded }

    @Selector()
    static getData(state: PickupPointsStateModel) { return state.data }

    @Selector()
    static getAddressPostalCode(state: PickupPointsStateModel) { return state.addressPostalCode }

    @Selector()
    static getSelectedLocationId(state: PickupPointsStateModel) { return state.selectedLocationId }

    @Selector()
    static getError(state: PickupPointsStateModel) { return state.error }

    @Selector([PickupPointsState.getError])
    static hasData(error: string) { return !error }

    @Selector([PickupPointsState.getSelectedLocationId, PickupPointsState.getData])
    static getSelectedPickupPoint(locationId: string, pickupPoints: PickupPoint[]) {
        if (!locationId || !pickupPoints?.length) {
            return null;
        }
        return pickupPoints?.find(point => point?.locationId === locationId)
    }

    @Selector([PickupPointsState.getSelectedPickupPoint])
    static getSelectedAddress(pickupPoint: PickupPoint) { return pickupPoint?.address }

    @Selector([PickupPointsState.getData, PickupPointsState.getAddressPostalCode])
    static pointsInPostalCodeArea(pickupPoints: PickupPoint[], selectedPostalCode: string) {
        return pickupPoints?.filter(point => point?.postal_codes_string?.includes(selectedPostalCode));
    }

    @Selector([PickupPointsState.pointsInPostalCodeArea, PickupPointsState.getSelectedLocationId])
    static formattedPickupPoints(pickupPoints: PickupPoint[], selectedLocationId: string): FormattedPickupPoint[] {
        return pickupPoints?.map(point => {
            const selected = point?.locationId === selectedLocationId;
            return {
                selected,
                ...point
            }
        });
    }

    private readonly _cancelRequest$ = new Subject<null>();

    constructor(private pickupPointService: PickupPointService) {
    }

    @Action(PickupPointActions.FetchIfNotLoadingOrLoaded)
    FetchIfNotLoadingOrLoaded(ctx: StateContext<PickupPointsStateModel>,) {
        const { loading, loaded } = ctx.getState();
        if (!loading || loaded) {
            return ctx.dispatch(new PickupPointActions.Fetch());
        }
    }

    @Action(PickupPointActions.Fetch)
    Fetch(ctx: StateContext<PickupPointsStateModel>) {
        ctx.patchState({ loading: true });

        return this.pickupPointService.fetchPickupLocations()
            .pipe(
                tap({
                    next: res => ctx.dispatch(new PickupPointActions.FetchSuccess(res)),
                    error: (e: unknown) => ctx.dispatch(new PickupPointActions.FetchFail(e))
                }),
                takeUntil(this._cancelRequest$.pipe(take(1)))
            );
    }


    @Action(PickupPointActions.FetchSuccess)
    FetchSuccess(ctx: StateContext<PickupPointsStateModel>, action: PickupPointActions.FetchSuccess) {
        const { payload } = action;
        DataLoadingHelper.setData(ctx, payload);
    }


    @Action(PickupPointActions.FetchFail)
    FetchFail(ctx: StateContext<PickupPointsStateModel>, action: PickupPointActions.FetchFail) {
        const error = Utils.Helpers.findError(action.error, '');
        DataLoadingHelper.setError(ctx, error);
    }

    @Action(PickupPointActions.SetAddressPostalCode)
    SetAddressPostalCode(ctx: StateContext<PickupPointsStateModel>, action: PickupPointActions.SetAddressPostalCode) {
        ctx.patchState({
            addressPostalCode: action.postalCode
        });
    }

    @Action(PickupPointActions.SetSelectedLocationId)
    SetSelectedLocationId(ctx: StateContext<PickupPointsStateModel>, action: PickupPointActions.SetSelectedLocationId) {
        ctx.patchState({
            selectedLocationId: action.locationId
        });
    }

    @Action(PickupPointActions.ClearSelectedOptions)
    ClearSelectedOptions(ctx: StateContext<PickupPointsStateModel>) {
        ctx.patchState({
            selectedLocationId: null,
            addressPostalCode: null
        })
    }

    @Action([PickupPointActions.Clear, SalesPortalDestroyed])
    Clear(ctx: StateContext<PickupPointsStateModel>) {
        this._cancelRequest$.next(null);
        ctx.setState(getDefaults());
    }

}   