import { Utils } from "src/app/Utils";
import { Callback } from "../../types/callback-response.interface";

export class FetchByIdIfNotPresent {
    static readonly type = Utils.Helpers.Type("[Customer Callback] Fetch by ID if not present");
    constructor(public callbackId: string) { }
}

export class FetchById {
    static readonly type = Utils.Helpers.Type("[Customer Callback] Fetch by ID");
    constructor(public callbackId: string) { }
}

export class FetchByIdSuccess {
    static readonly type = Utils.Helpers.Type("[Customer Callback] Fetch by ID success");
    constructor(public payload: Callback) { }
}

export class FetchByIdFail {
    static readonly type = Utils.Helpers.Type("[Customer Callback] Fetch by ID fail");
    constructor(public error: unknown) { }
}

export class SetCallbackData {
    static readonly type = Utils.Helpers.Type("[Customer Callback] Set Callback Data");
    constructor(public payload: Callback) { }
}

export class Clear {
    static readonly type = Utils.Helpers.Type("[Customer Callback] Clear");
}