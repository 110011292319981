import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { DataHandler } from '../../data-handler/data-handler';
import { LoadsheddingPerSiteResponse } from './store/interfaces/loadshedding-per-site-response.interface';
import { LoadsheddingReport } from './store/interfaces/loadshedding-report.interface';
import { LoadsheddingSchedulePerSite } from './store/interfaces/loadshedding-schedule-per-site';
import { NewLoadsheddingPerSite } from './store/interfaces/new-loadshedding-per-site.interface';

@Injectable({
  providedIn: 'root'
})
export class LoadsheddingService {

  constructor(private http: HttpClient) { }

  getLoadsheddingStatusBySiteID(siteID: number) {
    const url = `${environment.eskom_se_push}/api_site_status.php?site_id=${siteID}`;
    return this.http.get<LoadsheddingPerSiteResponse>(url, { observe: "response" });
  }

  getLoadsheddingScheduleForSiteID(siteID: number, date: string, stage?: number) {
    let url = `${environment.eskom_se_push}/api_site_schedule.php?site_id=${siteID}&date=${date}`;
    if (DataHandler.isDefined(stage)) {
      url += `&stage=${stage}`;
    }
    return this.http.get<LoadsheddingSchedulePerSite>(url, { observe: "response" });
  }

  getLoadsheddingReport() {
    const url = `${environment.eskom_se_push}/api_status.php`;
    return this.http.get<LoadsheddingReport>(url, { observe: "response" });
  }

  getLoadsheddingForMSISDN(msisdn: string) {
    const url = `${environment.bssEndpoint}/partner/smartsub/1/north/service/service-connection/service/${msisdn}/connection/v2`;

    const headers = {
      "Content-Type": "application/json",
      apiKey: environment.apigeeApiKey
    };

    return this.http.get<NewLoadsheddingPerSite>(url, { headers });
  }
}
