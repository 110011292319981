import { Injectable } from '@angular/core';
import { from } from 'rxjs';
import { CustomResolver } from '../../models/custom-resolver/custom-resolver.model';
@Injectable({
  providedIn: 'root'
})
export class NewMapService {
  private _mapResolver = new CustomResolver<google.maps.Map>();
  async getMap() {
    return this._mapResolver.getData();
  }
  setMap(map: google.maps.Map) {
    this._mapResolver.setData(map);
  }

  getMapObs() {
    return from(this.getMap());
  }


}
