import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';

@Component({
    selector: 'sf-toggle-button',
    templateUrl: './toggle-button.component.html',
    styleUrls: ['./toggle-button.component.scss']
})
export class ToggleButtonComponent implements OnInit {

    @Output() toggle = new EventEmitter<void>();

    @Input() label: string
    @Input() disabled: boolean
    @Input() toggled: boolean
    @Input() headerToggle: boolean

    toggleControl: FormControl

    constructor(private fb: FormBuilder) { }

    ngOnInit(): void {
        this.toggleControl = this.fb.control(this.toggled)
    }

    setToggleValue() {
        this.toggleControl.setValue(this.toggled)
    }

    onToggle() {
        this.toggle.next(null);
    }
}
