import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';

@Component({
  selector: 'sf-modal-template',
  templateUrl: './modal-template.component.html',
  styleUrls: ['./modal-template.component.scss']
})
export class ModalTemplateComponent {
  @Input() isOpen = false;
  @Input() title: string;
  @Output() onClose: EventEmitter<any> = new EventEmitter();

  @HostListener('click', ['$event'])
  private clickOutside(e) {
    const target: HTMLElement = e.target;

    if (target.classList.contains('is-open')) {
      this.close();
    }
  }

  public close() {
    return this.onClose.emit(true);

  }

}
