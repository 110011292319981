import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Inject, OnDestroy } from '@angular/core';
import { MsalService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';
import { AccountInfo, AuthenticationResult, InteractionType, PopupRequest, RedirectRequest } from '@azure/msal-browser';
import { Store } from '@ngxs/store';
import { Observable, Subject } from 'rxjs';
import { retry, takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AUTHORITY } from '../constants';
import { AzureResponse } from '../core/interfaces/azure-response.interface';
import { ClearPersistentStorageItem } from '../core/store/actions/persistent-storage.actions';
import { AzureProfile, FullAzureProfile } from '../profile/assets/azure-profile.interface';
import { CustomResolveHandler } from '../shared/models/custom-resolver/custom-resolve-handler.model';


@Injectable({
    providedIn: 'root'
})

export class AuthService implements OnDestroy {

    profile: FullAzureProfile;
    tokenLoaded = new Subject<boolean>();
    userName: string;
    idToken = "";
    private readonly _destroying$ = new Subject<void>();

    resolveHandler = new CustomResolveHandler();

    constructor(
        @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
        private msalAuthService: MsalService,
        private http: HttpClient,
        private store: Store
    ) { }


    async getProfile() {
        if (this.profile) return this.profile;
        const GRAPH_ENDPOINT = 'https://graph.microsoft.com/v1.0/me';
        this.profile = await this.http.get<FullAzureProfile>(GRAPH_ENDPOINT).toPromise();
        return this.profile;
    }

    private setHasLoaded() {
        this.resolveHandler.resolve(true);
    }


    setLoginStatus() {
        return this.msalAuthService.instance.getAllAccounts().length > 0;
    }

    login() {
        if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
            if (this.msalGuardConfig.authRequest) {
                this.msalAuthService.loginPopup({ ...this.msalGuardConfig.authRequest } as PopupRequest)
                    .pipe(takeUntil(this._destroying$))
                    .subscribe((response: AuthenticationResult) => {
                        this.msalAuthService.instance.setActiveAccount(response.account);
                    });
            }
            else {
                this.msalAuthService.loginPopup()
                    .pipe(takeUntil(this._destroying$))
                    .subscribe((response: AuthenticationResult) => {
                        this.msalAuthService.instance.setActiveAccount(response.account);
                    });
            }
        }
        else {
            if (this.msalGuardConfig.authRequest) {
                this.msalAuthService.loginRedirect({ ...this.msalGuardConfig.authRequest } as RedirectRequest);
            }
            else {
                this.msalAuthService.loginRedirect();
            }
        }
    }

    logout() {

        const confirmation = confirm("WARNING! \n This action will log you out of ALL Microsoft applications!")
        if (confirmation) {
            if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
                this.store.dispatch(new ClearPersistentStorageItem("agent-roles"));
                this.msalAuthService.logoutPopup({
                    postLogoutRedirectUri: "/",
                    mainWindowRedirectUri: "/"
                });
            } else {
                this.msalAuthService.logoutRedirect({
                    postLogoutRedirectUri: "/",
                });
            }
        }
    }

    setRainAuthHeaders() {
        return new HttpHeaders({
            Authorization: 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoiZmY1YTU5OGEtYTA5Mi00MTAzLWFiNmQtNTRhMjVkMTUwMWJlIiwiZXhwIjoxNjQ1NjA5MTM2fQ.JWtINeXTcJM2cTcXzgXMh2w89MRifzdiCIVcEPcyqCU',
            'apiKey': 'LrQ2oFL4NNo9jgXdOey7DGjuQoyd3xpH'
        });
    }

    async getUserName() {
        if (this.userName) return this.userName;
        const profile = await this.getProfile();
        this.userName = profile?.mail;
        return this.userName;
    }

    getAllAgents(): Observable<AzureResponse<AzureProfile>> {
        const GRAPH_ENDPOINT = 'https://graph.microsoft.com/v1.0/users';
        return this.http.get<AzureResponse<AzureProfile>>(GRAPH_ENDPOINT);
    }

    getAllAzureUsers(accessToken: string) {
        const authHeader = new HttpHeaders({
            Authorization: 'Bearer ' + accessToken,
        })
        const GRAPH_ENDPOINT = 'https://graph.microsoft.com/v1.0/users';
        return this.http.get<AzureResponse<AzureProfile>>(GRAPH_ENDPOINT, { headers: authHeader, observe: "response" });
    }


    //TODO: temp
    getAzureGroups(accessToken: string) {
        const authHeader = new HttpHeaders({
            Authorization: 'Bearer ' + accessToken,
        })
        const GRAPH_ENDPOINT = 'https://graph.microsoft.com/v1.0/groups';
        return this.http.get<AzureResponse<AzureProfile>>(GRAPH_ENDPOINT, { headers: authHeader, observe: "response" }).toPromise();
    }


    async setPayloaddetails(payload: AuthenticationResult) {
        this.idToken = payload.idToken;
        this.userName = payload.account.username;
        this.setHasLoaded();
        this.tokenLoaded.next(null);
    }

    getRefreshedToken(accountInfo: AccountInfo, scopes: string[]) {
        return this.msalAuthService.acquireTokenSilent({
            scopes,
            authority: AUTHORITY,
            redirectUri: environment.redirectUri,
            account: accountInfo,
            forceRefresh: true
        })
            .pipe(retry(3));
    }


    ngOnDestroy(): void {
        this._destroying$.next(null);
        this._destroying$.complete();
    }


}
