import { Invitation, Inviter, SessionState } from "sip.js";
import { SipSessionHandlerConfig } from "./sip-session-handler.config";
import { SoundHandler } from "./sound-handler.model";
import { Store } from "@ngxs/store";
import { SetInCall, SetConnectedPostCall, SetNonConnectedPostCall } from "src/app/shared/components/agent/store/actions/agent-status-actions/agent-status.actions";
import { SipCallType } from "../sip-call.type";
import { InteractionTicketsState } from "src/app/interactions/store/state/interaction-tickets.state";
import { AddTicketEvent } from "src/app/shared/customer-ticket/store/actions/ticket-event.actions";
import { CALL_ENDED, CALL_ID_STR } from "src/app/constants";
import { CallTimerActions } from "../../store/actions/call-timer-actions";
import { CallTimerState } from "../../store/state/call-timer.state";
import { SipPhoneState } from "../../store/state/sip.state";

//TODO: refactor this whole thing

export class SipSessionHandler {

  private _callWasEstablished = false;
  private _wasEstablishing = false;

  private _callId: string;

  constructor(private _store: Store,
    private _voiceElement: HTMLAudioElement,
    private _ringElement: HTMLAudioElement,
    private _callType: SipCallType) {
  }

  handleSession(session: Invitation | Inviter, config?: SipSessionHandlerConfig) {
    this._callId = session?.request?.callId;

    const listener = (state: SessionState) => {
      switch (state) {
        case SessionState.Initial:
          break;

        case SessionState.Establishing:
          this._wasEstablishing = true;
          break;

        case SessionState.Established:
          this.startTimer();

          this.toggleSounds(false);
          this.setupVoice(session);

          this._store.dispatch(new SetInCall(this._callType));
          this._callWasEstablished = true;
          break;

        case SessionState.Terminating:
          break;

        case SessionState.Terminated:
          this.stopTimer();

          this.postCallCleanup(config?.onCleanupFunction);
          session.stateChange.removeListener(listener);
          break;

        default:
          throw new Error("Unknown session state.");
      }

      if (config?.onChangeFunction) {
        config?.onChangeFunction(state);
      }
    }

    this.toggleSounds(true);
    session?.stateChange?.addListener(listener);
  }

  private postCallCleanup(cleanupFunction?: SipSessionHandlerConfig["onCleanupFunction"]) {
    if (cleanupFunction) {
      cleanupFunction();
    }

    this.toggleSounds(false);
    this.cleanupVoiceElement();

    if (this._callWasEstablished) {

      const durationInSeconds = this._store.selectSnapshot(CallTimerState.getLastCallDurationInSeconds);
      this._store.dispatch(new SetConnectedPostCall(this._callType, durationInSeconds, this._callId));
      this.logConnectedCallEnded();

    }
    else {
      this._store.dispatch(new SetNonConnectedPostCall(this._callType, this._wasEstablishing));
    }
  }

  private toggleSounds(play: boolean) {
    SoundHandler.toggleSound(this._ringElement, play);
  }

  private setupVoice(session: any) {
    const peerConnection = session.sessionDescriptionHandler.peerConnection;
    const localStream = new MediaStream();

    peerConnection.getReceivers()
      .forEach((sender) => {
        localStream.addTrack(sender.track)
      });

    this._voiceElement.srcObject = localStream;
    this._voiceElement.play();
  }

  private cleanupVoiceElement() {
    if (!this._voiceElement) {
      return;
    }
    this._voiceElement.srcObject = null;
    this._voiceElement.pause();
  }

  private startTimer() {
    this._store.dispatch(new CallTimerActions.StartTimer());
  }

  private stopTimer() {
    this._store.dispatch(new CallTimerActions.StopTimer());
  }

  private logConnectedCallEnded() {
    const currentTicketId = this._store.selectSnapshot(InteractionTicketsState.getCurrentHexId);
    if (currentTicketId) {

      const durationInSeconds = this._store.selectSnapshot(CallTimerState.getLastCallDurationInSeconds);

      const { phoneNumber } = this._store.selectSnapshot(SipPhoneState.getIncomingCallDetails) ?? {}

      this._store.dispatch(new AddTicketEvent({
        hexId: currentTicketId,
        eventComment: `${CALL_ENDED}. Duration: ${durationInSeconds} seconds. ${CALL_ID_STR}: ${this._callId}. Phone number: ${phoneNumber}`
      }));

    }
  }

}
