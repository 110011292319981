import { CustomerTicket, EntityTicket } from './interfaces/customer-ticket.interface';
import { TicketNoteHelper } from './ticket-note-helper';
import { NOTE_CHANNELS, TICKET_STATES } from './ticket-event-handler/assets/ticket.constants';
import { PostSalesModalForm } from 'src/app/interactions/chat-interaction/post-sales-modal/assets/post-sales-modal-form.interface';
import { TicketFunctions } from 'src/app/interactions/store/assets/ticket-functions';


export class CustomerTicketHelper {
  customerTicket: CustomerTicket;
  customerEmail: string;
  noteHelper: TicketNoteHelper;

  constructor(customerTicket: CustomerTicket) {
    this.customerTicket = customerTicket;
    this.noteHelper = new TicketNoteHelper(this.customerTicket);
    this.setFields();
  }

  private setFields() {
    this.customerEmail = this.getEmailFromTicket();
  }

  private getEmailFromTicket() {
    const entityTickets: EntityTicket[] = this.customerTicket?.entity_ticket;
    if (!entityTickets || entityTickets?.length < 1) {
      return null;
    }

    for (let i = 0; i < entityTickets.length; i++) {
      const entityTicket = entityTickets[i];
      if (entityTicket?.entity_type?.description === 'Requester') {
        return entityTicket?.related_entity?.email;
      }
    }
    return null;
  }

  doesPrivateNoteExistForAgent(agentEmail: string) {
    if (!agentEmail) {
      return false;
    }

    const noteArray = this.customerTicket?.notes;
    const correctChannels = ['HTML', 'Unknown'];

    for (const note of noteArray) {
      const isPrivate = note?.private;
      const matchingEmail =
        note?.related_entity?.email?.toLowerCase() ===
        agentEmail.toLowerCase();
      const isCorrectChannel = correctChannels.includes(
        note?.channel?.channel_name,
      );

      if (isPrivate && matchingEmail && isCorrectChannel) {
        return true;
      }
    }
    return false;
  }

  hasSuccessfulSale() {
    const { notes } = this.customerTicket ?? {};
    if (!notes?.length) {
      return false;
    }

    for (const note of notes) {
      //For backwards compatibility of older tickets
      if (note?.comment === 'Sale Successful form sent.') {
        return true;
      }
      if (note?.channel_id !== NOTE_CHANNELS.WORKAROUND_FORMS) {
        //Skip non JSON notes
        continue;
      }
      const salesFormData = TicketFunctions.getChatJsonNote<PostSalesModalForm>(note?.comment);
      if (salesFormData?.detail?.agent_order_status === "Sale Successful") {
        return true;
      }
    }
    return false;
  }

  doesEscalationExistFromAgent(agentEmail: string) {
    if (!agentEmail) {
      return false;
    }
    const notes = this.customerTicket?.['notes'];
    if (!notes) {
      return;
    }
    for (let i = 0; i < notes.length; i++) {
      const commentStringArray = notes[i]?.comment.split(' ');
      if (
        commentStringArray[0] === 'escalated' &&
        notes[i]?.related_entity?.email?.toLowerCase() ==
        agentEmail.toLowerCase()
      ) {
        return true;
      }
    }
    return false;
  }

  doesPublicNoteExistForAgent(agentEmail: string) {
    if (!agentEmail) {
      return false;
    }
    const noteArray = this.customerTicket?.notes;

    const correctChannels = ['HTML', 'Unknown'];

    for (const note of noteArray) {
      const isPrivate = note?.private;

      const matchingEmail =
        note?.related_entity?.email?.toLowerCase() ===
        agentEmail.toLowerCase();
      const isCorrectChannel = correctChannels.includes(
        note?.channel?.channel_name,

      );

      if (!isPrivate && matchingEmail && isCorrectChannel) {
        return true;
      }
    }
    return false;
  }


  isTicketClosed() {
    return this.customerTicket.state_id === TICKET_STATES.CLOSED;
  }
}
