import { MVCObject } from "@agm/core/services/google-maps-types";


export class MultiLayer {

  private _visible = false;
  private _listeners: google.maps.MapsEventListener[] = [];

  constructor(private map: google.maps.Map, public circle: google.maps.Circle, public polygons: google.maps.Polygon[]) {
    this.addListeners();
  }

  toggleVisibility() {
    this.setVisibility(!this._visible);
  }

  setVisibility(visible: boolean) {
    this._visible = visible;
    this.setMapForLayers(visible ? this.map : null);
  }

  private setMapForLayers(map: google.maps.Map | null) {
    this.circle.setMap(map);
    this.polygons.forEach(item => item.setMap(map));
  }

  private addListeners() {
    const mvcObjects: MVCObject[] = [this.circle, ...this.polygons];
    mvcObjects.forEach(item => {
      const listener = item.addListener("click", () => {
        this.setVisibility(false);
      });
      this._listeners.push(listener);
    })
  }

  removeAllListeners() {
    this._listeners.forEach(item => item?.remove());
  }
}
