<ng-container *ngIf="emails.length">
    <mat-icon
        class="pointer"
        [matTooltip]="description"
        matTooltipClass="multiToolTip"
    >
        remove_red_eye
    </mat-icon>
    <span
        class="badge"
        [matBadge]="emails.length"
        matBadgePosition="above after"
        matBadgeColor="warn"
        matBadgeSize="medium"
        matBadgeOverlap="true"
    >
    </span>
</ng-container>