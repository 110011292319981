import { Utils } from "src/app/Utils";
import { LogAgentStatusPayload } from "../../interfaces/log-agent-status-payload.interface";


export class LogStatus {
    static readonly type = Utils.Helpers.Type('[Core: Agents] Log current agent status');
    constructor(public payload: LogAgentStatusPayload) { }
}

export class LogStatusSuccess {
    static readonly type = Utils.Helpers.Type('[Core: Agents] Log current agent status success');
}

export class LogStatusFail {
    static readonly type = Utils.Helpers.Type('[Core: Agents] Log current agent status fail');
}

