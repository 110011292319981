import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, createSelector, } from '@ngxs/store';
import { tap, takeUntil, take } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { DataLoading, getDataLoadingDefaultValues } from 'src/app/shared/interfaces/data-loading.interface';
import { Utils } from 'src/app/Utils';
import { DataLoadingHelper } from 'src/app/Utils/helpers';
import { ProductService } from 'src/app/core/services/products.service';
import { ProductCatalogActions } from '../../actions/product-catalog-actions';
import { CatalogBundle, CatalogDevice, CatalogISim, CatalogProduct, CatalogProductBase, CatalogSimProduct, CatalogSkin, FullCatalogBundle, ProductCatalogMap, ProductCatalogType, RainPaymentType } from '../../interfaces/product-catalog.interfaces';
import { Dictionary } from 'src/app/shared/interfaces/dictionary.interface';
import { DataHandler } from 'src/app/shared/data-handler/data-handler';
import { TechType } from 'src/app/shared/services/google-maps/assets/techtype.type';
import { SFValidators } from 'src/app/shared/functions/sf-validators';
import { isRainOne101Bundle, isRainOneOffPeakBundle, isRainOneWorkBundle } from './assets/product-functions/product.functions';
import { SalesSettingsState } from 'src/app/sales/store/state/shared-sales-states/sales-settings.state';
import { SalesCartState } from 'src/app/sales/store/state/sales-cart.state';
import { STAND_ALONE_4G } from 'src/app/constants';

const getDefaults = (): ProductCatalogStateModel => {
    return {
        ...getDataLoadingDefaultValues(),
        products: []
    }
}

export interface ProductCatalogStateModel extends DataLoading<ProductCatalogMap> {
    products: CatalogProduct[];
}



@State<ProductCatalogStateModel>({
    name: 'sf_product_catalog_state',
    defaults: getDefaults()
})
@Injectable()
export class ProductCatalogState {

    @Selector()
    static isLoading(state: ProductCatalogStateModel) { return state.loading }

    @Selector()
    static isLoaded(state: ProductCatalogStateModel) { return state.loaded }

    @Selector()
    static getData(state: ProductCatalogStateModel) { return state.data }

    @Selector()
    static getCatalogProducts(state: ProductCatalogStateModel) { return state.products }

    @Selector()
    static getProductsMappedById<T extends CatalogProduct = CatalogProduct>(state: ProductCatalogStateModel) {
        return <Dictionary<T>>Utils.Mappers.toHashMapV2(state.products, "id");
    }

    static getProductsByTypeMappedById<T extends ProductCatalogType>(type: T) {
        return createSelector(
            [ProductCatalogState.getProductsByType(type)],
            (products: CatalogProduct[]) => Utils.Mappers.toHashMapV2<ProductCatalogMap[T][0]>(products, "id")
        );
    }

    static getProductsByType<T extends ProductCatalogType>(type: T): (catalogMap: ProductCatalogMap) => ProductCatalogMap[T] {
        return createSelector(
            [ProductCatalogState.getData],
            (catalogMap: ProductCatalogMap) => catalogMap?.[type]
        );
    }

    static getProductByIdAndType<T extends ProductCatalogType>(id: string, type: T) {
        return createSelector(
            [ProductCatalogState.getProductsByType(type)],
            (products: CatalogProduct[]) =>
                products?.find((product: CatalogProductBase) => product?.id === id) as ProductCatalogMap[T][0]
        );
    }

    @Selector([ProductCatalogState.getProductsByType("accessory")])
    static get101Skins(accessories: CatalogSkin[]) {
        return accessories?.filter((product: CatalogSkin) => {
            return SFValidators.isDefined(product?.config?.color) && SFValidators.isDefined(product?.config?.hexColor)}
        );
    }
    @Selector([ProductCatalogState.get101Skins])
    static get101aSkins(accessories: CatalogSkin[]) {
        return accessories?.filter((product: CatalogSkin) => product?.config?.form_factor === "the 101.a");
    }

    @Selector([ProductCatalogState.get101aSkins])
    static getArtCollectionSkins(skins: CatalogSkin[]) {
      return skins?.filter(skin => !skin?.config?.hexColor?.includes('#'))
    }

    @Selector([ProductCatalogState.get101aSkins])
    static getRainCollectionSkins(skins: CatalogSkin[]) {
      return skins?.filter(skin => skin?.config?.hexColor?.includes('#'))
    }


  @Selector([ProductCatalogState.getProductsByType("bundle")])
  static getStandalone4GProduct(bundles: CatalogBundle[]) {
    return bundles?.find((product: CatalogBundle) => {

      return product?.name?.includes(STAND_ALONE_4G);
    });
  }

  @Selector([ProductCatalogState.getProductsByType("sim")])
    static getStandalone4GSims(sims: CatalogSimProduct[]) {
        return sims?.filter( product => product?.name?.includes(STAND_ALONE_4G) )
    }

    @Selector([ProductCatalogState.getStandalone4GSims])
    static getStandalone4GSimsIDs(sims: CatalogSimProduct[]) {
        return sims?.map( product => product?.id )
    }

    @Selector([ProductCatalogState.getProductsByType("bundle")])
    static getBaseBundles(bundles: CatalogBundle[]) {
        return bundles?.filter((product: CatalogBundle) => {

            const is101 = product.name?.toLocaleLowerCase()?.includes("rainone 101a");
            const isWork = product.name?.toLocaleLowerCase()?.includes("rainone 101a work");
            const noLevel = !product.name?.toLocaleLowerCase()?.includes("level");

            return (is101 || isWork) && noLevel
        }); //simply swapped "rainOne" with "rainOne 101"
    }

    static getBaseBundleByPaymentType(paymentType: RainPaymentType) {
        return createSelector(
            [ProductCatalogState.getBaseBundles],
            (bundles: CatalogBundle[]) => bundles?.find(bundle => bundle?.config?.paymentType === paymentType)
        );
    }
    static getBaseBundleByPaymentTypeAndDemographic(paymentType: RainPaymentType, smeSelected: boolean) {
        return createSelector(
            [ProductCatalogState.getBaseBundles],
            (bundles: CatalogBundle[]) => bundles?.find(bundle => {
                const paymentTypeMatches = bundle?.config?.paymentType === paymentType;
                const isWorkProduct = smeSelected ?? false

                return paymentTypeMatches && (isWorkProduct ?
                    bundle?.config?.demographic?.toLocaleLowerCase() === 'sme' :
                    !bundle?.config?.demographic)
            })
        );
    }

    @Selector([ProductCatalogState.getProductsByType("bundle")])
    static getStaffBundleOptions(bundles: CatalogBundle[]) {
        return bundles?.filter(bundle => bundle?.config?.staff) ?? [];
    }

    @Selector([ProductCatalogState.getProductsByType("sim")])
    static getRainOneFourGSims(sims: CatalogSimProduct[]) {
        return sims?.filter((product) => {return product?.category === '4G' && product?.config?.base_subscription})
    }
    @Selector([ProductCatalogState.getRainOneFourGSims])
    static getRainOneFouGSimsIDs(sims: CatalogSimProduct[]) {
        return sims?.map((product) => { return product?.id })
    }

    @Selector([ProductCatalogState.getProductsByType("sim")])
    static get4GSimSwopSim(sims: CatalogSimProduct[]) {
        return sims?.filter(product => product?.category === '4G' && product?.name?.toLocaleLowerCase()?.includes('sim swap'))
    }

    @Selector([ProductCatalogState.get4GSimSwopSim])
    static get4GSimSwopSimID(sims: CatalogSimProduct[]) {
        return sims?.map(product => product?.id )
    }

    @Selector([ProductCatalogState.getProductsByType("sim")])
    static get5GSimSwopSim(sims: CatalogSimProduct[]) {
        return sims?.filter((product) => {return product?.category === '5G' && product?.name?.toLocaleLowerCase().includes('sim swap')})
    }
    @Selector([ProductCatalogState.get5GSimSwopSim])
    static get5GSimSwopSimID(sims: CatalogSimProduct[]) {
        return sims?.map((product) => { return product?.id })
    }
    @Selector([ProductCatalogState.getProductsByType('isim')])
    static getIsimProductIDs(products: CatalogISim[]) {
        return products?.map((product) => { return product?.id })
    }

    @Selector([ProductCatalogState.getProductsByType("sim")])
    static getRainOneFiveGSims(sims: CatalogSimProduct[]) {
        return sims?.filter(product=> product?.category === '5G')
    }

    @Selector([ProductCatalogState.getRainOneFiveGSims])
    static getRainOneFiveGSimsIDs(sims: CatalogSimProduct[]) {
        return sims?.map(product => product?.id )
    }

    @Selector([ProductCatalogState.getProductsByType("sim")])
    static getRainOne101WorkFiveGSims(sims: CatalogSimProduct[]) {
        return sims?.filter((product) => {return product?.category === '5G' && product?.name?.toLocaleLowerCase().includes('work')})
    }
    @Selector([ProductCatalogState.getProductsByType("sim")])
    static getRainOne101FiveGSims(sims: CatalogSimProduct[]) {
        return sims?.filter((product) => {return product?.category === '5G' && product?.name?.toLocaleLowerCase().includes('101')})
    }
    @Selector([ProductCatalogState.getRainOne101FiveGSims])
    static getRainOne101FiveGSimsIDs(sims: CatalogSimProduct[]) {
        return sims?.map((product) => { return product?.id })
    }

    @Selector([ProductCatalogState.getProductsByType("sim")])
    static get4gMobileSims(sims: CatalogSimProduct[]) {
        return sims?.filter(product => product?.category === '4G' && product?.name?.includes(STAND_ALONE_4G))
    }

    @Selector([ProductCatalogState.get4gMobileSims])
    static get4gMobileSimsIDs(sims: CatalogSimProduct[]) {
        return sims?.map(product => product?.id)
    }

    @Selector([ProductCatalogState.getRainOneFourGSims])
    static getRainOne4GSimsIDs(sims: CatalogSimProduct[]) {
        return sims?.map((product) => { return product?.id })
    }
    @Selector([ProductCatalogState.getRainOne101WorkFiveGSims])
    static getRainOne101WorkFiveGSimsIDs(sims: CatalogSimProduct[]) {
        return sims?.map((product) => { return product?.id })
    }
    @Selector([ProductCatalogState.get101Skins])
    static getRainOne101SkinsIds(skins: CatalogSkin[]) {
        return skins?.map((product) => {return product?.id})
    }
    static doesBundleHaveDevice(bundle: CatalogBundle) {
        return createSelector(
            [ProductCatalogState.getProductsByType("device"), ProductCatalogState.getProductsByType("isim")],
            (devices: CatalogDevice[], iSimdevices: CatalogISim[]) => {

                for (const item of bundle?.items ?? []) {
                    const hasDevice = (devices?.some(device => device?.id === item?.id) || iSimdevices?.some(device => device?.id === item?.id));
                    if (hasDevice) {
                        return true;
                    }
                }

                return false;
            }
        );
    }

    static getBundlesMappedByLevelForPaymentType(paymentType: RainPaymentType, techType: TechType) {

        return createSelector([
            ProductCatalogState.getProductsByType("bundle"),
            ProductCatalogState.getProductsMappedById
        ], (bundles: CatalogBundle[], catalogDict: Dictionary<CatalogProduct>): Dictionary<CatalogBundle> => {

            return (bundles ?? [])
                .reduce(
                    (dict, bundleItem) => {

                        //Skip staff bundles
                        if (bundleItem?.config?.staff) {
                            return dict;
                        }

                        for (const item of bundleItem?.items ?? []) {

                            const catalogItem = catalogDict?.[item?.id];
                            const { type, config, name } = catalogItem ?? {};

                            if(type !== "sim"){
                                return dict;
                            }

                            const matchesCategory = techType === (<CatalogSimProduct>catalogItem)?.category;
                            const isNotRain101 = !name?.includes('rainOne 101')

                            if (matchesCategory && config && (<CatalogSimProduct["config"]>config)?.paymentType === paymentType && isNotRain101) {
                                dict[(<CatalogSimProduct>catalogItem).config.level] = bundleItem;
                                return dict;
                            }
                        }

                        return dict;
                    },
                    <Dictionary<CatalogBundle>>{}
                );
        });

    }
    static getBundlesMappedByLevelForPaymentType101(paymentType: RainPaymentType, techType: TechType, selectedServiceOrBundle: CatalogSimProduct | CatalogBundle) {

        return createSelector([
            ProductCatalogState.getProductsByType("bundle"),
            ProductCatalogState.getProductsMappedById,
            ProductCatalogState.getProductsByType('isim'),
            ProductCatalogState.getProductsByType('sim'),
            SalesSettingsState.isKnownCustomer,
            SalesCartState.smeSelected
        ], (bundles: CatalogBundle[], catalogDict: Dictionary<CatalogProduct>, isimProducts: CatalogISim[], simProducts: CatalogSimProduct[], isKnownCustomer: boolean, isWorkProduct: boolean): Dictionary<CatalogBundle> => {
            const is5G = techType === '5G'
            const is101Bundle = is5G && isRainOne101Bundle(selectedServiceOrBundle, isimProducts)
            const isWorkBundle = is5G && isRainOneWorkBundle(selectedServiceOrBundle, isimProducts)
            const isOffPeakBundle = !is5G && isRainOneOffPeakBundle(selectedServiceOrBundle, simProducts)
            const isKnownCustomerAndWorkProduct = isKnownCustomer && isWorkProduct

            return (bundles ?? [])
                .reduce(
                    (dict, bundleItem) => {

                        const isStaffBundle = bundleItem?.config?.staff
                        const selectedPaymentType = bundleItem?.config?.paymentType === paymentType

                        const incorrect5GBundle = !!((is101Bundle && !isRainOne101Bundle(bundleItem, isimProducts)) ||
                            !!(!is101Bundle && isRainOne101Bundle(bundleItem, isimProducts)))

                        const incorrectWorkBundle = !!((isWorkBundle && !isRainOneWorkBundle(bundleItem, isimProducts)) ||
                            !!(!isWorkBundle && isRainOneWorkBundle(bundleItem, isimProducts)))

                        const incorrect4GBundle = !!((isOffPeakBundle && !isRainOneOffPeakBundle(bundleItem, simProducts)) ||
                        !!(!isOffPeakBundle && isRainOneOffPeakBundle(bundleItem, simProducts)))

                        if (incorrect5GBundle || incorrect4GBundle || incorrectWorkBundle) {
                            return dict
                        }

                        //Skip staff bundles
                        if (isStaffBundle || (!selectedPaymentType && !isKnownCustomerAndWorkProduct) ) {
                            return dict;
                        }

                        for (const item of bundleItem?.items ?? []) {
                            const catalogItem = catalogDict?.[item?.id];
                            const { type, config } = catalogItem ?? {};

                            if(type !== "sim"){
                                return dict;
                            }

                            const matchesCategory = techType === (<CatalogSimProduct>catalogItem)?.category;

                            if (matchesCategory && config && ((<CatalogSimProduct["config"]>config)?.paymentType === paymentType || isKnownCustomerAndWorkProduct)) {
                                dict[(<CatalogSimProduct>catalogItem)?.config?.level] = bundleItem;
                                return dict;
                            }
                        }

                        return dict;
                    },
                    <Dictionary<CatalogBundle>>{}
                );
        });

    }

    static getFullBundleById(id: string) {

        return createSelector(
            [ProductCatalogState.getProductsMappedById],
            (catalogDict: Dictionary<CatalogProduct>): FullCatalogBundle => {


              const bundle = catalogDict?.[id];
                if (!bundle || !id) {
                    return null;
                }

                if (bundle.type !== "bundle") {
                    console.error("getFullBundleById did not receive a valid bundle ID. Please make sure the ID passed is an actual bundle.");
                    return null;
                }

                // ===================================== //

                const bundleCopy = DataHandler.createDeepCopy(bundle);
                const { config, items } = bundleCopy ?? {}

                let level = 0;
                let levelRecurringPrice = 0;

                bundleCopy.items = items?.map(item => {
                    const product = catalogDict[item?.id];

                    if (product?.type === "sim") {
                        level = product?.config?.level ?? 0;
                        levelRecurringPrice = product?.recurringPrice ?? 0
                    }

                    return product;
                });

                if (config) {
                    config.add_ons = config.add_ons?.map(addon => catalogDict[addon?.id]);
                }

                (<FullCatalogBundle>bundleCopy).level = level;
                (<FullCatalogBundle>bundleCopy).levelUpRecurringPrice = levelRecurringPrice;

                return <FullCatalogBundle>bundleCopy;
            }
        )
    }

    @Selector([
      ProductCatalogState.getProductsByType('sim'),
      ProductCatalogState.getProductsMappedById
    ])
    static getMobileProductLevels(
      simProducts: CatalogSimProduct[],
      catalogDict: Dictionary<CatalogProduct>
    ){
      const { config } = simProducts?.find(product => product?.name?.includes(STAND_ALONE_4G)) ?? {}
      const { migration } = config ?? {}
      return migration?.map(option => catalogDict?.[option?.id]) ?? []
    }

    @Selector()
    static getError(state: ProductCatalogStateModel) { return state.error }

    private readonly _cancelRequest$ = new Subject<null>();

    constructor(private productService: ProductService) {
    }

    @Action(ProductCatalogActions.Fetch)
    Fetch(ctx: StateContext<ProductCatalogStateModel>) {

        ctx.patchState({ loading: true });

        return this.productService.getProductCatalog()
            .pipe(
                tap({
                    next: res => ctx.dispatch(new ProductCatalogActions.FetchSuccess(res?.result as CatalogProduct[])),
                    error: (e: unknown) => ctx.dispatch(new ProductCatalogActions.FetchFail(e))
                }),
                takeUntil(this._cancelRequest$.pipe(take(1)))
            );
    }


    @Action(ProductCatalogActions.FetchSuccess)
    FetchSuccess(ctx: StateContext<ProductCatalogStateModel>, action: ProductCatalogActions.FetchSuccess) {
        const { payload } = action;
        const productCatalogMap = this.productService.getProductsCatalogMap(payload);

        ctx.patchState({
            ...DataLoadingHelper.dataLoaded(productCatalogMap),
            products: payload ?? []
        });
      }

    @Action(ProductCatalogActions.FetchFail)
    FetchFail(ctx: StateContext<ProductCatalogStateModel>, action: ProductCatalogActions.FetchFail) {
        const error = Utils.Helpers.findError(action.error, '');
        DataLoadingHelper.setError(ctx, error);
    }

    @Action(ProductCatalogActions.Clear)
    Clear(ctx: StateContext<ProductCatalogStateModel>) {
        this._cancelRequest$.next(null);
        ctx.setState(getDefaults());
    }

}
