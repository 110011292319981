import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { PrettyJsonNoteComponent } from './pretty-json-note.component';

const declarations = [
  PrettyJsonNoteComponent
]

@NgModule({
  declarations: [...declarations],
  imports: [
    CommonModule,
    SharedModule
  ],
  exports: [...declarations]
})
export class PrettyJsonNoteModule { }
