import { isNil, isEmpty, isNumber, indexOf, isString } from 'lodash';
import moment from 'moment';

import { GeoJsonProperties } from 'geojson';
import { LuhnService } from 'src/app/shared/services/luhn.service';

/**
 * UNKNOWN @param
 */
const UNKNOWN = 'UNKNOWN';

/**
 * areTrue @param
 */
const areTrue = ['yes', 'Yes', 'true', 'True', true, 'y', 'Y', 1, '1'];

/**
 * VALID_ID_AGE @param
 */
const VALID_ID_AGE = 18;

export class ModelHelper {
  static fromTimestamp(timestamp: number): Date {
    return moment.unix(timestamp).toDate();
  }

  static validateIdNumber(idNumber: string, luhnService: LuhnService) {
    const idReg = /^(((\d{2}((0[13578]|1[02])(0[1-9]|[12]\d|3[01])|(0[13456789]|1[012])(0[1-9]|[12]\d|30)|02(0[1-9]|1\d|2[0-8])))|([02468][048]|[13579][26])0229))(( |-)(\d{4})( |-)(\d{3})|(\d{7}))/;

    if (isNil(idNumber) || isEmpty(idNumber)) {
      return false;
    }

    if (idReg.test(idNumber) == false) {
      return false;
    }

    if (luhnService.validate(idNumber) == false) {
      return false;
    }

    let year = parseInt(idNumber.substring(0, 2), 10);

    const month = parseInt(idNumber.substring(2, 4), 10);

    const day = parseInt(idNumber.substring(4, 6), 10);

    if (year <= 20) {
      year += 2000;
    } else {
      year += 1900;
    }

    /**
     * newDateOfBirth @param
     */
    const newDateOfBirth = new Date(year, month - 1, day);

    const years = moment().diff(newDateOfBirth, 'years');

    return years >= VALID_ID_AGE;
  }

  static round2(value: number) {
    return Math.round(value * 100) / 100;
  }

  static toBoolean(str: string, defaultValue = false): boolean {
    if (ModelHelper.emptyString(str)) {
      return defaultValue;
    }

    if (indexOf(areTrue, str) > -1) {
      return true;
    }

    return false;
  }

  static toDate(str: string): Date {
    if (isString(str) == false) {
      return null;
    }

    if (ModelHelper.emptyString(str)) {
      return null;
    }

    /**
     * parsedDate @param
     */
    const parsedDate = moment(str);

    /**
     * data @param
     */
    const date = parsedDate.isValid() ? parsedDate.toDate() : null;

    return date;
  }

  public static getFeatureProperty(key: string, properties: GeoJsonProperties, defaultValue: any = null): any {
    if (isNil(properties) || isNil(properties[key])) {
      return defaultValue;
    }

    return properties[key];
  }

  public static toFloat(value: string): number | string {
    if (isNumber(value)) {
      return value;
    }

    return ModelHelper.emptyString(value) ? null : parseFloat(value);
  }

  public static toNumber(value: string): number | string {
    if (isNumber(value)) {
      return value;
    }

    return ModelHelper.emptyString(value) ? null : parseInt(value, 10);
  }

  public static toEmptyString(str: string) {
    return isNil(str) ? '' : str;
  }

  public static emptyString(str: string) {
    return isNil(str) || isEmpty(str) || str === UNKNOWN;
  }

  public static clean(str: string) {
    if (isNil(str) || isEmpty(str) || str === UNKNOWN) {
      return null;
    }

    return str.trim();
  }

  public static toBlob(b64Data, contentType = '', sliceSize = 512) {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }
}
