import { Injectable } from '@angular/core';
import { State, Action, StateContext, Selector } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { TODAY } from 'src/app/constants';
import { ClearCoverageData } from 'src/app/customer-data-components/sim-details/sim-card-page/sub-components/coverage/store/actions/coverage-data.actions';
import { RefreshMaps } from 'src/app/customer-info-summary-page/store/actions/events.actions';
import { DataLoading, getDataLoadingDefaultValues } from 'src/app/shared/interfaces/data-loading.interface';
import { DataLoadingHelper } from 'src/app/Utils/helpers';
import { LoadsheddingService } from '../../loadshedding.service';
import { ClearLoadsheddingScheduleForSiteID, FetchLoadsheddingScheduleForSiteID, FetchLoadsheddingScheduleForSiteIDFail, FetchLoadsheddingScheduleForSiteIDSuccess, RefreshLoadsheddingForSiteID, SetLoadsheddingScheduleForSiteIDError } from '../actions/loadshedding-schedule-per-sim.actions';
import { LoadsheddingSchedulePerSite } from '../interfaces/loadshedding-schedule-per-site';



interface LoadsheddingForSiteIDstateModel extends DataLoading<LoadsheddingSchedulePerSite> {
  siteID: number;
  is_open: boolean;
}
@State<LoadsheddingForSiteIDstateModel>({
  name: 'loadshedding_schedule_per_site',
  defaults: {
    ...getDataLoadingDefaultValues(),
    siteID: null,
    is_open: false
  }

})
@Injectable()
export class LoadsheddingForSiteIDstate {

  @Selector()
  static isLoading(state: LoadsheddingForSiteIDstateModel) { return state.loading }

  @Selector()
  static isLoaded(state: LoadsheddingForSiteIDstateModel) { return state.loaded }

  @Selector()
  static getData(state: LoadsheddingForSiteIDstateModel) { return state.data }

  @Selector()
  static getError(state: LoadsheddingForSiteIDstateModel) { return state.error }

  @Selector()
  static getCurrentSiteID(state: LoadsheddingForSiteIDstateModel) { return state.siteID }

  @Selector()
  static isOpen(state: LoadsheddingForSiteIDstateModel) { return state.is_open }

  constructor(private _loadsheddingService: LoadsheddingService) {
  }

  @Action(FetchLoadsheddingScheduleForSiteID)
  FetchCurrentLoadsheddingReport(ctx: StateContext<LoadsheddingForSiteIDstateModel>, action: FetchLoadsheddingScheduleForSiteID) {
    const siteID = action.siteID;

    ctx.patchState({
      loading: true,
      is_open: true,
      siteID
    })

    return this._loadsheddingService.getLoadsheddingScheduleForSiteID(siteID, TODAY())
      .pipe(tap({
        next: (resp) => {
          if (resp.status === 200) {
            ctx.dispatch(new FetchLoadsheddingScheduleForSiteIDSuccess(resp.body));
          }
          else {
            ctx.dispatch(new SetLoadsheddingScheduleForSiteIDError(`Failed to fetch loadshedding for siteID ${siteID}`));
          }
        },
        error: (e) => {
          ctx.dispatch(new FetchLoadsheddingScheduleForSiteIDFail(e));
        }
      }));

  }

  @Action(FetchLoadsheddingScheduleForSiteIDSuccess)
  fetchLoadsheddingScheduleForSiteIDSuccess(ctx: StateContext<LoadsheddingForSiteIDstateModel>, action: FetchLoadsheddingScheduleForSiteIDSuccess) {
    DataLoadingHelper.setData(ctx, action.payload);
  }

  @Action(FetchLoadsheddingScheduleForSiteIDFail)
  fetchCurrentLoadsheddingReportFail(ctx: StateContext<LoadsheddingForSiteIDstateModel>, action: FetchLoadsheddingScheduleForSiteIDFail) {
    const errorMessage = action.error?.error;
    ctx.dispatch(new SetLoadsheddingScheduleForSiteIDError(errorMessage));
  }

  @Action(SetLoadsheddingScheduleForSiteIDError)
  setCurrentLoadsheddingReportError(ctx: StateContext<LoadsheddingForSiteIDstateModel>, action: SetLoadsheddingScheduleForSiteIDError) {
    DataLoadingHelper.setError(ctx, action.errorMessage);
  }

  @Action(RefreshMaps)
  clearAndRefresh(ctx: StateContext<LoadsheddingForSiteIDstateModel>) {
    const state = ctx.getState();
    if (!state.is_open) {
      return;
    }

    DataLoadingHelper.clearData(ctx);
    ctx.patchState({
      siteID: null,
      loading: true
    });
  }


  @Action(RefreshLoadsheddingForSiteID)
  refreshLoadsheddingForCurrentSiteID(ctx: StateContext<LoadsheddingForSiteIDstateModel>) {
    DataLoadingHelper.clearData(ctx);
    ctx.dispatch(new FetchLoadsheddingScheduleForSiteID(ctx.getState().siteID));
  }

  @Action([ClearLoadsheddingScheduleForSiteID, ClearCoverageData])
  clearLoadsheddingScheduleForSiteID(ctx: StateContext<LoadsheddingForSiteIDstateModel>) {
    DataLoadingHelper.clearData(ctx);
    ctx.patchState({
      siteID: null,
      is_open: false
    });
  }


}
