

export const PRODUCT_ID_NAME_MAP = {
  "4G 24/7 Unlimited": "db3495c4-e9b5-4ea0-9a6a-30cdb3b52d62",
  "4G for Phones": "73315190-9121-466e-a98e-2a0e6a8c6f4f",
  "4G with UOP": "c1f9cd04-d592-4667-a606-ac2a05b74923",
  '4G 19 hours unlimited off-peak': '70e483ec-deee-4727-9499-348ba07b020d',

  "5G Basic": "60105562-c3b9-4c2b-9318-333209658d06",
  "5G Home Standard": "ec2dc18c-68b7-4e8d-bd60-40c121c9b9d8",
  "5G Home Premium": "b8cba300-779a-417b-9133-ed37b7054c87",

  //PROMO
  "Unlimited Home 5G Standard Unlocked": "4ecc46af-e9a8-4ffa-b03a-eb5cc18d04c1",
  "Unlimited Home 5G Premium Unlocked": "9e4d584c-1c84-480d-a9f3-158cae6f606b",

  //4G PROMO
  "5G Home 4G PROMO": "c2c1c450-0fc1-474e-ac60-715610588b68",

  //Two for One PROMO
  "Unlimited home 5G Premium DUO1": "89dd4305-8f41-4d86-a097-416e1046e865",
  "Unlimited home 5G Premium DUO2": "12bf79dc-f05b-46cf-a91c-d28b79ed84e1",

  //Weekly arrears cohort
  "PrePaid Unlimited 4G": "a911af35-30d0-4e0d-a407-633d27ad8f3a",

} as const;


export const TWO_FOR_ONE_PRODUCT_IDS = [
  PRODUCT_ID_NAME_MAP["Unlimited home 5G Premium DUO1"],
  PRODUCT_ID_NAME_MAP["Unlimited home 5G Premium DUO2"]
] as const;


export type ProductNames = keyof typeof PRODUCT_ID_NAME_MAP;
