import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Store } from '@ngxs/store';
import moment from 'moment';
import { switchMap, takeUntil } from 'rxjs/operators';
import { AddSuccessResponseNotification } from 'src/app/core/store/actions/notifications.actions';
import { AddCallbackActions } from 'src/app/shared/components/callback/store/actions/add-callback-actions';
import { Observable, Subject } from 'rxjs';
import { CallbackSchedulerSelector } from './view-model/callback-scheduler.selector';
import { CallbackSchedulerViewModel } from './view-model/callback-scheduler-view-model.interface';
import { CoreState } from 'src/app/core/store/state/core.state';
import { ChatTicketState } from 'src/app/interactions/store/state/chat-ticket.state';

const isInPast = (d: moment.Moment) => d.valueOf() < moment().valueOf();

@Component({
    selector: 'sf-callback-scheduler',
    templateUrl: './callback-scheduler.component.html',
    styleUrls: ['./callback-scheduler.component.scss']
})
export class CallbackSchedulerComponent implements OnInit, OnDestroy {

    viewModel$: Observable<CallbackSchedulerViewModel>;

    callBackForm: FormGroup;

    readonly dateFilter = (d: moment.Moment) => {
        //Adding extra day so today is still selectable
        return d.valueOf() > moment().subtract(1, "day").valueOf();
    }

    private readonly ngDestroy$ = new Subject<void>();

    constructor(private store: Store,
        private fb: FormBuilder) { }


    ngOnInit(): void {
        this.initializeForm();

        this.viewModel$ = this.store.select(CoreState.getBasicAgentDetails)
            .pipe(
                switchMap(agentDetails => {
                    const { email, isInSales } = agentDetails ?? {};
                    return this.store.select(CallbackSchedulerSelector.getViewModel(email, isInSales));
                })
            );
    }

    private initializeForm() {
        this.callBackForm = this.fb.group({
            dateTime: this.fb.control(''),
        });

        const dateTimeControl = this.callBackForm.get('dateTime');

        const resetDateControl = () => dateTimeControl.patchValue(moment.now(), { emitEvent: false, onlySelf: true });

        dateTimeControl.valueChanges
            .pipe(takeUntil(this.ngDestroy$))
            .subscribe({
                next: (res: moment.Moment) => {
                    const { customerEmail, customerTicket } = this.store.selectSnapshot(ChatTicketState.getTicketHelper) ?? {};
                    const { hex_id } = customerTicket ?? {};

                    if (!res?.valueOf()) {
                        resetDateControl();
                        return;
                    }

                    if (isInPast(res)) {
                        resetDateControl();
                        return this.store.dispatch(new AddSuccessResponseNotification({
                            success: false,
                            message: "Date can not be in the past."
                        }));
                    }

                    if (hex_id && customerEmail) {
                        this.store.dispatch(new AddCallbackActions.AddCallbackInCtx({
                            customerEmail,
                            hexId: hex_id,
                            dateTime: Math.round(res?.valueOf() / 1000)
                        }));
                    }

                    resetDateControl();
                },
            });
    }

    ngOnDestroy(): void {
        this.ngDestroy$.next(null);
        this.ngDestroy$.complete();
    }

}
