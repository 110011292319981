import { Utils } from "..";

export function isEmailCheck(searchString:string): boolean{
    return searchString.includes('@');
}

export function isIDNumberCheck(searchString:string): boolean {
    return Utils.Regex.isSouthAfricanIdNumber(searchString);
}

export function isPhoneCheck(searchString:string): boolean { 
    return /^((27)|0){1,2}[0-9]{9,13}$/g.test(searchString);
}

export function isUUIDCheck(searchString:string): boolean { 
    return /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/g.test(searchString);
}

export function isNumberCheck(searchString:string): boolean { 
    return /^[0-9]/g.test(searchString);
}

export function isIMSICheck(searchString:string): boolean { 
    return /^655.{12}$/g.test(searchString);
}

export function isICCIDCheck(searchString:string): boolean {
    return searchString.startsWith("89") && /^[0-9]*$/.test(searchString) && ( searchString.length >= 18 && searchString.length <= 22 )
}