import { Utils } from "src/app/Utils";
import { CatalogProduct } from "../../interfaces/product-catalog.interfaces";


export class Fetch {
    static readonly type = Utils.Helpers.Type("[Product Catalog] Fetch");
}

export class FetchSuccess {
    static readonly type = Utils.Helpers.Type("[Product Catalog] Fetch success");
    constructor(public payload: CatalogProduct[]) { }
}

export class FetchFail {
    static readonly type = Utils.Helpers.Type("[Product Catalog] Fetch fail");
    constructor(public error: unknown) { }
}

export class Clear {
    static readonly type = Utils.Helpers.Type("[Product Catalog] Clear");
}