import { Utils } from "src/app/Utils";



export class ShowContinueButton {
  static readonly type = Utils.Helpers.Type('[Continue: ShowButton] Show continue button');
  constructor(public can_continue: boolean) { }
}

export class CheckContinueAfterEscalationFailed {
  static readonly type = Utils.Helpers.Type('[Continue: Escalation] Escalation failed, check continue state');
}

export class ClearContinueState {
  static readonly type = Utils.Helpers.Type('[Continue: ClearContinueState] Clear continue state');
}
