
import { ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import moment from 'moment';

export const SA_ID_REGEX = "(?<Year>[0-9][0-9])(?<Month>([0][1-9])|([1][0-2]))(?<Day>([0-2][0-9])|([3][0-1]))(?<Gender>[0-9])(?<Series>[0-9]{3})(?<Citizenship>[0-9])(?<Uniform>[0-9])(?<Control>[0-9])";

export const isSouthAfricanIdNumber = (value: string) => new RegExp(SA_ID_REGEX).test(value);

export const getAgeFromIdNumber = (idNumber: string): number | null => {
    const regex = new RegExp(SA_ID_REGEX);
    const match = regex.exec(idNumber);
    if (!match) {
        return null;
    }
    const years = parseInt(match?.groups?.Year ?? "", 10);
    const year = years + (years >= 25 ? 1900 : 2000);
    const month = parseInt(match?.groups?.Month ?? "", 10) - 1;
    const day = parseInt(match?.groups?.Day ?? "", 10);
    const dateOfBirth = moment({ year, month, day });
    return moment().diff(dateOfBirth, 'years');
}

export const minAgeIdValidatorFnFactory = (minAge: number): ValidatorFn =>
    (control: AbstractControl): ValidationErrors | null => {
        const age = getAgeFromIdNumber(control?.value);
        const isOldEnough = age >= minAge;
        return !isOldEnough ? { tooYoung: { value: control.value } } : null;
    };
