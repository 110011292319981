import { Injectable } from '@angular/core';
import { Action, createSelector, Selector, State, StateContext, } from '@ngxs/store';
import { tap, takeUntil, take } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { DataLoading, getDataLoadingDefaultValues } from 'src/app/shared/interfaces/data-loading.interface';
import { Utils } from 'src/app/Utils';
import { DataLoadingHelper } from 'src/app/Utils/helpers';
import { CustomerServiceActions } from '../actions/customer-service-actions';
import { BasicCustomerService, SpeedupPolicyOption } from '../interfaces/basic-customer-service.interface';
import { CustomerServiceService } from '../../../customer-data-components/sim-details/store/services/customer-service.service';
import { NO_DATA_MESSAGE } from 'src/app/constants';
import { CustomerInfoSummaryDestroyed, FetchCustomerDetailsSuccess } from '../actions/customer.actions';
import { SimState } from 'src/app/customer-data-components/sim-details/store/state/sim.state';
import { DataHandler } from 'src/app/shared/data-handler/data-handler';
import { StatusGroup } from 'src/app/shared/data-handler/datafield-options.interface';
import { CUSTOMER_SERVICE_STATUS } from 'src/app/shared/constants/service-status.constant';
import { ExtendedServiceSelectors } from '../selectors/extended-service.selector';
import { ExtendedCustomerService } from '../interfaces/proxy-customer-service.interface';
import { GetBucketByUserIdState } from 'src/app/customer-data-components/axiom/prepay-balance-management/store/state/get-bucket-by-user-id.state';
import { Buckets } from 'src/app/customer-data-components/axiom/prepay-balance-management/types/get-buckets-by-user-id-response';


type CustomerServiceStateModel = DataLoading<BasicCustomerService[]>;

export interface SpeedupOption {
    policy: SpeedupPolicyOption,
    description: string,
    val: number
}

export const SPEED_UP_OPTIONS: SpeedupOption[] = [
    {
        policy: "High_Speed_30",
        description: "30Mbps",
        val: 30
    },
    {
        policy: "High_Speed_60",
        description: "60Mbps",
        val: 60
    },
    {
        policy: "High_Speed_Unlimited",
        description: "100+Mbps",
        val: 100
    }

];


@State<CustomerServiceStateModel>({
    name: 'sf_customer_service_state',
    defaults: {
        ...getDataLoadingDefaultValues()
    }
})
@Injectable()
export class CustomerServiceState {

    @Selector()
    static isLoading(state: CustomerServiceStateModel) { return state.loading }

    @Selector()
    static isLoaded(state: CustomerServiceStateModel) { return state.loaded }

    @Selector()
    static getData(state: CustomerServiceStateModel) { return state.data }

    @Selector()
    static getError(state: CustomerServiceStateModel) { return state.error }

    static getBasicServiceByID(id: string) {
        return createSelector(
            [CustomerServiceState.getData,
            ExtendedServiceSelectors.getServiceById(id)],
            (services: BasicCustomerService[], selectedService: ExtendedCustomerService) => {
                const { associated_resource: iccid } = selectedService ?? {};
                return services?.find(service => service?.iccid === iccid);
            }
        );
    }

    static getPolicyByID(id: string) {
        return createSelector(
            [CustomerServiceState.getBasicServiceByID(id)],
            (service: BasicCustomerService) => service?.policy
        );
    }

    @Selector([SimState.getSelectedID, CustomerServiceState.getData, ExtendedServiceSelectors.getServices])
    static getCurrentBasicService(currentServiceId: string, services: BasicCustomerService[], extendedServices: ExtendedCustomerService[]) {
        const extendedService = extendedServices?.find(service => service?.id === currentServiceId);
        const { associated_resource: iccid } = extendedService ?? {};

        return services.find(service => service?.iccid === iccid)
    }

    @Selector([CustomerServiceState.getCurrentBasicService])
    static getCurrentServicePolicy(service: BasicCustomerService) {
        return service?.policy
    }

    @Selector([CustomerServiceState.getCurrentServicePolicy])
    static getCurrentMappedPolicy(servicePolicy: string) { return SPEED_UP_OPTIONS.find((speedUpOption) => speedUpOption.policy === servicePolicy) }

    @Selector([CustomerServiceState.getCurrentServicePolicy])
    static getCurrentServicePolicyStatusColor(policyStatus: string) {
        const statusGroup: StatusGroup = {
            green: ["high speed", "high_speed_30", "high_speed_60", "high_speed_90", "high_speed_unlimited"],
            orange: ["device", "device policy"],
            red: ["payment", "payment policy"],
            blue: ["spend limit"],
            defaultColor: "red"
        }
        return DataHandler.getStatusColorChoice(policyStatus, statusGroup);
    }

    @Selector([CustomerServiceState.getCurrentServicePolicy])
    static isCurrentServicePolicyHighSpeed(policy: string) {
        const lowerCasePolicy = policy?.toLowerCase();
        return lowerCasePolicy.includes("high") && lowerCasePolicy?.includes("speed");
    }

    @Selector([CustomerServiceState.getCurrentServicePolicy])
    static isCurrentServicePolicyPayment(policy: string) {
        const lowerCasePolicy = policy?.toLowerCase();
        return lowerCasePolicy.includes("payment") && !lowerCasePolicy?.includes("hold");
    }

    @Selector([CustomerServiceState.getCurrentServicePolicy])
    static getPolicyMigrationOptions(policy: string) {
        return SPEED_UP_OPTIONS.filter(option => option.policy !== policy);
    }

    @Selector([CustomerServiceState.getCurrentServicePolicy])
    static getCurrentSpeed(policy: string) {
        if (policy?.match('High_Speed_Unlimited')) {
            return 100;
        } else {
            return +policy.split("_")[2]
        }
    }

    @Selector([CustomerServiceState.getData])
    static getAllActiveBasicCustomerServices(services: BasicCustomerService[]) {
      return services?.filter(service => service?.status !== CUSTOMER_SERVICE_STATUS['Service Canceled'] )
    }

    @Selector([CustomerServiceState.getAllActiveBasicCustomerServices])
    static getAllActiveServicePolicies(services: BasicCustomerService[]) {
      return services?.map(service => service?.policy )
    }

    private readonly _cancelRequest$ = new Subject<null>();

    constructor(private customerServiceService: CustomerServiceService) {
    }


    @Action(FetchCustomerDetailsSuccess)
    FetchAfterCustomerDetailsFetched(ctx: StateContext<CustomerServiceStateModel>, action: FetchCustomerDetailsSuccess) {
        const { id } = action.payload?.user ?? {};
        return ctx.dispatch(new CustomerServiceActions.Fetch(id));
    }

    @Action(CustomerServiceActions.Fetch)
    Fetch(ctx: StateContext<CustomerServiceStateModel>, action: CustomerServiceActions.Fetch) {
        const { userId } = action;

        if (!userId) {
            return ctx.dispatch(new CustomerServiceActions.FetchFail("No user ID."));
        }

        ctx.patchState({ loading: true });

        return this.customerServiceService.fetchBasicCustomerServices(userId)
            .pipe(
                tap({
                    next: res => {
                        const { result } = res ?? {};
                        if (!result?.length) {
                            return ctx.dispatch(new CustomerServiceActions.FetchFail(NO_DATA_MESSAGE));
                        }
                        return ctx.dispatch(new CustomerServiceActions.FetchSuccess(result));
                    },
                    error: (e: unknown) => ctx.dispatch(new CustomerServiceActions.FetchFail(e))
                }),
                takeUntil(this._cancelRequest$.pipe(take(1)))
            );
    }


    @Action(CustomerServiceActions.FetchSuccess)
    FetchSuccess(ctx: StateContext<CustomerServiceStateModel>, action: CustomerServiceActions.FetchSuccess) {
        const { payload } = action;
        DataLoadingHelper.setData(ctx, payload);
    }


    @Action(CustomerServiceActions.FetchFail)
    FetchFail(ctx: StateContext<CustomerServiceStateModel>, action: CustomerServiceActions.FetchFail) {
        const { error } = action;
        const errorMessage = "reason" in <CustomerServiceActions.ResponseError>error
            ? (<CustomerServiceActions.ResponseError>error)?.reason
            : Utils.Helpers.findError(action.error, "Unknown Error");

        DataLoadingHelper.setError(ctx, errorMessage);
    }

    @Action([CustomerServiceActions.Clear, CustomerInfoSummaryDestroyed])
    Clear(ctx: StateContext<CustomerServiceStateModel>) {
        this._cancelRequest$.next(null);
        ctx.setState(getDataLoadingDefaultValues());
    }

}
