import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-blank-card',
  templateUrl: './blank-card.component.html',
  styleUrls: ['./blank-card.component.scss']
})
export class BlankCardComponent {

  @Input() clickable = false;
  @Input() backgroundColor = "white";

}
