import { Pipe, PipeTransform } from '@angular/core';
import { DataHandler } from '../../data-handler/data-handler';

@Pipe({ name: 'epochToDate' })
export class EpochToDatePipe implements PipeTransform {

  transform(value: number): Date {
    if (!DataHandler.isDefined(value) || isNaN(value)) {
      return;
    }
    return new Date(value);
  }

}
