import { AccountInfo, AuthenticationResult } from "@azure/msal-browser";
import { AzureProfile } from "src/app/profile/assets/azure-profile.interface";
import { Utils } from "src/app/Utils";

export class SetInitialAzureAuthResult {
  static readonly type = Utils.Helpers.Type('[AzureAuth: Set] Set Azure Auth result');
  constructor(public payload: AuthenticationResult) { }
}

export class FetchAllAzureUsers {
  static readonly type = Utils.Helpers.Type('[AzureAuth: Fetch] Fetch ALL azure users');
  constructor(public accessToken: string) { }
}

export class FetchAllAzureUsersSuccess {
  static readonly type = Utils.Helpers.Type('[AzureAuth: Fetch] Fetch ALL azure users success');
  constructor(public payload: AzureProfile[]) { }
}

export class FetchAllAzureUsersFail {
  static readonly type = Utils.Helpers.Type('[AzureAuth: Fetch] Fetch ALL azure users fail');
  constructor(public error: any) { }
}

export class SetAllAzureUsersErrorMessage {
  static readonly type = Utils.Helpers.Type('[AzureAuth: Set] Set ALL azure users error message');
  constructor(public errorMessage: string) { }
}

export class RefreshAzureToken {
  static readonly type = Utils.Helpers.Type('[AzureAuth: Refresh] Refresh id token');
  constructor(public account: AccountInfo, public scopes: string[]) { }
}

export class FetchAzureTokenSuccess {
  static readonly type = Utils.Helpers.Type('[AzureAuth: Fetch] Fetch id token success');
  constructor(public payload: AuthenticationResult) { }
}

export class FetchAzureTokenFail {
  static readonly type = Utils.Helpers.Type('[AzureAuth: Fetch] Fetch id token fail');
  constructor(public error: any) { }
}