<div class="modal-overlay" [class.is-open]="isOpen">
    <div class="modal-lockup">
        <header>
            <div class="top-bar">
                <mat-icon class="default-cancel-icon" (click)="close()">cancel</mat-icon>
            </div>
            <h1 class="default-card-header">{{title}}</h1>
        </header>

        <div class="modal-body">
            <ng-content select="[body]"></ng-content>
        </div>

        <div class="send-button-wrapper">
            <ng-content select="[footer]"></ng-content>
        </div>
    </div>
</div>