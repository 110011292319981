import { Component, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { ToggleModalByName } from 'src/app/core/store/actions/modal.actions';

@Component({
  selector: 'sf-expired-token-reminder-modal',
  templateUrl: './expired-token-reminder-modal.component.html',
  styleUrls: ['./expired-token-reminder-modal.component.scss']
})
export class ExpiredTokenReminderModalComponent {

  constructor(private store: Store) { }
  onClose()
  {
    this.store.dispatch(new ToggleModalByName('token_expired_modal', false));
  }

}
