
/**
 * @returns A tuple with [matchingItems, nonMatchingItems]
 */
export const partition = <T>(items: T[], filterFn: (item: T) => boolean) => {
    const matchingItems: T[] = [];
    const nonMatchingItems: T[] = [];

    items.forEach(item => {
        if (filterFn(item)) {
            matchingItems.push(item);
        }
        else {
            nonMatchingItems.push(item);
        }
    });

    return [
        matchingItems,
        nonMatchingItems
    ]
}