<sf-popup-modal
    [isOpen]="true"
    [hasCloseButton]="false"
    [limitHeight]="true"
    [autoClose]="false"
    [successResponse]="modalOptions.successResponse"
    [sending]="modalOptions.sending"
    [spinnerMessage]="modalOptions.spinnerMessage"
>
    <ng-container header class="header">post activation modal</ng-container>
    <ng-container mainContent>
        <sf-modal-form-container [formGroup]="postActivationsForm">
            <div
                *ngIf="contactOptions?.length > 0; else"
                class="form-row"
                [class.has-error]="checkForErrors('contacted')"
            >
                <label class="rain-text">Contacted:</label>
                <sf-select
                    [options]="contactOptions"
                    (onOptionSelect)="
                        onOptionSelect('contacted', $event)
                    "
                    [placeholder]="'contacted:'"
                    [hasError]="
                    postActivationsForm.get('contacted').errors
                            ?.notSelected
                    "
                ></sf-select>
            </div>

            <div
                *ngIf="outcomeTypes?.length > 0; else"
                class="form-row"
                [class.has-error]="checkForErrors('outcome')"
            >
                <label>Outcome:</label>
                <sf-select
                    [options]="outcomeTypes"
                    (onOptionSelect)="
                        onOptionSelect('outcome', $event)
                    "
                    [placeholder]="'select outcome:'"
                    [hasError]="
                    postActivationsForm.get('outcome').errors
                            ?.notSelected
                    "
                ></sf-select>

            </div>

            <div
                *ngIf="customerExperienceOptions?.length > 0; else"
                class="form-row"
                [class.has-error]="checkForErrors('customer_current_experience')"
            >
                <label>customer experience:</label>
                <sf-select
                    [options]="customerExperienceOptions"
                    (onOptionSelect)="
                        onOptionSelect('customer_current_experience', $event)
                    "
                    [placeholder]="'select customer experience:'"
                    [hasError]="
                    postActivationsForm.get('customer_current_experience').errors
                            ?.notSelected
                    "
                ></sf-select>
            </div>

            <label for="">Solutions Provided:</label>

            <sf-form-checkbox
                class="checkbox"
                [control]="this.postActivationsForm.get('optimisation')"
                label="Optimisation"
            >
            </sf-form-checkbox>

            <sf-form-checkbox
                class="checkbox"
                [control]="this.postActivationsForm.get('dasher_request')"
                label="Dasher Request"
            >
            </sf-form-checkbox>

            <sf-form-checkbox
                class="checkbox"
                [control]="this.postActivationsForm.get('device_setup')"
                label="Device Setup"
            >
            </sf-form-checkbox>
            <sf-form-checkbox
                class="checkbox"
                [control]="this.postActivationsForm.get('device_swap_out')"
                label="Device Swap Out"
            >
            </sf-form-checkbox>
            <sf-form-checkbox
                class="checkbox"
                [control]="this.postActivationsForm.get('sim_card_swap_out')"
                label="Sim Card Swap Out"
            >
            </sf-form-checkbox>
            <sf-form-checkbox
                class="checkbox"
                [control]="this.postActivationsForm.get('wifi_setup')"
                label="WIFI setup"
            >
            </sf-form-checkbox>
            <sf-form-checkbox
                class="checkbox"
                [control]="this.postActivationsForm.get('no_additional_help')"
                label="No additional help needed"
            >
            </sf-form-checkbox>
            <sf-form-checkbox class="checkbox" [control]="this.postActivationsForm.get('no_contact_email_sent')"
              label="No contact email sent">
            </sf-form-checkbox>

            <ng-container buttons>
                <div class="send-button-wrapper">
                    <button
                        [disabled]="postActivationsForm?.invalid || modalOptions.sending"
                        class="rain-button default-button-size send-button solid"
                        (click)="onSend()"
                    >
                        send
                    </button>
                </div>
            </ng-container>
        </sf-modal-form-container>
    </ng-container>
</sf-popup-modal>
