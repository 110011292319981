import { DOCUMENT } from '@angular/common';
import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { FormHelper } from '../../helpers/form.helper';
import { validationMessages } from '../../pipes';

@Component({
  selector: 'sf-form-checkbox',
  templateUrl: './snowflake-checkbox.component.html',
  styleUrls: ['./snowflake-checkbox.component.scss']
})
export class SFCheckboxComponent extends FormHelper {
  @Input() control: FormControl;
  @Input() messages = validationMessages;
  @Input() public label: string;
  @Input() public type = 'text';
  @Input() public placeholder = 'type here...';
  @Input() public autocomplete = '';
  @Input() public display: 'column' | 'row' = 'column';
  @Input() public markValid = false;
  @Output() public onClick: EventEmitter<any> = new EventEmitter();

  constructor(@Inject(DOCUMENT) private dom: Document) {
    super();
  }

  ngAfterViewInit() {
    if (this.control) {
      this.InitFormControl(this.control);
    }

  }

  public formatLabel(l: string) {
    if (l) {
      return l.replace(' ', '_');
    }
  }

  public clicked(e) {
    return this.onClick.emit(e);
  }
}
