import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MapComponent } from './map.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AgmCoreModule } from '@agm/core';
import { MapSearchModule } from '../map-search/map-search-module';
import { NgxsModule } from '@ngxs/store';
import { AzimuthState } from './store/state/azimuth.state';
import { NormalSitesState } from './store/state/normal-map-sites.state';
import { AlarmSiteState } from './store/state/alarm-sites.state';
import { SiteAlarmDetailsState } from './store/state/site-alarm-details.state';


@NgModule({
  declarations: [
    MapComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    NgxsModule.forFeature([
      AzimuthState,
      NormalSitesState,
      AlarmSiteState,
      SiteAlarmDetailsState
    ]),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyAUKK1ckJNssAk7waDZ370xKh6iPUlylbM',
      libraries: ['places']
    }),
    MapSearchModule
  ],
  exports: [
    MapComponent,
    MapSearchModule,
  ],
  providers: [],
})
export class MapModule { }
