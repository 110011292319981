import { HttpClient, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FileLoader } from 'src/app/shared/components/file-uploader/assets/file-uploader.model';
import { environment } from 'src/environments/environment';
import { Attachment } from '../customer-ticket/interfaces/customer-ticket.interface';
import { LoadedResponse } from '../interfaces/attachment-loaded-response.interface';
import { ApiControllerService } from './api-service/api-controller.service';
import { ApiResponse } from './api-service/api-response.interface';


@Injectable({
  providedIn: 'root'
})
export class AttachmentService {

  constructor(private _http: HttpClient,
    private apiService: ApiControllerService) { }

  uploadFile(file: File, hex_id: string, email: string, isPrivate: boolean): Observable<HttpEvent<LoadedResponse>> {
    const endpoint = `${environment.snowflake_api_url}/ticket/attachment/${hex_id}`;

    const fileUploader = new FileLoader<LoadedResponse>(this._http);
    const formdata = new FormData();
    formdata.append("hex_id", hex_id);
    formdata.append("file", file, file.name);
    formdata.append("email", email);
    formdata.append("private", `${isPrivate}`);

    return fileUploader.uploadFile(endpoint, formdata);
  }


  async getFullAttachment(url: string): Promise<ApiResponse<{ data: Attachment }>> {
    const endpoint = `${environment.snowflake_api_url}/attachment/${url}`;
    return this.apiService.get(endpoint);
  }

  downloadFile(url: string) {
    const fileUploader = new FileLoader<LoadedResponse>(this._http);
    return fileUploader.downloadFile(url);
  }

}
