import { HttpErrorResponse, HttpEventType } from '@angular/common/http';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { RainAgent } from 'src/app/shared/components/agent/rain-agent.service';
import { FileLoader } from 'src/app/shared/components/file-uploader/assets/file-uploader.model';
import { AttachmentService } from 'src/app/shared/services/attachment.service';

@Component({
  selector: 'sf-attachment-loading',
  templateUrl: './attachment-loading.component.html',
  styleUrls: ['./attachment-loading.component.scss']
})
export class AttachmentLoadingComponent implements OnInit, OnDestroy {
  @Output() close = new EventEmitter();
  @Output() loadedSuccessfully = new EventEmitter();

  @Input() file: File;
  @Input() hex_id: string;
  @Input() isPrivate: boolean;
  progress = 0;
  error: string;

  private _uploadingSub: Subscription;

  constructor(private attachmentService: AttachmentService,
    private rainAgent: RainAgent) { }


  ngOnInit(): void {
    this.uploadFile();
  }

  async uploadFile() {
    const email = (await this.rainAgent.getAgentDetails()).email;

    this._uploadingSub = this.attachmentService.uploadFile(this.file, this.hex_id, email, this.isPrivate).subscribe(
      response => {
        if (response.type === HttpEventType.UploadProgress) {
          this.progress = FileLoader.getPercentageDone(response);
          if (this.progress === 100) {
            this.loadedSuccessfully.next();
          }
        }
      },
      (error: HttpErrorResponse) => {
        this.showError(error.statusText);
      }
    )
  }

  showError(error: string) {
    this.error = error;
  }

  onClose() {
    this.close.next();
  }

  ngOnDestroy(): void {
    if (this._uploadingSub) this._uploadingSub.unsubscribe();
  }

}


