<div id="select-lockup" [class.has-error]="hasError" *ngIf="form" [formGroup]="form" [attr.disabled]="isDisabled"
    [style.margin-bottom]="marginBottom">
    <label *ngIf="label" [ngClass]="labelStyle" [class.is-disabled]="isDisabled">
        {{label}}
    </label>
    <div class="select-wrapper" (click)="toggleDropdown()" [class.is-open]="_isOpen"
        [class.no-data]="options?.length < 1">
        <div class="lockup" [ngStyle]="{'background-color': bgColor, 'border-color': bgColor}">
            <i class="pre-icon" *ngIf="preIcon">
                <rm-icon [name]="preIcon"></rm-icon>
            </i>
            <input type="text" [attr.placeholder]="placeholder" formControlName="text" readonly>
            <i class="post-icon">
                <svg width="22" height="12" viewBox="0 0 22 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M11.7464 0.362244L21.6436 10.1647C22.0297 10.5996 22.0106 11.2566 21.6 11.6689C21.1894 12.0812 20.5271 12.1083 20.0835 11.7311L12.3923 4.10589L10.9515 2.67739L9.85844 3.80049L1.90894 11.6818C1.46919 12.1061 0.768719 12.1061 0.328972 11.6818C0.118402 11.4747 -3.0508e-05 11.1927 -3.05044e-05 10.8986C-3.05009e-05 10.6045 0.118403 10.3226 0.328972 10.1154L10.2162 0.263727C10.6678 -0.123202 11.3491 -0.0793458 11.7464 0.362244Z"
                        fill="#B2B2B2" />
                </svg>
            </i>
        </div>
        <ul [class]="'scrollable ' + menuPosition">
            <li *ngFor="let option of selectOptions" (click)="selectOption(option)" [class.selected]="option?.selected">
                {{option?.label}}
            </li>
        </ul>
    </div>
</div>