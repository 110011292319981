import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { AgentAction, AgentActionAction, ExtraLoggingAction, ExtraLoggingActionAction } from '../../assets/agent-action.type';
import { AgentTrackingService } from '../../services/agent-tracking.service';
import { BrowserBackButtonClicked, CallAccepted, CallEnded, CallRejected, HomeButtonClicked, LoggedOff, SentPaymentLink, SuccessfulSale, TicketAccepted, TicketRejected, ToggledCallsOff, ToggledCallsOn, UnsuccessfulSale } from '../actions/agent-action-actions/agent-action.actions';
import { AgentActionLoggingRequest } from '../types/agent-action-logging-request.interface';
import { CoreState } from 'src/app/core/store/state/core.state';
import { AgentActionLoggingActions } from '../actions/agent-action-logging-actions';
import { SetConnectedPostCall, SetInCall, SetNonConnectedPostCall } from '../actions/agent-status-actions/agent-status.actions';
import { Dictionary } from 'src/app/shared/interfaces/dictionary.interface';
import { CustomerInfoState } from 'src/app/customer-info-summary-page/store/state/customer-info.state';


interface AgentActionStateModel {
    lastAction: AgentAction | ExtraLoggingAction
}
@State<AgentActionStateModel>({
    name: 'sf_agent_action_state',
    defaults: {
        lastAction: null
    }
})
@Injectable()
export class AgentActionState {

    //TODO: Split out non action logging stuff

    @Selector()
    static getLastAction(state: AgentActionStateModel) { return state.lastAction }

    constructor(private agentTrackingService: AgentTrackingService,
        private store: Store) {
    }

    @Action([
        CallEnded,
        BrowserBackButtonClicked,
        HomeButtonClicked,
        ToggledCallsOn,
        ToggledCallsOff,
        LoggedOff
    ])
    UpdateDefaultAction(ctx: StateContext<AgentActionStateModel>, action: AgentActionAction) {
        this.setAction(ctx, action);
        this.agentTrackingService.sendAgentActionUpdate(action?.actionType);
    }


    @Action([
        CallRejected,
        CallAccepted,
    ])
    UpdateAgentCallResponse(ctx: StateContext<AgentActionStateModel>, action: CallRejected | CallAccepted) {
        this.setAction(ctx, action);

        const { actionType } = action ?? {};
        this.agentTrackingService.sendAgentActionUpdate(actionType);

        const payload = this.getLoggingPayload({
            actionType: actionType === "call_rejected" ? "agent_rejected_call" : "agent_accepted_call"
        });

        return ctx.dispatch(new AgentActionLoggingActions.LogAction(payload));
    }


    @Action(SetInCall)
    CustomerAcceptedCall(ctx: StateContext<AgentActionStateModel>, action: SetInCall) {
        const { callType } = action;
        if (callType === "outgoing") {

            const payload = this.getLoggingPayload({ actionType: "customer_accepted_call" });
            return ctx.dispatch(new AgentActionLoggingActions.LogAction(payload));
        }
    }

    @Action(SetConnectedPostCall)
    OutboundCallFinished(ctx: StateContext<AgentActionStateModel>, action: SetConnectedPostCall) {
        const { callType, durationInSeconds } = action;
        if (callType === "outgoing") {

            const payload = this.getLoggingPayload({
                actionType: "outbound_call_finished",
                extra: {
                    duration_in_seconds: durationInSeconds
                }
            });
            return ctx.dispatch(new AgentActionLoggingActions.LogAction(payload));
        }
    }


    @Action(SetNonConnectedPostCall)
    CustomerRejectedCall(ctx: StateContext<AgentActionStateModel>, action: SetNonConnectedPostCall) {
        const { callType, wasEstablishing } = action;
        if (callType === "outgoing" && wasEstablishing) {

            const payload = this.getLoggingPayload({ actionType: "customer_rejected_call" });
            return ctx.dispatch(new AgentActionLoggingActions.LogAction(payload));
        }
    }

    @Action([
        TicketRejected,
        TicketAccepted,
    ])
    UpdateTicketResponse(ctx: StateContext<AgentActionStateModel>, action: TicketRejected | TicketAccepted) {
        this.setAction(ctx, action);

        const { actionType, metaData } = action ?? {};
        const payload = this.getLoggingPayload({ actionType, ...metaData ?? {} });

        this.agentTrackingService.sendAgentActionUpdate(actionType);
        return ctx.dispatch(new AgentActionLoggingActions.LogAction(payload));
    }


    @Action([
        SuccessfulSale,
        UnsuccessfulSale
    ])
    UpdateSaleSuccessResponse(ctx: StateContext<AgentActionStateModel>, action: SuccessfulSale | UnsuccessfulSale) {
        this.setAction(ctx, action);

        const { actionType, hexId, orderNumber, amount } = action ?? {};
        const payload = this.getLoggingPayload({
            actionType,
            hexId,
            extra: {
                order_number: orderNumber,
                amount
            }
        });

        return ctx.dispatch(new AgentActionLoggingActions.LogAction(payload));
    }


    @Action(SentPaymentLink)
    SentPaymentLink(ctx: StateContext<AgentActionStateModel>, action: SentPaymentLink) {
        this.setAction(ctx, action);

        const { actionType, orderNumber } = action ?? {};
        const payload = this.getLoggingPayload({
            actionType,
            extra: {
                order_number: orderNumber,
            }
        });

        return ctx.dispatch(new AgentActionLoggingActions.LogAction(payload));
    }


    private setAction(ctx: StateContext<AgentActionStateModel>, action: AgentActionAction | ExtraLoggingActionAction) {
        const { actionType } = action ?? {};

        ctx.patchState({
            lastAction: actionType
        })
    }


    private getLoggingPayload(data: { actionType: AgentAction | ExtraLoggingAction, hexId?: string, extra?: Dictionary, customerEmail?: string }): AgentActionLoggingRequest {
        const { actionType, hexId, extra, customerEmail } = data ?? {};
        const { agent_email, ticket_hex_id } = this.store.selectSnapshot(CoreState.getOrderMetaData) ?? {};
        const custEmail = customerEmail ?? this.store.selectSnapshot(CustomerInfoState.getCustomerEmail);

        return {
            action: <Uppercase<AgentAction | ExtraLoggingAction>>actionType?.toUpperCase(),
            external_reference: hexId ?? ticket_hex_id,
            agent_email,
            customer_email: custEmail,
            extra
        }
    }

}   