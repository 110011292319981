import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'sf-sit-warning-modal',
  templateUrl: './sit-warning-modal.component.html',
  styleUrls: ['./sit-warning-modal.component.scss']
})
export class SitWarningModalComponent implements OnInit {

  isOpen = false;

  ngOnInit(): void {
    this.isOpen = environment.show_sit_warning_message;
  }

  closeModal() {
    this.isOpen = false;
  }

}
