import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngxs/store';
import { ToggleModalByName } from 'src/app/core/store/actions/modal.actions';
import { SnowflakeSelectOption } from 'src/app/shared/elements/rain-forms/elements/snowflake-select/interfaces/rain-select-option.interface';
import { createSFselectOptions } from 'src/app/shared/functions/create-sf-select-options.function';
import { PostTicketModalOptions } from 'src/app/shared/services/post-ticket/post-ticket-modal-options.model';
import { PostTicketService } from 'src/app/shared/services/post-ticket/post-ticket.service';
import { FormHelper } from 'src/app/Utils/helpers';
import { InteractionTicketsState } from '../../store/state/interaction-tickets.state';
import { ChatJsonNote } from '../components/chat-json-note/assets/chat-json-note.interface';

@Component({
    selector: 'sf-retenstions-update-modal',
    templateUrl: './retenstions-update-modal.component.html',
    styleUrls: ['./retenstions-update-modal.component.scss']
})
export class RetenstionsUpdateModalComponent extends FormHelper implements OnInit, OnDestroy {

    modalOptions: PostTicketModalOptions;

    notClosingReasons: SnowflakeSelectOption[] = [];
    escalationReasons: SnowflakeSelectOption[] = [];
    showEscalationsReason = false;

    form: FormGroup;

    constructor(private fb: FormBuilder,
        private store: Store,
        private postTicketService: PostTicketService,) {
        super();
    }

    ngOnInit(): void {
        this.modalOptions = new PostTicketModalOptions();

        this.escalationReasons = createSFselectOptions(["Sim Swap", "Device Swap", "Collection"]);
        this.notClosingReasons = createSFselectOptions(["No Contact", "Pending customer feedback", "Escalated to logistics", "Dasher sent"]);

        this.form = this.fb.group({
            not_closing_reason: this.fb.control(null, [Validators.required]),
            escalation_reason: this.fb.control(null),
        });

        this.InitForm(this.form);
    }

    onSelect(value: string, controlName: string) {
        if (controlName === "not_closing_reason") {
            const escalationsControl = this.getControl("escalation_reason");
            if (value === "Escalated to logistics") {
                escalationsControl.addValidators([Validators.required]);
                this.showEscalationsReason = true;
            }
            else {
                escalationsControl.removeValidators([Validators.required]);
                this.showEscalationsReason = false;
            }
        }
        this.getControl(controlName).patchValue(value);
    }

    onSend() {
        const data = this.form.getRawValue();
        const payload: ChatJsonNote = {
            message: "Retentions post update form",
            detail: data,
            type: "retentions_update_form"
        }

        const hexId = this.store.selectSnapshot(InteractionTicketsState.getSelectedHexId);
        this.postTicketService.sendPostTicketPayload(
            hexId, payload, this.modalOptions, this.close.bind(this)
        );
    }

    close() {
        this.store.dispatch(new ToggleModalByName("retentions_update_modal", false));
        this.resetForm();
    }

    ngOnDestroy(): void {
        this.close();
    }
}
