<div
    class="chat-section"
    [class.expand-chat]="isExpanded"
>
    <div class="top-content">
        <mat-icon
            class="text-blue pointer back-button"
            (click)="onCloseChat()"
        >
            arrow_back_ios
        </mat-icon>
        <p class="center-container font-normal text-blue">
            {{ externalId }}
        </p>

        <div>
            <sf-ticket-eyeball [hexId]="hexId"></sf-ticket-eyeball>
            <sf-callback-scheduler></sf-callback-scheduler>
        </div>

    </div>


    <div
        #notesScrollbar
        class="notes scrollable"
        [scrollTop]="notesScrollbar.scrollHeight"
        [class.is-sales-agent]="agentType ==='SALES'"
    >
        <app-custom-spinner *ngIf="loading$ | async; else loaded"> </app-custom-spinner>

        <ng-template #loaded>

            <ng-container *ngIf="(ticketHelper$ | async)?.noteHelper?.chatDataList?.length; else noData">
                <div *ngFor="let chatData of (ticketHelper$ | async)?.noteHelper?.chatDataList">
                    <ng-container [ngSwitch]="chatData?.chatType">

                        <chat-bubble
                            *ngSwitchCase="'note'"
                            [note]="chatData?.data"
                            [noteHelper]="ticketHelper?.noteHelper"
                        >
                        </chat-bubble>

                        <chat-bubble
                            *ngSwitchCase="'html-note'"
                            [note]="chatData?.data"
                            [noteHelper]="ticketHelper?.noteHelper"
                        >
                        </chat-bubble>

                        <sf-chat-json-note
                            *ngSwitchCase="'json-note'"
                            [note]="chatData?.data"
                        ></sf-chat-json-note>

                        <chat-attachment
                            *ngSwitchCase="'attachment'"
                            [attachment]="chatData?.data"
                        ></chat-attachment>

                        <chat-event
                            *ngSwitchCase="'event'"
                            [note]="chatData?.data"
                        ></chat-event>

                    </ng-container>
                </div>
            </ng-container>

            <ng-template #noData>
                <p
                    class="no-notes-error"
                    *ngIf="(error$ | async) as error"
                >
                    {{error}}
                </p>
            </ng-template>

            <chat-updated-message
                *ngIf="chatUpdateSettings.message"
                [success]="chatUpdateSettings.success"
                [description]="chatUpdateSettings.message"
                (close)="onCloseUpdateMessage()"
            >
            </chat-updated-message>

            <sf-attachment-loading
                *ngFor="let file of files; let i = index"
                [file]="file"
                [hex_id]="hexId"
                [isPrivate]="isPrivate"
                (close)="onRemoveFile(i)"
                (loadedSuccessfully)="refreshTicket()"
            >
            </sf-attachment-loading>

            <div
                *ngIf="chatUpdateSettings?.sending"
                class="spinner-wrapper"
            >
                <small-text-spinner>sending..</small-text-spinner>
            </div>
            <div
                *ngIf="chatUpdateSettings?.relocating"
                class="spinner-wrapper"
            >
                <small-text-spinner>relocating..</small-text-spinner>
            </div>

        </ng-template>
    </div>

    <div
        class="chat-input"
        [class.is-sales-agent]="agentType==='SALES'"
    >
        <div class="bottom-section">
            <div class="flex-container">
                <div class="flex-child">
                    <mat-tab-group
                        (selectedTabChange)="toggleIsPrivate($event)"
                        [disablePagination]="true"
                    >
                        <mat-tab>
                            <ng-template
                                mat-tab-label
                                class="note"
                                label="public note"
                            >
                                <span [class.text-blue]="!isPrivate">public note</span>
                            </ng-template>
                        </mat-tab>
                        <mat-tab>
                            <ng-template
                                mat-tab-label
                                class="note"
                                label="private note"
                            >
                                <span [class.text-blue]="isPrivate">private note</span>
                            </ng-template>
                        </mat-tab>
                    </mat-tab-group>
                </div>
                <div class="flex-child">
                    <mat-icon
                        class="text-blue pointer chevron-more"
                        (click)="toggleTextExpand()"
                    >{{ isExpanded ? "expand_more" :
                        "expand_less" }}
                    </mat-icon>
                </div>
            </div>

            <div
                class="textarea-wrapper"
                [formGroup]="noteForm"
                [class.has-error]="noteForm?.invalid && noteForm?.dirty"
            >
                <div
                    align="center"
                    class="template-manager"
                >
                    <button
                        class="button template-button rain-button"
                        [matMenuTriggerFor]="menuForTheMessages"
                    >
                        templates
                    </button>

                    <mat-menu
                        #menuForTheMessages="matMenu"
                        yPosition="above"
                    >
                        <div *ngFor="let category of categories">
                            <button
                                class="rain-button mat-menu-item"
                                mat-menu-item
                                [matMenuTriggerFor]="categoriesSuggestedMessages"
                                (mouseenter)="setTemplateCategory(category)"
                            >
                                {{ category }}
                            </button>
                        </div>
                    </mat-menu>

                    <mat-menu #categoriesSuggestedMessages>
                        <button
                            class="rain-button mat-menu-item"
                            mat-menu-item
                            *ngFor="let option of templateOptions"
                            (click)="setTemplateMessage(option)"
                        >
                            {{ option }}
                        </button>
                    </mat-menu>
                </div>
                <textarea
                    class="scrollable"
                    [class.is-private-textarea]="isPrivate"
                    formControlName="comment"
                    cols="5"
                    rows="2"
                >
        </textarea>

                <p
                    *ngIf="noteForm?.invalid && noteForm?.dirty"
                    class="comment-error"
                >
                    {{ (getErrors("comment", noteform) | errorKeys)?.[0] | errorValue }}
                </p>
            </div>
            <ng-container>
                <div
                    class="flex-container2 buttons parent"
                    *ngIf="{isClosed: isTicketClosed$ | async} as closedState"
                    [matTooltip]="closedState?.isClosed ? 'Can not update a closed ticket!': ''"
                >
                    <div class="flex-child2 send-buttons">
                        <div id="close-button">
                            <!-- TODO: clean this up, repeating code -->
                            <button
                                [disabled]="noteForm?.invalid || (loading$ | async) || closedState?.isClosed"
                                class="blank-button decline-button default-button-size"
                                (click)="onAddNote('close')"
                                width="5rem"
                            >
                                close
                            </button>
                        </div>

                        <div>
                            <button
                                [disabled]="noteForm?.invalid || (loading$ | async) || closedState?.isClosed"
                                class="rain-button default-button-size"
                                (click)="onAddNote('update')"
                                width="5rem"
                            >
                                update
                            </button>
                        </div>
                    </div>
                    <div class="flex-child2 plus-button more_icon">
                        <button
                            mat-icon-button
                            [matMenuTriggerFor]="aboveMenu"
                            #menuTrigger="matMenuTrigger"
                            [disabled]="closedState?.isClosed"
                        >
                            <mat-icon
                                class="menu"
                                title="More"
                            >add_circle_outline</mat-icon>
                        </button>
                        <mat-menu #aboveMenu="matMenu">
                            <button
                                class="rain-button mat-menu-item"
                                mat-menu-item
                                (click)="toggleAttachmentModal()"
                            >
                                attach file
                            </button>
                            <button
                                class="rain-button mat-menu-item"
                                mat-menu-item
                                (click)="checkClosed()"
                            >
                                escalate
                            </button>
                        </mat-menu>
                    </div>
                </div>
            </ng-container>

        </div>

        <div class="file-upload-wrapper">
            <app-file-uploader
                *ngIf="attachmentModalOpen"
                (newFiles)="attachFileData($event)"
                (close)="attachmentModalOpen = false"
            ></app-file-uploader>
        </div>
    </div>
</div>