import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Inviter, SessionState, InviterInviteOptions } from 'sip.js';
import { SipCallType } from '../assets/sip-call.type';
import { SipMessageSubject } from '../assets/sip-message-subject.type';
import { Store } from '@ngxs/store';
import { ICallQueues } from '../assets/call-queue.interface';
import { SipPhoneService } from '../services/sip-phone.service';
import { CallLoggingService } from '../services/call-loggin.service';
import { CallDetails } from '../store/interfaces/call-details.interface';
import { SipPhoneActions } from '../store/actions/sip-phone-actions';
import { SetBusy, SetConnectingCall } from 'src/app/shared/components/agent/store/actions/agent-status-actions/agent-status.actions';
import { AgentActions } from 'src/app/shared/components/agent/store/actions/agent-action-actions';
import { SetOutgoingCallDetails } from '../store/actions/sip-phone-actions/sip-phone.action';

@Component({
  selector: 'app-outgoing-call-modal',
  templateUrl: './outgoing-call-modal.component.html',
  styleUrls: ['./outgoing-call-modal.component.scss', '../assets/sip-phone-styles.scss']
})
export class OutgoingCallModalComponent implements OnInit, OnDestroy {

  callStateMessage = "";
  minimized = false;
  callerNumber = "";
  showCallTimer = false;
  inviter: Inviter;
  callType: SipCallType;
  keypadKeys: string[] = [];
  inviterOptions: InviterInviteOptions = {}
  private callSub: Subscription;
  @Input() callQueues: ICallQueues[]

  constructor(public sipPhoneService: SipPhoneService,
    private callLoggingService: CallLoggingService,
    private store: Store) { }

  ngOnInit(): void {
    this.callStateMessage = "make a call";
    this.callSub = this.sipPhoneService.callSubject.subscribe(
      (response: Map<SipMessageSubject, any>) => {
        if (response.has("call_type_changed")) this.callType = response.get("call_type_changed");
        else if (response.has("state_changed")) {
          if (this.callType === "incoming") return;
          this.handleStateChange(response.get("state_changed"));
        }
        else if (response.has("call_number")) this.callNumber(response.get("call_number"));
        else if (response.has("error")) this.onTerminate();
      }
    );
    this.generateKeys();
  }

  generateKeys() {
    const keys = [];
    for (let i = 1; i < 10; i++) {
      keys.push(i.toString());
    }
    this.keypadKeys = [...keys, "*", "0", "#"];
  }

  addClickedKey(key: string) {
    this.callerNumber += key;
  }

  toggleMinimized() {
    this.minimized = !this.minimized;
  }

  onEndCall() {
    if (!this.inviter) {
      return;
    }

    if (this.inviter.state === SessionState.Established) {
      this.store.dispatch(new AgentActions.CallEnded());
    }

    this.sipPhoneService.endCall();
  }

  private callNumber(theNumber: string) {
    if (!theNumber) return;
    this.callerNumber = theNumber;
    this.onCall();
  }

  async onCall() {
    if (this.callerNumber === "") return;

    this.callStateMessage = "calling..";
    this.store.dispatch(new SetConnectingCall("outgoing"));

    const response = await this.sipPhoneService.getInviter(this.callerNumber);
    if (!response) {
      this.store.dispatch(new SetBusy());
      return;
    }

    this.inviter = response;
    this.inviter.invite();
    this.sipPhoneService.handleSession(this.inviter);
    this.handleStateChange(this.inviter.state);
  }

  onHold() {
    if (SessionState.Established === 'Established') {
      this.sipPhoneService.onHold()
      this.callStateMessage = "on hold";
    }
  }

  async resumeCall() {
    this.sipPhoneService.resumeCall()
    this.callStateMessage = 'connected'
  }

  transferCall(queue: ICallQueues) {

    this.sipPhoneService.transferCall(queue);
  }

  handleStateChange(state: SessionState) {
    switch (state) {
      case SessionState.Initial:
        this.callStateMessage = "initializing connection..";
        break;
      case SessionState.Establishing:
        this.onEstablishing();
        break;
      case SessionState.Established:
        this.onEstablished();
        break;
      case SessionState.Terminating:
        this.callStateMessage = "ending call..";
        break;
      case SessionState.Terminated:
      default:
        this.onTerminate();
    }
  }

  onEstablishing() {
    this.callStateMessage = "connecting call..";
    this.logCall("attempt");
  }

  onEstablished() {
    this.callStateMessage = "connected";
    this.startTimer();
    this.logCall("outgoing");
  }

  private getCallDetails(): CallDetails | null {
    if (!this.inviter || !this.callerNumber) {
      return null;
    }

    const callId = this.inviter?.request?.callId;

    //Outgoing calls use the same recording ID as the call ID
    return {
      callId,
      recordingId: callId,
      phoneNumber: this.callerNumber
    }
  }

  private async logCall(type: "outgoing" | "attempt") {
    const callDetails = this.getCallDetails();
    if (!callDetails) {
      return;
    }

    this.store.dispatch(new SetOutgoingCallDetails(callDetails));

    const hexId = await this.callLoggingService.getHexIdForOutgoingCall();
    if (!hexId) {
      return;
    }

    const logCallAction = new SipPhoneActions.LogCall(type, { hexId, callDetails });
    const { callId } = callDetails ?? {};

    if (type === "outgoing" && callId) {

      this.store.dispatch([
        logCallAction,
        new SipPhoneActions.LogCall("outgoingRecordingId", { hexId, callDetails })
      ]);
    }
    else {
      this.store.dispatch(logCallAction);
    }
  }

  onTerminate() {
    this.inviter = null;
    this.callerNumber = "";
    this.showCallTimer = false;
    this.callStateMessage = "make a call";
  }

  startTimer() {
    this.showCallTimer = true;
  }

  ngOnDestroy() {
    if (this.callSub) this.callSub.unsubscribe();
  }
}
