import { Utils } from "src/app/Utils";


export class AgentViewDestroyed {
    static readonly type = Utils.Helpers.Type("[Agent View] Agent view destroyed");
}

export class ClearCurrentTicketDetails {
    static readonly type = Utils.Helpers.Type("[Agent View] Clear current ticket details");
}

export class RejectAgentViewTicketOrCallback {
    static readonly type = Utils.Helpers.Type("[Agent View] Reject ticket or callback");
}

export class AcceptAgentViewTicketOrCallback {
    static readonly type = Utils.Helpers.Type("[Agent View] Accept ticket or callback");
}