<sf-popup-modal
  [isOpen]="true"
  [hasCloseButton]="false"
  [autoClose]="false"
  [successResponse]="modalOptions.successResponse"
  [sending]="modalOptions.sending"
  [spinnerMessage]="modalOptions.spinnerMessage"
  [limitHeight]="true"
>
  <ng-container
    *ngIf="isRainOneAgent; else isRain101AgentHeader"
    header
  > rain one retention questionnaire</ng-container>
  <ng-template
    #isRain101AgentHeader
    header
  > rain 101 retention questionnaire</ng-template>

  <ng-container mainContent>
    <sf-modal-form-container [formGroup]="form">
      <div class="checkbox-div">
        <sf-form-checkbox
          [control]="this.form.get('retained')"
          [label]="'Retained?'"
        >
        </sf-form-checkbox>
      </div>


      <div class="select-options">
        <sf-select
          label="selected msisdn"
          [options]="msisdnOptions"
          labelStyle="thin-font-no-gap"
          (onOptionSelect)="onSelectOptions('select_msisdn',$event)"
        ></sf-select>
        <sf-form-checkbox
          *ngIf="isRainOneAgent"
          [control]="this.form.get('migrated_to_rain_one')"
          [label]="'Migrated to rainOne?'"
        >
        </sf-form-checkbox>
        <sf-select
          *ngIf="isMigratedToRainOne"
          label="select msisdn migrated to rainOne"
          [options]="msisdnOptions"
          labelStyle="thin-font-no-gap"
          (onOptionSelect)="onSelectOptions('msisdn_migrated_to_rainone',$event)"
        ></sf-select>

        <sf-rain-multi-select
          *ngIf="isRetained"
          label="Retained - Yes"
          [options]="retainedYesOptions"
          labelStyle="thin-font-no-gap"
          (onOptionSelect)="onSelectOptions('retained_yes',$event)"
        >
        </sf-rain-multi-select>

        <sf-rain-multi-select
          *ngIf="!isRetained"
          label="Retained - No"
          [options]="retainedNoOptions"
          labelStyle="thin-font-no-gap"
          (onOptionSelect)="onSelectOptions('retained_no',$event)"
        >
        </sf-rain-multi-select>

        <sf-rain-multi-select
          *ngIf="this.hasOtherOptions['network_issues'] && !isRetained"
          label="Network issues"
          [control]="this.form.get('network_issues')"
          [options]="networkOptions"
          labelStyle="thin-font-no-gap"
          (onOptionSelect)="onSelectOptions('network_issues',$event)"
        >
        </sf-rain-multi-select>
        <sf-rain-multi-select
          *ngIf="this.hasOtherOptions['voice_issues'] && !isRetained"
          label="Voice issues"
          [options]="voiceOptions"
          labelStyle="thin-font-no-gap"
          [control]="this.form.get('voice_issues')"
          (onOptionSelect)="onSelectOptions('voice_issues',$event)"
        ></sf-rain-multi-select>



        <sf-form-textarea
          label="comments"
          [control]="this.form.get('comments')"
          placeholder="comments"
        ></sf-form-textarea>

      </div>

      <div class="spacer"></div>
      <p
        class="red error"
        *ngIf="!isAnyOptionSelected"
      >Atleast one option needs to be selected.</p>
    </sf-modal-form-container>
  </ng-container>

  <ng-container buttons>
    <button
      class="rain-button default-button-size send-button solid"
      (click)="onSend()"
      [disabled]="form.invalid || modalOptions.sending ||!isAnyOptionSelected"
    >
      send
    </button>
  </ng-container>
</sf-popup-modal>
