import { Utils } from "src/app/Utils";
import { FirebaseFeatureFlagsResponse } from "../../interfaces/firebase-feature-flags-response.interface";

export class FetchIfNotLoadingOrLoaded {
  static readonly type = Utils.Helpers.Type("[Firebase Feature Flags] Fetch if not loading or loaded");
}

export class Fetch {
  static readonly type = Utils.Helpers.Type("[Firebase Feature Flags] Fetch");
}

export class FetchSuccess {
  static readonly type = Utils.Helpers.Type("[Firebase Feature Flags] Fetch success");
  constructor(public response: FirebaseFeatureFlagsResponse) { }
}

export class FetchFail {
  static readonly type = Utils.Helpers.Type("[Firebase Feature Flags] Fetch fail");
  constructor(public error: unknown) { }
}

export class Clear {
  static readonly type = Utils.Helpers.Type("[Firebase Feature Flags] Clear");
}
