import moment, { unitOfTime } from "moment";
import { DefaultColorOptions, StatusGroup } from "./datafield-options.interface";

export const DEFAULT_RESPONSE = "--";
export const UNKNOWN_RESPONSE = "unknown";

export const COLOR_MAP = {
  dark_green: "#3d945a",
  green: "#4cc274",
  orange: "#F7B200",
  yellow: "#F7D800",
  red: "#E02020",
  "dark red": "#921400",
  dark_red: "#921400",
  purple: "#AE44FF",
  blue: "#85C9E8",
  light_blue: "#D5F0FC",
  grey: "grey",
  rain_blue: "#0077C8",
  light_grey: "#c8c8c8"
} as const;

export type ColorMapKey = keyof typeof COLOR_MAP;

const ICON_MAP = {
  green: "check_circle",
  orange: "info",
  red: "error"
}


export class DataHandler {

  static isDefined(value: any) {
    return (value !== undefined && value !== null);
  }

  static createObjectFromKeys(keys: string[], inputObject: any, defaultResponse = null): any {
    const outputObject = {};
    keys.forEach(key => {
      if (!inputObject) {
        outputObject[key] = defaultResponse;
        return;
      }
      outputObject[key] = defaultResponse;
      if (inputObject?.[key] || inputObject?.[key] === 0) outputObject[key] = inputObject[key];
    });
    return outputObject;
  }

  static validateData(input: any, response?: string) {
    const selectedResponse = response ?? DEFAULT_RESPONSE;
    let result = selectedResponse;
    if (DataHandler.isDefined(input)) result = input;
    return result;
  }

  static formatDate(date: string) {
    if (!date) return null;
    return date.replace("T", " at ").substring(0, 19);
  }

  static roundToSelectedDecimal(data: number, decimalPlace: number) {
    const x = Math.pow(10, decimalPlace);
    return (Math.round(data * x) / x);
  }

  static getMoneyFormat(input: number) {
    if (!input) return;
    return (Math.round(input * 100) / 100).toFixed(2);
  }

  static roundDownToSelectedDecimal(data: number, decimalPlace: number) {
    const x = Math.pow(10, decimalPlace);
    return (Math.floor(data * x) / x);
  }

  static formatKey(key: string) {
    const newString = key.replace(/_/g, " ").trim();
    return newString.toLowerCase();
    //return newString.charAt(0).toUpperCase() + newString.slice(1);
  }

  static removeUnderscores(input: string) {
    if (!input) return;
    return input.toString().replace(/_/g, " ").trim();
  }

  static getHeaderFormat(input: string | number) {
    if (!DataHandler.isDefined(input)) return;
    const output = input.toString().replace(/_/g, " ").trim();
    return output.charAt(0).toUpperCase() + output.slice(1);
  }


  //status and color group values are all converted to lowercase
  static getStatusColorChoice(status: string | number, statusGroup: StatusGroup): string {
    if (!DataHandler.isDefined(status)) return statusGroup.defaultColor;

    let colorChoice = statusGroup.defaultColor;
    const lowerCaseStatus = status.toString().toLowerCase().trim();
    for (const key in statusGroup) {
      if (key !== "defaultColor" && statusGroup[key].length) {
        const lowerCaseArray = DataHandler.getLowerCaseArray(statusGroup[key])
        if (lowerCaseArray.includes(lowerCaseStatus)) {
          colorChoice = (key as DefaultColorOptions);
          break;
        }
      }
    }

    return colorChoice;
  }

  static getLowerCaseArray(inputArray: string[]): string[] {
    const outputArray = [];
    inputArray.forEach(item => outputArray.push(item.toString().toLowerCase()));
    return outputArray;
  }

  static getColorCode(colorChoice: string) {
    return COLOR_MAP[colorChoice];
  }

  static getTdSpacing(items: number) {
    return `${(1 / items) * 100}%`;
  }

  static valuesEqualAndDefined(value1: any, value2: any) {
    const values = [value1, value2];
    for (let i = 0; i < values.length; i++) {
      const value = values[i];
      if (!value && value !== 0) return false;
    }
    return value1 === value2;
  }

  static getNumberPlacementSuffix(value: number | string) {
    if (!value) return value;
    const stringValue = value.toString();
    if (stringValue.endsWith("1")) return value + "st";
    if (stringValue.endsWith("2")) return value + "nd";
    if (stringValue.endsWith("3")) return value + "rd";
    return value + "th";
  }

  static getIcon(colorChoice: string) {
    return ICON_MAP[colorChoice];
  }


  static convertNaNResponse(input: string) {
    if (input === null || input === undefined) return null;
    return input?.replace("NaN", null);
  }

  /**
   * returns a deep copy of the object passed in
   */
  static createDeepCopy<T>(input: T): T {
    if (!DataHandler.isDefined(input)) return;
    return JSON.parse(JSON.stringify(input));
  }

  /** Default sorting is oldest first */
  static sortByDate(a: string, b: string, sorting: "oldestFirst" | "newestFirst" = "oldestFirst") {
    if (sorting === "oldestFirst") {
      return getTime(a) - getTime(b);
    } else {
      return getTime(b) - getTime(a);
    }

    function getTime(input: string) {
      const sameFormatDate = DataHandler.removeZfromDate(input);
      return new Date(sameFormatDate).getTime();
    }
  }

  static removeZfromDate(input: string) {
    if (input?.includes("z") || input?.includes("Z")) {
      return input.slice(0, -1);
    } else {
      return input;
    }
  }

  static getDiffInTime(dateString1: string | number, dateString2: string | number, timeUnit: unitOfTime.Diff) {
    const date1 = dateString1 === "now" ? moment() : moment(new Date(dateString1));
    const date2 = dateString2 === "now" ? moment() : moment(new Date(dateString2));
    return date1.diff(date2, timeUnit);
  }

  static getObjectAsArray(obj: { [x: string]: any; }) {
    return Object.keys(obj).map(key => obj[key]);
  }

}
