import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FileUploaderComponent } from './container/file-uploader.component';
import { DndZoneComponent } from './components/dnd-zone/dnd-zone.component';
import { UtilsModule } from 'src/app/Utils/utils.module';
import { MatIconModule } from '@angular/material/icon';


@NgModule({
  imports: [
    CommonModule,
    UtilsModule,
    MatIconModule,
  ],
  declarations: [DndZoneComponent, FileUploaderComponent],
  exports: [
    FileUploaderComponent
  ]
})
export class FileUploaderModule { }
