import { Component, OnDestroy } from '@angular/core';
import { Store } from '@ngxs/store';
import { SalesPortalDestroyed } from '../store/action/sales-portal.actions';
import { CustomerInfoState } from 'src/app/customer-info-summary-page/store/state/customer-info.state';

@Component({
    selector: 'sf-sales-portal',
    templateUrl: './sales-portal.component.html',
    styleUrls: ['./sales-portal.component.scss']
})
export class SalesPortalComponent implements OnDestroy {

    readonly knownCustomerEmail = this.store.selectSnapshot(CustomerInfoState.getCustomerEmail);

    constructor(private store: Store) { }

    ngOnDestroy(): void {
        this.store.dispatch(new SalesPortalDestroyed());
    }
}
