import { Utils } from "src/app/Utils";
import { BillingCyclePayload } from "../interfaces/bill-lifecycle-payload.interface";
import { GeneratePaymentLinkActionPayload, TopupPaymentLinkPayload } from "../types/payment-link.interface";
import { Dictionary } from "src/app/shared/interfaces/dictionary.interface";
import { ExtraActions } from "src/app/shared/interfaces/extra-actions.interface";
import { getDefaultExtraActions } from "src/app/shared/functions/get-default-extra-actions.function";


export class GeneratePaymentTopupLink {
    static readonly type = Utils.Helpers.Type('[[Sales] Generate payment link for known customer');
    constructor(public payload: TopupPaymentLinkPayload, public extraActions: ExtraActions = getDefaultExtraActions()) { }
}

export class GeneratePaymentLink {
    static readonly type = Utils.Helpers.Type('[[Sales] Generate payment link')
    constructor(public payload: GeneratePaymentLinkActionPayload, public extraActions: ExtraActions = getDefaultExtraActions()) { }
}

export class CancelPaymentLinksForOrder {
    static readonly type = Utils.Helpers.Type('[[Sales] Cancel payment links for order')
    constructor(public paymentLinkPayload: GeneratePaymentLinkActionPayload) { }
}


export class CancelPaymentLinksSuccess {
    static readonly type = Utils.Helpers.Type('[[Sales] Cancel payment links successful');
    constructor(public paymentLinkPayload: GeneratePaymentLinkActionPayload) { }
}

export class CancelPaymentLinksFail {
    static readonly type = Utils.Helpers.Type('[[Sales] Cancel payment links fail');
}

export class GeneratePaymentLinkSuccess {
    static readonly type = Utils.Helpers.Type('[[Sales] Generate payment link successful');
    constructor(public orderNumber: number, public successActions: Dictionary[] = []) { }
}

export class GeneratePaymentTopupLinkSuccess {
    static readonly type = Utils.Helpers.Type('[[Sales] Generate payment topup link successful');
    constructor(public successActions: Dictionary[] = []) { }
}


export class GeneratePaymentLinkFail {
    static readonly type = Utils.Helpers.Type('[[Sales] Generate payment link fail');
    constructor(public error: unknown, public failActions: Dictionary[] = []) { }
}

export class SetBillingCycleID {
    static readonly type = Utils.Helpers.Type('[[Sales] Set the billing cycle id');
    constructor(public cycleId: string, public paymentDay: number) { }
}

export class SaveBillingCycle {
    static readonly type = Utils.Helpers.Type('[Sales] Set Billing Cycle');
    constructor(public payload: BillingCyclePayload, public extraActions: ExtraActions = getDefaultExtraActions()) { }
}

export class SaveBillingCycleSuccess {
    static readonly type = Utils.Helpers.Type('[Sales] Set Billing Cycle Successful');
    constructor(public successActions: Dictionary[] = []) { }
}

export class SaveBillingCycleFail {
    static readonly type = Utils.Helpers.Type('[Sales] Set Billing Cycle Fail');
    constructor(public error: unknown, public failActions: Dictionary[] = []) { }
}
