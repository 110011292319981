import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { IndexedDBService } from '../../indexed-db.service';
import { IndexedDBActions } from '../actions/indexed-db-actions';


type IndexedDBStateModel = Record<string, unknown>;
@State<IndexedDBStateModel>({
    name: 'sf_indexed_db_state',
    defaults: {}
})
@Injectable()
export class IndexedDBState {

    constructor(private indexedDBservice: IndexedDBService) {
    }

    @Action(IndexedDBActions.SetItem)
    Set(_: StateContext<IndexedDBStateModel>, action: IndexedDBActions.SetItem) {
        const { key, value } = action;
        return this.indexedDBservice.set(key, value, true);
    }


    @Action(IndexedDBActions.ClearItem)
    ClearItem(_: StateContext<IndexedDBStateModel>, action: IndexedDBActions.ClearItem) {
        const { key } = action;
        return this.indexedDBservice.remove(key);
    }

}   