import { Utils } from "src/app/Utils";
import { BasicCustomerService } from "../../interfaces/basic-customer-service.interface";

export interface ResponseError {
    "error": "ERROR",
    "reason": "No services found for this user"
}

export class Fetch {
    static readonly type = Utils.Helpers.Type("[CustomerService] fetch");
    constructor(public userId: string) { }
}

export class FetchSuccess {
    static readonly type = Utils.Helpers.Type("[CustomerService] fetch success");
    constructor(public payload: BasicCustomerService[]) { }
}

export class FetchFail {
    static readonly type = Utils.Helpers.Type("[CustomerService] fetch fail");
    constructor(public error: ResponseError | string | unknown) { }
}

export class Clear {
    static readonly type = Utils.Helpers.Type("[CustomerService] clear");
}