<sf-popup-modal [isOpen]="true" [hasCloseButton]="false" [autoClose]="false"
  [successResponse]="modalOptions.successResponse" [sending]="modalOptions.sending"
  [spinnerMessage]="modalOptions.spinnerMessage" [limitHeight]="true">

  <ng-container header>CSAT questionnaire</ng-container>

  <ng-container mainContent>

    <div class="form-fields">

      <label>select all applicable options:</label>

      <sf-multi-level-select [multiSelectMap]="multiOptions.bad_rating" (optionSelected)="onMultiOptionSelected()">
      </sf-multi-level-select>
      <br>

      <sf-multi-level-select [multiSelectMap]="multiOptions.solutions" (optionSelected)="onMultiOptionSelected()">
      </sf-multi-level-select>
      <br>

      <sf-select label="outcome of interaction" [options]="singleOptions.outcome"
        (onOptionSelect)="onSelect($event, 'outcome')" placeholder="select outcome of interaction"
        [hasError]="form.get('outcome').errors?.notSelected">
      </sf-select>
      <br>

      <sf-select label="change rating" [options]="singleOptions.change_rating"
        (onOptionSelect)="onSelect($event, 'change_rating')" placeholder="change rating?"
        [hasError]="form.get('change_rating').errors?.notSelected">
      </sf-select>
      <br>

      <sf-select *ngIf="showNewRatingOptions" label="new rating" [options]="singleOptions.new_rating"
        (onOptionSelect)="onSelect($event, 'new_rating')" placeholder="select new rating"
        [hasError]="form.get('new_rating').errors?.notSelected">
      </sf-select>
      <br>

    </div>

  </ng-container>

  <ng-container buttons>

    <sf-rain-button (click)="onSend()" [disabled]="!form || modalOptions?.sending ||
      ((form.invalid || !isMultiselectValid) && !(noAnswerOptionSelected || pendingOptionSelected ))">
      send
    </sf-rain-button>

  </ng-container>

</sf-popup-modal>
