import { ErrorHandler, Injectable } from "@angular/core";
import { ApmErrorHandler } from "@elastic/apm-rum-angular";
import { Store } from "@ngxs/store";
import { AddInfoNotification, AddSuccessResponseNotification } from "../store/actions/notifications.actions";
import { InfoPopupError } from "./info-error";
import { PopupError } from "./popup-error";
import { ToggleModalByName } from "../store/actions/modal.actions";
import { Utils } from "src/app/Utils";


@Injectable()
export class CustomErrorHandlerService implements ErrorHandler {

    constructor(private store: Store, private apmErrorHandler: ApmErrorHandler) {
    }

    handleError(error: PopupError | any): void {
        const errorMessage = Utils.Helpers.findError(error);
        if(errorMessage.includes('steps.jwt.TokenExpired')){
          this.store.dispatch(new ToggleModalByName('token_expired_modal'));
        }
        else if (error instanceof InfoPopupError) {
            this.store.dispatch(new AddInfoNotification(error?.message));
        }
        else if (error instanceof PopupError) {
            this.store.dispatch(new AddSuccessResponseNotification({
                success: false,
                message: error?.message
            }));
        }
        else {
            this.apmErrorHandler.handleError(error);
        }
    }


}
