import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, } from '@ngxs/store';
import { take, tap, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { DataLoading, getDataLoadingDefaultValues } from 'src/app/shared/interfaces/data-loading.interface';
import { Utils } from 'src/app/Utils';
import { DataLoadingHelper } from 'src/app/Utils/helpers';
import { GeforceEligibilityActions } from '../actions/geforce-eligibility-actions';
import { GeforceWhitelistService } from '../services/geforce-whitelist.service';
import { GeforceEligibility } from '../types/geforce-eligibility.types';
import { CustomerInfoSummaryDestroyed } from 'src/app/customer-info-summary-page/store/actions/customer.actions';
import { SalesPortalDestroyed } from 'src/app/sales/store/action/sales-portal.actions';
import { NO_DATA_MESSAGE } from 'src/app/constants';


export type GeforceEligibilityStateModel = DataLoading<GeforceEligibility>;

@State<GeforceEligibilityStateModel>({
    name: 'sf_GeforceEligibility_state',
    defaults: {
        ...getDataLoadingDefaultValues()
    }
})
@Injectable()
export class GeforceEligibilityState {

    @Selector()
    static isLoading(state: GeforceEligibilityStateModel) { return state.loading }

    @Selector()
    static isLoaded(state: GeforceEligibilityStateModel) { return state.loaded }

    @Selector()
    static getData(state: GeforceEligibilityStateModel) { return state.data }

    @Selector()
    static getError(state: GeforceEligibilityStateModel) { return state.error }

    @Selector([GeforceEligibilityState.getData])
    static isEligible(data: GeforceEligibility): boolean { return data?.is_whitelisted ?? false; }

    private readonly _cancelRequest$ = new Subject<null>();

    constructor(private whitelistService: GeforceWhitelistService) {
    }

    @Action(GeforceEligibilityActions.FetchEligibility)
    FetchEligibility(ctx: StateContext<GeforceEligibilityStateModel>, action: GeforceEligibilityActions.FetchEligibility) {
        const { payload } = action;

        ctx.patchState({ loading: true });

        return this.whitelistService.getGeforceEligibility(payload)
            .pipe(
                tap({
                    next: res => {
                        if (!res?.result) {
                            return ctx.dispatch(new GeforceEligibilityActions.FetchEligibilityFail(NO_DATA_MESSAGE));
                        }
                        return ctx.dispatch(new GeforceEligibilityActions.FetchEligibilitySuccess(res.result));
                    },
                    error: (e: unknown) => ctx.dispatch(new GeforceEligibilityActions.FetchEligibilityFail(e))
                }),
                takeUntil(this._cancelRequest$.pipe(take(1))),
            );
    }


    @Action(GeforceEligibilityActions.FetchEligibilitySuccess)
    FetchEligibilitySuccess(ctx: StateContext<GeforceEligibilityStateModel>, action: GeforceEligibilityActions.FetchEligibilitySuccess) {
        const { payload } = action;
        DataLoadingHelper.setData(ctx, payload);
    }


    @Action(GeforceEligibilityActions.FetchEligibilityFail)
    FetchEligibilityFail(ctx: StateContext<GeforceEligibilityStateModel>, action: GeforceEligibilityActions.FetchEligibilityFail) {
        const error = Utils.Helpers.findError(action.error, '');
        DataLoadingHelper.setError(ctx, error);
    }

    @Action([CustomerInfoSummaryDestroyed, SalesPortalDestroyed, GeforceEligibilityActions.Clear])
    Clear(ctx: StateContext<GeforceEligibilityStateModel>) {
        this._cancelRequest$.next(null);
        ctx.setState(getDataLoadingDefaultValues());
    }

}       