import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BasicCustomerServiceResponse, ServicePolicy } from '../../../../customer-info-summary-page/store/interfaces/basic-customer-service.interface';
import { UpdateServicePolicyActionPayload, UpdateServicePolicyByUserIdPayload, UpdateServicePolicyResponse } from '../../sim-card-page/sub-components/actions-components/connection-details/store/types/update-policy-payload.interfaces';
import { DEFAULT_HTTP_OPTIONS } from 'src/app/constants';
import { ServicePolicyHistoryResponse } from '../interfaces/policy-history-response.interface';
import { NewProxyCustomerServiceResponse } from 'src/app/customer-info-summary-page/store/interfaces/new-proxy-customer-service.interface';
import { MobileProductVasIdResponse } from 'src/app/customer-info-summary-page/store/interfaces/mobile-product-vas-id-response.interface';

@Injectable({
    providedIn: 'root'
})
export class CustomerServiceService {

    constructor(private http: HttpClient) { }

    fetchBasicCustomerServices(userId: string) {
        const url = `${environment.main_proxy}/fullcircle/api/v2/partner/user/${userId}/services-2`;

        const headers = {
            "Content-Type": "application/json",
        }

        return this.http.get<BasicCustomerServiceResponse>(url, { headers });
    }

    updateServicePolicy(payload: UpdateServicePolicyActionPayload) {
        const { serviceId, details } = payload;
        const url = `${environment.main_proxy}/fullcircle/api/v9/partner/service/${serviceId}/policy`;

        return this.http.patch<UpdateServicePolicyResponse>(url, details);
    }

    updateServicePolicyV2(payload: { policy: ServicePolicy, service_id: string, user_id: string }) { // TODO store to be created - will be used in the future
      const { policy, service_id, user_id } = payload;
      const url = `${environment.updatePolicy}/policy/${policy}/serviceId/${service_id}/userId/${user_id}`;

      return this.http.patch<unknown>(url, DEFAULT_HTTP_OPTIONS); //TODO still need response type
    }

    fetchPolicyHistory(userId: string){
      const url = `${environment.main_proxy}/billing-service/web/service-accounts//find/service/policy-history/user/${userId}`;
      return this.http.post<ServicePolicyHistoryResponse>(url, DEFAULT_HTTP_OPTIONS)
    }

    fetchNewDetailedServices(userId: string) {
        const url = `${environment.main_proxy}/new-service-proxy/service`;
        // const url = `${environment.main_proxy}/service-proxy/service?user_id=${userId}`; // TODO keeping this for now, to test all scenarios of old SP

        const headers = {
          'Content-Type': 'application/json',
          userId
        }

        return this.http.get<NewProxyCustomerServiceResponse>(url, {headers});
    }

    updateServicePolicyByUserId(payload: UpdateServicePolicyByUserIdPayload) {
      const { policy, userId } = payload;
      const url = `${environment.main_proxy}/new-service-proxy/policy/${policy}/userId/${userId}`;
      return this.http.post<string>(url, DEFAULT_HTTP_OPTIONS);
  }

  fetchMobileProductVasId(userId: string) {
    const url = `${environment.main_proxy}/new-service-proxy/service/vas/${userId}`;

    const headers = {
      'Content-Type': 'application/json',
      userId
    }

    return this.http.get<MobileProductVasIdResponse>(url, {headers});
  }
}
