import { SuccessResult } from "src/app/shared/models/success-result.model";
import { AutoRetryOptions } from "./auto-retry.interface";

type Awaited<T> = T extends Promise<infer U> ? U : T;

const awaitTimeout = (delay: number, rejectReason?: string) =>
    new Promise<void>((resolve, reject) => {
        const id = setTimeout(
            () => {
                if (rejectReason === undefined) {
                    resolve();
                }
                else {
                    reject(rejectReason);
                }
                clearTimeout(id);
            },
            delay
        )
    });


const retryFn = async <Fn extends () => Promise<any>, FnReturn = Awaited<ReturnType<Fn>>>
    (fn: Fn, options: Omit<AutoRetryOptions, "cleanupFn" | "error">, lastError: any = null, currentAttempt = 0): Promise<SuccessResult<FnReturn>> => {
    const { maxAttempts, delayAmount, resetFn } = options;

    if (currentAttempt >= maxAttempts) {
        return new SuccessResult<FnReturn>().setError(lastError);
    }

    try {
        const result = await fn();
        return new SuccessResult(result);
    }
    catch (error) {
        await wrapWarn(resetFn);
        await awaitTimeout(delayAmount);
        return await retryFn(fn, options, error, currentAttempt + 1);
    }
}

const wrapWarn = async (fn: () => Promise<any> | any) => {
    try {
        await fn();
    }
    catch (error) {
        console.warn("Reset function itself also threw an error.");
        console.warn(error);
    }
}

export const autoRetryPromise = async <Fn extends () => Promise<any>>
    (fn: Fn, options: Omit<AutoRetryOptions, "error">): Promise<SuccessResult<Awaited<ReturnType<Fn>> | null>> => {

    const { cleanupFn } = options;

    const result = await retryFn(fn, options);
    if (result.error && cleanupFn) {
        await cleanupFn(result.error);
    }

    return result;
}