<div class="search-section">

    <label class="font-normal text-blue pointer" [matMenuTriggerFor]="searchMenu">
        search {{selectedOption?.label | prettifyKey}}
        <mat-icon class="text-blue">arrow_drop_down</mat-icon>
    </label>
    <mat-menu #searchMenu="matMenu">
        <button *ngFor="let option of searchOptions" mat-menu-item class="font-normal text-blue"
            (click)="onSetOption(option)">
            {{option.label | prettifyKey}}
        </button>
    </mat-menu>

    <sf-search-bar [placeholder]="'search by ' + selectedOption?.label" [showCard]="false" (onSearch)="onSearch($event)"
        [delay]="delay" [alwaysEmit]="true"></sf-search-bar>
</div>