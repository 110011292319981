import { Component, Input } from '@angular/core';

@Component({
  selector: 'sf-margin-box',
  templateUrl: './margin-box.component.html',
  styleUrls: ['./margin-box.component.scss']
})
export class MarginBoxComponent {

  @Input() padding = "2rem";

}
