import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { LogAgentStatusPayload } from "../store/interfaces/log-agent-status-payload.interface";

@Injectable({
    providedIn: 'root'
})
export class VoiceReportingService {


    constructor(private http: HttpClient) { }

    logStatus(payload: LogAgentStatusPayload) {
        const url = `${environment.agentReporting}/api/v1/agents/status`;

        const headers = {
            "Content-Type": "application/json"
        }

        return this.http.put(url, payload, { headers });
    }

}