import { Utils } from "src/app/Utils";

export interface QueueAction<T> {
  action: T;
  routerSegment: string;
}


export class UpdateURL {
  static readonly type = Utils.Helpers.Type('[Nav: Update] Update url');
  constructor(public readonly payload: string) { }
}

export class SetIsFromAgentView {
  static readonly type = Utils.Helpers.Type('[Nav: Set] Set is from agent view');
  constructor(public bool: boolean) { }
}

export class AddQueueAction<T> {
  static readonly type = Utils.Helpers.Type('[Nav: Queue] Queue action for routeChange');
  constructor(public payload: QueueAction<T>) { }
}

