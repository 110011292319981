<div class="form-group">
  <div class="form-row" [class.has-errors]="checkForErrors()">
    <div class="field-container">
      <label *ngIf="label" [for]="label">{{label}}:</label>
      <p *ngIf="secondaryLabel" class="secondary-label">{{secondaryLabel}}</p>
      <div class="input-container" [class.onlyBottomLine]="onlyBottomLine">
        <div class="pre-icon">
          <fa-icon [icon]="icon" size="lg" class="text-blue fa-icon"></fa-icon>
        </div>
        <input [name]="label" [formControl]="search_control" [placeholder]="placeholder" [autocomplete]="autocomplete">
        <div class="post-icon">
          <fa-icon *ngIf="searching" [icon]="spinner" size="sm" class="text-dark-grey fa-icon"></fa-icon>
        </div>
      </div>
    </div>
    <div class="results-lockup shadow" *ngIf="showResultsList && searchResults && searchResults.length > 0">
      <ul class="scrollable">
        <li *ngFor="let result of searchResults" (click)="onSelectAddress(result)" class="row">
          <p>{{result.description}}</p>
        </li>
      </ul>

    </div>
    <div class="form-errors">
      <ul>
        <li *ngFor="let error of checkForErrors()  | errorKeys">
          <p>{{messages[error]}}</p>
        </li>
      </ul>
    </div>
  </div>
</div>

<div #blankDiv class="hidden"></div>
