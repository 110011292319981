<sf-popup-modal *ngIf="isOpen$ | async as isOpen" [isOpen]="isOpen" [hasCloseButton]="false" [autoClose]="false" [limitHeight]="true">

  <ng-container header><span class="red">WARNING!</span></ng-container>
  <ng-container mainContent>
    <div class="content">
      <h2>You currently have more than one tab open!</h2>
      <h3>Please close this tab.</h3>
    </div>
  </ng-container>

</sf-popup-modal>
