import { Component } from '@angular/core';

@Component({
  selector: 'sf-agent-view-wrapper',
  templateUrl: './agent-view-wrapper.component.html',
  styleUrls: ['./agent-view-wrapper.component.scss']
})
export class AgentViewWrapperComponent {


}
