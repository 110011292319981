import { combineLatest, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { SuccessLoaderStatus } from "src/app/shared/elements/loaders/success-loader/success-loader-status.type";


export interface SuccessLoaderStatusOptions {
  isLoading$: Observable<boolean>;
  isLoaded$: Observable<boolean>;
  successful$: Observable<boolean>;
}

export function determineSuccessLoaderStatus(options: SuccessLoaderStatusOptions): Observable<SuccessLoaderStatus> {

  return combineLatest([
    options.isLoading$,
    options.isLoaded$,
    options.successful$,
  ]).pipe(
    map(
      ([isLoading, isLoaded, successful]) => {
        if (!isLoaded && !isLoading) {
          return "not-started";
        }
        if (isLoading) {
          return "loading";
        }
        if (successful) {
          return "successful";
        }
        else {
          return "failed";
        }
      })
  );
}
