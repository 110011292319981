import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { CustomerInfoSummaryDestroyed } from 'src/app/customer-info-summary-page/store/actions/customer.actions';
import { BasicStateUpdater } from 'src/app/shared/state-updaters/basic.state-updater';
import { BasicStateModel } from 'src/app/shared/state-updaters/types/basic-state-updater.types';
import { Subject } from 'rxjs';
import { NewProxyServiceActions } from '../actions/new-proxy-service';
import { CustomerServiceService } from 'src/app/customer-data-components/sim-details/store/services/customer-service.service';
import { PossibleNewProxyCustomerServiceResponse } from '../interfaces/new-proxy-customer-service.interface';

export type NewServiceProxyStateModel = BasicStateModel<PossibleNewProxyCustomerServiceResponse>;

@State<NewServiceProxyStateModel>({
  name: 'NewServiceProxyState',
  defaults: BasicStateUpdater.getDefaultState()
})
@Injectable()
export class NewServiceProxyState {

  @Selector()
  static isLoading(state: NewServiceProxyStateModel) { return state.loading }

  @Selector()
  static isLoaded(state: NewServiceProxyStateModel) { return state.loaded }

  @Selector()
  static getData(state: NewServiceProxyStateModel) { return state.data?.result }

  @Selector()
  static getError(state: NewServiceProxyStateModel) { return state.error }

  private readonly _cancelRequest$ = new Subject();

  private readonly _stateUpdater = new BasicStateUpdater<PossibleNewProxyCustomerServiceResponse, string>({
    cancelRequest$: this._cancelRequest$,
    errorMessage: "Failed to fetch services.",
    notFoundMessage: "No services.",
    baseFetchFn: (request: string) => this.customerServiceService.fetchNewDetailedServices(request),
    ...NewProxyServiceActions,
  });

  constructor(private customerServiceService: CustomerServiceService) { }

  @Action(NewProxyServiceActions.Fetch)
  Fetch(ctx: StateContext<NewServiceProxyStateModel>, action: NewProxyServiceActions.Fetch) {
    return this._stateUpdater.Fetch(ctx, action);
  }

  @Action(NewProxyServiceActions.FetchSuccess)
  FetchSuccess(ctx: StateContext<NewServiceProxyStateModel>, action: NewProxyServiceActions.FetchSuccess) {
    this._stateUpdater.FetchSuccess(ctx, action);
  }

  @Action(NewProxyServiceActions.FetchFail)
  FetchFail(ctx: StateContext<NewServiceProxyStateModel>, action: NewProxyServiceActions.FetchFail) {
    this._stateUpdater.FetchFail(ctx, action);
  }

  @Action([CustomerInfoSummaryDestroyed, NewProxyServiceActions.Clear])
  Clear(ctx: StateContext<NewServiceProxyStateModel>) {
    this._stateUpdater.Clear(ctx);
  }

}
