<div
    class="notification shadow pointer"
    [class.hovering-over-cancel]="hoveringOverCancel"
    (click)="executeAction()"
    (mouseenter)="markAsViewed()"
>

    <div class="content">

        <div class="mark-and-icon">

            <div
                class="mark"
                [class.shown]="!notification.viewed"
            ></div>

            <div class="icon">
                <mat-icon> {{notification?.icon ?? "textsms"}} </mat-icon>
            </div>
        </div>

        <div class="mid-section">
            <div class="title">{{notification.message}}</div>

            <div
                *ngIf="notification.description"
                class="description"
            >{{notification.description}}
            </div>

            <div class="time">
                {{notification.insertedAt | date: 'HH:mm EEEE'}}
            </div>
        </div>

        <mat-icon
            class="default-cancel-icon"
            (mouseenter)="setIsHoveringOverCancel(true)"
            (mouseleave)="setIsHoveringOverCancel(false)"
            (click)="removeNotification()"
        >cancel
        </mat-icon>

    </div>

</div>