import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { AgentNotificationActions } from '../actions/agent-notification-actions';
import { AgentNotification } from '../types/agent-notification.type';
import { produce } from 'immer';
import { AddInfoNotification } from 'src/app/core/store/actions/notifications.actions';
import { cryptoRandomUUID } from 'src/app/shared/functions/crypto-random-uuid.function';
import { ModalWindowService } from 'src/app/shared/modal-window/modal-window.service';


const getDefaults = (): AgentNotificationStateModel => {
    return {
        notifications: [],
        open: false
    }
}

export interface AgentNotificationStateModel {
    notifications: AgentNotification[];
    open: boolean;
}

@State<AgentNotificationStateModel>({
    name: 'sf_AgentNotification_state',
    defaults: getDefaults()
})
@Injectable()
export class AgentNotificationState {

    @Selector([AgentNotificationState])
    static getNotifications(state: AgentNotificationStateModel) { return state.notifications; }

    //TODO: apply a filter
    @Selector([AgentNotificationState.getNotifications])
    static getFilteredNotifications(notifications: AgentNotification[]) { return notifications; }

    @Selector([AgentNotificationState.getNotifications])
    static nonViewedCount(notifications: AgentNotification[]) {
        return notifications.filter(item => !item?.viewed).length;
    }

    @Selector([AgentNotificationState])
    static isOpen(state: AgentNotificationStateModel) { return state.open; }

    constructor(private modalService: ModalWindowService) { }


    @Action(AgentNotificationActions.AddNotification)
    AddNotification(ctx: StateContext<AgentNotificationStateModel>, action: AgentNotificationActions.AddNotification) {
        const { payload } = action;

        const notification: AgentNotification = {
            id: cryptoRandomUUID(),
            viewed: false,
            insertedAt: Date.now(),
            ...payload
        }

        ctx.setState(produce(draft => {
            //Add as 1st item in array
            draft.notifications.unshift(notification)
        }));

        const { alertType, message } = payload ?? {};

        if (alertType === "toast") {
            ctx.dispatch(new AddInfoNotification(message, "Attention"));
        }
        else if (alertType === "popup") {
            this.modalService.showInfo(message);
        }
    }

    @Action(AgentNotificationActions.RemoveNotification)
    RemoveNotification(ctx: StateContext<AgentNotificationStateModel>, action: AgentNotificationActions.RemoveNotification) {

        ctx.setState(produce(draft => {
            draft.notifications = draft.notifications.filter(notification => notification.id !== action.id);
        }));
    }

    @Action(AgentNotificationActions.MarkAsViewed)
    MarkAsViewed(ctx: StateContext<AgentNotificationStateModel>, action: AgentNotificationActions.MarkAsViewed) {

        ctx.setState(produce(draft => {
            const notification = draft.notifications.find(notification => notification.id === action.id);
            notification.viewed = true;
        }));
    }

    @Action(AgentNotificationActions.SetOpen)
    SetOpen(ctx: StateContext<AgentNotificationStateModel>, action: AgentNotificationActions.SetOpen) {

        ctx.patchState({
            open: action.open
        });
    }

    @Action(AgentNotificationActions.CloseAll)
    CloseAll(ctx: StateContext<AgentNotificationStateModel>) {

        ctx.patchState({
            notifications: []
        });
    }


}   