import { Component } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { ModalState } from '../core/store/state/modal.state';

@Component({
    selector: 'sf-main-modals-container',
    templateUrl: './main-modals-container.component.html',
    styleUrls: ['./main-modals-container.component.scss'],
})
export class MainModalsContainerComponent {
    @Select(ModalState.isModalOpen('faq_scenarios_modal')) isFAQ_ScenariosModalOpen$: Observable<boolean>;
    @Select(ModalState.isModalOpen('timeout_modal')) is_TimeOutModalOpen$: Observable<boolean>;
    @Select(ModalState.isModalOpen('token_expired_modal')) isTokenModalOpen$: Observable<boolean>;
}
