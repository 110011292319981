import { Injectable } from '@angular/core';
import { Action, State } from '@ngxs/store';
import { MainTimeoutService } from 'src/app/shared/services/main-timeout.service';
import { ResetTimer, StartTimer } from '../actions/time-out.actions';

type AgentTimeOutStateModel = {

}
@State<AgentTimeOutStateModel>({
  name: 'agent_timeout_state',
  defaults: {},
})
@Injectable()
export class AgentTimeOutState {

  constructor(private timeOutService: MainTimeoutService) { }

  @Action(StartTimer)
  startTimer() {
    this.timeOutService.startTimer();
  }

  @Action(ResetTimer)
  resetTimer() {
    this.timeOutService.resetTimer();
  }


}
