<div
    class="form-group"
    [class.mark-valid]="markValid"
>
    <div
        [class]="'form-row ' + display + ' ' + type"
        [class.has-errors]="checkForErrors()"
    >
        <div class="field-container">
            <label
                matTooltipPosition="right"
                [for]="formatLabel(label)"
            >{{ label }}</label>
            <div
                class="input-container"
                (click)="onBoundaryClick()"
            >
                <input
                    #mainInput
                    [maxlength]="maxlength"
                    [name]="formatLabel(label)"
                    [formControl]="control"
                    [placeholder]="placeholder"
                    [type]="type"
                    [autocomplete]="autocomplete"
                    [accept]="accept"
                    (change)="onChange($event)"
                />

            </div>
        </div>
        <div class="form-errors">
            <ul>
                <ng-container *ngIf="checkForErrors() as errors">
                    <li *ngFor="let error of errors | errorKeys">
                        <p>
                            {{
                            error === "custom_message"
                            ? errors[error]
                            : messages[error]
                            }}
                        </p>
                    </li>
                </ng-container>
            </ul>
        </div>
    </div>
</div>