import { Utils } from "src/app/Utils";
import { CustomerTicket, ShortTicket } from 'src/app/shared/customer-ticket/interfaces/customer-ticket.interface';
import { InteractionType } from "../../interfaces/interaction-type.type";


export class SetIsPrivateReply {
    static readonly type = Utils.Helpers.Type('[Interactions: Chat] Set is private reply');
    constructor(public isPrivate: boolean) { }
}

export class ToggleHasMoreOpenTicketsModal {
    static readonly type = Utils.Helpers.Type('[Agent: Chat] Toggle more open tickets modal');
    constructor(public readonly payload: boolean) { }
}

export class ContinueFromPostTicketModal {
    static readonly type = Utils.Helpers.Type('[Agent: Chat] Close and continue from post tickets modal');
    constructor(public isPrivateReply: boolean) { }
}

export class InteractionTicketInit {
    static readonly type = Utils.Helpers.Type('[Interactions: Set] Interaction ticket was created or initialized.');
    constructor(public payload: ShortTicket | CustomerTicket) { }
}

export class ClearInitializedTicket {
    static readonly type = Utils.Helpers.Type('[Interactions: Clear] Clear initialized interaction ticket.');
}

export class CreatedAutoTicket {
    static readonly type = Utils.Helpers.Type('[Interactions: Set] Set created auto ticket.');
    constructor(public bool: boolean) { }
}

export class SetIsActive {
    static readonly type = Utils.Helpers.Type('[Interactions: Set] Set is interaction component active.');
    constructor(public active: boolean) { }
}


export class SetInteractionType {
    static readonly type = Utils.Helpers.Type('[Interactions: Type] Set Interactions Type.');
    constructor(public type: InteractionType) { }
}


export class InteractionMenuDestroyed {
    static readonly type = Utils.Helpers.Type('[Interactions] Interaction menu is destroyed.');
}