<div class="multi-select">
  <div class="select-bar top-level-select" [class.is-open]="multiSelect.value.isOpen"
    *ngFor=" let multiSelect of multiSelectMap | keyvalue: originalOrder">

    <div class="top-select-header-section" [class.is-open]="multiSelect.value.isOpen"
      (click)="multiSelect.value.toggleIsOpen()">
      <p>{{multiSelect.key}}</p>
    </div>

    <div class="select-group scrollable" *ngIf="multiSelect.value.isOpen">

      <sf-select-span *ngFor="let selectOption of multiSelect.value.selectOptions, let i = index"
        [multiSelectOption]="selectOption" (successToggled)="onSuccessToggled(multiSelect.key)"
        (click)="onOptionSelect(multiSelect.key, i, multiSelect.value, selectOption)">
      </sf-select-span>

      <div *ngIf="multiSelect.value?.showOtherOption" class="input-container">
        <input (input)="onOtherSelected($event)" placeholder="other reason" type="text" [autocomplete]="true"
          [(ngModel)]="multiSelect.value.otherOption">
      </div>

    </div>

  </div>
</div>