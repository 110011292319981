<div class="interaction-buttons">
  <div
    *ngIf="showCart && (viewModel$ | async) as viewModel"
    [matTooltip]="viewModel.reason"
    matTooltipClass="multiToolTip"
  >
    <custom-icon-button
      [size]="buttonSize"
      (click)="onShoppingCart()"
      [disabled]="viewModel.disabled"
    >shopping_cart</custom-icon-button>
  </div>

  <custom-icon-button
    [size]="buttonSize"
    (click)="onCallCustomer()"
  >phone</custom-icon-button>

</div>
