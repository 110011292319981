
import { SFValidators } from "src/app/shared/functions/sf-validators";
import { GenericFunc } from "../assets/generic-func.type";



export class Maybe<T> {
    constructor(public readonly value: T | null = null) { }

    bind<F extends GenericFunc<T>, R extends ReturnType<F>>(fn: F) {
        return SFValidators.isNotDefined(this.value)
            ? new Maybe<R>()
            : new Maybe<R>(fn(this.value));
    }
}
