import { PaymentStatusItem } from "./payment-status-item.interface";


export const PAYMENT_GENERIC_RESPONSE = "The transaction failed as it was rejected by the issuing bank, please contact them for a resolution";


export const PAYMENT_STATUS_MAPPING: { [status: string]: PaymentStatusItem } = {

    //ERROR CODES
    "800.100.155": {
        status: "Transaction declined - amount exceeds credit",
        successful: false,
        suggestion: {
            header: "Please be advised that these transactions failed due to insufficient funds/exceeding the credit available on the customer's card/account",
            bulletPoints: [
                "You would have likely exceeded your available funds or the limits set on their banking app."
            ],
        },
        action: "Create new order once limit adjusted"
    },
    "100.396.103": {
        status: "Previously pending transaction timed out",
        successful: false,
        suggestion: {
            header: "Your transaction was declined for the following reason:",
            bulletPoints: [
                `Your bank has rejected the transaction/s for account-related reasons.`,
                "If you are using a Virtual Card, please ensure the card is enabled/active on your banking app.",
            ],
            summaryMessage: `We ask that you please contact your bank, as further detail on the reason for the failed transaction can only be communicated/explained to a customer by the customer's bank. This information is privy only to the customer and his/her bank.`
        },
        action: "Create new order once issue sorted at bank"
    },
    "800.100.162": {
        status: "Transaction declined (Limit exceeded)",
        successful: false,
        suggestion: {
            header: "Please be advised that this transaction failed with the Result Description as the Limit was exceeded.",
            bulletPoints: [
                `This error means that the transaction will bring the customer's bank balance below the limit set by their bank or they have exceeded their transaction limit for that day`,
            ],
            summaryMessage: `The Customer would need to check their daily limits/available funds in their account to proceed with the transaction`
        },
        action: "Create new order once limit adjusted"
    },
    "800.100.152": {
        status: "Transaction declined by authorization system",
        successful: false,
        suggestion: {
            header: "Your transaction was declined for the following reason:",
            bulletPoints: [
                `Your bank has rejected the transaction/s for account-related reasons.`,
                `If you are using a Virtual Card, please ensure the card is enabled/active on your banking app.`,
            ],
            summaryMessage: `We ask that you please contact your bank, as further detail on the reason for the failed transaction can only be communicated/explained to a customer by the customer's bank. This information is privy only to the customer and his/her bank.`
        },
        action: "Create new order once issue sorted at bank"
    },
    "100.380.501": {
        status: "Risk management transaction timeout",
        successful: false,
        suggestion: {
            header: "Your transaction was declined for one of the following reason:",
            bulletPoints: [
                `Possibly not entering in the OTP on time`,
                `The bank card is not enabled for online transactions or`,
                `There could be an issue with the bank 3D server/transaction approval server`,
            ],
            summaryMessage: `Please try again if you know your bank card is activated for online transactions or contact your bank.`
        },
        action: "Create new order once issue sorted"
    },
    "100.150.203": {
        status: "Registration is not valid, probably initially rejected",
        successful: false,
        suggestion: {
            header: "Please be advised for this transaction that the initial phase of this transaction failed which was registering. It would either mean one of the following points occurred:",
            bulletPoints: [
                `The Registering of the card contained no Account holder data`,
                `The Issuing bank was offline for the initial period of the transaction`,
                `Did not pass 3DS and therefore the token is not valid, should try the transaction again`
            ],
            summaryMessage: "If they do not succeed they would need to reach out to their issuing bank"
        },
        action: "Create new order once issue sorted"
    },
    "800.100.170": {
        status: "Transaction declined (Transaction not permitted)",
        successful: false,
        suggestion: {
            header: "The transaction was declined by your issuing bank.",
            bulletPoints: [
                `This means that your issuing bank could not process this transaction due to some rules applied to their system.`,
                `This information is privy only to the customer and his/her bank`
            ],
            summaryMessage: `Please contact your bank, as further detail on the reason for the failed transaction can only be communicated/explained to a customer by the customer's bank.`
        },
        action: "Create new order once issue sorted at bank"
    },
    "100.380.401": {
        status: "User Authentication Failed",
        successful: false,
        suggestion: {
            header: "Your transaction failed due to the OTP details either:",
            bulletPoints: [
                `Not entered correctly`,
                `There was a field or system error regarding the OTP`
            ],
            summaryMessage: `Therefore your bank was unable to successfully authenticate you and process the transaction. Please try again or contact your bank to find out if there is an issue with OTP system.`
        },
        action: "Create new order once issue sorted"
    },
    "100.390.107": {
        status: "Transaction rejected",
        successful: false,
        suggestion: {
            header: "The transaction failed due to one of the following reasons:",
            bulletPoints: [
                `Either the cardholder or card issuing bank is not 3D enrolled`,
                `3D card authentication was unsuccessful, for example, the 3D Cardholder is not an enrolled`,
                `Card or issuing bank is not 3D Secure ready`
            ],
            summaryMessage: `The Customer would need to check with the issuing Bank if they are enrolled on 3D Secure alternatively
            the Customer would need to check if their Card is enrolled on 3D Secure or not.`
        },
        action: "Create new order once issue sorted at bank"
    },
    "800.100.168": {
        status: "Transaction declined (restricted card)",
        successful: false,
        suggestion: {
            header: "Upon further investigation, we could see that this transaction failed. This means the following:",
            bulletPoints: [
                `The customer's card issuer has declined the transaction as the credit card has some restrictions.`
            ],
            summaryMessage: `You would need to reach out to your issuing bank in order for them to provide you with a reason why this card has been flagged as a restricted card.`
        },
        action: "Create new order once issue sorted at bank"
    },
    "100.396.101": {
        status: "Cancelled by User",
        successful: false,
        suggestion: {
            header: `The transaction failed with the result description "Canceled by the user".`,
            bulletPoints: [
                `The authentication phase is taking too long`,
                `The payment method is taking too long to load`,
                `Loadshedding`,
                `Accidentally closing the browser tab`
            ],
            summaryMessage: `The suggestion would be to not close the browser when transacting or to wait a while if your internet is too slow.`
        },
        action: "Create new order once issue sorted"
    },
    "800.100.151": {
        status: "Transaction declined (invalid card)",
        successful: false,
        suggestion: {
            header: "This transaction failed due to a response from the Issuing Bank with a description of Invalid Card",
            bulletPoints: [
                `The customer's card might be expired`,
                `The card is not registered to do online transactions`
            ],
            summaryMessage: `Please advise the customer that they should check with their bank on why they failed the transaction as
            this is an issue between the customer and their issuing bank`
        },
        action: "Create new order once issue sorted at bank"
    },
    "800.100.158": {
        status: "Transaction declined (suspecting manipulation)",
        successful: false,
        suggestion: {
            header: "This could mean one of the following:",
            bulletPoints: [
                `You are attempting and failing multiple transactions by inputting bad data such as an incorrect CVV, Expiry Date, or re-trying transactions in quick succession.`,
                `Your issuing bank is suspecting fraudulent behaviour or transacting out of the normal transactional categories, as a protective measure, may fail the transaction.`
            ],
            summaryMessage: `The customer would need to reach out to their issuing bank for them to provide the customer with a reason why this card has been flagged as fraudulent`
        },
        action: "Create new order once issue sorted at bank"
    },
    "100.390.111": {
        status: "Communication Error to Scheme Directory Server",
        successful: false,
        suggestion: {
            header: "This means that either your issuing bank, acquiring bank or a 3rd party involved in the online transaction process is experiencing downtime",
            bulletPoints: [],
            summaryMessage: `The suggestion would be wait for a while until one of the parties mentioned above make their system available again in order for you to transact further.`
        },
        action: "Create new order once issue sorted at bank"
    },
    "100.100.303": {
        status: "Card expired",
        successful: false,
        suggestion: {
            header: `Please note that this transaction failed with the Result Description, "Card expired".`,
            bulletPoints: [],
            summaryMessage: `Please advise the Customer that they tried to transact when their Card was picked up as an Expired Card.`
        },
        action: "Create new order once card expiry is sorted"
    },
    "100.390.112": {
        status: "Technical Error in 3D system",
        successful: false,
        suggestion: {
            header: `There could be an issue with the bank 3D server/transaction approval server.`,
            bulletPoints: [],
            summaryMessage: `Please try again if you know your bank card is activated for online transactions or contact your bank.`
        },
        action: "Create new order once issue sorted at bank"
    },
    "800.100.165": {
        status: "Transaction declined (card lost)",
        successful: false,
        suggestion: {
            header: `In this case, the customer will need to reach out to their bank to find out why the transaction was declined.`,
            bulletPoints: [],
        },
        action: "Create new order once issue sorted at bank"
    },
    "800.100.171": {
        status: "Transaction declined (pick up card)",
        successful: false,
        suggestion: {
            header: `This could mean one of the following:`,
            bulletPoints: [
                "Points to the card being lost and picked up and reported to the bank.",
                `-Depending on the bank normally these cards are then held for a period and "locked" ie they fail until the customer signs the card out of the bank's possession`,
                `Or the bank has cancelled the card and you might not be aware of this as they might have flagged the card as lost or stolen`
            ],
            summaryMessage: `You would need to reach out to your issuing bank for them to provide you with a reason why this card has been flagged as a pickup card`
        },
        action: "Create new order once issue sorted at bank"
    },
    "800.100.157": {
        status: "The wrong expiry date entered",
        successful: false,
        suggestion: {
            header: `One of the following numbers has been entered incorrectly:`,
            bulletPoints: [
                `Card Number`,
                `Expiry date`,
                `CVV`
            ],
            summaryMessage: `Please double-check the numbers above when doing online transactions`
        },
        action: "Create new order once issue sorted at bank"
    },
    "800.100.100": {
        status: "Transaction Rejected by Issuing bank",
        successful: false,
        suggestion: {
            header: `This is an error message from the Issuing/Customer Bank.
            Please advise the customer to reach out to their bank for more information regarding this failure.`,
            bulletPoints: [],
        },
        action: "Create new order once issue sorted at bank"
    },
    "800.100.153": {
        status: "Transaction declined (invalid CVV)",
        successful: false,
        suggestion: {
            header: `Please note that your transaction has been declined due to either:`,
            bulletPoints: [
                `Invalid CVV number being entered`,
                `No CVV number entered`
            ],
            summaryMessage: `If you entered your CVV correctly and still had your transaction declined please contact your bank and inform them that you are unable to make E-Commerce transactions.`
        },
        action: "Create new order once data entered correctly or issue sorted at bank"
    },
    "800.100.203": {
        status: "The card has insufficient funds",
        successful: false,
        suggestion: {
            header: `Please advise the Card Holder they he/she should have sufficient funds available to do Online transactions.`,
            bulletPoints: [],
            summaryMessage: `If they need any other information or assistance they would need to contact their bank.`
        },
        action: "Create new order when funds available or issue sorted at bank"
    },
    "800.140.112": {
        status: "The maximum number of registrations of email per credit card number exceeded",
        successful: false,
        suggestion: {
            header: `Please be advised that we picked up that the Cardholder perhaps tried to register or transact with a different email on the same card number.`,
            bulletPoints: [],
        },
        action: "Create new order once issue sorted"
    },

    //SUCCESS CODES
    "000.200.100": {
        status: "User successfully created checkout.",
        successful: true,
    },
    "000.200.000": {
        status: "User started the payment process.",
        successful: true,
    },
    "000.000.000": {
        status: "Transaction was successful.",
        successful: true,
    },
    "000.100.110": {
        status: "Request successfully processed in 'Merchant in Integrator Test Mode'",
        successful: true,
    },
};





