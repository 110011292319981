


export const CUSTOMER_SERVICE_STATUS = {
    "Active": 0,
    "Service Pre Provisioned": 100,
    "Service Deactivated": 200,
    "Service Failed Provisioning": 300,
    "Service Suspended": 400,
    "Service Canceled": 900
} as const;


type CustomerServiceStatusMap = typeof CUSTOMER_SERVICE_STATUS;
type Keys = keyof CustomerServiceStatusMap;

export type CustomerServiceStatus = CustomerServiceStatusMap[Keys];

