import { Utils } from "src/app/Utils";


export const BILLING_SUPPORT_ERROR_CODES = {
  "1": "Customer id not found.",
  "2": "Invoice not found",
  "3": "Minimum amount is incorrect",
  "4": "Transaction already processed",
  "5": "Pay at customer id not found",
  "6": "Sending notification exception",
  "7": "Promise to pay already exists",
  "8": "Promise to pay not found",
  "9": "Promise to pay date exception",
  "10": "Create snow flake ticket exception",
  "11": "Promise to pay already cancelled",
  "12": "Promise to pay amount is zero",
  "13": "Promo customer already exists",
  "14": "Promo customer's balance is null",
  "15": "Promo customer's balance is zero",
  "16": "Customer already exists"
}


export function getBillSupportErrorMessage(errorPayload: unknown): string | null {

  const errorCode = Utils.Helpers.findError(errorPayload);

  return BILLING_SUPPORT_ERROR_CODES?.[errorCode] ?? errorCode;

}

