import { Utils } from "src/app/Utils";

export class AgentDetails {
  constructor(
    public name: string,
    public email: string,
    public roles: string[] = []
  ) { }


  getTeamRole() {

    return Utils.SfHelpers.getTeamRole(this.roles);
  }

  isInTeam(team: string): boolean {
    const currentTeam = this.getTeamRole();
    return currentTeam.includes(team.toUpperCase());
  }

  getDefaultTeam() {
    return 'CEC.TEAM.A';
  }
}
