import { Component, Input } from '@angular/core';
import { FormattedWhatsappMessage } from '../../store/types/whatsapp-message.interface';


@Component({
  selector: 'sf-whatsapp-chat-bubble',
  templateUrl: './whatsapp-chat-bubble.component.html',
  styleUrls: ['./whatsapp-chat-bubble.component.scss']
})
export class WhatsappChatBubbleComponent {

  @Input() message: FormattedWhatsappMessage<"TEXT">;

}
