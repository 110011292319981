<div class="custom-geocoder-div" (clickOutside)="clearSearchResults()" delayClickOutsideInit="true"
  [exclude]="'#custom-geocoder-div'">

  <div class="default-input-wrapper">
    <input [(ngModel)]="addressInput" (input)="onSearch($event.target.value)" placeholder="search address">
    <div class="input-controls">
      <mat-icon *ngIf="addressInput!==address" class="text-blue pointer" (click)="undo()">undo</mat-icon>
      <mat-icon class="red pointer" (click)="clearInput()">clear</mat-icon>
    </div>
  </div>

  <div class="result-dropdown shadow">

    <div *ngFor="let result of addressResults" class="result-dropdown-item"
      (click)="moveToAddress(result.place_id, result.description)">
      <div class="item-inline">
        <mat-icon class="images text-dark-grey">place</mat-icon>
        <h5 class="popup-text text-dark-grey">{{result.description}}</h5>
      </div>
    </div>

  </div>

  <div #blankDiv>
  </div>

</div>
