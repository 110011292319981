import { CALL_RECORDING_FILE_NAME_STR, CALL_RECORDING_ID_STR } from "src/app/constants";
import { ToggleModalByName } from "src/app/core/store/actions/modal.actions";
import { SfModalName } from "src/app/customer-info-summary-page/store/types/modal-name.type";
import { TICKET_STATES } from "src/app/shared/customer-ticket/ticket-event-handler/assets/ticket.constants";
import { AgentGroupType } from "src/app/shared/interfaces/agent-group-type.type";


export function getModalEvent(state_id: number, agentGroupType: AgentGroupType) {
  const closedState = state_id === TICKET_STATES.CLOSED;


  const defaultModal: SfModalName = "default_post_ticket_modal";

  const modalMap: { [key in AgentGroupType]?: SfModalName } = {
    SALES: "post_sales_modal",
    RETENTIONS: closedState ? "retentions_main_post_close_modal" : "retentions_update_modal",
    'CEC.COLLECTIONS.A': "post_collections_modal",
    ACTIVATIONS: "post_activations_modal" ,
    RICA: "rica_post_close_modal",
    CSAT: "csat_close_modal" ,
    EARLYOPTIMIZATION: "early_optimizations_close_modal",
    UPGRADE: "post_upgrade_modal",
    NO_CONTACT:"post_sales_modal",
    "CEC.TEAM.A": "cec_team_a_to_f_post_close_modal",
    "CEC.NVIDIA.A":"nvidia_post_close_modal",
    "CEC.COLLECTIONS.B":"post_collections_modal_b",
    "FULFILLMENT.DELIVERIES_A":'fulfillment_deliveries_post_close_modal',
    "FULFILLMENT.CPE.COLLECTIONS_A":'fulfillment_cpe_collections_post_close_modal',
    "FULFILLMENT.RICA_A": 'fulfillment_rica_post_close_modal',
    "RETAIL.CUSTOMER_ENGAGEMENT.A": "retail_customer_engagement_post_close_modal",
    "CEC.CALLBACK.A": "cec_team_a_to_f_post_close_modal",
    "THE101": "rain_101_post_close_modal"
  };
  const modalName: SfModalName = modalMap?.[agentGroupType] ?? defaultModal;
  return new ToggleModalByName(modalName, true);
}


export const hasCallIdOrFileNameInText = (text: string) => {

  const { callId, fileName } = extractCallIdOrFileNameFromText(text) ?? {}

  return Boolean(callId) || Boolean(fileName)

};

export const extractCallIdOrFileNameFromText = (text: string) => {
  const callIdRegex = new RegExp(`${CALL_RECORDING_ID_STR}: (.+)`);
  const fileNameRegex = new RegExp(`${CALL_RECORDING_FILE_NAME_STR}: (.+)`);

  const idMatch = text?.match(callIdRegex);
  const fileNameMatch = text?.match(fileNameRegex);

  return {
    callId: idMatch ? idMatch?.[1] : null,
    fileName: fileNameMatch ? fileNameMatch?.[1] : null
  }


}
