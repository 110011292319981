import { Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SnowflakeSelectOption } from './interfaces/rain-select-option.interface';

@Component({
  selector: 'sf-select',
  templateUrl: './snowflake-select.component.html',
  styleUrls: ['./snowflake-select.component.scss']
})
export class SnowflakeSelectComponent implements OnChanges {
  @Input() preIcon?: string;
  @Input() placeholder: string;
  @Input() options: SnowflakeSelectOption[];
  @Input() isDisabled = false;
  @Input() hasError = false;
  @Input() reset = false;
  @Input() bgColor = '#F5F5F5';
  @Input() label: string;
  @Input() labelStyle: "default" | "thin-large-font" | "thin-font-no-gap" | "thin-close";
  @Input() menuPosition: 'top' | 'bottom' = "bottom";
  @Input() marginBottom = "0rem";
  @Output() onOptionSelect: EventEmitter<string | number> = new EventEmitter();

  public _form: FormGroup;
  public _isOpen = false;

  constructor(private fb: FormBuilder, private eRef: ElementRef) {

    const selectedOption = this.options?.find(o => o?.selected);
    

    if (selectedOption) {
      const { label, value } = selectedOption;
      this._form = this.fb.group({
        label: this.fb.control(label),
        select: this.fb.control(value, Validators.required)
      });
    }
    else {
      this._form = this.fb.group({
        label: this.fb.control(this.placeholder),
        select: this.fb.control(this.placeholder, Validators.required)
      });
    }
  }

  public resetForm() {
    this._form.reset();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.reset && changes.reset.currentValue && changes.reset.currentValue !== changes.reset.previousValue) {
      if (this._form) {
        this._form.patchValue({
          label: this.placeholder,
          select: this.placeholder
        });
      }
    }

    if (changes.options && changes.options.currentValue !== changes.options.previousValue) {
      const selectedOption = this.options.find((o) => o.selected);
      if (selectedOption && this._form) {
        this._form.patchValue({
          label: selectedOption.label,
          select: selectedOption.value
        });
      }
    }
  }

  public selectOption(o: SnowflakeSelectOption) {
    if (this.isDisabled) return;
    if (o) {
      this._form.get('label').patchValue(o.label);
      this.onOptionSelect.emit(o.value);
    }
  }

  public toggleDropdown() {
    if (this.isDisabled) return;

    return this._isOpen = !this._isOpen;
  }

  @HostListener('document:click', ['$event'])
  private onOutsideClick(e: Event) {
    if (!this.eRef.nativeElement.contains(e.target)) {
      this._isOpen = false;
    }
  }
}
