

export interface SIPHeaders {
    [name: string]: {
        parsed?: unknown;
        raw: string;
    }[];
}

export type CustomVoiceHeaderInfo = {
    [key in IMobExtraHeaders]: string;
}

export const I_MOB_EXTRA_HEADERS = {
    "Q-Campaign": "Q-Campaign",
    "Q-Cld": "Q-Cld",
    "Q-Cname": "Q-Cname",
    "Q-Queue": "Q-Queue",
    "Q-Ticketid": "Q-Ticketid",
    "Q-Customer-Callid": "Q-Customer-Callid",
    "Call-ID": "Call-ID",
    "Q-Campaign-Type": "Q-Campaign-Type",
    "Q-Recording-File-Name": "Q-Recording-File-Name"
}

export type IMobExtraHeaders = keyof typeof I_MOB_EXTRA_HEADERS;
