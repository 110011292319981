import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AgentNotificationWebSocketService } from 'src/app/toolbar/agent-notifications/store/services/agent-notification-websocket.service';
import { ChatNoteActions } from '../../../store/actions/chat-note-actions';
import { ChatNoteState } from 'src/app/interactions/store/state/note.state';

@Component({
    selector: 'sf-ticket-eyeball',
    templateUrl: './ticket-eyeball.component.html',
    styleUrls: ['./ticket-eyeball.component.scss']
})
export class TicketEyeballComponent implements OnInit, OnDestroy {

    @Select(ChatNoteState.getNonSelfViewers) emails$: Observable<string[]>;

    @Input() hexId: string;

    private readonly ngDestroy$ = new Subject<void>();

    constructor(private store: Store,
        private agentNotificationWSService: AgentNotificationWebSocketService) { }

    ngOnInit(): void {
        if (!this.hexId) {
            return;
        }

        const topic = `ticket/${this.hexId}` as const;

        this.listenToCurrentTicketViewers(topic);

        this.agentNotificationWSService.subscribeToTopic(topic, this.ngDestroy$);
    }

    private listenToCurrentTicketViewers(topic: `ticket/${string}`) {

        this.agentNotificationWSService
            .getNotificationsForTopic<string[]>(topic)
            .pipe(
                takeUntil(this.ngDestroy$)
            )
            .subscribe({
                next: notification => this.store.dispatch(new ChatNoteActions.UpdateViewers(notification.data))
            });
    }

    ngOnDestroy(): void {
        this.ngDestroy$.next(null);
        this.ngDestroy$.complete();
    }

}
