import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { VoicePlatformData } from "./assets/voice-platform-data.interface";
import { VoiceQueueInfo } from "./assets/voice-queue-info.interface";
import { LoginAction } from "./login-action.type";
import { ACCOUNT_NAME } from "../../assets/sip-config";

@Injectable({
    providedIn: 'root'
})
export class SipPlatformLoginService {

    constructor(private http: HttpClient) {
    }


    getLoginDetails(email: string) {
        const requestPath = `${environment.voice}/ucp/v2/login`;

        const httpOptions = {
            headers: {
                'Content-Type': 'application/json',
            },
        }

        const payload = {
            domain: ACCOUNT_NAME,
            password: environment.sipPassword,
            username: email?.toLowerCase()
        }

        return this.http.post<VoicePlatformData>(requestPath, payload, httpOptions);
    }

    fetchQueues(authToken: string) {
        const requestPath = `${environment.voice}/callcenter/agent/status/queues`;

        const httpOptions = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${authToken}`
            },
        };

        return this.http.get<VoiceQueueInfo>(requestPath, httpOptions);
    }

    loginOrOut(action: LoginAction, queueID: string, accessToken: string) {
        const requestPath = `${environment.voice}/callcenter/agent/${action}?queueID=${queueID}`;

        const httpOptions = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${accessToken}`
            },
        };

        return this.http.put(requestPath, {}, httpOptions);
    }

}
