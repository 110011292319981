import { Component, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { ReloadCustomerDetails } from 'src/app/customer-info-summary-page/store/actions/customer.actions';
import { CustomerInfoState } from 'src/app/customer-info-summary-page/store/state/customer-info.state';

@Component({
  selector: 'sf-refresh-smartsub-data-button',
  templateUrl: './refresh-smartsub-data-button.component.html',
  styleUrls: ['./refresh-smartsub-data-button.component.scss']
})
export class RefreshSmartsubDataButtonComponent implements OnInit {
  @Select(CustomerInfoState.isLoading) isLoading$: Observable<boolean>;

  constructor(private store: Store) { }

  mostRecentSearchParam: string;
  tooltipMessage: string;

  ngOnInit(): void {
    this.mostRecentSearchParam = this.store.selectSnapshot(CustomerInfoState.getMostRecentSearchParam);
    this.tooltipMessage = "Refresh customer data for " + this.mostRecentSearchParam;
  }

  onRefreshCustomerData() {
    this.store.dispatch(new ReloadCustomerDetails(true));
  }


}
