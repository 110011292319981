import { Injectable } from '@angular/core';
import { State, Action, StateContext, createSelector } from '@ngxs/store';
import { produce } from 'immer';
import { AddSFWarning, RemoveSFWarning } from '../actions/sf-warnings.actions';
import { SfWarningType } from '../types/sf-warning.type';


interface SfWarningStateModel {
  warnings: {
    [key in SfWarningType]: boolean
  }
}
@State<SfWarningStateModel>({
  name: 'sf_warning_state',
  defaults: {
    warnings: {
      multiple_tabs_open: false
    }
  }
})
@Injectable()
export class SfWarningState {

  static getWarningType(warningType: SfWarningType) {
    return createSelector([SfWarningState], (state: SfWarningStateModel) => {
      return state.warnings[warningType];
    });
  }


  @Action(AddSFWarning)
  addSFWarning(ctx: StateContext<SfWarningStateModel>, action: AddSFWarning) {
    ctx.setState(
      produce(draft => {
        draft.warnings[action.warningType] = true;
      })
    );
  }

  @Action(RemoveSFWarning)
  removeSFWarning(ctx: StateContext<SfWarningStateModel>, action: RemoveSFWarning) {
    ctx.setState(
      produce(draft => {
        draft.warnings[action.warningType] = false;
      })
    );
  }

}
