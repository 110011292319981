import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { CoreState } from 'src/app/core/store/state/core.state';
import { AddSFWarning } from '../../store/actions/sf-warnings.actions';

const TAB_OPEN_MESSAGE = "Is tab already open?";
const CLOSE_TAB_MSG = "close tab";

@Injectable({
  providedIn: 'root'
})
export class WarningBroadcasterService {

  constructor(private store: Store) { }

  initializeBroadcaster() {
    const bc = new BroadcastChannel("sf-warning-broadcaster");

    bc.onmessage = (event: MessageEvent) => {
      const msg = event.data?.message;

      if (msg === TAB_OPEN_MESSAGE) {
        bc.postMessage({ message: CLOSE_TAB_MSG });
      }
      else if (msg === CLOSE_TAB_MSG) {
        this.conditionallyAddWarning();
      }
    };

    bc.postMessage({ message: TAB_OPEN_MESSAGE });
  }

  private conditionallyAddWarning() {

    const isManagementOrAdmin = this.store.selectSnapshot(CoreState.hasOneOfTheseRoles([
      "AUTH.USER.ADMIN",
      "AUTH.USER.MANAGEMENT",
      "CEC.WIPROGEN.A"
    ]));

    if (!isManagementOrAdmin) {
      this.store.dispatch(new AddSFWarning("multiple_tabs_open"));
    }
  }

}
