
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiControllerService } from 'src/app/shared/services/api-service/api-controller.service';
import { environment } from 'src/environments/environment';
import { Group } from '../interfaces/groups.interface';

@Injectable({
  providedIn: 'root'
})
export class GroupService {

  constructor(private apiService: ApiControllerService) { }


  getAllGroups(): Observable<{ data: Group[] }> {
    return this.apiService.getV2(`${environment.snowflake_api_url}/ticket/all/groups`, {});
  }

}
