import { Utils } from "src/app/Utils";
import { DataHandler } from "../../data-handler/data-handler";
import { Dictionary } from "../../interfaces/dictionary.interface";
import { SFValidators } from "../sf-validators";

export const selectProperties = <T extends Dictionary, Keys extends (keyof T)[], NewObj extends Pick<T, Keys[number]>>
    (obj: T, keys: Keys) => keys.reduce(
        (newObj: NewObj, key: keyof T) => {
            newObj[key] = obj[key];
            return newObj;
        }, {} as NewObj
    );


export const removeProperties = <T extends Record<string, any>, K extends keyof T, Ks extends K[]>
    (obj: T, keys: Ks) =>
    Object
        .keys(obj)
        .filter(key => !keys.includes(key as K))
        .reduce(
            (acc, key) => {
                acc[key] = obj[key as K];
                return acc;
            }, {} as any
        ) as Omit<T, Ks[number]>;


export const deepRemoveProperties = <T extends Record<string, any>, Ks extends (keyof T)[]>
    (obj: T, keys: Ks) =>
    Utils.Functional.pipe(
        obj,
        DataHandler.createDeepCopy,
        (obj) => removeProperties(obj, keys)
    ) as Omit<T, Ks[number]>;


export const keyValueFilter = <Arr extends Value[], Value extends { [key in string | number]: any }>
    (arr: Arr, key: keyof Value, value: Value) => arr?.filter(item => item[key] === value);



export const findOnlyHighestPrio = <T, AccessorFn extends (v: T) => any>
    (items: T[], accessor: AccessorFn, priorityValuesInOrder: ReturnType<AccessorFn>[]) => {

    const highestPrioValue = findHigestPrioValue(items, accessor, priorityValuesInOrder);
    if (SFValidators.isNotDefined(highestPrioValue)) {
        return [];
    }

    return items.filter(item => accessor(item) === highestPrioValue);
}

const findHigestPrioValue = <T, AccessorFn extends (v: T) => any>
    (items: T[], accessor: AccessorFn, priorityValuesInOrder: ReturnType<AccessorFn>[]) => {

    let highestPrioIndex: number | null = null;

    for (const item of items) {
        const value = accessor(item);
        if (value === priorityValuesInOrder[0]) {
            return priorityValuesInOrder[0];
        }

        for (let i = 0; i < priorityValuesInOrder.length; i++) {
            const relevantValue = priorityValuesInOrder[i];

            if (highestPrioIndex !== null && highestPrioIndex < i) {
                continue;
            }

            if (relevantValue === value) {
                highestPrioIndex = i;
            }
        }
    }

    return highestPrioIndex === null ? null : priorityValuesInOrder[highestPrioIndex];
}