import { AgentActionLoggingState } from "./agent-action-logging.state";
import { AgentActionState } from "./agent-action.state";
import { AgentViewCallbackResponseState } from "./agent-view-callback-response.state";
import { AgentState } from "./agent.state";


export const CORE_AGENT_STATES = [
    AgentState,
    AgentActionState,
    AgentActionLoggingState,
    AgentViewCallbackResponseState
]