<div *ngIf="isLoading$ | async; else isLoaded" class="spinner-container">
    <small-text-spinner class="spinner" size="10px">
    </small-text-spinner>
</div>

<ng-template #isLoaded>

    <svg *ngIf="message$ | async" [matTooltip]="message$ | async" xmlns="http://www.w3.org/2000/svg" width="17px"
        height="18px" viewBox="0 0 1239.000000 1280.000000" preserveAspectRatio="xMidYMid meet">
        <g transform="translate(0.000000,1280.000000) scale(0.100000,-0.100000)" stroke="none">
            <path
                d="M11255 12785 c-198 -7 -513 -18 -700 -24 -187 -7 -468 -16 -625 -21 -157 -5 -431 -14 -610 -20 -179 -6 -446 -15 -595 -20 -148 -5 -447 -15 -663 -22 l-393 -12 -2102 -2140 c-2744 -2793 -2907 -2959 -2904 -2962 3 -3 316 -15 1172 -44 319 -11 767 -27 995 -35 228 -8 428 -15 443 -15 31 0 182 151 -2986 -2995 l-1188 -1180 268 -7 c147 -3 547 -11 888 -18 341 -6 621 -13 622 -14 2 -2 -639 -711 -1423 -1577 -784 -866 -1428 -1579 -1431 -1586 -7 -18 49 -84 67 -77 8 3 688 380 1510 838 822 459 2418 1348 3545 1976 1128 628 2053 1145 2056 1148 3 4 0 28 -8 55 l-14 48 -447 -6 c-1001 -13 -2037 -24 -2040 -21 -4 4 322 264 3196 2556 1183 943 2151 1718 2151 1722 1 5 -654 9 -1454 10 l-1455 3 947 885 c4110 3836 3743 3490 3743 3532 l0 38 -102 -1 c-57 -1 -265 -7 -463 -14z" />
        </g>
    </svg>

</ng-template>