<sf-popup-modal
    [isOpen]="true"
    [hasCloseButton]="false"
    [autoClose]="false"
    [successResponse]="modalOptions.successResponse"
    [sending]="modalOptions.sending"
    [spinnerMessage]="modalOptions.spinnerMessage"
    [limitHeight]="true"
>

    <ng-container header>retention questionnaire</ng-container>

    <ng-container
        mainContent
        *ngIf="viewModel$ | async as viewModel"
    >

        <ng-container [formGroup]="form">
            <sf-select
                [labelStyle]="labelStyle"
                label="Services"
                [options]="viewModel.servicesSfOptions"
                name="msisdn"
                marginBottom="1rem"
                (onOptionSelect)="onSelect($event, 'msisdn')"
                placeholder="select service"
            >
            </sf-select>

            <div class="form-wrapper">
                <h4>Email address:</h4>
                <p class="input-div">{{viewModel.customerEmail}}</p>
            </div>


            <div class="toggle-wrapper">
                <input
                    type="checkbox"
                    [formControlName]="'retained'"
                >&nbsp; Retained?
            </div>
            <sf-select
                [labelStyle]="labelStyle"
                label="reasons"
                marginBottom="1rem"
                (onOptionSelect)="onSelect($event, 'retainedOrNotReason')"
                [options]="retentionReasonsDynamic"
            >
            </sf-select>
            <sf-select
                *ngIf="(isNetworkIssue$ | async)"
                label="network issues"
                [labelStyle]="labelStyle"
                [options]="noNetworkReasonList"
                marginBottom="1rem"
                (onOptionSelect)="onSelect($event, 'noNetworkReason')"
                placeholder="no network"
            >
            </sf-select>
            <div class="form-wrapper">
                <sf-form-input
                    label="comments:"
                    style="font-weight: 100;"
                    [control]="form.get('comments')"
                    [type]="'text'"
                >
                </sf-form-input>
            </div>

            <ng-template #extraSpace>
                <div class="extra-space"></div>
            </ng-template>
        </ng-container>
    </ng-container>

    <ng-container buttons>
        <button
            class="rain-button default-button-size send-button solid"
            (click)="onSend()"
            [disabled]="!form?.valid || modalOptions.sending"
        >
            send
        </button>
    </ng-container>


</sf-popup-modal>
