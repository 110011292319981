import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ToggleButtonComponent } from "./toggle-button.component";
import { ReactiveFormsModule } from "@angular/forms";

const declarations = [
  ToggleButtonComponent
]

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule
  ],
  declarations: [...declarations],
  exports: [...declarations],
})
export class ToggleButtonModule { }
