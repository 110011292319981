import { Injectable } from '@angular/core';
import escalations_map from '../../../../assets/json-files/escalations_map.json';
import { CustomerInfoSummaryService } from 'src/app/customer-info-summary-page/store/services/customer-info-summary.service';
import { RainAgent } from 'src/app/shared/components/agent/rain-agent.service';

@Injectable({
  providedIn: 'root'
})
export class EscalationService {

  constructor(private customerInfoSummaryService: CustomerInfoSummaryService,
    private rainAgent: RainAgent) { }

  getSimDetails() {
    return this.customerInfoSummaryService?.getCurrentSimSFOptions();
  }

  getEscalationTeamOptions() {
    return escalations_map.escalations;
  }

  async getEscalationOptionsForTeam(): Promise<string[]> {
    const agentDetails = await this.rainAgent.getAgentDetails();
    return this.getEscalationTeamOptions()?.[agentDetails?.getTeamRole()] ?? [] as string[];
  }
}
