import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { TicketTrackingActions } from '../actions/ticket-tracking-actions';
import { tap } from 'rxjs/operators';
import { TicketTrackingService } from '../../services/ticket-tracking.service';
import { CoreState } from 'src/app/core/store/state/core.state';
import { CustomerInfoState } from 'src/app/customer-info-summary-page/store/state/customer-info.state';
import { TicketMetaData } from '../types/add-ticket-metadata-payload.interface';
import { INCOMING_CALL_TITLE, OUTGOING_CALL_TITLE, VoiceTicketSubject } from 'src/app/sip-phone/store/constants/call-ticket-and-event-names.constants';
import { SipPhoneState } from 'src/app/sip-phone/store/state/sip.state';


interface TicketTrackingStateModel {
    loading: boolean;
}
@State<TicketTrackingStateModel>({
    name: 'sf_ticket_tracking_state',
    defaults: {
        loading: false
    }
})
@Injectable()
export class TicketTrackingState {

    @Selector([TicketTrackingState])
    static isLoading(state: TicketTrackingStateModel) { return state.loading }

    constructor(private ticketTrackingService: TicketTrackingService,
        private store: Store) {
    }

    @Action(TicketTrackingActions.InteractedWithTicket)
    TicketInteracted(ctx: StateContext<TicketTrackingStateModel>, action: TicketTrackingActions.InteractedWithTicket) {
        const { hexId, interactionType, customerIdentifier } = action?.payload ?? {};

        const defaultData = this.getDefaultMetaData();

        return ctx.dispatch(new TicketTrackingActions.AddMetaData({
            external_reference: hexId,
            extra: {
                ...defaultData,
                customer_identifier: customerIdentifier,
                interaction_type: interactionType,
            }
        }));
    }

    @Action(TicketTrackingActions.NewTicketCreated)
    NewTicketCreated(ctx: StateContext<TicketTrackingStateModel>, action: TicketTrackingActions.NewTicketCreated) {
        const { hex_id, name } = action?.payload ?? {};

        const isCreatedCallTicket = [INCOMING_CALL_TITLE, OUTGOING_CALL_TITLE]?.includes(<VoiceTicketSubject>name);
        if (!isCreatedCallTicket) {
            return;
        }

        const defaultData = this.getDefaultMetaData();
        const { phoneNumber } = this.store.selectSnapshot(SipPhoneState.getIncomingOrOutgoingCallDetails) ?? {};

        return ctx.dispatch(new TicketTrackingActions.AddMetaData({
            external_reference: hex_id,
            extra: {
                ...defaultData,
                customer_identifier: {
                    type: "phone",
                    value: phoneNumber
                },
                interaction_type: name === INCOMING_CALL_TITLE ? "auto created incoming call ticket" : "auto created outgoing call ticket",
            }
        }));
    }


    @Action(TicketTrackingActions.AddMetaData)
    LogMetaData(ctx: StateContext<TicketTrackingStateModel>, action: TicketTrackingActions.AddMetaData) {
        const { payload } = action ?? {};

        //TODO: can remove later
        const inSales = this.store.selectSnapshot(CoreState.isInSales);
        if (!inSales) {
            return;
        }

        ctx.patchState({ loading: true });

        return this.ticketTrackingService.addTicketMetaData(payload)
            .pipe(tap({
                next: () => ctx.dispatch(new TicketTrackingActions.AddMetaDataSuccess()),
                error: () => ctx.dispatch(new TicketTrackingActions.AddMetaDataFail())
            }));
    }


    @Action(TicketTrackingActions.AddMetaDataSuccess)
    LogMetaDataSuccess(ctx: StateContext<TicketTrackingStateModel>) {
        ctx.patchState({ loading: false });
    }


    @Action(TicketTrackingActions.AddMetaDataFail)
    LogMetaDataFail(ctx: StateContext<TicketTrackingStateModel>) {
        ctx.patchState({ loading: false });
    }

    private getDefaultMetaData(): Pick<TicketMetaData, "agent_email" | "customer_info" | "is_known"> {
        const agentEmail = this.store.selectSnapshot(CoreState.getAgentEmail);
        const customerInfo = this.store.selectSnapshot(CustomerInfoState.getSmartsubData);

        return {
            customer_info: customerInfo,
            is_known: Boolean(customerInfo),
            agent_email: agentEmail,
        }
    }


}
