import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CurrentDeviceRequest, PossibleCurrentDevice } from '../store/interfaces/current-device.interfaces';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class DeviceDataService {

    constructor(private http: HttpClient) { }

    getDevice(request: CurrentDeviceRequest): Observable<PossibleCurrentDevice> {
        const url = `${environment.da_endpoint}/cpe-stats-coordinates?imsi=${request.imsi}`;

        //TODO: remove after testing
        // return of(
        //     {
        //         "gps_coords": [
        //             {
        //                 "imsi": "655730001304630",
        //                 "cpe_latitude": -33.915405,
        //                 "cpe_longitude": 18.418065
        //             }
        //         ]
        //     }
        // )
        //     .pipe(
        //         delay(3000)
        //     )

        const httpOptions = {
            headers: {
                "content-type": "application/json",
                "x-api-key": environment.da_apikey
            }
        };

        return this.http.get<PossibleCurrentDevice>(url, httpOptions);
    }
}