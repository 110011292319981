import { Pipe, PipeTransform } from '@angular/core';
import { SFValidators } from '../../functions/sf-validators';

@Pipe({ name: 'postfix' })
export class Postfix implements PipeTransform {

  transform(value: string, postfix: string): string {
    if (SFValidators.isNotDefined(value)) {
      return
    }

    return `${value}${postfix}`;

  }

}
