import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { SimActions } from '../actions/sim-actions';

export interface SimStateModel {
    selectedServiceID
}
@State<SimStateModel>({
    name: 'sf_sim_state',
    defaults: {
        selectedServiceID: null,
    }
})
@Injectable()
export class SimState {

    @Selector([SimState])
    static getSelectedID(state: SimStateModel) { return state?.selectedServiceID }
    
    @Action(SimActions.SetSelectedID)
    SetSelectedID(ctx: StateContext<SimStateModel>, action: SimActions.SetSelectedID) {
        ctx.patchState({ selectedServiceID: action.id });
    }

    @Action(SimActions.ClearSelectedID)
    ClearSelectedID(ctx: StateContext<SimStateModel>) {
        ctx.patchState({ selectedServiceID: null });
    }

}
