

export function stringArrToNumArr(stringArr: string[]): number[] {
  if (!stringArr) {
    return [];
  }

  const getOnlyNums = (val: any) => {
    if (val?.match(/^\d+$/)) return parseInt(val, 10);
    return null;
  }

  return stringArr.map(getOnlyNums)
    .filter(num => num !== undefined && num !== null);
}
