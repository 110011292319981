import { Component, Input } from '@angular/core';
import { DataHandler } from 'src/app/shared/data-handler/data-handler';

@Component({
  selector: 'sf-labelled-color-box',
  templateUrl: './labelled-color-box.component.html',
  styleUrls: ['./labelled-color-box.component.scss']
})
export class LabelledColorBoxComponent {

  readonly largeFont = "0.9rem";

  @Input() label: string;
  @Input() text: string;
  @Input() textToolTip: string;
  @Input() backgroundColor: string;
  @Input() fallbackColor = DataHandler.getColorCode("grey");
  @Input() indicatorType: "default" | "reverse" = "default";

  @Input() skipNoDataCheck = false;

  @Input() largeFontStyle = false;

}
