import { HashMap } from "src/app/shared/interfaces/hash-map.interface";
import { StringKey } from "src/app/shared/types/string-of.type";
import { String } from 'ts-toolbelt';


type FormattedKeys<T, S extends string, R extends string = ""> = {
    [k in StringKey<T> as `${String.Replace<k, S, R>}`]: T[k];
};

export function formatKeys<T extends HashMap, S extends string, R extends string>
    (hashmap: T, searchValue: S, replacer?: R) {

    return Object.keys(hashmap)
        .reduce((preVal, key) => {
            const newKey = key?.replace(searchValue, replacer ?? "");
            preVal[newKey] = hashmap[key];
            return preVal;
        }, {} as HashMap) as FormattedKeys<T, S, R>;
}