
const DISTANCE_COLOR_MAP = {
  green: "#37F713",
  orange: "#f7ef00",
  red: "#F72F2F"
}

export function getDistanceColor(distanceInMeters: number, asColorCode = true) {
  let color: string;
  if (distanceInMeters < 1000) {
    color = "green";
  }
  else if (distanceInMeters >= 1000 && distanceInMeters < 3000) {
    color = "orange";
  }
  else {
    color = "red";
  }

  return asColorCode ? DISTANCE_COLOR_MAP[color] : color;
}
