import { SearchByNamePayload } from "../search.interfaces";

export function getFullNameFromSearchString(text: string): SearchByNamePayload {
  const stringArr = text.replace(' ', '_').split('_', 2);
  const [first_name, last_name] = stringArr;

  if (stringArr.length == 1) {
    return { first_name, last_name: '%' };
  }

  return { first_name, last_name };
}
