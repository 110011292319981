<sf-popup-modal
  [isOpen]="true"
  [hasCloseButton]="false"
  [autoClose]="false"
  [successResponse]="modalOptions.successResponse"
  [sending]="modalOptions.sending"
  [spinnerMessage]="modalOptions.spinnerMessage"
>

  <ng-container header>post fulfilment form</ng-container>

  <ng-container mainContent>
    <sf-modal-form-container [formGroup]="form">

        <div
          class="delivery-options-wrapper"
          *ngFor="let option of FULFILLMENT_DELIVERIES_OPTIONS"
        >
          <label for="{{ option }}">{{ option }}</label>
          <input
            type="radio"
            id="{{ option }}"
            formControlName="fulfillment_deliveries_option"
            [value]="option"
            (click)="updateSelectedOption(option)"
            [checked]="selectedOption === option"
          />
          </div>

    </sf-modal-form-container>
  </ng-container>

  <ng-container buttons>
    <sf-rain-button
      [isSolid]="true"
      [disabled]="form?.invalid || modalOptions?.sending || !selectedDeliveryOption"
      (click)="onSubmit()"
    >
      send
    </sf-rain-button>
  </ng-container>

</sf-popup-modal>
